import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { PracticePathNarrationDuringRecording } from "src/app/classes/model/practice-path";
import { DraggableDialogService } from "src/app/modules/draggable-dialog/services/draggable-dialog.service";
import { NarrationPlayerDialogComponent, NarrationPlayerDialogInput } from "../narrations-during-recording-list-tab/narration-player-dialog/narration-player-dialog.component";

@Injectable()
export class NarrationPlayerDialogOpenerService {
  private narrationPlayerDialogOpenedSubject:Subject<void>;
  private readonly titleLengthCap:number = 40;

  constructor(private draggableDialogService:DraggableDialogService) {
    this.narrationPlayerDialogOpenedSubject = new Subject<void>();
  }

  /**
   * Gets the narration player opened subject as an observable.
   */
  public get narrationPlayerDialogOpened$():Observable<void> {
    return this.narrationPlayerDialogOpenedSubject.asObservable();
  }

  /**
   * Opens a narration player dialog with the given narration.
   * 
   * @param narrationDuringRecording the target narration to open in the dialog
   */
  public openNarrationPlayerDialog(
    narrationDuringRecording:PracticePathNarrationDuringRecording
  ):void {
    // Limit the title length
    let displayedTitle:string = narrationDuringRecording.title ?? "Cím nélküli narráció";
    if(displayedTitle.length > this.titleLengthCap) {
      displayedTitle = displayedTitle.substring(0, this.titleLengthCap) + "...";
    }

    // Open the narration player dialog in a draggable dialog
    this.draggableDialogService.open<
      NarrationPlayerDialogComponent,
      NarrationPlayerDialogInput,
      void
    >(
      NarrationPlayerDialogComponent,
      {
        width: "500px",
        data: {
          narrationDuringRecording: narrationDuringRecording
        }
      },
      {
        title: displayedTitle
      }
    );

    this.narrationPlayerDialogOpenedSubject.next();
  }
}