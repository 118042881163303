import { Component, Input } from '@angular/core';
import { CriticalPointLog } from 'src/app/modules/practical-module-logs/models/practical-module-log.model';

@Component({
  selector: 'app-critical-point-log-item-content',
  templateUrl: './critical-point-log-item-content.component.html',
  styleUrls: ['./critical-point-log-item-content.component.scss']
})
export class CriticalPointLogItemContentComponent {
  @Input() criticalPointLog:CriticalPointLog;

  /**
   * Determines if the log has data to show.
   * 
   * @returns true if the log has data to show, false otherwise
   */
  protected hasNoLogData():boolean {
    return this.criticalPointLog.oldData == null && this.criticalPointLog.newData == null;
  }
}
