<div
  *ngIf = "data.dialogTitle"
  class = "dialog-title"
  style = "width: 500px"
>
  {{ data.dialogTitle }}
</div>
<div class="main-container">
  <div style="color:red;" *ngIf="this.unexpectedErrorHappen==true">Nem várt hiba történt ...</div>

  <div>
    <circle-progress
      [percent]="currentTaskState?.progressPercent ?? 0"
      [radius]="51"
      [animation]="true"
      [subtitle]="'Mr. Vegas task'"
      [animationDuration]="300"
    ></circle-progress>
  </div>

  <!-- Logok -->
  <div style="width: 500px; height: 280px; overflow: auto">
    <b>logs</b>
    <ngx-json-viewer
      [json]="this.currentTaskState?.logs ?? []"
    ></ngx-json-viewer>
  </div>

  <div
    *ngIf="this.currentTaskState?.errorLogs != undefined && this.currentTaskState?.errorLogs?.length != 0"
    style="width: 500px; height: 400px"
  >
    <b>error</b>
    <ngx-json-viewer
      [json]="this.currentTaskState?.errorLogs ?? []"
    ></ngx-json-viewer>
  </div>

  <div *ngIf="this.currentTaskState?.errorLogs?.length == 0">
    Nincs error log ✅
  </div>

  <div *ngIf="this.currentTaskState?.state != 'DONE'">
    Még nem fejeződött be teljesen ⏳
  </div>

  <!--
  A bezárás gombot csak akkor jelenítjük meg, ha hiba történt, vagy ha végeztünk
  egyébként ha még folyamatban van egy task ne lehessen bezárni a dialógust
  -->
  <button
    style="margin-top: 25px"
    (click)="onTapClose()"
    mat-button
    *ngIf="shouldCloseButtonVisible()"
  >
    Bezárás
  </button>
</div>
