import { ConfirmationDialogWithReasonComponent } from './../confirmation-dialog-with-reason/confirmation-dialog-with-reason.component';
import { MrvegasTaskProgressDialogComponent, MrVegasProgressDialogOutput } from './../../../../../../misc/mrvegas-task-progress-dialog/mrvegas-task-progress-dialog.component';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { awaitFor } from 'src/app/classes/misc';
import { PracticePathBaseInformations } from 'src/app/classes/model/practice-path';
import { FinalVideo, PracticeVideo, videoSorter } from 'src/app/classes/model/practice-video';
import { PracticePathService } from 'src/app/services/practice-path.service';
import { PracticeVideoService } from 'src/app/services/practice-video.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotifierService } from 'src/app/services/common/notifier-service.service';
import { BehaviorSubject } from 'rxjs';
import { ProgressDialogComponent } from 'src/app/modules/shared-module/components/progress-dialog/progress-dialog.component';
import { PermissionService } from 'src/app/services/common/permission.service';
import { Permission } from 'src/app/classes/model/permissions';

@Component({
  selector: 'app-final-videos-tab',
  templateUrl: './final-videos-tab.component.html',
  styleUrls: ['./final-videos-tab.component.scss']
})
export class FinalVideosTabComponent implements OnInit, OnChanges {
  @Input() finalVideos: Array<FinalVideo>;
  @Input() practicePath: PracticePathBaseInformations;


  protected startCloudInstance: boolean = true;

  @Output() clickedOnAnonymizedVideoEvent: EventEmitter<string>;
  @Output() finalVideoUploaded: EventEmitter<PracticeVideo>;

  @ViewChild("videoInput") videoInput: ElementRef<HTMLInputElement>;
  @ViewChild("finalVideosDataTable") finalVideosDataTable: ElementRef<HTMLTableElement>;

  @HostListener('change', ['$event.target.files']) async emitFiles(event: FileList) {
    const file: File | null = event && event.item(0);

    // Create the progress and the text subject for the upload
    const progressSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    const textSubject: BehaviorSubject<string> = new BehaviorSubject<string>("Fájlok feltöltése a szerverre...");

    const onUploadProgress = (state: "upload" | "save", progress: number) => {
      progressSubject.next(progress / 100);

      if (state === 'save') {
        textSubject.next("Fájlok mentése S3-ba...")
      }
    }

    // Open the progress dialog
    const uploadProgressDialogRef = ProgressDialogComponent.open(this.dialogService, {
      progressSubject: progressSubject,
      textSubject: textSubject
    });

    let practiceVideo: PracticeVideo;
    try {
      practiceVideo = await this.practicePathService.uploadFinalPracticePathVideo(
        this.practicePath.uuid,
        { name: file.name, file: file },
        onUploadProgress
      );
      this.selectIndex(0);
      this.finalVideoUploaded.emit(practiceVideo);
      this.finalVideos = this.practicePath.video.finalVideos;
      this.changeDetectorRef.detectChanges();
      this.finalVideosDataTable.nativeElement.scrollTo({ top: 0 });
    } catch (error: any) {
      console.error(error);
    }
    uploadProgressDialogRef.close();
  }

  selectedIndex: number | null = null;

  // Annak az indexe amit éppen release-elünk
  releasingIndex: number | null = null;

  finalVideoDeletionsInProgress: Array<string> = [];

  constructor(
    private practiceVideoService: PracticeVideoService,
    private practicePathService: PracticePathService,
    private dialogService: MatDialog,
    private notifierService: NotifierService,
    private ngxUiService: NgxUiLoaderService,
    private permissionService: PermissionService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.clickedOnAnonymizedVideoEvent = new EventEmitter<string>();
    this.finalVideoUploaded = new EventEmitter<PracticeVideo>();
  }

  ngOnInit(): void {
    if (this.finalVideos.length > 0) {
      this.selectedIndex = 0;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If there are changes, resort the final videos
    this.finalVideos.sort(videoSorter);
  }

  public selectIndex(newIndex: number): void {
    this.selectedIndex = newIndex;
  }

  public selectFinalVideoToUpload(): void {
    this.videoInput.nativeElement.click();
  }

  public navigateToAnonymizedVideo(anonimizedVideoUuid: string): void {
    this.clickedOnAnonymizedVideoEvent.emit(anonimizedVideoUuid);
  }

  public async releaseFinalVideo(index: number): Promise<void> {
    if (this.releasingIndex !== null || index >= this.finalVideos.length) {
      return;
    }

    if (this.finalVideos[index].isPermanentDeleted) {
      this.notifierService.notify('error', 'Ezt a videót nem tudot kiadni, mivel törölve lett!');
      return;
    }

    this.releasingIndex = index;


    try {
      const taskId: string = await this.practiceVideoService.releaseFinalVideo(this.practicePath, this.finalVideos[index].uuid, this.startCloudInstance);
      if (taskId == null) {
        this.notifierService.notify("error", "Hiba történt a task létrehozásakor");
        return;
      }
      const mrVegasTaskProgressDialogRef = MrvegasTaskProgressDialogComponent.openDialog(this.dialogService, {
        taskId: taskId
      });

      // Ezen a ponton kilépett a dialógusból a felhasználó (csak akkor léphetett ki ha terminate state-be került a task)
      mrVegasTaskProgressDialogRef.afterClosed().subscribe(async (dialogOutput: MrVegasProgressDialogOutput) => {
        if (dialogOutput.lastTaskState.state == "DONE") {
          this.ngxUiService.start();
          await awaitFor(25000); // a szerver is lementi a videót... kb 25-30 másodpercenként checkolja, hogy végzett-e egy mrvegas task
          this.practicePath.video.releasedVideo = (
            await this.practicePathService.fetchPracticePath(this.practicePath.uuid, "summary")
          ).video.releasedVideo;

          this.ngxUiService.stop();
        }
      });
    } catch (error: any) {
      this.ngxUiService.stop();
      console.error(error);
    }

    this.releasingIndex = null;
  }

  protected async deleteFinalVideo(index: number): Promise<void> {



    const dialog = ConfirmationDialogWithReasonComponent.openDialog(this.dialogService, {
      title: "Végleges videó törlése",
      onYes: async (reason: string) => {
        dialog.close();

        this.finalVideoDeletionsInProgress.push(this.finalVideos[index].uuid);
        try {
          await this.practiceVideoService.deleteFinalVideo(this.practicePath, this.finalVideos[index].uuid, reason);
          this.notifierService.notify('success', 'A videó sikeresen törölve lett!');
        } catch (error: any) {
          console.error(error);
        }

        this.finalVideoDeletionsInProgress.removeItems(item => item === this.finalVideos[index].uuid);
      },
      onNo: () => {
        dialog.close();
      }
    });


  }

  protected isFinalVideoDeletionInProgress(index: number) {
    return this.finalVideoDeletionsInProgress.includes(this.finalVideos[index].uuid);
  }


  protected hasAdminFinalVideoUploadPermisison(): boolean {
    return this.permissionService.isLoggedUserHasPermission(Permission.GeneralFileUpload) &&
    this.permissionService.isLoggedUserHasPermission(Permission.PracticeVideoUpload);
  }

}
