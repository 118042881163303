import { Component, Input, OnInit } from '@angular/core';
import { LoggerService } from '../../services/logger.service';
import { Log, LogType } from '../../models/log';

@Component({
  selector: 'app-logger',
  templateUrl: './logger.component.html',
  styleUrls: ['./logger.component.scss']
})
export class LoggerComponent implements OnInit {
  @Input() logKey:string;

  LogType = LogType;
  logsRef:ReadonlyArray<Readonly<Log>>;

  constructor(private loggerService:LoggerService) { }

  ngOnInit(): void {
    this.logsRef = this.loggerService.getLogs(this.logKey);
  }

  getIconNameForType(logType:LogType):string {
    switch(logType) {
      case LogType.Info:    return "info";
      case LogType.Success: return "check_circle";
      case LogType.Warning: return "warning";
      case LogType.Error:   return "error";
      default:              return "info";
    }
  }

  getLogClass(logType:LogType):string {
    const baseClass:string = "logger-logs-container";
    switch(logType) {
      case LogType.Info:    return baseClass + " info";
      case LogType.Success: return baseClass + " success";
      case LogType.Warning: return baseClass + " warning";
      case LogType.Error:   return baseClass + " error";
      default:              return baseClass;
    }
  }

}
