import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";

export class CustomValidators {
  static wholeNumber(control: AbstractControl): ValidationErrors | null {
    const controlValue: number = control.value as number;
    if (Number.isNaN(controlValue)) {
      return null;
    }

    if (controlValue - Math.floor(controlValue) !== 0) {
      return { wholeNumber: true };
    } else {
      return null;
    }
  }

  static positiveNumber(control: AbstractControl): ValidationErrors | null {
    const controlValue: number = control.value as number;
    if (Number.isNaN(controlValue)) {
      return null;
    }

    if (controlValue < 0) {
      return { positiveNumber: true };
    } else {
      return null;
    }
  }

  static positiveWholeNumber(
    control: AbstractControl
  ): ValidationErrors | null {
    const controlValue: number = control.value as number;
    if (Number.isNaN(controlValue)) {
      return null;
    }

    if (controlValue < 0 || controlValue - Math.floor(controlValue) !== 0) {
      return { positiveWholeNumber: true };
    } else {
      return null;
    }
  }

  static acceptableFileTypes(fileTypes: ReadonlyArray<string>): ValidatorFn {
    return function (control: AbstractControl): ValidationErrors | null {
      if (typeof control.value === "string") {
        return null;
      }

      const file: File | null = control.value ?? null;
      if (file !== null && !fileTypes.includes(file.type)) {
        return { invalidFileType: true };
      }

      return null;
    };
  }

  static valueOneOf<ValueType>(
    acceptableValues: Array<ValueType>
  ): ValidatorFn {
    return function (control: AbstractControl): ValidationErrors | null {
      return !acceptableValues.includes(control.value)
        ? { invalidValue: true }
        : null;
    };
  }

  static maximalLineCount(maximalLineCount: number): ValidatorFn {
    return function (control: AbstractControl): ValidationErrors | null {
      if (typeof control.value !== "string") {
        return null;
      }

      const lineCount: number = control.value.split("\n").length;
      return lineCount > maximalLineCount
        ? {
            maximalLineCount: {
              requiredLineCount: maximalLineCount,
              actualLineCount: lineCount,
            },
          }
        : null;
    };
  }

  static maxLineLength(maxLength: number): ValidatorFn {
    return function (control: AbstractControl): ValidationErrors | null {
      if (typeof control.value !== "string") {
        return null;
      }

      const error: {
        maxLength: number;
        invalidLines: Array<{ rowIndex: number; actualLength: number }>;
      } = {
        maxLength: maxLength,
        invalidLines: [],
      };

      const charactersPerRow: Array<number> = control.value
        .split("\n")
        .map((row) => row.length);
      for (let index = 0; index < charactersPerRow.length; ++index) {
        if (charactersPerRow[index] > maxLength) {
          error.invalidLines.push({
            rowIndex: index,
            actualLength: charactersPerRow[index],
          });
        }
      }

      return error.invalidLines.length > 0 ? { maxLineLength: error } : null;
    };
  }

  public static childFormControlsValid(
    childFormControls: ReadonlyArray<AbstractControl>
  ): ValidatorFn {
    return function (control: AbstractControl): ValidationErrors | null {
      return childFormControls.some((childFormControl: AbstractControl) => {
        return childFormControl.invalid;
      })
        ? { childFormControlsValid: true }
        : null;
    };
  }

  public static email(control: AbstractControl): ValidationErrors | null {
    const emailValidatorRegExp: RegExp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return Validators.pattern(emailValidatorRegExp)(control)
      ? { email: true }
      : null;
  }

  public static noScrollbar(containerElement: HTMLElement): ValidatorFn {
    return function (control: AbstractControl): ValidationErrors | null {
      return containerElement.clientHeight < containerElement.scrollHeight
        ? { hasScrollbar: true }
        : null;
    };
  }
}

export class CustomAsyncValidators {
  public static fileImageAspectRatio(
    widthRatio: number,
    heightRatio: number
  ): AsyncValidatorFn {
    return function (
      control: AbstractControl
    ): Promise<ValidationErrors | null> {
      return new Promise<ValidationErrors | null>(
        (
          resolve: (value: ValidationErrors | null) => void,
          reject: (reason: any) => void
        ) => {
          if (!(control.value instanceof File)) {
            resolve(null);
          }

          control.setErrors(
            Object.assign(control.errors ?? {}, {
              isFileImageAspectRatioCheckPending: true,
            })
          );

          const image: HTMLImageElement = new Image();
          image.src = URL.createObjectURL(control.value);

          image.onload = function () {
            const width: number = (this as any).width;
            const height: number = (this as any).height;

            if (control.hasError("isFileImageAspectRatioCheckPending")) {
              delete control.errors["isFileImageAspectRatioCheckPending"];
            }

            if (height === (width / widthRatio) * heightRatio) {
              resolve(null);
            } else {
              resolve({ fileImageAspectRatio: true });
            }
          };
        }
      );
    };
  }

  public static videoFileMaximalLength(
    maximalLengthInSeconds: number
  ): AsyncValidatorFn {
    return function (
      control: AbstractControl
    ): Promise<ValidationErrors | null> {
      return new Promise<ValidationErrors | null>(
        (
          resolve: (value: ValidationErrors | null) => void,
          reject: (reason: any) => void
        ) => {
          if (!(control.value instanceof File)) {
            resolve(null);
          }

          if (!(control.value as File).type.includes("video")) {
            resolve(null);
          }

          control.setErrors(
            Object.assign(control.errors ?? {}, {
              isVideoFileMaximalLengthCheckPending: true,
            })
          );

          const video: HTMLVideoElement = document.createElement("video");
          video.src = URL.createObjectURL(control.value);

          video.onloadedmetadata = function () {
            const length: number = (this as HTMLVideoElement).duration;

            if (control.hasError("isVideoFileMaximalLengthCheckPending")) {
              delete control.errors["isVideoFileMaximalLengthCheckPending"];
            }

            if (length <= maximalLengthInSeconds) {
              resolve(null);
            } else {
              resolve({ videoFileMaximalLength: true });
            }
          };
        }
      );
    };
  }
}
