import { encode } from "punycode";
import { MarkerStyle } from "./marker-types";

export class MarkerUtil {
  static generateCircleSvgDataUri(
    fillColor: string,
    label: string = "",
    labelColor: string = "white"
  ) {
    labelColor = encodeURIComponent(labelColor);
    const labelSvgElem =
      label == ""
        ? ""
        : `<text x="50%" y="50%" dominant-baseline="middle" fill="${labelColor}" text-anchor="middle">${label}</text>`;
    fillColor = encodeURIComponent(fillColor);

    const resultSvgDataUri =
      encodeURI(
        'data:image/svg+xml,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32"><circle fill="'
      ) +
      fillColor +
      encodeURI(`" cx="16" cy="16" r="12"/>
      ${labelSvgElem}
    </svg>`);

    return resultSvgDataUri;
  }

  static generateLabeledMarkerSvgDataUri(
    color: string | "#BEBEBE" | "#AFAFAF",
    label: string,
    strokeColor: string = "transparent"
  ) {
    const colorUrlEncoded = encodeURIComponent(color);
    const labelEncoded = encodeURIComponent(label);
    const encodedStrokeColor = encodeURIComponent(strokeColor);

    return (
      `data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2238%22%20height%3D%2238%22%20viewBox%3D%220%200%2038%2038%22%3E%3Cpath%20fill%3D%22${colorUrlEncoded}%22%20stroke%3D%22${encodedStrokeColor}%22%20stroke-width%3D%22 3 %22%20d%3D%22M34.305%2016.234c0%208.83-15.148%2019.158-15.148%2019.158S3.507%2025.065%203.507%2016.1c0-8.505%206.894-14.304%2015.4-14.304%208.504%200%2015.398%205.933%2015.398%2014.438z%22%2F%3E%3Ctext%20transform%3D%22translate%2819%2018.5%29%22%20fill%3D%22%23fff%22%20style%3D%22font-family%3A%20Arial%2C%20sans-serif%3Bfont-weight%3Abold%3Btext-align%3Acenter%3B%22%20font-size%3D%2214%22%20text-anchor%3D%22middle%22%3E` +
      labelEncoded +
      "%3C%2Ftext%3E%3C%2Fsvg%3E"
    );
  }

  // bgColor: #FEFEFE format
  static getLabeledMarker(
    defaultFillColor: string,
    selectedFillColor: string,
    label: string,
    strokeColor: string = "transparent"
  ): MarkerStyle {
    return {
      defaultIcon: {
        scaledSize: new google.maps.Size(40, 40),
        anchor: new google.maps.Point(20, 32),
        url: MarkerUtil.generateLabeledMarkerSvgDataUri(
          defaultFillColor,
          label,
          strokeColor
        ),
      },
      selectedIcon: {
        scaledSize: new google.maps.Size(40, 40),
        anchor: new google.maps.Point(25, 25),
        url: MarkerUtil.generateLabeledMarkerSvgDataUri(
          selectedFillColor,
          label,
          strokeColor
        ),
      },
    };
  }

  static getCircleMarker(
    defaultFillColor: string,
    selectedFillColor: string,
    label: string = "",
    size: google.maps.Size = new google.maps.Size(22, 22),
    anchor: google.maps.Point = new google.maps.Point(11, 11), // hol legyen a közepe
    labelColor: string = "white"
  ): MarkerStyle {
    return {
      defaultIcon: {
        size: size,
        scaledSize: size,
        anchor: anchor,
        url: MarkerUtil.generateCircleSvgDataUri(
          defaultFillColor,
          label,
          labelColor
        ),
      },
      selectedIcon: {
        size: size,
        scaledSize: size,
        anchor: anchor,
        url: MarkerUtil.generateCircleSvgDataUri(
          selectedFillColor,
          label,
          labelColor
        ),
      },
    };
  }

  static getNotAssignedCriticalPointMarker(): MarkerStyle {
    return MarkerUtil.getCircleMarker("#ff7263", "red");
  }
}
