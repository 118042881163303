import { Injectable } from "@angular/core";
import { CriticalPoint, CriticalPointPositionInPath } from "../classes/model/practice-path";
import { BackendService } from "./common/backend.service";
import { HttpErrorHandlerService } from "./common/http-error-handler.service";
import { HttpResponseData } from "../classes/http-communication";

@Injectable({
  providedIn: "root",
})
export class CriticalPointService {

  constructor(
    private backendService: BackendService,
    private httpErrorHandlerService: HttpErrorHandlerService
  ) {}

  private getApiUrlPath(cityUuid:string, criticalPointUuid?:string) {
    let apiUrl:string = `/api/admin/practice_path_city/${cityUuid}`;

    if(criticalPointUuid) {
      apiUrl += `/critical_points/${criticalPointUuid}`
    }

    return apiUrl;
  }

  // Az adott vizsgahelyszínben szereplő összes kritikus pontot lekérdezi
  public async retrieveCriticalPointsInCity(
    cityUuid: string
  ): Promise<Array<CriticalPoint>> {
    try {
      const response: HttpResponseData<Array<CriticalPoint>> =
        await this.backendService.callApi(
          `${this.getApiUrlPath(cityUuid)}/critical_points`,
          "GET"
        );
      return response.data;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba a kritikus pontok lekérdezése közben."
      );
      return [];
    }
  }

  // Melyik vizsgahelyszínen, melyik kritikus pontot szeretnénk törölni
  // igazzal tér vissza, ha sikerült a törlés
  public async removeCriticalPoint(
    cityUuid: string,
    criticalPointUuid: string
  ): Promise<boolean> {
    try {
      await this.backendService.callApi(
        this.getApiUrlPath(cityUuid, criticalPointUuid),
        "DELETE"
      );
      return true;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba kritikus pont törlése közben"
      );
      return false;
    }
  }

  // Egy új kritikus pontot hoz létre a vizsgahelyszínben
  // Visszatér a beszúrt kritikus ponttal
  // vagy undefined-al ha nem sikerült
  public async addCriticalPoint(
    cityUuid: string,
    newCriticalPoint: CriticalPoint,
    sourcePracticePathUuid?:string
  ): Promise<CriticalPoint | undefined> {
    try {
      const response: { insertedCriticalPoint: CriticalPoint } =
        await this.backendService.callApi(
          `${this.getApiUrlPath(cityUuid)}/critical_points`,
          "PUT",
          {...newCriticalPoint, sourcePracticePathUuid}
        );
      return response.insertedCriticalPoint;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba kritikus pont hozzáadásakor"
      );
      return undefined;
    }
  }

  // Egy kritikus pontot teljesen kicserél egy vizsgahelyszínen belül
  // Visszatér a szervertől kapott kritikus ponttal
  public async changeCriticalPointFields(
    cityUuid: string,
    criticalPointUuid: string,
    newFields: {
      uuid: string,
      title: string,
      description: string,
      coordinate: {
        latitude: number,
        longitude: number,
      },
      isLocal: boolean,
      onlyAssignablePracticePathUuid?: string,      
    },
    sourcePracticePathUuid?: string
  ) {
    const response: {insertedCriticalPoint: CriticalPoint} = await this.backendService.callApi(
      this.getApiUrlPath(cityUuid, criticalPointUuid),
      "POST",
      {...newFields, sourcePracticePathUuid}
    );
    return response.insertedCriticalPoint;
  }

  /**
   * Sets the verification status of a given critical point to a given value. After the update it returns the updated critical point.
   * 
   * @param cityUuid the uuid of the city (which contains the critical point)
   * @param criticalPointUuid the uuid of the critical point
   * @param newVerificationState the new verification state
   * 
   * @returns the updated critical point
   */
   public async setCriticalPointVerificationState(
    cityUuid:string,
    criticalPointUuid:string,
    newVerificationState:boolean,
    sourcePracticePathUuid?: string
  ):Promise<CriticalPoint> {
    const apiUrlFragment:string = `${this.getApiUrlPath(cityUuid, criticalPointUuid)}/verification`;
    const httpBody:Object = {
      isVerified: newVerificationState,
      sourcePracticePathUuid: sourcePracticePathUuid
    };

    let criticalPoint:CriticalPoint;

    try {
      const result:{ criticalPoint:CriticalPoint } = await this.backendService.callApi(apiUrlFragment, "POST", httpBody);
      criticalPoint = result.criticalPoint;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Sikertelen kritikus pont hitelességi státusz módosítás.");
    }

    return criticalPoint;
  }

  /**
   * Gets the practice paths where the critical point is assigned to with it's position in them.
   * 
   * @param cityUuid he uuid of the city (which contains the critical point)
   * @param criticalPointUuid the uuid of the critical point
   * 
   * @returns the array of the positions of the critical point the (assigned) practice paths
   */
  public async fetchCalculatedPositionOfCriticalPointInAssignedPaths(
    cityUuid:string,
    criticalPointUuid:string
  ):Promise<Array<CriticalPointPositionInPath>> {
    const apiUrlFragment:string = `${this.getApiUrlPath(cityUuid, criticalPointUuid)}/calculate_position_in_assigned_paths`;

    let criticalPointPositionInPaths:Array<CriticalPointPositionInPath> = [];
    try {
      const result:{ criticalPointPositionInPaths:Array<CriticalPointPositionInPath> } = await this.backendService.callApi(
        apiUrlFragment,
        "GET"
      );
      criticalPointPositionInPaths = result.criticalPointPositionInPaths;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Sikertelen kritikus pont útvonal hozzárendelés lekérdezés.");
    }

    return criticalPointPositionInPaths;
  }

  public async fetchCriticalPointSet(criticalPointUuids:Array<string>):Promise<CriticalPointQueryResult> {
    const apiPath:string = `/api/admin/critical_points/select`;
    const httpBody:Object = {
      criticalPointUuids: criticalPointUuids
    }

    let result:CriticalPointQueryResult;

    try {
      const response:CriticalPointQueryResult = await this.backendService.callApi(apiPath, "POST", httpBody);
      result = response;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a kritikus pontok lekérdezése közben.");
    }

    return result;
  }


}

export type CriticalPointQueryResult = {
  criticalPoints:Array<CriticalPoint>;
  notExistCriticalPointUuids:Array<string>;
}