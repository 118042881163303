import { PracticeCityZoneEditorComponent } from './modules/practice-city-zone-editor/practice-city-zone-editor.component';
import { PlaygroundComponent } from './components/dashboard/content/special/playground/playground.component';
import { PracticePathCreateNewComponent } from './components/dashboard/content/practice/practice-paths/practice-path-create-new/practice-path-create-new.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { LoginComponent } from "./components/login/login.component";
import { WelcomeComponent } from "./components/dashboard/content/welcome/welcome.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { CategoriesComponent } from "./components/dashboard/content/actual-questionbank/categories/categories.component";
import { QuestionsComponent } from "./components/dashboard/content/actual-questionbank/questions/questions.component";
import { QuestiongroupsComponent } from "./components/dashboard/content/actual-questionbank/questiongroups/questiongroups.component";
import { RefreshCacheComponent } from "./components/dashboard/content/server/refresh-cache/refresh-cache.component";
import { StudentsComponent } from "./components/dashboard/content/users/students/students.component";
import { ChangelogComponent } from "./components/dashboard/content/actual-questionbank/changelog/changelog.component";
import { SettingsComponent } from "./components/dashboard/content/admin-menu/settings/settings.component";
import { FeedbacksComponent } from "./components/dashboard/content/users/feedbacks/feedbacks.component";
import { UserNotificationsComponent } from "./components/dashboard/content/special/user-notifications/user-notifications.component";
import { GlobalGiftsComponent } from "./components/dashboard/content/special/global-gifts/global-gifts.component";
import { MaintenanceComponent } from "./components/dashboard/content/server/maintenance/maintenance.component";
import { StudentLoggingComponent } from "./components/dashboard/content/special/student-logging/student-logging.component";
import { PracticePathsComponent } from "./components/dashboard/content/practice/practice-paths/practice-paths.component";
import { PracticeIconsComponent } from "./components/dashboard/content/practice/practice-icons/practice-icons.component";
import { UnavailablePathOnProductionGuardService } from "./services/guards/unavailable-path-on-production-guard.service";
import { RoleGuardService } from "./services/guards/role-guard.service";
import { AuthorizationGuardService } from "./services/common/authorization-guard.service";
import { UnavailableOnProductionComponent } from "./components/dashboard/content/unavailable-on-production/unavailable-on-production.component";
import { PracticeCitiesComponent } from "./components/dashboard/content/practice/practice-cities/practice-cities.component";
import { AnonymizerTasksComponent } from "./components/dashboard/content/practice/anonymizer-tasks/anonymizer-tasks.component";
import { VendorsComponent } from "./components/dashboard/content/users/vendors/vendors.component";
import { PersonalGiftsComponent } from "./components/dashboard/content/special/personal-gifts/personal-gifts.component";
import { AdminUsersComponent } from "./components/dashboard/content/admins/admin-users/admin-users.component";
import { PermissionsComponent } from "./components/dashboard/content/admins/permissions/permissions.component";
import { AdminRolesComponent } from "./components/dashboard/content/admins/admin-roles/admin-roles.component";
import { PermissionGroupsComponent } from "./components/dashboard/content/admins/permission-groups/permission-groups.component";
import { InstructorsComponent } from "./components/dashboard/content/users/instructors/instructors.component";
import { PracticePathMainDataEditorPageComponent } from './components/dashboard/content/practice/practice-paths/practice-path-main-data-editor-page/practice-path-main-data-editor-page.component';
import { PracticalModuleLogsPageComponent } from './components/dashboard/content/practice/practical-module-logs-page/practical-module-logs-page.component';

const routes: Routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
    children: [
      {
        path: "actual-questionbank",
        children: [
          {
            path: "categories",
            component: CategoriesComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
          {
            path: "questiongroups",
            component: QuestiongroupsComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
          {
            path: "questions",
            component: QuestionsComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
          {
            path: "changelog",
            component: ChangelogComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          }
        ],
        canActivate: [UnavailablePathOnProductionGuardService],
      },
      {
        path: "practice",
        children: [
          {
            path: "cities",
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
            children: [
              {
                path: "",
                component: PracticeCitiesComponent,
              },
              {
                path: ":cityUuid/zone-editor",
                children: [
                  { path: "", component: PracticeCityZoneEditorComponent },
                ]
              },
              {
                path: ":cityUuid/paths",
                children: [
                  { path: "list", pathMatch: "full", redirectTo: "" },
                  { path: "", component: PracticePathsComponent },
                  {
                    path: ":practicePathUuid/video-edit",
                    loadChildren: () =>
                      import(
                        "./modules/practice-path-video-editor-page/practice-path-video-editor-page.module"
                      ).then((m) => m.PracticePathVideoEditPageModule)
                  },
                  {
                    path: ":practicePathUuid/main-data-edit",
                    component: PracticePathMainDataEditorPageComponent
                  },
                  {
                    path: "create-new-practice-path",
                    component: PracticePathCreateNewComponent
                  },
                ]
              }
            ]
          },
          {
            path: "icons",
            component: PracticeIconsComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
          {
            path: "anonymizer-tasks",
            component: AnonymizerTasksComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
          {
            path: "mrvegas",
            loadChildren: () =>
              import(
                "./modules/mrvegas-metadata-viewer/mrvegas-metadata-viewer.module"
              ).then((m) => m.MrvegasMetadataViewerModule)
          },
          {
            path: "logs",
            component: PracticalModuleLogsPageComponent
          }
        ],
        canActivate: [UnavailablePathOnProductionGuardService],
      },
      {
        path: "users",
        children: [
          {
            path: "students",
            component: StudentsComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
          {
            path: "feedbacks",
            component: FeedbacksComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
          {
            path: "vendors",
            component: VendorsComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
          {
            path: "instructors",
            children: [
              {
                path: "",
                component: InstructorsComponent,
                canActivate: [
                  UnavailablePathOnProductionGuardService,
                  RoleGuardService,
                ],
              },
              {
                path: "applications",
                loadChildren: () =>
                import("./modules/classroom-applications-page/classroom-applications-page.module").then(
                  (m) => m.ClassroomApplicationsPageModule
                ),
              }
            ]
          }
        ],
        canActivate: [UnavailablePathOnProductionGuardService],
      },
      {
        path: "packages",
        children: [
          {
            path: "package-entities",
            loadChildren: () =>
              import("./modules/packages-page/packages-page.module").then(
                (m) => m.PackagesPageModule
              ),
              canActivate: [
                UnavailablePathOnProductionGuardService,
                RoleGuardService,
              ],
          },
          {
            path: "package-definitions",
            loadChildren: () =>
              import("./modules/package-definitions-page/package-definitions-page.module").then(
                (m) => m.PackageDefinitionsPageModule
              ),
              canActivate: [
                UnavailablePathOnProductionGuardService,
                RoleGuardService,
              ],
          }
        ]
      },
      {
        path: "server",
        children: [
          {
            path: "maintenance",
            component: MaintenanceComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
          {
            path: "refresh-cache",
            component: RefreshCacheComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
        ],
        canActivate: [UnavailablePathOnProductionGuardService],
      },
      {
        path: "special",
        children: [
          {
            path: "user-notifications",
            component: UserNotificationsComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
          {
            path: "global-gifts",
            component: GlobalGiftsComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
          {
            path: "personal-gifts",
            component: PersonalGiftsComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
          {
            path: "student-logging",
            component: StudentLoggingComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
          {
            path: "playground",
            component: PlaygroundComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
        ],
        canActivate: [UnavailablePathOnProductionGuardService],
      },
      {
        path: "admins",
        children: [
          {
            path: "admin-users",
            component: AdminUsersComponent,
            canActivate: [RoleGuardService],
          },
          {
            path: "permissions",
            component: PermissionsComponent,
            canActivate: [RoleGuardService],
          },
          {
            path: "permission-groups",
            component: PermissionGroupsComponent,
            canActivate: [RoleGuardService],
          },
          {
            path: "roles",
            component: AdminRolesComponent,
            canActivate: [RoleGuardService],
          },
        ],
        canActivate: [UnavailablePathOnProductionGuardService],
      },
      {
        path: "admin-menu",
        children: [
          {
            path: "settings",
            component: SettingsComponent,
            canActivate: [
              UnavailablePathOnProductionGuardService,
              RoleGuardService,
            ],
          },
        ],
      },
      {
        path: "unavailable-on-production",
        component: UnavailableOnProductionComponent,
      },
      { path: "", component: WelcomeComponent },
    ],
    canActivate: [AuthorizationGuardService],
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [AuthorizationGuardService]
  },
  { path: "", pathMatch: "full", redirectTo: "login" },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
