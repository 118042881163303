import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnChanges {
  @Input() icon:string;
  @Input() disabled:boolean = false;
  @Input() color:string = "#000000";
  @Input() backgroundColor:string = "#FFFFFF";
  @Input() disabledColor:string = "gray";
  @Input() disabledBackgroundColor:string = "lightgray";

  constructor(
    private elementRef:ElementRef<HTMLElement>
  ) { }

  ngOnInit() {
    this.elementRef.nativeElement.style.color = this.disabled ? this.disabledColor : this.color;
    this.elementRef.nativeElement.style.background = this.disabled ? this.disabledBackgroundColor : this.backgroundColor;
  }

  ngOnChanges(changes:SimpleChanges): void {
    if(changes.disabled) {
      if(!changes.disabled.previousValue && changes.disabled.currentValue) {
        this.elementRef.nativeElement.classList.add("disabled");
      } else if(changes.disabled.previousValue && !changes.disabled.currentValue) {
        this.elementRef.nativeElement.classList.remove("disabled");
      }

      this.elementRef.nativeElement.style.color = this.disabled ? this.disabledColor : this.color;
      this.elementRef.nativeElement.style.background = this.disabled ? this.disabledBackgroundColor : this.backgroundColor;
    }
  }
  
}
