<div class="main-container">
  <div class="header">
    <div style="flex: 1">
      <b>{{ data.city.city }}</b> | {{ data.practicePath.name }} |
      Útvonal:
      {{ data.practicePath.examPathNumber + 1 }}
    </div>

    <div>
      <button mat-dialog-close style="margin-right: 15px" mat-raised-button>
        Bezárás
      </button>

      <button
        (click)="onTapSave()"
        style="margin-right: 5px"
        mat-raised-button
        color="primary"
      >
        Mentés
      </button>
    </div>
  </div>

  <div class="content">
    <div *ngIf="videoPlayerController == null">
      Ehhez a videóhoz még nem töltöttél fel videót. A vizsga érintések pontok érintésének az
      ideje csak akkor állítható be, ha már van videó feltöltve az
      útvonalhoz
    </div>

    <div *ngIf="data.practicePath.examPathNumber == null">
      Az útvonalhoz nem tartozik vizsgatétel sorszám.
    </div>

    <div
      class="content-inner"
      *ngIf="
        videoPlayerController != null &&
        data.practicePath.examPathNumber != null
      "
    >
      <!-- Videó + térkép -->
      <div
        style="
          column-gap: 20px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          overflow: visible;
        "
      >
        <video-player [videoPlayerController]="videoPlayerController">
        </video-player>
        <google-map-extended
          style="overflow: hidden"
          (onReady)="onInitedGoogleMapController($event)"
        >
        </google-map-extended>
      </div>

      <div style="margin-top: 10px; font-size: 14px">
        Az időpont beállításra kattintva az adott vizsga érintési pont hozzárendelődik a
        videó jelenlegi pozíciója. Tehát egy adott vizsga érintési pont érintési időpontját
        úgy lehet megadni, hogy a videóban oda tekersz, ahova az érintési pont idejét
        szeretnéd beállítani. Használhatod a tekerésre a videó lejátszó tekerő
        sávját, vagy a térképet. Ahova kattintasz a térképen oda tekerődik a
        videó.
      </div>

      <div
        style="margin-top: 20px;padding-bottom:15px; overflow: auto; max-height: 100%"
        class="node-list-container"
      >
        <div
          *ngFor="
            let nodeUuid of data.city.cityNodeUuidsAssigmentToExamThesis[
              data.practicePath.examPathNumber
            ];
            let i = index
          "
          class="node-touch-row"
          [ngClass]="{
            'not-set-touch-for-node': getTouchMsForNode(i) == null
          }"
        >
          <span class="node-name-span">
            {{ getNodeFromUuid(nodeUuid).name }}
          </span>

          <div
            (click)="videoPlayerController.seekTo(getTouchMsForNode(i))"
            class="node-touch-ms-text"
          >
            {{ getTouchStringForNode(i) }}
          </div>

          <button (click)="onTapSetCurrentTimeToNode(i)" mat-raised-button>
            Időpont beállítása
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
