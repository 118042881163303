import { GoogleMapPosition } from 'src/app/classes/model/google-map-position';
import { PracticeVideo } from "./practice-video";

export type StopPoint = {
  stopPointUuid: string;
  focusSettings?: { x: number; y: number; radius: number };
  stopTimeInMs: number;
  isActive: boolean;
};

export class CriticalPointAssignment {
  constructor(
    public uuid: string,
    public criticalPointUuid: string,
    public stopPoints: StopPoint[]
  ) {}
}

export class PracticePath {
  uuid: string;
  name: string;
  cityUuid: string;
  criticalPointAssignments: Array<CriticalPointAssignment>;
  relativeVideoUrl?: string;
  video: PracticeVideo;
  originalVideoFileName: string;
  created: {
    adminEmail: string;
    timestamp: number;
  };
  autoIncrementId:number;
  editState: PathEditState;
  isFullPracticePath: boolean;
  audio: PracticePathAudio;
  nodeTouches?: { nodeUuid: string; touchMs: number }[]; // csak akkor van értéke, ha isFullExamPath igaz
  examPathNumber?: number; // csak akkor van értéke, ha isFullExamPath igaz
  path: Array<PathItem>;
  iconPaths: Array<IconPath>;
  timedIcons: Array<TimedIcon>;
  zoneUuids: string[] // Ezekhez a zónákhoz tartozik a videó (tartozhat simán többhöz is, ha van overlap 2 zóna között)
  isFree: boolean;
  pathStaticMapDarkImageUrl?: string;
  pathStaticMapLightImageUrl?: string;
}


export class PracticePathIcon {
  practiceIconUuid: string;
}

export class IconPath extends PracticePathIcon {
  uuid: string;
  path: Array<GoogleMapPosition>;
}

export class TimedIcon extends PracticePathIcon {
  uuid: string; // Maga a hozzárendelésnek az azonosítója
  startFrame: number;
  endFrame: number;
}

export class CriticalPoint {
  uuid?: string;
  coordinate: GoogleMapPosition;
  directionalAngle?: number;
  directionalAnglePoint?: GoogleMapPosition;
  title: string;
  description: string;
  isLocal: boolean;
  isVerified?: boolean;
  verificationLogs?: Array<CriticalPointVerificationLog>
  anchorPoint?: GoogleMapPosition;
  onlyAssignablePracticePathUuid?: string;
  audio?: {
    narrationForDescriptionUrl?: string;
  };
}

export class CriticalPointVerificationLog {
  newVerificationValue:boolean;
  verificationChangerAdminEmail:string;
  verificationChangerAdminUuid:string;
  reasonOfVerificationStatusChange: CriticalPointVerificationStatusChangeReason;
  timestamp:number;
}

export type CriticalPointVerificationStatusChangeReason = "explicit_verification_status_change"|"critical_point_updated";


export class FocusedCriticalPoint {
  uuid: string;
  shape: "CIRCLE" | "RECTANGLE";
  shapeData: RectangleShapeData | CircleShapeData;
  title: string;
  description: string;
}

export class RectangleShapeData {
  position: {
    top: number;
    left: number;
  };
  size: {
    width: number;
    height: number;
  };
}

export type PracticePathAudio = {
  narrationsDuringRecording:Array<PracticePathNarrationDuringRecording>;
}

export type PracticePathNarrationDuringRecording = {
  uuid:string;
  audioUrl:string;
  originalFileName:string;
  title?:string;
  created:{
    adminEmail:string,
    timestamp:number
  }
}

export class CircleShapeData {
  position: {
    top: number;
    left: number;
  };
  radius: number;
}

export class PathItem {
  millisecOfFrame: number;
  position: GoogleMapPosition;
}


export type PathEditState =
  | "UPLOADED"
  | "EDIT_STAGE"
  | "WAITING_FOR_VERIFICATION"
  | "WRONG_VIDEO"
  | "DONE";

export type DisplayedPathEditState = {
  key: PathEditState;
  displayedName: string;
  color: string;
};

export const pathEditStates: ReadonlyArray<DisplayedPathEditState> = [
  { key: "WRONG_VIDEO", displayedName: "Hibás videó, újra kell venni", color: "black" },
  { key: "UPLOADED", displayedName: "Frissen feltöltve", color: "red" },
  { key: "EDIT_STAGE", displayedName: "Szerkesztés alatt", color: "orange" },
  {
    key: "WAITING_FOR_VERIFICATION",
    displayedName: "Ellenőrzésre vár",
    color: "#349BEB",
  },
  { key: "DONE", displayedName: "Kész", color: "green" },
];

export type CriticalPointPositionInPath = {
  practicePathUuid:string;
  practicePathName:string;
  criticalPointPosition:number
};

export type PracticePathBaseInformations = Pick<
  PracticePath,
  "uuid"| "name" | "cityUuid" | "criticalPointAssignments" | "video" | "audio" | "created"  | "editState" |
  "pathStaticMapDarkImageUrl" | "pathStaticMapLightImageUrl" |
  "isFullPracticePath" | "autoIncrementId" | "nodeTouches" | "examPathNumber" | "zoneUuids" | "isFree"
>;
