import { Injectable } from '@angular/core';
import { HttpResponseData } from '../classes/http-communication';
import { LoggedStudent } from "../classes/model/logged-student";
import { Permission, PermissionString } from '../classes/model/permissions';
import { matchingPropertyPredicate } from '../functions/misc';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { DataCacherService } from './data-cacher-service';

@Injectable({
  providedIn: 'root'
})
export class StudentLoggingService implements DataCacherService {
  private loggedStudentsUrlFragment:string = "/api/admin/user/logged_users"
  private studentLoggingUrlFragment:string = "/api/admin/user/logging"
  private loggedStudents:Array<LoggedStudent>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.StudentsRead ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) {
    this.loggedStudents = new Array<LoggedStudent>();
  }

  public getLoggedStudentsArrayRef():ReadonlyArray<Readonly<LoggedStudent>> {
    return this.loggedStudents;
  }

  public async fetchLoggedStudents():Promise<Array<Readonly<LoggedStudent>>> {
    let loggedStudents:Array<Readonly<LoggedStudent>> = [];
    try {
      const response:HttpResponseData<Array<LoggedStudent>> = await this.backendService.callApi(this.loggedStudentsUrlFragment, "GET");
      loggedStudents = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a monitorozott felhasználól lekérdezése közben.");
    }

    return loggedStudents;
  }

  public async switchLoggingModeForStundent(loggedStudent:Partial<LoggedStudent>, isActive:boolean):Promise<void> {
    const httpBody:Object = {
      data: {
        userUuid: loggedStudent.uuid,
        switch: (isActive ? "ON" : "OFF")
      }
    };

    try {
      await this.backendService.callApi(this.studentLoggingUrlFragment, "POST", httpBody);
      loggedStudent.isActive = isActive;
      this.loggedStudents.replaceItems(matchingPropertyPredicate("uuid", loggedStudent.uuid), loggedStudent as LoggedStudent);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a monitorozási státusz módosítása közben.");
    }
  }

  public async isStudentUnderLogging(studentUuid:string):Promise<boolean> {
    try {
      this.loggedStudents = await this.fetchLoggedStudents();
    } catch(error:any) {
      throw error;
    }

    return this.loggedStudents.find(loggedStudent => loggedStudent.uuid === studentUuid) !== undefined;
  }


  public async loadDataIntoCache():Promise<void> {
    const loggedStudents:Array<Readonly<LoggedStudent>> = await this.fetchLoggedStudents();
    this.loggedStudents.replaceArrayElements(loggedStudents).forEach(
      (loggedStudent:LoggedStudent) => { loggedStudent.isActive = true; }
    );
  }

  public clearCachedData():void {
    this.loggedStudents.length = 0;
  }

  public getRequiredPermissionsForDataLoading(): ReadonlyArray<PermissionString> {
    return this.requiredPermissionsForDataLoading;
  }

  public getNameOfCachedData(): string {
    return "Tanulók";
  }
}
