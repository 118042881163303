import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/classes/custom-validators';
import { Permission } from 'src/app/classes/model/permissions';
import { Vendor, VendorType } from 'src/app/classes/model/vendor';

@Component({
  selector: 'app-vendor-data-form',
  templateUrl: './vendor-data-form.component.html',
  styleUrls: ['./vendor-data-form.component.scss']
})
export class VendorDataFormComponent implements OnInit {
  @Input() mode: "add" | "edit";
  @Input() defaultValues?: Pick<Vendor, "email" | "name" | "type" | "phoneNumber"> & { hasActiveSzamlazzHuConnection: boolean };
  @Input() isSzamlazzHuConnectionCheckboxEnabled: boolean;

  protected emailFC: FormControl<string>;
  protected nameFC: FormControl<string>;
  protected typeFC: FormControl<VendorType | "">;
  protected phoneNumberFC: FormControl<string>;
  protected hasActiveSzamlazzHuConnectionFC: FormControl<boolean>;

  private formGroup: FormGroup;

  vendorTypes: Array<{ name: string, displayedName: string }> = [
    { name: "instructor", displayedName: "Oktató" },
    { name: "school", displayedName: "Autósiskola" },
    { name: "other", displayedName: "Egyéb" }
  ];

  constructor() { }

  ngOnInit(): void {
    this.initalizeFormControls();
  }

  private initalizeFormControls(): void {
    // E-mail form control
    this.emailFC = new FormControl<string>(
      this.defaultValues?.email ?? "",
      [Validators.required, CustomValidators.email]
    );

    // Name form control
    this.nameFC = new FormControl<string>(
      this.defaultValues?.name ?? "",
      [Validators.required]
    );

    // Vendor type form control
    this.typeFC = new FormControl<VendorType | "">(
      this.defaultValues?.type ?? "",
      [Validators.required]
    );

    // Phone number form control
    this.phoneNumberFC = new FormControl(
      this.defaultValues?.phoneNumber ?? "",
      [Validators.pattern(new RegExp("^(\\+)([0-9]{10,11})$"))]
    );

    // Can send payment request form control
    this.hasActiveSzamlazzHuConnectionFC = new FormControl<boolean>(
      this.defaultValues?.hasActiveSzamlazzHuConnection,
    );
    if (!this.isSzamlazzHuConnectionCheckboxEnabled) {
      this.hasActiveSzamlazzHuConnectionFC.disable()
    }

    // Create the from group
    this.formGroup = new FormGroup({
      emailFC: this.emailFC,
      nameFC: this.nameFC,
      typeFC: this.typeFC,
      phoneNumberFC: this.phoneNumberFC,
      hasActiveSzamlazzHuConnectionFC: this.hasActiveSzamlazzHuConnectionFC
    });

    // If the mode is edit, disable the immutable fields
    if (this.mode === "edit") {
      this.emailFC.disable();
      this.nameFC.disable();
      this.typeFC.disable();
    }
  }

  public isFormValid(): boolean {
    return this.formGroup.valid;
  }

  hasVendorSentSzamlazzHuRequest(): boolean {
    return this.hasActiveSzamlazzHuConnectionFC.value;
  }

  public getFormData(): VendorDataFormResult {
    return {
      email: this.emailFC.value,
      name: this.nameFC.value,
      type: this.typeFC.value as VendorType,
      phoneNumber: this.phoneNumberFC.value,
      hasActiveSzamlazzHuConnection: this.isSzamlazzHuConnectionCheckboxEnabled ? this.hasActiveSzamlazzHuConnectionFC.value : undefined
    };
  }

}

export type VendorDataFormResult =
  Pick<Vendor, "email" | "name" | "type" | "phoneNumber">
  & { hasActiveSzamlazzHuConnection?: boolean };
