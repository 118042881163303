<div style = "display: flex; column-gap: 20px">
     <mat-form-field appearance='outline' style = "width: 100%;">
        <mat-label>Hangfelvétel címe</mat-label>
        <input
            matInput
            [formControl] = "titleFC"
        />
    </mat-form-field>
    <button
        (click) = "onDeleteItemButtonClick()"
        class = "remove-button"
    >
        <mat-icon> delete </mat-icon>
    </button>
</div>
<div style = "margin-bottom: 10px; font-size: 0.9rem;">
    <div style = "font-weight: 500;">
        Eredeti fájlnév:
    </div>
    <div>
        {{ audioFile.name }}
    </div>
</div>
<audio controls = "true" preload = "metadata" [src] = "audioUrl | safe: 'resourceUrl'">
    The audio tag is not supported by this browser
</audio>
