import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Package } from 'src/app/classes/model/packages/Package';
import { Vendor } from 'src/app/classes/model/vendor';
import { CreateVendorGiftDialogComponent } from '../create-vendor-gift-dialog/create-vendor-gift-dialog.component';
import { PackageGrouperService } from '../../services/package-grouper.service';
import { GroupedPackages } from '../../models/grouped-packages';
import { PermissionService } from 'src/app/services/common/permission.service';
import { Permission } from 'src/app/classes/model/permissions';
import { VendorService } from 'src/app/services/vendor.service';

@Component({
  selector: 'app-vendor-gifts-tab',
  templateUrl: './vendor-gifts-tab.component.html',
  styleUrls: ['./vendor-gifts-tab.component.scss']
})
export class VendorGiftsTabComponent implements OnInit {
  @Input() vendor:Vendor;
  @Input() vendorPackages: Array<Package>;
  groupedGiftedPackagesGroups:Array<GroupedPackages> = [];

  constructor(
    private dialogService:MatDialog,
    private packageGrouperService:PackageGrouperService,
    private permissionService:PermissionService,
    private vendorService: VendorService
  ) { }

  ngOnInit(): void {
    // Calculate the gift groups by their bulk creation
    this.groupedGiftedPackagesGroups = this.calculateGroupedGiftedPackagesGroups(this.vendorPackages);
  }

  /**
   * Groups the gifted packages by their bulk creation. If the gift was not in a bulk creation originally
   * it will be represented in a standalone group.
   */
  private calculateGroupedGiftedPackagesGroups(packages:Array<Package>):Array<GroupedPackages> {
    // Filter out the gifted packages packages
    const giftedPackages:Array<Package> = packages.filter(
      packageElement => packageElement.packageType === "GiftedPackage"
    );

    // Group the packages
    const groupedGiftedPackages:Array<GroupedPackages> = this.packageGrouperService.groupPackages(giftedPackages);

    // Sort the groups by their creation timestamp
    groupedGiftedPackages.sort((group1, group2) => { return group2.creationTimestamp - group1.creationTimestamp });

    // Return the result
    return groupedGiftedPackages;
  }

  /**
   * Opens a dialog to create a gift for a vendor.
   */
  public openCreateVendorGiftDialog():void {
    const dialogRef:MatDialogRef<CreateVendorGiftDialogComponent, void> = CreateVendorGiftDialogComponent.open(
      this.dialogService,
      { vendor: this.vendor }
    );

    // After the dialog closes, recalculate the bulk gifts
    const dialogCloseSubscription:Subscription = dialogRef.afterClosed().subscribe(
      async () => {
        dialogCloseSubscription.unsubscribe();
        console.log("fetch new packages");
        const newPackages = await this.vendorService.fetchVendorPackages(this.vendor.uuid);
        // Jelenleg így oldjuk meg, hogy a kinti vendorPackages is frissüljön
        // refaktorálni kellene a tab komponenseket, hogy legyen egy service amit a viszonteladó details tap használ
        // és abban legyenek globális objectek amikhez hozzáférhetnek a tabok
        // és ha az egyik tab letölt egy új adatot, akkor azt a másik tab is megkapja 
        this.vendorPackages.length = 0;
        for(const newPackage of newPackages) this.vendorPackages.push(newPackage);

        this.groupedGiftedPackagesGroups = this.calculateGroupedGiftedPackagesGroups(this.vendorPackages);
      }
    );
  }

  /**
   * Determines that the current admin user has the vendor gifting permission.
   * 
   * @returns true if the admin has the permission, false otherwise
   */
  protected hasAdminVendorGiftingPermission():boolean {
    return this.permissionService.isLoggedUserHasPermission(Permission.VendorGifting);
  }
}



