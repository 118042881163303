import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Category } from 'src/app/classes/model/category';
import { CategoryEditDialogComponent } from '../category-edit-dialog/category-edit-dialog.component';
import { QuestionGroup } from 'src/app/classes/model/question-groups';
import { CategoryQuestionPositionEditDialogComponent } from '../category-question-position-edit-dialog/category-question-position-edit-dialog.component';
import { DialogMode } from 'src/app/classes/misc';
import { matchingPropertyPredicate } from 'src/app/functions/misc';

/**
 * @description This component represents a category card in the categories view.
*/
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  /** The category which will be described by the card. */
  @Input() category:Category;
  /** Array reference to all question groups. */
  @Input() questionGroups:ReadonlyArray<Readonly<QuestionGroup>>;

  constructor(public dialog:MatDialog) { }

  ngOnInit(): void { }

  openModifyPositionsDialog():void {
    this.dialog.open(CategoryQuestionPositionEditDialogComponent, {
      data: {
        category: this.category
      },
      disableClose: true,
      height: "80vh",
      width: "800px"
    });
  }

  /**
   * Opens a dialog to modify the corresponding category.
   * 
   * @returns {void} nothing
  */
  openModifyCategoryDialog():void {
    this.dialog.open(CategoryEditDialogComponent, {
      data: {
        dialogMode: DialogMode.Edit,
        category: this.category
      },
      disableClose: true
    });
  }

  /**
   * Returns with the question group's name with the given uuid. If there is no such question group, null will be returned.
   * 
   * @param {string} questionGroupUuid - the question group's uuid
   * 
   * @returns {string} the question group's name
  */
  getQuestionGroupName(questionGroupUuid:string):string {
    let questionGroup:Readonly<QuestionGroup> = this.questionGroups.find(matchingPropertyPredicate("uuid", questionGroupUuid));
    return questionGroup?.name ?? "";
  }
}
