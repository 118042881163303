<div class = "dialog-header">
    <div class = "dialog-title">
        Új dokumentumok feltöltése
    </div>
    <button class = "close-button" mat-dialog-close>
        <mat-icon> close </mat-icon>
    </button>
</div>
<div class = "name-input-container">
     <mat-form-field appearance='outline' style = "width: 100%;">
        <mat-label>Név</mat-label>
        <input matInput [formControl] = "nameFC" />
        <mat-error>Kötelező mező!</mat-error>
    </mat-form-field>
    <input #fileInput type = "file" multiple style = "display: none;" />
</div>
<div class = "pending-files">
    <table class = "pending-files-table">
        <tr *ngFor = "let file of pendingFiles; let i = index">
            <td style = "width: 24px;"> 📃 </td>
            <td> {{ file.name }} </td>
            <td style = "width: 80px;"> {{ file.size | fileSize }} </td>
            <td style = "width: 20px;">
                <mat-icon
                    class = "delete-icon"
                    (click) = "onDeleteIconClick(i)"
                >
                    delete
                </mat-icon>
            </td>
        </tr>
    </table>

    <div *ngIf = "pendingFiles.length === 0" class = "no-pending-files">
        Jelenleg nem választottál ki még egyetlen fájlt sem!
    </div>
</div>
<mat-dialog-actions align = "end">
    <button
        class = "action-button add-files"
        (click) = "onAddFilesButtonClick()"
    >
        <mat-icon> add </mat-icon>
        Fájlok hozzáadása
    </button>
    <button
        class = "action-button upload-documents"
        (click) = "onUploadDocumentsButtonClick()"
        [disabled] = "!nameFC.valid || isUploadInProgress || pendingFiles.length < 1"
        [class.button-loading] = "isUploadInProgress"
    >
        <mat-icon> upload </mat-icon>
        Feltöltés
    </button>
</mat-dialog-actions>
