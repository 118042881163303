import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalGift } from "src/app/classes/model/gift";
import { GlobalGiftService } from "src/app/services/global-gift.service";
import { CreateGlobalGiftDialogComponent } from './create-global-gift-dialog/create-global-gift-dialog.component';

@Component({
  selector: 'app-global-gifts',
  templateUrl: './global-gifts.component.html',
  styleUrls: ['./global-gifts.component.scss']
})
export class GlobalGiftsComponent implements OnInit {

  isContentLoading:boolean;

  activeGlobalGifts:Array<Readonly<GlobalGift>> = [];
  inactiveGlobalGifts:Array<Readonly<GlobalGift>> = [];

  constructor(
    private globalGiftService:GlobalGiftService,
    public dialog:MatDialog
  ) { }

  public ngOnInit():void {
    this.loadGlobalGifts();
  }
    
  public openCreateGiftDialog():void {
    this.dialog.open(CreateGlobalGiftDialogComponent);
  }

  public async handleReloadButtonClick():Promise<void> {
    await this.loadGlobalGifts();
  }

  private async loadGlobalGifts():Promise<void> {
    this.isContentLoading = true;
    this.activeGlobalGifts.length = 0;
    this.inactiveGlobalGifts.length = 0;

    try {
      const globalGifts:ReadonlyArray<GlobalGift> = await this.globalGiftService.fetchGlobalGifts();
      this.activeGlobalGifts = globalGifts.filter(globalGift => globalGift.isActive);
      this.inactiveGlobalGifts = globalGifts.filter(globalGift => !globalGift.isActive);
    } catch(error:any) {}

    this.isContentLoading = false;
  }

}
