<div class = "title">
    Ajándék létrehozása
</div>

<div class = "reason-form-field">
        <mat-form-field appearance='outline' style = "width: 100%">
        <mat-label>Ajándék oka</mat-label>
        <input matInput [formControl] = "reasonFC" />
    </mat-form-field>
</div>
<div class = "message-form-field">
        <mat-form-field appearance='outline' style = "width: 100%">
        <mat-label>Leírás</mat-label>
        <input matInput [formControl] = "messageFC" required />
        <mat-hint> Ezt látja a viszonteladó. </mat-hint>
        <mat-error *ngIf = "messageFC.hasError('required')">Kötelező mező! (Ezt látja a viszonteladó)</mat-error>
    </mat-form-field>
</div>
<div class = "package-form-field">
    <app-package-definition-selector
        [formControl] = "packageDefinitionFC"
        ngDefaultControl
    ></app-package-definition-selector>
</div>
<div class = "quantity-form-field">
        <mat-form-field appearance='outline' style = "width: 100%">
        <mat-label>Mennyiség</mat-label>
        <input matInput [formControl] = "quantityFC" />
    </mat-form-field>
</div>

<div style = "text-align: right;">
    <button mat-raised-button mat-dialog-close style = "margin-right: 20px;">
        Bezár
    </button>
    <button
        mat-raised-button
        color = "primary"
        [disabled] = "giftFG.invalid || giftCreatingInProgress"
        (click) = "handleCreateGiftButtonClick()"
        [class.button-loading] = "giftCreatingInProgress"
    >
        Létrehozás
    </button>
</div>

