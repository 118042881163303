import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotifierService } from 'src/app/services/common/notifier-service.service';
import { CustomValidators } from 'src/app/classes/custom-validators';
import { Instructor } from 'src/app/classes/model/instructor';
import { InstructorService } from 'src/app/services/instructor.service';

@Component({
  selector: 'app-create-instructor-dialog',
  templateUrl: './create-instructor-dialog.component.html',
  styleUrls: ['./create-instructor-dialog.component.scss']
})
export class CreateInstructorDialogComponent implements OnInit {

  emailFC:FormControl;
  lastNameFC:FormControl;
  firstNameFC:FormControl;
  phoneNumberFC:FormControl;

  registrateAsVendor:boolean = false;

  formGroup:FormGroup;

  vendorTypes:Array<{ name:string, displayedName:string }> = [
    { name: "instructor", displayedName: "Oktató" },
    { name: "school", displayedName: "Autósiskola" },
    { name: "other", displayedName: "Egyéb" }
  ];

  constructor(
    private instructorService:InstructorService,
    private notifierService:NotifierService,
    private dialogRef:MatDialogRef<CreateInstructorDialogComponent>
  ) { }

  ngOnInit(): void {
    this.emailFC = new FormControl("", [ Validators.required, CustomValidators.email ]);
    this.lastNameFC = new FormControl("", [ Validators.required ]);
    this.firstNameFC = new FormControl("", [ Validators.required ]);
    this.phoneNumberFC = new FormControl("", [ Validators.pattern(new RegExp("^(\\+)([0-9]{10,11})$")) ]);

    this.formGroup = new FormGroup({
      emailFC: this.emailFC,
      lastNameFC: this.lastNameFC,
      firstNameFC: this.firstNameFC,
      phoneNumberFC: this.phoneNumberFC
    });
  }

  public async handleRegistrateInstructorButtonClick():Promise<void> {
    const instructorFields:Partial<Instructor> = {
      email: this.emailFC.value,
      lastName: this.lastNameFC.value,
      firstName: this.firstNameFC.value
    };

    if(this.phoneNumberFC.value) {
      instructorFields.phoneNumber = this.phoneNumberFC.value;
    }

    try {
      await this.instructorService.registrateInstructor(instructorFields, this.registrateAsVendor);
      this.dialogRef.close();
      this.notifierService.notify("success", "Sikeres classroom oktató létrehozás");
    } catch(error:any) {
      if(error.error?.error === "INSTRUCTOR_ALREADY_EXISTS") {
        this.notifierService.notify("error", "A classroom oktató már regisztrálva van ezzel az e-mail címmel.");
      }
    }
  }

}
