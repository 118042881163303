<div #messageEditor class = "message-editor" contenteditable>

</div>
<div class = "message-actions">
    <div
        class = "message-action"
        (click) = "addCommentToEntity()"
    >
        <span class = "material-icons">
            send
        </span>
    </div>
</div>