<app-page-header
    title = "Szerver cache frissítése"
    subtitle = "A szerverhez tartozó cache-elt adatokat frissítheted itt."
    style = "margin-bottom: 20px;"
></app-page-header>

<div style = "text-align: center;">
    <button mat-mini-fab color = "primary" (click) = "refreshCache()">
        <mat-icon> refresh </mat-icon>
    </button>
    <div style = "margin-top: 10px;">
        Cache frissítése
    </div>
</div>


<div id = "refresh-cache-log-wrapper">
    <app-logger [logKey] = "logKey"></app-logger>
</div>
