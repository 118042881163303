export class AudioPlayerService{
  private audio = new Audio();
  public isFirstPlayDone: boolean = false;

  constructor(){

  }


  play(url: string, volume: number = 1.0){
    this.audio.src = url;
    this.audio.load();
    this.audio.volume = volume;
    this.audio.play();

    this.isFirstPlayDone = true;
  }

  cancelCurrentPlaying(){
    this.audio.pause();
  }
}
