import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Instructor } from 'src/app/classes/model/instructor';
import { InstructorService } from 'src/app/services/instructor.service';
import { CreateInstructorDialogComponent } from './create-instructor-dialog/create-instructor-dialog.component';

@Component({
  selector: 'app-instructors',
  templateUrl: './instructors.component.html',
  styleUrls: ['./instructors.component.scss']
})
export class InstructorsComponent implements OnInit {

  instructors:ReadonlyArray<Instructor> = [];

  isContentLoading:boolean = false;

  constructor(
    private instructorService:InstructorService,
    private dialogService:MatDialog
  ) { }

  public ngOnInit():void {
    this.instructors = this.instructorService.getInstructorsArrayRef();
    this.loadInstructors();
  }

  private async loadInstructors():Promise<void> {
    this.isContentLoading = true;

    try {
      await this.instructorService.loadDataIntoCache();
    } catch(error:any) {}

    this.isContentLoading = false;
  }

  public handleAddIstructorButtonClick():void {
    this.dialogService.open(
      CreateInstructorDialogComponent,
      {
        disableClose: true
      }
    );
  }

}
