<div class = "vendor-datas">
    <div class = "vendor-data">
        <div class = "vendor-data-name">
            UUID:
        </div>
        <div class = "vendor-data-info">
            {{ vendor.uuid }}
        </div>
    </div>
    <div class = "vendor-data">
        <div class = "vendor-data-name">
            Név:
        </div>
        <div class = "vendor-data-info">
            {{ vendor.name }}
        </div>
    </div>
    <div class = "vendor-data">
        <div class = "vendor-data-name">
            Email:
        </div>
        <div class = "vendor-data-info">
            {{ vendor.email }}
        </div>
    </div>
    <div class = "vendor-data">
        <div class = "vendor-data-name">
            Telefonszám:
        </div>
        <div *ngIf = "vendor.phoneNumber" class = "vendor-data-info">
            {{ vendor.phoneNumber }}
        </div>
        <div *ngIf = "!vendor.phoneNumber">
            Nincs megadva
        </div>
    </div>
    <div>
        <ng-container *ngIf = "_hasActiveSzamlazzHuConnection(); then hasActiveSzamlazzHuConnection; else hasNotActiveSzamlazzHuConnection"></ng-container>
        <ng-template #hasActiveSzamlazzHuConnection>
            <span> ✅ Van aktív számlázz.hu kapcsolata </span>
        </ng-template>
        <ng-template #hasNotActiveSzamlazzHuConnection>
            <span> ❌ Nincs aktív számlázz.hu kapcsolata </span>
        </ng-template>
    </div>
    <div>
        <ng-container *ngIf = "vendor.hasContract; then hasContract; else hasNoContract"></ng-container>
        <ng-template #hasContract>
            <span> ✅ A viszonteladó szerződtetve van </span>
        </ng-template>
        <ng-template #hasNoContract>
            <span> ❌ A viszonteladó nincs szerződtetve </span>
        </ng-template>
    </div>
</div>
<div>
    <button
        mat-raised-button
        color = "primary"
        (click) = "onEditButtonClick()"
        [disabled] = "!hasAdminVendorWritePermission()"
        [matTooltip] = "'Nincs jogod szerkeszteni a viszonteladó alapadatait.'"
        [matTooltipDisabled] = "hasAdminVendorWritePermission()"
    >
        <mat-icon> edit </mat-icon>
        Módosítás
    </button>
</div>