<!-- Header -->
<div class="student-details-dialog-header">
  <div style="font-size: 24px; font-weight: bold;"> Tanuló részletek </div>
  <div style="justify-self: end; display: grid; grid-template-columns: 1fr 1fr;">
    <button mat-raised-button color="error" (click)="deleteUser()" [disabled]="isContentLoading">
      Személyes adatok törlése
    </button>
    <button mat-raised-button (click)="switchLogging(!isStudentUnderLogging)" style="margin-left: 20px;"
      [disabled]="isContentLoading" cdkFocusInitial>
      {{ isStudentUnderLogging ? "Logolás kikapcsolása" : "Logolás bekapcsolása" }}
    </button>
  </div>
</div>


<ng-container *ngIf="isContentLoading; then contentLoading; else contentReady"></ng-container>

<ng-template #contentLoading>
  <mat-spinner style="margin: 100px auto;"></mat-spinner>
</ng-template>

<ng-template #contentReady>
  <!-- General info -->
  <div class="student-details-section">
    <div class="student-details-section-title"> Általános adatok </div>
    <div class="student-details-general">
      <!-- First row -->
      <div class="student-details-data-title">
        UUID:
      </div>
      <div>
        {{ student.uuid }}
      </div>
      <div class="student-details-data-title">
        Nem:
      </div>
      <div>
        <span *ngIf="student.gender === ''" class="student-details-no-data"> Nincs megjeleníthető nem </span>
        <span *ngIf="student.gender !== ''"> {{ student.gender === "male" ? "Férfi" : "Nő" }} </span>
      </div>

      <!-- Second row -->
      <div class="student-details-data-title">
        Név:
      </div>
      <div>
        <span *ngIf="student.fullname === ''" class="student-details-no-data"> Nincs megjeleníthető név </span>
        {{ student.fullname }}
      </div>
      <div class="student-details-data-title">
        Születési év:
      </div>
      <div>
        <span *ngIf="student.birthdayYear === ''" class="student-details-no-data"> Nincs megjeleníthető születési év
        </span>
        {{ student.birthdayYear }}
      </div>

      <!-- Third row -->
      <div class="student-details-data-title">
        E-mail:
      </div>
      <div>
        <span *ngIf="student.email === ''" class="student-details-no-data"> Nincs megjeleníthető e-mail </span>
        {{ student.email }}
      </div>
      <div class="student-details-data-title">
        Város:
      </div>
      <div>
        <span *ngIf="student.city === ''" class="student-details-no-data"> Nincs megjeleníthető város </span>
        {{ student.city }}
      </div>
    </div>
    <br />
    <div class="student-details-general">
      <div class="student-details-data-title">
        Regisztrált:
      </div>
      <div>
        {{ student.registrationDate | date: "YYYY. MM. dd. HH:mm:ss" }} ({{ student.registratedWith }})
      </div>
      <div class="student-details-data-title">
        Utolsó belépés:
      </div>
      <div>
        {{ student.lastLoginDate | date: "YYYY. MM. dd. HH:mm:ss" }}
      </div>
    </div>
    <br />
    <div class="student-details-general">
      <div class="student-details-data-title">
        Forgalmi:
      </div>
      <div>
        <span *ngIf="practiceAccessTimestamp"> {{ practiceAccessTimestamp | date: "YYYY. MM. dd. HH:mm:ss" }} </span>
        <span *ngIf="!practiceAccessTimestamp"> - </span>
        <span *ngIf="!isAccessActive(practiceAccessTimestamp)"> (nem aktív) </span>
      </div>
      <div class="student-details-devices" (click)="openStudentDevicesDialog()">
        <div class="devices-button">
          <span class="material-icons"> phone_iphone </span>
          Eszközök
        </div>

      </div>
      <div></div>
      <div class="student-details-data-title">
        Elméleti:
      </div>
      <div>
        <span *ngIf="theoryAccessTimestamp"> {{ theoryAccessTimestamp | date: "YYYY. MM. dd. HH:mm:ss" }} </span>
        <span *ngIf="!theoryAccessTimestamp"> - </span>
        <span *ngIf="!isAccessActive(theoryAccessTimestamp)"> (nem aktív) </span>
      </div>

    </div>
    <div class="student-details-general">
      <div class="student-details-data-title"> Vásárlói ID: </div>
      <div>
        {{ customerId }}
      </div>
      <div></div>
      <div></div>
    </div>
    
    

    <div style="margin-top:20px;" class="student-details-general">
      <div class="student-details-data-title">
        Elméleti (sub):
      </div>
      <div>
        <span *ngIf="theoryAccessTimestampForSubscription"> {{ theoryAccessTimestampForSubscription | date: "YYYY. MM. dd. HH:mm:ss" }} </span>
        <span *ngIf="!theoryAccessTimestampForSubscription"> - </span>
        <span *ngIf="!isAccessActive(theoryAccessTimestampForSubscription)"> (nem aktív) </span>
      </div>
    </div>

    <div class="student-details-general">
      <div class="student-details-data-title">
        Forgalmi (sub):
      </div>
      <div>
        <span *ngIf="practiceAccessTimestampForSubscription"> {{ practiceAccessTimestampForSubscription | date: "YYYY. MM. dd. HH:mm:ss" }} </span>
        <span *ngIf="!practiceAccessTimestampForSubscription"> - </span>
        <span *ngIf="!isAccessActive(practiceAccessTimestampForSubscription)"> (nem aktív) </span>
      </div>
    </div>

  </div>

  <!-- Redeemed packages -->
  <div class="student-details-section">
    <div class="student-details-section-title"> Beváltott csomagok ({{ redeemedPackages.length }}) </div>
    <div *ngIf="redeemedPackages.length !== 0">
      <div class="student-details-redeemed-package-item" style="font-weight: 500">
        <div> </div>
        <div> Beváltva </div>
        <div> Forrás </div>
        <div> Csomag neve </div>
      </div>
      <div class="student-details-redeemed-packages-container">
        <div *ngFor="let redeemedPackage of redeemedPackages; let i = index"
          class="student-details-redeemed-package-item">
          <div> {{ i + 1 }}. </div>
          <div> {{ redeemedPackage.redemptionInformation.redemptionTimestamp | date: "YYYY. MM. dd. HH:mm:ss" }} </div>
          <div>
            <img [src]="'assets/emojis/' + packageSource[redeemedPackage.ownerVendorUuid ? 'vendor' : 'web']"
              class="line-emoji" />
          </div>
          <div> {{ redeemedPackage.displayedName }} </div>
        </div>
      </div>
    </div>
    <div *ngIf="redeemedPackages.length === 0" class="student-details-no-elements">
      A felhasználó nem rendelkezik beváltott csomaggal.
    </div>
  </div>

  <!-- Gifts -->
  <div class="student-details-section">
    <div class="student-details-section-title">
      Ajándékok ({{ studentGifts.length }})
      <span class="student-fillout-mini-add" (click)="openCreateGiftDialog()"> + </span>
    </div>
    <div *ngIf="studentGifts.length !== 0">
      <div class="student-details-gift-item" style="font-weight: 500">
        <div> </div>
        <div> Kiosztva </div>
        <div> Forrás </div>
        <div> Átvéve </div>
        <div> Csomagnév </div>
        <div> Leírás </div>
      </div>
      <div class="student-details-gifts-container">
        <div *ngFor="let gift of studentGifts; let i = index" class="student-details-gift-item">
          <div> {{ i + 1 }}. </div>
          <div> {{ gift.packageCreationTimestamp | date: "YYYY. MM. dd. HH:mm:ss" }} </div>
          <div> <img [src]="'assets/emojis/' + giftOriginEmojiMap[gift.giftOrigin]" class="line-emoji"> </div>
          <div>
            <span class="material-icons" *ngIf="!isGiftPending(gift)" style="font-size: 16px;">
              done
            </span>
          </div>
          <div> {{ gift.displayedName | prefixWithDots: 40 }} </div>
          <div> {{ gift.giftInfo.message | prefixWithDots: 40 }} </div>
        </div>
      </div>
    </div>
    <div *ngIf="studentGifts.length === 0" class="student-details-no-elements"> A felhasználó nem rendelkezik
      ajándékkal. </div>
  </div>

  <!-- Theory activity -->
  <div class="student-details-section">
    <div class="student-details-section-title"> Kitöltési statisztika </div>
    <div style="display: grid; grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); margin-bottom: 10px;">
      <div *ngFor="let category of categories">
        <div style="font-weight: 500; margin-bottom: 5px;"> {{ category.name }} </div>
        <div style="padding-left: 5px;"> Gyakorlás: {{ activity.categories[category.uuid].practice }} </div>
        <div style="padding-left: 5px;"> Vizsga: {{ activity.categories[category.uuid].exam }} </div>
        <div style="padding-left: 5px;"> Tematikus: {{ activity.categories[category.uuid].thematic }} </div>
      </div>
    </div>
  </div>

  <!-- Fillouts -->
  <div class="student-details-section">
    <div class="student-details-section-title"> Kitöltések ({{ student.histories.length }}) </div>
    <div *ngIf="student.histories.length !== 0">
      <div class="student-details-fillout-item" style="font-weight: 500">
        <div> </div>
        <div> Időpont </div>
        <div> Kategória </div>
        <div> Típus </div>
        <div> Eredmény </div>
      </div>
      <div class="student-details-fillouts-container">
        <div *ngFor="let fillout of student.histories; let i = index" class="student-details-fillout-item"
          (click)="openDetailsForFillout(fillout.uuid, i)">
          <div> {{ i + 1 }}. </div>
          <div> {{ fillout.fillDateTimeStamp | date: "YYYY. MM. dd. HH:mm:ss" }} </div>
          <div> {{ fillout.categoryName }} </div>
          <div> {{ getFilloutTypeName(fillout) }} </div>
          <div> {{ fillout.score }} / {{ fillout.maxPoint }} </div>
        </div>
      </div>
    </div>
    <div *ngIf="student.histories.length === 0" class="student-details-no-elements"> A felhasználó nem rendelkezik
      kitöltéssel. </div>
  </div>
</ng-template>