<ng-template [ngIf] = "selectedIndex !== null">
    <div class = "video-container">
        <video
            *ngIf = "anonymizedVideos[selectedIndex]?.videoUrl != null"
            class = "video"
            [src] = "anonymizedVideos[selectedIndex]?.videoUrl"
            controls
        ></video>
        <div class = "video-progress-spinner-container" *ngIf = "anonymizedVideos[selectedIndex]?.videoUrl == null">
            <mat-spinner></mat-spinner>
            <span *ngIf = "anonymizedVideos[selectedIndex]?.videoUrl == null" class = "video-progress-value">
                {{ anonymizerTask?.progress }}%
            </span>
        </div>
    </div>
    <div *ngIf = "anonymizedVideos.length > 0" class = "anonymized-videos-list">
        <table class = "anonymized-videos-table">
            <thead>
                <tr class = "anonymized-videos-table-header">
                    <td class = "state">
                        Állapot
                    </td>
                    <td class = "added-on">
                        Létrehozva
                    </td>
                    <td class = "logs-cell">
                        <!-- Logs -->
                    </td>
                    <td class = "download-cell">
                        <!-- Download -->
                    </td>
                    <td class = "finalize-cell">
                        <!-- Finalize -->
                    </td>
                    <td class = "deleted-cell">
                      <!-- deleted -->
                    </td>
                </tr>
            </thead>
        </table>
        <table class = "anonymized-videos-table">
            <tbody>
                <tr
                    *ngFor = "let anonymizedVideo of anonymizedVideos; let i = index"
                    class = "anonymized-videos-table-data-row"
                    [ngClass] = "{ selected: selectedIndex === i }"
                    (click) = "selectIndex(i)"
                >
                    <td class = "state">
                        <span *ngIf = "anonymizedVideo.isAnonymizedTaskFinished" class = "material-icons" style = "color: #178b1d">
                            check_circle
                        </span>

                        <span *ngIf = "!anonymizedVideo.isAnonymizedTaskFinished" class = "material-icons" >
                            <mat-spinner [diameter] = "20"></mat-spinner>
                        </span>
                    </td>
                    <td class = "added-on">
                        {{ anonymizedVideo.creationTimestamp | date: "YYYY-MM-dd HH:mm" }}
                    </td>
                    <td class = "logs-cell">
                        <div
                            class = "anonymized-video-table-button logs"
                            (click) = "openLogs(i)"
                        >
                            <span class = "material-icons" >
                                list_alt
                            </span>
                            Logok
                        </div>

                    </td>
                    <td class = "download-cell">
                        <div
                            class = "anonymized-video-table-button download"
                            (click) = "download($event, i)"
                            [class.disabled] = "!anonymizedVideo.isAnonymizedTaskFinished"
                        >
                            <span class = "material-icons" >
                                download
                            </span>
                            Letöltés
                        </div>
                    </td>
                    <td class = "finalize-cell">
                        <div
                            class = "anonymized-video-table-button finalize"
                            (click) = "finalize(i)"
                            [class.button-loading] = "promotingIndex === i"
                            [class.disabled] = "promotingIndex === i || !anonymizedVideo.isAnonymizedTaskFinished"
                        >
                            <span class = "material-icons">
                                rocket
                            </span>
                            Véglegesítés
                        </div>
                    </td>
                    <td class = "deleted-cell">
                      <div
                          *ngIf="anonymizedVideo.isPermanentDeleted"
                      >
                        <span>
                          Törölve
                          <br>
                          {{anonymizedVideo.permanentDeletionInformation.timestamp | date}}
                          {{anonymizedVideo.permanentDeletionInformation.reason}}
                        </span>
                      </div>
                      <div  *ngIf="!anonymizedVideo.isPermanentDeleted">
                        <div
                            class = "anonymized-video-table-button delete"
                            (click) = "deleteAnoymizedVideo(i)"
                            [class.button-loading] = "isAnonymizedVideoDeletionInProgress(i)"
                            [class.disabled] = "isAnonymizedVideoDeletionInProgress(i)"
                        >
                            <span class = "material-icons">
                                delete
                            </span>
                            Törlés
                        </div>
                      </div>
                  </td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template [ngIf] = "selectedIndex === null">
    <div class = "no-anonymized-video">
        Jelenleg nincs anonimizált verzió a videóból
    </div>
</ng-template>
