<!-- Category edit dialog title -->
<div class = "dialog-title">
    {{ title }}
</div>

<!-- Category edit dialog content -->
<div class = "dialog-content">
    <form [formGroup] = "categoryForm">
        <!-- Category name form -->
            <mat-form-field appearance='outline' id = "category-name-form">
            <mat-label>Név</mat-label>
            <input matInput formControlName = "categoryName"  required />
            <mat-error>Kötelező mező!</mat-error>
        </mat-form-field>
    
        <!-- Category description form -->
            <mat-form-field appearance='outline' id = "category-description-form">
            <mat-label>Leírás</mat-label>
            <textarea matInput formControlName = "categoryDescriptionFC" required></textarea>
            <mat-error *ngIf = "categoryDescriptionFC.hasError('required')">Kötelező mező!</mat-error>
        </mat-form-field>
        <br />
    
        <div style = "margin-bottom: 15px;">
            Teljes teszt
        </div>
        <!-- Category min-point for full test form -->
            <mat-form-field appearance='outline' id = "category-minpoint-form">
            <mat-label>Min. pont</mat-label>
            <input matInput formControlName = "categoryMinPointFullFC" type = "number" min = "0" />
            <div matSuffix> pont </div>
            <mat-error *ngIf = "categoryMinPointFullFC.hasError('required')">Kötelező számot megadni!</mat-error>
            <mat-error *ngIf = "categoryMinPointFullFC.hasError('positiveWholeNumber')">A számnak pozitív egésznek kell lennie!</mat-error>
        </mat-form-field>
    
            <!-- Category number of questions for full test form -->
            <mat-form-field appearance='outline' id = "category-numberofquestions-form">
            <mat-label>Kérdések száma</mat-label>
            <input matInput formControlName = "numberOfQuestionsFullFC" type = "number" min = "0" />
            <div matSuffix> db </div>
            <mat-error *ngIf = "numberOfQuestionsFullFC.hasError('required')">Kötelező számot megadni!</mat-error>
            <mat-error *ngIf = "numberOfQuestionsFullFC.hasError('positiveWholeNumber')">A számnak pozitív egésznek kell lennie!</mat-error>
        </mat-form-field>
    
        <!-- Category question time for full test form -->
            <mat-form-field appearance='outline' id = "category-time-form" >
            <mat-label>Idő kérdésenként</mat-label>
            <input matInput formControlName = "categoryQuestionTimeFullFC" type = "number" min = "0" />
            <div matSuffix> s </div>
            <mat-error *ngIf = "categoryQuestionTimeFullFC.hasError('required')">Kötelező számot megadni!</mat-error>
            <mat-error *ngIf = "categoryQuestionTimeFullFC.hasError('positiveWholeNumber')">A számnak pozitív egésznek kell lennie!</mat-error>
        </mat-form-field>
        <br />
    
        <div style = "margin-bottom: 15px;">
            Tematikus teszt
        </div>
        <!-- Category min-point for thematic test form -->
            <mat-form-field appearance='outline' id = "category-numberofquestions-form" >
            <mat-label>Minimum pont</mat-label>
            <input matInput formControlName = "categoryMinPointThematicFC" type = "number" min = "0" />
            <div matSuffix> pont </div>
            <mat-error *ngIf = "categoryMinPointThematicFC.hasError('required')"> Kötelező számot megadni! </mat-error>
            <mat-error *ngIf = "categoryMinPointThematicFC.hasError('positiveWholeNumber')"> A számnak pozitív egésznek kell lennie! </mat-error>
        </mat-form-field>
    
        <!-- Category min-point for thematic test form -->
            <mat-form-field appearance='outline' id = "category-numberofquestions-form" >
            <mat-label>Kérdések száma</mat-label>
            <input matInput formControlName = "numberOfQuestionsThematicFC" type = "number" min = "0" />
            <div matSuffix> db </div>
            <mat-error *ngIf = "numberOfQuestionsThematicFC.hasError('required')"> Kötelező számot megadni! </mat-error>
            <mat-error *ngIf = "numberOfQuestionsThematicFC.hasError('positiveWholeNumber')"> A számnak pozitív egésznek kell lennie! </mat-error>
        </mat-form-field>
    
    
        <!-- Category icon and header media form -->
        <div id = "category-medias-container">
            <div id = "category-icon-media-container">
                <span class = "category-media-title"> Ikon kép </span>
                <div id = "category-icon-media-preview-div">
                    <img id = "category-icon-media-preview-image" [src] = "getMediaUrl('icon')" draggable = "false" />
                </div>
                <div id = "category-icon-media-input-div">
                    <input id = "category-icon-media-input" type = 'file' accept = "image/jpeg" />
                    <mat-error *ngIf = "wasSubmitted && uploadedIconMediaType === MediaType.None"> Kötelező képet feltölteni! </mat-error>
                    <mat-error *ngIf = "uploadedIconMediaType === MediaType.Unsupported"> Hibás file formátum. Elfogadott típus: .png </mat-error>
                </div>
            </div>
            <div id = "category-header-media-container">
                <span class = "category-media-title"> Header kép </span>
                <div id = "category-header-media-preview-div">
                    <img id = "category-header-media-preview-image" [src] = "getMediaUrl('header')" draggable = "false" />
                </div>
                <div id = "category-header-media-input-div">
                    <input id = "category-header-media-input" type = 'file' accept = "image/jpeg" />
                    <mat-error *ngIf = "wasSubmitted && uploadedHeaderMediaType === MediaType.None"> Kötelező képet feltölteni! </mat-error>
                    <mat-error *ngIf = "uploadedHeaderMediaType === MediaType.Unsupported"> Hibás file formátum. Elfogadott típus: .png </mat-error>
                </div>
            </div>
        </div>
        <div style = "margin-bottom: 10px;">
            <mat-slide-toggle formControlName = "isFreeFC"> Ingyenes kategória </mat-slide-toggle>
        </div>
        <div>
            <mat-slide-toggle formControlName = "underDevelopmentFC"> Fejlesztés alatt </mat-slide-toggle>
        </div>
    </form>
</div>



<!-- Category action buttons -->
<div class = "dialog-actions">
    <div *ngIf = "data.dialogMode === DialogMode.Add" id = "category-dialog-add-actions">
        <button mat-button mat-dialog-close> Vissza </button>
        <button id = "category-dialog-add-button" mat-button (click) = "handleAddButtonClick()"> Felvesz </button>
    </div>
    <div *ngIf = "data.dialogMode === DialogMode.Edit" id = "category-dialog-edit-actions">
        <div id = "category-dialog-actions-delete-container">
            <button mat-button id  = "category-dialog-delete-button" (click) = "handleDeleteButtonClick()"> Törlés </button>
        </div>
        <div id = "category-dialog-actions-modify-container">
            <button mat-button mat-dialog-close> Vissza </button>
            <button mat-button id = "category-dialog-modify-button"  (click) = "handleModifyButtonClick()" cdkFocusInitial> Módosít </button>
        </div>
    </div>
</div>


