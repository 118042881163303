<app-page-header
    title = "Jogosultság csoportok"
    subtitle = "Itt található az összes jogosultság csoport. Csak a jogosultságok csoportos megjelenítésnél van jelentősége a UI elemeken."
    style = "margin-bottom: 20px;"
></app-page-header>

<ng-template [ngIf] = "!isContentLoading">
    <table *ngIf = "permissionGroups.length > 0">
        <thead class = "table-header">
            <td>
                Név
            </td>
            <td>
                Megjelenített név
            </td>
            
            <td>
                Leírás
            </td>
            <td class = "edit-column">
                <!-- edit -->
            </td>
            <td class = "delete-column">
                <!-- delete -->
            </td>
        </thead>
        <tbody>
            <tr *ngFor = "let permissionGroup of permissionGroups">
                <td>
                    {{ permissionGroup.name }}
                </td>
                <td>
                    {{ permissionGroup.displayedName }}
                </td>
                <td>
                    {{ permissionGroup.description }}
                </td>
                <td class = "edit-column">
                    <span class = "material-icons" (click) = "handleEditButtonClick(permissionGroup)">
                        edit
                    </span>
                </td>
                <td class = "delete-column">
                    <span class = "material-icons" (click) = "handleDeleteButtonClick(permissionGroup)">
                        delete
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf = "permissionGroups.length === 0" class = "no-data">
        Nincsenek megjeleníthető jogosultság csoportok.
    </div>
    <div class = "add-button" (click) = "handleAddButtonClick()">
        <span class = "material-icons">
            add
        </span>
    </div>
</ng-template>
<div *ngIf = "isContentLoading" class = "loading-container">
    <mat-spinner></mat-spinner>
</div>

