<!-- Audio player -->
<audio
    #audioPlayer
    style = "width: 100%;"
    [src] = "data.narrationDuringRecording.audioUrl"
    preload = "metadata"
    controls
></audio>

<!-- Rewind button -->
<button
    class = "action-button"
    (click) = "onRewindButtonClick()"
>
    <mat-icon> fast_rewind </mat-icon>
</button>

<!-- Forward button -->
<button
    class = "action-button"
    (click) = "onForwardButtonClick()"
>
    <mat-icon> fast_forward </mat-icon>
</button>