import { Component, OnInit, AfterViewInit } from "@angular/core";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
})
export class ContentComponent implements OnInit, AfterViewInit {
  contentSectionElement: HTMLElement;
  headerSectionElement: HTMLElement;
  footerSectrionElement: HTMLElement;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {

  }
}
