import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-icon-flow',
  templateUrl: './icon-flow.component.html',
  styleUrls: ['./icon-flow.component.scss']
})
export class IconFlowComponent implements OnInit {
  @Input()
  iconUrls: string[];


  @Input()
  selectedIconIndexes: number[];


  // Amikor egy ikonra kattint
  // akkor értesíti a feliratkozókat
  // a kattintott ikon indexével
  @Output()
  onTapIcon = new EventEmitter<number>();


  constructor() { }

  ngOnInit(): void {
  }

}
