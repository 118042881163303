import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Category } from 'src/app/classes/model/category';
import { Student, StudentFillout } from 'src/app/classes/model/student';
import { CategoryService } from 'src/app/services/category.service';
import { ConfirmationDialogService } from 'src/app/modules/confirmation-dialog/services/confirmation-dialog.service';
import { StudentLoggingService } from 'src/app/services/student-logging.service';
import { StudentService } from 'src/app/services/student.service';
import { CreateGiftForUserDialogComponent } from './create-gift-dialog/create-gift-for-user-dialog.component';
import { StudentFilloutDetailsComponent } from './student-fillout-details/student-fillout-details.component';
import { StudentDevicesComponent } from './student-devices/student-devices.component';
import { GiftedPackage } from 'src/app/classes/model/packages/GiftedPackage';
import { Package } from 'src/app/classes/model/packages/Package';
import { NotifierService } from 'src/app/services/common/notifier-service.service';

class Activity {
  categories: {
    [key: string]: {
      name: string;
      exam: number;
      practice: number;
      thematic: number;
      unknown: number;
    };
  };
  games: {
    road_sign_game: number;
    true_false_game: number;
  }
}

@Component({
  selector: 'app-student-details-dialog',
  templateUrl: './student-details-dialog.component.html',
  styleUrls: ['./student-details-dialog.component.scss']
})
export class StudentDetailsDialogComponent implements OnInit {

  student: Student;
  activity: Activity;
  categories: ReadonlyArray<Category>;
  isStudentUnderLogging: boolean | null = null;
  studentGifts: Array<GiftedPackage> = [];
  redeemedPackages: Array<Package> = [];

  theoryAccessTimestamp: number | null = null;
  practiceAccessTimestamp: number | null = null;

  // in app előfizetés által hozzáférés
  theoryAccessTimestampForSubscription: number | null = null;
  practiceAccessTimestampForSubscription: number | null = null;

  customerId: string;

  isContentLoading: boolean;

  giftOriginEmojiMap: Object = {
    system: "gear.png",
    admin: "admin.png"
  }

  packageSource: Object = {
    vendor: "money-mouth-face.png",
    web: "globe.png"
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: StudentDetailsDialogData,
    private dialogRef: MatDialogRef<StudentDetailsDialogComponent>,
    public dialog: MatDialog,
    public notifierService: NotifierService,
    private categoryService: CategoryService,
    private studentLoggingService: StudentLoggingService,
    private studentService: StudentService,
    private confirmationDialogService: ConfirmationDialogService
  ) { }

  public ngOnInit(): void {
    this.init()
  }

  private async init(): Promise<void> {
    this.isContentLoading = true;
    // Fetch the student detailed info
    await this.loadStudentInfo();

    // Check if the fetching was successful
    if (this.student == undefined || this.isStudentUnderLogging === null) {
      // If it was not, close the dialog (the service will show the error message in a snackbar)
      this.dialogRef.close();
      return;
    }

    // Initialize the activities fields
    this.initializeActivities();

    // Initialize the redeemed packages array
    this.initializeRedeemedPackages();

    // Initialize the studentGifts array
    this.initializeStudentGifts();

    // Initialize the module access timestamps
    this.initalizeModulAccessTimestamps();

    // Initialize the customerId
    this.initializeCustomerId();

    this.isContentLoading = false;
  }

  private async loadStudentInfo(): Promise<void> {
    console.log("load student info...");
    try {
      this.student = await this.studentService.getStudent(this.data.studentUuid);
      console.log(this.student);
      this.isStudentUnderLogging = await this.studentLoggingService.isStudentUnderLogging(this.data.studentUuid);
    } catch (error: any) { }
  }

  private initializeActivities(): void {
    this.categories = this.categoryService.getCategoriesArrayRef();

    this.activity = {
      categories: {},
      games: {
        road_sign_game: 0,
        true_false_game: 0
      }
    }

    for (let category of this.categories) {
      this.activity.categories[category.uuid] = {
        name: category.name,
        exam: 0,
        practice: 0,
        thematic: 0,
        unknown: 0
      }
    }

    for (let history of this.student.histories) {
      ++this.activity.categories[history.categoryUuid][history.type ?? "unknown"];
    }
  }

  private initializeRedeemedPackages(): void {
    this.redeemedPackages.pushArray(this.student.creditedPackageLogs.filter(
      (creditedPackageLogItem) => {
        return creditedPackageLogItem.creditedPackage.packageType === "PurchasedPackage" ||
          (creditedPackageLogItem.creditedPackage.packageType === "GiftedPackage" &&
            // Csak az olyan ajándékokat jelenítjük meg a beváltott csomagnál, amit nem közvetlenül ő kapott, hanem mondjuk egy viszonteladó
            // majd a viszonteladó a felhasználónak kiküldt és úgy váltotta be
            (creditedPackageLogItem.creditedPackage as GiftedPackage).giftInfo.receiver.entityType !== "student"
          )
      }
    ).map(
      (creditedPackageLogItem) => {
        return creditedPackageLogItem.creditedPackage
      }
    ));


    this.redeemedPackages.sort((package1: Package, package2: Package) => {

      return package1.redemptionInformation.redemptionTimestamp - package2.redemptionInformation.redemptionTimestamp
    });
  }

  private initializeStudentGifts(): void {
    this.studentGifts.pushArray(this.student.pendingExplicitGiftedPackage);

    this.studentGifts.pushArray(
      this.student.creditedPackageLogs.filter(
        (creditedPackageLogItem) => {
          // Csak azokat az ajándékokat jelenítjük meg itt, amit közvetlenül a felhasználó kapott (tehát nem a viszonteladónak lett ajándékozva)
          // Közvetlenül = maga a felhasználóra lett jóváírva általunk, vagy emailben kapta meg, és beváltotta erre a felhasználóra
          return creditedPackageLogItem.creditedPackage.packageType === "GiftedPackage" &&
            (creditedPackageLogItem.creditedPackage as GiftedPackage).giftInfo.receiver.entityType === "student"
        }
      ).map(
        (creditedPackageLogItem) => creditedPackageLogItem.creditedPackage as GiftedPackage
      )
    );

    this.studentGifts.sort(this.giftSorter);
  }

  private initalizeModulAccessTimestamps(): void {
    this.theoryAccessTimestamp = this.student.digitalAccesses.find(digitalAccess => digitalAccess.accessName === "theory_module_access")?.expireInTimestamp ?? null;
    this.practiceAccessTimestamp = this.student.digitalAccesses.find(digitalAccess => digitalAccess.accessName === "practical_module_access")?.expireInTimestamp ?? null;


    if (this.student.subscriptionEntitlements != undefined) {
      this.theoryAccessTimestampForSubscription = this.student.subscriptionEntitlements["theory_module_access"]?.expireTimestamp ?? null;
      this.practiceAccessTimestampForSubscription = this.student.subscriptionEntitlements["practical_module_access"]?.expireTimestamp ?? null;
    }
  }

  private initializeCustomerId(): void {
    if (this.student.customerId?.id) {
      const customerId: string = this.student.customerId?.id;
      this.customerId = [
        customerId.slice(0, customerId.length / 2),
        customerId.slice(customerId.length / 2)
      ].join("-")
    } else {
      this.customerId = "-";
    }
  }

  public openDetailsForFillout(filloutUuid: string, index: number) {
    this.dialog.open(StudentFilloutDetailsComponent, {
      data: {
        filloutUuid: filloutUuid,
        index: index + 1
      }
    });
  }

  public openCreateGiftDialog(): void {
    const dialogRef: MatDialogRef<CreateGiftForUserDialogComponent> = this.dialog.open(CreateGiftForUserDialogComponent, {
      data: {
        student: this.student
      }
    });

    dialogRef.afterClosed().subscribe(
      (gift: any) => {
        if (gift) {
          this.studentGifts.push(gift);
          this.studentGifts.sort(this.giftSorter);
        }
      }
    )
  }

  public getFilloutTypeName(fillout: StudentFillout): string {
    switch (fillout.type) {
      case "exam": return "vizsga";
      case "practice": return "gyakorlás";
      case "thematic": return "tematikus";
      default: return "ismeretlen";
    }
  }

  public async switchLogging(isActive: boolean) {
    try {
      await this.studentLoggingService.switchLoggingModeForStundent({
        uuid: this.student.uuid,
        email: this.student.email
      },
        isActive
      );

      this.notifierService.notify("success", "Tanuló monitorozás " + (isActive ? "bekapcsolva." : "kikapcsolva."));
      this.isStudentUnderLogging = !this.isStudentUnderLogging;
    } catch (error: any) { }
  }

  public async deleteUser(): Promise<void> {
    this.confirmationDialogService.open(
      "Tanuló személyes adatainak törlése",
      "Biztosan törli?",
      async () => {
        try {
          await this.studentService.deleteStudentPersonalDetails(this.student);
          this.notifierService.notify("success", "A tanuló személyes adatai törölve.");
        } catch (error: any) { }
      }
    );
  }

  public openStudentDevicesDialog(): void {
    this.dialog.open(StudentDevicesComponent, {
      data: {
        devices: this.student.devices
      }
    });
  }

  public isGiftPending(giftedPackage: any): boolean {
    return this.student.pendingExplicitGiftedPackage.find(pendingGiftedPackage => pendingGiftedPackage.uuid === giftedPackage.uuid);
  }

  public isAccessActive(timestamp: number | null): boolean {
    return timestamp !== null && Date.now().valueOf() < timestamp;
  }

  private giftSorter: (giftedPackage1: any, giftedPackage2: any) => number = (giftedPackage1: any, giftedPackage2: any) => {
    return giftedPackage1.packageCreationTimestamp - giftedPackage2.packageCreationTimestamp
  }

}

export type StudentDetailsDialogData = {
  studentUuid: string
}