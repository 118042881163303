import { CityZoneDialogEditComponent } from './components/city-zone-dialog-edit/city-zone-dialog-edit.component';
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogTitle } from '@angular/material/dialog';
import { SharedComponentsModule } from './../shared-module/shared-module.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PracticePathVideoEditPageModule } from './../practice-path-video-editor-page/practice-path-video-editor-page.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PracticeCityZoneEditorComponent } from './practice-city-zone-editor.component';
import { CityZoneAdderComponent } from './components/city-zone-adder/city-zone-adder.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { CityZoneCardComponent } from './components/city-zone-card/city-zone-card.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    PracticeCityZoneEditorComponent,
    CityZoneAdderComponent,
    CityZoneCardComponent,
    CityZoneDialogEditComponent
  ],
  imports: [
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatDialogModule,
    CommonModule,
    PracticePathVideoEditPageModule,
    MatFormFieldModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    SharedComponentsModule
  ],
  exports: [
    PracticeCityZoneEditorComponent
  ]
})
export class PracticeCityZoneEditorModule { }
