import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuestionChange } from 'src/app/classes/model/changelog';

@Component({
  selector: 'app-view-change-dialog',
  templateUrl: './view-change-dialog.component.html',
  styleUrls: ['./view-change-dialog.component.scss']
})
export class ViewChangeDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:{ change:QuestionChange }) { }

  ngOnInit(): void { }

}
