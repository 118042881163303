import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EntityType } from '../../models/entity-types';
import { CommentsOnEntityDialogOpenerButtonService } from '../../services/comments-on-entity-dialog-opener-button.service';
import { DraggableDialogService } from 'src/app/modules/draggable-dialog/services/draggable-dialog.service';
import { CommentsOnEntityDialogComponent, CommentsOnEntityDialogInput } from '../comments-on-entity-dialog/comments-on-entity-dialog.component';
import { PracticePathGlobalVideoEditorPageService } from 'src/app/modules/practice-path-video-editor-page/services/practice-path-global-video-editor-page.service';

@Component({
  selector: 'app-comments-on-entity-dialog-opener-button',
  templateUrl: './comments-on-entity-dialog-opener-button.component.html',
  styleUrls: ['./comments-on-entity-dialog-opener-button.component.scss'],
  providers: [
    CommentsOnEntityDialogOpenerButtonService
  ]
})
export class CommentsOnEntityDialogOpenerButtonComponent implements OnInit {
  @Input() entityType:EntityType;
  @Input() entityKeyValue:string;
  @Input() dialogTitle:string;

  isCommentsChatOpened:boolean = false;

  constructor(
    private draggableDialogService:DraggableDialogService,
    protected commentsOnEntityDialogOpenerButtonService:CommentsOnEntityDialogOpenerButtonService,
    private practicePathService: PracticePathGlobalVideoEditorPageService
  ) { }

  ngOnInit(): void {
    this.commentsOnEntityDialogOpenerButtonService.initialize(
      this.entityType,
      this.entityKeyValue
    );
  }

  /**
   * Handles the messages icon click in the tabs header.
   */
   protected onMessagesTabIconClick():void {
    // If the chat is already opened, nothing to do
    if(this.isCommentsChatOpened) {
      return;
    }

    // Set the chat opening state to true
    this.isCommentsChatOpened = true;

    const dialogRef = this.draggableDialogService.open<
      CommentsOnEntityDialogComponent,
      CommentsOnEntityDialogInput,
      void
    >(
      CommentsOnEntityDialogComponent,
      {
        width: "400px",
        height: "500px",
        data: {
          entityType: this.entityType,
          entityKeyValue: this.entityKeyValue
        }
      },
      {
        title: this.dialogTitle,
        contentPadding: "5px 10px 15px"
      }
    );

    this.practicePathService.generalEventEmitter.emit("commentsDialogOpened");

    // Subscribe to the dialog close event
    const dialogClosedSubscription:Subscription = dialogRef.afterClosed().subscribe(
      async () => {
        this.practicePathService.generalEventEmitter.emit("commentsDialogClosed");
        dialogClosedSubscription.unsubscribe();
        // Set the chat opening state to false
        this.isCommentsChatOpened = false;
        // Refresh the number of comments
        await this.commentsOnEntityDialogOpenerButtonService.updateNumberOfComments();
      }
    );
  }
}
