import { Injectable } from '@angular/core';
import { awaitFor } from '../classes/misc';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';

export type JobState = "DONE" | "ERROR" | "PROGRESS";

export type TaskProgressResponse = {
  state: JobState;
  message?: string;
  progressPercent?: number;
  logs: any[],
  errorLogs: any[]
}

@Injectable({
  providedIn: 'root'
})
export class MrVegasTaskService {
  private readonly apiUrlFragment: string = "/api/admin/video_converter_tasks";

  constructor(
    private backendService: BackendService,
    private httpErrorHandlerService: HttpErrorHandlerService
  ) { }

  public async fetchTaskState(taskId: string): Promise<TaskProgressResponse> {
    let response: TaskProgressResponse;

    try {
      response = await this.backendService.callApi(`${this.apiUrlFragment}/${taskId}`, "GET");
      return response;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a mr vegas task lekérdezése közben.");
      throw Error();
    }
  }

  /**
   *
   * @param videoConvertTaskId Ennek a tasknak az állapotát figyeljük meg
   * @param onProgress ezen a callbacken keresztül van értesítve a hívó fél a változásról
   * @param timeGapBetweenCalls ilyen gyakran updateljük a task állapotát (ilyen gyakran van API call)
   * @returns visszatér az utoljára lekért task-al, aminek a state-je ERROR vagy DONE lehet, progress esetén pedig az onProgress callback kerül csak hívásra
   * kivételt pedig akkor dob, ha egy API hívás nem sikerül
   * vagy ha olyan task state-et kapunk a szervertől amit nem kezeltünk le
  */
  public waitForVideoConvertTask(
      videoConvertTaskId: string,
      onProgress: (currentTaskState: TaskProgressResponse) => void,
      timeGapBetweenCalls: number = 10000): Promise<TaskProgressResponse> {
    return new Promise<TaskProgressResponse>(
      async (resolve: (value: TaskProgressResponse) => void, reject: (reason: any) => void) => {
        while (true) {
          try {
            const taskResponse: TaskProgressResponse = await this.fetchTaskState(videoConvertTaskId);

            switch (taskResponse.state) {
              case "DONE":
                onProgress(taskResponse);
                resolve(taskResponse);
                return;
              case "ERROR":
                resolve(taskResponse);
                return;
              case "PROGRESS":
                onProgress(taskResponse);
                await awaitFor(timeGapBetweenCalls);
                break;
              default:
                reject("Invalid task state: " + taskResponse.state);
                return;
            }
          } catch (error: any) {
            reject(error);
            break;
          }
        }
      }
    );
  }
}

