import { Component, Input, OnInit } from '@angular/core';
import { GlobalGift } from 'src/app/classes/model/gift';

@Component({
  selector: 'app-global-gift-card',
  templateUrl: './global-gift-card.component.html',
  styleUrls: ['./global-gift-card.component.scss']
})
export class GlobalGiftCardComponent implements OnInit {
  @Input() globalGift:GlobalGift;

  constructor() { }

  ngOnInit(): void {
  }

}
