import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params, QueryParamsHandling } from '@angular/router';
import { NavigationQueueService } from 'src/app/services/common/navigation-queue.service';
import { PracticePathsPageDataService } from '../services/practice-paths-page-data.service';
import { PracticePathFiltering } from '../practice-path-filter/practice-path-filter.component';
import { practicePathSortingOptions, SortingFunctionOption } from './practice-path-sortings';

@Component({
  selector: 'app-practice-path-sorting-selector',
  templateUrl: './practice-path-sorting-selector.component.html',
  styleUrls: ['./practice-path-sorting-selector.component.scss']
})
export class PracticePathSortingSelectorComponent implements OnInit {
  @Output() initialized:EventEmitter<void> = new EventEmitter<void>();

  selectedSortingOption:SortingFunctionOption;
  defaultSortingOptionForShortPaths:SortingFunctionOption;
  defaultSortingOptionForFullPaths:SortingFunctionOption;
  defaultSortingOption:SortingFunctionOption;

  readonly savedSortingInSessionStorageName:string = "saved-practice-path-list-sorting";

  constructor(
    private navigationQueueService:NavigationQueueService,
    private activatedRoute:ActivatedRoute,
    protected practicePathsPageDataService:PracticePathsPageDataService
  ) { }

  ngOnInit(): void {
    this.initializeSorting();
  }

  /**
   * Initializes the sorting component.
   */
  private async initializeSorting():Promise<void> {
    // Set the default sorting options
    this.defaultSortingOptionForShortPaths = practicePathSortingOptions["byAutoIncrementId"];
    this.defaultSortingOptionForFullPaths = practicePathSortingOptions["byExamPathNumber"];
    this.defaultSortingOption = practicePathSortingOptions["byName"];

    // Initialize the selected sorting option
    await this.initializeSelectedSortingOption();

    // Subscribe to the practice path base information filtering change
    this.subscribeToFilteringChange();

    // Emit that the component finished it's initialization
    this.initialized.emit();
  }

  /**
   * Returns the available sorting options based on the actual filtering.
   * 
   * @returns the available sorting options
   */
  protected availableSortingOptions():Array<SortingFunctionOption> {
    return Object.values(practicePathSortingOptions).filter(this.isSortingOptionAvailable);
  }

  /**
   * Return that the given sorting option is available based on the actual filtering.
   * 
   * @param practicePathSortingOption 
   * @returns 
   */
  private isSortingOptionAvailable = (practicePathSortingOption:SortingFunctionOption) => {
    // If there is no "sortingAvailableOnlyIfPredicate" predicate, the sorting option is available in every cases
    if(!practicePathSortingOption.sortingAvailableOnlyIfPredicate) {
      return true;
    }

    return practicePathSortingOption.sortingAvailableOnlyIfPredicate(this.practicePathsPageDataService.getPracticePathFiltering());
  }


  /**
   * Initalizes the selected sorting option.
   */
  private async initializeSelectedSortingOption():Promise<void> {
    // Load the saved sorting from the query params
    const sortingOption:SortingFunctionOption = this.loadInitialSortingFunctionFromQueryParams();
    await this.updatePracticePathSorting(sortingOption);
  }

  /**
   * Subscribes to the filtering change. It used to set the sorting selector's new value if the
   * filtered practice path base information type is changed.
   */
  private subscribeToFilteringChange():void {
    this.practicePathsPageDataService.practicePathFiltering$.subscribe(
      (practicePathFiltering:PracticePathFiltering|null) => {
        // If there is no filtering, nothing to do
        if(practicePathFiltering == null) {
          return;
        }

        // Check if the actual filtering is still available
        if(this.isSortingOptionAvailable(this.selectedSortingOption)) {
          return;
        }

        this.updatePracticePathSorting(this.getDefaultSortingBasedOnFiltering());
      }
    );
  }

  /**
   * Updates the actually selected sorting option, the session storage and the URL. Finally
   * it emits an event with the new soring function.
   * 
   * @param sortingOption the actually selected sorting function option
   */
  public async updatePracticePathSorting(sortingOption:SortingFunctionOption):Promise<void> {
    // Update the selected sorting option
    this.selectedSortingOption = sortingOption;

    // Update the query params
    await this.updateUrlQueryParams(this.selectedSortingOption.name);
    
    // Apply the new sorting with the practicePathsPageDataService
    this.practicePathsPageDataService.updatePracticePathSorting(this.selectedSortingOption.sortingFunction);
  }

  /**
   * Determines the initial sorting function from the URL. If none found it returns the default one.
   * 
   * @returns the initial sorting function
   */
  private loadInitialSortingFunctionFromQueryParams():SortingFunctionOption {
    // Check if there is a sorting saved in the URL's query params
    const savedNameInUrl:string|null = this.activatedRoute.snapshot.queryParams.sort ?? null;

    // If there is no sorting saved, or the saved sorting name is invalid
    if(savedNameInUrl == null || Object.keys(practicePathSortingOptions).includes(savedNameInUrl) === false) {
      // Return the default sorting based on the filtering
      return this.getDefaultSortingBasedOnFiltering();
    }

    // Otherwise return the saved sorting
    return practicePathSortingOptions[savedNameInUrl];
  }

  /**
   * Returns the default sorting basedon the actual filtering. If there is no filtering, the default "general" sorting
   * is returned.
   * 
   * @returns the default sorting based on the actual filtering
   */
  private getDefaultSortingBasedOnFiltering() {
    if(this.practicePathsPageDataService.getPracticePathFiltering() === null) {
      return this.defaultSortingOption;
    }

    return this.practicePathsPageDataService.getPracticePathFiltering().onlyFullPracticePaths ?
      this.defaultSortingOptionForFullPaths : this.defaultSortingOptionForShortPaths;
  }

  /**
   * Updates the query parameters with the given identifier.
   * 
   * @param sortingName the name of the sorting
   */
  private async updateUrlQueryParams(sortingName:string):Promise<void> {
    const queryParams:Params = { sort: sortingName };
    const queryParamsHandling:QueryParamsHandling = "merge";


    // For the navigation it uses the navigation queue service
    // It is needed, because there are other compontents that would like to update
    // the query parameters aswell at the initialization process, and with the default
    // navigation they would cancel eachother out
    await this.navigationQueueService.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: queryParamsHandling,
        replaceUrl: true
      }
    );
  }
}

