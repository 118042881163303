import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PracticePath } from 'src/app/classes/model/practice-path';

@Component({
  selector: 'app-practice-path-upload-dialog',
  templateUrl: './practice-path-upload-dialog.component.html',
  styleUrls: ['./practice-path-upload-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PracticePathUploadDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<PracticePathUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { practicePath: PracticePath }
  ) { }

  ngOnInit(): void { }

  onTapDialogClose(){
    this.dialogRef.close(null);
  }
}
