import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../components/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogComponentData } from "../models/confirmation-dialog-data";

@Injectable({
  providedIn: "root",
})
export class ConfirmationDialogService {
  constructor(private dialog: MatDialog) {}

  open(
    title: string,
    message: string,
    onPositiveAnswer?: () => void,
    onNegativeAnswer?: () => void,
    yesText?: string,
    noText?: string
  ): MatDialogRef<ConfirmationDialogComponent, boolean> {
    const confirmationDialogRef: MatDialogRef<
      ConfirmationDialogComponent,
      boolean
    > = this.dialog.open<
      ConfirmationDialogComponent,
      ConfirmationDialogComponentData,
      boolean
    >(ConfirmationDialogComponent, {
      data: {
        title: title,
        message: message,
        yesText: yesText ?? "Igen",
        noText: noText ?? "Nem",
      },
      disableClose: true,
    });

    const afterDialogCloseSubscription = confirmationDialogRef
      .afterClosed()
      .subscribe((userAnswer: boolean) => {
        afterDialogCloseSubscription.unsubscribe();

        if (userAnswer) {
          if (onPositiveAnswer) {
            onPositiveAnswer();
          }
        } else {
          if (onNegativeAnswer) {
            onNegativeAnswer();
          }
        }
      });

    return confirmationDialogRef;
  }
}
