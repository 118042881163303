 /**
 * Retruns the start of the day of a date. It calculates the minimal timestamp which is still in the same
 * day.
 *
 * @param date the target date
 *
 * @returns a date object which represents the end of the given day
 */
export function getStartOfDay(date:Date):Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
}

/**
 * Retruns the end of the day of a date. It calculates the maximal timestamp which is still in the same
 * day.
 *
 * @param date the target date
 *
 * @returns a date object which represents the end of the given day
 */
export function getEndOfDay(date:Date):Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
} 