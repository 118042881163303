<div class = "title">
    Jogosultság {{ data.dialogMode === 'add' ? 'felvétele' : 'módosítása' }}
</div>

 <mat-form-field appearance='outline' style = "width: 100%; margin-bottom: 10px;">
    <mat-label>ID</mat-label>
    <input matInput [formControl] = "nameFC" />
    <mat-error *ngIf = "nameFC.hasError('required')">Kötelező mező!</mat-error>
</mat-form-field>

 <mat-form-field appearance='outline' style = "width: 100%; margin-bottom: 10px;">
    <mat-label>Megjelenített név</mat-label>
    <input matInput [formControl] = "displayedNameFC" />
    <mat-error *ngIf = "displayedNameFC.hasError('required')">Kötelező mező!</mat-error>
</mat-form-field>

 <mat-form-field appearance='outline' style = "width: 100%; margin-bottom: 10px;">
    <mat-label>Csoport</mat-label>
    <mat-select [formControl] = "permissionGroupFC">
    <mat-option [value] = ""> - </mat-option>
      <mat-option *ngFor = "let permissionGroup of data.permissionGroups" [value] = "permissionGroup.name">
            {{ permissionGroup.displayedName }}
        </mat-option>
    </mat-select>
</mat-form-field>

 <mat-form-field appearance='outline' style = "width: 100%; margin-bottom: 10px;">
    <mat-label>Hozzárendelhető</mat-label>
    <mat-select [formControl] = "assignableToFC" multiple>
      <mat-option *ngFor = "let value of permissionAssignableToValues" [value] = "value.name">
            {{ value.displayedName }}
        </mat-option>
    </mat-select>
    <mat-error *ngIf = "displayedNameFC.hasError('required')">Kötelező mező!</mat-error>
</mat-form-field>

 <mat-form-field appearance='outline' style = "width: 100%;">
    <mat-label>Leírás</mat-label>
    <textarea matInput [formControl] = "descriptionFC" style = "resize: none;"> </textarea>
    <mat-error *ngIf = "descriptionFC.hasError('required')">Kötelező mező!</mat-error>
</mat-form-field>

<mat-dialog-actions align = "end">
    <button
        mat-raised-button
        mat-dialog-close
    >
        Bezár
    </button>
    <button
        mat-raised-button
        color = "primary"
        (click) = "handleSaveButtonClick()"
        [disabled] = "formGroup.invalid"
    >
        Mentés
    </button>
</mat-dialog-actions>
