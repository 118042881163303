import { GoogleMapPosition } from 'src/app/classes/model/google-map-position';
import { ReadableObservableArray, WritableObservableArray } from './../practice-path-video-editor-page/models/ObservableArray';
import { PracticeCityZone } from './../../classes/model/practice-city-zone';
import { Readable } from 'stream';

export type WrappedZoneOnMap = {
  zone: PracticeCityZone,
  isEditable: boolean,
  isNewlyCreatedZone: boolean,
  strongBorder: boolean
}

// Zónákat kontrollálja egy google map-en
export class ZoneMapController {
  private googleMap!: google.maps.Map;
  private underlyingZones$: WritableObservableArray<WrappedZoneOnMap>;
  private polygons: google.maps.Polygon[] = [];

  constructor(underlyingZones$: WritableObservableArray<WrappedZoneOnMap>) {
    this.underlyingZones$ = underlyingZones$;
    this.initFromUnderlyingZones(this.underlyingZones$);
  }

  public dispose() {
    this.removeAllPolygons();
    this.underlyingZones$.removeListener(this.onChangeUnderlyingData)
  }

  private onChangeUnderlyingData = (action: "update" | "add" | "remove" | "removedAllElement",
    oldValue?: WrappedZoneOnMap,
    newValue?: WrappedZoneOnMap,
    index?: number,
    actionOrigin?: string) => {
    switch (action) {
      case "add":
        this.addZone(newValue!, index!);
        break;
      case "removedAllElement":
        this.removeAllPolygons();
        break;
      case "remove":
        this.removeZone(index!);
        break;
      case "update":
        this.updateZone(oldValue!, newValue!, index!);
        break;
    }
  }

  private googleMapPositionToLatLng(position: GoogleMapPosition): google.maps.LatLng {
    return new google.maps.LatLng(position.latitude, position.longitude);
  }

  public initFromUnderlyingZones(underlyingZones$: WritableObservableArray<WrappedZoneOnMap>) {
    this.dispose(); // reseteljük
    this.underlyingZones$ = underlyingZones$; // beállítjuk az újat

    const zones = this.underlyingZones$.getFullCopy();
    for (const wrappedZone of zones) { // elvégezzük a térképhez hozzáadást
      this.addZone(wrappedZone, this.underlyingZones$.length - 1);
    }

    this.addChangeListenerToZones();
  }

  public setMap(map: google.maps.Map) {
    // TODO: check memory leak
    if (this.googleMap != null) {
      this.dispose();
      this.googleMap.unbindAll();
    }

    this.googleMap = map;
    this.initFromUnderlyingZones(this.underlyingZones$);
  }

  public getCenterOfPolygon(polygon: GoogleMapPosition[]): google.maps.LatLng {
    const bounds = new google.maps.LatLngBounds();
    polygon.forEach((latLng: GoogleMapPosition) => {
      bounds.extend(this.googleMapPositionToLatLng(latLng));
    });
    return bounds.getCenter();
  }

  public animateCameraToZone(wrappedZone: WrappedZoneOnMap) {
    const center = this.getCenterOfPolygon(wrappedZone.zone.polygon);
    this.googleMap.panTo(center);
  }

  private addZone(wrappedZone: WrappedZoneOnMap, index: number) {
    const newPolygon = new google.maps.Polygon({
      paths: wrappedZone.zone.polygon.map((position) => this.googleMapPositionToLatLng(position)),
      strokeColor: wrappedZone.zone.polygonColor,
      strokeOpacity: 0.9,
      // a zIndex valamiért fordítva van... a<b esetén azt várnánk
      // hogy b lesz legfelül, de ez nem így van, minél kisebb a zIndex annál fentebb lesz
      zIndex:1000-index,
      strokeWeight: wrappedZone.strongBorder ? 7 : 2,
      fillColor: wrappedZone.zone.polygonColor,
      fillOpacity: 0.4,
      editable: wrappedZone.isEditable,
      draggable: wrappedZone.isEditable,
      map: this.googleMap
    });

    google.maps.event.addListener(newPolygon, 'dragend', (event) => {
      this.updateUnderlyingDataFromPolygon(wrappedZone.zone.uuid, newPolygon);
    });

    google.maps.event.addListener(newPolygon, 'mouseup', (e) => {
      // Check right click
      if (e.domEvent.button === 2) {
        this.onTapPolygonVertexRightClick(wrappedZone.zone.uuid, e.vertex);
        return;
      }

      // edge ha új pont lett hozzáadva
      // vertex ha egy meglévő pontot mozgattunk
      if (e.vertex != null || e.edge != null) {
        this.updateUnderlyingDataFromPolygon(wrappedZone.zone.uuid, newPolygon);
      }
    });

    this.polygons.splice(index, 0, newPolygon);
  }


  private updateUnderlyingDataFromPolygon(wrappedZoneUuid: string, polygon: google.maps.Polygon) {
    const currentVertexes = polygon.getPath().getArray();
    console.log(currentVertexes.length);

    const newVertexes = currentVertexes.map((latLng) => {
      return {
        latitude: latLng.lat(),
        longitude: latLng.lng()
      }
    });
    const wrappedZoneIndex = this.underlyingZones$.findIndex((currentZone) => currentZone.zone.uuid === wrappedZoneUuid);
    if (wrappedZoneIndex == null) return;
    const currentWrappedZone = this.underlyingZones$.getCopyAt(wrappedZoneIndex);
    currentWrappedZone.zone.polygon = newVertexes;
    this.underlyingZones$.updateAt(wrappedZoneIndex, currentWrappedZone);
  }

  private onTapPolygonVertexRightClick(zoneUuid: string, vertexIndex: number) {
    const wrappedZone = this.underlyingZones$.find((wrappedZone) => wrappedZone.zone.uuid === zoneUuid);
    if (wrappedZone == null) return;
    // 3 pont és annál kevesebbnél nem törölhet
    if (wrappedZone.zone.polygon.length <= 3) return;

    wrappedZone.zone.polygon.splice(vertexIndex, 1);
    this.underlyingZones$.updateAt(this.underlyingZones$.findIndex((wrappedZone) => wrappedZone.zone.uuid === zoneUuid), wrappedZone);
  }


  private removeAllPolygons() {
    this.polygons.forEach((polygon) => {
      polygon.setMap(null);
      google.maps.event.clearInstanceListeners(polygon);
    });
    this.polygons = [];
  }

  private removeZone(index: number) {
    const polygon = this.polygons[index];
    google.maps.event.clearInstanceListeners(polygon);
    polygon.setMap(null);
    this.polygons.splice(index, 1);
  }

  private updateZoneProperties(wrappedZoneOnMap: WrappedZoneOnMap, index: number) {
    const polygon = this.polygons[index];

    polygon.setOptions({
      paths: wrappedZoneOnMap.zone.polygon.map((position) => this.googleMapPositionToLatLng(position)),
      strokeColor: wrappedZoneOnMap.zone.polygonColor,
      strokeWeight: wrappedZoneOnMap.strongBorder ? 7 : 2,
      fillColor: wrappedZoneOnMap.zone.polygonColor,
      editable: wrappedZoneOnMap.isEditable,
      draggable: wrappedZoneOnMap.isEditable,
    });
  }

  private updateZone(oldWrappedZone: WrappedZoneOnMap, wrappedZoneOnMap: WrappedZoneOnMap, index: number) {
    // Ha ugyanaz a wrapped zone, akkor elég updatelni a propertyket
    if (oldWrappedZone.zone.uuid === wrappedZoneOnMap.zone.uuid) {
      this.updateZoneProperties(wrappedZoneOnMap, index);
      return;
    }

    // egyébként pedig azért, hogy frissüljenek az event listener-ek is, újra hozzá kell adni (hiszen ez már egy másik entitás)
    this.removeZone(index);
    this.addZone(wrappedZoneOnMap, index);
  }

  private addChangeListenerToZones() {
    this.underlyingZones$.addListener(this.onChangeUnderlyingData);
  }
}
