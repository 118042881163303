export class RotateMarkerIconUtil{
  options: any;
  rImg: any;

  // Egy Data URI-val tér vissza, amit átadhatunk a markernek
  async rotateIcon(iconUrl: string,angle: number = 0) : Promise<string>{
    return new Promise((resolve,reject) => {
      this.rImg = new Image();
      this.rImg.src = iconUrl;
      this.options = {};

      this.rImg.onload = () => {
        this.options.width = this.rImg.width ?? 60;
        this.options.height = this.rImg.height ?? 60;
        var canvas = document.createElement("canvas");
        canvas.width = this.options.width;
        canvas.height = this.options.height;
        let context = canvas.getContext("2d");

        angle = angle * Math.PI / 180;
        let centerX = this.options.width/2;
        let centerY = this.options.height/2;

        context.clearRect(0, 0, this.options.width, this.options.height);
        context.save();
        context.translate(centerX, centerY);
        context.rotate(angle);
        context.translate(-centerX, -centerY);

        context.drawImage(this.rImg, 0, 0);

        const resultDataUri = canvas.toDataURL('image/png');
        resolve(resultDataUri);
      }
    });

  }
}
