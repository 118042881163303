import { MrvegasMetadataViewerPageComponent } from "./mrvegas-metadata-viewer-page.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  { path: "", component: MrvegasMetadataViewerPageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MrvegasMetadataViewerRoutingModule {}
