<div class="icon-recorder-main-container">
  <div
    class="icon-recording-row"
    *ngFor="let recording of this.iconRecorderService.getAllRecording()"
  >
    <img
      class="icon"
      style="width: 40px; height: 40px; margin-right: 15px"
      [src]="recording.practiceIconUrl"
    />
    <span (click)="onTapTime.next(recording.startFrameMs)" class="valid-time">
      {{ recording.startFrameMs | msToTimeString }}
    </span>

    <span style="white-space: pre-wrap"> - </span>

    <span
      (click)="onTapTime.next(recording.endFrameMs)"
      style="margin-right: 20px"
      [class]="
        recording.endFrameMs >= recording.startFrameMs
          ? 'valid-time'
          : 'invalid-time'
      "
    >
      {{ recording.endFrameMs | msToTimeString }}
    </span>

    <button
    mat-raised-button
    class="save-button"
    color="primary"
      (click)="onTapSaveRecording(recording.practiceIconUrl)"
      style="margin-right:25px;"
    >
      Mentés
    </button>
    <span
    class="cancel-button"
      (click)="onCancelRecording(recording.practiceIconUrl)"
      style="font-weight: bold; color: black; cursor: pointer"
    >
      Visszavonás
    </span>
  </div>
</div>
