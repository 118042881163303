<div style="display:block;height:100%;position:relative;">
  <google-map [options]="defaultMapOptions" [width]="'100%'" [height]="'100%'">
  </google-map>

  <!-- Ezzel lehet ki/be kapcsolni a markered polyline-ok láthatóságát -->
  <app-radio-buttons-float-menu
    [style]="'position:absolute; top:'+ (mapController?.isStreetViewMode?.getValue() ? 72 : 5) +'px;left:5px'"
    [values]="mapController?.getMarkeredPolylineControllers() ?? []"
    (onChangeValue)="onChangeSwitchSettings($event)"
  >
  </app-radio-buttons-float-menu>
</div>






