import { NotifierService } from 'src/app/services/common/notifier-service.service';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



export type ConfirmationDialogWithReasonInputData = {
  title: string;
  onYes: (reason: string) => void;
  onNo: () => void;
}


@Component({
  selector: 'app-confirmation-dialog-with-reason',
  templateUrl: './confirmation-dialog-with-reason.component.html',
  styleUrls: ['./confirmation-dialog-with-reason.component.scss']
})
export class ConfirmationDialogWithReasonComponent implements OnInit {
  reason: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) private data: ConfirmationDialogWithReasonInputData, private notifierService: NotifierService) { }

  public static openDialog(matDialogService: MatDialog,inputData: ConfirmationDialogWithReasonInputData) : MatDialogRef<ConfirmationDialogWithReasonComponent>{
    const dialogInfo = {
      data: {
        title: inputData.title,
        onYes: inputData.onYes,
        onNo: inputData.onNo
      }
    }

    return matDialogService.open(ConfirmationDialogWithReasonComponent, { data: dialogInfo.data });
  }


  ngOnInit(): void {
  }

  onTapNo() {
    this.data.onNo();
  }

  onTapYes() {
    if(this.reason.length<5){
      this.notifierService.notify('error','Legalább 5 karakterből kell állnia az oknak!');
      return;
    }

    this.data.onYes(this.reason);
  }

}
