<div class = "title">
    Admin szerepkör {{ data.dialogMode === 'add' ? 'felvétele' : 'módosítása' }}
</div>

<div class = "dialog-content">
     <mat-form-field appearance='outline' style = "width: 100%; margin-bottom: 10px;">
        <mat-label>ID</mat-label>
        <input matInput [formControl] = "nameFC" />
        <mat-error *ngIf = "nameFC.hasError('required')">Kötelező mező!</mat-error>
    </mat-form-field>

     <mat-form-field appearance='outline' style = "width: 100%; margin-bottom: 10px;">
        <mat-label>Megjelenített név</mat-label>
        <input matInput [formControl] = "displayedNameFC" />
        <mat-error *ngIf = "displayedNameFC.hasError('required')">Kötelező mező!</mat-error>
    </mat-form-field>

     <mat-form-field appearance='outline' style = "width: 100%; margin-bottom: 10px;">
        <mat-label>Leírás</mat-label>
        <textarea matInput [formControl] = "descriptionFC" style = "resize: none;"> </textarea>
    </mat-form-field>
    <div>
        <div class = "permission-selector-title">
            Hozzárendelt alap jogosultságok
        </div>
        <app-permission-selector
            [assignedPermissions] = "assignedPermissions"
            [withAssignableTo] = "[ 'ADMINS' ]"
        ></app-permission-selector>
    </div>
</div>

<mat-dialog-actions align = "end" style = "padding-top: 10px;">
    <button
        mat-raised-button
        mat-dialog-close
    >
        Bezár
    </button>
    <button
        mat-raised-button
        color = "primary"
        (click) = "handleSaveButtonClick()"
        [disabled] = "formGroup.invalid"
    >
        Mentés
    </button>
</mat-dialog-actions>
