<app-page-header
    title = "Jelzőtábla ikonok"
    subtitle = "A forgalmi felkészítő modul videóihoz rendelhető jelzőtáblákat szerkesztheted itt."
    style = "margin-bottom: 20px;"
></app-page-header>
<div *ngFor = "let practiceIcon of practiceIcons; let i = index" class = "practice-icon-card">
    <div>
        <img [src] = "practiceIcon.iconUrl" width = "150" height = "150"/>
    </div>
    <div class = "practice-icon-name">
        {{ practiceIcon.name | prefixWithDots: 60 }}
    </div>

    <div *ngIf = "userCanEdit" class = "practice-icon-edit" (click) = "handleEditButtonClick(i)">
        <span class = "material-icons">
            edit
        </span>
    </div>
</div>
<div *ngIf = "!isContentLoading && practiceIcons.length === 0">
    Nincsenek ikonok!
</div>
<div *ngIf = "isContentLoading">
    <mat-spinner></mat-spinner>
</div>
<div *ngIf = "userCanEdit" class = "add-button-container">
    <button mat-fab color = "primary" (click) = "handleAddButtonClick()">
        <mat-icon> add </mat-icon>
    </button>
</div>