<ng-container *ngIf="(commentsOnEntityService.getEntityComments() | async).length > 0; then commentsList; else noComments">
</ng-container>

<ng-template #commentsList>
    <app-comment-item
        *ngFor="let entityComment of commentsOnEntityService.getEntityComments() | async as entityComments; let i = index; trackBy: ngForTrackByEntityUuid"
        [entityComment] = "entityComment"
        style = "margin-top: 5px"
        [ngStyle] = "{ 'margin-bottom': getBottomMarginOfEntityCommentItem(entityComments, i) + 'px' }"
    ></app-comment-item>
</ng-template>

<ng-template #noComments>
    <div class = "no-comments">
        Jelenleg nincs egy megjegyzés sem.
    </div>
</ng-template>