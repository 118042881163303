import { EntityAccessCheckService } from 'src/app/services/entity-access-check.service';
import { GeoTaggedFrame } from "./../../../services/gopro-metadata-extractor";
import { PathIconSorter } from "./../../../services/practice-path-util";
import { PathIconMapController } from "../path-icon-map-controller";
import { PathIconService } from "../../../services/path-icon.service";
import { CriticalPointTimedIconAssignmentService } from "../../../services/timed-icon-service";
import { PracticeIconService } from "../../../services/practice-icon.service";
import {
  CriticalPointSorter,
  PracticePathUtil,
} from "../../../services/practice-path-util";
import { GoogleMapExtendedController } from "../components/google-map-extended/google-map-controller";
import { CriticalPointAssignmentService } from "../../../services/critical-point-assignment-service";
import { WritableObservableArray } from "../models/ObservableArray";
import { NotifierService } from 'src/app/services/common/notifier-service.service';
import { CriticalPointService } from "../../../services/critical-point-service";
import { PracticePathService } from "src/app/services/practice-path.service";
import {
  CriticalPoint,
  CriticalPointAssignment,
  IconPath,
  PathItem,
  PracticePath,
  TimedIcon,
} from "../../../classes/model/practice-path";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { Injectable, Injector, ViewContainerRef } from "@angular/core";
import { PracticeCityService } from "src/app/services/practice-city.service";
import { PracticeCity } from "src/app/classes/model/practice-city";
import { MatDialog } from "@angular/material/dialog";
import {
  CriticalPointEditorDialogComponent,
  CriticalPointEditorResult,
} from "../components/critical-point-editor-dialog/critical-point-editor-dialog.component";
import { PracticeIcon } from "src/app/classes/model/practice-icons";
import { HttpErrorResponse } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { VideoPlayerController } from "../../shared-module/components/video-player/video-player.controller";
import { ActivatedRoute } from '@angular/router';
import { NotificationDialogComponent } from '../../shared-module/components/notification-dialog/notification-dialog.component';
import { Emitter } from 'strict-event-emitter';
import { PracticePathEditorGeneralEventType } from './practice-path-editor-general-event-type';

const clone = require("clone");

@Injectable()
export class PracticePathGlobalVideoEditorPageService {
  practiceIcons: ReadonlyArray<PracticeIcon>;

  practicePath!: PracticePath; // Annak a practice path-nek a másolata amelyiknek a szerkesztését megkezdtük
  city!: PracticeCity;
  mainVideoPlayerController: VideoPlayerController;

  isNarrationEnabled: boolean = true;

  // practice page seteli onMapInit-ben
  // Az útvonal ikonokhoz külön controllert használunk
  // mivel ez egyedibb implementálást igényelt, nem lehetett általánosítani
  // Több különálló polyline-t renderel amiknek az elejére egy markert helyez el
  pathIconMapController: PathIconMapController;

  // fő térkép controller, onMapInit-ben kap értéket
  googleMapController: GoogleMapExtendedController;

  // Az útvonal path (sárga)
  path$: WritableObservableArray<PathItem>;
  timedIconAssignments$!: WritableObservableArray<TimedIcon>;
  pathIconAssignments$!: WritableObservableArray<IconPath>;
  criticalPointsInCorrespondingCity$!: WritableObservableArray<CriticalPoint>;
  criticalPointAssignments$: WritableObservableArray<CriticalPointAssignment>;

  // Amikor frissül a videó pozíció, akkor frissítjük ezt is
  currentPosition: BehaviorSubject<PathItem> = new BehaviorSubject(
    new PathItem()
  );

  // null az értéke ha jelenleg nem állunk egy kritikus pontnál se!
  currentlyActiveCriticalPoint: BehaviorSubject<CriticalPoint | null> =
    new BehaviorSubject(null);

  // Megálljon-e a kritikus pontoknál
  shouldStopAtCriticalPoint: BehaviorSubject<boolean> = new BehaviorSubject(
    true
  );

  generalEventEmitter: Emitter<PracticePathEditorGeneralEventType> = new Emitter();

  // Az utoljára elmentett path.
  // ha még nem történt mentés a felhasználó által, akkor az utoljára lekérdezett path lesz az értéke
  copyOfLastSavedPath: GeoTaggedFrame[] = [];

  private readonly ongoingCriticalPointModifications: Array<string> = [];


  constructor(
    private practiceCityService: PracticeCityService,
    private practicePathService: PracticePathService,
    private criticalPointService: CriticalPointService,
    private notifier: NotifierService,
    private matDialog: MatDialog,
    private criticalPointAssignmentService: CriticalPointAssignmentService,
    private practicePathUtil: PracticePathUtil,
    private criticalPointSorter: CriticalPointSorter,
    private practiceIconService: PracticeIconService,
    private timedIconService: CriticalPointTimedIconAssignmentService,
    private pathIconService: PathIconService,
    private iconPathSorter: PathIconSorter,
    private ngxService: NgxUiLoaderService,
    private entityAccessCheck: EntityAccessCheckService,
    private activatedRoute: ActivatedRoute
  ) { }

  // PracticePathEditorComponent hívja, inicializálja a service-t
  // editedPracticePathUuid annak az útvonal az azonosítója amit éppen szerkesztünk
  // Igazzal tér vissza, ha sikerült betölteni az útvonalat és inicializálni a service-t
  async init(editedPracticePathUuid: string): Promise<boolean> {
    const cityUuid: string = this.activatedRoute.snapshot.params.cityUuid;
    if (cityUuid == undefined) {
      this.notifier.notify("error", "Vizsgahelyszín azonosító nem található.");
      return false;
    }

    // Vizsgahelyszín betöltése
    try {
      this.city = await this.practiceCityService.fetchPracticeCity(cityUuid);
    } catch (error: any) {
      this.notifier.notify("error", "Hiba a vizsgahelyszín betöltése közben.");
      return false;
    }

    // Útvonal betöltése
    try {
      this.practicePath = await this.practicePathService.fetchPracticePath(editedPracticePathUuid);
    } catch (error: any) {
      this.notifier.notify("error", "Hiba az útvonal betöltése közben.");
      return false;
    }

    this.practiceIcons = this.practiceIconService.getPracticeIconsArrayRef();

    this.initGlobalObservableArrays();

    this.reSortTimedIcons();
    this.reSortCriticalPointAssignments();
    this.reSortPathIcons();
    this.initGlobalMainVideoPlayerController();

    this.notifier.notify("success", "Útvonal betöltve 🔥");

    return true;
  }


  async hasAccessToPath(practicePathUuid: string) {
    return await this.entityAccessCheck.checkEntityAccess(
      "practice_path",
      {
        practicePathUuid: practicePathUuid
      }
    );
  }



  private initGlobalMainVideoPlayerController() {
    this.mainVideoPlayerController = new VideoPlayerController(
      this.determineVideoUrl(this.practicePath).videoUrl
    );

    this.mainVideoPlayerController
      .getCurrentPositionInMs$()
      .subscribe((newPosition) => {
        this.currentPosition.next(
          this.practicePathUtil.getClosestGeoTaggedFrameToVideoPosition(
            this.path$.getOriginalArrayRef(),
            newPosition
          )
        );
      });
  }

  private initGlobalObservableArrays() {
    this.pathIconAssignments$ = WritableObservableArray.fromPodArray(
      this.practicePath.iconPaths
    );

    this.timedIconAssignments$ = WritableObservableArray.fromPodArray(
      this.practicePath.timedIcons
    );

    this.path$ = WritableObservableArray.fromPodArray(this.practicePath.path);
    this.copyOfLastSavedPath = JSON.parse(JSON.stringify(this.practicePath.path));

    // Elkészítjük a globális observable-eket
    // amiket használhatnak a komponensek
    // mentéskor ezeket fogjuk elmenteni
    this.criticalPointsInCorrespondingCity$ =
      WritableObservableArray.fromPodArray(this.city.criticalPoints);

    // Lerendezzük a hozzárendeléseket, hogy a térképen megfelelő sorszámot kapjanak
    this.criticalPointAssignments$ = WritableObservableArray.fromPodArray(
      this.practicePath.criticalPointAssignments
    );
  }

  recoverySpecificPathPointsFromLastSaved(indexes: number[]) {
    for (let ind of indexes) {
      this.path$.updateAt(ind, JSON.parse(JSON.stringify(this.copyOfLastSavedPath[ind])));
    }
  }

  // Az ikon látszódik-e jelenleg
  public isVisibleTimedIcon(timedIcon: TimedIcon) {
    return (
      timedIcon.startFrame <=
      this.mainVideoPlayerController.getLastKnownPosition() &&
      this.mainVideoPlayerController.getLastKnownPosition() <=
      timedIcon.endFrame
    );
  }

  // Visszatér azzal az assignment indexel ami a megadott kritikus ponthoz tartozik
  public getAssignmentIndex(criticalPointUuid: string): number {
    return this.criticalPointAssignments$.findIndex(
      (cp) => cp.criticalPointUuid == criticalPointUuid
    );
  }

  public getAllStopPoints() {
    return this.criticalPointAssignments$
      .getFullCopy()
      .map((cp) => cp.stopPoints.map((s) => s.stopTimeInMs))
      .flat();
  }

  // prior:
  // 1, raw minimal bitrate
  // 2, Kiadott videó
  // 3, raw full
  determineVideoUrl(practicePath: PracticePath): { type: PracticePathVideoType, videoUrl: string } {
    let url: string | null = null;
    let type: PracticePathVideoType = "NONE";

    if (practicePath.video?.rawVideoConvertedMinimalBitrate != null) {
      // MINIMAL
      url = practicePath.video?.rawVideoConvertedMinimalBitrate.minimalBitrateVideoUrl;
      type = 'RAW_MINIMAL';
    } else if (practicePath.video.releasedVideo?.videoHlsUrl != null) {
      // KIADOTT
      url = practicePath.video.releasedVideo?.videoHlsUrl;
      type = 'RELEASED';
    } else if (practicePath.video.rawVideo?.videoUrl != null) {
      // RAW
      url = practicePath.video.rawVideo?.videoUrl;
      type = 'RAW';
    }

    return {
      videoUrl: url,
      type: type
    };
  }

  // Megnyitja a kritikus pont szerkesztőt és kezeli a kritikus pont mentését
  async openCriticalPointEditorAndHandleSave(
    criticalPoint: CriticalPoint,
    criticalPointAssignment: CriticalPointAssignment,
    viewContainerRef: ViewContainerRef,
    injector: Injector,
    defaultStopPointIndex: number = 0
  ) {
    // Zárjuk be az összes info window-t
    // erre azért van szükség, mert így nem kell syncelni az info window-kat a mapen
    // ha változik egy kritikus pont szövege
    this.googleMapController.removeAllOverlay();

    this.mainVideoPlayerController.pause();

    const criticalPointCopy = clone(criticalPoint);
    const assignmentCopy = clone(criticalPointAssignment);

    const isMarkerVisible = this.googleMapController
      .getMarkeredPolylineControllers()
      .find((value) => value.getId() == "orange_path")
      .isMarkerVisible;

    const matDialogRef = this.matDialog.open(
      CriticalPointEditorDialogComponent,
      {
        data: {
          allOtherCriticalPoints: this.criticalPointsInCorrespondingCity$
            .getFullCopy()
            .filter((cp) => cp.uuid !== criticalPoint.uuid),
          criticalPoint: criticalPointCopy,
          videoUrl: this.mainVideoPlayerController.videoUrl,
          defaultSelectedStopPointIndex:
            criticalPointAssignment.stopPoints.length == 0
              ? undefined
              : defaultStopPointIndex,
          geoTaggedFrames: this.path$.getFullCopy(),
          stopPoints: assignmentCopy.stopPoints,
          showPathMarkers: isMarkerVisible,
          actualPracticePathUuid: this.practicePath.uuid
        },
        viewContainerRef: viewContainerRef,
        injector: injector,
        panelClass: "critical-point-editor-dialog",
      }
    );

    const editorResult = await firstValueFrom<CriticalPointEditorResult>(
      matDialogRef.afterClosed()
    );

    if (editorResult == undefined) {
      return;
    }

    if (criticalPoint.uuid == undefined) {
      // Ha még nem volt, akkor először létre kell hozni a kritikus pontot majd hozzá kell adni az útvonalhoz egy hozzárendelést

      try {
        await this.saveNewlyCreatedCriticalPoint(editorResult);
      } catch (error: any) {
        NotificationDialogComponent.open(
          this.matDialog,
          {
            type: "error",
            text: "Hiba történt a kritikus pont létrehozása közben!"
          }
        );
      }
    } else {
      // Ha már volt uuid-ja akkor egy létező kritikus pontot nyitottunk meg
      // tehát a kritikus pontot + a practice-ben lévő hozzárendelést kell frissíteni
      this.ongoingCriticalPointModifications.push(criticalPoint.uuid);

      try {
        await this.updateExistedCriticalPointAndAssignment(
          criticalPoint.uuid,
          criticalPointAssignment.uuid,
          editorResult
        );
      } catch (e) {
        console.error(e);

        // Set the default error message
        let errorText: string = "Hiba történt a kritikus pont módosítása közben!";

        // Set the specific error message on global to local not allowed conversation
        if (e instanceof HttpErrorResponse) {
          if (e.error.error == "GLOBAL_TO_LOCAL_NOT_ALLOWED") {
            errorText = "A módosítás nem sikerült, csak akkor lehet lokális ha egy videóhoz van hozzárendelve!";
          }
        }

        NotificationDialogComponent.open(
          this.matDialog,
          {
            type: "error",
            text: errorText
          }
        );
      }

      this.ongoingCriticalPointModifications.removeItems(criticalPointUuid => criticalPointUuid === criticalPoint.uuid);
    }

    this.reSortCriticalPointAssignments();
  }

  // Frissítéskor először frissítnei kell magát a kritikus pontot
  // majd a hozzárendelést a practice path-ben
  // dobhat kivételt
  async updateExistedCriticalPointAndAssignment(
    criticalPointUuid: string,
    assignmentUuid: string,
    newCriticalPointFromEditor: CriticalPointEditorResult
  ) {
    // (1) [API] Frissítjük a kritikus pontot
    const modifiedCriticalPoint = {
      uuid: criticalPointUuid,
      description: newCriticalPointFromEditor.description,
      coordinate: newCriticalPointFromEditor.criticalPointCoordinate,
      anchorPoint: newCriticalPointFromEditor.anchorPoint,
      isLocal: newCriticalPointFromEditor.isLocal,
      directionalAngle: newCriticalPointFromEditor.directionAngle?.angle,
      directionalAnglePoint: newCriticalPointFromEditor.directionAngle?.point,
      title: newCriticalPointFromEditor.title,
      onlyAssignablePracticePathUuid: newCriticalPointFromEditor.isLocal
        ? this.practicePath.uuid
        : undefined, // Csak ha lokális akkor állítjuk be a jelenlegi útvonalat
    };

    // Visszatér azzal a kritikus ponttal ami a szerveren tárolásra került
    // Ha a description változott, akkor ez az object tartalmazza az új audio url-t
    const newCriticalPointFromServer =
      await this.criticalPointService.changeCriticalPointFields(
        this.practicePath.cityUuid,
        criticalPointUuid,
        {
          coordinate: modifiedCriticalPoint.coordinate,
          description: modifiedCriticalPoint.description,
          title: modifiedCriticalPoint.title,
          uuid: modifiedCriticalPoint.uuid,
          onlyAssignablePracticePathUuid: modifiedCriticalPoint.onlyAssignablePracticePathUuid,
          isLocal: modifiedCriticalPoint.isLocal,
        },
        this.practicePath.uuid
      );

    const critInd = this.criticalPointsInCorrespondingCity$.findIndex(
      (cp) => cp.uuid == newCriticalPointFromServer.uuid
    );
    this.criticalPointsInCorrespondingCity$.updateAt(
      critInd,
      newCriticalPointFromServer
    );

    // (2) [API] Frissítjük a hozzárendelést is
    const modifiedAssignment = {
      stopPoints: newCriticalPointFromEditor.stopPoints,
      uuid: assignmentUuid,
      criticalPointUuid: criticalPointUuid,
    };
    await this.criticalPointAssignmentService.replaceCriticalPointAssignmentInPracticePath(
      this.practicePath.uuid,
      assignmentUuid,
      modifiedAssignment
    );
    this.notifier.notify("success", "Kritikus pont frissítve");

    // Frissítjük a globális modellben
    const assignmentInd = this.criticalPointAssignments$.findIndex(
      (assignment) => assignment.uuid == modifiedAssignment.uuid
    );
    this.criticalPointAssignments$.updateAt(assignmentInd, modifiedAssignment);

    this.reSortCriticalPointAssignments();
  }


  /**
   * Sets a critical point's verification's state to the given value.
   *
   * @param criticalPointUuid the uuid of the critical point
   * @param newVerificationState the new state of the verification status
   */
  async setCriticalPointVerificationState(
    criticalPointUuid: string,
    newVerificationState: boolean
  ): Promise<void> {
    // Update and get the critical point via the API service
    const updatedCriticalPointFromServer = await this.criticalPointService.setCriticalPointVerificationState(
      this.city.uuid,
      criticalPointUuid,
      newVerificationState,
      this.practicePath.uuid
    );

    // Update the critical points in city array
    const critInd = this.criticalPointsInCorrespondingCity$.findIndex(
      (cp) => cp.uuid == updatedCriticalPointFromServer.uuid
    );
    this.criticalPointsInCorrespondingCity$.updateAt(
      critInd,
      updatedCriticalPointFromServer
    );

    // Construct the success string
    const successString: string = updatedCriticalPointFromServer.isVerified ?
      "Kritikus pont sikeresen hitelesítve" :
      "Kritikus pont hitelesítés sikeresen eltávolítva";

    // Create a notification with the success strig
    this.notifier.notify("success", successString);
  }

  // Töröl véglegesen egy kritikus pontot!
  // csak akkor lehet törölni, ha nem tartozik egy útvonalhoz se a kritikus pont
  // Igazzal tér vissza, ha sikerült a törlés
  async removeCriticalPointPermanent(
    criticalPointUuid: string
  ): Promise<boolean> {
    try {
      const result = await this.criticalPointService.removeCriticalPoint(
        this.city.uuid,
        criticalPointUuid
      );
      if (!result) return false;

      const ind = this.criticalPointsInCorrespondingCity$.findIndex(
        (cp) => cp.uuid == criticalPointUuid
      );
      this.criticalPointsInCorrespondingCity$.removeAt(ind);

      return true;
    } catch (e) {
      return false;
    }
  }

  // Az éppen szerkesztett útvonalról levesz egy kritikus pont hozzárendelést
  // maga a kritikus pontot nem törli, csak az adott útvonalról veszi le (CriticalPointAssignment törlés)
  // Igazzal tér vissza, ha sikerült
  async removeCriticalPointAssignment(
    assignmentUuid: string,
    criticalPoint: CriticalPoint // A hozzárendelés ehhez a kritikus ponthoz tartozott
  ): Promise<boolean> {
    if (this.ongoingCriticalPointModifications.includes(criticalPoint.uuid)) {
      this.notifier.notify("info", "A kritikus pont módosítása még folyamatban van, addig nem tudod eltávolítani.");
      return false;
    }

    try {
      const result =
        await this.criticalPointAssignmentService.removeCriticalPointAssignment(
          this.practicePath.uuid,
          assignmentUuid
        );
      if (!result) return false;

      const ind = this.criticalPointAssignments$.findIndex(
        (assignment) => assignment.uuid == assignmentUuid
      );
      this.criticalPointAssignments$.removeAt(ind);

      // Ha egy lokális kritikus pontot vettünk le, akkor töröljük is véglegesen!
      if (criticalPoint.isLocal) {
        this.notifier.notify(
          "success",
          "Mivel a kritikus pont lokális volt, ezért törlésre is került"
        );
        // A backend elvégezte a törlést, ezért nekünk csak a memóriából kell kivenni
        const targetIndex = this.criticalPointsInCorrespondingCity$.findIndex((cp) => cp.uuid == criticalPoint.uuid);
        this.criticalPointsInCorrespondingCity$.removeAt(targetIndex);
      }

      // Törlésnél nincs szükség újra rendezni a hozzárendeléseket, mert a sorrend törlés után helyes marad
      return true;
    } catch (e) {
      NotificationDialogComponent.open(
        this.matDialog,
        {
          type: "error",
          text: "Hiba történt a kritikus pont hozzárendelés törlése közben!"
        }
      );
      return false;
    }
  }

  // Meglévő kritikus pontot vesz fel az útvonalhoz
  // Létrehoz az adott útvonalhoz egy teljesen új kritikus pont hozzárendelést
  async assignExistedCriticalPoint(criticalPointUuid: string) {
    try {
      const newAssignment = await this.criticalPointAssignmentService.addCriticalPointAssignment(
        this.practicePath.uuid,
        {
          criticalPointUuid: criticalPointUuid,
          stopPoints: [],
          uuid: undefined, // Mivel új hozzárendelés, ezért nincs még uuid, a szervertől kapunk
        }
      );

      this.criticalPointAssignments$.add(newAssignment);
      this.reSortCriticalPointAssignments();
      this.notifier.notify("success", "Sikeres felvétel");
    } catch (error: any) {
      NotificationDialogComponent.open(
        this.matDialog,
        {
          type: "error",
          text: "Hiba történt a kritikus pont felvétele közben!"
        }
      );
    }
  }

  // A hozzárendelt kritikus pontokat újra rendezi
  // A kritikus pont tabról egy button-el tudjuk hívni ezt a függvényt
  // illetve minden alkalommal amikor változhatot a kritikus pontok sorrendje meghívja a service
  async reSortCriticalPointAssignments() {
    const cps = this.criticalPointAssignments$.getFullCopy();
    this.criticalPointSorter.sortCriticalPointAssignmentsInPlace(
      cps,
      this.criticalPointsInCorrespondingCity$.getOriginalArrayRef(),
      this.path$.getOriginalArrayRef()
    );

    this.criticalPointAssignments$.removeAll();
    for (const assignment of cps) {
      this.criticalPointAssignments$.add(assignment);
    }
  }

  async saveNewlyCreatedCriticalPoint(
    newCriticalPointFromEditor: CriticalPointEditorResult
  ) {
    // (1) Adjuk hozzá ezt az új kritikus pontot API-n keresztül
    const insertedCriticalPoint =
      await this.criticalPointService.addCriticalPoint(
        this.practicePath.cityUuid,
        {
          description: newCriticalPointFromEditor.description,
          coordinate: newCriticalPointFromEditor.criticalPointCoordinate,
          anchorPoint: newCriticalPointFromEditor.anchorPoint,
          isLocal: newCriticalPointFromEditor.isLocal,
          directionalAngle: newCriticalPointFromEditor.directionAngle?.angle,
          directionalAnglePoint:
            newCriticalPointFromEditor.directionAngle?.point,
          title: newCriticalPointFromEditor.title,
          onlyAssignablePracticePathUuid: newCriticalPointFromEditor.isLocal
            ? this.practicePath.uuid
            : undefined, // Csak ha lokális akkor állítjuk be a jelenlegi útvonalat
        },
        this.practicePath.uuid
      );
    if (insertedCriticalPoint != undefined) {
      this.notifier.notify("success", "Kritikus pont sikeresen létrehozva");
    }

    // (2) Tároljuk le a response-t a critPointsInCity tömbben (az api által visszaadott objektumban már van uuid is)
    this.criticalPointsInCorrespondingCity$.add(insertedCriticalPoint);

    // (3) API-n keresztül rendeljük hozzá az útvonalhoz a kritikus pontot (ez már tartalmaz server által generált uuid-t)
    const insertedAssignment =
      await this.criticalPointAssignmentService.addCriticalPointAssignment(
        this.practicePath.uuid,
        {
          criticalPointUuid: insertedCriticalPoint.uuid,
          stopPoints: newCriticalPointFromEditor.stopPoints,
          uuid: undefined, // A szerver generálja
        }
      );

    // (4) Majd adjuk hozzá az API-tól visszakapott kritikus pont hozzárendelést a criticalPointAssignment-hez
    this.criticalPointAssignments$.add(insertedAssignment);
  }

  // Az időzített ikonokat újra rendezi kezdő időpont szerint
  reSortTimedIcons() {
    const sorted = this.timedIconAssignments$.getFullCopy().sort((a, b) => {
      return a.startFrame - b.startFrame;
    });

    this.timedIconAssignments$.removeAll();
    sorted.forEach((s) => this.timedIconAssignments$.add(s));
  }

  // Útvonal ikonok újra rendezése
  reSortPathIcons() {
    const sorted = this.pathIconAssignments$.getFullCopy();
    this.iconPathSorter.sortPathIconInPlace(
      sorted,
      this.path$.getOriginalArrayRef()
    );
    this.pathIconAssignments$.removeAll();
    sorted.forEach((s) => this.pathIconAssignments$.add(s));
  }

  async deleteTimedIconAssignment(assignmentUuid: string): Promise<boolean> {
    const isSuccessful = await this.timedIconService.removeTimedIconAssignment(
      this.practicePath.uuid,
      assignmentUuid
    );
    if (isSuccessful) {
      this.notifier.notify("success", "Sikeres törlés!");
      const index = this.timedIconAssignments$.findIndex(
        (ti) => ti.uuid == assignmentUuid
      );
      this.timedIconAssignments$.removeAt(index);
      return true;
    } else {
      this.notifier.notify("error", "A törlés nem sikerült!");
      return false;
    }
  }

  async addTimedIconAssignment(timedIcon: Omit<TimedIcon, "uuid">) {
    const newValueFromServerWithUuid: TimedIcon =
      await this.timedIconService.addTimedIconAssignment(
        this.practicePath.uuid,
        timedIcon
      );
    if (newValueFromServerWithUuid) {
      const index = this.timedIconAssignments$.add(newValueFromServerWithUuid);
      this.reSortTimedIcons();
      return true;
    } else {
      this.notifier.notify("error", "Az időzített ikon hozzáadás nem sikerül!");
      return false;
    }
  }

  async addPathIconAssignment(pathIcon: Omit<IconPath, "uuid">) {
    const newValueFromServerWithUuid: IconPath =
      await this.pathIconService.addPathIconAssignment(
        this.practicePath.uuid,
        pathIcon
      );
    if (newValueFromServerWithUuid) {
      const index = this.pathIconAssignments$.add(newValueFromServerWithUuid);
      this.reSortPathIcons();
      return true;
    } else {
      this.notifier.notify("error", "Az időzített ikon hozzáadás nem sikerül!");
      return false;
    }
  }

  async deletePathIconAssignment(pathIconAssignmentUuid: string) {
    const isSuccessful = await this.pathIconService.removePathIconAssignment(
      this.practicePath.uuid,
      pathIconAssignmentUuid
    );
    if (isSuccessful) {
      this.notifier.notify("success", "Sikeres törlés!");
      const index = this.pathIconAssignments$.findIndex(
        (ti) => ti.uuid == pathIconAssignmentUuid
      );
      this.pathIconAssignments$.removeAt(index);
      return true;
    } else {
      this.notifier.notify("error", "Az útvonal ikon törlés nem sikerült!");
      return false;
    }
  }

  // Frissíti egy PracticePath-ben maga a path-et (GeoTaggedFrame)
  async updatePath(newPath: Readonly<GeoTaggedFrame[]>) {
    this.ngxService.start();

    const isSuccessful = await this.practicePathService.updateGeoTaggedFramesPath(
      this.practicePath.uuid,
      newPath
    );

    if (!isSuccessful) {
      this.ngxService.stop();
      this.notifier.notify("error", "Útvonal pontok frissítése nem sikerült");
      return;
    }

    this.copyOfLastSavedPath = JSON.parse(JSON.stringify(newPath));

    this.notifier.notify("success", "Útvonal pontok sikeresen elmentve!");
    this.path$.removeAll();

    newPath.forEach((p) => {
      this.path$.add(p);
    });

    this.ngxService.stop();
  }

  // Akkor kerül meghívásra, amikor a hozzátartozó editor page component megszűnik
  // az összes event listenert törli
  dispose() {
    this.path$?.destroy();
    this.criticalPointAssignments$?.destroy();
    this.pathIconAssignments$?.destroy();
    this.timedIconAssignments$?.destroy();
    this.criticalPointsInCorrespondingCity$?.destroy();
    this.mainVideoPlayerController?.destroy();
    this.currentPosition?.unsubscribe();

    if (this.shouldStopAtCriticalPoint.unsubscribe instanceof Function) {
      this.shouldStopAtCriticalPoint?.unsubscribe();
    }


    this.currentlyActiveCriticalPoint?.unsubscribe();
    this.pathIconMapController?.destroy();
  }
}

export type PracticePathVideoType = 'RAW' | "RAW_MINIMAL" | "RELEASED" | "NONE";
