<div id = "create-notification-dialog">
    <div id = "create-notification-dialog-title">
        Új értesítés létrehozása
    </div>
    <form [formGroup] = "appNotificationFG">
            <div id = "create-notification-user-type">
                <div>
                     <mat-form-field appearance='outline' style = "width: 100%">
                        <mat-label>Felhasználó uuid</mat-label>
                        <input matInput [formControl] = "userUuidFC">
                        <mat-error>Kötelező mező!</mat-error>
                    </mat-form-field>
                </div>
                <div>
                    <mat-checkbox [formControl] = "typeFC"> Mindenkinek </mat-checkbox>
                </div>
            </div>
            <div>
                 <mat-form-field appearance='outline' style = "width: 100%">
                    <mat-label>Cím</mat-label>
                    <input matInput [formControl] = "titleFC" />
                    <mat-error>Kötelező mező!</mat-error>
                </mat-form-field>
            </div>
            <div>
                 <mat-form-field appearance='outline' style = "width: 100%">
                    <mat-label>Üzenet</mat-label>
                    <textarea matInput [formControl] = "messageFC" style = "resize: none;"> </textarea>
                    <mat-error>Kötelező mező!</mat-error>
                </mat-form-field>
            </div>
            <div>
                 <mat-form-field appearance='outline' style = "width: 100%">
                    <mat-label>Útvonal</mat-label>
                    <input matInput [formControl] = "routeFC" />
                    <mat-error>Kötelező mező!</mat-error>
                </mat-form-field>
            </div>
        <mat-dialog-actions align="end">
            <button mat-flat-button mat-dialog-close style = "margin-right: 10px;"> Bezár </button>
            <button mat-flat-button color = "primary" [disabled] = "appNotificationFG.invalid" (click) = "handleCreateNotificationButtonClick()" mat-dialog-close> Értesítés létrehozása </button>
        </mat-dialog-actions>
    </form>
</div>
