import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AndroidDeviceInfo, DeviceInfo, IosDeviceInfo } from 'src/app/classes/model/device';

@Component({
  selector: 'app-student-devices',
  templateUrl: './student-devices.component.html',
  styleUrls: ['./student-devices.component.scss']
})
export class StudentDevicesComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{ devices:Array<DeviceInfo> }
  ) { }

  ngOnInit(): void {
  }

  protected getAsAndroidDeviceInfo(deviceInfo:DeviceInfo):AndroidDeviceInfo {
    return deviceInfo as AndroidDeviceInfo;
  }

  protected getAsIosDeviceInfo(deviceInfo:DeviceInfo):IosDeviceInfo {
    return deviceInfo as IosDeviceInfo;
  }

}
