<div class = "tab-header">
    <div>
        Itt találhatóak a viszonteladónak adott ajándékok.
    </div>
    <button
        class = "add-gift-button"
        (click) = "openCreateVendorGiftDialog()"
        [disabled] = "!hasAdminVendorGiftingPermission()"
        [matTooltip] = "'Nincs jogosultságod viszonteladónak ajándékot kiosztani.'"
        [matTooltipDisabled] = "hasAdminVendorGiftingPermission()"
    >
        <mat-icon> add </mat-icon>
    </button>
</div>
<div class = "package-group-info-container">
    <app-vendor-package-group-info
        *ngFor = "let groupedGiftedPackagesGroup of groupedGiftedPackagesGroups"
        [groupedPackages] = "groupedGiftedPackagesGroup"
        class = "vendor-package-group-info"
    ></app-vendor-package-group-info>
    <div *ngIf = "groupedGiftedPackagesGroups.length === 0" class = "no-gifts">
        Jelenleg nincs kiosztott ajándék a viszonteladó számára.
    </div>
</div>