import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Feedback } from 'src/app/classes/model/feedback';
import { Student } from 'src/app/classes/model/student';
import { FeedbackService } from 'src/app/services/feedback.service';
import { StudentService } from 'src/app/services/student.service';
import { StudentDetailsDialogComponent, StudentDetailsDialogData } from '../../students/student-details-dialog/student-details-dialog.component';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  @Input() feedback:Feedback;

  @Output() feedbackClosed:EventEmitter<void> = new EventEmitter<void>();

  message:Array<string>;
  email:string;

  constructor(
    private feedbackService:FeedbackService,
    private studentService:StudentService,
    public dialog:MatDialog,
    public snackBar:MatSnackBar
  ) { }

  ngOnInit(): void {
    this.message = this.feedback.message.split("\n");
    this.email = this.feedback.created.userEmail ? this.feedback.created.userEmail : "Nincs megjeleníthető e-mail cím";
  }

  public async handleHandleFeedbackButtonClick():Promise<void> {
    await this.feedbackService.closeFeedback(this.feedback);
    this.feedbackClosed.emit();
  }

  public openStudentDetailsDialog(studentUuid:string):void {
    this.dialog.open<StudentDetailsDialogComponent, StudentDetailsDialogData>(
      StudentDetailsDialogComponent,
      {
        data: {
          studentUuid: studentUuid
        }
      }
    );
  }

}
