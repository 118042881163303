import { MarkeredPolylineController } from '../markered-polyline-controller';
import { ChangeDetectionStrategy, ChangeDetectorRef, DoCheck, OnChanges } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

export type RadioButton = {
  switchName: string,
  label: string,
  value: boolean
}

@Component({
  selector: 'app-radio-buttons-float-menu',
  templateUrl: './radio-buttons-float-menu.component.html',
  styleUrls: ['./radio-buttons-float-menu.component.scss']
})
export class RadioButtonsFloatMenuComponent implements OnChanges {
  // Ez alapján rendereljük a menüt
  isOpened: boolean = false;

  @Output()
  onChangeValue = new EventEmitter<{markeredPolylineId: string, newValue: boolean}>();

  @Input()
  values!: MarkeredPolylineController[];

  @Input()
  menuName: string = "Pontok";

  constructor(private changeDet: ChangeDetectorRef) { }



  onToggleChange(markeredPolylineId: string, change: MatSlideToggleChange){
    this.onChangeValue.emit({
      markeredPolylineId: markeredPolylineId,
      newValue: change.checked
    });
  }

  ngOnChanges(){

  }


  onTapMenu(){
    this.isOpened = !this.isOpened;
  }
}
