import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommentsOnEntityService } from 'src/app/services/comments-on-entity.service';
import { EntityType } from '../models/entity-types';

@Injectable()
export class CommentsOnEntityDialogOpenerButtonService implements OnDestroy {
  private entityType:EntityType;
  private entityKeyValue:string;

  private readonly numberOfCommentsSubject:BehaviorSubject<number|null>;

  private readonly numberOfCommentsUpdateIntervalTimeInMs:number = 10000;
  private numberOfCommentsIntervalId:NodeJS.Timeout|null = null;

  constructor(
    private commentsOnEntityService:CommentsOnEntityService
  ) {
    this.numberOfCommentsSubject = new BehaviorSubject<number|null>(null);
  }

  public ngOnDestroy():void {
    if(this.numberOfCommentsIntervalId) {
      clearInterval(this.numberOfCommentsIntervalId);
    }
  }

  public async initialize(entityType:EntityType, entityKeyValue:string) {
    this.entityType = entityType;
    this.entityKeyValue = entityKeyValue;

    // Update the number of comments at the initialization
    await this.updateNumberOfComments();
    // Start the periodic fetching of the number of comments
    this.numberOfCommentsIntervalId = setInterval(this.updateNumberOfComments, this.numberOfCommentsUpdateIntervalTimeInMs)
  }

  /**
   * Updated the number of comments via `CommentsOnEntityService`. On successful fetching it puts the new
   * value into the `numberOfComments` stream.
   */
   public updateNumberOfComments = async () => {
    try {
      const numberOfComments:number = await this.commentsOnEntityService.getNumberOfCommentsOnEntity(
        this.entityType,
        this.entityKeyValue
      );
      this.numberOfCommentsSubject.next(numberOfComments);
    } catch(error:any) {
      console.error(error);
    }
  }

  /**
   * Returns the number of comments subject as an observable.
   */
   public get numberOfComments$():Observable<number|null> {
    return this.numberOfCommentsSubject.asObservable();
  }

}