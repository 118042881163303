<div class = "devices-title">
    Tanuló eszközei
</div>
<div class = "device-info-header">
    <div>
        Típus
    </div>
    <div>
        Eszköz
    </div>
    <div>
        Verzió
    </div>
    <div>
       ID
    </div>
</div>
<div *ngFor = "let device of data.devices">
    <div *ngIf = "device.platform === 'android'" class = "device-info-item">
        <div>
            <img src = "assets/logos/android_logo.png"  class = "device-type-logo" />
        </div>
        <div>
            {{ getAsAndroidDeviceInfo(device).manufacturer }} {{ device.model }}
        </div>
        <div>
            {{ getAsAndroidDeviceInfo(device).sdkVersion }}
        </div>
        <div>
            {{ getAsAndroidDeviceInfo(device).androidId }}
        </div>
        
    </div>
    <div *ngIf = "device.platform === 'ios'" class = "device-info-item">
        <div>
            <img src = "assets/logos/apple_logo.png" class = "device-type-logo" />
        </div>
        <div>
            {{ device.model }}
        </div>
        <div>
            {{ getAsIosDeviceInfo(device).systemVersion }}
        </div>
        <div>
            {{ getAsIosDeviceInfo(device).identifierForVendor }}
        </div>
    </div>

</div>
