import { PracticePathService } from 'src/app/services/practice-path.service';
import { AnonymizerTask } from './../../../../../../classes/model/anonymizer-task';
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PracticePathBaseInformations } from "src/app/classes/model/practice-path";
import { PracticeVideo, videoSorter } from "src/app/classes/model/practice-video";
import { PracticeVideoService } from "src/app/services/practice-video.service";
import { AnonymizerTaskService } from 'src/app/services/anonymizer-task.service';

export type PracticeVideoManagerDialogData = {
  practicePathUuid: string,
}

@Component({
  selector: "app-practice-video-manager-dialog",
  templateUrl: "./practice-video-manager-dialog.component.html",
  styleUrls: ["./practice-video-manager-dialog.component.scss"],
})
export class PracticeVideoManagerDialogComponent implements OnInit, OnDestroy {
  tabIndex: number = 0;
  selectedAnonymizationIndex: number | null = null;
  anonymizerTask: AnonymizerTask | null = null;

  isContentLoading: boolean = false;

  intervalId: NodeJS.Timeout | null;

  practicePath:PracticePathBaseInformations;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PracticeVideoManagerDialogData,
    private practicePathService: PracticePathService,
    private anonymizerTaskService: AnonymizerTaskService,
    protected dialogRef:MatDialogRef<PracticeVideoManagerDialogComponent>
  ) {}

  public ngOnInit(): void {
    this.init();

   this.addBackdropClickListener();
  }

  public async init() {
    this.isContentLoading = true;

    this.practicePath = await this.practicePathService.fetchPracticePath(this.data.practicePathUuid, "summary");
    this.sortPracticePathVideos();
    this.updatePracticePath();

    if (this.practicePath.video.anonymizedVideos.length > 0) {
      this.selectedAnonymizationIndex = 0;
    }

    this.isContentLoading = false;

    this.intervalId = setInterval(() => {
      this.updatePracticePath();
    }, 10000);
  }

  private addBackdropClickListener() {
    this.dialogRef.backdropClick().subscribe(
      () => {
        this.dialogRef.close(this.practicePath);
      }
    )
  }

  public ngOnDestroy(): void {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
    }
  }

  public tabIndexChange(newTabIndex: number): void {
    this.tabIndex = newTabIndex;
  }

  public anonymizationStarted(): void {
    this.selectedAnonymizedIndexChanged(0);
    this.tabIndex = 1;
  }

  public handleClickedOnAnonomizedVideoEvent(
    anonymizedVideoUuid: string
  ): void {
    const anonymizedVideoIndex =
      this.practicePath.video.anonymizedVideos.findIndex(
        (video) => video.uuid === anonymizedVideoUuid
      );
    if (anonymizedVideoIndex >= 0) {
      this.selectedAnonymizationIndex = anonymizedVideoIndex;
      this.tabIndex = 1;
    }
  }

  public selectedAnonymizedIndexChanged(newIndex: number): void {
    this.anonymizerTask = null;

    this.selectedAnonymizationIndex = newIndex;
    this.updateAnonymizingProgressState(
      this.practicePath.video.anonymizedVideos[
        this.selectedAnonymizationIndex
      ].anonymizerTaskId
    );
  }

  public async updateAnonymizingProgressState(
    anonymizerTaskId: string
  ): Promise<void> {
    try {
      this.anonymizerTask =
        await this.anonymizerTaskService.fetchAnonymizerTask(
          anonymizerTaskId
        );
    } catch (error: any) {}
  }

  sortPracticePathVideos(){
    //Sort
    this.practicePath.video.anonymizedVideos.sort(videoSorter);
    this.practicePath.video.finalVideos.sort(videoSorter);
  }

  public async updatePracticePath(): Promise<void> {
    // Frissíteni kell a practice path-et is!
    const remotePracticePath = await this.practicePathService.fetchPracticePath(this.data.practicePathUuid, "summary");
    this.practicePath.video = remotePracticePath.video; // Frissítjük a video fieldet

    this.sortPracticePathVideos();

    for(let i=0;i<this.practicePath.video.anonymizedVideos.length;i++){
      const anonymizedVideo = this.practicePath.video.anonymizedVideos[i];

      try {
        const anonymizerTask: AnonymizerTask =
          await this.anonymizerTaskService.fetchAnonymizerTask(
            anonymizedVideo.anonymizerTaskId
          );

        if (this.selectedAnonymizationIndex === i) {
          this.anonymizerTask = anonymizerTask;
        }
      } catch (error: any) {
        console.error(error);
      }
    }

  }
  
  protected onFinalVideoUploaded(newPracticeVideoField:PracticeVideo):void {
    this.practicePath.video = newPracticeVideoField;
  }
}
