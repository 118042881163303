import { GoogleMapPosition } from 'src/app/classes/model/google-map-position';
import { ColorInterpolator } from "./ColorInterpolator";
import { MarkerUtil } from "./components/google-map-extended/marker-util";
import { GoogleMap } from "@angular/google-maps";
import {
  ObservableArray,
  ObservableArraySubscription,
  ReadableObservableArray,
} from "./models/ObservableArray";
import { IconPath, PathItem } from "../../classes/model/practice-path";
import { PracticePathGlobalVideoEditorPageService } from "./services/practice-path-global-video-editor-page.service";
import { Injectable } from "@angular/core";

// Az útvonal ikonok megjelenítéséért és frissítéséért felel a térképen
export class PathIconMapController {
  // key: útvonal ikon uuuid
  // value: Az útvonal ikonhoz tartozó polyline
  polylines = new Map<
    string,
    {
      polyline: google.maps.Polyline;
      markerAtPolylineBegining: google.maps.Marker;
      label: string;
      iconPath: IconPath; // Ez alapján hozza létre a polyline-t és a markert az elejére
    }
  >();

  isVisible: boolean = false;

  listeners: ObservableArraySubscription[] = [];

  constructor(
    private pathIconAssignments$: ReadableObservableArray<IconPath>,
    private map: google.maps.Map
  ) {
    this.initPolylines();
    this.addListeners();
  }

  public setDefaultPolylineOptions(iconPathUuid: string) {
    const haveAnim =
      this.polylines
        .get(iconPathUuid)
        .markerAtPolylineBegining.getAnimation() != null;
    if (!haveAnim) {
      const p = this.polylines.get(iconPathUuid);
      p.polyline.setOptions(this.getDefaultPolylineOptions(p.iconPath));
    }
  }

  public setPolylineOptions(
    iconPathUuid: string,
    color: string,
    zIndex?: number,
    strokeOpacity?: number
  ) {
    // Ha van animáció akkor nem alkalmazzuk!
    const haveAnim =
      this.polylines
        .get(iconPathUuid)
        .markerAtPolylineBegining.getAnimation() != null;
    if (!haveAnim) {
      this.polylines.get(iconPathUuid).polyline.setOptions({
        strokeColor: color,
        zIndex: zIndex,
        strokeOpacity: strokeOpacity,
      });
    }
  }

  public addArrowToHead(iconPathUuid: string, color: string) {
    this.polylines.get(iconPathUuid).polyline.setOptions({
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            fillColor: "red",
            fillOpacity: 1,
          },
          offset: "100%",
        },
      ],
    });
  }

  public removeArrowFromHead(iconPathUuid: string) {
    const haveAnim =
      this.polylines
        .get(iconPathUuid)
        .markerAtPolylineBegining.getAnimation() != null;
    if (!haveAnim) {
      this.polylines.get(iconPathUuid).polyline.setOptions({ icons: [] });
    }
  }

  public makeFlashEffect(iconPathUuid: string) {
    const p = this.polylines.get(iconPathUuid);
    p.polyline.setOptions({
      strokeColor: "red",
      strokeOpacity: 1.0,
      zIndex: 10000,
      strokeWeight: 6,
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            fillColor: "red",
            fillOpacity: 1,
          },
          offset: "100%",
        },
      ],
    });
    const marker = p.markerAtPolylineBegining;
    marker.setAnimation(google.maps.Animation.BOUNCE);

    setTimeout(() => {
      marker.setAnimation(null);

      const defaultOpts = this.getDefaultPolylineOptions(p.iconPath);
      p.polyline.setOptions(defaultOpts);
    }, 2500);
  }

  private getDefaultPolylineOptions(iconPath: IconPath) {
    return {
      clickable: false,
      draggable: false,
      zIndex: 3,
      editable: false,
      strokeColor: "blue",
      icons: [],
      strokeOpacity: 0.6,
      strokeWeight: 4,
      map: this.isVisible ? this.map : null,
      path: iconPath.path.map((icon) => ({
        lat: icon.latitude,
        lng: icon.longitude,
      })),
    };
  }

  private createPolylineFromIconPath(iconPath: IconPath) {
    return new google.maps.Polyline(this.getDefaultPolylineOptions(iconPath));
  }

  public setVisibility(isVisible: boolean) {
    this.isVisible = isVisible;
    this.polylines.forEach((value) => {
      value.polyline.setMap(isVisible ? this.map : null);
      value.markerAtPolylineBegining.setMap(isVisible ? this.map : null);
    });
  }

  private createMarkerAtBeginning(
    iconPathUuid: string,
    label: string,
    beginning: GoogleMapPosition
  ): google.maps.Marker {
    const marker = new google.maps.Marker({
      clickable: false,
      optimized: true,
      draggable: false,
      icon: MarkerUtil.getLabeledMarker("blue", "blue", label).defaultIcon,
      map: this.isVisible ? this.map : null,
      position: { lat: beginning.latitude, lng: beginning.longitude },
    });

    return marker;
  }

  private initPolylines() {
    for (let i = 0; i < this.pathIconAssignments$.length; i++) {
      const icon = this.pathIconAssignments$.getAt(i);
      this.addNewIconPath(icon, i);
    }
  }

  private addNewIconPath(iconPath: IconPath, index: number) {
    this.polylines.set(iconPath.uuid, {
      polyline: this.createPolylineFromIconPath(iconPath),
      iconPath: iconPath,
      markerAtPolylineBegining: this.createMarkerAtBeginning(
        iconPath.uuid,
        (index + 1).toString(),
        iconPath.path[0]
      ),
      label: (index + 1).toString(),
    });
  }

  private removeIconPath(iconPathUuid: string | undefined) {
    this.polylines.get(iconPathUuid ?? "")?.polyline.setMap(null);
    this.polylines
      .get(iconPathUuid ?? "")
      ?.markerAtPolylineBegining.setMap(null);
    this.polylines.delete(iconPathUuid ?? "");
  }

  private addListeners() {
    this.pathIconAssignments$.addListener(
      (action, oldValue, newValue, index) => {
        // Hozzáadunk egy polylinet
        if (action == "add") {
          if (newValue == undefined) return;
          this.addNewIconPath(newValue, index);
        }

        // Töröljük a polyline-t
        // Ekkor újra kell renderelni az összes utána következő marker-t!
        // hiszen mindegyik indexe csökkent egyel
        if (action == "remove") {
          this.removeIconPath(oldValue?.uuid);
          // Az index elemet törölte
          for (let i = index; i < this.pathIconAssignments$.length; i++) {
            this.removeIconPath(this.pathIconAssignments$.getAt(i).uuid);
            this.addNewIconPath(this.pathIconAssignments$.getAt(i), i);
          }
        }

        // Frissítjük a polyline-t
        if (action == "update") {
          if (newValue == undefined) return;
          // Töröljük és újra hozzáadjuk
          this.removeIconPath(newValue.uuid);
          this.addNewIconPath(newValue, index);
        }

        if (action == "removedAllElement") {
          this.polylines.forEach((p) => {
            p.polyline.setMap(null);
            p.markerAtPolylineBegining.setMap(null);
          });
          this.polylines.clear();
        }
      }
    );
  }

  public destroy() {
    this.listeners.forEach((element) => {
      element.unsubscribe();
    });

    this.polylines.forEach(p => p.markerAtPolylineBegining.setMap(null));
    this.polylines.forEach(p => p.polyline.setMap(null));
    this.polylines.clear();
  }
}
