import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private static readonly UserLocalStorageSettingsKey:string = "UserSettingsKey";
  private settingsMap:Map<SettingsString, any>;
  private settingsValueChangeSubject:Subject<SettingsKeyValuePair>;

  constructor() {
    this.settingsMap = new Map<SettingsString, any>();
    this.settingsValueChangeSubject = new Subject<SettingsKeyValuePair>();
  }

  public getSettingsValue<SettingValueType>(name:SettingsString, defaultValueIfNotFound:SettingValueType = null):SettingValueType {
    return this.settingsMap.get(name) ?? defaultValueIfNotFound;
  }

  public setSettingsValue<SettingValueType>(name:SettingsString, value:SettingValueType, saveToLocalStorage:boolean = true):void {
    this.settingsMap.set(name, value);
    this.settingsValueChangeSubject.next({ name, value });

    if(saveToLocalStorage) {
      this.saveSettingsToLocalStorage();
    }
  }

  public saveSettingsToLocalStorage():void {
    const savedSettingsObject:Object = {};
    this.settingsMap.forEach(
      (value:any, key:SettingsString) => {
        (savedSettingsObject as any)[key] = value;
      }
    );
    localStorage.setItem(SettingsService.UserLocalStorageSettingsKey, JSON.stringify(savedSettingsObject));
  }

  public loadSettingsFromLocalStorage():void {
    try {
      let savedSettingsObjectString:string = localStorage.getItem(SettingsService.UserLocalStorageSettingsKey) ?? "{}";
      const savedSettingsObject:Object = JSON.parse(savedSettingsObjectString);
      for (const [key, value] of Object.entries(savedSettingsObject)) {
        this.settingsMap.set(key as SettingsString, value);
      }
    } catch(error:any) {
        console.error("Failed to load the saved settings.");
        console.error(error);
    }
  }

  public resetSettings():void {
    this.settingsMap.clear();
    localStorage.removeItem(SettingsService.UserLocalStorageSettingsKey);
  }

  public getSettingsValueChangeSubscription<ValueType = any>(
    callback:(settingsKeyValuePair:SettingsKeyValuePair<ValueType>) => void, subscribedKeys?:ReadonlyArray<SettingsString>
  ):Subscription {
    return this.settingsValueChangeSubject.subscribe(
      (settingsKeyValuePair:SettingsKeyValuePair<ValueType>) => {
        if(subscribedKeys === undefined || subscribedKeys.includes(settingsKeyValuePair.name)) {
          callback(settingsKeyValuePair);
        }
      }
    );
  }

}

export class Settings {
  public static readonly savedEnvironment:SettingsString = "savedEnvironment";
  public static readonly apiLogging:SettingsString = "apiLogging";
  public static readonly isNavigatonSectionVisible:SettingsString = "isNavigatonSectionVisible";
}

export type SettingsString =
  "savedEnvironment"|
  "apiLogging"|
  "isNavigatonSectionVisible"
;

export type SettingsKeyValuePair<ValueType = any> = {
  name:SettingsString;
  value:ValueType;
}