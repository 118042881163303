import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-copyable-text',
  templateUrl: './copyable-text.component.html',
  styleUrls: ['./copyable-text.component.scss']
})
export class CopyableTextComponent implements OnInit {
  // Ezt a szöveget jelenítjük meg
  @Input()
  text: string;

  // Ezt másoljuk le
  @Input()
  clipboardText: string;



  constructor() { }

  ngOnInit(): void {
  }

}
