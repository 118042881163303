import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminPersonalGiftService } from 'src/app/services/admin-personal-gift.service';

@Component({
  selector: 'app-create-personal-gift-via-email-dialog',
  templateUrl: './create-personal-gift-via-email-dialog.component.html',
  styleUrls: ['./create-personal-gift-via-email-dialog.component.scss']
})
export class CreatePersonalGiftViaEmailDialogComponent implements OnInit {
  giftFG:FormGroup;
  emailFC:FormControl;
  reasonFC:FormControl;
  messageFC:FormControl;
  packageDefinitionFC:FormControl;

  constructor(
    private adminPersonalGiftService:AdminPersonalGiftService,
    private snackBar:MatSnackBar,
    private dialogRef:MatDialogRef<CreatePersonalGiftViaEmailDialogComponent>
  ) { }

  ngOnInit():void {
    this.emailFC = new FormControl("",  [ Validators.required, Validators.email ]);
    this.reasonFC = new FormControl("", [ Validators.required ]);
    this.messageFC = new FormControl("", [ Validators.required ]);
    this.packageDefinitionFC = new FormControl("", [ Validators.required ]);

    this.giftFG = new FormGroup({
      emailFC: this.emailFC,
      reasonFC: this.reasonFC,
      messageFC: this.messageFC,
      packageDefinitionFC: this.packageDefinitionFC
    });
  }

  public async handleCreateGiftButtonClick():Promise<void>  {
    try {
      await this.adminPersonalGiftService.handOutGiftViaEmail(this.emailFC.value, this.packageDefinitionFC.value, this.reasonFC.value, this.messageFC.value);
      this.dialogRef.close();
      this.snackBar.open("Ajándék sikeresen létrehozva a cím részére", "Bezár", { duration: 3000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}
  }

  protected getEmailError():string {
    if(this.emailFC.hasError("required")) {
      return "Kötelező mező";
    }

    if(this.emailFC.hasError('email')) {
      return "Hibás email formátum";
    }

    return "";
  }
}
