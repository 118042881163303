<app-page-header
    title = "Admin szerepkörök"
    subtitle = "Az admin menü rendszerében létező szerepköröket találhatod itt. Különböző szerepkörök különböző jogosultságokat
        tartalmaznak, az alapján, hogy az adminisztrátor a szerepköréhez tartozó feladatokat el tudja látni."
    style = "margin-bottom: 20px;"
></app-page-header>

<ng-template [ngIf] = "!isContentLoading">
    <table *ngIf = "adminRoles.length > 0">
        <thead class = "table-header">
            <td>
                ID
            </td>
            <td>
                Megjelenített név
            </td>
            <td>
                Leírás
            </td>
            <td>
                Jogosultságok
            </td>
            <td class = "edit-column">
                <!-- edit -->
            </td>
            <td class = "delete-column">
                <!-- delete -->
            </td>
        </thead>
        <tbody>
            <tr *ngFor = "let adminRole of adminRoles">
                <td>
                    {{ adminRole.name }}
                </td>
                <td>
                    {{ adminRole.displayedName }}
                </td>
                <td>
                    {{ adminRole.description ?? '-' }}
                </td>
                <td>
                    {{ adminRole.basePermissions.length }} jogosultság hozzárendelve
                </td>
                <td class = "edit-column">
                    <span class = "material-icons" (click) = "handleEditButtonClick(adminRole)">
                        edit
                    </span>
                </td>
                <td class = "delete-column">
                    <span class = "material-icons" (click) = "handleDeleteButtonClick(adminRole)">
                        delete
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf = "adminRoles.length === 0" class = "no-data">
        Nincsenek megjeleníthető szerepkörök.
    </div>
    <div class = "add-button" (click) = "handleAddButtonClick()">
        <span class = "material-icons">
            add
        </span>
    </div>
</ng-template>
<div *ngIf = "isContentLoading" class = "loading-container">
    <mat-spinner></mat-spinner>
</div>
