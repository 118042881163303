import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentsOnEntityComponent } from './comments-on-entity.component';
import { CommentListComponent } from './components/comment-list/comment-list.component';
import { CommentWriterComponent } from './components/comment-writer/comment-writer.component';
import { CommentItemComponent } from './components/comment-list/comment-item/comment-item.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommentsOnEntityDialogOpenerButtonComponent } from './components/comments-on-entity-dialog-opener-button/comments-on-entity-dialog-opener-button.component'; 
import { DraggableDialogModule } from '../draggable-dialog/draggable-dialog.module';
import { CommentsOnEntityDialogComponent } from './components/comments-on-entity-dialog/comments-on-entity-dialog.component';

@NgModule({
  declarations: [
    CommentsOnEntityComponent,
    CommentListComponent,
    CommentWriterComponent,
    CommentItemComponent,
    CommentsOnEntityDialogOpenerButtonComponent,
    CommentsOnEntityDialogComponent
  ],
  imports: [
    CommonModule,
    DragDropModule,
    MatDialogModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    DraggableDialogModule
  ],
  exports: [
    CommentsOnEntityComponent,
    CommentsOnEntityDialogOpenerButtonComponent
  ]
})
export class CommentsOnEntityModule { }
