import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Feedback } from 'src/app/classes/model/feedback';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
  selector: 'app-feedbacks',
  templateUrl: './feedbacks.component.html',
  styleUrls: ['./feedbacks.component.scss']
})
export class FeedbacksComponent implements OnInit, AfterViewInit {
  @ViewChild("paginator") paginator:MatPaginator;

  feedbacks:ReadonlyArray<Readonly<Feedback>>;
  onlyUnhandled:boolean;

  isContentLoading:boolean = false;

  displayedFeedbacks:Array<Readonly<Feedback>>;

  paginatorPageSize:number = 10;
  
  constructor(private feedbackService:FeedbackService) { }

  public ngOnInit():void {
    this.onlyUnhandled = true;
    this.toogleCheckbox(this.onlyUnhandled, false);
  }

  public ngAfterViewInit():void {
    this.paginator.firstPage();
    this.handlePageEvent({ pageIndex: 0, pageSize: this.paginatorPageSize, length: this.feedbacks.length });
  }

  public async handleReloadButtonClick():Promise<void> {
    this.isContentLoading = true;

    try {
      await this.feedbackService.loadDataIntoCache();
      this.handlePageEvent({ pageIndex: 0, pageSize: this.paginatorPageSize, length: this.feedbacks.length });
    } catch(error:any) {}

    this.isContentLoading = false;
  }

  public toogleCheckbox(newValue:boolean, shouldSetPaginator:boolean = true) {
    this.feedbacks = newValue ? this.feedbackService.getUnhandledFeedbacksArrayRef() : this.feedbackService.getFeedbacksArrayRef();
    if(shouldSetPaginator) {
      this.paginator.firstPage();
    }
    this.handlePageEvent({ pageIndex: 0, pageSize: this.paginatorPageSize, length: this.feedbacks.length });
  }

  public handlePageEvent(pageEvent:PageEvent):void {
    this.displayedFeedbacks = this.feedbacks.slice(
      pageEvent.pageSize * pageEvent.pageIndex,
      Math.min(pageEvent.pageSize * (pageEvent.pageIndex + 1), this.feedbacks.length)
    );
  }

  public handleFeedbackClosedEvent():void {
    const pageIndex:number = Math.min(Math.floor((Math.max(this.feedbacks.length - 1, 0)) / 3), this.paginator.pageIndex);
    this.paginator.pageIndex= pageIndex;
    this.handlePageEvent({
      pageIndex: pageIndex,
      pageSize: this.paginatorPageSize,
      length: this.feedbacks.length
    });
  }
}
