import { MrvegasTaskProgressDialogComponent } from './../../../../misc/mrvegas-task-progress-dialog/mrvegas-task-progress-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss']
})
export class PlaygroundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

  onTapOpenMrVegasTaskDialog() {

  }

}
