import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Category } from 'src/app/classes/model/category';
import { CategoryService } from 'src/app/services/category.service';
import { DialogMode } from 'src/app/classes/misc';
import { MediaType } from 'src/app/classes/media';
import { CustomValidators } from 'src/app/classes/custom-validators';
import { ConfirmationDialogService } from 'src/app/modules/confirmation-dialog/services/confirmation-dialog.service';

/**
 * @description This component contains the dialog for the category creation and modification. The title and the displayed buttons are based on the
 * injected data object's `dialogMode` property.
*/
@Component({
  selector: 'category-edit-dialog',
  templateUrl: './category-edit-dialog.component.html',
  styleUrls: ['./category-edit-dialog.component.scss']
})
export class CategoryEditDialogComponent implements OnInit, AfterViewInit {
  /** Relative path for the default image */
  readonly noMediaUploadedImageUrl:string = "assets/pictures/png/no-camera.png";

  // This assignments is for to enable the usage of the enum in the HTML template.
  DialogMode = DialogMode;
  MediaType = MediaType;

  /** The title of the dialog. */
  title:string;
  /** Indicates if the form was submited. */
  wasSubmitted:boolean;

  // FormGroups and FormControls
  /** FormGroup for the FormControls. */
  categoryForm:FormGroup;
  /** FormControl for the category's name input. */
  categoryNameFormControl:FormControl;
  /** FormControl for the category's description input. */
  categoryDescriptionFC:FormControl;
  /** FormControl for the category's minimum point input. */
  categoryMinPointFullFC:FormControl;
  /** FormControl for the category's exam mode's time input. */
  categoryQuestionTimeFullFC:FormControl;

  numberOfQuestionsFullFC:FormControl;

  categoryMinPointThematicFC:FormControl;

  numberOfQuestionsThematicFC:FormControl;

  isFreeFC:FormControl;

  underDevelopmentFC:FormControl;


  // Media member variables
  /** The uploaded icon's media type. If there is no locally uploaded media, it holds the media type of the stored media (on the server). */
  uploadedIconMediaType:MediaType;
  /** The uploaded header's media type. If there is no locally uploaded media, it holds the media type of the stored media (on the server). */
  uploadedHeaderMediaType:MediaType;
  /** The data of the locally uploaded icon image. */
  uploadedIconMediaData:string;
  /** The data of the locally uploaded header image. */
  uploadedHeaderMediaData:string;

  uploadedIconMedia:any;
  uploadedHeaderMedia:any;

  constructor(
    private dialogRef:MatDialogRef<CategoryEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{ dialogMode:DialogMode, category:Category },
    private categoryService:CategoryService,
    private snackBar:MatSnackBar,
    public dialog:MatDialog,
    private confirmationDialogService:ConfirmationDialogService
    ) { }

  ngOnInit():void {
    this.title = this.data.dialogMode == DialogMode.Add ? "Kategória létrehozása" : "Kategória módosítása";

    // Create and initalize the FormControls based on the DialogMode
    this.categoryNameFormControl      = new FormControl(this.data.category === null ?    "" : this.data.category.name,                              [ Validators.required ]);
    this.categoryDescriptionFC        = new FormControl(this.data.category === null ?    "" : this.data.category.description,                       [ Validators.required ]);
    this.categoryMinPointFullFC       = new FormControl(this.data.category === null ?    0  : this.data.category.minPointFullTest,                  [ Validators.required, CustomValidators.positiveWholeNumber ]);
    this.categoryQuestionTimeFullFC   = new FormControl(this.data.category === null ?    60 : this.data.category.maxTimeForOneQuestionInMs / 1000,  [ Validators.required, CustomValidators.positiveWholeNumber ]);
    this.numberOfQuestionsFullFC      = new FormControl(this.data.category === null ?    55 : this.data.category.numberOfQuestionsOfFullTest,       [ Validators.required, CustomValidators.positiveWholeNumber ]);
    this.categoryMinPointThematicFC   = new FormControl(this.data.category === null ?    0  : this.data.category.minPointThematicTest,               [ Validators.required, CustomValidators.positiveWholeNumber ]);
    this.numberOfQuestionsThematicFC  = new FormControl(this.data.category === null ?    10 : this.data.category.numberOfQuestionsOfThematicTest,   [ Validators.required, CustomValidators.positiveWholeNumber ]);
    this.isFreeFC                     = new FormControl(this.data.category === null ? false : this.data.category.isFree);
    this.underDevelopmentFC           = new FormControl(this.data.category === null ?  true : this.data.category.underDevelopment);
    // Assign the FormControls to the FormGroup
    this.categoryForm = new FormGroup({
      categoryName: this.categoryNameFormControl,
      categoryDescriptionFC: this.categoryDescriptionFC,
      categoryMinPointFullFC: this.categoryMinPointFullFC,
      categoryQuestionTimeFullFC: this.categoryQuestionTimeFullFC,
      numberOfQuestionsFullFC: this.numberOfQuestionsFullFC,
      categoryMinPointThematicFC: this.categoryMinPointThematicFC,
      numberOfQuestionsThematicFC: this.numberOfQuestionsThematicFC,
      isFreeFC: this.isFreeFC,
      underDevelopmentFC: this.underDevelopmentFC
    });

    this.wasSubmitted = false;

    // Set the uploadedIconMediaType's default value
    if(this.data.category !== null && this.data.category.iconImageUrl !== "") {
      this.uploadedIconMediaType = MediaType.Image;
    } else {
      this.uploadedIconMediaType = MediaType.None;
    }
    // Set the uploadedHeaderMediaType's default value
    if(this.data.category !== null && this.data.category.headerImageUrl !== "") {
      this.uploadedHeaderMediaType = MediaType.Image;
    } else {
      this.uploadedHeaderMediaType = MediaType.None;
    }
  }

  ngAfterViewInit():void {
    const self = this;
    // Icon media process
    const iconMediaInput:HTMLInputElement = document.getElementById("category-icon-media-input") as HTMLInputElement;
    const iconMediaPreviewImg:HTMLImageElement = document.getElementById("category-icon-media-preview-image") as HTMLImageElement;
    iconMediaInput.onchange = function() {
      self.uploadedIconMedia = iconMediaInput.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(iconMediaInput.files[0]);

      if(iconMediaInput.files[0].type === "image/jpeg") {
        self.uploadedIconMediaType = MediaType.Image;
      } else {
        self.uploadedIconMediaType = MediaType.Unsupported;
      }

      reader.onload = function(e) {
        self.uploadedIconMediaData = e.target.result.toString();
        iconMediaPreviewImg.setAttribute("src", self.uploadedIconMediaData);
      }
    }

    // Header media process
    const headerMediaInput:HTMLInputElement = document.getElementById("category-header-media-input") as HTMLInputElement;
    const headerMediaPreviewImg:HTMLImageElement = document.getElementById("category-header-media-preview-image") as HTMLImageElement;
    headerMediaInput.onchange = function() {
      self.uploadedHeaderMedia = headerMediaInput.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(headerMediaInput.files[0]);

      if(headerMediaInput.files[0].type === "image/jpeg") {
        self.uploadedHeaderMediaType = MediaType.Image;
      } else {
        self.uploadedHeaderMediaType = MediaType.Unsupported;
      }

      reader.onload = function(e) {
        self.uploadedHeaderMediaData = e.target.result.toString();
        headerMediaPreviewImg.setAttribute("src", self.uploadedHeaderMediaData);
      }
    }

  }

  /**
   * Gets the given media's URL to display. If There is no media (only when the DialogMode is 'add'), a default media image will be shown.
   *
   * @param {"icon"|"header"} media the target media
   *
   * @returns {string} the target media's URL
  */
  getMediaUrl(media:"icon"|"header"):string {
    if(this.data.category === null) {
      return this.noMediaUploadedImageUrl;
    } else {
      if(media === "icon") {
        return this.data.category.iconImageUrl;
      } else {
        return this.data.category.headerImageUrl;
      }
    }
  }

  /**
   * Checks the validity of the form. The form is valid when all FormControls are valid and an image is provided in each media inputs.
   *
   * @retruns {boolean} the validity of the form
  */
  isFormValid():boolean {
    this.wasSubmitted = true;
    return this.categoryNameFormControl.valid && this.categoryDescriptionFC.valid && this.categoryMinPointFullFC.valid && this.categoryQuestionTimeFullFC.valid &&
      this.numberOfQuestionsFullFC.valid && this.uploadedHeaderMediaType === MediaType.Image && this.uploadedIconMediaType === MediaType.Image;
  }

  /**
   * Handles the 'add button' click. The function calls the `isFormValid()` function, which checks the validity of the forms. If there is no error,
   * the corresponding category service function will be called, and the dialog will be closed. The function creates a Angular Material Snackbar
   * which will indicate the result of the operation.
   *
   * @returns {void} nothing
  */
  public handleAddButtonClick():void {
    this.categoryForm.markAllAsTouched();
    if(this.isFormValid()) {
      // Build a partial category based on the input values
      const newCategory:Partial<Category> = {
        name: this.categoryNameFormControl.value as string,
        description: this.categoryDescriptionFC.value as string,
        minPointFullTest: this.categoryMinPointFullFC.value as number,
        maxTimeForOneQuestionInMs: this.categoryQuestionTimeFullFC.value as number * 1000,
        numberOfQuestionsOfFullTest: this.numberOfQuestionsFullFC.value as number,
        minPointThematicTest: this.categoryMinPointThematicFC.value as number,
        numberOfQuestionsOfThematicTest: this.numberOfQuestionsThematicFC.value as number,
        distributionRules: [],
        questionGroups: [],
        isVisible: true,
        isFree: this.isFreeFC.value,
        underDevelopment: this.underDevelopmentFC.value
      }
      // Wrap the media members

      this.addCategory(newCategory, this.uploadedIconMedia, this.uploadedHeaderMedia);

      this.dialogRef.close();
    }

  }

  /**
   * Handles the 'delete button' click. The function calls the correspondig category service function and then creates a Angular Material Snackbar
   * which will indicate the result of the operation.
   *
   * @returns {void} nothing
  */
  handleDeleteButtonClick():void {
    this.confirmationDialogService.open(
      "Kategória törlése",
      "Biztosan törli a kategóriát?",
      async () => {
        await this.deleteCategory(this.data.category.uuid);
        this.dialogRef.close();
      }
    );
  }

  /**
   * Handles the 'modify button' click. The function calls the `isFormValid()` function, which checks the validity of the forms. If there is no error,
   * the corresponding category service function will be called, and the dialog will be closed. The function creates a Angular Material Snackbar
   * which will indicate the result of the operation.
   *
   * @returns {void} nothing
  */
  handleModifyButtonClick():void {
    if(this.isFormValid()) {
      // Build a Category based on the original and the input values
      const modifiedCategory:Category = {
        uuid: this.data.category.uuid,
        name: this.categoryNameFormControl.value as string,
        description: this.categoryDescriptionFC.value as string,
        minPointFullTest: this.categoryMinPointFullFC.value as number,
        maxPointFullTest: this.data.category.maxPointFullTest as number,
        maxTimeForOneQuestionInMs: this.categoryQuestionTimeFullFC.value as number * 1000,
        numberOfQuestionsOfFullTest: this.numberOfQuestionsFullFC.value as number,
        minPointThematicTest: this.categoryMinPointThematicFC.value as number,
        numberOfQuestionsOfThematicTest: this.numberOfQuestionsThematicFC.value as number,
        distributionRules: this.data.category.distributionRules,
        questionGroups: this.data.category.questionGroups,
        isVisible: true,
        iconImageUrl: this.data.category.iconImageUrl,
        headerImageUrl: this.data.category.headerImageUrl,
        isFree: this.isFreeFC.value,
        underDevelopment: this.underDevelopmentFC.value
      }

      // Wrap the media members
      this.modifyCategory(modifiedCategory, this.uploadedIconMedia, this.uploadedHeaderMedia);
      this.dialogRef.close();
    }
  }

  private async addCategory(newCategory:Partial<Category>, iconMedia:any, headerMedia:any):Promise<void> {
    try {
      await this.categoryService.addCategory(newCategory, iconMedia, headerMedia);
      this.snackBar.open("Kategória sikeresen hozzáadva", "Bezár", { duration: 2000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}
  }

  private async deleteCategory(categoryUuuid:string):Promise<void> {
    try {
      await this.categoryService.deleteCategory(categoryUuuid);
      this.snackBar.open("Kategória sikeresen törölve", "Bezár", { duration: 2000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}
  }

  private async modifyCategory(modifiedCategory:Category, iconMedia:any, headerMedia:any):Promise<void> {
    try {
      await this.categoryService.modifyCategory(modifiedCategory, iconMedia, headerMedia);
      this.snackBar.open("Kategória sikeresen módosítva", "Bezár", { duration: 2000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}
  }

}
