<app-page-header
    title = "Felhasználói visszajelzések"
    subtitle = "A felhasználók által az appon keresztül írt visszajelzéseket találhatod itt."
>
    <div class = "header-actions">
        <div>
            <button mat-mini-fab color = "primary" (click) = "handleReloadButtonClick()">
                <mat-icon> refresh </mat-icon>
            </button>
        </div>
        <div style = "align-self: center; justify-self: end;">
            <mat-checkbox [(ngModel)] = "onlyUnhandled" (ngModelChange) = "toogleCheckbox($event)"> Csak kezeletlen </mat-checkbox>
        </div>
    </div>
</app-page-header>

<ng-template [ngIf] = "!isContentLoading">
    <mat-paginator
        #paginator
        [length] = "feedbacks.length"
        [pageSize] = "paginatorPageSize"
        [showFirstLastButtons] = "true"
        (page) = "handlePageEvent($event)"
        class = "paginator"
    ></mat-paginator>
    <app-feedback
        *ngFor = "let feedback of displayedFeedbacks"
        [feedback] = "feedback"
        (feedbackClosed) = "handleFeedbackClosedEvent()"
    ></app-feedback>
</ng-template>

<div
    *ngIf = "!isContentLoading && onlyUnhandled && feedbacks.length === 0"
    id = "feedbacks-no-undhandled"
>
    Nincsenek kezeletlen visszajelzések.
</div>

<div *ngIf = "isContentLoading" style = "display: flex; justify-content: center;">
    <mat-spinner></mat-spinner>
</div>