import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnonymizerTask } from 'src/app/classes/model/anonymizer-task';
import { AnonymizerTaskService } from 'src/app/services/anonymizer-task.service';

export type AnonymizationProcessLogsDialogInput = {
  taskId:string;
}

@Component({
  selector: 'app-anonymizer-task-logs-dialog',
  templateUrl: './anonymizer-task-logs-dialog.component.html',
  styleUrls: ['./anonymizer-task-logs-dialog.component.scss']
})
export class AnonymizerTaskLogsDialogComponent implements OnInit {
  @ViewChild("content") contentContainerElementRef:ElementRef<HTMLElement>;

  anonymizerTask: AnonymizerTask|null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:AnonymizationProcessLogsDialogInput,
    private anonymizerTaskService:AnonymizerTaskService
  ) {

  }

  public ngOnInit():void {
    this.refreshAnonymizationProgressState();
  }

  public ngAfterViewInit():void {
    setTimeout(() => {
      this.contentContainerElementRef.nativeElement.scrollTo({  top: this.contentContainerElementRef.nativeElement.scrollHeight });
    }, 0);

  }

  public async refreshAnonymizationProgressState():Promise<void> {
    try {
      const newState:AnonymizerTask = await this.anonymizerTaskService.fetchAnonymizerTask(this.data.taskId);
      this.anonymizerTask = Object.assign(this.anonymizerTask ?? {}, newState);

      if(this.contentContainerElementRef?.nativeElement) {
        this.contentContainerElementRef.nativeElement.scrollTo({ top: this.contentContainerElementRef.nativeElement.scrollHeight });
      }
    } catch(error:any) {}
  }

}
