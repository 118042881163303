<div class = "package-group-info-header">
    <div
        class = "toggle-container"
        (click) = "toggleDetails()"
    >
        <mat-icon> {{ isDetailsShown ? 'arrow_drop_down' : 'arrow_right' }} </mat-icon>
    </div>
    <div class = "package-group-info-header-data">
        <div>
            <div [ngSwitch] = "groupedPackages.packagesType" class = "header-title">
                <span *ngSwitchCase = "'PurchasedPackage'">
                    Vásárlás időpontja:
                </span>
                <span *ngSwitchCase = "'GiftedPackage'">
                    Kiosztás időpontja:
                </span>
                <span *ngSwitchDefault>
                    Létrehozás időpontja:
                </span>
            </div>
            <div style = "white-space: nowrap;">
                {{ groupedPackages.creationTimestamp | date: "YYYY-MM-dd HH:mm" }}
            </div>
        </div>
        <div>
            <div class = "header-title">
                Csomagnév:
            </div>
            <div>
                {{ groupedPackages.displayedName }}
            </div>
        </div>
        <div>
            <div class = "header-title">
                Mennyiség:
            </div>
            <div>
                {{ groupedPackages.packages.length }}db
            </div>
        </div>
    </div>
</div>
<div class = "package-group-info-details" [style.display] = "isDetailsShown ? 'block' : 'none'">
    <div class = "package-group-summary">
        <div>
            🚀 Beváltva: {{ redeemedPackages }}db
        </div>
        <div>
            ➡️ Kiosztva: {{ onlyHandedOutPackages }}db
        </div>
        <div>
            ✅ Kiosztásra vár: {{ waitingForHandingOut }}db
        </div>
    </div>
    <div style = "overflow-y: scroll; max-height: 150px;">
        <table class = "packages-table">
            <tr>
                <th style = "text-align: end; width: 50px"> # </th>
                <th style = "text-align: start; width: 120px"> Beváltási kód </th>
                <th style = "text-align: start"> Státusz </th>
            </tr>
            <tr *ngFor = "let package of groupedPackages.packages; let i = index">
                <td style = "text-align: end;"> {{ i + 1 }} </td>
                <td> {{ package.redemptionInformation?.packageActivationCode ?? "-" }} </td>
                <td [ngSwitch] = "getPackageState(package)">
                    <span *ngSwitchCase = "'Redeemed'">
                        🚀 Beváltva
                    </span>
                    <span *ngSwitchCase = "'OnlyHandedOut'">
                        ➡️ Kiosztva 
                    </span>
                    <span *ngSwitchCase = "'WaitingForHandingOut'">
                        ✅ Kiosztásra vár
                    </span>
                    <span *ngSwitchDefault>
                        ❓ Ismeretlen állapot
                    </span>
                </td>
            </tr>
        </table>
    </div>
    
</div>