<mat-card class = "gift-card">
    <div class = "gift-card-content-info">
        <div class = "gift-details">
            Leírás: {{ globalGift.message }}
        </div>
        <div class = "package-definition">
            Csomag: {{ globalGift.packageDefinitionName }}
        </div>
        <div class = "gift-receivable-until">
            Átvehető eddig: {{ globalGift.receivableUntil | date: "YYYY.MM.dd" }}
        </div>
    </div>
    <div class = "gift-card-admin-info">
        <div>
            {{ globalGift.created.adminEmail }}
        </div>
        <div>
            {{ globalGift.created.timestamp | date: "YYYY.MM.dd. HH:mm:ss" }}
        </div>
    </div>
</mat-card>
