import { Question } from './question';

export class QuestionChange {
    type:"add"|"modify"|"delete";
    who:string;
    timestamp:number;
    rev:number;
    newQuestion?:Question;
    oldQuestion?:Question;
}

export function changeSorter(qestionChange1:QuestionChange, questionChange2:QuestionChange) {
    return questionChange2.timestamp - qestionChange1.timestamp
}