import { Injectable } from "@angular/core";
import { EntityType } from "../models/entity-types";
import { CommentsOnEntityService } from "src/app/services/comments-on-entity.service";
import { EntityComment } from "../models/entity-comment";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Permission } from "src/app/classes/model/permissions";
import { PermissionService } from "src/app/services/common/permission.service";

@Injectable()
export class CommentsOnEntityModuleService {

  private entityType:EntityType;
  private entityKeyValue:string;
  private entityComments$:BehaviorSubject<Array<EntityComment>> = new BehaviorSubject<Array<EntityComment>>([]);
  private newCommentCreated$:Subject<void> = new Subject<void>();
  private ongoingIntervalId:NodeJS.Timeout|null = null;

  constructor(
    private commentsOnEntityService:CommentsOnEntityService,
    private permissionService:PermissionService
  ) {}

  public ngOnDestroy():void {
    // If there is an ongoing interval, clear it
    if(this.ongoingIntervalId !== null) {
      clearInterval(this.ongoingIntervalId);
    }
  }

  /**
   * Gets the entity comments array subject as an observable.
   * 
   * @returns the entity comments array as an observable
   */
  public getEntityComments():Observable<Array<EntityComment>> {
    return this.entityComments$.asObservable();
  }

  /**
   * Gets and observable which fires when a new comment is created by this service.
   * 
   * @returns the observable
   */
  public newCommentCreated():Observable<void> {
    return this.newCommentCreated$.asObservable();
  }


  /**
   * Initializes the service. If this was the first initizialization it stats the interval which in every
   * `refreshInEveryMs` refreshes the entity comments. If the initialization fails `CommentsOnEntityServiceError`
   * error is thrown.
   * 
   * @param entityType 
   * @param entityKeyValue 
   * @param refreshInEveryMs 
   */
  public async initialize(entityType:EntityType, entityKeyValue:string, refreshInEveryMs:number = 10000):Promise<void> {
    this.entityType = entityType;
    this.entityKeyValue = entityKeyValue;

    if(this.hasUserCommentsOnEntityPermission() == false){
      throw new CommentsOnEntityServiceError(CommentsOnEntityServiceErrorType.UserHasNoPermission);
    }

    // If there is no interval running, start one
    if(this.ongoingIntervalId === null) {
      this.ongoingIntervalId = setInterval(this.updateCommentsOfEntity, refreshInEveryMs);
    }

    // Call the first refresh immediately
    try {
      await this.updateCommentsOfEntitySubject();
    } catch(error:any) {
      throw new CommentsOnEntityServiceError(CommentsOnEntityServiceErrorType.CommentsFetchingError, error);
    }
  }

  /**
   * Callback function that is called in every setInterval tick.
   */
  private updateCommentsOfEntity = async () => {
    try {
      await this.updateCommentsOfEntitySubject();
    } catch(error:any) {
      console.error(error);
    }
  }

  /**
   * Gets the comments of the entities via the `CommentsOnEntityService`. After the get it pushes the new
   * array into the entityComments stream. It uses the service's entityType and the entityKeyValue properties
   * to indentify the target entity, which can be set with the `initialize()` method.
   */
  private async updateCommentsOfEntitySubject():Promise<void> {
    try {
      const commentsOnEntity:Array<EntityComment> = await this.commentsOnEntityService.fetchCommentsOfEntity(
        this.entityType,
        this.entityKeyValue
      );

      this.entityComments$.next(commentsOnEntity);
    } catch(error:any) {
      // Rethrow error
      throw error;
    }
  }

  /**
   * Adds a comment to the entities via the `CommentsOnEntityService`. After the addition it gets all the
   * actual comments of the entity, and put the array into the entityComments stream. It uses the service's
   * entityType and the entityKeyValue properties to indentify the target entity, which can be set with the
   * `initialize()` method.
   * 
   * @param message the message to add as a comment to the entity
   */
  public async addCommentToEntity(message:string):Promise<void> {
    try {
      await this.commentsOnEntityService.addCommentToEntity(
        this.entityType,
        this.entityKeyValue,
        message
      );

      // Refresh the comments
      await this.updateCommentsOfEntitySubject();

      // Emit a new comment created event
      this.newCommentCreated$.next();
    } catch(error:any) {
      // Rethrow error
      throw error;
    }
  }

  /**
   * Updates the comments visibility with the given @commentUuid.
   * 
   * @param commentUuid the uuid of the comment
   * @param isVisible the new visibility value
   */
  public async updateCommentOnEntityVisibility(commentUuid:string, isVisible:boolean):Promise<void> {
    try {
      await this.commentsOnEntityService.toogleCommentVisibility(
        this.entityType,
        this.entityKeyValue,
        commentUuid,
        isVisible
      );

      // Refresh the comments
      await this.updateCommentsOfEntitySubject();
    } catch(error:any) {
      // Rethrow error
      throw error;
    }
  }

  public hasUserCommentsOnEntityPermission():boolean {
    return this.permissionService.isLoggedUserHasPermission(Permission.CommentsOnEntity)
  }
}

export class CommentsOnEntityServiceError {
  errorType:CommentsOnEntityServiceErrorType;
  error:any;

  constructor(errorType:CommentsOnEntityServiceErrorType, error?:any) {
    this.errorType = errorType;
    this.error = error;
  }
}

export enum CommentsOnEntityServiceErrorType {
  UserHasNoPermission,
  CommentsFetchingError
}