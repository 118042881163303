import { PracticePathUtil } from "../../../../../../services/practice-path-util";
import { FinalVideo } from "src/app/classes/model/practice-video";
import * as uuid from "uuid";

import { BehaviorSubject } from "rxjs";
import { EventEmitter, Injectable } from "@angular/core";
import { PathItem } from "src/app/classes/model/practice-path";

export type IconRecordingBase = {
  practiceIconUrl: string;
};

export interface IconRecordingStatusManager<
  IconRecordingStatusType extends IconRecordingBase
> {
  currentRecordings: IconRecordingStatusType[];
  createNewRecording(practiceIconUrl: string): IconRecordingStatusType;
  removeRecording(practiceIconUrl: string);
  getRecording(practiceIconUrl: string): IconRecordingStatusType | undefined;
  isRecordingValid(practiceIconUrl: string): boolean;
}

export type TimedIconRecording = {
  practiceIconUrl: string;
  startFrameMs: number;
  endFrameMs: number;
};

export type PathIconRecording = {
  practiceIconUrl: string;
  startGeoTaggedFrameIndex: number;
  endGeoTaggedFrameIndex: number;
};

export class TimedIconRecordingStatusManager
  implements IconRecordingStatusManager<TimedIconRecording>
{
  constructor(private currentVideoTimeMs: BehaviorSubject<number>) {
    this.currentVideoTimeMs.subscribe((currentTime) => {
      this.currentRecordings.forEach((r) => (r.endFrameMs = currentTime));
    });
  }

  isRecordingValid(practiceIconUrl: string): boolean {
    const recording = this.currentRecordings.find(
      (cr) => cr.practiceIconUrl == practiceIconUrl
    );
    return recording.startFrameMs <= recording.endFrameMs;
  }

  currentRecordings: TimedIconRecording[] = [];

  createNewRecording(practiceIconUrl: string): TimedIconRecording {
    const currentTimeMs = this.currentVideoTimeMs.getValue();
    const newRecording: TimedIconRecording = {
      startFrameMs: currentTimeMs,
      endFrameMs: currentTimeMs,
      practiceIconUrl: practiceIconUrl,
    };
    this.currentRecordings.push(newRecording);
    return newRecording;
  }
  removeRecording(practiceIconUrl: string) {
    this.currentRecordings.removeItems(
      (val) => val.practiceIconUrl == practiceIconUrl
    );
  }
  getRecording(practiceIconUrl: string) {
    return this.currentRecordings.find(
      (record) => record.practiceIconUrl == practiceIconUrl
    );
  }
}

export class PathIconRecordingStatusManager
  implements IconRecordingStatusManager<PathIconRecording>
{
  constructor(
    private currentVideoTimeMs: BehaviorSubject<number>,
    private geoTaggedFrames: Readonly<PathItem[]>
  ) {
    this.currentVideoTimeMs.subscribe((time) => {
      this.currentRecordings.map(
        (r) => (r.endGeoTaggedFrameIndex = this.getCurrentGeoTaggedFrameIndex())
      );
    });
  }

  isRecordingValid(practiceIconUrl: string): boolean {
    const recording = this.currentRecordings.find(
      (cr) => cr.practiceIconUrl == practiceIconUrl
    );
    return (
      recording.startGeoTaggedFrameIndex <= recording.endGeoTaggedFrameIndex
    );
  }

  currentRecordings: PathIconRecording[] = [];

  private getCurrentGeoTaggedFrameIndex(): number {
    const gf = new PracticePathUtil().getClosestGeoTaggedFrameToVideoPosition(
      this.geoTaggedFrames,
      this.currentVideoTimeMs.getValue()
    );
    return this.geoTaggedFrames.indexOf(gf);
  }

  createNewRecording(practiceIconUrl: string): PathIconRecording {
    const currentIndex = this.getCurrentGeoTaggedFrameIndex();
    const newRecording: PathIconRecording = {
      startGeoTaggedFrameIndex: currentIndex,
      endGeoTaggedFrameIndex: currentIndex,
      practiceIconUrl: practiceIconUrl,
    };
    this.currentRecordings.push(newRecording);
    return newRecording;
  }

  removeRecording(practiceIconUrl: string) {
    this.currentRecordings.removeItems(
      (val) => val.practiceIconUrl == practiceIconUrl
    );
  }

  getRecording = (practiceIconUrl: string) =>
    this.currentRecordings.find(
      (record) => record.practiceIconUrl == practiceIconUrl
    )
}

export class IconRecorderService<
  IconRecordingStatusType extends IconRecordingBase
> {
  public onFinishedRecording = new EventEmitter<IconRecordingStatusType>();
  currentRecordingChanged = new EventEmitter<void>();

  constructor(
    private iconRecordingStatusManager: IconRecordingStatusManager<IconRecordingStatusType>
  ) {}

  startRecording(practiceIconUrl: string): void {
    if (this.isRecordInProgress(practiceIconUrl)) {
      // Ha ugyanazt az ikont újra el akarja kezdeni felvenni, akkor az előző megszakítja
      this.cancelRecording(practiceIconUrl);
    }

    this.iconRecordingStatusManager.createNewRecording(practiceIconUrl);
    this.currentRecordingChanged.next();
  }

  finishRecording(practiceIconUrl: string) {
    const recording =
      this.iconRecordingStatusManager.getRecording(practiceIconUrl);
    this.iconRecordingStatusManager.removeRecording(practiceIconUrl);
    this.onFinishedRecording.next(recording);
    this.currentRecordingChanged.next();
  }

  isValidRecording(practiceIconUrl: string) {
    return this.iconRecordingStatusManager.isRecordingValid(practiceIconUrl);
  }

  cancelRecording(practiceIconUrl: string) {
    this.iconRecordingStatusManager.removeRecording(practiceIconUrl);
    this.currentRecordingChanged.next();
  }

  isRecordInProgress(practiceIconUrl: string) {
    return (
      this.iconRecordingStatusManager.getRecording(practiceIconUrl) != undefined
    );
  }

  getRecordingIconUrls(): string[] {
    return this.iconRecordingStatusManager.currentRecordings.map(
      (cr) => cr.practiceIconUrl
    );
  }

  getAllRecording(): IconRecordingStatusType[] {
    return this.iconRecordingStatusManager.currentRecordings;
  }
}
