<app-page-header
    title = "Viszonteladók"
    subtitle = "A Mr. Kresz Csomagok rendszerébe regisztrált viszonteladók listáját találhatod és szerkesztheted itt."
    style = "margin-bottom: 20px;"
></app-page-header>

<ng-template [ngIf] = "!isContentLoading">
    <div class = "vendors-table-container">
        <table class = "vendors-table">
            <tr>
                <th style = "text-align: start;"> Név </th>
                <th style = "text-align: start;"> E-mail cím </th>
                <th style = "text-align: start; width: 120px;"> Telefonszám </th>
                <th style = "text-align: center; width: 120px;"> Szerződött </th>
                <th style = "width: 120px;"></th>
            </tr>
            <tr *ngFor = "let vendor of vendors">
                <td> {{ vendor.name }} </td>
                <td> {{ vendor.email }} </td>
                <td> {{ vendor.phoneNumber }} </td>
                <td style = "text-align: center;">
                    <span *ngIf = "vendor.hasContract">
                        ✅
                    </span>
                </td>
                <td>
                    <button
                        class = "vendor-details-button"
                        (click) = "openVendorInfoDialog(vendor)"
                    >
                        <mat-icon
                            class = "info-icon"
                        > info </mat-icon>
                        Részletek
                    </button>
                </td>
            </tr>
        </table>
    </div>
</ng-template>
<div *ngIf = "isContentLoading" class = "loading-container">
    <mat-spinner></mat-spinner>
</div>
<button
    mat-fab
    class = "add-button"
    color = "primary"
    (click) = "onAddVendorButtonClick()"
    [disabled] = "!hasAdminVendorWritePermission()"
    [matTooltip] = "'Nincs jogosultságod viszonteladó létrehozásához.'"
    [matTooltipDisabled] = "hasAdminVendorWritePermission()"
>   
    <mat-icon> add </mat-icon>
</button>