<section id = "categories-section">
    <app-page-header
        title = "Kategóriák"
        subtitle = "Az elméleti modulban elérhető jogosítvány kategóriákat találod itt."
        style = "margin-bottom: 20px;"
    >
        <button mat-mini-fab color = "primary" (click) = "handleReloadButtonClick()">
            <mat-icon> refresh </mat-icon>
        </button>
    </app-page-header>
    <div id = "categories-content">
        <div *ngIf = "isContentLoading" id = "loading-container">
            <mat-spinner></mat-spinner>
        </div>
        <app-category
            *ngFor = "let category of categories"
            [category] = "category"
            [questionGroups] = "questionGroups"
            style = "margin-bottom: 10px;"
        ></app-category>
        <div id = "new-category-button-container">
            <button mat-fab color = "primary" (click) = "showAddCategoryDialog()">
                <mat-icon> add </mat-icon>
            </button>
        </div>
    </div>
</section>