<div style = "font-size: 1.5em; margin-bottom: 10px;">
    {{ title }}
</div>

<form [formGroup] = "practiceCityFG">
    <div>
         <mat-form-field appearance='outline' style = "width: 100%;">
            <mat-label>Vizsgahelyszín</mat-label>
            <input matInput [formControl] = "cityFC" />
            <mat-error>Kötelező mező!</mat-error>
        </mat-form-field>
    </div>
    <div style="margin-top:15px;">
         <mat-form-field appearance='outline' style = "width: 100%;">
            <mat-label>Vizsgatételek száma</mat-label>
            <input matInput type = "number" min = "1" [formControl] = "positionsFC" />
            <mat-error *ngIf = "positionsFC.hasError('required')">Kötelező mező!</mat-error>
            <mat-error *ngIf = "positionsFC.hasError('min')">Legalább 1-nek kell lennie!</mat-error>
            <mat-error *ngIf = "!positionsFC.hasError('min') && positionsFC.hasError('wholeNumber')">Egész számnak kell lennie!</mat-error>
        </mat-form-field>
    </div>
    <div>
        <mat-checkbox [formControl] = "isVisibleFC"> Látható </mat-checkbox>
    </div>
</form>

<mat-dialog-actions align = "end">
    <button mat-raised-button mat-dialog-close>Vissza</button>
    <button
        mat-raised-button
        color = "primary"
        cdkFocusInitial
        (click) = "handleSaveButtonClick()"
        [disabled] = "practiceCityFG.invalid"
    > Mentés </button>
</mat-dialog-actions>

