<div
    class = "message-wrapper"
    [class.own-comment] = "isOwnComment"
>
    <div class = "message-bubble" [class.hidden] = "entityComment.isVisible === false">
        <div class = "message-header">
            <div class = "comment-creator">
                {{ adminName }}
            </div>
            <div class = "comment-date">
                {{ entityComment.created.timestamp | date: "YYYY-MM-dd HH:mm:ss" }}
            </div>
        </div>
        <div class = "message-body">
            {{ entityComment.message }}
        </div>
        <div
            *ngIf = "entityComment.isVisible === false"
            class = "message-hidden-info"
        >
            A megjegyzést a felhasználó elrejtette.
        </div>
    </div>
    <div class = "message-actions">
        <div
            *ngIf = "isOwnComment && entityComment.isVisible"
            class = "message-action"
            (click) = "toolgeMessageVisibility(false)"
         >
            <span class = "material-icons"> delete </span>
        </div>
        <div
            *ngIf = "isUserServerManager && !entityComment.isVisible"
            class = "message-action"
            (click) = "toolgeMessageVisibility(true)"
         >
            <span class = "material-icons"> settings_backup_restore </span>
        </div>
    </div>
</div>

