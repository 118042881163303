import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MrvegasTaskProgressDialogComponent } from './../../../../../misc/mrvegas-task-progress-dialog/mrvegas-task-progress-dialog.component';
import { PracticePathCutReleasedVideoService } from './../../../../../../services/practice-path-cut-released-video-service';
import { NotifierService } from 'src/app/services/common/notifier-service.service';
import { PracticePathUtil } from './../../../../../../services/practice-path-util';
import { WritableObservableArray } from './../../../../../../modules/practice-path-video-editor-page/models/ObservableArray';
import { MarkeredPolylineController } from './../../../../../../modules/practice-path-video-editor-page/components/google-map-extended/markered-polyline-controller';
import { PracticeCity } from './../../../../../../classes/model/practice-city';
import { VideoPlayerController } from './../../../../../../modules/shared-module/components/video-player/video-player.controller';
import { GoogleMapExtendedController } from './../../../../../../modules/practice-path-video-editor-page/components/google-map-extended/google-map-controller';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PracticePath } from './../../../../../../classes/model/practice-path';
import { Component, Inject, OnInit } from '@angular/core';
import { LatLng } from 'src/app/modules/practice-path-video-editor-page/models/LatLng';
import moment from 'moment';
export type PracticePathVideoCutterDialogInput = {
  practicePath: PracticePath
  city: PracticeCity
}

@Component({
  selector: 'app-practice-path-video-cutter-dialog',
  templateUrl: './practice-path-video-cutter-dialog.component.html',
  styleUrls: ['./practice-path-video-cutter-dialog.component.scss']
})
export class PracticePathVideoCutterDialogComponent implements OnInit {
  protected startMs: number = 0;
  protected endMs: number = 0;
  protected newPracticePathName: string = "";
  protected startCloudInstance: boolean = true;


  // Akkor marad null, ha nincs a practicePath-nek releasedVideo
  protected videoPlayerController: VideoPlayerController | null = null;


  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: PracticePathVideoCutterDialogInput,
    private practicePathUtil: PracticePathUtil,
    private notifier: NotifierService,
    private cutService: PracticePathCutReleasedVideoService,
    private dialogService: MatDialog,
    private loaderService: NgxUiLoaderService
  ) {
    if (data.practicePath.video?.releasedVideo != null) {
      this.videoPlayerController = new VideoPlayerController(data.practicePath.video!.releasedVideo!.videoHlsUrl);
    }

    console.log(data);
    console.log(data.practicePath.video?.releasedVideo == null);
  }

  public static openDialog(matDialogService: MatDialog, input: PracticePathVideoCutterDialogInput) {
    matDialogService.open<PracticePathVideoCutterDialogComponent, PracticePathVideoCutterDialogInput>(
      PracticePathVideoCutterDialogComponent,
      {
        data: input
      }
    );
  }


  ngOnInit(): void { }

  onInitedGoogleMapController(googleMapController: GoogleMapExtendedController) {
    const pathAsObservableArray = WritableObservableArray.fromPodArray(this.data.practicePath.path);

    const pathMarkeredPolylineController = new MarkeredPolylineController(pathAsObservableArray, {
      zIndex: 1,
      displayedName: "Sárga pöttyök",
      id: "orange_path",
      isDraggableMarkers: false,
      isMultiSelectable: false,
      maximumVisibleMarkerOnMap: 500,
      clickable: false,
      polylineColor: "orange"
    });

    pathMarkeredPolylineController.isMarkerVisible = false;

    googleMapController.addMarkeredPolylineController(pathMarkeredPolylineController);

    const firstPathItem = this.data.practicePath.path[0];

    googleMapController.setCenter({
      latitude: firstPathItem.position.latitude,
      longitude: firstPathItem.position.longitude
    });

    googleMapController.onTap.subscribe((posi: LatLng) => {
      const frame = this.practicePathUtil.getClosestGeoTaggedFrameToGeoLocation(
        this.data.practicePath.path,
        posi
      );
      this.videoPlayerController.seekTo(frame.millisecOfFrame);
      console.log(frame.millisecOfFrame + "ms");
    });
  }

  isValidInput() {
    console.log(this.newPracticePathName);
    if (this.newPracticePathName.length == 0) {
      this.notifier.notify('error', 'Adj meg egy nevet az új útvonalnak');
      return false;
    }

    if (this.startMs > this.endMs) {
      this.notifier.notify('error', 'A kezdő pontnak kisebbnek kell lennie, mint a végpontnak');
      return false;
    }

    if (Math.abs(this.startMs - this.endMs) < 4000) {
      this.notifier.notify("error", "A kezdő és végpont között legalább 4 másodpercnek kell lennie");
      return false;
    }

    return true;
  }

  async onTapCut() {
    if (!this.isValidInput()) return;

    this.loaderService.start();
    try {
      const taskId = await this.cutService.cutAndMakeNewPracticePath(
        this.data.practicePath.uuid,
        this.newPracticePathName,
        this.startMs,
        this.endMs,
        this.startCloudInstance
      );

      this.loaderService.stop();
      const dialogRef = MrvegasTaskProgressDialogComponent.openDialog(this.dialogService, {
        taskId: taskId,
        dialogTitle: this.createMrVegasProgressDialogTitle(
          this.newPracticePathName,
          this.data.practicePath.name,
          this.startMs,
          this.endMs
        )
      });
    } catch (e) {
      this.notifier.notify('error', 'Task létrehozása közben hiba történt');
      this.loaderService.stop();
    }
  }

  private createMrVegasProgressDialogTitle(
    newPracticePathName:string,
    originalPracticePathName:string,
    startMs:number,
    endMs:number
  ):string {
    const startTimeString:string = moment(startMs).format("mm:ss.SSS");
    const endTimeString:string = moment(endMs).format("mm:ss.SSS");
    return `"${newPracticePathName}" létrehozása a(z) ${originalPracticePathName} videóból (${startTimeString} - ${endTimeString})`;
  }

}
