import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export class UploadProgress {
  value:number;
  text:string;
}


@Component({
  selector: 'app-file-upload-indicator-dialog',
  templateUrl: './file-upload-indicator-dialog.component.html',
  styleUrls: ['./file-upload-indicator-dialog.component.scss']
})
export class FileUploadIndicatorDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data:{ uploadProgress:UploadProgress }) { }

  ngOnInit(): void { }
  
}
