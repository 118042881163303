import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PracticePathCacherService } from 'src/app/services/cacher-services/practice-path-cacher.service';
import { CachedPracticePath } from '../../../models/cached-entities.model';
import { PracticePathLog } from '../../../models/practical-module-log.model';
import { getDisplayedEntityAndEventName } from '../../../models/practice-module-event-log-groups.model';
import { PracticalModuleLogsDataService } from '../../../services/practical-module-logs-data.service';
import { EntityCachingState, PracticalModuleLogsEntityCacherHelperService } from '../../../services/practical-module-logs-entity-cacher-helper.servce';
import { PracticalModuleLogsDialogComponent } from '../../practical-module-logs-dialog/practical-module-logs-dialog.component';

@Component({
  selector: 'app-practice-path-log-item-header',
  templateUrl: './practice-path-log-item-header.component.html',
  styleUrls: ['./practice-path-log-item-header.component.scss']
})
export class PracticePathLogItemHeaderComponent implements OnInit {
  @Input() practicePathLog:PracticePathLog;
  
  protected displayedEntityAndEventName:string;
  
  protected practicePathName:string|null;
  
  protected EntityCachingState = EntityCachingState;

  protected practicePathCachingState:EntityCachingState;

  constructor(
    private practicalModuleLogsDataService:PracticalModuleLogsDataService,
    private dialogService:MatDialog,
    private practicePathCacherService:PracticePathCacherService,
    private practicalModuleLogsEntityCacherHelperService:PracticalModuleLogsEntityCacherHelperService
  ) { }

  ngOnInit(): void {
    // Get the displayed entity name and operation name
    this.displayedEntityAndEventName = getDisplayedEntityAndEventName(this.practicePathLog);

    // Get the related practice path from the caching service
    this.practicePathCachingState = this.practicalModuleLogsEntityCacherHelperService.getPracticePathCachingState(
      this.practicePathLog.loggedEntityKey
    );

    if(this.practicePathCachingState === EntityCachingState.CachedAndExist) {
      const cachedPracticePath:CachedPracticePath|null = this.practicePathCacherService.getPracticePathFromCache(
        this.practicePathLog.loggedEntityKey
      ).practicePath as CachedPracticePath;

      this.practicePathName = cachedPracticePath.name;
    }
  }

  /**
   * Determines if the provided entity key is the same as the entity which logs is currently displayed.
   * 
   * @returns true if the entity is the same, false otherwise
   */
  protected isLogEntitySameAsOpenedLoggedEntity(entityUuid:string):boolean {
    return this.practicalModuleLogsDataService.getEntityKeyAsString() === entityUuid;
  }

  /**
   * Opens the log's practice path's logs in a new dialog.  
   */
  protected openPracticePathLogsInDialog():void {
    if(this.isLogEntitySameAsOpenedLoggedEntity(this.practicePathLog.loggedEntityKey)) {
      return;
    }

    PracticalModuleLogsDialogComponent.open(
      this.dialogService,
      {
        entityType: "practice_path",
        entityKey: this.practicePathLog.loggedEntityKey,
        dialogTitle: "Útvonal logok"
      }
    );
  }

}
