import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Modules
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field'; 
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

// Components
import { PracticalModuleLogsComponent } from './practical-module-logs.component';
import { PracticeModuleLogsFilterComponent } from './components/practice-module-logs-filter/practice-module-logs-filter.component';
import { PracticalModuleLogsListComponent } from './components/practical-module-logs-list/practical-module-logs-list.component';
import { PracticalModuleLogItemComponent } from './components/practical-module-log-item/practical-module-log-item.component';
import { PracticalModuleLogsDialogComponent } from './components/practical-module-logs-dialog/practical-module-logs-dialog.component';
import { PracticalModuleLogsApiService } from './services/practical-module-logs-api.service';
import { PracticePathLogItemHeaderComponent } from './components/practical-module-log-item/practice-path-log-item-header/practice-path-log-item-header.component';
import { CriticalPointLogItemHeaderComponent } from './components/practical-module-log-item/critical-point-log-item-header/critical-point-log-item-header.component';
import { CriticalPointAssignmentLogItemHeaderComponent } from './components/practical-module-log-item/critical-point-assignment-log-item-header/critical-point-assignment-log-item-header.component';
import { PracticePathLogItemContentComponent } from './components/practical-module-log-item/practical-module-log-item-content/practice-path-log-item-content/practice-path-log-item-content.component';
import { CriticalPointLogItemContentComponent } from './components/practical-module-log-item/practical-module-log-item-content/critical-point-log-item-content/critical-point-log-item-content.component';
import { CriticalPointAssignmentLogItemContentComponent } from './components/practical-module-log-item/practical-module-log-item-content/critical-point-assignment-log-item-content/critical-point-assignment-log-item-content.component';
import { GeneralLogItemContentComponent } from './components/practical-module-log-item/practical-module-log-item-content/general-log-item-content/general-log-item-content.component';
import { PracticalModuleLogsContentComponent } from './components/practical-module-logs-content/practical-module-logs-content.component';
import { PracticalModuleLogsContentInfoDialogComponent } from './components/practical-module-logs-content-info-dialog/practical-module-logs-content-info-dialog.component';
import { SharedComponentsModule } from '../shared-module/shared-module.module';

@NgModule({
  declarations: [
    PracticalModuleLogsComponent,
    PracticeModuleLogsFilterComponent,
    PracticalModuleLogsListComponent,
    PracticalModuleLogItemComponent,
    PracticalModuleLogsDialogComponent,
    PracticePathLogItemHeaderComponent,
    CriticalPointLogItemHeaderComponent,
    CriticalPointAssignmentLogItemHeaderComponent,
    PracticePathLogItemContentComponent,
    CriticalPointLogItemContentComponent,
    CriticalPointAssignmentLogItemContentComponent,
    GeneralLogItemContentComponent,
    PracticalModuleLogsContentComponent,
    PracticalModuleLogsContentInfoDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTooltipModule,
    NgxJsonViewerModule,
    SharedComponentsModule
  ],
  exports: [
    PracticalModuleLogsComponent
  ],
  providers: [
    PracticalModuleLogsApiService
  ]
})
export class PracticalModuleLogsModule { }
