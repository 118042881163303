import { ConfirmationDialogWithReasonComponent } from './../confirmation-dialog-with-reason/confirmation-dialog-with-reason.component';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PracticePathBaseInformations } from 'src/app/classes/model/practice-path';
import { AnonymizedVideo } from 'src/app/classes/model/practice-video';
import { PracticeVideoService } from 'src/app/services/practice-video.service';
import { AnonymizerTaskLogsDialogComponent } from './anonymizer-task-logs-dialog/anonymizer-task-logs-dialog.component';
import { AnonymizerTask } from 'src/app/classes/model/anonymizer-task';
import { ResourceService } from 'src/app/services/common/resource.service';
import { NotifierService } from 'src/app/services/common/notifier-service.service';

@Component({
  selector: 'app-anonymized-videos-tab',
  templateUrl: './anonymized-videos-tab.component.html',
  styleUrls: ['./anonymized-videos-tab.component.scss']
})
export class AnonymizedVideosTabComponent implements OnInit {
  @Input() anonymizedVideos: Array<AnonymizedVideo>;
  @Input() practicePath: PracticePathBaseInformations;
  @Input() selectedIndex: number | null = null;
  @Input() anonymizerTask: AnonymizerTask | null;

  @Output() selectedAnonymizedIndexChanged: EventEmitter<number>;

  promotingIndex: number | null = null;

  anonymizedVideoDeletionsInProgress: Array<string> = [];

  constructor(
    private renderer: Renderer2,
    private practiceVideoService: PracticeVideoService,
    private dialogService: MatDialog,
    private snackBarService: MatSnackBar,
    private notifierService: NotifierService,
    private resourceService: ResourceService,
  ) {
    this.selectedAnonymizedIndexChanged = new EventEmitter<number>();
  }

  ngOnInit(): void { }

  public selectIndex(newIndex: number): void {
    if (newIndex !== this.selectedIndex) {
      this.selectedAnonymizedIndexChanged.emit(newIndex);
    }
  }

  public download(event: MouseEvent, index: number): void {
    if (!this.anonymizedVideos[index].isAnonymizedTaskFinished) {
      return;
    }

    event.stopPropagation();

    const ancorElement: HTMLAnchorElement = this.renderer.createElement('a');
    ancorElement.setAttribute('target', '_blank');
    ancorElement.setAttribute('href', this.anonymizedVideos[index].videoUrl);
    ancorElement.setAttribute('download', `${this.practicePath.name.replace(" ", "_")}_${this.anonymizedVideos[index].uuid}.mp4`);
    document.body.appendChild(ancorElement);
    ancorElement.click();
    ancorElement.remove();
  }

  public async finalize(index: number): Promise<void> {
    if (this.promotingIndex !== null || !this.anonymizedVideos[index].isAnonymizedTaskFinished) {
      return;
    }

    if (this.anonymizedVideos[index].isPermanentDeleted) {
      this.notifierService.notify('error', 'Ez a videó nem véglegesíthető, mivel törölve lett!');
      return;
    }

    this.promotingIndex = index;

    // Check with a HEAD request that the anonimized video is available
    const isAnonimizedVideoAvailable: boolean = await this.resourceService.isResourceAvailable(
      this.anonymizedVideos[index].videoUrl
    );

    // If the anonimized video is not available
    if (!isAnonimizedVideoAvailable) {
      // Show an error message and terminate the method
      this.notifierService.notify("error", "Az anonimizált videó nem elérhető.");
      this.promotingIndex = null;
      return;
    }

    try {
      await this.practiceVideoService.promoteAnonymizedVideo(this.practicePath, this.anonymizedVideos[index].uuid);
      this.snackBarService.open("Sikeres videó véglegesítés", "Bezár", { duration: 3000, panelClass: ["mat-snackbar-success"] })
    } catch (error: any) { }

    this.promotingIndex = null;
  }

  public openLogs(index: number): void {
    this.selectIndex(index);


    this.dialogService.open(AnonymizerTaskLogsDialogComponent, {
      data: {
        anonymizerTask: this.anonymizerTask,
        taskId: this.anonymizedVideos[index].anonymizerTaskId
      }
    });
  }

  protected async deleteAnoymizedVideo(index: number): Promise<void> {



    const dialog = ConfirmationDialogWithReasonComponent.openDialog(this.dialogService, {
      title: "Videó törlése",
      onYes: async (reason: string) => {
        dialog.close();
        this.anonymizedVideoDeletionsInProgress.push(this.anonymizedVideos[index].uuid);
        try {
          await this.practiceVideoService.deleteAnonymizedVideo(this.practicePath, this.anonymizedVideos[index].uuid, reason);
          this.notifierService.notify('success', 'A videó sikeresen törölve lett!');
        } catch (e) {
          console.error("anonymized video deletion failed", e);
        }
        this.anonymizedVideoDeletionsInProgress.removeItems(item => item === this.anonymizedVideos[index].uuid);
      },
      onNo: () => {
        dialog.close()
      }
    });




  }

  protected isAnonymizedVideoDeletionInProgress(index: number) {
    return this.anonymizedVideoDeletionsInProgress.includes(this.anonymizedVideos[index].uuid);
  }

}
