import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EnvironmentService } from '../common/environments/environment.service';

@Injectable({
  providedIn: 'root'
})
export class UnavailablePathOnProductionGuardService  {
  private readonly unavailablePathFragments:Array<string> = [
    "/dashboard/practice"
  ];

  constructor(
    private router:Router,
    private environmentService:EnvironmentService
  ) { }

  public canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):boolean {
    if(this.environmentService.getCurrentEnvironment().type !== "production") {
      return true;
    }

    let isUnavailableOnProduction:boolean = this.unavailablePathFragments.some(
      (unavailablePathFragment:string) => {
        return state.url.includes(unavailablePathFragment);
      }
    );

    if(isUnavailableOnProduction) {
      this.router.navigate(["dashboard/unavailable-on-production"]);
    }
      
    return true;
  }

  
}
