import { Injectable } from '@angular/core';
import { DataCacherService } from './data-cacher-service';
import { HttpResponseData } from '../classes/http-communication';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { Instructor } from '../classes/model/instructor';
import { Permission, PermissionString } from '../classes/model/permissions';

@Injectable({
  providedIn: 'root'
})
export class InstructorService implements DataCacherService {
  private readonly instructorsApiUrlFragment:string = "/api/admin/instructors";
  private readonly instructorsRegistrateApiUrlFragment:string = "/api/admin/instructors/registrate";

  private instructors:Array<Instructor>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.InstructorRead ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) {
    this.instructors = new Array<Instructor>();
  }

  public getInstructorsArrayRef():ReadonlyArray<Instructor> {
    return this.instructors;
  }

  public async fetchInstructors():Promise<Array<Instructor>> {
    let instructors:Array<Instructor> = [];
    try {
      const response:HttpResponseData<Array<Instructor>> = await this.backendService.callApi(this.instructorsApiUrlFragment, "GET");
      instructors = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a oktatók lekérdezése közben.");
    }

    return instructors;
  }

  public async registrateInstructor(instructorFields:Partial<Instructor>, registrateAsVendor:boolean = false) {
    const httpBody:Object = {
      instructorFields: instructorFields,
      registrateAsVendor: registrateAsVendor
    };

    try {
      const response:{ instructor:Instructor } = await this.backendService.callApi(this.instructorsRegistrateApiUrlFragment, "POST", httpBody);
      this.instructors.push(response.instructor);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az oktató regisztrációja közben.");
    }
  }

  public async loadDataIntoCache():Promise<void> {
    const instructors:Array<Instructor> = await this.fetchInstructors();
    this.instructors.replaceArrayElements(instructors);
  }

  public clearCachedData():void {
    this.instructors.length = 0;
  }

  public getRequiredPermissionsForDataLoading(): ReadonlyArray<PermissionString> {
    return this.requiredPermissionsForDataLoading;
  }

  public getNameOfCachedData(): string {
    return "Oktatók";
  }
}
