<app-page-header
    title = "Adminisztrátorok"
    subtitle = "Az admin menü rendszer felhasználóit találhatod itt."
    style = "margin-bottom: 20px;"
></app-page-header>

<ng-template [ngIf]  = "!isContentLoading">
    <table *ngIf = "displayedAdminUsers.length > 0">
        <thead class = "table-header">
            <td>
                <!-- Server manger -->
            </td>
            <td>
                Név
            </td>
            <td>
                E-mail cím
            </td>
            <td>
                Szerepkör
            </td>
            <td>
                Jogosultságok
            </td>
            <td class = "practical-module-logs-column">
                <!-- Practical module logs -->
            </td>
            <td class = "edit-column">
                <!-- Edit -->
            </td>
        </thead>
        <tbody>
            <tr *ngFor = "let adminUser of displayedAdminUsers">
                <td>
                    <span *ngIf = "adminUser.isServerManager"> 👑 </span>
                </td>
                <td>
                    {{ adminUser.username }}
                </td>
                <td>
                    {{ adminUser.email }}
                </td>
                <td>
                    <div *ngFor = "let roleName of adminUser.displayedRoleNames">
                        {{ roleName }}
                    </div>
                </td>
                <td>
                    {{ adminUser.permissions.length }} jogosultság hozzárendelve
                </td>
                <td class = "practical-module-logs-column">
                    <span class = "material-icons" (click) = "opePracticalModuleLogsForAdminUser(adminUser)">
                        assignment
                    </span>
                </td>
                <td class = "edit-column">
                    <span class = "material-icons" (click) = "handleEditButtonClick(adminUser)">
                        edit
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf = "displayedAdminUsers.length === 0" class = "no-data">
        Nincsenek megjeleníthető adminisztrátorok.
    </div>
    <div class = "add-button" (click) = "handleAddButtonClick()">
        <span class = "material-icons">
            add
        </span>
    </div>
</ng-template>
<div *ngIf = "isContentLoading" class = "loading-container">
    <mat-spinner></mat-spinner>
</div>