import { Injectable } from '@angular/core';
import { DataCacherService } from './data-cacher-service';
import { PackageDefinition } from '../modules/package-definitions-page/models/package-definitions.model';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { Permission, PermissionString } from '../classes/model/permissions';

@Injectable({
  providedIn: 'root'
})
export class PackageDefinitionService implements DataCacherService {
  private readonly packageDefinitionsApiUrlFragment:string = "/api/admin/package_definitions"

  private packageDefinitions:Array<PackageDefinition>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.PackageDefinitionRead ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService,
  ) {
    this.packageDefinitions = new Array<PackageDefinition>();
  }

  public getPackageDefinitionsArrayRef():Array<PackageDefinition> {
    return this.packageDefinitions;
  }

  public async fetchPackageDefinitons():Promise<Array<PackageDefinition>> {
    let packageDefinitions:Array<PackageDefinition> = [];

    try {
      const response:{ packageDefinitions:Array<PackageDefinition> } = await this.backendService.callApi(this.packageDefinitionsApiUrlFragment, "GET");
      packageDefinitions = response.packageDefinitions;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a csomagdefiníciók lekérdezésekor!");
    }

    return packageDefinitions;
  }

  public async loadDataIntoCache():Promise<void> {
    const packageDefinitions:Array<PackageDefinition> = await this.fetchPackageDefinitons();
    this.packageDefinitions.replaceArrayElements(packageDefinitions);
  }

  public clearCachedData():void {
    this.packageDefinitions.length = 0;
  }

  public getRequiredPermissionsForDataLoading(): ReadonlyArray<PermissionString> {
    return this.requiredPermissionsForDataLoading;
  }

  public getNameOfCachedData(): string {
    return "Csomagdefiníciók";
  }
}
