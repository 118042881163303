<!-- Dialog header -->
<div>
    <div *ngIf="data.dialogTitle" class = "dialog-title">
        {{ data.dialogTitle }}
    </div>
    <div style = "font-size: 0.9rem;">
        <div>
            <span style = "font-weight: 500;">ID:</span> {{ data.entityKey }}
        </div>
        <div *ngIf = "data.entityName">
            <span style = "font-weight: 500;">Név:</span> {{ data.entityName }}
        </div>
    </div>
</div>
<!-- Dialog content -->
<div class = "practical-module-logs-wrapper">
    <app-practical-module-logs
        [entityType] = "data.entityType"
        [entityKey] = "data.entityKey"
    ></app-practical-module-logs>
</div>
