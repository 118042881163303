import { Component, Input, OnInit } from '@angular/core';
import { AppNotification } from 'src/app/classes/model/app-notification';

@Component({
  selector: 'app-user-notification-card',
  templateUrl: './user-notification-card.component.html',
  styleUrls: ['./user-notification-card.component.scss']
})
export class UserNotificationCardComponent implements OnInit {
  @Input() appNotification:AppNotification;

  addressed:string;

  constructor() { }

  ngOnInit(): void {
    this.addressed = this.appNotification.type === "all" ? "mindenki" : this.appNotification.userUuid;
  }

}
