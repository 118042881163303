import { Component, Input, OnInit } from '@angular/core';
import { Package } from 'src/app/classes/model/packages/Package';
import { GroupedPackages } from '../../models/grouped-packages';

@Component({
  selector: 'app-vendor-package-group-info',
  templateUrl: './vendor-package-group-info.component.html',
  styleUrls: ['./vendor-package-group-info.component.scss']
})
export class VendorPackageGroupInfoComponent implements OnInit {
  @Input() groupedPackages:GroupedPackages;

  protected onlyHandedOutPackages:number = 0;
  protected redeemedPackages:number = 0;
  protected waitingForHandingOut:number = 0;

  protected isDetailsShown:boolean = false;

  constructor() { }

  ngOnInit(): void {
    // Calculate the handed out and redeemed packages
    for(const packageElement of this.groupedPackages.packages) {
      switch(this.getPackageState(packageElement)) {
        case "Redeemed":
          ++this.redeemedPackages;
          break;
        case "OnlyHandedOut":
          ++this.onlyHandedOutPackages;
          break;
        case "WaitingForHandingOut":
          ++this.waitingForHandingOut;
          break;
      }
    }
  }

  /**
   * Switches the details shown status of this component.
   */
  protected toggleDetails():void {
    this.isDetailsShown = !this.isDetailsShown;
  }

  /**
   * Determines if the target package is the redeemed state.
   * 
   * @param targetPackage the target package
   * 
   * @returns true if the package is redeemed, false otherwise
   */
  protected isPackageRedeemed(targetPackage:Package):boolean {
    return targetPackage.redemptionInformation?.redeemer != undefined;
  }

  /**
   * Determines if the target package is the handed out state (but not redeemed yet).
   * 
   * @param targetPackage the target package
   * 
   * @returns true if the package is handed out, false otherwise
   */
  protected isPackageOnlyHandedOut(targetPackage:Package):boolean {
    return !this.isPackageRedeemed(targetPackage) && targetPackage.redemptionInformation.redemptionLinkSentTo != undefined;
  }

  /**
   * Gets the target package's state. A package can be redeemed, handed out (but not redeemed) or
   * available.
   * 
   * @param targetPackage the target package
   * 
   * @returns the package's status
   */
  protected getPackageState(targetPackage:Package):"Redeemed"|"OnlyHandedOut"|"WaitingForHandingOut" {
    if(this.isPackageRedeemed(targetPackage)) {
      return "Redeemed";
    } else if(this.isPackageOnlyHandedOut(targetPackage)) {
      return "OnlyHandedOut";
    }

    return "WaitingForHandingOut";
  }

}
