<div class = "title">
    Adminisztrátor {{ data.dialogMode === 'add' ? 'felvétele' : 'módosítása' }}
</div>

<div class = "dialog-content">
     <mat-form-field appearance='outline' style = "width: 100%; margin-bottom: 10px;">
        <mat-label>E-mail cím</mat-label>
        <input matInput autocomplete="off" [formControl] = "emailFC" />
        <mat-error *ngIf = "emailFC.hasError('required')">Kötelező mező!</mat-error>
    </mat-form-field>

     <mat-form-field appearance='outline' style = "width: 100%; margin-bottom: 10px">
        <mat-label>Név</mat-label>
        <input matInput [formControl] = "nameFC" />
        <mat-error *ngIf = "nameFC.hasError('required')">Kötelező mező!</mat-error>
    </mat-form-field>

     <mat-form-field appearance='outline' style = "width: 100%; margin-bottom: 10px">
        <mat-label>Szerepkör</mat-label>
        <mat-select [formControl] = "roleFC" multiple>
            <mat-option *ngFor = "let adminRole of data.adminRoles" [value] = "adminRole">
                {{ adminRole.displayedName }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class = "permissions">
        <div class = "permissions-title">
            Jogosultságok
        </div>
        <app-permission-selector
            [assignedPermissions] = "assignedPermissions"
            [showSpecialPermissions] = "true"
            [disabledPermissions] = "disabledPermissions"
            [withAssignableTo] = "[ 'ADMINS' ]"
        ></app-permission-selector>
    </div>
</div>

<mat-dialog-actions align = "end" style = "padding-top: 10px;">
    <button
        mat-raised-button
        mat-dialog-close
    >
        Bezár
    </button>
    <button
        mat-raised-button
        color = "primary"
        (click) = "handleSaveButtonClick()"
        [disabled] = "formGroup.invalid"
    >
        Mentés
    </button>
</mat-dialog-actions>

