import { Injectable } from "@angular/core";
import { CriticalPoint } from "src/app/classes/model/practice-path";
import { CriticalPointQueryResult, CriticalPointService } from "../critical-point-service";

@Injectable()
export class CriticalPointCacherService {
  // key: critical point uuid - value: CachedCriticalPoint
  private cachedCriticalPointsMap:Map<string, CachedCriticalPoint>;
  private pendingRequestedCriticalPointUuids:Array<string>;

  constructor(
    private criticalPointService:CriticalPointService
  ) {
    this.cachedCriticalPointsMap = new Map<string, CachedCriticalPoint>();
    this.pendingRequestedCriticalPointUuids = new Array<string>();
  }

  /**
   * Determines if the critical point with the given uuid is already cached.
   * 
   * @param criticalPointUuid the target critical point's uuid
   * 
   * @returns true if the critical point is already cached, false otherwise
   */
  public isCriticalPointCached(criticalPointUuid:string):boolean {
    return this.getCriticalPointFromCache(criticalPointUuid) != null;
  }

  /**
   * Gets the critical point with the given uuid from the cache. If the critical point is not cached `null`
   * is returned. 
   * 
   * @param criticalPointUuid the target critical point's uuid
   * 
   * @returns a `CachedCriticalPoint` object if the critical point is cached, false otherwise
   */
  public getCriticalPointFromCache(criticalPointUuid:string):CachedCriticalPoint|null {
    return this.cachedCriticalPointsMap.get(criticalPointUuid) ?? null;
  }


  /**
   * Gets the critical points with the given uuids from the cache. If a critical point with the given
   * uuid is not exists in the cache, it will be omitted from the resulting array.
   * 
   * @param criticalPointUuids the target critical points' uuids
   * 
   * @returns an array of `CachedCriticalPoint` objects from the cache
   */
  public getCriticalPointsFromCache(criticalPointUuids:Array<string>):Array<CachedCriticalPoint> {
    const result:Array<CachedCriticalPoint> = [];

    for(const criticalPointUuid of criticalPointUuids) {
      const cachedCriticalPoint:CachedCriticalPoint|null = this.getCriticalPointFromCache(criticalPointUuid);
      if(cachedCriticalPoint) {
        result.push(cachedCriticalPoint);
      }
    }

    return result;
  }

  /**
   * Fetches and caches the critical points with the given critical point uuids.
   * Note: If the critical point is already in the cache, it will be not fetched from the server.
   * 
   * @param criticalPointUuids array of the critical point uuids to query and cache
   * 
   * @returns an array of `CachedCriticalPoint` objects based on the provided `criticalPointUuids` array
   */
   public async fetchAndCacheCriticalPoints(criticalPointUuids:Array<string>):Promise<Array<CachedCriticalPoint>> {
    // Determine the target critical point uuids
    const targetCriticalPointUuids:Array<string> = criticalPointUuids.filter(
      (criticalPointUuid:string) => {
        // The critical point uuid will be filtered out if
        // - it's already resolved (or determined to be unresolvable)
        // - it's resolution is already in progress
        return this.isCriticalPointCached(criticalPointUuid) == false &&
        this.pendingRequestedCriticalPointUuids.includes(criticalPointUuid) == false;
      }
    );

    // If all practice paths uuids is filtered out, nothing to do
    if(targetCriticalPointUuids.length === 0) {
      return [];
    }

    // Remove the possible duplicates from the target practice path uuids array
    targetCriticalPointUuids.removeDuplicates();

    // Put them in a pending state
    this.pendingRequestedCriticalPointUuids.pushArray(targetCriticalPointUuids);

    try {
      // Fetch the target critical points
      const criticalPointQueryResult:CriticalPointQueryResult = await this.criticalPointService.fetchCriticalPointSet(
        targetCriticalPointUuids
      );
      

      criticalPointQueryResult.criticalPoints.forEach(
        (criticalPoint:CriticalPoint) => {
          this.cachedCriticalPointsMap.set(
            criticalPoint.uuid,
            {
              criticalPointUuid: criticalPoint.uuid,
              isExists: true,
              criticalPoint: criticalPoint
            }
          );
        }
      );

      criticalPointQueryResult.notExistCriticalPointUuids.forEach(
        (criticalPointUuid:string) => {
          this.cachedCriticalPointsMap.set(
            criticalPointUuid,
            {
              criticalPointUuid: criticalPointUuid,
              isExists: false
            }
          );
        }
      );


      return this.getCriticalPointsFromCache(criticalPointUuids);
    } catch(error:any) {
      console.error(error);
    } finally {
      // Remove the target practice paths from the pending state
      this.pendingRequestedCriticalPointUuids.removeItems((criticalpointUuid) => targetCriticalPointUuids.includes(criticalpointUuid));
    }
  }
}

export type CachedCriticalPoint = {
  criticalPointUuid:string,
  isExists:boolean,
  criticalPoint?:CriticalPoint
}