import { Injectable } from '@angular/core';
import { QuestionGroup, questionGroupSorter } from '../classes/model/question-groups';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { HttpResponseData } from '../classes/http-communication';
import { DataCacherService } from './data-cacher-service';
import { matchingPropertyPredicate } from '../functions/misc';
import { Permission, PermissionString } from '../classes/model/permissions';

/**
 * @description THis class provices services for the questiongroup magangement. It's fuctions creates HTTP
 * requests and manipulates the data in the remote database.
 */
@Injectable({
  providedIn: 'root'
})
export class QuestionGroupService implements DataCacherService {
  /** The URL for the admin questions API */
  readonly questiongroupsApiUrlFragment:string = "/api/admin/questiongroup";

  /** Question array to store the questiongroups */
  private questionGroups:Array<QuestionGroup>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.TheoryRead ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService,
  ) {
    this.questionGroups = new Array<QuestionGroup>();
  }

  public getQuestionGroupsArrayRef():ReadonlyArray<Readonly<QuestionGroup>> {
    return this.questionGroups;
  }

  /**
   * Gets the list of the questiongroups from the remote server. The list contains all questions which can be found in the database at the
   * query's time.
   *
   * @returns {Promise<ReadonlyArray<Readonly<QuestionGroup>>>} a Promise which contains the questiongroups in a Question array
   */
  public async fetchQuestionGroups():Promise<Array<QuestionGroup>> {
    let questionGroups:Array<QuestionGroup> = [];

    try {
      const response:HttpResponseData<Array<QuestionGroup>> = await this.backendService.callApi(this.questiongroupsApiUrlFragment, "GET");
      questionGroups = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a kérdéscsoportok lekérdezése közben.");
    }

    return questionGroups;
  }

   /**
   * Saves the locally built questiongroup in the remote database.
   *
   * @param {ewQuestion:Partial<QuestionGroup>} newQuestionGroup - the new questiongroup object without the uuid
   *
   * @returns {Promise<boolean>} a Promise which contains a boolean value to indicate the succession of the operation
   */
  public async addQuestionGroup(newQuestionGroup:Partial<QuestionGroup>):Promise<void> {
    const httpBody:Object = {
      data: {
        item: newQuestionGroup
      }
    };

    try {
      const response:HttpResponseData<Partial<QuestionGroup>> = await this.backendService.callApi(this.questiongroupsApiUrlFragment, "PUT", httpBody);
      newQuestionGroup.uuid = response.data.uuid;
      this.questionGroups.push(newQuestionGroup as QuestionGroup);
      this.questionGroups.sort(questionGroupSorter);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a kérdéscsoport létrehozása közben.");
    }
  }

  /**
   * Removes a question from the remote database with the given questiongroup uuid.
   *
   * @param {string} uuid - the questiongroup's uuid
   *
   * @returns {Promise<boolean>} a Promise which contains a boolean value to indicate the succession of the operation
  */
  public async deleteQuestionGroup(questionGroupUuid:string):Promise<void> {
    const apiUrlFragment:string = this.questiongroupsApiUrlFragment + "/" + questionGroupUuid;

    try {
      await this.backendService.callApi(apiUrlFragment, "DELETE");
      this.questionGroups.removeItems(matchingPropertyPredicate("uuid", questionGroupUuid));
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a kérdéscsoport törlése közben.");
    }
  }

  /**
   * Modifies a questiongroups's attributes in the remote database with the given question uuid.
   *
   * @param {Question} modifiedQuestion - the questiongroups's new attributes
   *
   * @returns {Promise<boolean>} a Promise which contains a boolean value to indicate the succession of the operation
  */
  public async modifyQuestionGroup(modifiedQuestionGroup:QuestionGroup):Promise<void> {
    const httpBody:Object = {
      data: {
        item: modifiedQuestionGroup,
      }
    }

    try {
      await this.backendService.callApi(this.questiongroupsApiUrlFragment, "POST", httpBody);
      this.questionGroups.replaceItems(matchingPropertyPredicate("uuid", modifiedQuestionGroup.uuid), modifiedQuestionGroup);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a kérdéscsoport módosítása közben.");
    }
  }

  public async loadDataIntoCache():Promise<void> {
    const questionGroups:Array<QuestionGroup> = await this.fetchQuestionGroups();
    this.questionGroups.replaceArrayElements(questionGroups).sort(questionGroupSorter);
  }

  public clearCachedData():void {
    this.questionGroups.length = 0;
  }

  public getRequiredPermissionsForDataLoading(): ReadonlyArray<PermissionString> {
    return this.requiredPermissionsForDataLoading;
  }

  public getNameOfCachedData(): string {
    return "Kérdéscsoportok";
  }

}
