<div class = "tab-header">
    <div class = "tilte">
        Itt találhatóak az útvonalhoz feltöltött narrációk.
    </div>
</div>
<div class = "tab-content">
    <app-narrations-during-recording-card
        *ngFor = "let narrationDuringRecording of practicePath.audio.narrationsDuringRecording"
        [narrationDuringRecording] = "narrationDuringRecording"
        style = "margin: 10px 5px;"
    ></app-narrations-during-recording-card>
    <div
        *ngIf = "practicePath.audio.narrationsDuringRecording.length === 0"
        class = "no-narrations"
    >
        Jelenleg nincs feltöltve egyetlen narráció sem.
    </div>
</div>
