import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PracticeCity } from "src/app/classes/model/practice-city";
import { CriticalPoint } from "src/app/classes/model/practice-path";
import { PracticeCityService } from "src/app/services/practice-city.service";
import { CustomValidators } from "src/app/classes/custom-validators";
import { ConfirmationDialogService } from "src/app/modules/confirmation-dialog/services/confirmation-dialog.service";
import { NotifierService } from 'src/app/services/common/notifier-service.service';

@Component({
  selector: "app-practice-cities-editor",
  templateUrl: "./practice-cities-editor.component.html",
  styleUrls: ["./practice-cities-editor.component.scss"],
})
export class PracticeCitiesEditorComponent implements OnInit {
  @ViewChild("mapContainer") mapContainer: ElementRef<HTMLDivElement>;

  title: string;

  cityFC: FormControl;
  positionsFC: FormControl;
  isVisibleFC: FormControl;
  practiceCityFG: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<PracticeCitiesEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { practiceCity: PracticeCity|null },
    private practiceCityService: PracticeCityService,
    private snackBar: MatSnackBar,
    private confirmationDialogService: ConfirmationDialogService,
    private notifierService:NotifierService
  ) {}

  ngOnInit(): void {
    this.title =
      "Vizsgahelyszín " +
      (this.data.practiceCity ? "módosítása" : "hozzáadása");

    this.cityFC = new FormControl(this.data.practiceCity?.city ?? "", [
      Validators.required,
    ]);
    this.positionsFC = new FormControl(
      this.data.practiceCity?.countOfExamPath ?? 1,
      [Validators.required, CustomValidators.wholeNumber, Validators.min(1)]
    );
    this.isVisibleFC = new FormControl(
      this.data.practiceCity?.isVisible ?? false
    );

    this.practiceCityFG = new FormGroup({
      cityFC: this.cityFC,
      positionsFC: this.positionsFC,
    });

    const checkedCriticalPoints: Array<CriticalPoint> = [];
    let numberOfDuplicates: number = 0;
    this.data.practiceCity?.criticalPoints.forEach((criticalPoint) => {
      if (
        checkedCriticalPoints.find(
          (checkedCriticalPoint) =>
            criticalPoint.coordinate.latitude ===
              checkedCriticalPoint.coordinate.latitude &&
            criticalPoint.coordinate.longitude ===
              checkedCriticalPoint.coordinate.longitude
        )
      ) {
        ++numberOfDuplicates;
      } else {
        checkedCriticalPoints.push(criticalPoint);
      }
    });
    if (numberOfDuplicates === 0) {
      console.info("Everything is fine, there are no duplicates.");
    } else {
      console.warn(
        `There are ${numberOfDuplicates} duplicated critical points in this city.`
      );
    }
  }

  public async handleSaveButtonClick(): Promise<void> {
    const practiceCity: Partial<PracticeCity> = {
      city: this.cityFC.value,
      countOfExamPath: this.positionsFC.value,
      isVisible: this.isVisibleFC.value,
    };

    if (!this.data.practiceCity) {
      await this.addPracticeCity(practiceCity);
    } else {
      practiceCity.uuid = this.data.practiceCity.uuid;

      if (
        practiceCity.countOfExamPath < this.data.practiceCity.countOfExamPath
      ) {
        this.confirmationDialogService.open(
          "Vizsgahelyszín módosítása",
          "Csökkenteni készülsz a vizsgahelyszínhez rendelt vizsgatételek számát, ezzel bizonyos hozzárendelések törlődhetnek. Biztosan folytatod?",
          async () => {
            await this.modifyPracticeCity(practiceCity);
          }
        );
      } else {
        await this.modifyPracticeCity(practiceCity);
      }
    }
  }

  public async addPracticeCity(
    newPracticeCity:Partial<PracticeCity>
  ):Promise<void> {
    try {
      await this.practiceCityService.addPracticeCity(newPracticeCity);
      this.snackBar.open("Sikeres vizsgahelyszín létrehozás.", "Bezár", {
        duration: 3000,
        panelClass: ["mat-snackbar-success"],
      });
      this.dialogRef.close(true);
    } catch (error: any) {
      if(error?.error?.error === "EXISTING_CITY_NAME") {
        this.notifierService.notify("error", "Létezik már ilyen nevű vizsgahelyszín.");
      }
    }
  }

  public async modifyPracticeCity(
    practiceCity: Partial<PracticeCity>
  ): Promise<void> {
    try {
      await this.practiceCityService.modifyPracticeCity(
        practiceCity as PracticeCity
      );
      this.snackBar.open("Sikeres vizsgahelyszín módosítás.", "Bezár", {
        duration: 3000,
        panelClass: ["mat-snackbar-success"],
      });
      this.dialogRef.close(true);
    } catch (error: any) {}
  }
}
