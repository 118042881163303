<div class="header">
  <div>
    <div class="title">Anonimizálási állapot logok</div>
    <div *ngIf="this.anonymizerTask" class="status">
      Kész: {{ this.anonymizerTask?.progress }}%
    </div>
  </div>
  <button mat-mini-fab mat-dialog-close color="white">
    <span class="material-icons"> clear </span>
  </button>
</div>

<div *ngIf="this.anonymizerTask?.anonymizingSummary != null">
  <h2>Summary</h2>
  <ngx-json-viewer
    [json]="this.anonymizerTask?.anonymizingSummary"
  ></ngx-json-viewer>
</div>

<mat-divider></mat-divider>

<div class = "dialog-content" #content>
  <h3>Error logs</h3>

  <ng-template [ngIf]="this.anonymizerTask">
    <div *ngFor="let log of this.anonymizerTask?.errorLogs">
      <h4 style="margin-bottom: 3px">
        {{ log.timestamp ?? 0 | date: "YYYY. MM. dd. HH:mm:ss" }}
      </h4>
      <ngx-json-viewer [json]="log"></ngx-json-viewer>
    </div>
    <div *ngIf="this.anonymizerTask?.errorLogs.length === 0" class="no-logs">
      🥳 Nincs error!
    </div>


    <h3>Logs</h3>
    <ng-template [ngIf]="this.anonymizerTask">
      <div *ngFor="let log of this.anonymizerTask.logs">
        <h4 style="margin-bottom: 3px">
          {{ log.timestamp ?? 0 | date: "YYYY. MM. dd. HH:mm:ss" }}
        </h4>
        <ngx-json-viewer [json]="log"></ngx-json-viewer>
      </div>
      <div *ngIf="this.anonymizerTask?.logs?.length === 0" class="no-logs">
        A processzhez nincsenek még logok.
      </div>
    </ng-template>
    <div
      *ngIf="!this.anonymizerTask"
      style="display: flex; justify-content: center"
    >
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
  <div
    *ngIf="!this.anonymizerTask"
    style="display: flex; justify-content: center"
  >
    <mat-spinner></mat-spinner>
  </div>
</div>

<mat-dialog-actions align="center">
  <button
    mat-raised-button
    color="primary"
    (click)="refreshAnonymizationProgressState()"
    cdkFocusInitial
  >
    Frissítés
  </button>
</mat-dialog-actions>
