import { Injectable } from '@angular/core';
import { isShorthandPropertyAssignment } from 'typescript';
import { GoogleMapPosition } from '../classes/model/google-map-position';


// Google snap to road service
@Injectable({
  providedIn: 'root'
})
export class SnapToRoadService {
  static readonly apiKey: string = "AIzaSyBv7iX4apDBRHJ_RZMgVMM2fSwlcEX6OUE";
  static readonly portionLength: number = 100;

  getGoogleApiUrl() {
    return `https://roads.googleapis.com/v1/snapToRoads?key=${SnapToRoadService.apiKey}`;
  }

  async snapping(path: GoogleMapPosition[]) {
    const portionCount = Math.ceil(path.length / SnapToRoadService.portionLength);
    const result: GoogleMapPosition[] = [];

    console.log(`input count: ${path.length}, portion count: ${portionCount}`);

    for (let i = 0; i < portionCount + 1; i++) {
      const snappedPoints = await this._callSnapToRoadApi(path.slice(i * SnapToRoadService.portionLength, i * SnapToRoadService.portionLength + SnapToRoadService.portionLength));

      snappedPoints.forEach((p) => result.push(p));

      console.log(snappedPoints.length);
    }


    console.log(`output count: ${result.length}`);

    return result;
  }


  async _callSnapToRoadApi(path: GoogleMapPosition[]): Promise<GoogleMapPosition[]> {
    if (path.length == 0) return [];
    let url = this.getGoogleApiUrl();

    const pathParameter = path.map(p => `${p.latitude.toFixed(7)},${p.longitude.toFixed(7)}`).join("|");

    url += `&interpolate=false&path=${pathParameter}`;

    const response = await fetch(url);
    const responseJson = await response.json();

    const snappedPoints = responseJson.snappedPoints;
    const result: GoogleMapPosition[] = [];

    snappedPoints.forEach((p: any) => {
      result[p.originalIndex] = { latitude: p.location.latitude, longitude: p.location.longitude }
    });

    for(let i=0;i<path.length;i++){
      if(result[i] == undefined)result[i] = path[i];
    }

    return result;
  }


}
