import { Injectable } from '@angular/core';
import { BackendService } from './common/backend.service';
import { Feedback, feedbackSorter } from '../classes/model/feedback';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { HttpResponseData } from '../classes/http-communication';
import { DataCacherService } from './data-cacher-service';
import { matchingPropertyPredicate } from '../functions/misc';
import { Permission, PermissionString } from '../classes/model/permissions';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService implements DataCacherService {
  private readonly feedbacksApiFragment:string = "/api/admin/feedback";
  private readonly closeFeedbackApiFragment:string = "/api/admin/feedback/close";

  private feedbacks:Array<Feedback>;
  private unhandledFeedbacks:Array<Feedback>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.FeedbackRead ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) {
    this.feedbacks = new Array<Feedback>();
    this.unhandledFeedbacks = new Array<Feedback>();
  }

  public getFeedbacksArrayRef():ReadonlyArray<Readonly<Feedback>> {
    return this.feedbacks;
  }

  public getUnhandledFeedbacksArrayRef():ReadonlyArray<Readonly<Feedback>> {
    return this.unhandledFeedbacks;
  }

  public async fetchFeedbacks():Promise<Array<Feedback>> {
    let feedbacks:Array<Feedback> = [];
    try {
      const response:HttpResponseData<Array<Feedback>> = await this.backendService.callApi(this.feedbacksApiFragment, "GET");
      feedbacks = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a visszajelzések lekérdezése közben.");
    }

    return feedbacks;
  }

  public async closeFeedback(feedback:Feedback):Promise<void> {
    const httpBody:Object = {
      feedbackUuid: feedback.uuid,
    }

    try {
      const respose:HttpResponseData<Partial<Feedback>> = await this.backendService.callApi(this.closeFeedbackApiFragment, "POST", httpBody);
      feedback.closed = respose.data.closed;
      this.unhandledFeedbacks.removeItems(matchingPropertyPredicate("uuid", feedback.uuid));
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a visszajelzés lezárása közben.");
    }
  }

  public async loadDataIntoCache():Promise<void> {
    const feedbacks:Array<Feedback> = await this.fetchFeedbacks();
    this.feedbacks.replaceArrayElements(feedbacks).sort(feedbackSorter);
    this.unhandledFeedbacks.replaceArrayElements(
      this.feedbacks.filter(
        (feedback:Feedback) => { return feedback.closed === null }
      )
    );
  }

  public clearCachedData():void {
    this.feedbacks.length = 0;
    this.unhandledFeedbacks.length = 0;
  }

  public getRequiredPermissionsForDataLoading(): ReadonlyArray<PermissionString> {
    return this.requiredPermissionsForDataLoading;
  }

  public getNameOfCachedData(): string {
    return "Visszajelzések";
  }
}
