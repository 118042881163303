<div style="width: 800px">
  <div style="font-size: 24px; font-weight: bold">
    {{ data.index }}. kitöltés
  </div>
  <div id="student-fillout-data">
    <mat-card
      *ngFor="let resolvedQuestion of resolvedQuestions; let i = index"
      class="student-fillout-data-card"
    >
      <div
        style="
          margin-bottom: 20px;
          display: grid;
          grid-template-columns: auto 80px;
        "
      >
        <div>
          {{ i + 1 }}.
          <span *ngIf="resolvedQuestion !== null">
            {{ resolvedQuestion.text }}
          </span>
          <span *ngIf="resolvedQuestion === null" style="font-style: italic">
            Törölt kérdés
          </span>
        </div>
        <div style="justify-self: end">
          <ng-template
            [ngIf]="
              (resolvedQuestion.answerIndex === -1 &&
                resolvedQuestion.type == 'simple') ||
              (resolvedQuestion.type == 'arrangeable' &&
                (resolvedQuestion.answerIndexes ?? []).length == 0)
            "
          >
            <div style="font-size: 0.8em; text-align: center">
              Nem válaszolt
            </div>
          </ng-template>
          <div>
            <span *ngIf="resolvedQuestion.spentTimeOnAnswerInMs !== null">
              {{ resolvedQuestion.spentTimeOnAnswerInMs / 1000 }} s
            </span>
            <span *ngIf="resolvedQuestion.spentTimeOnAnswerInMs === null">
              -
            </span>
          </div>
        </div>
      </div>
      <div
        *ngIf="resolvedQuestion !== null"
        style="display: grid; grid-template-columns: 50% 50%"
      >
        <div style="text-align: center">
          <img
            *ngIf="resolvedQuestion.mediaUrl !== ''"
            [src]="resolvedQuestion.mediaUrl"
            style="width: 300px; height: auto"
          />
        </div>
        <div>
          <div>

            answerIndexes (zerobased):{{ resolvedQuestion.answerIndexes}}<br>
            answerIndex(zerobased) : {{resolvedQuestion.answerIndex}}
          </div>
          <div
            *ngFor="
              let optionText of resolvedQuestion.optionTexts;
              let j = index
            "
            class="student-fillout-option"
            [ngClass]="
              getOptionClass(
                resolvedQuestion.optionCorrectnesses[j],
                resolvedQuestion.optionChoosen[j]
              )
            "
          >
            {{ optionText }}
          </div>

        </div>
      </div>
    </mat-card>
  </div>
  <div *ngIf="filloutDetails === null" class="student-fillout-not-exist">
    A kitöltés nem megjeleníthető.
  </div>
</div>
