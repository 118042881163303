import { Injectable } from '@angular/core';
import { Category, categorySorter } from '../classes/model/category';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { HttpResponseData } from '../classes/http-communication';
import { DataCacherService } from './data-cacher-service';
import { matchingPropertyPredicate } from '../functions/misc';
import { Permission, PermissionString } from '../classes/model/permissions';


/**
 * @description This class provides services for the categories, which are listing, adding, deleting and modifying category.
 */
@Injectable({
  providedIn: 'root'
})
export class CategoryService implements DataCacherService {
  /** The URL for the admin category API */
  readonly categoryApiUrlFragment = "/api/admin/category";
  /** Question array to store the categories */
  private categories:Array<Category>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.TheoryRead ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
    ) {
    this.categories = new Array<Category>();
  }

  public getCategoriesArrayRef():ReadonlyArray<Category> {
    return this.categories;
  }

  /**
   * Gets the categories as a Category array from the server. If the HTTP request was successful returns a Promise for a Category array.
   *
   * @returns {Promise<ReadonlyArray<Readonly<Category>>>} a Promise for a Category array.
  */
  public async fetchCategories():Promise<Array<Category>> {
    let categories:Array<Category> = [];

    try {
      const response:HttpResponseData<Array<Category>> = await this.backendService.callApi(this.categoryApiUrlFragment, "GET");
      categories = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a kategóriák lekérdezése közben.");
    }

    return categories;
  }

  /**
   * Adds a new category to the database. If the request was successful the server returns the S3 bucket URL-s for the medias, the local category
   * array will be updated. The method returs a Promise for a boolean, which is true, if the insertion was successful, false otherwise.
   *
   * @param {Partial<Category>} - a (partial) Category which will be inserted
   *
   * @returns {Promise<boolean>} a Promise for a boolean which indicates the succession of the operation.
  */
  public async addCategory(newCategory:Partial<Category>, iconMedia:any, headerMedia:any):Promise<void> {
    const httpBody:Object = {
      item: newCategory
    };

    const fileMap:ReadonlyMap<string, File> = new Map<string, File>([
      [ "category-icon-media", iconMedia ],
      [ "category-header-media", headerMedia ]
    ]);

    try {
      const response:HttpResponseData<Partial<Category>> = await this.backendService.callApi(this.categoryApiUrlFragment, "PUT", httpBody, fileMap);
      Object.assign<Partial<Category>, Partial<Category>>(newCategory, {
        uuid: response.data.uuid,
        iconImageUrl: response.data.iconImageUrl,
        headerImageUrl: response.data.headerImageUrl,
        maxPointFullTest: 0,
      });
      this.categories.push(newCategory as Category);
      this.categories.sort(categorySorter);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a kategória létrehozása közben.");
    }
  }

  /**
   * Deletes category from the database. If the request was successful, updates the local category array. The method
   * returs a Promise for a boolean, which is true, if the deletion was successful, false otherwise.
   *
   * @param {string} uuid - the uuid of the category
   *
   * @returns {Promise<boolean>} a Promise for a boolean which indicates the succession of the operation.
  */
  public async deleteCategory(categoryUuid:string):Promise<void> {
    try {
      await this.backendService.callApi(this.categoryApiUrlFragment + "/" + categoryUuid, "DELETE");
      this.categories.removeItems(matchingPropertyPredicate("uuid", categoryUuid));
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a kategória törlése közben.");
    }
  }

  /**
   * Modifies the details of a category in the database. If the request was successful, updates the local category array.  The method
   * returs a Promise for a boolean, which is true, if the modification was successful, false otherwise.
   *
   * @param {Partial<Category>}  - a (partial) Category with the updated details
   *
   * @returns a Promise for a boolean which indicates the succession of the operation.
  */
  public async modifyCategory(modifiedCategory:Category, iconMedia?:any, headerMedia?:any):Promise<void> {
    const httpBody:Object = {
      item: modifiedCategory
    };

    const fileMap:Map<string, File> = new Map<string, File>();
    iconMedia && fileMap.set("category-icon-media", iconMedia);
    headerMedia && fileMap.set("category-header-media", headerMedia);

    try {
      const response:HttpResponseData<Partial<Category>> = await this.backendService.callApi(this.categoryApiUrlFragment, "POST", httpBody, fileMap);
      Object.assign<Partial<Category>, Partial<Category>>(modifiedCategory, {
        iconImageUrl: response.data.iconImageUrl,
        headerImageUrl: response.data.headerImageUrl,
        maxPointFullTest: response.data.maxPointFullTest
      });
      this.categories.replaceItems(matchingPropertyPredicate("uuid", modifiedCategory.uuid), modifiedCategory);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a kategória módosítása közben.");
    }

  }

  public async loadDataIntoCache():Promise<void> {
    const categories:Array<Category> = await this.fetchCategories();
    this.categories.replaceArrayElements(categories).sort(categorySorter);
  }

  public clearCachedData():void {
    this.categories.length = 0;
  }

  public getRequiredPermissionsForDataLoading(): ReadonlyArray<PermissionString> {
    return this.requiredPermissionsForDataLoading;
  }

  public getNameOfCachedData(): string {
    return "Kategóriák";
  }

}
