import { PracticePathUtil } from "./../../../../services/practice-path-util";
import { ConfirmationDialogService } from "./../../../confirmation-dialog/services/confirmation-dialog.service";
import { IconPath } from "./../../../../classes/model/practice-path";
import {
  PathIconRecording,
  PathIconRecordingStatusManager,
} from "../timed-icons-tab/components/timed-icon-recorder/timed-icon-recorder.service";
import { PracticePathGlobalVideoEditorPageService } from "../../services/practice-path-global-video-editor-page.service";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { IconRecorderService } from "../timed-icons-tab/components/timed-icon-recorder/timed-icon-recorder.service";

@Component({
  selector: "app-path-icons-tab",
  templateUrl: "./path-icons-tab.component.html",
  styleUrls: ["./path-icons-tab.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathIconsTabComponent implements OnInit {
  @Input()
  originalTabIndex: number;

  iconRecorderService: IconRecorderService<PathIconRecording>;
  practiceIconUrls: string[] = [];

  selectedIconIndexes: number[] = [];

  constructor(
    protected globalEditorService: PracticePathGlobalVideoEditorPageService,
    protected confirmationDialogService: ConfirmationDialogService,
    protected practiceUtil: PracticePathUtil,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.iconRecorderService = new IconRecorderService<PathIconRecording>(
      new PathIconRecordingStatusManager(
        this.globalEditorService.mainVideoPlayerController._currentPositionInMs$,
        this.globalEditorService.path$.getOriginalArrayRef()
      )
    );

    this.practiceIconUrls = this.globalEditorService.practiceIcons.map(
      (pi) => pi.iconUrl
    );

    // Ezzel frissítjük a kiválasztott ikonokat
    // a recording alapján határozzik meg az IconFlow-ban a kiválasztott ikonokat
    this.iconRecorderService.currentRecordingChanged.subscribe(() => {
      this.selectedIconIndexes = this.iconRecorderService
        .getRecordingIconUrls()
        .map((r) => this.practiceIconUrls.indexOf(r));
    });

    this.iconRecorderService.onFinishedRecording.subscribe((newRecording) => {
      this.globalEditorService.addPathIconAssignment({
        practiceIconUuid: this.globalEditorService.practiceIcons.find(
          (p) => p.iconUrl == newRecording.practiceIconUrl
        ).uuid,
        path: this.globalEditorService.path$
          .getOriginalArrayRef()
          .slice(
            newRecording.startGeoTaggedFrameIndex,
            newRecording.endGeoTaggedFrameIndex + 1
          )
          .map((p) => p.position),
      });
    });

    this.globalEditorService.pathIconAssignments$.addListener(() => {
      this.changeDetector.markForCheck();
    });

    this.globalEditorService.currentPosition.subscribe(() => {
      this.changeDetector.markForCheck();
    });
  }

  getCountOfActivePathIcon(): number {
    return this.globalEditorService.pathIconAssignments$
      .getOriginalArrayRef()
      .reduce((prev, current) => {
        return this.isSpecificPathIconActive(current) ? prev + 1 : prev;
      }, 0);
  }

  isSpecificPathIconActive(pathIcon: IconPath) {
    return this.practiceUtil.isIconPathVisible(
      this.globalEditorService.currentPosition.getValue(),
      this.globalEditorService.path$.getOriginalArrayRef(),
      pathIcon
    );
  }

  onTapDelete(pathIcon: IconPath) {
    this.confirmationDialogService.open(
      "Útvonal ikon törlés",
      "Biztosan törölni szeretnéd az útvonal ikont?",
      () => {
        this.globalEditorService.deletePathIconAssignment(pathIcon.uuid);
      }
    );
  }

  // Amikor maga a kártyára viszi rá az egeret
  // a mentett ikonok listájában
  onHoverIconPathCard(iconPath: IconPath) {
    this.globalEditorService.pathIconMapController.setPolylineOptions(
      iconPath.uuid,
      "red",
      10000,
      1.0
    );

    this.globalEditorService.pathIconMapController.addArrowToHead(
      iconPath.uuid,
      "red"
    );
  }

  onUnHoverIconPathCard(iconPath: IconPath) {
    this.globalEditorService.pathIconMapController.setDefaultPolylineOptions(
      iconPath.uuid
    );
    this.globalEditorService.pathIconMapController.removeArrowFromHead(
      iconPath.uuid
    );
  }

  // Akkor hívódik, amikor egy kártyán rákattint az ugrás a térképenre
  // ilyenkor automatikusan megjelenítjük a térképen a markereket
  // ha nem jelenítjük meg, akkor nem fogják érteni, hogy hol van a pont
  // és lehet, hogy nem kapcsolják be a checkbox-al a markereket
  changeMapPositionToBeginningOfIconPath(iconPath: IconPath) {
    this.globalEditorService.pathIconMapController.setVisibility(true);
    this.globalEditorService.googleMapController.map.setZoom(19);

    const pos = iconPath.path[0];
    const closestPosition =
      this.practiceUtil.getClosestGeoTaggedFrameToGeoLocation(
        this.globalEditorService.path$.getOriginalArrayRef(),
        pos
      );

    this.globalEditorService.mainVideoPlayerController.pause();

    this.globalEditorService.mainVideoPlayerController.seekTo(
      closestPosition.millisecOfFrame
    );

    this.globalEditorService.pathIconMapController.makeFlashEffect(
      iconPath.uuid
    );

    setTimeout(() => {
      this.globalEditorService.googleMapController.setCenter(iconPath.path[0]);
    }, 100);
  }

  // IconFlow-n belül egy ikonra kattintás
  // ha még az adott ikonból nem indítottunk felvételt
  // akkor elindítja, ha pedig indított, akkor megszakítja
  onTapIconInIconFlow(iconIndex:number) {
    const isRecordInProgress = this.iconRecorderService.isRecordInProgress(
      this.practiceIconUrls[iconIndex]
    );

    // Ha újra rákattint egy olyanra amin megy a felvétel
    // azt VISSZAVONÁSNAK vesszük, nem pedig mentésnek.
    if (isRecordInProgress) {
      this.iconRecorderService.cancelRecording(
        this.practiceIconUrls[iconIndex]
      );
    } else {
      this.iconRecorderService.startRecording(this.practiceIconUrls[iconIndex]);
    }
  }
}
