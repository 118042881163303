import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ResourceService {
  constructor() { }

  public async isResourceAvailable(url: string): Promise<boolean> {
    try {
      const response: Response = await fetch(url, { method: "HEAD", cache:"no-store"});
      return response.status >= 200 && response.status < 300;
    } catch (error: any) {
      return false;
    }
  }

  /**
   * Downloads the given resource via GET request. This solution is neccessary, beacause the Firefox browser
   * opens the "inlinle-openable" resources in the same tab.
   * https://stackoverflow.com/questions/3975648/how-to-set-content-disposition-attachment-via-javascript
   * 
   * @param url the url of the target resource
   * @param fileName the name of the downloaded resoure
   * @param fileType the type of the downloaded resource (default: application/octet-stream)
   */
  public async downloadResource(url:string, fileName:string, fileType?:string):Promise<void> {
    // Get the file via a HTTP request
    const response:Response = await fetch(url, { method: "GET" });

    if(response.status !== 200) {
      throw Error("Resource download error");
    }

    const responseBlob:Blob = await response.blob();

    // Create a new blob out of it
    // const newBlob = new Blob([responseBlob], { type: "audio/mp3"})
    const newBlob = new Blob([responseBlob], { type: fileType ?? "application/octet-stream"});
    const data = URL.createObjectURL(newBlob);

    // Create the anchor elemenet
    const link:HTMLAnchorElement = document.createElement("a");
    link.href = data;
    // link.download = this.narrationDuringRecording.originalFileName;
    link.download = fileName;

    // Click the anchor element
    link.click();

    // For Firefox it is necessary to delay revoking the ObjectURL
    URL.revokeObjectURL(data);
  }

}
