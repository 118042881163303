import { Component, OnInit, Input, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { trigger, state, style, animate, transition } from "@angular/animations";
import { Question } from 'src/app/classes/model/question';
import { QuestionEditDialogComponent } from '../question-edit-dialog/question-edit-dialog.component';
import { QuestionGroup } from 'src/app/classes/model/question-groups';
import { DialogMode } from 'src/app/classes/misc';
import { MediaType, getMediaTypeFromUrl } from 'src/app/classes/media';
import { Category } from 'src/app/classes/model/category';
import { matchingPropertyPredicate } from 'src/app/functions/misc';

/**
 * @description This component represents a question group card in the question groups view.
*/
@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  animations: [
    trigger("expand", [
      state("retracted", style({
        height: "0px"
      })),
      state("expanded", style({

      })),
      transition("expanded <=> retracted", [
        animate("150ms")
      ])
    ])
  ]
})
export class QuestionComponent implements OnInit {
  /** The question which will be described by the card. */
  @Input() question: Question;
  /** Array reference to all question groups. */
  @Input() questionGroups: ReadonlyArray<Readonly<QuestionGroup>>;
  @Input() categories: ReadonlyArray<Readonly<Category>>;

  // This assignment is for to enable the usage of the enum in the HTML template.
  MediaType = MediaType;

  /** Prefix for the question's container's ID. */
  public static readonly containerPrefix = "question-container-";

  /** Stores if the container is opened or not. */
  isExpanded: boolean;

  constructor(
    public dialog: MatDialog,
    public viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void { }

  /**
   * Retruns with the container's generated ID based on the questions uuid.
   * 
   * @returns {string} the generated id
  */
  getContainerID(): string {
    return QuestionComponent.containerPrefix + this.question?.uuid ?? "";
  }

  /**
   * Returns with the displayed toogle button's icon's name. If the container is opened, the up arrow icon is shown, if not, the down arrow.
   * 
   * @retrun {string} name of the icon
  */
  getButtonIcon(): string {
    return this.isExpanded ? "keyboard_arrow_up" : "keyboard_arrow_down";
  }

  /**
   * Returns with the options class, which determines it's color based on that it is a solution or not.
   * 
   * @param {number} optionIndex - the index of the option
   * 
   * @returns {string} the name of the assigned classes
  */
  getOptionClass(optionIndex: number): string {
    if (this.question.type === "simple") {
      if (this.question.options[optionIndex].isSolution) {
        return "question-option simple-option-solution";
      } else {
        return "question-option simple-option-not-solution";
      }
    } else {
      return "question-option arrangeable-option";
    }
  }

  /**
   * Opens a dialog to modify the corresponding question.
   * 
   * @returns {void} nothing
  */
  openQuestionModifyDialog(event: Event): void {
    this.dialog.open(QuestionEditDialogComponent, {
      data: {
        dialogMode: DialogMode.Edit,
        question: this.question,
        categories: this.categories,
        questionGroups: this.questionGroups
      },
      disableClose: true,
      viewContainerRef: this.viewContainerRef // To provide a parent's service
    });
    event.stopPropagation();
  }

  /**
   * Toogles the expansion of the card.
   * 
   * @returns {void} nothing
  */
  toogleCardExpansion(): void {
    this.isExpanded = !this.isExpanded;
  }

  /**
   * Returns with the question group's name with the given uuid. If there is no such question group, null will be returned.
   * 
   * @param {string} questionGroupUuid - the question group's uuid
   * 
   * @returns {string} the question group's name
  */
  getQuestionGroupName(questionGroupUuid: string): string {
    return this.questionGroups?.find(matchingPropertyPredicate("uuid", questionGroupUuid))?.name ?? "";
  }

  getCategoryName(categoryUuid: string): string {
    return this.categories?.find(matchingPropertyPredicate("uuid", categoryUuid))?.name ?? "";
  }

  getQuestionMediaType(): MediaType {
    return getMediaTypeFromUrl(this.question.mediaUrl);
  }

  hasMetadata(): boolean {
    const hasMetadata =  this.question.metadata.lastModifier !== undefined || this.question.metadata.lastModifyDate !== undefined;
    return hasMetadata;
  }

  getModificationDate(): string {
    const date: Date = new Date(this.question.metadata.lastModifyDate);
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
  }

}
