import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-new-narration-during-recording-item',
  templateUrl: './new-narration-during-recording-item.component.html',
  styleUrls: ['./new-narration-during-recording-item.component.scss']
})
export class NewNarrationDuringRecordingItemComponent implements OnInit {
  @Input() audioFile:File;
  @Input() defaultTitle:string = "";

  @Output() deleteItemButtonClicked:EventEmitter<void> = new EventEmitter<void>();

  titleFC:FormControl<string>;
  audioUrl:string;

  constructor() { }

  ngOnInit(): void {
    // Create the form control for the title
    this.titleFC = new FormControl<string>(this.defaultTitle)
    // Create an url for the locally selected file
    this.audioUrl = URL.createObjectURL(this.audioFile);
  }

  /**
   * Gets the component's actual values such as the given title and the file name.
   * 
   * @returns the components actual values
   */
  public getComponentStateValues():{ file:File, title?:string } {
    return {
      file: this.audioFile,
      title: this.titleFC.value ? this.titleFC.value : undefined
    }
  }

  /**
   * Handles the delete button click. It notifies the parent that the delete button was clicked.
   */
  protected onDeleteItemButtonClick():void {
    // Emit an event for the parent that the delete button was clicked.
    this.deleteItemButtonClicked.emit();
  }

}