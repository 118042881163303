<!-- Question group edit dialog title -->
<div id="questiongroup-edit-dialog-title">{{ title }}</div>

<!-- Question group edit dialog body -->
<form [formGroup]="questionGroupFormGroup">
  <!-- Question group name form -->
    <mat-form-field appearance='outline' id="questiongroup-name-form">
    <mat-label>Kérdéscsoport neve</mat-label>
    <input matInput class="" formControlName="questionGroupName" required />
    <mat-error *ngIf="questionGroupNameFormControl.hasError('required')">
      Kötelező mező!
    </mat-error>
  </mat-form-field>
  <div id="questiongroup-edit-assigned-categories-title">
    Hozzárendelt kategóriák
  </div>
  <div style="margin-bottom: 10px;">
    <mat-chip-listbox>
      <mat-chip-option
        *ngFor="let category of tempAssignedCategories"
        color="primary"
        [disableRipple]="true"
        [selectable]="false"
      >
        {{ category.name }}
        <mat-icon
          matChipRemove
          (click)="removeQuestionGroupFromCategory(category.uuid)"
        >
          cancel
        </mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
  <div id="questiongroup-category-assignment-container">
    <div id="questiongroup-edit-add-category-select-container">
        <mat-form-field appearance='outline' id="questiongroup-category-assignment-select-form">
        <mat-label>Kategóriák</mat-label>
        <mat-select formControlName="questionGroupCategorySelectFC">
          <mat-option
            *ngFor="let category of categoriesInSelect"
            [value]="category.uuid"
          >
            {{ category.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div id="questiongroup-edit-add-category-button-container">
      <button
        mat-raised-button
        color="primary"
        [disabled]="questionGroupCategorySelectFC.value === null"
        (click)="assignQuestionGroupToCategory()"
      >
        Kategória hozzárendelése
      </button>
    </div>
  </div>
  <div>
    <mat-slide-toggle formControlName="questionGroupIsHighlightedFC">
      Kiemelt
    </mat-slide-toggle>
  </div>
</form>


<!-- Question dialog actions -->
<div id="questiongroup-dialog-actions">
  <div
    *ngIf="data.dialogMode === DialogMode.Add"
    id="questiongroup-dialog-add-actions"
  >
    <button mat-button mat-dialog-close>Vissza</button>
    <button
      id="questiongroup-dialog-add-button"
      mat-button
      (click)="handleAddButtonClick()"
      cdkFocusInitial
    >
      Felvesz
    </button>
  </div>
  <div
    *ngIf="data.dialogMode === DialogMode.Edit"
    id="questiongroup-dialog-edit-actions"
  >
    <div id="questiongroup-dialog-actions-delete-container">
      <button
        mat-button
        id="questiongroup-dialog-delete-button"
        (click)="handleDeleteButtonClick()"
      >
        Törlés
      </button>
    </div>
    <div id="questiongroup-dialog-actions-modify-container">
      <button mat-button mat-dialog-close>Vissza</button>
      <button
        mat-button
        id="questiongroup-dialog-modify-button"
        (click)="handleModifyButtonClick()"
        cdkFocusInitial
      >
        Módosít
      </button>
    </div>
  </div>
</div>
