import { Injectable } from '@angular/core';
import { HttpResponseData } from '../classes/http-communication';
import { QuestionChange, changeSorter } from '../classes/model/changelog';
import { Permission, PermissionString } from '../classes/model/permissions';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { DataCacherService } from './data-cacher-service';

@Injectable({
  providedIn: 'root'
})
export class ChangelogService implements DataCacherService {
  readonly getChangelogApiUrlFragment:string = "/api/admin/question/changelog";
  private changes:Array<Readonly<QuestionChange>>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.TheoryRead ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
    ) {
    this.changes = new Array<Readonly<QuestionChange>>();
  }

  public getChangesArrayRef():ReadonlyArray<Readonly<QuestionChange>> {
    return this.changes;
  }

  public async fetchChanges():Promise<Array<Readonly<QuestionChange>>> {
    let changes:Array<Readonly<QuestionChange>> = [];
    try {
      const response:HttpResponseData<Array<QuestionChange>> = await this.backendService.callApi(this.getChangelogApiUrlFragment, "GET");
      changes = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a changelog lekérdezése közben.");
    }

    return changes;
  }

  public async loadDataIntoCache():Promise<void> {
    const changes:Array<Readonly<QuestionChange>> = await this.fetchChanges();
    this.changes.replaceArrayElements(changes).sort(changeSorter);
  }

  public clearCachedData():void {
    this.changes.length = 0;
  }

  public getRequiredPermissionsForDataLoading(): ReadonlyArray<PermissionString> {
    return this.requiredPermissionsForDataLoading;
  }

  public getNameOfCachedData(): string {
    return "Kérdésmódosítások";
  }
}
