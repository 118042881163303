<div class="grid-divider">
  <form [formGroup]="formGroupForCriticalPoint">
    <div class="edit-container">
      <!-- Cím és leírás -->
      <div class="critical-point-form-container">
        <!-- Cím -->
        <div style="margin-bottom: 10px">
           <mat-form-field appearance='outline' required style="width: 300px;">
            <mat-label>Cím</mat-label>
            <input matInput formControlName="titleFC" />
            <mat-error
              *ngIf="
                formGroupForCriticalPoint.get('titleFC')!.hasError('required')
              "
            >Kötelező mező!</mat-error>
            <mat-error
              *ngIf="
                formGroupForCriticalPoint.get('titleFC')!.hasError('maxlength')
              "
            >A cím max {{ maxTitleLength }} karakter lehet!</mat-error>
          </mat-form-field>
        </div>

        <!-- Leírás -->
        <div style="margin-bottom: 20px;">
           <mat-form-field appearance='outline' style="width: 300px;">
            <mat-label>Leírás</mat-label>
            <textarea
              matInput
              #descriptionTextArea
              [formControlName]="'descriptionFC'"
              class="description-textarea"
            ></textarea>
            <mat-hint>Fontos! Az applikációban is így jelenik meg, maximum 8 sor kerül megjelenítésre.</mat-hint>
            <mat-error
              *ngIf="
                formGroupForCriticalPoint
                  .get('descriptionFC')!
                  .hasError('required')
              "
            >Kötelező mező!</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div
        *ngIf = "data.criticalPoint.uuid != undefined"
        style = "margin: 5px 0;"
      >
        <ng-container
          *ngIf = "
            criticalPointPositionInPaths !== null || criticalPointPositionInPathsCalculationHasError;
            then otherPracticePathsLoadingFinished;
            else otherPracticePathsLoading"
        ></ng-container>

        <ng-template #otherPracticePathsLoadingFinished>
          <ng-container
            *ngIf = "criticalPointPositionInPathsCalculationHasError;
            then otherPracticePathsLoadingError;
            else otherPracticePathsLoaded"
          ></ng-container>

          <ng-template #otherPracticePathsLoadingError>
            Sikertelen útvonalbetöltés.
            <button
              (click) = "initOtherPracticePathsToAssignedTo()"
              class = "reload-other-practice-paths-button"
            >
              Újrapróbál
            </button>
          </ng-template>

          <ng-template #otherPracticePathsLoaded>
            <span
              (click) = "openOtherPracticePathsToCriticalPointAssignedToDialog()"
              class = "other-practice-paths"
            >
              {{ criticalPointPositionInPaths.length }} további útvonalhoz hozzárendelve
            </span>
          </ng-template>
        </ng-template>

        <ng-template #otherPracticePathsLoading>
          További hozzárendelt útvonalak betöltése...
        </ng-template>
      </div>

      <!-- Lokális-e -->
      <div class="checkbox-container">
        <mat-checkbox
          matTooltip="Akkor lokális, ha csak egy útvonalnál szeretnénk használni a kritikus pontot, pl: Felhívjuk a figyelmet egy biciklisre."
          formControlName="isLocalFC"
        ></mat-checkbox>

        <span class="checkbox-label" style="margin-left: 5px">Lokális</span>
      </div>

      <!-- Térkép -->
      <div style="height: 270px">
        <app-directional-angle-edit
          [showPathMarkers]="this.data.showPathMarkers"
          [isDirectionAngleEditEnabled]="
            formGroupForCriticalPoint.get('hasDirectionAngleFC')!.value
          "
          [otherCriticalPoints]="this.data.allOtherCriticalPoints"
          [initialCriticalPointPosition]="this.data.criticalPoint.coordinate"
          [initialBearingAngle]="this.data.criticalPoint.directionalAngle"
          (onChangeCriticalPointPosition)="
            this.data.criticalPoint.coordinate = $event
          "
          (onChangeCurrentBearing)="this.currentDirectionalAngle = $event"
          [path]="this.data.geoTaggedFrames"
          [selectedStopPointUuid]="selectedStopPointUuid"
          [allStopPoints]="this.stopPointsAsObservable"
          [criticalPointAnchor]="{
            radiusInMeter: this.criticalPointAnchorRadius,
            position: this.data.criticalPoint.anchorPoint
          }"
        ></app-directional-angle-edit>
      </div>

      <!-- Mentés és visszavonás -->
      <div style="margin-top: 15px" class="save-cancel-row">
        <button
          (click)="onTapSaveButton()"
          mat-raised-button
          color="primary"
          [disabled]="formGroupForCriticalPoint.invalid || hasUserPracticePathWritePermission() == false"
        >
          Kritikus pont mentése
        </button>

        <button
          (click)="onTapCancelButton()"
          class="cancel-button"
          mat-raised-button
          color="primary"
        >
          Mégse
        </button>
      </div>
    </div>
  </form>

  <!-- Preview -->
  <div>
    <video-player
      [useLargeControlSize]="true"
      [fastForwardSeekTimeInSec]="0.4"
      [clickToPlay]="false"
      (onInited)="videoPlayerInited()"
      [disabledControls]="['settings', 'time', 'progress', 'current-time','fullscreen']"
      [isControlEnabled]="isFocusEditState == false"
      [videoPlayerController]="previewVideoPlayerController"
    >
      <video-overlay *ngIf="getSelectedStopPoint()?.focusSettings != undefined">
        <focus-video-overlay
          [relativeCenterXToWidth]="
            this.getSelectedStopPoint()?.focusSettings?.x
          "
          [relativeCenterYToHeight]="
            this.getSelectedStopPoint()?.focusSettings?.y
          "
          [relativeRadiusToHeight]="
            this.getSelectedStopPoint()?.focusSettings?.radius
          "
        ></focus-video-overlay>
      </video-overlay>

      <video-overlay *ngIf="isFocusEditState == false">
        <app-text-video-overlay
          [positionBottom]="'11px'"
          [positionLeft]="'15px'"
          [positionRight]="'140px'"
          [title]="formGroupForCriticalPoint.get('titleFC')!.value"
          [text]="formGroupForCriticalPoint.get('descriptionFC')!.value"
        >
        </app-text-video-overlay>
      </video-overlay>
    </video-player>

    <!-- Megállási pontok szekció -->
    <div class="stop-point-container">
      <div class="stop-point-header">
        <div style="margin-bottom: 10px" class="stop-point-section-title">
          Megállási pontok
        </div>
        <button
          (click)="onTapStopPointsReset()"
          class="stop-points-reset-button"
          mat-raised-button
          style="
            background: linear-gradient(to bottom right, #ef4765, #ff9a5a);
            color: white;
          "
        >
          Megállási pontok újrabecslése
        </button>
      </div>

      <span
        *ngIf="this.data.stopPoints.length != 0"
        style="display: block; margin-bottom: 10px"
      >
        A bejelölt pontokon fog megállni a videó.</span
      >

      <!-- Nincs még egy stop pont se -->
      <div style="color: red" *ngIf="this.data.stopPoints.length == 0">
        Nem található megállási pont ehhez a kritikus ponthoz.<br />
        Ha szeretnél hozzáadni megállási pontokat akkor kattints a becslésre!
      </div>

      <div
        *ngFor="let point of this.data.stopPoints; let i = index"
        class="stop-point"
        [ngClass]="{
          'selected-stop-point-text':
            this.selectedStopPointUuid == point.stopPointUuid
        }"
      >
        <div class="checkbox-container">
          <div
            [matTooltip] = "getStopPointCheckDisabledMessage(point)"
            [matTooltipDisabled]="isStoppingPointCheckDisabled(point) == false"
          >
            <mat-checkbox
              [(ngModel)]="point.isActive"
              (change)="onChangeStopPointActiveCheckbox()"
              [disabled] = "isStoppingPointCheckDisabled(point)"
            ></mat-checkbox>
          </div>
          <span
            (click)="onTapStopPoint(point.stopPointUuid)"
            class="checkbox-label"
            style="margin-left: 5px; margin-right: 5px; cursor: pointer"
          >
            {{ point.stopTimeInMs | msToTimeString }}</span
          >

          <!-- Fókusz -->
          <mat-checkbox
            [formControl]="this.isFocusedFormControllers[i]"
            style="margin-left: 35px"
          ></mat-checkbox>
          <span
            class="checkbox-label"
            style="display: inline-block; margin-left: 5px; margin-right: 5px"
          >
            Fókusz</span
          >

          <mat-icon
            (click)="onTapEditFocus()"
            class="edit-icon"
            *ngIf="
              selectedStopPointUuid == point.stopPointUuid &&
              this.isFocusedFormControllers[i].value
            "
            class="edit-icon"
            [color]="isFocusEditState ? 'error' : 'primary'"
            >edit</mat-icon
          >
        </div>
      </div>
    </div>
  </div>
</div>
