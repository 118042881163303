import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Settings, SettingsService } from '../settings.service';
import { Environment } from "./environment";

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  private readonly currentEnvironmentSubject:BehaviorSubject<Readonly<Environment>|null>;

  constructor(
    private settingsService:SettingsService
  ) {
    this.currentEnvironmentSubject = new BehaviorSubject<Readonly<Environment>|null>(null);
  }

  /**
   * Returns an observable for the environment change.
   */
  public get environmentChanged():Observable<Readonly<Environment>|null> {
    return this.currentEnvironmentSubject.asObservable();
  }

  /**
   * Returns the current environment's value.
   * 
   * @returns 
   */
  public getCurrentEnvironment():Readonly<Environment>|null {
    return this.currentEnvironmentSubject.value;
  }

  /**
   * Set the current environment to the provided environment. It can also save the actual environment to the local storage
   * through the `SettingsService`. 
   * 
   * @param environment the environment to set it as current
   * @param saveInLocalStorage boolean to deterimine if the set environment should be saved in the local storage
   */
  public setEnvironment(environment:Environment, saveInLocalStorage:boolean = true):void {
    if(saveInLocalStorage) {
      this.settingsService.setSettingsValue(Settings.savedEnvironment, environment, true);
    }

    this.currentEnvironmentSubject.next(environment);
  }
}
