<app-page-header
    title = "Jogosultságok"
    subtitle = "Az admin menü rendszerében létező, felhasználóhoz rendelhető jogosultságokat találhatod itt."
    style = "margin-bottom: 20px;"
></app-page-header>

<ng-template [ngIf] = "!isContentLoading">
    <table *ngIf = "displayedPermissions.length > 0">
        <thead class = "table-header">
            <td>
                <!-- isSpecial -->
            </td>
            <td>
                ID
            </td>
            <td>
                Megjelenített név
            </td>
            <td>
                Csoport
            </td>
            <td>
                Hozzárendelhető
            </td>
            <td>
                Leírás
            </td>
            <td class = "edit-column">
                <!-- edit -->
            </td>
            <td class = "delete-column">
                <!-- delete -->
            </td>
        </thead>
        <tbody>
            <tr *ngFor = "let permission of displayedPermissions">
                <td>
                    <span *ngIf = "permission.isSpecial"> ⭐ </span>
                </td>
                <td>
                    {{ permission.name }}
                </td>
                <td>
                    {{ permission.displayedName }}
                </td>
                <td>
                    {{ permission.permissionGroupDisplayedName }}
                </td>
                <td>
                    <div *ngFor = "let displayedAssignedToName of permission.displayedAssignedToNames">
                        {{ displayedAssignedToName }}
                    </div>
                </td>
                <td>
                    {{ permission.description }}
                </td>
                <td class = "edit-column">
                    <span class = "material-icons" (click) = "handleEditButtonClick(permission)">
                        edit
                    </span>
                </td>
                <td class = "delete-column">
                    <span class = "material-icons" (click) = "handleDeleteButtonClick(permission)">
                        delete
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf = "displayedPermissions.length === 0" class = "no-data">
        Nincsenek megjeleníthető jogosultságok.
    </div>
    <div class = "add-button" (click) = "handleAddButtonClick()">
        <span class = "material-icons">
            add
        </span>
    </div>
</ng-template>
<div *ngIf = "isContentLoading" class = "loading-container">
    <mat-spinner></mat-spinner>
</div>
