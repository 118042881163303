import { Injectable } from '@angular/core';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class RevisionNumberService {
  private readonly databaseRevisionApiUrlFragment:string = "/api/admin/server/database_revision";

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) { }

  public async getRevisionNumber():Promise<string> {
    try {
      const response:{ data:{ REVISION:string } } = await this.backendService.callApi(this.databaseRevisionApiUrlFragment, "GET");
      return response.data.REVISION;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Revíziószám lekérése nem sikerült.");
    }
  }
}
