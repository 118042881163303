import { Component, Input, OnInit } from '@angular/core';
import { MrVegasVideoMetadata } from '../../models/mrvegas-video-metadata';

@Component({
  selector: 'app-metadata-table',
  templateUrl: './metadata-table.component.html',
  styleUrls: ['./metadata-table.component.scss']
})
export class MetadataTableComponent implements OnInit {
  @Input()
  currentLoadedMetadata: MrVegasVideoMetadata;


  constructor() { }

  ngOnInit(): void {
  }

}
