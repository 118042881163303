import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalGiftService } from 'src/app/services/global-gift.service';

@Component({
  selector: 'app-create-global-gift-dialog',
  templateUrl: './create-global-gift-dialog.component.html',
  styleUrls: ['./create-global-gift-dialog.component.scss']
})
export class CreateGlobalGiftDialogComponent implements OnInit {

  giftFG:FormGroup;
  messageFC:FormControl;
  packageDefinitionFC:FormControl;
  receivableUntilFC:FormControl;
  minDate:Date;

  constructor(private globalGiftService:GlobalGiftService, public snackBar:MatSnackBar) { }

  ngOnInit(): void {
    this.messageFC = new FormControl("", [ Validators.required ]);
    this.packageDefinitionFC = new FormControl("", [ Validators.required ]);
    this.receivableUntilFC = new FormControl(null, [ Validators.required ]);

    this.receivableUntilFC.disable();

    this.giftFG = new FormGroup({
      messageFC: this.messageFC,
      packageDefinitionFC: this.packageDefinitionFC,
      receivableUntilFC: this.receivableUntilFC
    });

    this.minDate = new Date();

  }

  public async handleCreateGiftButtonClick():Promise<void> {
    const receivableUntilDateValue:number = (this.receivableUntilFC.value as Date).valueOf();
    try {
      await this.globalGiftService.createGlobalGift(this.packageDefinitionFC.value, this.messageFC.value, receivableUntilDateValue);
      this.snackBar.open("Sikeres ajándék létrehozás", "Bezár", { duration: 2000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}
  }

}
