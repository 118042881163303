import { Injectable } from "@angular/core";
import { PracticePath } from "src/app/classes/model/practice-path";
import { PracticePathQueryResult, PracticePathService } from "src/app/services/practice-path.service";

@Injectable()
export class PracticePathCacherService {
  // key: practice path uuid - value: CachedPracticePath
  private cachedPracticePathsMap:Map<string, CachedPracticePath>;
  private pendingRequestedPracticePathUuids:Array<string>;
  private queriedPracticePathFields:ReadonlyArray<keyof PracticePath> = [ "uuid", "name" ];

  constructor(
    private practicePathService:PracticePathService
  ) {
    this.cachedPracticePathsMap = new Map<string, CachedPracticePath>();
    this.pendingRequestedPracticePathUuids = new Array<string>();
  }

  /**
   * Sets the queried fields value.
   */
  public set queriedFields(fields:ReadonlyArray<keyof PracticePath>) {
    this.queriedPracticePathFields = fields;

    // Make sure that the "uuid" is always present
    if(fields.includes("uuid") == false) {
      fields = [ ...fields, "uuid" ];
    }
  }

  /**
   * Determines if the practice path with the given uuid is already cached.
   * 
   * @param practicePathUuid the target practice path's uuid
   * 
   * @returns true if the practice path is already cached, false otherwise
   */
  public isPracticePathCached(practicePathUuid:string):boolean {
    return this.getPracticePathFromCache(practicePathUuid) != null;
  }

  /**
   * Gets the practice path with the given uuid from the cache. If the practice path is not cached `null`
   * is returned. 
   * 
   * @param practicePathUuid the target practice path's uuid
   * 
   * @returns a `CachedPracticePath` object if the practice path is cached, false otherwise
   */
  public getPracticePathFromCache(practicePathUuid:string):CachedPracticePath|null {
    return this.cachedPracticePathsMap.get(practicePathUuid) ?? null;
  }


  /**
   * Gets the practice paths with the given uuids from the cache. If a practice path with the given
   * uuid is not exists in the cache, it will be omitted from the resulting array.
   * 
   * @param practicePathUuids the target practice paths' uuids
   * 
   * @returns an array of `CachedPracticePath` objects from the cache
   */
  public getPracticePathsFromCache(practicePathUuids:Array<string>):Array<CachedPracticePath> {
    const result:Array<CachedPracticePath> = [];

    for(const practicePathUuid of practicePathUuids) {
      const CachedPracticePath:CachedPracticePath|null = this.getPracticePathFromCache(practicePathUuid);
      if(CachedPracticePath) {
        result.push(CachedPracticePath);
      }
    }

    return result;
  }

  /**
   * Fetches and caches the practice paths with the given practice path uuids.
   * Note: If the practice path is already in the cache, it will be not fetched from the server.
   * 
   * @param practicePathUuids array of the practice path uuids to query and cache
   * 
   * @returns an array of `CachedPracticePath` objects based on the provided `practicePathUuids` array
   */
   public async fetchAndCachePracticePaths(practicePathUuids:Array<string>):Promise<Array<CachedPracticePath>> {
    // Determine the target practice path uuids
    const targetPracticePathUuids:Array<string> = practicePathUuids.filter(
      (practicePathUuid:string) => {
        // The practice path uuid will be filtered out if
        // - it's already resolved (or determined to be unresolvable)
        // - it's resolution is already in progress
        return this.isPracticePathCached(practicePathUuid) == false &&
        this.pendingRequestedPracticePathUuids.includes(practicePathUuid) == false;
      }
    );

    // If all practice paths uuids is filtered out, nothing to do
    if(targetPracticePathUuids.length === 0) {
      return [];
    }

    // Remove the possible duplicates from the target practice path uuids array
    targetPracticePathUuids.removeDuplicates();

    // Put them in a pending state
    this.pendingRequestedPracticePathUuids.pushArray(targetPracticePathUuids);

    try {
      // Fetch the target practice paths
      const practicePathQueryResult:PracticePathQueryResult = await this.practicePathService.fetchPracticePathSetFields(
        targetPracticePathUuids, this.queriedPracticePathFields
      );
      

      practicePathQueryResult.practicePaths.forEach(
        (practicePath:PracticePath) => {
          this.cachedPracticePathsMap.set(
            practicePath.uuid,
            {
              practicePathUuid: practicePath.uuid,
              isExists: true,
              practicePath: practicePath
            }
          );
        }
      );

      practicePathQueryResult.notFoundPracticePathUuids.forEach(
        (practicePathUuid:string) => {
          this.cachedPracticePathsMap.set(
            practicePathUuid,
            {
              practicePathUuid: practicePathUuid,
              isExists: false
            }
          );
        }
      );

      return this.getPracticePathsFromCache(practicePathUuids);
    } catch(error:any) {
      console.error(error);
    } finally {
      // Remove the target practice paths from the pending state
      this.pendingRequestedPracticePathUuids.removeItems((practicePathUuid) => targetPracticePathUuids.includes(practicePathUuid));
    }
  }
}

export type CachedPracticePath = {
  practicePathUuid:string,
  isExists:boolean,
  practicePath?:StoredPracticePathFields
}

type StoredPracticePathFields = Partial<PracticePath>&Pick<PracticePath, "uuid">;