import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RelatedEntityTypeToPracticalModuleLog } from '../../models/practical-module-log.model';

export type PracticalModuleLogsDialogInput = {
  entityType:RelatedEntityTypeToPracticalModuleLog;
  entityKey:string;
  dialogTitle?:string;
  entityName?:string;
};

@Component({
  selector: 'app-practical-module-logs-dialog',
  templateUrl: './practical-module-logs-dialog.component.html',
  styleUrls: ['./practical-module-logs-dialog.component.scss']
})
export class PracticalModuleLogsDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data:PracticalModuleLogsDialogInput
  ) { }

  ngOnInit(): void {}

  /**
   * Opens the practical module logs component in a material dialog.
   * 
   * @param dialogService the dialog service to use
   * @param data the input of the dialog
   * 
   * @returns reference to the opened dialog
   */
  public static open(
    dialogService:MatDialog,
    data:PracticalModuleLogsDialogInput
  ):MatDialogRef<PracticalModuleLogsDialogComponent, void> {
    return dialogService.open<
      PracticalModuleLogsDialogComponent,
      PracticalModuleLogsDialogInput,
      void
    >(
      PracticalModuleLogsDialogComponent,
      {
        data: data,
        width: "80vw",
        maxWidth: "1000px",
        height: "95vh",
        minHeight: "400px"
      }
    );
  }

}
