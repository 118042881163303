import { Injectable } from '@angular/core';
import { LogType, Log, LogsMap } from '../models/log';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  private logsMap:LogsMap;

  constructor() {
    this.logsMap = new LogsMap;
  }

  getLogs(key:string):ReadonlyArray<Readonly<Log>> {
    return this.logsMap.getLogs(key);
  }

  addLog(key:string, content:string, type:LogType = LogType.Simple):void {
    this.logsMap.addLog(key, content, type);
  }

  clearLogs(key:string):void {
    this.logsMap.clearLogs(key);
  }

}
