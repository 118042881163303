<ng-container *ngIf = "isPracticePathLoading; then practicePathLoading; else practicePathLoaded"></ng-container>

<ng-template #practicePathLoading>
    <mat-spinner></mat-spinner>
</ng-template>

<ng-template #practicePathLoaded>
    <div class = "dialog-header">
        <div class = "title">
            {{ practicePath.name }}
        </div>
        <button
            class = "close-button"
            (click) = "onCloseButtonClick()"
        >
            <mat-icon> close </mat-icon>
        </button>
    </div>

    <mat-tab-group
        mat-stretch-tabs
        animationDuration = "0ms"
        class = "tab-group"
        [selectedIndex] = "selectedTabIndex"
        (selectedIndexChange) = "tabIndexChange($event)"
    >
        <mat-tab label = "🎤 Feltöltött narrációk">
            <app-narrations-during-recording-list-tab
                [practicePath] = "practicePath"
            ></app-narrations-during-recording-list-tab>
        </mat-tab>
        <mat-tab label = "⬆️ Új narráció feltöltése">
            <app-narrations-during-recording-upload-tab
                [practicePath] = "practicePath"
                (narrationsUploaded) = "onNarrationsUploaded()"
            ></app-narrations-during-recording-upload-tab>
        </mat-tab>
    </mat-tab-group>
</ng-template>

