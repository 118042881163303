import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  private static units = [ "B", "KB", "MB", "GB", "TB", "PB" ] as const;

  transform(value:number):string {
    if(value == undefined) {
      return "? B";
    }

    let unitIndex:number = 0;
    while(unitIndex < FileSizePipe.units.length && value > 999) {
      value /= 1000;
      ++unitIndex;
    }

    return `${Math.round(value * 10) / 10} ${FileSizePipe.units[unitIndex]}`;
  }

}
