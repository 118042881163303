export type DraggableDialogConfig = {
  title?:string,
  headerColor?:string;
  headerHeight?:string;
  titleColor?:string;
  contentPadding?:string
}

export const defaultDraggableDialogConfig:Required<DraggableDialogConfig> = {
  title: "",
  headerColor: "#3f51b5",
  headerHeight: "50px",
  titleColor: "white",
  contentPadding: "10px"
}