<div> {{ dialogTitle }} </div>
<form [formGroup] = "practiceIconFG">
    <div>
         <mat-form-field appearance='outline' style = "width: 100%; margin: 20px 0;">
            <mat-label>Név</mat-label>
            <input matInput [formControl] = "nameFC" />
            <mat-error>Kötelező mező!</mat-error>
        </mat-form-field>
    </div>
    <div class = "media-form-container">
        <app-media-form
            [formControlRef] = "iconFC"
            [previewEnabled] = "true"
            [acceptableFileTypes] = "acceptableFileTypes"
            [defaultPreviewMedia] = "data.practiceIcon?.iconUrl ?? ''"
            [previewWidth] = "200"
            [previewHeight] = "200"
        ></app-media-form>
        <div class = "media-form-error-container">
            <mat-error *ngIf = "iconFC.errors?.fileImageAspectRatio">A képnek 1:1 arányúnak kell lennie!</mat-error>
        </div>
    </div>

    <div style = "margin-top: 20px;">
        <button
            *ngIf = "data.editorMode === DialogMode.Edit"
            mat-raised-button
            color = "error"
            (click) = "handleDeleteButtonClick()"
            style = "float: left;"
        >
            Törlés
        </button>

        <button
            *ngIf = "data.editorMode === DialogMode.Add"
            mat-raised-button
            color = "primary"
            (click) = "handleAddButtonClick()"
            [disabled] = "practiceIconFG.invalid"
            style = "float: right;"
        >
            Felvesz
        </button>

        <button
            *ngIf = "data.editorMode === DialogMode.Edit"
            mat-raised-button
            color = "primary"
            (click) = "handleEditButtonClick()"
            [disabled] = "practiceIconFG.invalid"
            style = "float: right;"
        >
            Módosít
        </button>
    </div>

</form>
