import { CriticalPoint, CriticalPointAssignment, PracticePath } from "src/app/classes/model/practice-path";
import { Type } from "src/app/functions/misc";
import { EventOrigin } from "./event-origin";

export abstract class PracticalModuleLog {
  constructor(
    public uuid: string,
    // A log rekord típusa (ez adja meg, hogy milyen fieldjei vannak)
    public logType: "CriticalPointLog" | "CriticalPointAssignmentLog" | "PracticePathLog" | "PracticePathAssignmentLog" | "GeneralPracticalModuleLog",
    public loggedEntityType: LoggedPracticalModuleEntityType,
    public loggedEntityKey: string,
    public eventOrigin: EventOrigin,
    public creationTimestampMs: number,
    public relatedEntities: Array<RelatedEntityToPracticalModuleLog>,
    public cityName?: string,
  ) { }
}

// Ilyen típusú entitások változását logoljuk
export type LoggedPracticalModuleEntityType = "practice_path" | "critical_point" | "critical_point_assignment";

// Egy adott loghoz kapcsolódó entitás (olyan entitás ami egy adott log esetén releváns)
// Ennek a segítségével csoportosítjuk a logokat és tesszük elérhetővé egy adott entitás logjainak a lekérdezésénél
export type RelatedEntityToPracticalModuleLog = {
  entityType: RelatedEntityTypeToPracticalModuleLog;
  entityKey: string;
}


// Azok az entitás típusok amikhez tartozhat log
export const relatedEntityTypeToPracticalModuleLog = ["admin", "practice_path", "critical_point", "critical_point_assignment"] as const;
export type RelatedEntityTypeToPracticalModuleLog = typeof relatedEntityTypeToPracticalModuleLog[number];


export type CriticalPointEventName = "creation" | "deletion" | "base_data_modification" | "verification_change";
export class CriticalPointLog extends PracticalModuleLog {
  constructor(
    uuid: string,
    loggedEntityKey: string,
    eventOriginEntity: EventOrigin,
    creationTimestampMs: number,
    releatedEntities: Array<RelatedEntityToPracticalModuleLog>,
    public eventName: CriticalPointEventName,
    public cityUuid: string,
    public cityName: string,
    public practicePathUuid?: string, // ha az adott esemény egy útvonalból lett indítva az admin menüben
    public oldData?: Partial<CriticalPoint>, // creation esetén null
    public newData?: Partial<CriticalPoint> // deletion esetén null
  ) {
    super(uuid, "CriticalPointLog", "critical_point", loggedEntityKey, eventOriginEntity, creationTimestampMs, releatedEntities, cityName);
  }

}


export type CriticalPointAssignmentEventName = "creation" | "deletion" | "modification";
export class CriticalPointAssignmentLog extends PracticalModuleLog {
  constructor(
    uuid: string,
    loggedEntityKey: string,
    eventOriginEntity: EventOrigin,
    creationTimestampMs: number,
    releatedEntities: Array<RelatedEntityToPracticalModuleLog>,
    public eventName: CriticalPointAssignmentEventName,
    public practicePathUuid: string, // Annak az útvonalnak az azonosítója amelyikben benne van
    public criticalPointUuid: string, // Az assignment ezzel a kritikus pontal van kapcsolatban (ezt rendelte az útvonalhoz)
    public cityUuid: string,
    public cityName: string, // A vizsgahelyszín neve nagyon ritkán változik, nyugodtan letárolhatjuk az egyszerűbb kliens oldali megjelenítéshez
    public oldData?: Partial<CriticalPointAssignment>, // null, if the changeType is "creation"
    public newData?: Partial<CriticalPointAssignment> // null, if the changeType is "deletion"
  ) {
    super(uuid, "CriticalPointAssignmentLog", "critical_point_assignment", loggedEntityKey, eventOriginEntity, creationTimestampMs, releatedEntities, cityName);
  }
}


export type PracticePathEventName = "creation" | "deletion" | "base_data_modification" | "path_modification";

export class PracticePathLog extends PracticalModuleLog {
  constructor(
    uuid: string,
    loggedEntityKey: string,
    eventOriginEntity: EventOrigin,
    creationTimestampMs: number,
    releatedEntities: Array<RelatedEntityToPracticalModuleLog>,
    public eventName: PracticePathEventName,
    public cityName: string,
    public oldData?: Partial<PracticePath>, // null, if the changeType is "creation" or "path_modification"
    public newData?: Partial<PracticePath> // null, if the changeType is "deletion" or "path_modification"
  ) {
    super(uuid, "PracticePathLog", "practice_path", loggedEntityKey, eventOriginEntity, creationTimestampMs, releatedEntities, cityName);
  }
}


// Általános log ami bármelyik entitáshoz hozzárendelhető
// akkor használandó, amikor olyan eseményt akarunk loggolni aminek nincs saját eseménye
export class GeneralPracticalModuleLog extends PracticalModuleLog {
  constructor(
    uuid: string,
    // Általános lognál meg kell adnunk, hogy milyen típusú entitáshoz csatoljuk a logot
    loggedEntityType: LoggedPracticalModuleEntityType,
    loggedEntityKey: string,
    eventOriginEntity: EventOrigin,
    creationTimestampMs: number,
    releatedEntities: Array<RelatedEntityToPracticalModuleLog>,
    public message: string,
    public data?: any
  ) {
    // Speciális log, általános célra használható
    // Például: Ha történik egy kivágás egy hosszú videóból akkor általános logként hozzáadhatjuk az eseményt
    // egy message-el ellátva.
    super(uuid, "GeneralPracticalModuleLog", loggedEntityType, loggedEntityKey, eventOriginEntity, creationTimestampMs, releatedEntities,undefined);
  }
}



export function getRealTypeOfPracticalModuleLog<T extends PracticalModuleLog>(log: T):
  Type<CriticalPointLog> |
  Type<CriticalPointAssignmentLog> |
  Type<PracticePathLog> |
  Type<GeneralPracticalModuleLog> |
  null {
  if (log.logType === "CriticalPointLog") {
    return CriticalPointLog;
  } else if (log.logType === "CriticalPointAssignmentLog") {
    return CriticalPointAssignmentLog;
  } else if (log.logType === "PracticePathLog") {
    return PracticePathLog;
  } else if (log.logType === "GeneralPracticalModuleLog") {
    return GeneralPracticalModuleLog;
  } else {
    return null; // Unknown log type
  }
}

export function getEventNameOfPracticalModuleLog(log: PracticalModuleLog): string {
  if (log.logType === "CriticalPointLog") {
    return (log as CriticalPointLog).eventName;
  } else if (log.logType === "CriticalPointAssignmentLog") {
    return (log as CriticalPointAssignmentLog).eventName;
  } else if (log.logType === "PracticePathLog") {
    return (log as PracticePathLog).eventName;
  } else if (log.logType === "GeneralPracticalModuleLog") {
    return "general";
  } else {
    return "unknown";
  }
}