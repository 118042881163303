<app-page-header
    title = "Globális ajándékok"
    subtitle = "A rendszer által automatikusan kiosztott ajándékokat találhatod itt."
    style = "margin-bottom: 20px;"
>
    <button mat-mini-fab color = "primary" (click) = "handleReloadButtonClick()" >
        <mat-icon> refresh </mat-icon>
    </button>
</app-page-header>

<ng-template [ngIf] = "!isContentLoading">
    <div>
        <div class = "section-title">
            Aktív ajándékok
        </div>
        <div>
            <app-global-gift-card *ngFor = "let globalGift of activeGlobalGifts" [globalGift] = "globalGift"></app-global-gift-card>
        </div>
        <div *ngIf = "activeGlobalGifts.length === 0" class = "no-data">
            Nincs egyetlen aktív globális ajándék sim
        </div>
    </div>

    <div *ngIf = "inactiveGlobalGifts.length > 0">
        <div class = "section-title">
            Inaktív ajándékok
        </div>
        <div>
            <app-global-gift-card *ngFor = "let globalGift of inactiveGlobalGifts" [globalGift] = "globalGift"></app-global-gift-card>
        </div>
    </div>
    <div class = "add-gift-button-container">
        <button mat-fab color = "primary" (click) = "openCreateGiftDialog()">
            <mat-icon>
                add
            </mat-icon>
        </button>
    </div>
</ng-template>

<div *ngIf = "isContentLoading" class = "loading-container">
    <mat-spinner></mat-spinner>
</div>
    
    
    
    
    
