import { NotifierService } from 'src/app/services/common/notifier-service.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

export type NewZoneinfo = {
  name: string;
  isMainZone: boolean;
}

// Egy új zónához tartozó property-ket lehet ezen keresztül megadni!
@Component({
  selector: 'app-city-zone-adder',
  templateUrl: './city-zone-adder.component.html',
  styleUrls: ['./city-zone-adder.component.scss']
})
export class CityZoneAdderComponent implements OnInit {
  protected zoneName: string = "";

  @Input()
  newZoneColor: string = "#000000";

  @Input()
  currentState: 'SHOW_ADD_ZONE_BUTTON_STATE' | 'ADDING_NEW_ZONE_STATE' = "SHOW_ADD_ZONE_BUTTON_STATE";

  @Output()
  clickAddNewZone: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  clickCancelAddNewZone: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  clickSaveNewZone: EventEmitter<NewZoneinfo> = new EventEmitter<NewZoneinfo>();

  constructor(
    private notifier: NotifierService
  ) { }

  ngOnInit(): void {
  }

  // Create new zone info
  getNewZoneInfo(): NewZoneinfo {
    return {
      name: this.zoneName,
      isMainZone: true // TODO: mainzone checkbox
    }
  }

  onTapSaveZone() {
    if (this.zoneName == "") {
      this.notifier.notify("error", "A zóna neve nem lehet üres!");
      return;
    }

    this.clickSaveNewZone.emit(this.getNewZoneInfo());

    this.clearInput();
  }

  clearInput() {
    this.zoneName = "";
  }

}
