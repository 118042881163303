<section>
    <app-page-header
        title = "Felhasználók (tanulók)"
        subtitle = "A Mr. Kresz rendszerébe regisztrált felhasználók listáját találhatod itt."
    >
        <button
            mat-mini-fab
            color = "primary"
            (click) = "handleReloadButtonClick()">
            <mat-icon> refresh </mat-icon>
        </button>
    </app-page-header>

    <div style="margin-top:10px;">
         <mat-form-field appearance='outline' appearance="outline" class = "students-search-form" >
            <mat-label>Felhasználó keresése</mat-label>
            <input matInput [formControl] = "stundentSearchFC" />
            <mat-hint> uuid, név, e-mail </mat-hint>
        </mat-form-field>
    </div>
    <div>
        <div class = "mat-elevation-z8" style = "overflow: hidden;">
            <mat-paginator [pageSizeOptions] = "[10, 25, 100]"></mat-paginator>

            <table mat-table [dataSource] = "dataSource" matSort style = "width: 100%; font-size: 14px;">

                <ng-container matColumnDef = "uuid">
                    <th mat-header-cell *matHeaderCellDef> UUID </th>
                    <td mat-cell *matCellDef = "let student" class = "users-table-uuid" (click) = "openStudentDetailsDialog(student)">
                        {{ student.uuid }}
                    </td>
                </ng-container>

                <ng-container matColumnDef = "fullname">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Név </th>
                    <td mat-cell *matCellDef = "let student" class = "users-table-name">
                        {{ student.fullname | prefixWithDots: 30 }}
                        <span *ngIf = "student.fullname === ''" style = "color: gray; font-style: italic;"> Nincs megjeleníthető név </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef = "email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> E-mail cím </th>
                    <td mat-cell *matCellDef = "let student" class = "users-table-email">
                        {{ student.email | prefixWithDots: 30 }}
                        <span *ngIf = "student.email === ''" style = "color: gray; font-style: italic;"> Nincs megjeleníthető e-mail cím </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef = "customerId">
                    <th mat-header-cell *matHeaderCellDef> Vásárlói az. </th>
                    <td mat-cell *matCellDef = "let student" class = "users-table-customer-id">
                        {{ getCustomerIdOfStudent(student) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef = "registrationDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Regisztrált </th>
                    <td mat-cell *matCellDef = "let student" class = "users-table-registrated">
                        {{ student.registrationDate | date: "YYYY. MM. dd. HH:mm:ss" }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef = "displayedColumns"></tr>
                <tr mat-row *matRowDef = "let student; columns: displayedColumns;"></tr>

            </table>

            <div
                *ngIf = "!studentLoadingInProgress && !studentFilteringInProgress && dataSource.data.length === 0"
                class = "no-students"
            >
                Nincs megjeleníthető tanuló.
            </div>

            <mat-spinner
                *ngIf = "studentLoadingInProgress || studentFilteringInProgress"
                style = "margin: 50px auto;"
            ></mat-spinner>
        </div>

    </div>
</section>
