import { Injectable } from '@angular/core';
import { config, S3 } from 'aws-sdk';
import { Buffer } from 'buffer';
import { awaitFor } from 'src/app/classes/misc';
import S3UploadStreamClient from "./aws-s3-upload-stream";

@Injectable({
  providedIn: 'root'
})
export class AwsS3Service {
  private readonly awsRegion:string = "eu-central-1";

  constructor() { }

  public initAwsConfig(accessKeyId:string, secretAccessKey:string, sessionToken:string):void {
    config.update({
      region: this.awsRegion,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      sessionToken: sessionToken,
      apiVersions: {
        s3: '2006-03-01'
      }
    });
  }

  public async uploadFiles(
    bucketName:string,
    directoryPath:string,
    files:Array<NamedFile>,
    setFilesPublic:boolean,
    uploadEventHandlers?:UploadEventHandlers
  ):Promise<string[]> {
    const resultUploadedFileUrl = [] as string[];

    for(const [ index, namedFile ] of files.entries()) {
      if(uploadEventHandlers?.beforeEachUpload) {
        uploadEventHandlers.beforeEachUpload(index, files.length);
      }

      try{
        await this.uploadFile(bucketName, directoryPath, namedFile, setFilesPublic, uploadEventHandlers);
        const url = `https://${bucketName}.s3.eu-central-1.amazonaws.com/${directoryPath}${!directoryPath.endsWith("/") ? '/' : ''}${namedFile.name}`;
        resultUploadedFileUrl.push(url);
      } catch(error:any) {
        console.log("upload hiba történt...");
        console.error(error);
      }
    }

    return resultUploadedFileUrl;
  }

  private async uploadFile(bucketName:string, directoryPath:string, namedFile:NamedFile, setFilePublic:boolean, uploadEventHandlers:UploadEventHandlers){
    const oneMb:number = 1000*1000; // 1000 * 1000 byte = 1 MB
    const chunkSize:number = 12*oneMb; // 12mb-os chunkokban töltjük fel

    const fileSize:number = namedFile.file.size;
    const chunkCount:number = fileSize / chunkSize;
    const numberOfIterations:number = fileSize%chunkSize === 0 ? chunkCount - 1 : chunkCount;

    const uploader = new S3UploadStreamClient(new S3());

    const uploadParams:any= {
      Bucket:bucketName,
      Key: `${directoryPath}${!directoryPath.endsWith("/") ? '/' : ''}${namedFile.name}`,
      ContentType: namedFile.file.type
    };

    if(setFilePublic) {
      uploadParams.ACL = 'public-read';
    }

    const uploadStream = uploader.upload(uploadParams);

    const file = namedFile.file;
    for(let iteration = 0; iteration < numberOfIterations; iteration++){
      uploadEventHandlers.onProgress({
        loaded:iteration,
        total:numberOfIterations
      });

      const start:number = iteration * chunkSize;
      const end:number = Math.min(fileSize, start + chunkSize);

      const currentArrayBuffer = await file.slice(start, end).arrayBuffer();

      try{
        while(true) {
          // Csak akkor írjuk hozzá a bufferhez, ha nincs benne egy chunk se
          const writableStreamBufferLength = uploadStream._writableState.buffer.length
          if(writableStreamBufferLength == 0){
            await uploadStream.write(Buffer.from(new Uint8Array(currentArrayBuffer)));
            break;
          }
          await awaitFor(20);
        }
      } catch(error:any) {
        console.error(error);
      }
    }

    uploadStream.end();

    return new Promise((resolve, reject) => {
      uploadStream.on('uploaded', resolve)
      uploadStream.on('error', reject)
    })
  }

}

export type UploadAccessToS3 = {
  directoryPath:string;
  access:{
      bucketName:string;
      accessKeyId:string;
      secretAccessKey:string;
      sessionToken:string;
  },
  temporaryAccessDurationInSeconds:number
}

export type UploadEventHandlers = {
  onProgress?:(progress:S3.ManagedUpload.Progress) => void;
  beforeEachUpload?:(actualFileIndex:number, numberOfFiles:number) => void;
}

export type NamedFile = {
  name:string;
  file:File;
}
