import { TimedIcon} from "./../classes/model/practice-path";
import { Injectable } from "@angular/core";
import { CriticalPoint } from "../classes/model/practice-path";
import { BackendService } from "./common/backend.service";
import { HttpErrorHandlerService } from "./common/http-error-handler.service";
import { HttpResponseData } from "../classes/http-communication";

// Időzített ikon hozzárendelésre/törlésre/módosításra használhatjuk egy útvonalon belül

@Injectable({
  providedIn: "root",
})
export class CriticalPointTimedIconAssignmentService {
  // teljes: practicePathApiUrlFragment/:practicePathUuid/timed_icons/:timedIconUuid?

  private readonly practicePathAssignmentApiUrlFragment: string =
    "/api/admin/practice_path/";
  constructor(
    private backendService: BackendService,
    private httpErrorHandlerService: HttpErrorHandlerService
  ) {}

  // Töröl egy időzített ikon hozzárendelést egy útvonalból
  public async removeTimedIconAssignment(
    practicePath: string,
    timedIconAssignmentUuid: string
  ): Promise<boolean> {
    try {
      const response: HttpResponseData<Array<CriticalPoint>> =
        await this.backendService.callApi(
          this.practicePathAssignmentApiUrlFragment +
            practicePath +
            "/timed_icons/" +
            timedIconAssignmentUuid,
          "DELETE"
        );
      return true;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba időzített ikon törlése közben!"
      );
      return false;
    }
  }

  // Egy új időzített ikont hoz létre a vizsgahelyszínben
  // Visszatér a beszúrt időzített ikonnal (uuid-val ellátva)
  // vagy undefined-al ha nem sikerült
  public async addTimedIconAssignment(
    practicePathUuid: string,
    timedIconAssignment: Omit<TimedIcon, "uuid"> // uuid-t nem kell megadni!
  ): Promise<TimedIcon | undefined> {
    try {
      const response: {
        insertedIcon: TimedIcon;
      } = await this.backendService.callApi(
        this.practicePathAssignmentApiUrlFragment +
          practicePathUuid +
          "/timed_icons/",
        "PUT",
        {
          item: timedIconAssignment,
        }
      );
      return response.insertedIcon;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba időzített ikon hozzáadásakor!"
      );
      return undefined;
    }
  }

  // Egy kritikus pontot hozzárendelést teljesen kicserél egy practice path-en belül
  public async replaceTimedIconAssignmentInPracticePath(
    practicePathUuid: string,
    timedIconAssignmentUuid: string,
    newTimedIconAssignment: TimedIcon
  ) : Promise<boolean> {
    try {
      const response: HttpResponseData<Array<CriticalPoint>> =
        await this.backendService.callApi(
          this.practicePathAssignmentApiUrlFragment +
            practicePathUuid +
            "/timed_icons/" +
            timedIconAssignmentUuid,
          "POST",
          {
            item: newTimedIconAssignment
          }
        );
      return true;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba időzített ikon módosításakor!"
      );
      return false;
    }
  }
}
