import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { PracticeCity } from "src/app/classes/model/practice-city";
import { Permission } from "src/app/classes/model/permissions";
import { PracticePathCardComponent } from "./practice-path-card/practice-path-card.component";
import { PracticePathsPageScrollPositionService } from "./services/practice-paths-page-scroll-position.service";
import { PracticePageInitializationError, PracticePageInitializationState, PracticePathsPageDataService } from "./services/practice-paths-page-data.service";
import { combineLatestWith, map, Observable } from "rxjs";
import { PermissionService } from "src/app/services/common/permission.service";

@Component({
  selector: "app-practice-paths",
  templateUrl: "./practice-paths.component.html",
  styleUrls: ["./practice-paths.component.scss"],
  providers: [
    PracticePathsPageScrollPositionService,
    PracticePathsPageDataService
  ]
})
export class PracticePathsComponent implements OnInit, OnDestroy {
  PracticePageInitializationState:typeof PracticePageInitializationState = PracticePageInitializationState;

  @ViewChildren(PracticePathCardComponent, { read: PracticePathCardComponent })
  practicePathCardsQueryList:QueryList<PracticePathCardComponent>;

  userCanEdit: boolean = false;

  currentSelectedCity:PracticeCity|null = null;

  practicePageInitializationState:PracticePageInitializationState;

  isContentLoading$:Observable<boolean>;
  isFilteringInitialized:boolean = false;
  isSortingInitialized:boolean = false;

  constructor(
    protected practicePathsPageDataService:PracticePathsPageDataService,
    private permissionService: PermissionService,
    private elementRef:ElementRef<HTMLElement>,
    private practicePathsPageScrollPositionService:PracticePathsPageScrollPositionService,
    private changeDetectorRef:ChangeDetectorRef
  ) {}

  public ngOnInit():void {
    // Save that if the user has the PracticePathWrite permission (can create practice paths)
    this.userCanEdit = this.permissionService.isLoggedUserHasPermission(Permission.PracticePathWrite);
    
    // Initialize the component
    this.initialize();
  }

  public ngOnDestroy(): void {
    // Before the component is destroyed, save the actual scroll position
    this.saveScrollPosition();
  }

  /**
   * Initializes the component. It uses the practicePathsPageDataService to fetch the available
   * city names and the initially selected city. It also sets the state of the page according
   * the result.
   */
  public async initialize(): Promise<void> {
    // Set the page state to loading
    this.practicePageInitializationState = PracticePageInitializationState.Loading;

    // Initialize the page's service
    // It handles the fetching of the city names and loads the initially selected city
    try {
      await this.practicePathsPageDataService.initialize();
    } catch(error:any) {
      if((error as PracticePageInitializationError).initializationState != undefined) {
        this.practicePageInitializationState = error.initializationState;
      } else {
        this.practicePageInitializationState = PracticePageInitializationState.Error;
      }
      return;
    }

    // If the initialization was successful, set the page state to successfully initialized
    this.practicePageInitializationState = PracticePageInitializationState.SucessfullyInitialized;

    // Set the content loading to true if either the isPracticePathDataLoading or the selectedCityLoading is true
    this.isContentLoading$ = this.practicePathsPageDataService.isPracticePathDataLoading$.pipe(
      combineLatestWith(this.practicePathsPageDataService.selectedCityLoading$),
      map(
        ([ isPracticePathDataLoading, selectedCityLoading ]) => {
          return isPracticePathDataLoading || selectedCityLoading; 
        }
      )
    );
  }

  /**
   * Saves the scroll position through the `PracticePathScrollPositionService`.
   */
  protected saveScrollPosition():void {
    this.practicePathsPageScrollPositionService.saveScrollPosition(
      this.elementRef.nativeElement.scrollTop,
      this.practicePathsPageDataService.getLastFilteredPracticePathBaseInformations() ?? [],
      this.practicePathCardsQueryList
    );
  }

  protected onFilteringInitialized():void {
    this.isFilteringInitialized = true;
    this.restoreScrollPositionIfContentInitialized();
  }

  protected onSortingInitialized():void {
    this.isSortingInitialized = true;
    this.restoreScrollPositionIfContentInitialized();
  }

  private restoreScrollPositionIfContentInitialized():void {
    if(this.isFilteringInitialized === false || this.isSortingInitialized === false) {
      return;
    }

    // Make sure that the UI has the changes
    this.changeDetectorRef.detectChanges();
    
    // With the practicePathsPageScrollPositionService restore the sroll position    
    this.practicePathsPageScrollPositionService.restoreScrollPosition(
      this.elementRef.nativeElement,
      this.practicePathCardsQueryList,
      this.practicePathsPageDataService.getPracticePathBaseInformationsSorter()
    );
  }
}

