import { Component, OnInit } from '@angular/core';
import { LogType } from 'src/app/modules/logger/models/log';
import { LoggerService } from 'src/app/modules/logger/services/logger.service';
import { CacheReloadService } from 'src/app/services/cache-reload.service';


@Component({
  selector: 'app-refresh-cache',
  templateUrl: './refresh-cache.component.html',
  styleUrls: ['./refresh-cache.component.scss']
})
export class RefreshCacheComponent implements OnInit {
  public readonly logKey:string = "refresh-cache"

  constructor(
    private refreshCacheService:CacheReloadService,
    private loggerService:LoggerService
  ) { }

  ngOnInit(): void {}

  public async refreshCache():Promise<void> {
    this.loggerService.addLog(this.logKey, "Cache frissítési kérelem elküldve a szerver felé.", LogType.Info);
  
    try {
      await this.refreshCacheService.refreshCache();
      this.loggerService.addLog(this.logKey, "A cache frissítése sikeresen lezajlott.", LogType.Success);
    } catch(error:any) {
      this.loggerService.addLog(this.logKey, "Hiba történt a cache frissítése közben.", LogType.Warning);
    }
  }

}
