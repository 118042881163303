import { MediaFormComponent } from './components/media-form/media-form.component';
import { VideoPlayerOverlayDirective } from './components/video-player/video-player.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ProgressDialogComponent } from './components/progress-dialog/progress-dialog.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    VideoPlayerComponent,
    VideoPlayerOverlayDirective,
    PageHeaderComponent,
    ProgressDialogComponent,
    NotificationDialogComponent,
    MediaFormComponent,
  ],
  imports: [
    CommonModule,
    NgCircleProgressModule,
    MatDialogModule
  ],
  exports:[
    VideoPlayerComponent,
    VideoPlayerOverlayDirective,
    PageHeaderComponent,
    MediaFormComponent,
  ]
})
export class SharedComponentsModule { }
