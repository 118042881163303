<div style = "font-size: 1.2rem; font-weight: 500; margin-bottom: 10px;">
    Művelet típusa: {{ data.change.type }}
</div>
<div class = "dialog-content">
    <div class = "change-compare-wrapper">
        <div id = "change-dialog-old-content">
            <div *ngIf = "data.change.type === 'add'"> Hozzáadásnál nincs régi kérdés adat. </div>
            <app-view-change *ngIf = "data.change.type !== 'add'" [data] = "data.change.oldQuestion" [compareTo] = "data.change.newQuestion" [differentColor] = "'#fc0303'" > </app-view-change>
        </div>
        <div id = "change-dialog-new-content">
            <div *ngIf = "data.change.type === 'delete'"> Törlésnél nincs új kérdés adat. </div>
            <app-view-change *ngIf = "data.change.type !== 'delete'" [data] = "data.change.newQuestion" [compareTo] = "data.change.oldQuestion" [differentColor] = "'#00781e'" ></app-view-change>
        </div>
    </div>
</div>
<div class = "dialog-actions">
    <button mat-button mat-dialog-close> Vissza </button>
</div>