import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DraggableDialogService } from './services/draggable-dialog.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { DraggableDialogContentWrapperComponent } from './components/draggable-dialog-content-wrapper/draggable-dialog-content-wrapper.component';

@NgModule({
  declarations: [
    DraggableDialogContentWrapperComponent
  ],
  imports: [
    CommonModule,
    DragDropModule,
    MatDialogModule,
    MatIconModule,
    PortalModule
  ],
  providers: [
    DraggableDialogService
  ]
})
export class DraggableDialogModule { }
