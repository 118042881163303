import { TabScrollPositionRecoveryService } from "./../../../../services/tab-position-recovery.service";
import {
  IconRecorderService,
  TimedIconRecording,
  TimedIconRecordingStatusManager,
} from "./components/timed-icon-recorder/timed-icon-recorder.service";
import { ConfirmationDialogService } from "./../../../confirmation-dialog/services/confirmation-dialog.service";
import { ConfirmationDialogComponent } from "./../../../confirmation-dialog/components/confirmation-dialog/confirmation-dialog.component";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { PracticePathGlobalVideoEditorPageService } from "../../services/practice-path-global-video-editor-page.service";
import { TimedIcon } from "src/app/classes/model/practice-path";

@Component({
  selector: "app-timed-icons-tab",
  templateUrl: "./timed-icons-tab.component.html",
  styleUrls: ["./timed-icons-tab.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimedIconsTabComponent implements OnInit, AfterViewInit {
  @Input()
  originalTabIndex: number;

  @ViewChild("timedIconList", { read: ElementRef })
  timedIconList: ElementRef;

  practiceIconUrls: string[] = [];
  selectedIconIndexes: number[] = [];

  iconRecorderService: IconRecorderService<TimedIconRecording>;

  constructor(
    public globalEditorService: PracticePathGlobalVideoEditorPageService,
    private confirmationDialogService: ConfirmationDialogService,
    private scrollPositionRecovery: TabScrollPositionRecoveryService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.iconRecorderService = new IconRecorderService(
      new TimedIconRecordingStatusManager(
        this.globalEditorService.mainVideoPlayerController._currentPositionInMs$
      )
    );

    this.practiceIconUrls = this.globalEditorService.practiceIcons.map(
      (pi) => pi.iconUrl
    );

    this.iconRecorderService.currentRecordingChanged.subscribe(() => {
      this.selectedIconIndexes = this.iconRecorderService
        .getRecordingIconUrls()
        .map((r) => this.practiceIconUrls.indexOf(r));
    });

    this.iconRecorderService.onFinishedRecording.subscribe((newRecording) => {
      this.globalEditorService.addTimedIconAssignment({
        startFrame: newRecording.startFrameMs,
        endFrame: newRecording.endFrameMs,
        practiceIconUuid: this.globalEditorService.practiceIcons.find(
          (p) => p.iconUrl == newRecording.practiceIconUrl
        ).uuid,
      });
    });

    // Ha változik a TimedIcon akkor rendereljünk újra
    this.globalEditorService.timedIconAssignments$.addListener(event => {
      this.changeDetector.markForCheck();
    });

    // Ha frissül a pozíció akkor is hívjunk egy change detektálást
    this.globalEditorService.mainVideoPlayerController.getCurrentPositionInMs$().subscribe(posi => {
      this.changeDetector.markForCheck();
    });
  }

  onTapIcon(index: number) {
    const isRecordInProgress = this.iconRecorderService.isRecordInProgress(
      this.practiceIconUrls[index]
    );

    // Ha újra rákattint egy olyanra amin megy a felvétel
    // azt VISSZAVONÁSNAK vesszük, nem pedig mentésnek.
    if (isRecordInProgress) {
      this.iconRecorderService.cancelRecording(this.practiceIconUrls[index]);
    } else {
      this.iconRecorderService.startRecording(this.practiceIconUrls[index]);
    }
  }

  // Egy kártyán egy időre kattintottak
  onTapTime(ms: number) {
    this.globalEditorService.mainVideoPlayerController.seekTo(ms);
  }

  onDeleteTimedIcon(timedIconAssignmentUuid: string) {
    this.confirmationDialogService.open(
      "Időzített ikon törlés",
      "Biztosan törölni szeretnéd az időzített ikont?",
      () => {
        this.globalEditorService.deleteTimedIconAssignment(
          timedIconAssignmentUuid
        );
      }
    );
  }

  isSpeficIconActive(timedIcon: TimedIcon) {
    const currentTime =
      this.globalEditorService.mainVideoPlayerController.getLastKnownPosition();
    return (
      timedIcon.startFrame <= currentTime && currentTime <= timedIcon.endFrame
    );
  }

  getCountOfActiveIcons(): number {
    return this.globalEditorService.timedIconAssignments$
      .getOriginalArrayRef()
      .reduce((prev, current) => {
        return this.isSpeficIconActive(current) ? prev + 1 : prev;
      }, 0);
  }

  ngAfterViewInit() {
    this.initScrollRecovery();
  }

  private initScrollRecovery() {
    this.scrollPositionRecovery.createEventListenerForMainScrollContextInTab(
      this.originalTabIndex.toString(),
      this.timedIconList.nativeElement
    );
  }
}
