<div class = "tab-header">
    <div>
        Itt találhatóak a viszonteladóhoz tartozó dokumentumok.
    </div>
    <button
        class = "add-documents-button"
        (click) = "onAddDocumentsButtonClick()"
        [disabled] = "!hasAdminDocumentUploadPermission()"
        [matTooltip] = "'Nincs jogosultságod a dokumentumfeltöltéshez.'"
        [matTooltipDisabled] = "hasAdminDocumentUploadPermission()"
    >
        <mat-icon> add </mat-icon>
    </button>
</div>
<div class = "content-container">
    <div *ngFor = "let vendorDocument of vendor.documents" class = "vendor-document">
        <div class = "document-header">    
            <div class = "vendor-document-name">
                {{ vendorDocument.name }}
            </div>
            <div class = "file-creation-info">
                <span style = "white-space: nowrap;">
                    {{ vendorDocument.created.timestamp | date: "YYYY-MM-dd HH:mm" }}
                </span>
                <mat-icon
                    class = "file-creation-info-icon"
                    [matTooltip] = "'Feltöltötte: ' + vendorDocument.created.adminEmail"
                > info </mat-icon>
            </div>
        </div>
        
        <div>
            <table class = "vendor-document-files-table">
                <tr *ngFor = "let file of vendorDocument.files">
                    <td>  {{ file.originalFileName }} </td>
                    <td style = "width: 80px;">  {{ file.size | fileSize }} </td>
                    <td style = "width: 20px;">
                        <mat-icon
                            *ngIf = "!isResourceDownloading(file)"
                            class = "download-icon"
                            (click) = "onDownloadIconClick(file)"
                        >
                            download
                        </mat-icon>
                        <mat-spinner
                            *ngIf = "isResourceDownloading(file)"
                            strokeWidth="3"
                            diameter="16"
                        ></mat-spinner>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>