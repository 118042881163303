import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'packageCode'
})
export class PackageCodePipe implements PipeTransform {

  transform(value:string):string {
    if(value.length < 5) {
      return "";
    }

    return value.slice(0, 4) + "-" + value.slice(4);
  }

}
