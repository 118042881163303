<div *ngIf = "previewEnabled" class = "media-form-preview-container" [ngClass] = "getPreviewContainerClass()" [ngStyle] = "getPreviewContainerStyle()">
    <ng-container *ngIf = "displayedPreviewType !== null; then previewAvailable; else previewNotAvailable">
    </ng-container>

    <ng-template #previewAvailable>
        <ng-container [ngSwitch] = "displayedPreviewType">
            <audio *ngSwitchCase = "'audio'" #PreviewAudio controls = "true" preload = "metadata">
                The audio tag is not supported by this browser
            </audio>
            <img style="object-fit:contain;" *ngSwitchCase = "'image'" #PreviewImage [width] = "previewWidth" [height] = "previewHeight" alt = "media-form image" draggable = "false" />
            <video *ngSwitchCase = "'video'" #PreviewVideo controls = "true" preload = "auto">
                The video tag is not supported by this browser
            </video>
            <div *ngSwitchDefault> Ehhez az elemtípushoz nem tartozik előnézet. </div>
        </ng-container>
    </ng-template>

    <ng-template #previewNotAvailable>
        <div class = "media-form-preview-placeholder-container">
            <div class = "material-icons">
                hide_image
            </div>
            <div style = "font-style: italic;">
                Nincs média feltöltve
            </div>
        </div>

    </ng-template>

</div>
<div class = "media-form-actions-container" [class.hidden] = "!buttonVisible">
    <input hidden #fileInput type = "file" [accept] = "acceptableFileTypes.join(', ')" />

    <div
        #button
        class = "media-form-button click"
        (click) = "handleInputButtonClick()"
    >
        {{ buttonText }}
    </div>
</div>
<div *ngIf = "formControlRef.hasError('invalidFileType')">
    Rossz file formátum!
</div>
