import { Injectable } from '@angular/core';
import { HttpResponseData } from '../classes/http-communication';
import { GlobalGift, globalGiftSorter } from '../classes/model/gift';
import { PackageDefinition } from '../modules/package-definitions-page/models/package-definitions.model';
import { Permission, PermissionString } from '../classes/model/permissions';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { DataCacherService } from './data-cacher-service';
import { PackageDefinitionService } from './package-definition.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalGiftService implements DataCacherService {
  private readonly giftApiUrlFragment:string = "/api/admin/global_gifts";

  private globalGifts:Array<GlobalGift>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.GlobalGiftRead ];


  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService,
    private packageDefinitionService:PackageDefinitionService
  ) {
    this.globalGifts = new Array<GlobalGift>();
  }

  public getGlobalGiftArrayRef():ReadonlyArray<Readonly<GlobalGift>> {
    return this.globalGifts;
  }

  public async fetchGlobalGifts():Promise<Array<GlobalGift>> {
    let globalGifts:Array<GlobalGift> = [];

    try {
      const response:HttpResponseData<Array<GlobalGift>> = await this.backendService.callApi(this.giftApiUrlFragment, "GET");
      globalGifts = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az ajándékok lekérdezése közben.");
    }

    return globalGifts;
  }

  public async createGlobalGift(packageDefinitionUuid:string, message:string, receivableUntil:number):Promise<void> {
    const httpBody:Object = {
      data: {
        packageDefinitionUuid: packageDefinitionUuid,
        message: message,
        receivableUntil: receivableUntil
      }
    };

    try {
      const response:HttpResponseData<GlobalGift> = await this.backendService.callApi(this.giftApiUrlFragment, "PUT", httpBody);

      const packageDefinitions:ReadonlyArray<Readonly<PackageDefinition>> = await this.packageDefinitionService.fetchPackageDefinitons();
      response.data.packageDefinitionName = packageDefinitions.find(
        packageDefinition => packageDefinition.uuid === response.data.packageDefinitionUuid
      )?.displayedName ?? "Ismeretlen csomag";

      this.globalGifts.push(response.data);
      this.globalGifts.sort(globalGiftSorter);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az ajándék létrehozása közben.");
    }
  }

  public async loadDataIntoCache():Promise<void> {
    const globalGifts:Array<GlobalGift> = await this.fetchGlobalGifts();
    this.globalGifts.replaceArrayElements(globalGifts).sort(globalGiftSorter);

    const packageDefinitions:ReadonlyArray<Readonly<PackageDefinition>> = await this.packageDefinitionService.fetchPackageDefinitons();
    this.globalGifts.forEach(
      (globalGift:GlobalGift) => {
        globalGift.packageDefinitionName = packageDefinitions.find(
          packageDefinition => packageDefinition.uuid === globalGift.packageDefinitionUuid
        )?.displayedName ?? "Ismeretlen csomag";
      }
    );
  }

  public clearCachedData():void {
    this.globalGifts.length = 0;
  }

  public getRequiredPermissionsForDataLoading(): ReadonlyArray<PermissionString> {
    return this.requiredPermissionsForDataLoading;
  }

  public getNameOfCachedData(): string {
    return "Globális ajándékok";
  }

}
