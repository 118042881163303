import { PracticePathUploadDialogComponent } from "./../../../practice-path-upload-dialog/practice-path-upload-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { VideoPlayerController } from "./../../../../../../../../modules/shared-module/components/video-player/video-player.controller";
import { PracticePath } from "./../../../../../../../../classes/model/practice-path";
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PermissionService } from "src/app/services/common/permission.service";
import { Permission } from "src/app/classes/model/permissions";

// Megjeleníti egy kicsi preview boxban a jelenlegi videót
// illetve biztosít egy Új videó feltöltése gombot
// ami egy dialógus ablakot fog megnyitni

enum PreviewVideoType {
  RAW = "Eredeti nyers videó",
  MINIMAL_BIT_RATE = "Minimal bitrate nyers",
  RELEASED = "HLS Video (RELEASED)",
  NONE = "Nincs videó feltöltve",
}

@Component({
  selector: "app-practice-path-video-preview-with-upload",
  templateUrl: "./practice-path-video-preview-with-upload.component.html",
  styleUrls: ["./practice-path-video-preview-with-upload.component.scss"],
})
export class PracticePathVideoPreviewWithUploadComponent implements OnInit {
  @Input()
  currentPracticePath: PracticePath;

  protected previewVideoPlayerController: VideoPlayerController;

  PreviewVideoType: typeof PreviewVideoType = PreviewVideoType;
  currentPreviewVideoType: PreviewVideoType;

  constructor(
    private dialogService: MatDialog,
    private routerService:Router,
    private permissionService: PermissionService
  ) {}


  ngOnInit(): void {
    const videoUrl = this.getPreviewVideoUrl();
    if (videoUrl != null) {
      this.previewVideoPlayerController = new VideoPlayerController(videoUrl);
    }
  }

  // Prioritás:
  // 1. Minimal bitrate raw
  // 2. Kiadott
  // 2. Eredeti raw
  // Ugyan ez a prioritás a szerkesztőben is
  getPreviewVideoUrl(): string | null {
    if (this.currentPracticePath.video.rawVideoConvertedMinimalBitrate != null) {
      this.currentPreviewVideoType = PreviewVideoType.MINIMAL_BIT_RATE;
      return this.currentPracticePath.video!.rawVideoConvertedMinimalBitrate
        .minimalBitrateVideoUrl;
    }

    if(this.currentPracticePath.video.releasedVideo != null){
      this.currentPreviewVideoType = PreviewVideoType.RELEASED;
      return this.currentPracticePath.video!.releasedVideo!.videoHlsUrl;
    }

    if (this.currentPracticePath.video.rawVideo != null) {
      this.currentPreviewVideoType = PreviewVideoType.RAW;
      return this.currentPracticePath.video.rawVideo.videoUrl;
    }

    this.currentPreviewVideoType = PreviewVideoType.NONE;
    return null;
  }

  onTapUploadNewVideo() {
    const ref = this.dialogService.open(PracticePathUploadDialogComponent, {
      disableClose: false,
      data: { practicePath: this.currentPracticePath }
    });

    ref.afterClosed().subscribe(
      async (updatedPracticePath:PracticePath|null) => {
        if(updatedPracticePath == null) {
          return;
        }
        
        const currentUrl = this.routerService.url;
        this.routerService.navigateByUrl('/', {skipLocationChange: true}).then(
          () => {
            this.routerService.navigate([currentUrl]);
          }
        );
      }
    );
  }

  protected adminHasPracticeVideoUploadPermission() {
    return this.permissionService.isLoggedUserHasPermission(Permission.GeneralFileUpload) && 
    this.permissionService.isLoggedUserHasPermission(Permission.PracticeVideoUpload);
  }
}
