import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Emitter } from "strict-event-emitter";

export type VideoPlayerControllerEvents = {
  play: [];
  pause: [];
  seekTo: [positionMs: number];
  changeTimeUpdateInterval: [interval: number]
  changeVideo: [newVideoUrl: string];
}

export class VideoPlayerController extends Emitter<VideoPlayerControllerEvents> {
  constructor(private _videoUrl: string, private initialTimeMs: number = 0) {
    super();
    this._currentPositionInMs$.next(initialTimeMs);
  }

  get videoUrl() {
    return this._videoUrl;
  }

  // _ csak a video player component használhatja!
  // Ha változnak az értékek ezeken a observable objecteken keresztül értesíti a listenereket
  public _totalDurationInMs$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  public _currentPositionInMs$: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  public _isPlaying$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  // A control-ra kattintott rá a user, ez még az isPlaying értékét nem állítja át
  // és még a plyr default event handlers előtt kapják meg a subject-ek az eseményt
  public _playControlClick = new Subject<void>();
  public _pauseControlClick = new Subject<void>();

  public _seekHappen$: Subject<void> = new Subject<void>();
  public _playbackSpeed: BehaviorSubject<number> = new BehaviorSubject(1);

  destroy() {
    this.removeAllListeners();
    this._totalDurationInMs$.unsubscribe();
    this._currentPositionInMs$.unsubscribe();
    this._isPlaying$.unsubscribe();
    this._seekHappen$.unsubscribe();
    this._playbackSpeed.unsubscribe();
  }

  changeVideoUrl(newVideoUrl: string) {
    this.emit("changeVideo", newVideoUrl);
    this._videoUrl = newVideoUrl;
  }

  getTotalDuration(): Observable<number> {
    return this._totalDurationInMs$.asObservable();
  }

  getLastKnownPosition() {
    return this._currentPositionInMs$.getValue();
  }

  getCurrentPositionInMs$(): Observable<number> {
    return this._currentPositionInMs$.asObservable();
  }

  getSeekObservable(): Observable<void> {
    return this._seekHappen$.asObservable();
  }

  getPlaybackSpeed(): Observable<number> {
    return this._playbackSpeed.asObservable();
  }

  playControlClick(): Observable<void> {
    return this._playControlClick.asObservable();
  }

  // Módosítja az idő frissítésnek az intervallumát
  // default ~ 28ms
  setTimeUpdateInterval(interval: number): void {
    this.emit("changeTimeUpdateInterval", interval);
  }

  isPlaying(): Observable<boolean> {
    return this._isPlaying$.asObservable();
  }

  play() {
    this.emit("play");
  }

  pause() {
    this.emit("pause");
  }

  seekTo(positionInMs: number) {
    this.emit("seekTo", positionInMs);
  }
}
