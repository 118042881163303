import * as nodeGeoDistance from "node-geo-distance";

export class GeoHelper {
  // Converts from degrees to radians.
  static toRadians(degrees: number) {
    return (degrees * Math.PI) / 180;
  }

  // Converts from radians to degrees.
  static toDegrees(radians: number) {
    return (radians * 180) / Math.PI;
  }

  static distance(startLat: number, startLng: number, destLat: number, destLng: number) : number{
    return  (nodeGeoDistance as any).vincentySync({latitude: startLat, longitude: startLng}, {latitude: destLat, longitude: destLng});
  }

  static bearing(startLat: number, startLng: number, destLat: number, destLng: number) {
    startLat = this.toRadians(startLat);
    startLng = this.toRadians(startLng);
    destLat = this.toRadians(destLat);
    destLng = this.toRadians(destLng);

    let y = Math.sin(destLng - startLng) * Math.cos(destLat);
    let x =
      Math.cos(startLat) * Math.sin(destLat) -
      Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    let brng = Math.atan2(y, x);
    brng = this.toDegrees(brng);
    return (brng + 360) % 360;
  }

  static bearingAngle(
    pos1: { latitude: number; longitude: number },
    pos2: { latitude: number; longitude: number }
  ) {
    return this.bearing(
      pos1.latitude,
      pos1.longitude,
      pos2.latitude,
      pos2.longitude
    );
  }
}
