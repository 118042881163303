<div
  (mouseenter)="onMouseEnter.next()"
  (mouseleave)="onMouseLeave.next()"
  [ngClass]="{
    'active-icon': this.isActive
  }"
  class="timed-icon-card"
>
  <!-- Icon és intervallum -->
  <div style="display: flex; align-items: center">
    <!-- Icon kép -->
    <img class="icon-img" [src]="this.resolvedPracticeIcon.iconUrl" />

    <!-- Idő intervallum -->
    <div style="margin-left: 10px; display: inline">
      <span (click)="onTapJump()" class="clickable-time"> Ugrás az ikon kezdetéhez: </span>
      <span (click)="onTapJump()" class="clickable-time"> {{id+1}} </span>
    </div>
  </div>

  <!-- Szerkesztés/Törlés -->
  <div style="display: flex; gap: 7px">
    <mat-icon
      (click)="this.onTapDelete.next()"
      class="delete-icon"
      style="cursor: pointer"
      >delete</mat-icon
    >
  </div>
</div>
