import { Injectable } from "@angular/core";

export type PracticePathSplitViewSettings = {
  leftSide: number;
  rightSide: number;
};

@Injectable()
export class PracticePathEditorSplitViewService {
  leftSide: number = 0.5;
  rightSide: number = 0.5;

  // Ezzel a kulcsal mentjük el a beállítást a split view-hoz
  static readonly cookieSettingsName = "practice-editor-split-view-settings";
  currentSettings: PracticePathSplitViewSettings;

  constructor() {    
    const cookie = localStorage.getItem(PracticePathEditorSplitViewService.cookieSettingsName);

    if (cookie) {
      const parsedCookie = JSON.parse(cookie) as PracticePathSplitViewSettings;
      this.currentSettings = parsedCookie;
    } else {
      this.currentSettings = { leftSide: 0.35, rightSide: 0.65 };
    }
  }

  save(leftSide: number, rightSide: number) {
    this.leftSide = leftSide;
    this.rightSide = rightSide;
    localStorage.setItem(
      PracticePathEditorSplitViewService.cookieSettingsName,
      JSON.stringify({
        leftSide: this.leftSide,
        rightSide: this.rightSide,
      } as PracticePathSplitViewSettings)
    );
  }
}
