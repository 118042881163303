<div id = "question-category-assignment-edit-dialog-title" > Kérdés pozícióhoz rendelése </div>
<div class = "dialog-content">
    <mat-card *ngFor = "let displayedQuestionPositions of displayedQuestionPositionsArray" class = "question-category-assignment-category-card">
        <div class = "question-category-assignment-category-header">
            <div class = "question-category-assignment-category-name">
                {{ displayedQuestionPositions.categoryName }}
            </div>
            <div class = "question-category-assignment-category-delete">
                <button mat-mini-fab color = "primary" (click) = "removeQuestionFromCategory(displayedQuestionPositions.categoryUuid)">
                    <mat-icon> delete </mat-icon>
                </button>
            </div>
        </div>
        <div class = "category-position-container">
            <div
                *ngFor = "let position of displayedQuestionPositions.positions"
                [ngClass] = "getPositionStyleClass(displayedQuestionPositions.categoryUuid, position - 1)"
                (click) = "tooglePositionAssignment(displayedQuestionPositions.categoryUuid, position - 1)"
            >
                {{ position }}
            </div>
        </div>
        
    </mat-card>
</div>
<div>
    <form [formGroup] = "categorySelectFG">
        <div id = "question-category-assignment-select-container">
            <div>
                    <mat-form-field appearance='outline' id = "question-category-assignment-select-form" >
                    <mat-label>Kategóriák</mat-label>
                    <mat-select formControlName = "questionCategorySelectFC">
                        <mat-option *ngFor = "let category of categoriesInSelect" [value] = "category.uuid"> {{ category.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <button mat-raised-button color = "primary" (click) = "addQuestionToCategory()" [disabled] = "questionCategorySelectFC.value === null">
                    Kategória felvétele
                </button>
            </div>
        </div>
    </form>
</div>
<div id = "question-category-assignment-edit-dialog-actions">
    <button mat-button mat-dialog-close> Vissza </button>
    <button id = question-category-assignment-edit-dialog-save mat-button (click) = "handleSaveButtonClick()" mat-dialog-close> Mentés </button>
</div>

