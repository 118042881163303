import { VideoPlayerController } from "./video-player.controller";
import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import Plyr from "plyr";
import Hls from "hls.js";
import { Subscription } from "rxjs";
import { Platform } from "@angular/cdk/platform";
var clone = require("clone");

@Directive({ selector: "video-overlay" })
export class VideoPlayerOverlayDirective { }

@Component({
  selector: "video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPlayerComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Output()
  onInited = new EventEmitter<void>();

  @ContentChildren(VideoPlayerOverlayDirective, { read: ElementRef })
  overlays!: QueryList<ElementRef>;
  queryListSubscription: Subscription;

  nativeHtmlVideoElement: HTMLVideoElement;


  @Input()
  isKeyboardShortcutEnabled: boolean = false;

  @Input()
  disabledControls: string[] = [];

  @Input()
  clickToPlay: boolean = true;

  // Ezzel lehet vezérleni a videót
  @Input()
  videoPlayerController!: VideoPlayerController;

  @Input()
  isControlEnabled: boolean = true;

  // Ebbe a containerben jelennek meg a videó képkockák
  @ViewChild("videoDiv")
  mainVideoContainer!: ElementRef;

  // Ez felel a hls streamért
  hlsClient!: Hls;

  @Input()
  useLargeControlSize: boolean = false;

  @Input()
  fastForwardSeekTimeInSec: number = 5;

  videoSourceForMp4: HTMLSourceElement;

  // A plyr össze van kötve a hlsClient-el
  // A frameket a hlsClient biztosítja
  plyr!: Plyr;

  timeUpdateInterval: any;

  addedNativeElementToPlyrOverlay: any[] = [];

  subscriptions: Subscription[] = [];

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private zone: NgZone,
    private applicationRef: ApplicationRef,
    private platformService: Platform
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.plyr == undefined) return;
    if (changes["isControlEnabled"]) {
      this.plyr.toggleControls(changes["isControlEnabled"].currentValue);
    }
  }

  ngOnInit(): void { }

  addOverlaysFromNgContent() {
    const plyrVideoRealContainer = (
      this.mainVideoContainer.nativeElement as HTMLElement
    ).getElementsByClassName("plyr__video-wrapper");

    this.renderer.addClass(
      this.elementRef.nativeElement,
      this.useLargeControlSize ? "large-control-size" : "small-control-size"
    );

    const plyrControls = (
      this.mainVideoContainer.nativeElement as HTMLElement
    ).getElementsByClassName("plyr__controls");

    (plyrVideoRealContainer[0] as HTMLElement).style.zIndex = "2";
    (plyrControls[0] as HTMLElement).style.zIndex = "4";

    for (const overlay of this.overlays) {
      if (
        this.addedNativeElementToPlyrOverlay.includes(overlay.nativeElement)
      ) {
        this.renderer.removeChild(
          plyrVideoRealContainer[0],
          overlay.nativeElement
        );
      }

      this.addedNativeElementToPlyrOverlay.push(overlay.nativeElement);

      overlay.nativeElement.style.zIndex = 3;
      overlay.nativeElement.style.position = "absolute";
      overlay.nativeElement.style.left = "0px";
      overlay.nativeElement.style.top = "0px";
      overlay.nativeElement.style.width = "100%";
      overlay.nativeElement.style.height = "100%";

      this.renderer.appendChild(
        plyrVideoRealContainer[0],
        overlay.nativeElement
      );
    }
  }

  addListenersToVideoPlayerController() {
    this.videoPlayerController?.addListener("play", this.onPlayEvent);
    this.videoPlayerController?.addListener("pause", this.onPauseEvent);
    this.videoPlayerController?.addListener("seekTo", this.onSeekToEvent);
    this.videoPlayerController?.addListener(
      "changeVideo",
      this.onChangeVideoSource
    );
    this.videoPlayerController?.addListener(
      "changeTimeUpdateInterval",
      this.onChangeTimeUpdateInterval
    );
  }

  onChangeVideoSource(newVideoUrl: string) {
    throw new Error("NOT_IMPLEMENTED");
  }

  // A jelenlegi idő frissítésének interval-ja megváltozott (pl: playback speed növelése esetén sűrűbben kell frissíteni)
  onChangeTimeUpdateInterval = (newInterval: number) => {
    if (this.timeUpdateInterval != null) {
      clearInterval(this.timeUpdateInterval);
      this.timeUpdateInterval = null;
    }

    this.zone.runOutsideAngular(() => {
      this.timeUpdateInterval = this.createNewTimeUpdateInterval(newInterval);
    });
  };

  onSeekToEvent = async (newPositionInMs: number) => {
    if (this.plyr == null) return;
    const seekPositionInSec = newPositionInMs / 1000;
    this.plyr.currentTime = seekPositionInSec;

    if (!this.plyr.playing) {
      // Ha nem megy a videó, akkor egy pillanatra indítsuk el, majd állítsuk meg
      // valamiért bizonyos esetekben ha meg van állítva és úgy seekelünk, akkor
      // csak akkor teker az adott frame-re, ha elindítjuk a videót.
      await this.plyr.play();
      if (this.plyr == null) return;
      this.plyr.pause();
      this.plyr.currentTime = seekPositionInSec;
    }
  };

  onPauseEvent = () => {
    this.plyr.pause();
  };

  onPlayEvent = () => {
    this.plyr.play();
  };

  runDetector() {
    console.log("Run video player change detector");
  }

  ngOnDestroy() {
    this.queryListSubscription.unsubscribe();

    this.nativeHtmlVideoElement?.removeAllListeners?.("seeking");
    this.nativeHtmlVideoElement?.removeAllListeners?.("ended");

    this.nativeHtmlVideoElement.pause();
    this.nativeHtmlVideoElement.removeAttribute("src");
    this.nativeHtmlVideoElement.load();

    this.videoPlayerController?.removeListener("pause", this.onPauseEvent);
    this.videoPlayerController?.removeListener("play", this.onPlayEvent);
    this.videoPlayerController?.removeListener("seekTo", this.onSeekToEvent);
    this.videoPlayerController?.removeListener(
      "changeTimeUpdateInterval",
      this.onChangeTimeUpdateInterval
    );

    this.subscriptions.forEach((s) => s.unsubscribe());

    clearInterval(this.timeUpdateInterval);

    if (this.hlsClient) {
      // hacky clear interval, avoid memory leak
      // A hlsclient memory leak-et tartalmaz, egy helyen nincs meghívva a handleDestroy szülő metódus
      const hlsAny = this.hlsClient as any;
      hlsAny.streamController?.clearInterval();

      for (const controller of hlsAny.networkControllers) {
        if (controller.clearInterval) {
          clearInterval(controller._tickInterval);
          controller.__proto__.clearInterval(); // TaskLoop
          controller.__proto__.clearNextTick(); // TaskLoop
        }
      }

      this.hlsClient?.removeAllListeners();
      this.hlsClient?.destroy();
      this.hlsClient?.detachMedia();

      (this.hlsClient as any) = null;
    }

    (this.nativeHtmlVideoElement as any).plyr = null;
    (this.nativeHtmlVideoElement as any) = null;
    this.plyr.off("qualitychange", this.onQualityChange);

    (this.plyr as any).listeners = [];
    this.plyr.destroy();

    (this.plyr as any) = null;
  }

  ngAfterViewInit(): void {
    this.queryListSubscription = this.overlays.changes.subscribe(() => {
      this.addOverlaysFromNgContent();
    });

    const url = this.videoPlayerController.videoUrl;
    console.info(`video-player.components.ts, videoUrl: ${url}`);

    this.nativeHtmlVideoElement = document.createElement("video");
    this.nativeHtmlVideoElement.setAttribute("disablePictureInPicture", "true");

    this.mainVideoContainer.nativeElement.appendChild(
      this.nativeHtmlVideoElement
    );
    // For more options see: https://github.com/sampotts/plyr/#options
    const defaultOptions: Plyr.Options = {
      controls: this.isControlEnabled
        ? [
          "progress",
          "current-time",
          "settings",
          "volume",
          "rewind",

          "play",
          "fast-forward",
          "fullscreen",
        ].filter((value) => this.disabledControls.includes(value) == false)
        : [],

      enabled: true,
      hideControls: false,
      settings: ["speed"],
      listeners: {
        play: () => {
          this.videoPlayerController._playControlClick.next();
        },
        pause: () => {
          this.videoPlayerController._pauseControlClick.next();
        },
      },
      invertTime: false,
      seekTime: this.fastForwardSeekTimeInSec,
      clickToPlay: this.clickToPlay,
      speed: {
        selected: 1,
        options: this.platformService.SAFARI ? [1, 2] : [0.5, 1, 2, 3, 4],
      },
      i18n: {
        speed: "Sebesség",
        normal: "Normál",
      },
      ads: {
        enabled: false,
      }
    };

    if (url.includes(".m3u8")) {
      // Ha nem futtatnánk outside akkor rengetegszer lefutna feleslegesen a change detector
      // az egész alkalmazásra
      this.zone.runOutsideAngular(() => {
        this.plyr = new Plyr(
          this.nativeHtmlVideoElement,
          defaultOptions
        );

        this.plyr.on("qualitychange", this.onQualityChange);

        this.hlsClient = new Hls({});
        this.hlsClient.attachMedia(this.nativeHtmlVideoElement);

        this.hlsClient.on(Hls.Events.LEVEL_SWITCHED, (levelSwitch, data) => {
          //console.log("hlsClient level switch");
          //console.log(levelSwitch,data);
        });

        this.hlsClient.on(Hls.Events.MEDIA_ATTACHED, () => {
          this.hlsClient.loadSource(url);

          this.hlsClient.on(
            Hls.Events.MANIFEST_PARSED,
            (event: any, data: any) => {
              const availableQualities = this.hlsClient.levels.map(
                (l) => l.height
              );
              console.log("Videóban elérhető minőségek: ", availableQualities);

              this.zone.run(() => {
                // Mivel angular specifikus dolgot állítunk a postInit-ben ezért az ngZone-on belül kell végrehajtani!
                this.postInitOnPlyr();
              });
            }
          );
        });
      });
    } else {
      this.videoSourceForMp4 = document.createElement("source");
      this.videoSourceForMp4.setAttribute("src", url);
      this.videoSourceForMp4.setAttribute("type", "video/mp4");
      this.nativeHtmlVideoElement.appendChild(this.videoSourceForMp4);

      this.plyr = new Plyr(this.nativeHtmlVideoElement, defaultOptions);

      this.postInitOnPlyr();
    }


    this.addKeyboardListener();

  }

  // Keyboard shortcuts:
  // 'A' - 5s hátra tekereés
  // 'D' - 5s előre tekereés
  // 'S' - Megállítás/elindítás
  addKeyboardListener() {
    const keyboardListener = (event: KeyboardEvent) => {
      if (this.isKeyboardShortcutEnabled == false) return;

      if (event.key == 'a') {
        this.videoPlayerController.seekTo(this.videoPlayerController.getLastKnownPosition() - 5000);
      } else if (event.key == 'd') {
        this.videoPlayerController.seekTo(this.videoPlayerController.getLastKnownPosition() + 5000);
      } else if (event.key == 's') {
        if (this.plyr.playing) {
          this.videoPlayerController.pause();
        } else {
          this.videoPlayerController.play();
        }
      }
    };

    // Listen on global document

    document.addEventListener('keydown', keyboardListener);
    this.subscriptions.push(new Subscription(() => {
      document.removeEventListener('keydown', keyboardListener);
    }));
  }

  postInitOnPlyr() {
    this.addOverlaysFromNgContent();
    this.addPlyrListeners();
    this.addListenersToVideoPlayerController();
  }

  // a plyr jelenleg hls stream-ben quality változtatást nem támogat
  // tehát ez nem lesz meghívva sose. Akkor lenne meghívva, ha több stream-et adnánk meg mi manuálisan (pl több mp4)
  // és abban történő változásnál hívná a player ezt, hogy ki lett választva egy quality
  onQualityChange = (e: any) => {
    console.log("VideoPlayer change quality:", e);
    this.updateQuality(e);
  };

  addPlyrListeners() {
    this.plyr.on("ratechange", () => {
      this.videoPlayerController._playbackSpeed.next(this.plyr.speed);
    });

    this.plyr.on("seeking", () => {
      this.videoPlayerController._seekHappen$.next();
    });

    this.plyr.on("loadedmetadata", () => {
      // Ha betöltött a metadata akkor seekeljük a jelenlegi pozícióra (ami nem feltétlen 0)
      this.onSeekToEvent(
        this.videoPlayerController._currentPositionInMs$.getValue()
      );
      this.videoPlayerController?._isPlaying$.next(this.plyr.playing);
      this.videoPlayerController._totalDurationInMs$.next(
        Math.round(this.plyr.duration * 1000)
      );
      this.onInited.emit();

      this.startVideoPlayerCurrentTimeUpdater();
    });

    this.plyr?.on("playing", () => {
      this.videoPlayerController._isPlaying$.next(this.plyr.playing);
    });

    this.plyr?.on("pause", () => {
      this.videoPlayerController._isPlaying$.next(this.plyr.playing);
    });
  }

  startVideoPlayerCurrentTimeUpdater() {
    this.zone.runOutsideAngular(() => {
      const defaultTimeUpdateInMs = 28;
      if (this.timeUpdateInterval != null) {
        clearInterval(this.timeUpdateInterval);
        this.timeUpdateInterval = null;
      }

      this.timeUpdateInterval = this.createNewTimeUpdateInterval(
        defaultTimeUpdateInMs
      );
    });
  }

  private createNewTimeUpdateInterval(intervalMs: number) {
    let lastValue = -1;
    return setInterval(() => {
      const current = Math.round(this.plyr.currentTime * 1000);
      if (current != lastValue) {
        lastValue = current;

        this.zone.run(() => {
          // Futtatunk a root-tól egy change detektálást
          this.applicationRef.tick();
          // Majd átadjuk az új pozíciót a feliratkozóknak
          // mivel hívtunk change detektálást ezért le fog futni a detektor
          this.videoPlayerController._currentPositionInMs$.next(current);
        });
      }
    }, intervalMs);
  }

  updateQuality(newQuality: any): void {
    this.hlsClient.levels.forEach((level: any, levelIndex: any) => {
      if (level.height === newQuality) {
        this.hlsClient.currentLevel = levelIndex;
      }
    });
  }
}
