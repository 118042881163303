import { Component, Input } from '@angular/core';
import { GeneralPracticalModuleLog } from 'src/app/modules/practical-module-logs/models/practical-module-log.model';

@Component({
  selector: 'app-general-log-item-content',
  templateUrl: './general-log-item-content.component.html',
  styleUrls: ['./general-log-item-content.component.scss']
})
export class GeneralLogItemContentComponent {
  @Input() generalPracticalModuleLog:GeneralPracticalModuleLog;
}
