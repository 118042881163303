<div class="main-data-form">
  <!-- Útvonal neve -->
  <mat-form-field appearance="outline" class="text-input-field" style="margin-bottom: 10px;">
    <mat-label>Útvonal neve</mat-label>
    <input
      matInput
      autocomplete="off"
      [formControl]="nameFC"
      placeholder="Rókusi 3. kijárat / 1. teljes útvonal"
      value="Sushi"
    />

    <mat-error *ngIf="nameFC.hasError('required')">Kötelező mező!</mat-error>
  </mat-form-field>

  <div
    style="
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr 1fr;
      width: 100%;
    "
  >
    <!-- Állapot -->
    <mat-form-field appearance="outline" style="margin-bottom: 10px;">
      <mat-label>Állapot</mat-label>
      <mat-select
        [formControl]="editStateFC"
        [disabled]="this.practicePath == null"
      >
        <mat-select-trigger>
          <div class="edit-state-option-container">
            <div
              class="edit-state-option-colored-circle"
              [style.background-color]="getSelectedEditState()?.color"
            ></div>
            {{ getSelectedEditState()?.displayedName }}
          </div>

        </mat-select-trigger>
        <mat-option
          *ngFor="let pathEditState of pathEditStates"
          [value]="pathEditState.key"
        >
          <div class="edit-state-option-container">
            <div
              class="edit-state-option-colored-circle"
              [style]="'background-color:' + pathEditState.color + ';'"
            ></div>
            {{ pathEditState.displayedName }}
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Vizsgahelyszín -->
    <mat-form-field appearance="outline" style="margin-bottom: 10px;">
      <mat-label>Vizsgahelyszín</mat-label>
      <mat-select [formControl]="cityFC" [disabled]="this.practicePath != null">
        <mat-option
          *ngFor="let city of cityNames; let i = index"
          [value]="cityUuids[i]"
        >
          {{ city }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="cityFC.hasError('required')">Kötelező mező!</mat-error>
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline">
    <mat-label>Zónák</mat-label>
    <mat-select [formControl]="zonesFC" multiple>
      <mat-option
        *ngFor="let zone of currentSelectedCity?.zones ?? []"
        [value]="zone.uuid"
      >
        {{ zone.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-checkbox [formControl]="isFullExamPathFc" class="example-margin">
    Teljes útvonal
  </mat-checkbox>

  <mat-checkbox [formControl]="isFreeFC" class="example-margin">
    Ingyenes
  </mat-checkbox>

  <mat-form-field appearance="outline" *ngIf="isFullExamPathFc.value">
    <mat-label>Vizsgatétel szám</mat-label>
    <mat-select [formControl]="examPathNumberFc">
      <mat-option
        *ngFor="
          let item of [].constructor(currentSelectedCity?.countOfExamPath ?? 0);
          let i = index
        "
        [value]="i"
      >
        {{ i + 1 }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="examPathNumberFc.hasError('required')">Kötelező mező!</mat-error>
  </mat-form-field>
</div>
