<div class = "title">
    Válassz szervert
</div>
<mat-radio-group [(ngModel)] = "selectedEnvironmentType" >
    <mat-radio-button
        *ngFor = "let environment of predefinedEnvironmentService.getPredefinedEnvironments()"
        [value] = "environment.type"
        class = "select-item"
    >
        {{ environment.serverHost }}
    </mat-radio-button>
    <mat-radio-button
        [value] = "customEnvironmentService.getCustomEnvironmentTypeValue()"
    >
         <mat-form-field appearance='outline' style = "width: 300px">
            <mat-label>Egyedi cím</mat-label>
            <input
                #customEnvironmentHostAddressInput
                matInput
                [formControl] = "customEnvironmentHostAddressFC"
            />
        </mat-form-field>
    </mat-radio-button>
</mat-radio-group>
<div style = "text-align: center; margin-top: 20px;">
    <button
        mat-raised-button
        (click) = "handleSaveButtonClick()"
        [disabled] = "!isSaveButtonEnabled()"
    >
        Mentés
    </button>
</div>
