import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PracticePathNarrationDuringRecording } from 'src/app/classes/model/practice-path';

export type NarrationPlayerDialogInput = {
  narrationDuringRecording:PracticePathNarrationDuringRecording
};

@Component({
  selector: 'app-narration-player-dialog',
  templateUrl: './narration-player-dialog.component.html',
  styleUrls: ['./narration-player-dialog.component.scss']
})
export class NarrationPlayerDialogComponent implements OnInit {
  @ViewChild("audioPlayer") private audioElementRef:ElementRef<HTMLAudioElement>;

  private readonly timeJumpSizeInSec:number = 10;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data:NarrationPlayerDialogInput
  ) { }

  ngOnInit(): void { }

  /**
   * Rewinds the audio element by `timeJumpSizeInSec` secs
   */
  protected onRewindButtonClick():void {
    this.audioElementRef.nativeElement.currentTime -= this.timeJumpSizeInSec;
  }

  /**
   * Forwards the audio element by `timeJumpSizeInSec` secs
   */
  protected onForwardButtonClick():void {
    this.audioElementRef.nativeElement.currentTime += this.timeJumpSizeInSec;
  }
}
