import { MarkeredPolylineController } from "./markered-polyline-controller";
import { RadioButton } from "./radio-buttons-float-menu/radio-buttons-float-menu.component";
import { GoogleMapExtendedController } from "./google-map-controller";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  DoCheck,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { GoogleMap } from "@angular/google-maps";
import { Subscription } from "rxjs";

@Pipe({ name: "markeredPolylineToRadioButton", pure: true })
export class MarkeredPolylineControllerToRadioPipe implements PipeTransform {
  transform(
    markeredPolylineControllers: MarkeredPolylineController[]
  ): RadioButton[] {
    return markeredPolylineControllers.map((markeredPolylineController) => ({
      switchName: markeredPolylineController.getId(),
      label: markeredPolylineController.getDisplayedName(),
      value: markeredPolylineController.isMarkerVisible,
    }));
  }
}

@Component({
  selector: "google-map-extended",
  templateUrl: "./google-max-extended.component.html",
  styleUrls: ["./google-max-extended.component.scss"],
})
export class GoogleMapExtendedComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @ViewChild(GoogleMap)
  public googleMapComponent!: GoogleMap;

  // Amikor a view inicializálódott meghívásra kerül az onReady callback (EventEmitter, amire a szülő fel van iratkozva)
  @Output()
  public onReady = new EventEmitter<GoogleMapExtendedController>();

  public readonly mapId = Math.floor(Math.random() * 10000);

  public defaultMapOptions: google.maps.MapOptions = {
    zoom: 16,
    mapTypeControl: false,
    clickableIcons: false,
  };

  // Ezzel vezérelhető a térkép
  // maga a komponens létrehozza és az onReady output-on keresztül
  // elküldi a szülőnek
  public mapController!: GoogleMapExtendedController;
  public mapClickSubscription: Subscription;

  // Kellenek még az útvonal ikonok listája, hogy az alapján jelenítsük meg az útvonal ikon első pontját
  constructor(
    public changeDetector: ChangeDetectorRef,
    public ngZone: NgZone
  ) {}

  // Amikor megszűnik a kompoenns, akkor
  // a felszabadítunk mindent a controllerből is. (event listeners, object reference, stb..)
  ngOnDestroy(): void {
    this.mapClickSubscription.unsubscribe();
    google.maps.event.clearInstanceListeners(this.googleMapComponent.googleMap);
    this.mapController.dispose();
    this.mapController = null;
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    // A térkép akkor inicializálódik, amikor a komponens az GoogleMap component ngOnInit-ben van
    // mivel az ngAfterViewInit akkor hívódik amikor az összes gyerek inicializálódott, ezért a térkép ezen a ponton már ok
    this.mapController = new GoogleMapExtendedController(
      this.googleMapComponent!.googleMap!
    );

    this.onReady.emit(this.mapController);
    // onReady hatására úgy változnak adatok, hogy nem triggereli automatikusan a change detectort
    // ezért nekünk kell detektálni
    // A markeredPolylineController-ek például változnak onReady callback alatt
    this.changeDetector.detectChanges();

    this.mapClickSubscription = this.googleMapComponent.mapClick.subscribe(
      (mouseEvent) => {
        this.mapController.onTapGoogleMap({
          latitude: mouseEvent.latLng?.lat()!,
          longitude: mouseEvent.latLng?.lng()!,
        });
      }
    );

    this.mapController.isStreetViewMode.subscribe(() => {
      this.changeDetector.detectChanges();
    });
  }

  onChangeSwitchSettings(event: {
    markeredPolylineId: string;
    newValue: boolean;
  }) {
    this.mapController
      .getMarkeredPolylineControllers()
      .find((m) => m.getId() == event.markeredPolylineId)
      ?.setMarkersVisibility(event.newValue!);
  }
}
