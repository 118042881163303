import { AnonymizerTask } from './../classes/model/anonymizer-task';
import { Injectable } from '@angular/core';
import { PracticePath, PracticePathBaseInformations } from '../classes/model/practice-path';
import { AnonymizedVideo, videoSorter } from '../classes/model/practice-video';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class PracticeVideoService {
  private readonly anonimizeVideoApiUrlFragment: string = "/api/admin/practice_path/anonymize_raw_video";

  private readonly promoteAnonymizedVideoApiUrlFragment: string = "/api/admin/practice_path/promote_anonymized_video";
  private readonly uploadFinalVideoApiUrlFragment: string = "/api/admin/practice_path/upload_final_video";
  private readonly releaseFinalVideoApiUrlFragment: string = "/api/admin/practice_path/release_final_video";

  // videó törlő endpointok
  private readonly deleteAnonymizedVideoApiUrl: string = "/api/admin/practice_path/delete_anonymized_video";
  private readonly deleteFinalVideoApiUrl: string = "/api/admin/practice_path/delete_final_video";
  private readonly deleteRawVideoApiUrl: string = "/api/admin/practice_path/delete_raw_video";


  constructor(
    private backendService: BackendService,
    private httpErrorHandlerService: HttpErrorHandlerService
  ) { }

  public async anonymizeVideo(
    practicePath: PracticePath | PracticePathBaseInformations,
    settings: {
      fastMode: boolean,
      startNewInstance: boolean,
      debugBoundingBox: boolean
    }): Promise<void> {
    const httpBody: Object = {
      practicePathUuid: practicePath.uuid,
      debugBoundingBox: settings.debugBoundingBox,
      mode: settings.fastMode ? 'fast' : 'slow',
      priority: settings.startNewInstance ? 'high' : 'low'
    }

    try {
      const response: { anonymizedVideo: AnonymizedVideo } = await this.backendService.callApi(this.anonimizeVideoApiUrlFragment, "POST", httpBody);
      practicePath.video.anonymizedVideos.push(response.anonymizedVideo);
      practicePath.video.anonymizedVideos.sort(videoSorter);
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a videó anonimizálása közben.");
    }
  }

  public async promoteAnonymizedVideo(practicePath: PracticePathBaseInformations, anonimizedVideoUuid: string): Promise<void> {
    const httpBody: Object = {
      data: {
        practicePathUuid: practicePath.uuid,
        anonymizedVideoUuid: anonimizedVideoUuid
      }
    };

    try {
      const response: { data: PracticePath } = await this.backendService.callApi(this.promoteAnonymizedVideoApiUrlFragment, "POST", httpBody);
      practicePath.video.finalVideos.replaceArrayElements(response.data.video.finalVideos).sort(videoSorter);
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a videó véglegesítése közben");
    }
  }

  public async releaseFinalVideo(practicePath: PracticePathBaseInformations, finalVideoUuid: string,startCloudInstance: boolean): Promise<string> {
    const httpBody: Object = {
      data: {
        practicePathUuid: practicePath.uuid,
        finalVideoUuid: finalVideoUuid,
        startCloudInstance: startCloudInstance
      }
    }

    try {
      const response: { taskId: string } = await this.backendService.callApi(this.releaseFinalVideoApiUrlFragment, "POST", httpBody);
      return response.taskId;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(error, "Hiba történt a kiadás közben");
    }
  }

  public async deleteAnonymizedVideo(practicePath: PracticePathBaseInformations, anonymizedVideoUuid: string, reason: string): Promise<void> {
    const httpBody: Object = {
      practicePathUuid: practicePath.uuid,
      anonymizedVideoUuid: anonymizedVideoUuid,
      reason: reason
    };

    try {
      const response: { updatedPracticePath: PracticePath } = await this.backendService.callApi(this.deleteAnonymizedVideoApiUrl, "POST", httpBody);
      practicePath.video.anonymizedVideos = response.updatedPracticePath.video.anonymizedVideos.sort(videoSorter);
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az anonimizált videó törlése közben");
    }
  }

  public async deleteFinalVideo(practicePath: PracticePathBaseInformations, finalVideoUuid: string,reason: string): Promise<void> {
    const httpBody: Object = {
      practicePathUuid: practicePath.uuid,
      finalVideoUuid: finalVideoUuid,
      reason: reason
    };

    try {
      const response: { updatedPracticePath: PracticePath } = await this.backendService.callApi(this.deleteFinalVideoApiUrl, "POST", httpBody);
      practicePath.video.finalVideos = response.updatedPracticePath.video.finalVideos.sort(videoSorter);
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a végleges videó törlése közben");
    }
  }

  public async deleteRawVideo(practicePath: PracticePathBaseInformations, reason: string): Promise<void> {
    const httpBody: Object = {
      practicePathUuid: practicePath.uuid,
      reason: reason
    };

    try {
      const response: { updatedPracticePath: PracticePath } = await this.backendService.callApi(this.deleteRawVideoApiUrl, "POST", httpBody);
      practicePath.video.rawVideo = response.updatedPracticePath.video.rawVideo;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a nyers videó törlése közben");
    }
  }

}
