import { Injectable } from '@angular/core';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class CacheReloadService {
  readonly reloadCacheApiUrlFragment:string = "/api/admin/cache/reload";

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) { }

  public async refreshCache():Promise<void> {
    try {
      await this.backendService.callApi(this.reloadCacheApiUrlFragment, "POST");
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a cache frissítése közben.");
    }
  }
}
