<app-page-header
    title = "Karbantartás"
    subtitle = "A szerver karbantartási állapotát  kapcsolhatod be- és ki itt."
    style = "margin-bottom: 20px;"
>
    <button mat-mini-fab color = "primary" (click) = "handleReloadButtonClick()">
        <mat-icon> refresh </mat-icon>
    </button>
</app-page-header>
<div *ngIf = "actualMaintenanceStatus" style = "margin-bottom: 20px;">
    <span *ngIf = "!actualMaintenanceStatus.isActive">
        A karbantartás mód jelenleg <span style = "color: red">nem aktív</span> a szerveren.
    </span>
    <span *ngIf = "actualMaintenanceStatus.isActive">
        A karbantartás mód jelenleg <span style = "color: darkgreen">aktív</span> a szerveren.
    </span>
</div>
<div [formGroup] = "maintenanceFG" style = "width: 500px;">
    <div style = "margin-bottom: 20px;">
        <mat-slide-toggle formControlName = "isActiveFC"> Karbantartás aktív </mat-slide-toggle>
    </div>
    <div style = "margin-bottom: 20px;">
         <mat-form-field appearance='outline' style = "width: 100%;">
            <mat-label>Üzenet</mat-label>
            <textarea matInput [formControl] = "messageFC" style = "resize: none; height: 8em;"> </textarea>
            <mat-error>Kötelező mező!</mat-error>
        </mat-form-field>
    </div>
    <div>
        <button mat-flat-button color = "primary" [disabled] = "maintenanceFG.invalid" (click) = "setMaintenanceStatus()">
            Módosít
        </button>
    </div>
</div>

