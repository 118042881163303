import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EChartsOption } from 'echarts';
import { Subscription } from 'rxjs';
import { ChartDataPoint, LineChartData } from 'src/app/classes/charts';
import { Category } from 'src/app/classes/model/category';
import { DisplayedStatistics } from 'src/app/classes/model/displayed-statistics';
import { matchingPropertyPredicate } from 'src/app/functions/misc';
import { CategoryService } from 'src/app/services/category.service';
import { StatisticsService } from 'src/app/services/statistics.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit, OnDestroy {
  displayedStatistics:DisplayedStatistics;
  categoryUuids:ReadonlyArray<string>;

  registrationsChartOptions:EChartsOption;
  pruchasesChartOptions:EChartsOption;
  usageChartOptions:EChartsOption;
  platformRegistrationsChartOptions:EChartsOption;
  devicesChartOptions:EChartsOption;

  timeIntervalSelectItems:ReadonlyArray<TimeIntervalSelectItem> = [
    { displayedName: "Utolsó egy hét",    lastNDays: 7 },
    { displayedName: "Utolsó egy hónap",  lastNDays: 30 },
    { displayedName: "Utolsó egy év",     lastNDays: 365 },
    { displayedName: "Összes",            lastNDays: 99999 }
  ];

  timeIntervalSelectFC:FormControl;
  timeIntervalSelectValueChanges:Subscription;

  constructor(
    private statisticsService:StatisticsService,
    private categoryService:CategoryService,
    public elementRef:ElementRef<HTMLElement>,
    private changeDetectorRef:ChangeDetectorRef
  ) {}

  public ngOnInit():void {
    this.timeIntervalSelectFC = new FormControl(this.timeIntervalSelectItems[0].lastNDays);
    this.timeIntervalSelectValueChanges = this.timeIntervalSelectFC.valueChanges.subscribe(
      (newValue:number) => {
        let s = this.displayedStatistics;
        this.updateCharts();
        this.displayedStatistics = null;
        setTimeout(() => { this.displayedStatistics = s; }, 0);
      }
    )

    this.displayedStatistics = this.statisticsService.getDisplayedStatisticsRef();
    this.initCharts();
    this.calulateStatistis(false);
  }

  public ngOnDestroy():void {
    this.timeIntervalSelectValueChanges.unsubscribe();
  }

  public async calulateStatistis(recalculate:boolean):Promise<void> {
    // TODO: what to be displayed on error
    
    try {
      this.displayedStatistics = await this.statisticsService.calculateStatistics(recalculate);
      this.categoryUuids = this.statisticsService.getStatisticsRef().categoryUuids;
      this.updateCharts();

    } catch(error:any) {
      console.log(error);
    }
  }

  public getCategoryName(categoryUuid:string):string {
    return this.categoryService.getCategoriesArrayRef().find(matchingPropertyPredicate("uuid", categoryUuid))?.name;
  }

  public async handleReloadButtonClick():Promise<void> {
    this.displayedStatistics = null;
    await this.calulateStatistis(true);
  }


  private updateCharts():void {
    // Platform registrations
    this.platformRegistrationsChartOptions.series[0].data = this.displayedStatistics.registrations.platforms;

    // Registrations
    this.registrationsChartOptions.xAxis = {
      data: this.getChartDataPointsInInterval(this.displayedStatistics.registrations.daily[0].series).map(s => s.name)
    };
    this.registrationsChartOptions.series = [
      {
        type: 'line',
        data: this.getChartDataPointsInInterval(this.displayedStatistics.registrations.daily[0].series).map(s => s.value),
        showAllSymbol: true,
      }
    ];

    // Purchases
  
    this.getChartDataPointsInInterval(this.displayedStatistics.purchases.daily[0].series).map(s => s.name)
    this.pruchasesChartOptions.xAxis = {
      data: this.getChartDataPointsInInterval(this.displayedStatistics.purchases.daily[0].series).map(s => s.name)
    };

    this.pruchasesChartOptions.series = this.displayedStatistics.purchases.daily.map(
      (lineChartData:LineChartData) => {
        return {
          name: this.displayedStatistics.packageDefinitions.find(packageDefinition => packageDefinition.uuid === lineChartData.name)?.displayedName ?? "Ismeretlen csomag",
          type: 'line',
          stack: 'Total',
          areaStyle: {},
          emphasis: {
            focus: 'series'
          },
          data: this.getChartDataPointsInInterval(lineChartData.series)
        }
      }
    );

    // Usage
    this.usageChartOptions.xAxis = {
      data: this.getChartDataPointsInInterval(this.displayedStatistics.usage.daily[0].series).map(s => s.name)
    };
    this.usageChartOptions.series = [
      {
        type: 'line',
        data: this.getChartDataPointsInInterval(this.displayedStatistics.usage.daily[0].series).map(s => s.value),
        showAllSymbol: true,
      }
    ];

    // Devices
    this.devicesChartOptions.series[0].data = this.displayedStatistics.devices;
  }

  private initCharts():void {
    this.platformRegistrationsChartOptions = {
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          name: 'Platformok',
          type: 'pie',
          radius: [30, 60],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          }
        }
      ],
      grid: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }
    };

    this.registrationsChartOptions = {
      title: {
        text: 'Regisztrációk'
      },
      tooltip: {},
      yAxis: {},
      grid: {
        left: 0,
        right: "3%",
        top: "20%",
        bottom: "20%"
      }
    };

    this.pruchasesChartOptions = {
      title: {
        text: 'Vásárlások'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      yAxis: {},
      grid: {
        left: "3%",
        right: "3%",
        top: "20%",
        bottom: "20%"
      }
    };

    this.usageChartOptions = {
      title: {
        text: 'Napi használat'
      },
      tooltip: {},
      yAxis: {},
      grid: {
        left: "3%",
        right: "3%",
        top: "20%",
        bottom: "20%"
      }
    };

    this.devicesChartOptions = {
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          name: 'Készülékek',
          type: 'pie',
          radius: [30, 60],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          }
        }
      ],
      grid: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }
    };
  }

  private getChartDataPointsInInterval(points:Array<ChartDataPoint>):Array<ChartDataPoint> {
    return points.slice(Math.max(0, points.length - this.timeIntervalSelectFC.value), points.length);
  }

}

type TimeIntervalSelectItem = {
  displayedName:string;
  lastNDays:number;
}