import { Injectable } from '@angular/core';
import { CustomEnvironmentType, customEnvironmentTypeValue, Environment } from './environment';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class CustomEnvironmentService {

  private readonly customEnvironmentDefaultValues:Omit<Environment, "serverHost"> = {
    type: customEnvironmentTypeValue,
    displayedName: "Egyedi környezet",
    color: "#8a680a"
  }

  constructor(
    private environmentService:EnvironmentService
  ) { }

  /**
   * Sets a custom environment. The custom environment only needs a custom server host address.
   * 
   * @param serverHost the address of the server in the custom environment
   */
  public setCustomEnvironment(serverHost:string):void {
    const environment:Environment = {
      ...this.customEnvironmentDefaultValues,
      serverHost: serverHost
    }

    this.environmentService.setEnvironment(environment);
  }

  /**
   * Returns the custom environment type's name's value.
   */
  public getCustomEnvironmentTypeValue():CustomEnvironmentType {
    return customEnvironmentTypeValue;
  }

}
