import { PracticeCityZone } from './../../classes/model/practice-city-zone';
export class PreDefinedColors {
  readonly colors = [
    "#BB271A",
    "#839706",
    "#EEC13B",
    "#4273AD",
    "#F387BE",
    "#FEA319",
    "#54808C",
    "#634FA2",
    "#EA3323",
    "#B8D407",
    "#BAB4B4",
    "#9B5277",
    "#83BBFF",
    "#902C15",
    "#74EE48",
    "#75FBFD",
    "#9877FF",
    "#FF6D4E",
    "#F2EA19",
    "#039B09",
    "#DE309E",
    "#081CE9",
    "#D28635",
    "#39CAA7",
  ];

  getNextFreeColor(currentZones: PracticeCityZone[]): string {
    const usedColors = currentZones.map((zone) => zone.polygonColor.toUpperCase());
    const freeColors = this.colors.filter(
      (color) => !usedColors.includes(color)
    );

    if (freeColors.length == 0) {
      // pick random color
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    }

    return freeColors[0];
  }
}
