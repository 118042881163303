import { Injectable } from '@angular/core';
import { BackendService } from "./common/backend.service";
import { HttpErrorHandlerService } from "./common/http-error-handler.service";
import { UploadAccessToS3 } from './common/aws-s3.service';

@Injectable({
  providedIn: 'root'
})
export class VideoComposerService {
  private readonly videoComposerAccessRequestApiUrlFragment:string = "/api/admin/video_composer/access_request";

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) { }

  public async getVideoUploadAccess():Promise<UploadAccessToS3> {
    let result:UploadAccessToS3;

    try {
      result = await this.backendService.callApi(this.videoComposerAccessRequestApiUrlFragment, "POST");
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a tárhelyengedély kérése közben.");
    }

    return result!;
  }
}
