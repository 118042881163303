import { Injectable } from "@angular/core";
import { EntityType } from "src/app/modules/comments-on-entity/models/entity-types";
import { BackendService } from "./common/backend.service";
import { HttpErrorHandlerService } from "./common/http-error-handler.service";
import { EntityComment } from "src/app/modules/comments-on-entity/models/entity-comment";

@Injectable({
  providedIn: 'root'
})
export class CommentsOnEntityService {
  private readonly commentsOnEntityBaseApiUrlFragment:string = "/api/admin/comments_on_entity";

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) { }

  /**
   * Fetches the comments of the specified entity.
   * 
   * @param entityType the type of the entity to add comment to
   * @param entityKeyValue the key of the entity to add comment to
   */
  public async fetchCommentsOfEntity(entityType:EntityType, entityKeyValue:string):Promise<Array<EntityComment>> {
    const apiUrlFragment:string = `${this.commentsOnEntityBaseApiUrlFragment}/${entityType}/${entityKeyValue}`;
    let result:Array<EntityComment> = [];

    try {
      const response:{ comments:Array<EntityComment> } = await this.backendService.callApi(
        apiUrlFragment,
        "GET"
      );

      result = response.comments;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az entitás megjegyzések lekérdezése közben.");
    }

    return result;
  }

  /**
   * Adds a comment to the specified entity.
   * 
   * @param entityType the type of the entity to add comment to
   * @param entityKeyValue the key of the entity to add comment to
   * @param message the the message body of the comment
   */
  public async addCommentToEntity(entityType:EntityType, entityKeyValue:string, message:string):Promise<void> {
    const apiUrlFragment:string = `${this.commentsOnEntityBaseApiUrlFragment}/${entityType}/${entityKeyValue}/comments`;
    const httpBody:Object = { message: message };

    try {
      await this.backendService.callApi(
        apiUrlFragment,
        "PUT",
        httpBody
      );

    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az megjegyzés hozzáadása közben.");
    }
  }

  /**
   * Makes an API call to change a given comment's visibility. The comment list is determined by the @entityType and the
   * @entityKeyValue.
   * 
   * @param entityType the type of the entity 
   * @param entityKeyValue the key value of the entity
   * @param commentUuid the uuid of the comment
   * @param isVisible the new visibility value
   */
  public async toogleCommentVisibility(
    entityType:EntityType,
    entityKeyValue:string,
    commentUuid:string,
    isVisible:boolean
  ):Promise<void> {
    const apiUrlFragment:string =
      `${this.commentsOnEntityBaseApiUrlFragment}/${entityType}/${entityKeyValue}/comments/${commentUuid}/change_visibility`;
    const httpBody:Object = { isVisible: isVisible };

    try {
      await this.backendService.callApi(
        apiUrlFragment,
        "POST",
        httpBody
      );
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a megjegyzés módosítása közben.");
    }
  }

  /**
   * Fetches the number of hte comments of a target entity.
   * 
   * @param entityType the type of the entity
   * @param entityKeyValue the value of the key of the entity
   * 
   * @returns the number of the comments on the target entity
   */
  public async getNumberOfCommentsOnEntity(
    entityType:EntityType,
    entityKeyValue:string,
  ):Promise<number> {
    const apiUrlFragment:string = `${this.commentsOnEntityBaseApiUrlFragment}/${entityType}/${entityKeyValue}/comments/count`;
    let result:number;

    try {
      const response:{ countOfComments:number } = await this.backendService.callApi(
        apiUrlFragment,
        "GET"
      );

      result = response.countOfComments;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az entitás megjegyzések számának lekérdezése közben.");
    }

    return result;
  }
}
