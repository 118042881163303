<circle-progress
    [percent] = "(data.progressSubject | async) * 100"
    [radius] = "80"
    [animation] = "false"
    style = "margin: 0 auto;"
></circle-progress>
<div style = "text-align: center; margin-top: 30px; font-size: 0.9rem; font-weight: 500;">
    {{ data.textSubject | async }}
</div>

