import { Component, Input, OnInit } from '@angular/core';
import { Instructor } from 'src/app/classes/model/instructor';
import { getFullName } from 'src/app/functions/misc';

@Component({
  selector: 'app-instructor-card',
  templateUrl: './instructor-card.component.html',
  styleUrls: ['./instructor-card.component.scss']
})
export class InstructorCardComponent implements OnInit {
  @Input() instructor:Instructor;

  fullname:string;

  constructor() { }

  ngOnInit(): void {
    this.fullname = getFullName(this.instructor.lastName, this.instructor.firstName);
  }

}
