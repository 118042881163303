import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntityType } from '../../models/entity-types';

export type CommentsOnEntityDialogInput = {
  entityType:EntityType;
  entityKeyValue:string;
}

@Component({
  selector: 'app-comments-on-entity-dialog',
  templateUrl: './comments-on-entity-dialog.component.html',
  styleUrls: ['./comments-on-entity-dialog.component.scss']
})
export class CommentsOnEntityDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data:CommentsOnEntityDialogInput
  ) { }

  ngOnInit(): void {}



}
