<section>
    <app-page-header
        title = "Kérdésmódosítások"
        subtitle = "A Mr. Kresz elméleti modulban történt módosításokat találod itt. "
        style = "margin-bottom: 20px;"
    >
        <button mat-mini-fab color = "primary" (click) = "handleReloadButtonClick()">
            <mat-icon> refresh </mat-icon>
        </button>
    </app-page-header>
    <div class = "changelog-table-container mat-elevation-z8">
        <mat-paginator [pageSizeOptions] = "[10, 25, 50]" [pageSize] = "10" showFirstLastButtons></mat-paginator>
        <table style = "width: 100%;" mat-table [dataSource] = "dataSource">
    
            <ng-container matColumnDef = "date">
                <th mat-header-cell *matHeaderCellDef> Dátum </th>
                <td mat-cell *matCellDef = "let change"> {{ change.timestamp | date: "YYYY. MM. dd. HH:mm:ss" }} </td>
            </ng-container>
    
            <ng-container matColumnDef = "revision">
                <th mat-header-cell *matHeaderCellDef> Revíziószám </th>
                <td mat-cell *matCellDef = "let change"> {{ change.rev }} </td>
            </ng-container>
    
            <ng-container matColumnDef = "question-uuid">
                <th mat-header-cell *matHeaderCellDef> Kérdésazonosító </th>
                <td mat-cell *matCellDef = "let change">
                    <span class = "changelog-qeustion-uuid" (click) = "handleUuidClick(change)">
                        {{ change.type === 'add' ? change.newQuestion.uuid : change.oldQuestion.uuid }}
                    </span>
                </td>
            </ng-container>
    
            <ng-container matColumnDef = "operation">
                <th mat-header-cell *matHeaderCellDef> Művelet </th>
                <td mat-cell *matCellDef="let change"> {{ getDisplayedOperationName(change) }} </td>
            </ng-container>
    
            <ng-container matColumnDef = "user">
                <th mat-header-cell *matHeaderCellDef> Felhasználó </th>
                <td mat-cell *matCellDef = "let change"> {{ change.who }} </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef = "displayedColumns"></tr>
            <tr mat-row *matRowDef = "let row; columns: displayedColumns;"></tr>
        </table>
        <div *ngIf = "isContentLoading" id = "loading-container">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</section>


