import { ElementRef, Injectable, QueryList } from '@angular/core';
import { PracticePathCardComponent } from '../practice-path-card/practice-path-card.component';
import { PracticePathSorter } from '../practice-path-sorting-selector/practice-path-sortings';
import { PracticePath, PracticePathBaseInformations } from "src/app/classes/model/practice-path";
import { matchingPropertyPredicate } from "src/app/functions/misc";

@Injectable()
export class PracticePathsPageScrollPositionService {
    private savedTopPracticePathProperties:Partial<PracticePath>|null = null;
    private readonly savedTopPracticePathPropertiesKey:string = "saved-top-practice-path-properties-for-scrolling";
    private readonly savedScrollPositionKey:string = "saved-practice-paths-scroll-position";
    private readonly savedPracticePathUuidsKey:string = "saved-practice-path-uuids";

    constructor() {
      try {
        this.savedTopPracticePathProperties = JSON.parse(sessionStorage.getItem(this.savedTopPracticePathPropertiesKey));
      } catch(error:any) {}
    }

    /**
     * Saves the scroll position informations. This includes the actual scroll position and the top visible
     * element's relevant properties. It's saves an array of practice path uuids also to check if the array is
     * changed before the scroll position restoring step.
     * 
     * @param actualScrollPosition the actual position of the scrollbar
     * @param savedPracticePaths the saved practice paths
     * @returns 
     */
    public saveScrollPosition(
      actualScrollPosition:number,
      practicePathBaseInformations:Array<PracticePathBaseInformations>,
      practicePathCardComponentQueryList:QueryList<PracticePathCardComponent>
    ):void {
      // Save the exact position
      sessionStorage.setItem(
        this.savedScrollPositionKey,
        actualScrollPosition.toString()
      );
  
      // Save the provided practice path uuids
      sessionStorage.setItem(
        this.savedPracticePathUuidsKey,
        JSON.stringify(practicePathBaseInformations.map(path => path.uuid))
      );
  
      // Save the nearest card for the approximate position
      // Get the nearest next component
      const nearestPracticePathCardComponentToActualScrollPosition:PracticePathCardComponent|null =
        this.getNearestPracticePathCardComponentToActualScrollPosition(
          practicePathCardComponentQueryList,
          actualScrollPosition
      );
  
      // If none found, nothing to do
      if(nearestPracticePathCardComponentToActualScrollPosition === null) {
        return;
      }
  
      // Otherwise save the relevant information of that component
      const practicePath:PracticePathBaseInformations = nearestPracticePathCardComponentToActualScrollPosition.practicePath;
      const savedPracticePathFields:Partial<PracticePath> = {
        uuid: practicePath.uuid,
        name: practicePath.name,
        created: practicePath.created,
        examPathNumber: practicePath.examPathNumber
      }
  
      sessionStorage.setItem(
        this.savedTopPracticePathPropertiesKey,
        JSON.stringify(savedPracticePathFields)
      );
    }

    /**
     * Restores the saved scroll position. If the data changed after the last save, it tries to restore it
     * based on the change and the actual values.
     * 
     * @param scrolledElement the scrolled element
     * @param practicePathCardComponentQueryList the query list of the actual practice path components
     * @param sortingFunction the actual sorting function
     */
    public restoreScrollPosition(
      scrolledElement:HTMLElement,
      practicePathCardComponentQueryList:QueryList<PracticePathCardComponent>,
      sortingFunction:PracticePathSorter
    ):void {
      // Get the saved and the actual practice path uuids
      const savedPathUuids:Array<string> = JSON.parse(sessionStorage.getItem(this.savedPracticePathUuidsKey) ?? "[]");
      const actualDisplayedPathUuids:Array<string> = practicePathCardComponentQueryList.map(path => path.practicePath.uuid);

      // Compare the the arrays
      let isDisplayedArrayChanged:boolean = false;
      if(actualDisplayedPathUuids.length !== savedPathUuids.length) {
        isDisplayedArrayChanged = true;
      } else {
        for(let index:number = 0; index < actualDisplayedPathUuids.length; ++index) {
          if(actualDisplayedPathUuids[index] !== savedPathUuids[index]) {
            isDisplayedArrayChanged = true;
            break;
          }
        }
      }

      // If the arrays are differ
      if(isDisplayedArrayChanged) {
        // Restore the scroll position by the best guess
        this.restoreScrollPositionApproximately(scrolledElement, practicePathCardComponentQueryList, sortingFunction);
      } else {
        // Otherwise restore the scroll position to the saved exact value
        this.restoreScrollPositionExactly(scrolledElement);
      }
    }

    /**
     * Gets the nearest next practice path card component for a given scrolling position. If none found `null`
     * is returned.
     * 
     * @returns nearest next practice path card component
     */
    private getNearestPracticePathCardComponentToActualScrollPosition(
      practicePathCardComponentQueryList:QueryList<PracticePathCardComponent>,
      actualScrollPosition:number
    ):PracticePathCardComponent|null {
      // Check if the query list is exist and has elements
      if(practicePathCardComponentQueryList == undefined || practicePathCardComponentQueryList.length === 0) {
        return null;
      }

      // Initialize the search
      let minimalOffsetTop:number = Infinity;
      let nearestPracticePathCardComponent:PracticePathCardComponent|null = null;
      // Search for the nearest next practice path card component
      for(const practicePathCardComponent of practicePathCardComponentQueryList) {
        // The actual elements offset value
        // It's negative if the top of the element is over of the top of it's container
        const actualOffsetTop:number =
          practicePathCardComponent.elementRef.nativeElement.offsetTop - actualScrollPosition;

        // If the actual element is a better solution
        if(actualOffsetTop >= 0 && actualOffsetTop < minimalOffsetTop) {
          // ... save it
          nearestPracticePathCardComponent = practicePathCardComponent;
          minimalOffsetTop = actualOffsetTop;
        }
      }
  
      // Return the found element
      return nearestPracticePathCardComponent;
    }

    /**
     * Restores the scrolling position approximately. If the saved item is found, it will be on the top of the list,
     * if not, it searches for the next similar item.
     * 
     * @param scrolledElement the scrolled element
     * @param practicePathCardComponentQueryList the query list of the actual practice path components
     * @param sortingFunction the actual sorting function
     */
    private restoreScrollPositionApproximately(
      scrolledElement:HTMLElement,
      practicePathCardComponentQueryList:QueryList<PracticePathCardComponent>,
      sortingFunction:PracticePathSorter
    ):void {
      const nearestElementToSavedScrollPosition:ElementRef<HTMLElement>|null = this.getNearestPracticePathCardElementToSavedScrollPosition(
        practicePathCardComponentQueryList,
        sortingFunction
      );
      const targetPosition:number = nearestElementToSavedScrollPosition?.nativeElement.offsetTop ?? 0;
      scrolledElement.scroll({ top: targetPosition });
    }

    /**
     * Restores the scrolling position exactly to the saved point.
     * 
     * @param scrolledElement the scrolled element
     */
    private restoreScrollPositionExactly(
      scrolledElement:HTMLElement,
    ):void {
      const targetPosition:number = +sessionStorage.getItem(this.savedScrollPositionKey) ?? 0;
      scrolledElement.scroll({ top: targetPosition });
    }

    /**
     * Returns the nearest next practice path card component's element ref based on the saved one. If no
     * exact match found, it searches for the nearest next item.
     * 
     * @param practicePathCardComponentQueryList the query list of the actual practice path components
     * @param sortingFunction the actual sorting function
     * @returns 
     */
    private getNearestPracticePathCardElementToSavedScrollPosition(
      practicePathCardComponentQueryList:QueryList<PracticePathCardComponent>,
      sortingFunction:PracticePathSorter
    ):ElementRef<HTMLElement>|null {
      // Check if we can search for the item
      if(this.savedTopPracticePathProperties === null || practicePathCardComponentQueryList.length === 0) {
        // If not, null returned
        return null;
      }
  
      // We search for the saved practice path's card
      let practiceCardComponent:PracticePathCardComponent|undefined = practicePathCardComponentQueryList.find(
        (practicePathCardComponent:PracticePathCardComponent) => {
          return practicePathCardComponent.practicePath.uuid === this.savedTopPracticePathProperties.uuid;
        }
      );
  
      // If the saved one found
      if(practiceCardComponent) {
        // ... return it's ElementRef
        return practiceCardComponent.elementRef;
      }
  
      // If not we search for the closes one in the actual sorting
      // We determine where the actual element would be
      const array:Array<Partial<PracticePath>> = practicePathCardComponentQueryList.map(component => component.practicePath);
      array.push(this.savedTopPracticePathProperties);
      array.sort(sortingFunction);
      const index:number = array.findIndex(matchingPropertyPredicate("uuid", this.savedTopPracticePathProperties.uuid));
  
      // And if there is an element before that
      if(index > 0) {
        // ... return that element
        return practicePathCardComponentQueryList.get(index - 1)!.elementRef;
      } else {
        // ... otherwise return the element after that
        return practicePathCardComponentQueryList.get(index)!.elementRef;
      }
    }
}