import { Injectable } from '@angular/core';
import { Question } from '../../../../../../classes/model/question';

export enum FilterByPresence {
  All, Present, NotPresent
}

@Injectable()
export class QuestionFilterService {
  public static readonly UuidFilterWildcard:string = "*";
  public static readonly UuidFilterEmptyField:string = "-";
  public static readonly PositionFilterWildCard:number = -1;

  public numberOfFilteredQuestions:number;
  
  public filteredCategoryUuid:string;
  public filteredCategoryPosition:number;
  public filteredQuestionGroupUuid:string;
  public filteredQuestionText:string;
  public filterByExplanationPresence:FilterByPresence;
  public filterByMediaPresence:FilterByPresence;
  public filterByIllustrationPresence:FilterByPresence;
  public filterByIsUpdated:FilterByPresence;
  
  private questionsArrayRef:ReadonlyArray<Question>;
  private displayedQuestionIndexes:Array<number>;
  private filteredStartIndex:number;
  private filteredEndIndex:number;

  constructor() {
    this.displayedQuestionIndexes = Array<number>();
    this.numberOfFilteredQuestions = 0;
    this.initFilters();
  }

  initFilters():void {
    this.filteredCategoryUuid = QuestionFilterService.UuidFilterWildcard;
    this.filteredCategoryPosition = -1;
    this.filteredQuestionGroupUuid = QuestionFilterService.UuidFilterWildcard;
    this.filteredQuestionText = "";
    this.filterByExplanationPresence = FilterByPresence.All;
    this.filterByMediaPresence = FilterByPresence.All;
    this.filterByIllustrationPresence = FilterByPresence.All;
    this.filterByIsUpdated = FilterByPresence.All;
    this.setFilteredStartAndEndIndex(0, Infinity);
  }

  setQuestionsArrayRef(questionsArrayRef:ReadonlyArray<Question>):void {
    this.questionsArrayRef = questionsArrayRef;
  }

  updateDisplayedQuestions():void {
    this.resetDisplayedQuestionIndexesArray();

    if(this.filteredQuestionText.length === 36) {
      const index:number = this.questionsArrayRef.findIndex((element:Question) => { return element.uuid === this.filteredQuestionText });
      if(index !== -1) {
        this.displayedQuestionIndexes.push(index);
        this.numberOfFilteredQuestions = 1;
        this.filteredQuestionText = "";
      }
      return;
    }

    let displayedIndex:number = 0;
    for(let index = 0; index < this.questionsArrayRef.length; ++index) {

      // Filter by category and position
      if(this.filteredCategoryUuid !== QuestionFilterService.UuidFilterWildcard) {
        if(this.filteredCategoryUuid === QuestionFilterService.UuidFilterEmptyField && this.questionsArrayRef[index].categories.length > 0) {
          continue;
        }

        if(this.filteredCategoryUuid !== QuestionFilterService.UuidFilterEmptyField) {
          const questionCategoryIndex:number = this.questionsArrayRef[index].categories.findIndex(
            element => { return element.categoryUuid === this.filteredCategoryUuid }
          );
          if(questionCategoryIndex === -1) {
            continue;
          } else if(this.filteredCategoryPosition !== QuestionFilterService.PositionFilterWildCard &&
            !this.questionsArrayRef[index].categories[questionCategoryIndex].positions.includes(this.filteredCategoryPosition)) {
            continue;
          }
        }
      }

      // Filter by question group
      if(this.filteredQuestionGroupUuid !== QuestionFilterService.UuidFilterWildcard) {
        if(this.filteredQuestionGroupUuid === QuestionFilterService.UuidFilterEmptyField && this.questionsArrayRef[index].questionGroups.length > 0) {
          continue;
        }
        if(this.filteredQuestionGroupUuid !== QuestionFilterService.UuidFilterEmptyField &&
          !this.questionsArrayRef[index].questionGroups.includes(this.filteredQuestionGroupUuid)) {
          continue;
        }
      }

      // Filter by explanation
      if((this.filterByExplanationPresence === FilterByPresence.Present && this.questionsArrayRef[index].explanation === "") ||
        (this.filterByExplanationPresence === FilterByPresence.NotPresent && this.questionsArrayRef[index].explanation !== "")) {
        continue;
      }

      // Filter by media
      if((this.filterByMediaPresence === FilterByPresence.Present && this.questionsArrayRef[index].mediaUrl === "") ||
        (this.filterByMediaPresence === FilterByPresence.NotPresent && this.questionsArrayRef[index].mediaUrl !== "")) {
        continue;
      }

      // Filter by illustration
      if((this.filterByIllustrationPresence === FilterByPresence.Present && !this.questionsArrayRef[index].hasIllustration) ||
        (this.filterByIllustrationPresence === FilterByPresence.NotPresent && this.questionsArrayRef[index].hasIllustration)) {
        continue;
      }

      // Filter by isUpdated
      if((this.filterByIsUpdated === FilterByPresence.Present && this.questionsArrayRef[index].isUpdated === false) ||
        (this.filterByIsUpdated === FilterByPresence.NotPresent && this.questionsArrayRef[index].isUpdated === true)) {
        continue;
      }

      // Filter by question text
      if(this.filteredQuestionText !== "") {
        if(!this.questionsArrayRef[index].text.toLocaleUpperCase().includes(this.filteredQuestionText.toLocaleUpperCase())) {
          if(this.questionsArrayRef[index].metadata.id === undefined ||
            !this.questionsArrayRef[index].metadata.id.toString().includes(this.filteredQuestionText)) {
            continue;
          }
        }
      }

      // Filter by position
      if(this.filteredStartIndex <= displayedIndex && displayedIndex < this.filteredEndIndex) {
        this.displayedQuestionIndexes.push(index);
      }

      ++displayedIndex;
    }


    this.numberOfFilteredQuestions = displayedIndex;
  }

  getDisplayedQuestionIndexes():ReadonlyArray<number> {
    return this.displayedQuestionIndexes;
  }

  resetDisplayedQuestionIndexesArray():void {
    this.displayedQuestionIndexes.length = 0;
    this.numberOfFilteredQuestions = 0;
  }

  isValidCategoryFiltered():boolean {
    return this.filteredCategoryUuid !== QuestionFilterService.UuidFilterWildcard && this.filteredCategoryUuid !== QuestionFilterService.UuidFilterEmptyField;
  }

  setFilteredStartAndEndIndex(filteredStartIndex:number, filteredEndIndex:number):void {
    this.filteredStartIndex = filteredStartIndex;
    this.filteredEndIndex = filteredEndIndex;
  }

  public clearCachedData():void {
    this.displayedQuestionIndexes.length = 0;
    this.numberOfFilteredQuestions = 0;
  }

}
