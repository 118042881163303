<div class="main-tab-container">
  <app-icon-flow
    [iconUrls]="this.practiceIconUrls"
    [selectedIconIndexes]="this.selectedIconIndexes"
    (onTapIcon)="this.onTapIcon($event)"
  >
  </app-icon-flow>

  <app-icon-recorder
    style="display: block; margin-top: 30px; margin-bottom: 20px"
    [iconRecorderService]="this.iconRecorderService"
    (onTapTime)="
      this.globalEditorService.mainVideoPlayerController.seekTo($event)
    "
  ></app-icon-recorder>

  Aktív időzített ikonok ({{ this.getCountOfActiveIcons() }})
  <div #timedIconList class="timed-icon-card-list-container">
    <div
      *ngFor="let timedIcon of this.globalEditorService.timedIconAssignments$"
    >
      <app-timed-icon-card
        [isActive]="this.globalEditorService.isVisibleTimedIcon(timedIcon)"
        (onTapTime)="this.onTapTime($event)"
        (onTapDelete)="this.onDeleteTimedIcon(timedIcon.uuid)"
        [timedIcon]="timedIcon"
      ></app-timed-icon-card>
    </div>
    <div
      *ngIf = "globalEditorService.timedIconAssignments$.length === 0"
      class = "no-timed-icons"
    >
      Jelenleg nincs felvéve egyetlen időzített ikon sem.
      <span *ngIf = "selectedIconIndexes.length === 0">
        Kattints a fent található ikon listára, hogy elindítsd az időzített ikon felvéltelt.
      </span>
    </div>
  </div>
</div>
