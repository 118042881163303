import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { AsyncValidatorFn, FormControl, ValidatorFn } from '@angular/forms';
import { CustomValidators } from 'src/app/classes/custom-validators';

@Component({
  selector: 'app-string-list-input',
  templateUrl: './string-list-input.component.html',
  styleUrls: ['./string-list-input.component.scss']
})
export class StringListInputComponent implements OnInit {
  @Input() control:FormControl;
  @Input() validators:Array<ValidatorFn> = [];
  @Input() asyncValidators:Array<AsyncValidatorFn> = [];
  @Input() nameSuffix:string = "elem";

  sortablejsOptions = {
    onUpdate: (event:any) => {
      this.control.setValue(
        this.subFormControls.map(
          (subFormControl:FormControl) => {
            return subFormControl.value;
          }
        )
      );
    }
  }

  subFormControls:Array<FormControl>;

  constructor() { }

  ngOnInit(): void {
    if(!Array.isArray(this.control.value)) {
      this.control.setValue([]);
    }

    this.subFormControls = new Array<FormControl>();

    this.control.value.forEach(
      (value:any) => {
        this.createSubFormControl(value);
      }
    );

    if(!this.control.hasValidator(CustomValidators.childFormControlsValid(this.subFormControls))) {
      this.control.addValidators(CustomValidators.childFormControlsValid(this.subFormControls));
    }
  }

  public removeElement(index:number):void {
    if(index < 0 || index >= this.subFormControls.length) {
      return;
    }

    this.subFormControls.splice(index, 1);
    this.control.setValue([ ...this.control.value.slice(0, index), ...this.control.value.slice(index + 1) ]);
  }

  public addElement(defaultValue?:string):FormControl {
    const subFormControl:FormControl = this.createSubFormControl(defaultValue);
    this.control.setValue([ ...this.control.value, subFormControl.value ]);
    return subFormControl;
  }

  private createSubFormControl(defaultValue?:string):FormControl {
    const subFormControl: FormControl = new FormControl(defaultValue, this.validators, this.asyncValidators);

    subFormControl.valueChanges.subscribe(
      (newValue: string) => {
        const index:number = this.subFormControls.findIndex(formControl => formControl === subFormControl);
        this.control.setValue([ ...this.control.value.slice(0, index), newValue, ...this.control.value.slice(index + 1) ]);
      }
    )

    this.subFormControls.push(subFormControl);
    return subFormControl;
  }

  public onDragEnd(event: any){
    moveItemInArray(this.subFormControls, event.previousIndex, event.currentIndex);
  }

}
