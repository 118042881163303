<app-page-header
  title = "Útvonal ellenőrző"
></app-page-header>
<div style="display: flex; flex-direction: column; width: 100%; height: 100%">
  <ngx-dropzone (change)="droppedMetadataFile($event)">
    <ngx-dropzone-label>Húzd ide a JSON metadata-t!</ngx-dropzone-label>
  </ngx-dropzone>

  <ng-template [ngIf]="currentLoadedMetadata != null">
    <app-metadata-table
      [currentLoadedMetadata]="currentLoadedMetadata"
      style="margin-top: 15px"
    >
    </app-metadata-table>

    <app-practice-path-points-map-viewer
      style="flex-grow:1;display:block;width:100%;margin-top:15px;"
      [geoTaggedFrames]="this.currentLoadedMetadata.geoTaggedFrames"
    >
    </app-practice-path-points-map-viewer>
  </ng-template>
</div>

