import { Component, Input, OnInit } from '@angular/core';
import { PracticePath, PracticePathBaseInformations } from 'src/app/classes/model/practice-path';

@Component({
  selector: 'app-narrations-during-recording-list-tab',
  templateUrl: './narrations-during-recording-list-tab.component.html',
  styleUrls: ['./narrations-during-recording-list-tab.component.scss']
})
export class NarrationsDuringRecordingListTabComponent implements OnInit {
  @Input() practicePath:PracticePath|PracticePathBaseInformations;

  constructor() { }

  ngOnInit(): void { }

}
