import { NotifierService } from 'src/app/services/common/notifier-service.service';
import { Injectable } from "@angular/core";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: "root",
})
export class HttpErrorHandlerService {
  constructor(
    private authenticationService: AuthenticationService,
    private notifierService: NotifierService
  ) {}

  public handleError(httpRequestError: any, defaultSnackBarMessage: string) {
    if (httpRequestError?.error?.error) {
      switch (httpRequestError.error.error) {
        case ApiError.InvalidSessionError.name:
          this.notifierService.notify(
            "error",
            ApiError.InvalidSessionError.message
          );
          this.authenticationService.logout();
          break;
        case ApiError.RequiredPermissionMissingError.name:
          this.notifierService.notify(
            "error",
            ApiError.RequiredPermissionMissingError.message
          );
          break;
        case ApiError.InsufficientCapacityError.name:
          this.notifierService.notify(
            "error",
            ApiError.InsufficientCapacityError.message
          );
          break;
        default:
          this.notifierService.notify("error", defaultSnackBarMessage);
      }
    } else {
      console.error(httpRequestError);
      this.notifierService.notify(
        "error",
        defaultSnackBarMessage ?? "Ismeretlen hiba történt"
      );
    }

    throw httpRequestError;
  }
}

class ApiError {
  public static readonly InvalidSessionError: Error = {
    name: "INVALID_SESSION",
    message: "A munkamenet lejárt. Kérjük jelentkezz be újra.",
  };

  public static readonly RequiredPermissionMissingError: Error = {
    name: "REQUIRED_PERMISSION_MISSING",
    message: "Nincs megfelelő jogosultságod a művelethez.",
  };

  public static readonly InsufficientCapacityError: Error = {
    name: "INSUFFICIENT_CAPACITY",
    message: "Nincs elegendő elérhető errőforrás.",
  };
}
