<div class = "title">
    Oktató regisztrációja
</div>

<div class = "form-item">
    <!-- E-mail address -->
        <mat-form-field appearance='outline'>
        <mat-label>E-mail cím</mat-label>
        <input matInput [formControl] = "emailFC" />
        <mat-hint> Ezt használja az oktató majd a bejelentkezéshez </mat-hint>
        <mat-error *ngIf = "emailFC.hasError('required')">Kötelező mező!</mat-error>
        <mat-error *ngIf = "!emailFC.hasError('required') && emailFC.hasError('email')">Hibás e-mail cím formátum!</mat-error>
    </mat-form-field>
</div>
<div class = "form-item">
    <!-- Last name -->
        <mat-form-field appearance='outline'>
        <mat-label>Vezetéknév</mat-label>
        <input matInput [formControl] = "lastNameFC" />
        <mat-error>Kötelező mező!</mat-error>
    </mat-form-field>
</div>
<div class = "form-item">
    <!-- First name -->
        <mat-form-field appearance='outline'>
        <mat-label>Keresztnév</mat-label>
        <input matInput [formControl] = "firstNameFC" />
        <mat-error>Kötelező mező!</mat-error>
    </mat-form-field>
</div>
<div class = "form-item">
    <!-- Phone number -->
        <mat-form-field appearance='outline'>
        <mat-label>Telefonszám</mat-label>
        <input matInput [formControl] = "phoneNumberFC" placeholder = "+36301122333" />
        <mat-error>Hibás formátum! Elvárt: +36301122333</mat-error>
    </mat-form-field>
</div>
<div class = "form-item">
    <mat-checkbox [(ngModel)] = "registrateAsVendor"> Regisztráció viszonteladóként is </mat-checkbox>
</div>

<mat-dialog-actions align = "end">
    <button mat-raised-button mat-dialog-close>
        Bezár
    </button>
    <button
        mat-raised-button
        color = "primary"
        (click) = "handleRegistrateInstructorButtonClick()"
        [disabled] = "formGroup.invalid"
    >
        Oktató regisztrálása
    </button>
</mat-dialog-actions>

