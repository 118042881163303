import { Injectable } from '@angular/core';
import { HttpResponseData } from '../classes/http-communication';
import { PracticeCity, PracticeCityBaseInformations, practiceCityBaseInformationsFields } from '../classes/model/practice-city';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class PracticeCityService {
  private readonly practiceCitiesApiUrlFragment:string = "/api/admin/practice_path_city";
  private readonly changeContentResponsibleInCityApiUrlFragment:string =
    `${this.practiceCitiesApiUrlFragment}/change_content_responsible`;
  private readonly selectPracticeCitiesApiPath: string =
  "/api/admin/practice_path_city/select";

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) {}

  /**
   * Fetches the (entire) practice cities from the server available for the requester admin user.
   * 
   * @returns the array of the practice cities
   */
  fetchPracticeCities():Promise<Array<PracticeCity>>
  fetchPracticeCities(fields:Array<keyof PracticeCity>):Promise<Array<Partial<PracticeCity>>>
  public async fetchPracticeCities(
    fields:Array<keyof PracticeCity> = []
  ):Promise<Array<PracticeCity>|Array<Partial<PracticeCity>>> {
    let apiUrlFragment:string = this.practiceCitiesApiUrlFragment;
    if(fields.length > 0) {
      apiUrlFragment += `?fields=${fields.join(",")}`;
    }

    let practiceCities:Array<Partial<PracticeCity>> = [];

    try {
      const response:HttpResponseData<Array<Partial<PracticeCity>>> = await this.backendService.callApi(apiUrlFragment, "GET");
      practiceCities = response.data;
      
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a vizsgahelyszínek lekérdezése közben.");
    }

    return practiceCities;
  }

  // practiceCityBaseInformationsFields
  fetchPracticeCity(cityUuid:string, baseInformationsOnly?:false):Promise<PracticeCity>
  fetchPracticeCity(cityUuid:string, baseInformationsOnly?:true):Promise<PracticeCityBaseInformations>
  fetchPracticeCity(cityUuid:string, baseInformationsOnly?:boolean):Promise<PracticeCity|PracticeCityBaseInformations>
  public async fetchPracticeCity(
    cityUuid:string,
    baseInformationsOnly:boolean = false
  ):Promise<PracticeCity|PracticeCityBaseInformations> {
    let apiUrlFragment:string = `${this.practiceCitiesApiUrlFragment}/${cityUuid}`;
    let practiceCity:PracticeCity|PracticeCityBaseInformations;

    if(baseInformationsOnly) {
      apiUrlFragment += `?fields=${practiceCityBaseInformationsFields.join(",")}`
    }

    try {
      const response:HttpResponseData<PracticeCity|PracticeCityBaseInformations> = await this.backendService.callApi(
        apiUrlFragment,
        "GET"
      );
      practiceCity = response.data;
      
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a vizsgahelyszínek lekérdezése közben.");
    }

    return practiceCity;
  }

  public async fetchPracticeCitySetFields(
    practiceCityUuids:Array<string>,
    fields:ReadonlyArray<keyof PracticeCity>
  ):Promise<PracticeCityQueryResult> {
    const apiPath:string = `${this.selectPracticeCitiesApiPath}?fields=${fields.join(",")}`;
    const httpBody:Object = {
      practiceCityUuids: practiceCityUuids
    }

    let result:PracticeCityQueryResult;

    try {
      const response:PracticeCityQueryResult = await this.backendService.callApi(apiPath, "POST", httpBody);
      result = response;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az útvonal vizsgahelyszín feloldása közben.");
    }

    return result;
  }

  public async addPracticeCity(newPracticeCity:Partial<PracticeCity>):Promise<void> {
    const httpBody:Object = {
      data: {
        item: newPracticeCity
      }
    };

    try {
      await this.backendService.callApi(this.practiceCitiesApiUrlFragment, "PUT", httpBody);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a vizsgahelyszín hozzáadása közben.");
    }
  }

  public async deletePracticeCity(practiceCityUuid:string):Promise<void> {
    const apiUrl:string = this.practiceCitiesApiUrlFragment + "/" + practiceCityUuid;

    try {
      await this.backendService.callApi(apiUrl, "DELETE");
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a vizsgahelyszín törlése közben.");
    }
  }

  public async modifyPracticeCity(modifiedPracticeCity:PracticeCity):Promise<void> {
    const httpBody:Object = {
      data: {
        item: modifiedPracticeCity
      }
    };

    try {
      const response:HttpResponseData<PracticeCity> = await this.backendService.callApi(this.practiceCitiesApiUrlFragment, "POST", httpBody);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a vizsgahelyszín módosítása közben.");
    }
  }

  public async updateCityResponsibleAssignment(city:PracticeCity, responsibleUuid:string, action:"ADD"|"DELETE"):Promise<void> {
    const httpBody:Object = {
      cityUuid: city.uuid,
      adminUuid: responsibleUuid,
      action: action
    };

    try {
      await this.backendService.callApi(this.changeContentResponsibleInCityApiUrlFragment, "POST", httpBody);
    } catch(error:any) {
      const errorString:string = action === "ADD" ? "Felelős hozzáadása nem sikerült." : "Felelős levétele nem sikerült";
      this.httpErrorHandlerService.handleError(error, errorString);
    }
  }

  /**
   * Determines if the admin is content responsible.
   * 
   * @param cityContentResponsibles array of the content responsibles (admin uuid-s) 
   * @param adminUuid the uuid of the admin
   * 
   * @returns true, if the admin content responsible, false otherwise
   */
  public isAdminContentResposibleOfCity(cityContentResponsibles:Array<string>, adminUuid:string):boolean {
    return cityContentResponsibles.includes(adminUuid);
  }

}

export type PracticeCityQueryResult = {
  practiceCities:Array<Partial<PracticeCity>&Pick<PracticeCity, "uuid">>;
  notFoundPracticeCityUuids:Array<string>;
}