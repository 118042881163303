<!-- Dialog header -->
<div id="quesiton-qg-assignment-dialog-title">
  Kérdéscsportok hozzárendelése
</div>

<!-- Dialog content -->
<div id="quesiton-qg-assignment-main">
  <div id="quesiton-qg-assignment-unassigned-column">
    <div class="quesiton-qg-assignment-column-title">
      Hozzárendelhető kérdéscsoportok
    </div>
    <div class="quesiton-qg-assignment-column-sortings">
      <button
        mat-raised-button
        (click)="
          sortQuestionGroups(
            QuestionGroupAssignment.NotAssigned,
            QuestionGroupSortingMethod.Aphabetical
          )
        "
      >
        <mat-icon> sort </mat-icon> ABC
      </button>
      <button
        mat-raised-button
        (click)="
          sortQuestionGroups(
            QuestionGroupAssignment.NotAssigned,
            QuestionGroupSortingMethod.Thematic
          )
        "
      >
        <mat-icon> sort </mat-icon> Tematikusan
      </button>
    </div>
    <div class="quesiton-qg-assignment-column-content">
        <mat-card
          class="quesiton-qg-assignment-card"
          *ngFor="let notAssignedQuestionGroup of notAssignedQuestionGroups.items"
        >
          <div>
            {{ notAssignedQuestionGroup.questionGroupName }}
            <div>
              <mat-chip-listbox>
                <mat-chip-option
                  *ngFor="
                    let categoryName of notAssignedQuestionGroup.categoies
                  "
                  class="quesiton-qg-assignment-category-chip"
                  [disableRipple]="true"
                  [selectable]="false"
                >
                  {{ categoryName }}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
          </div>
          <div>
            <button
              mat-mini-fab
              color="primary"
              class="quesiton-qg-assignment-button"
              (click)="
                assingQuestionGroup(
                  notAssignedQuestionGroup.questionGroupUuid
                )
              "
            >
              <mat-icon> arrow_forward </mat-icon>
            </button>
          </div>
        </mat-card>

    </div>
  </div>
  <div id="quesiton-qg-assignment-assigned-column">
    <div class="quesiton-qg-assignment-column-title">
      Hozzárendelt kérdéscsoportok
    </div>
    <div class="quesiton-qg-assignment-column-sortings">
      <button
        mat-raised-button
        (click)="
          sortQuestionGroups(
            QuestionGroupAssignment.Assigned,
            QuestionGroupSortingMethod.Aphabetical
          )
        "
      >
        <mat-icon> sort </mat-icon> ABC
      </button>
      <button
        mat-raised-button
        (click)="
          sortQuestionGroups(
            QuestionGroupAssignment.Assigned,
            QuestionGroupSortingMethod.Thematic
          )
        "
      >
        <mat-icon> sort </mat-icon> Tematikusan
      </button>
    </div>
    <div class="quesiton-qg-assignment-column-content">
      <div
        *ngFor="let assignedQuestionGroup of assignedQuestionGroups.items"
      >
        <mat-card class="quesiton-qg-assignment-card">
          <div>
            {{ assignedQuestionGroup.questionGroupName }}
            <div>
              <mat-chip-listbox>
                <mat-chip-option
                  *ngFor="
                    let categoryName of assignedQuestionGroup.categoies
                  "
                  class="quesiton-qg-assignment-category-chip"
                  [disableRipple]="true"
                  [selectable]="false"
                >
                  {{ categoryName }}
                </mat-chip-option>
              </mat-chip-listbox>
            </div>
          </div>
          <div>
            <button
              mat-mini-fab
              color="primary"
              class="quesiton-qg-assignment-button"
              (click)="
                unassingQuestionGroup(
                  assignedQuestionGroup.questionGroupUuid
                )
              "
            >
              <mat-icon> arrow_backward </mat-icon>
            </button>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<!-- Dialog actions -->
<div id="quesiton-qg-assignment-dialog-actions">
  <button mat-button mat-dialog-close>Vissza</button>
  <button
    mat-raised-button
    (click)="handleSaveButtonClick()"
    style="background-color: darkgreen; color: white"
    mat-dialog-close
  >
    Mentés
  </button>
</div>