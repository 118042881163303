<section id="login-section">
  <div id="login-container">
    <div id="login-title">
      <div style="display: flex; align-items: center; justify-content: center; margin-bottom:15px;">
        <img
          style="width: 35px; height: 35px; margin-right: 10px"
          src="https://mrkresz.hu/assets/shared/mrkresz-logo.png"
        />
        <span> Mr. Kresz </span>
      </div>

      Adminisztrációs felület
    </div>

    <div id="login-input-fields">
      <form [formGroup]="loginForm">
        <mat-form-field appearance="outline" id="login-email-field">
          <mat-label>E-mail cím</mat-label>
          <input #emailInput matInput formControlName="loginEmailFormControl" />
        </mat-form-field>
        <mat-form-field appearance="outline" id="login-password-field">
          <mat-label>Jelszó</mat-label>
          <input
            #passwordInput
            matInput
            [type]="showPassword ? 'text' : 'password'"
            formControlName="loginPasswordFormControl"
          />
          <button
            mat-icon-button
            matSuffix
            type="button"
            (click)="showPassword = !showPassword"
            tabindex="-1"
          >
            <mat-icon>
              {{ showPassword ? "visibility" : "visibility_off" }}
            </mat-icon>
          </button>
        </mat-form-field>
      </form>
      <button
        mat-flat-button
        color="primary"
        id="login-submit"
        (click)="checkCredentials()"
      >
        Bejelentkezés
      </button>

      <div class="server-address">
        Szerver: {{ environmentService.getCurrentEnvironment().serverHost }}
      </div>
    </div>
  </div>
</section>
