import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-video-overlay',
  templateUrl: './text-video-overlay.component.html',
  styleUrls: ['./text-video-overlay.component.scss']
})
export class TextVideoOverlayComponent implements OnInit {
  @Input()
  title!: string;

  @Input()
  text!: string;


  @Input()
  positionBottom: string = "40px;";


  @Input()
  positionLeft: string = "5px";


  @Input()
  positionRight: string = "5px";


  constructor() { }

  ngOnInit(): void {
  }

}
