import { EnvironmentService } from 'src/app/services/common/environments/environment.service';
import { BackendService } from './common/backend.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralFileUploadService {
  constructor(private backendService: BackendService) { }


  private getApiUrl() {
    return "/api/admin/general_file_upload/upload_file";
  }

  // return with the temporary file name of the uploaded file in bucket
  // this can be used with any endpoint that accept general uploaded file
  async uploadFile(file: File, uploadProgressCallback: (loaded: number, total: number) => void): Promise<string> {
    const result: { relativeBucketUrlOfUplodedFile: string } = await this.backendService.callApi(
      this.getApiUrl(),
      "POST",
      {}, // empty body
      new Map([["general-file", file]]
      ), {
      onUploadProgress: uploadProgressCallback
    }, 1000 * 60 * 60 * 7);


    return result.relativeBucketUrlOfUplodedFile;
  }
}
