import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, Subscription } from 'rxjs';
import { getEndOfDay, getStartOfDay } from 'src/app/modules/shared-module/utils/date-utils';
import { GeneralPracticalModuleLog, getEventNameOfPracticalModuleLog, getRealTypeOfPracticalModuleLog, PracticalModuleLog } from '../../models/practical-module-log.model';
import { generalEventName, PracticeModuleEventDescription, PracticeModuleEventLogGroup, practiceModuleEventLogGroups } from '../../models/practice-module-event-log-groups.model';
import { PracticalModuleLogsDataService } from '../../services/practical-module-logs-data.service';

@Component({
  selector: 'app-practice-module-logs-filter',
  templateUrl: './practice-module-logs-filter.component.html',
  styleUrls: ['./practice-module-logs-filter.component.scss']
})
export class PracticeModuleLogsFilterComponent implements OnInit, OnDestroy {
  protected displayedPracticeModuleEventLogGroups: Array<PracticeModuleEventLogGroup<string>>;

  protected fromDateFilterFC: FormControl<Date>;
  protected toDateFilterFC: FormControl<Date>;
  protected eventsFilterFC: FormControl<Array<PracticalModuleLogEntityEvent>>;
  protected textSearchFC: FormControl<string>;

  private fetchedLogsChangeSubscription: Subscription | null = null;

  constructor(
    protected practicalModuleLogsDataService: PracticalModuleLogsDataService
  ) { }

  public ngOnInit(): void {
    // Create the form controls
    this.createFormControls();

    // Initialize the displayed events in the event selector
    // By default all events are present
    this.displayedPracticeModuleEventLogGroups = practiceModuleEventLogGroups;

    console.log("Hey I'M here");
    console.log(this.displayedPracticeModuleEventLogGroups);

    // Make a subscription to the fetched log change which will update the displayed event
    // options based on the fetched logs each time when a new ones is fetched from the server
    this.createDisplayedEventUpdaterSubscription();

    // At the end of the initialization we call the onFormControlValueChange method to
    // create the initial filtering with the default form control values
    this.onFormControlValueChange();
  }

  public ngOnDestroy(): void {
    this.fetchedLogsChangeSubscription?.unsubscribe();
  }

  /**
   * Creates the form controls and sets their default values. Also subscribes to the `toDateFilterFC`,
   * `eventsFilterFC`, `textSearchFC` form controls value changes to update the actual filtering state.
   */
  private createFormControls(): void {
    // From date form control (default value is the actual date's starting)
    this.fromDateFilterFC = new FormControl<Date>(getStartOfDay(new Date()));

    // From date form control (default value is the actual date's ending)
    this.toDateFilterFC = new FormControl<Date>(getEndOfDay(new Date()));
    this.toDateFilterFC.valueChanges.subscribe(this.onFormControlValueChange);

    // Events filter form control (default value is all event)
    this.eventsFilterFC = new FormControl<Array<PracticalModuleLogEntityEvent>>(
      this.getAllPracticalModuleLogEntityEvent()
    );
    this.eventsFilterFC.valueChanges.subscribe(this.onFormControlValueChange);

    // Text search from control (default value is an empty string)
    this.textSearchFC = new FormControl<string>("");
    this.textSearchFC.valueChanges.pipe(debounceTime(600)).subscribe(this.onFormControlValueChange);
  }


  /**
   * Subcribes to the fetched logs change and updates the displayed events in the event selector.
   * If the subscription is already made, no new subscriotion will be created.
   */
  private createDisplayedEventUpdaterSubscription(): void {
    // Check if the subsciption is already made
    if (this.fetchedLogsChangeSubscription) {
      return;
    }

    this.fetchedLogsChangeSubscription = this.practicalModuleLogsDataService.fetchedLogs$.subscribe(
      (fetchedLogs: Array<PracticalModuleLog> | null) => {
        // If the fetched logs null, disable the event selector
        if (fetchedLogs == null) {
          this.eventsFilterFC.disable({ emitEvent: false });
          return;
        }

        // If the fetched logs length is 0, we disable the event selector
        if (fetchedLogs.length === 0) {
          this.eventsFilterFC.disable({ emitEvent: false });
        } else {
          // Otherwise we enable it
          this.eventsFilterFC.enable({ emitEvent: false });
        }

        // Determine the visible groups by the logs
        this.displayedPracticeModuleEventLogGroups = this.getDisplayedPracticeModuleOperationLogGroupsFromLogs(
          fetchedLogs
        );

      }
    );
  }

  /**
   * Handler for the form controls value change. It updates the actual filtering state of the
   * `PracticalModuleLogsDataService`.
   */
  private onFormControlValueChange = async () => {
    if (this.fromDateFilterFC.value == null || this.toDateFilterFC.value == null) {
      return;
    }

    // Create the filtering based on the form controlers state
    const filtering: PracticeModuleLogsFiltering = this.composeFilteringFromFormControls();
    // Update the filtering in the data service
    await this.practicalModuleLogsDataService.updateFiltering(filtering);
  }

  /**
   * Creates a `PracticeModuleLogsFiltering` object from the actual form controls' state.
   * 
   * @returns the filtering object
   */
  private composeFilteringFromFormControls(): PracticeModuleLogsFiltering {
    return {
      startTimestamp: getStartOfDay(this.fromDateFilterFC.value).valueOf(),
      endTimestamp: getEndOfDay(this.toDateFilterFC.value).valueOf(),
      events: this.eventsFilterFC.value,
      containedText: this.textSearchFC.value
    };
  }

  /**
   * Gets the displayed text for the event selector which shows how many event is selected currently.
   * 
   * @returns isplayed text for the event selector
   */
  protected getDisplayedValueForEventSelectTrigger(): string {
    return `${this.getNumberOfSelectedEntityEvents()} művelettípus kiválasztva`;
  }

  /**
   * Gets the event option value for the event selector.
   * 
   * @param group the event group
   * @param eventName the name of the event
   * 
   * @returns the event option value as `PracticalModuleLogEntityEvent`
   */
  protected getEventOptionValue(
    group: PracticeModuleEventLogGroup<string>,
    eventName: string
  ): PracticalModuleLogEntityEvent {
    return {
      entityType: group.entityType,
      eventName: eventName
    }
  }

  /**
   * Gets all possible entity events as `PracticalModuleLogEntityEvent`. This used to provide a default
   * value for the event selector.
   * 
   * @returns all possible entity events as `PracticalModuleLogEntityEvent`
   */
  private getAllPracticalModuleLogEntityEvent(): Array<PracticalModuleLogEntityEvent> {
    const practicalModuleLogEntityOperations: Array<PracticalModuleLogEntityEvent> = [];

    // Iterate over the event groups
    for (const groups of practiceModuleEventLogGroups) {
      // Iterate overt the events in the group
      for (const events of groups.eventDescriptions) {
        practicalModuleLogEntityOperations.push({ entityType: groups.entityType, eventName: events.eventName })
      }
    }

    return practicalModuleLogEntityOperations;
  }

  protected operationsSelectCompareWith = (entityEvent1: PracticalModuleLogEntityEvent, entityEvent2: PracticalModuleLogEntityEvent) => {
    return entityEvent1.entityType === entityEvent2.entityType && entityEvent1.eventName === entityEvent2.eventName;
  }

  /**
   * Determines the found entity operations of the provided set of logs.
   * 
   * @param logs 
   * @returns 
   */
  protected getDisplayedPracticeModuleOperationLogGroupsFromLogs(logs: Array<PracticalModuleLog>): Array<PracticeModuleEventLogGroup<string>> {
    const displayedPracticeModuleOperationLogGroups: Array<PracticeModuleEventLogGroup<string>> = [];

    // Iterate over the logs
    for (const log of logs) {
      // Get the correspondig group which contains the logged entity
      const originalGroup: PracticeModuleEventLogGroup<string> = practiceModuleEventLogGroups.find(
        (group: PracticeModuleEventLogGroup<string>) => { return group.entityType === log.loggedEntityType; }
      );

      // Get the event description of the logged event
      let originalEventDescription: PracticeModuleEventDescription<string> | undefined;
      if (getRealTypeOfPracticalModuleLog(log) == GeneralPracticalModuleLog) {
        originalEventDescription = originalGroup.eventDescriptions.find(
          (eventDescription: PracticeModuleEventDescription<string>) => {
            return eventDescription.eventName === generalEventName;
          }
        );
      } else {
        originalEventDescription = originalGroup.eventDescriptions.find(
          (eventDescription: PracticeModuleEventDescription<string>) => {
            return eventDescription.eventName === getEventNameOfPracticalModuleLog(log);
          }
        );
      }

      // Get the group for the already created groups
      const group: PracticeModuleEventLogGroup<string> | undefined = displayedPracticeModuleOperationLogGroups.find(
        (group: PracticeModuleEventLogGroup<string>) => { return group.entityType === log.loggedEntityType; }
      );

      // If the group exists 
      if (group) {
        // and the event NOT already added to the group
        const isEventAlreadyAddedToGroup = group.eventDescriptions.find((desc: PracticeModuleEventDescription<string>) => desc.eventName === originalEventDescription.eventName);

        if (!isEventAlreadyAddedToGroup) {
          // Add the event to the existing group
          group.eventDescriptions.push(originalEventDescription);
        }
      } else {
        // Otherwise create a group with the event
        displayedPracticeModuleOperationLogGroups.push(
          {
            ...originalGroup,
            eventDescriptions: [originalEventDescription]
          }
        );
      }
    }

    return displayedPracticeModuleOperationLogGroups;
  }

  /**
   * Returns the number of selected entity events in the event selector filter. It only counts the selected
   * events which are also displayed.
   * Note: There are selected options which are not shown to preseve their selected state
   * 
   * @returns the number of selected events in the event selector filter
   */
  private getNumberOfSelectedEntityEvents(): number {
    let numberOfSelectedOperations = 0;

    for (const entityEvent of this.getAllPracticalModuleLogEntityEvent()) {
      // Determine if the entity event is displayed
      // (there is a log in the actual fetched logs with that entity and event)
      const isDisplayedContains: boolean = this.displayedPracticeModuleEventLogGroups.some(
        (group: PracticeModuleEventLogGroup<string>) => {
          return group.entityType === entityEvent.entityType && group.eventDescriptions.some(
            (eventDescription: PracticeModuleEventDescription<string>) => {
              return eventDescription.eventName === entityEvent.eventName;
            }
          )
        }
      );
      // Determine if the option is checked
      const isChecked: boolean = this.eventsFilterFC.value.some(
        (actualEntityEvent: PracticalModuleLogEntityEvent) => {
          return actualEntityEvent.entityType === entityEvent.entityType && actualEntityEvent.eventName === entityEvent.eventName;
        }
      );

      // If the option is both displayed and checked, we count it
      if (isDisplayedContains && isChecked) {
        ++numberOfSelectedOperations;
      }
    }

    // Return the selected (and displayed) options
    return numberOfSelectedOperations;
  }

}

export type PracticeModuleLogsFiltering = {
  startTimestamp: number;
  endTimestamp: number;
  events: Array<PracticalModuleLogEntityEvent>
  containedText: string;
}

export type PracticalModuleLogEntityEvent = {
  entityType: string;
  eventName: string;
}