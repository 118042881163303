import { Component, OnInit, ViewChildren, QueryList, ElementRef, OnDestroy } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subscription } from 'rxjs';
import { Menu, MenuPoint, SubMenuPoint } from 'src/app/classes/menu';
import { Settings, SettingsKeyValuePair, SettingsService } from 'src/app/services/common/settings.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
  @ViewChildren("expansionPanel") expansionPanelList:QueryList<MatExpansionPanel>;
  
  isNavigationSectionVisibleSettingChangeSubscription:Subscription;

  menu:Menu;

  constructor(
    private settingsService:SettingsService,
    protected menuService:MenuService,
    private elementRef:ElementRef<HTMLElement>,
  ) { }

  ngOnInit(): void {
    // Get the available menu
    this.menu = this.menuService.getAvailableMenu();

    // Set the initial visibility based on the saved value in the settings
    this.setNavigationSectionVisibility(this.settingsService.getSettingsValue<boolean>(Settings.isNavigatonSectionVisible, true));

    // Subscribe to the navigation section visibility setting change
    this.subscribeToIsNavigationSectionVisibleSettingChange();
  }

  public ngOnDestroy(): void {
    this.isNavigationSectionVisibleSettingChangeSubscription?.unsubscribe();
  }

  private subscribeToIsNavigationSectionVisibleSettingChange() {
    this.isNavigationSectionVisibleSettingChangeSubscription = this.settingsService.getSettingsValueChangeSubscription(
      (settingsKeyValuePair:SettingsKeyValuePair) => {
        this.setNavigationSectionVisibility(settingsKeyValuePair.value);
      },
      [ Settings.isNavigatonSectionVisible ]
    );
  }

  private setNavigationSectionVisibility(isVisible:boolean):void {
    if(isVisible) {
      this.elementRef.nativeElement.classList.add("visible");
    } else {
      this.elementRef.nativeElement.classList.remove("visible");
    }
  }

  public expansionPanelOpened(openedPanelIndex:number):void {
    this.expansionPanelList.toArray().forEach(
      (expansionPanel:MatExpansionPanel, index:number) => {
        if(index !== openedPanelIndex) {
          expansionPanel.close();
        }
      }
    );
  }

  protected getRouteCommands(menuPoint:MenuPoint, subMenuPoint:SubMenuPoint):Array<string> {
    return [ menuPoint.route, ...subMenuPoint.subroute.split("/")];
  }

}
