import { NgZone } from "@angular/core";
import { DescriptionLinesItem } from "../../../../package-definitions-page/models/package-definitions.model";

export type MenuWindowOption = {
  text: string;
  backgroundColor?: string;
};

export class MenuWindowOverlay extends google.maps.OverlayView {
  position: google.maps.LatLng;
  mainContainer: HTMLDivElement;
  closeButton: HTMLElement;
  buttons: HTMLElement[] = [];

  constructor(
    position: google.maps.LatLng,
    private title: string,
    private text: string,
    private options: MenuWindowOption[],
    private onTapOption: (optionIndex: number) => any,
    private zone: NgZone
  ) {
    super();
    this.position = position;

    // This zero-height div is positioned at the bottom of the tip.
    this.mainContainer = document.createElement("div");
    this.mainContainer.setAttribute("style", this.getMainContainerStyle());
    this.addTextToMainContainer();
    this.addOptionToMainContainer();
    this.addCloseButton();

    // Optionally stop clicks, etc., from bubbling up to the map.
    MenuWindowOverlay.preventMapHitsAndGesturesFrom(this.mainContainer);
  }

  dispose() {
    this.mainContainer.removeAllListeners();
    this.closeButton?.removeAllListeners("click");
    this.buttons?.forEach((b) => b.removeAllListeners("click"));
    this.buttons = [];
    this.mainContainer = null;
    this.closeButton = null;
    this.setMap(null);
  }

  addCloseButton() {
    this.closeButton = document.createElement("div");

    this.closeButton.setAttribute(
      "style",
      `
      position:absolute;
      top:6px;
      right:6px;
      display:block;
      height:25px;
      width:25px;
      background-color:black;
      color:white;
      border-radius:5px;
      text-align:center;
      line-height:25px;
      cursor:pointer;
    `
    );

    this.closeButton.addEventListener("click", () => {
      this._close();
    });

    this.closeButton.textContent = "✕";

    this.mainContainer.appendChild(this.closeButton);
  }

  getTitleStyle() {
    return `
      font-weight:bold;
      font-size:15px;
      margin-bottom:7px;
      margin-right:30px;
    `;
  }

  getTextStyle() {
    return `
      font-size:14px;
      margin-bottom:5px;
      white-space:pre-wrap;
    `;
  }

  addTextToMainContainer() {
    const titleDiv = document.createElement("div");
    titleDiv.setAttribute("style", this.getTitleStyle());
    const descDiv = document.createElement("div");
    descDiv.setAttribute("style", this.getTextStyle());

    titleDiv.textContent = this.title;
    descDiv.textContent = this.text;

    this.mainContainer.appendChild(titleDiv);
    this.mainContainer.appendChild(descDiv);
  }

  getButton(text: string, backgroundColor: string): HTMLElement {
    const newButton = document.createElement("div");
    newButton.textContent = text;
    newButton.setAttribute(
      "style",
      `
      color:white;
      background-color: ${backgroundColor};
      border-radius:5px;
      height:35px;
      display:flex;
      align-items:center;
      justify-content:center;
      width:100%;
      font-size:14px;
      margin-top:10px;
      font-weight:700;
      cursor:pointer;
    `
    );
    return newButton;
  }

  _close() {
    this.setMap(null);
  }

  addOptionToMainContainer() {
    const defaultBg = "#434bb2";
    for (let i = 0; i < this.options.length; i++) {
      const option = this.options[i];
      const button = this.getButton(
        option.text,
        option.backgroundColor ?? defaultBg
      );
      this.buttons.push(button);
      button.addEventListener("click", () => {
        // Azért kell, hogy az angular zone-on belül futtassuk!
        this.zone.run(() => {
          this.onTapOption(i);
        });

        this._close();
      });
      this.mainContainer.appendChild(button);
    }
  }

  getMainContainerStyle() {
    return `
      background-color:white;
      border-radius:5px;
      border: 1px solid grey;
      padding:10px;
      position:absolute;
      display:flex;
      cursor:default;
      width:200px;
    `;
  }

  /** Called when the popup is added to the map. */
  onAdd() {
    this.getPanes()!.floatPane.appendChild(this.mainContainer);
  }

  /** Called when the popup is removed from the map. */
  onRemove() {
    if (this.mainContainer.parentElement) {
      this.mainContainer.parentElement.removeChild(this.mainContainer);
    }
  }

  /** Called each frame when the popup needs to draw itself. */
  draw() {
    const divPosition = this.getProjection().fromLatLngToDivPixel(
      this.position
    )!;

    // Hide the popup when it is far out of view.
    const display =
      Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
        ? "block"
        : "none";

    if (display === "block") {
      this.mainContainer.style.left = divPosition.x + "px";
      this.mainContainer.style.top = divPosition.y + "px";
    }

    if (this.mainContainer.style.display !== display) {
      this.mainContainer.style.display = display;
    }
  }
}
