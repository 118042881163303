import { first } from 'rxjs';
import { CityZoneDialogEditComponent } from './components/city-zone-dialog-edit/city-zone-dialog-edit.component';
import { ConfirmationDialogService } from 'src/app/modules/confirmation-dialog/services/confirmation-dialog.service';
import { PracticeCityZoneService } from './services/practice-city-zone-service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActionTextComponent } from './../action-text/action-text.component';
import { PracticePathService } from 'src/app/services/practice-path.service';
import { NewZoneinfo } from './components/city-zone-adder/city-zone-adder.component';
import { NotifierService } from 'src/app/services/common/notifier-service.service';
import { PreDefinedColors } from './pre-defined-zone-colors';
import { LatLng } from 'src/app/modules/practice-path-video-editor-page/models/LatLng';
import { GoogleMapPosition } from 'src/app/classes/model/google-map-position';
import { PracticeCityZone } from './../../classes/model/practice-city-zone';
import { WrappedZoneOnMap, ZoneMapController } from './zone-map-controller';
import { constructorParametersDownlevelTransform } from '@angular/compiler-cli';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GoogleMapExtendedController } from '../practice-path-video-editor-page/components/google-map-extended/google-map-controller';
import { WritableObservableArray } from '../practice-path-video-editor-page/models/ObservableArray';
import { v4 } from "uuid";
import { ActivatedRoute } from '@angular/router';
import { PracticePath } from 'src/app/classes/model/practice-path';
import { PracticePathMapController } from "./practice-path-map-controller";
import { timeStamp } from 'console';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-practice-city-zone-editor',
  templateUrl: './practice-city-zone-editor.component.html',
  styleUrls: ['./practice-city-zone-editor.component.scss']
})
export class PracticeCityZoneEditorComponent implements OnInit, OnDestroy {
  protected googleMapController: GoogleMapExtendedController | undefined;

  protected zoneController: ZoneMapController;
  protected zones$: WritableObservableArray<WrappedZoneOnMap> = new WritableObservableArray<WrappedZoneOnMap>();
  protected currentEditedNewZoneUuid: string | undefined = undefined;
  protected currentAddingNewZoneColor: string = "#000000";
  protected isVertexesEditable: boolean = false;
  protected currentHoveredZoneUuid: string | undefined = undefined;

  protected practicePathMapController: PracticePathMapController | undefined;
  protected isPracticePathsDrawOnMapEnabled: boolean = true;

  protected isComponentLoaded: boolean;

  protected practicePaths: PracticePath[] = [];

  constructor(public notifier: NotifierService,
    protected activatedRoute: ActivatedRoute,
    private loaderService: NgxUiLoaderService,
    protected practicePathService: PracticePathService,
    private zoneService: PracticeCityZoneService,
    private confirmationDialogService: ConfirmationDialogService,
    private matDialog: MatDialog
  ) {
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.googleMapController?.dispose();
  }

  getPathParameters(): { cityUuid: string } {
    const cityUuid = this.activatedRoute.snapshot.paramMap.get('cityUuid')
    if (cityUuid == null) {
      throw new Error("City uuid path parameter is null");
    }

    return {
      cityUuid: cityUuid
    };
  }

  // Az init akkor hívódik, amikor a map betöltődött!
  async init() {
    // load practice path
    this.loaderService.start();
    this.practicePaths = await this.practicePathService.fetchPracticePathsOfCity(this.getPathParameters().cityUuid)
    this.practicePathMapController = new PracticePathMapController(this.googleMapController.map!);
    for (const path of this.practicePaths) {
      this.practicePathMapController.addPracticePathToMap(path);
    }

    // load zones from server
    const zoneFromServer = await this.zoneService.getAllZoneInCity(this.getPathParameters().cityUuid);

    for (const zone of zoneFromServer) {
      this.zones$.add({
        zone: zone,
        isEditable: false,
        isNewlyCreatedZone: false,
        strongBorder: false
      })
    }

    this.zoneController = new ZoneMapController(this.zones$);
    this.zoneController.setMap(this.googleMapController.map);
    this.loaderService.stop();

    if (this.practicePaths[0] != undefined && this.practicePaths[0].path[0] != undefined) {
      this.googleMapController.setCenter(this.practicePaths[0].path[0].position);
    }
  }

  getZoneAdderComponentState() {
    if (this.currentEditedNewZoneUuid == undefined) {
      return "SHOW_ADD_ZONE_BUTTON_STATE";
    } else {
      return "ADDING_NEW_ZONE_STATE";
    }
  }

  getNextFreeColor(): string {
    return new PreDefinedColors().getNextFreeColor(this.zones$.getFullCopy().map((wrappedZoneOnMap: WrappedZoneOnMap) => wrappedZoneOnMap.zone));
  }

  onTapChangeVertexesEditable() {
    for (let i = 0; i < this.zones$.length; i++) {
      const copy = this.zones$.getAt(i);
      copy.isEditable = !this.isVertexesEditable;
      this.zones$.updateAt(i, copy);
    }

    this.isVertexesEditable = !this.isVertexesEditable;
  }

  onTapDeleteZone(zoneUuid: string) {
    const wrappedZone = this.zones$.getFullCopy().find((wrappedZoneOnMap: WrappedZoneOnMap) => wrappedZoneOnMap.zone.uuid == zoneUuid);

    const dialog = this.confirmationDialogService.open("Zóna törlése", `Biztosan törlőd a '${wrappedZone.zone.name}' zónát?`, async () => {
      await this.zoneService.removeZone(wrappedZone.zone.cityUuid, wrappedZone.zone.uuid);

      this.notifier.notify('success', 'Sikeres zóna törlés');

      const indexOfZone = this.zones$.getFullCopy().findIndex((wrappedZoneOnMap: WrappedZoneOnMap) => wrappedZoneOnMap.zone.uuid == zoneUuid);
      this.zones$.removeAt(indexOfZone);

      dialog.close();
    },
      () => {
        dialog.close();
      }
    );

  }

  onTapEditZone(zoneUuid: string) {
    const dialogRef = CityZoneDialogEditComponent.openDialog(this.matDialog, {
      zone: this.zones$.getFullCopy().find((wrappedZoneOnMap: WrappedZoneOnMap) => wrappedZoneOnMap.zone.uuid == zoneUuid).zone,
    });

    dialogRef.afterClosed().pipe(first()).subscribe(async (result: PracticeCityZone | undefined) => {
      if (result != undefined) {
        // update local fields in zones$
        const indexOfZone = this.zones$.getFullCopy().findIndex((wrappedZoneOnMap: WrappedZoneOnMap) => wrappedZoneOnMap.zone.uuid == zoneUuid);
        const copy = this.zones$.getCopyAt(indexOfZone);
        copy.zone = result;
        console.log(copy);

        this.zones$.updateAt(indexOfZone, copy);
      }
    });

  }

  onTapAddNewZone() {
    const nextPredefinedFreeColor = this.getNextFreeColor();
    this.currentAddingNewZoneColor = nextPredefinedFreeColor;

    const temporaryUuidForNewZone = v4();
    const defaultPolygon: GoogleMapPosition[] = this.googleMapController.createRectanglePolygonFromCenterRelativeToZoomLevel(
      this.googleMapController.getCenterOfMap(),
      150,
      150,
      this.googleMapController.getZoomLevel()
    );

    // Hozzunk létre egy új zónát és adjuk hozzá a térképhez
    const newZone: PracticeCityZone = {
      polygonColor: nextPredefinedFreeColor,
      name: "Új zóna",
      polygon: defaultPolygon,
      cityUuid: this.getPathParameters().cityUuid,
      uuid: temporaryUuidForNewZone
    };

    this.zones$.insert(0, {
      zone: newZone,
      isEditable: true,
      isNewlyCreatedZone: true,
      strongBorder: false
    });


    this.currentEditedNewZoneUuid = temporaryUuidForNewZone;
  }

  async onTapSaveAllZonePolygons() {
    if (this.currentEditedNewZoneUuid != undefined) {
      this.notifier.notify("error", "Új zóna felvétele folyamatban van, fejezd be!");
      return;
    }

    const cityUuid = this.getPathParameters().cityUuid;
    await this.zoneService.updatePolygonsOfZones(cityUuid, this.zones$.getFullCopy().map((wrappedZoneOnMap: WrappedZoneOnMap) => wrappedZoneOnMap.zone));

    this.notifier.notify("success", "A zónák mentése sikeres volt!");
  }

  async onTapSaveNewZone(newZoneInfo: NewZoneinfo) {
    const zoneIndex = this.zones$.findIndex((wrappedZoneOnMap: WrappedZoneOnMap) => wrappedZoneOnMap.zone.uuid == this.currentEditedNewZoneUuid);
    const wrappedZone = this.zones$.getCopyAt(zoneIndex);
    wrappedZone.zone.name = newZoneInfo.name;

    const cityUuid = this.getPathParameters().cityUuid;
    const insertedZone = await this.zoneService.addZone(cityUuid, wrappedZone.zone);

    // Frissítjük a szerver által létrehozott uuid-val
    wrappedZone.zone.uuid = insertedZone.uuid;

    this.zones$.updateAt(zoneIndex, wrappedZone);

    this.currentEditedNewZoneUuid = undefined;
    this.notifier.notify('success', 'Zóna sikeresen hozzáadva! Ne felejts el menteni a végén! 😉');
  }

  onTapCancelNewZoneAdding() {
    const zoneIndex = this.zones$.findIndex((wrappedZoneOnMap: WrappedZoneOnMap) => wrappedZoneOnMap.zone.uuid == this.currentEditedNewZoneUuid);
    // remove zone from map
    this.zones$.removeAt(zoneIndex);
    this.currentEditedNewZoneUuid = undefined;
  }

  onTapChangePracticePathsDrawing() {
    this.isPracticePathsDrawOnMapEnabled = !this.isPracticePathsDrawOnMapEnabled;
    if (this.isPracticePathsDrawOnMapEnabled) {
      this.practicePathMapController?.showPracticePaths();
    } else {
      this.practicePathMapController?.hidePracticePaths();
    }
  }

  onMouseLeaveFromZoneCard() {
    if (this.currentHoveredZoneUuid == undefined) return;
    // remove strong border
    const zoneIndex = this.zones$.findIndex((wrappedZoneOnMap: WrappedZoneOnMap) => wrappedZoneOnMap.zone.uuid == this.currentHoveredZoneUuid);
    const wrappedZone = this.zones$.getCopyAt(zoneIndex);
    wrappedZone.strongBorder = false;
    this.zones$.updateAt(zoneIndex,
      wrappedZone
    );

    this.currentHoveredZoneUuid = undefined;

    // remove path highlight
    this.practicePathMapController.removeHighlightFromPathInZone()
  }

  onMouseEnterZoneCard(zoneUuid: string) {
    if (this.currentHoveredZoneUuid == zoneUuid) return;

    this.currentHoveredZoneUuid = zoneUuid;

    // Update hovered zone border to strong
    const zoneIndex = this.zones$.findIndex((wrappedZoneOnMap: WrappedZoneOnMap) => wrappedZoneOnMap.zone.uuid == zoneUuid);
    const wrappedZone = this.zones$.getCopyAt(zoneIndex);
    wrappedZone.strongBorder = true;

    this.zones$.updateAt(
      zoneIndex,
      wrappedZone
    );

    this.practicePathMapController.highlighPathInZone(zoneUuid, wrappedZone.zone.polygonColor);
  }

  ngForTrackByZoneUuid(index: number, wrappedZoneOnMap: WrappedZoneOnMap) {
    return wrappedZoneOnMap.zone.uuid;
  }

  onTapCityZoneCard(zoneUuid: string) {
    // Navigáljunk a térképen az adott zónához
    this.zoneController.animateCameraToZone(this.zones$.find((wrappedZoneOnMap: WrappedZoneOnMap) => wrappedZoneOnMap.zone.uuid == zoneUuid));
  }


  onTapMap(latLng: LatLng) {
    console.log(latLng);
  }

  // Akkor hívódik amikor a google-map-extended komponenst betöltődött
  // ez hívja az init-et
  async onGoogleMapReady(googleMapExtendedController: GoogleMapExtendedController) {
    this.googleMapController = googleMapExtendedController;

    googleMapExtendedController.onTap.subscribe((latLng: LatLng) => {
      this.onTapMap(latLng);
    });

    await this.init();
  }

}
