<!-- Log item header -->
<div
    class = "log-item-header"
    [style.background-color] = "eventColor"
>
    <!-- Toggle -->
    <div>
        <mat-icon
            class = "toggle-icon"
            (click) = "toggleDetailsShown()"
        >
            {{ isDetailsShown ? 'arrow_drop_down' : 'arrow_right' }}
        </mat-icon>
    </div>
    <!-- Special header part based on the log -->
    <div
        [ngSwitch]="practicalModuleLog.loggedEntityType"
        style="flex-grow: 1; min-width: 0;"
    >
        <app-critical-point-log-item-header
            *ngSwitchCase="'critical_point'"
            [criticalPointLog]="getLogAsCriticalPointLog(practicalModuleLog)"
        ></app-critical-point-log-item-header>
        <app-critical-point-assignment-log-item-header
            *ngSwitchCase="'critical_point_assignment'"
            [criticalPointAssignmentLog]="getLogAsCriticalPointAssignmentLog(practicalModuleLog)"
        ></app-critical-point-assignment-log-item-header>
        <app-practice-path-log-item-header
            *ngSwitchCase="'practice_path'"
            [practicePathLog]="getLogAsPracticePathLog(practicalModuleLog)"
        ></app-practice-path-log-item-header>
        <div *ngSwitchDefault> Ismeretlen entitástípus </div>
    </div>
    <!-- Creation and misc infos -->
    <div style="font-size: 0.8rem; text-align: end;">
        <div style="white-space: nowrap;">
            {{ practicalModuleLog.creationTimestampMs | date: "YYYY-MM-dd HH:mm:ss" }}
        </div>

        <div>
          {{practicalModuleLog.cityName}}
        </div>

        <div
            [ngSwitch]="practicalModuleLog.eventOrigin.eventInitiator.initiatorType"
        >
            <div
                *ngSwitchCase="'system'"
                class="log-creator"
            >
                Rendszer (v.{{ getEventOriginSystemEntityVersionNumber(practicalModuleLog.eventOrigin) }}) ⚙️
            </div>
            <div
                *ngSwitchCase="'admin'"
                class="log-creator"
            >
                <div>
                    {{ getEventOriginAdminEntityName(practicalModuleLog.eventOrigin) }} 👨‍💻
                </div>
                <div>
                    IP: 
                    <ng-container
                        *ngIf="getEventOriginAdminEntityIpAddress(practicalModuleLog.eventOrigin);
                        then adminHasIpAddress;
                        else adminHasNoIpAddress"
                    ></ng-container>

                    <ng-template #adminHasIpAddress>
                        <a
                            [href]="'https://tools.keycdn.com/geo?host=' + getEventOriginAdminEntityIpAddress(practicalModuleLog.eventOrigin)"
                            target="_blank"
                            class="ip-address-link"
                        >
                            {{ getEventOriginAdminEntityIpAddress(practicalModuleLog.eventOrigin) }}
                        </a>
                    </ng-template>

                    <ng-template #adminHasNoIpAddress>
                        <span style="font-style: italic;"> Ismeretlen </span>
                    </ng-template>
                </div>
            </div>
        </div>
         <div
            *ngIf = "practicalModuleLog.eventOrigin.isImplicitlyCreated"
            matTooltip="A logolt esemény közvetetten jött létre egy másik esemény hatására"
            style="white-space: nowrap;"
        >
            Implicit 🤖
         </div>
    </div>
</div>
<!-- Log item content -->
<div
    class = "log-item-content-container"
    *ngIf="isDetailsShown"
    [style.border]="'2px solid ' + eventColor"
>
    <ng-content
        *ngIf="isPracticalModuleLogGeneral(); then generalLog; else specificEntityLog"
    ></ng-content>

    <ng-template #generalLog>
        <app-general-log-item-content
            [generalPracticalModuleLog]="getLogAsGeneralLog(practicalModuleLog)"
        ></app-general-log-item-content>
    </ng-template>

    <ng-template #specificEntityLog>
        <div
            [ngSwitch]="practicalModuleLog.loggedEntityType"
        >
            <app-critical-point-log-item-content
                *ngSwitchCase="'critical_point'"
                [criticalPointLog]="getLogAsCriticalPointLog(practicalModuleLog)"
            ></app-critical-point-log-item-content>
            <app-critical-point-assignment-log-item-content
                *ngSwitchCase="'critical_point_assignment'"
                [criticalPointAssingmentLog]="getLogAsCriticalPointAssignmentLog(practicalModuleLog)"
            ></app-critical-point-assignment-log-item-content>
            <app-practice-path-log-item-content
                *ngSwitchCase="'practice_path'"
                [practicePathLog]="getLogAsPracticePathLog(practicalModuleLog)"
            ></app-practice-path-log-item-content>
        </div>
    </ng-template>
</div>