<div style="font-size: 26px; font-weight: bold; margin-bottom: 10px">
  {{ this.globalPracticeEditorService.practicePath.name }}<br />
  {{ this.globalPracticeEditorService.city.city }}
</div>

<div style = "display: flex; column-gap: 10px; margin-bottom: 10px;">
  <button
    [routerLink]="['../../', this.globalPracticeEditorService.practicePath.uuid, 'main-data-edit']"
    color="primary"
    mat-raised-button
  >
    Adat szerkesztő megnyitása
  </button>
  <button
    color="primary"
    mat-raised-button
    (click) = "onOpenNarrationsButtonClick()"
  >
    Narrációk megnyitása
  </button>
</div>

<div style="margin-bottom: 25px">
  {{ this.globalPracticeEditorService.practicePath.uuid }}
</div>

<table mat-table [dataSource]="this.statistics" class="mat-elevation-z8">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Megnevezés</th>
    <td mat-cell *matCellDef="let element">{{ element.displayedName }}</td>
  </ng-container>

  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef>Érték</th>
    <td mat-cell *matCellDef="let element">{{ element.value }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['name', 'value']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['name', 'value']"></tr>
</table>

<!-- Videó linkek -->
<span style="margin-top:20px;display:block;">
  A szerkesztőbe betöltött videó:
  <span *ngIf="getVideoType() == 'RELEASED'">
    Released HLS
  </span>
  <span *ngIf="getVideoType() == 'RAW'">
    Eredeti nyers (egyenesen a GoPro-ból)
  </span>
  <span *ngIf="getVideoType() == 'RAW_MINIMAL'">
    Nyers lebutítótt változat (Raw minimal bitrate)
  </span>
</span>

<div style="margin-top: 25px;" class="info-title">Videó linkek</div>

<div style="display: flex; flex-wrap:wrap;flex-direction: row; gap: 15px">
  <app-copyable-text
    style="
      background-color: rgba(243, 247, 248, 0.564);
      padding: 5px;
      border-radius: 5px;
    "
    text="Raw video"
    [clipboardText]="
      this.globalPracticeEditorService.practicePath.video.rawVideo?.videoUrl ??
      'Nem létezik'
    "
  >
  </app-copyable-text>

  <br />

  <app-copyable-text
    style="
      background-color: rgba(243, 247, 248, 0.564);
      padding: 5px;
      border-radius: 5px;
    "
    text="Minimal raw"
    [clipboardText]="
      this.globalPracticeEditorService.practicePath.video
        .rawVideoConvertedMinimalBitrate?.minimalBitrateVideoUrl ??
      'Nem létezik'
    "
  >
  </app-copyable-text>

  <br />
  <app-copyable-text
    style="
      background-color: rgba(243, 247, 248, 0.564);
      padding: 5px;
      border-radius: 5px;
    "
    text="Felhasználók számára (released)"
    [clipboardText]="
      this.globalPracticeEditorService.practicePath.video.releasedVideo
        ?.videoHlsUrl ?? 'Nem létezik'
    "
  >
  </app-copyable-text>
</div>
