import { PracticePath, PracticePathBaseInformations } from 'src/app/classes/model/practice-path';
import { PracticePathFiltering } from '../practice-path-filter/practice-path-filter.component';

export type PracticePathSorter = (
  practicePath1:PracticePath|PracticePathBaseInformations,
  practicePath2:PracticePath|PracticePathBaseInformations
) => number;

export class PracticePathSorters {
  /**
   * Sorts the practice paths in ascending order by their name property.
   */
  public static sortByName:PracticePathSorter = (
    practicePath1:PracticePath|PracticePathBaseInformations,
    practicePath2:PracticePath|PracticePathBaseInformations
  ) => {
    return practicePath1.name.localeCompare(practicePath2.name);
  };

  /**
   * Sorts the practice paths in descending order by their creation date.
   */
  public static sortByCreatedDesc:PracticePathSorter = (
    practicePath1:PracticePath|PracticePathBaseInformations,
    practicePath2:PracticePath|PracticePathBaseInformations
  ) => {
    return practicePath2.created.timestamp - practicePath1.created.timestamp;
  }

  /**
   * Sorts the (full) practice paths in ascending order by their exam path number.
   */
  public static sortByExamPathNumber:PracticePathSorter = (
    practicePath1:PracticePath|PracticePathBaseInformations,
    practicePath2:PracticePath|PracticePathBaseInformations
  ) => {
    return (practicePath1.examPathNumber ?? Infinity) - (practicePath2.examPathNumber ?? Infinity)
  }

  public static sortByAutoIncrementId:PracticePathSorter = (
    practicePath1:PracticePath|PracticePathBaseInformations,
    practicePath2:PracticePath|PracticePathBaseInformations
  ) => {
    return (practicePath1.autoIncrementId ?? Infinity) - (practicePath2.autoIncrementId ?? Infinity);
  }
}


export type PracticePathShortingOptionNames = "byAutoIncrementId"|"byExamPathNumber"|"byName"|"byCreatedDesc";

export type SortingFunctionOption = {
  displayedName: string;
  name:PracticePathShortingOptionNames;
  sortingFunction: PracticePathSorter;

  /** Determines if the sorting option is available based on the given filtering.
   * If this is not present, the sorting is always available
   */
  sortingAvailableOnlyIfPredicate?:(filtering:PracticePathFiltering) => boolean;
};

export const practicePathSortingOptions:Record<PracticePathShortingOptionNames, SortingFunctionOption> = {
  byAutoIncrementId: {
    name: "byAutoIncrementId",
    displayedName: "Sorszám alapján",
    sortingFunction: PracticePathSorters.sortByAutoIncrementId,
    sortingAvailableOnlyIfPredicate: (filtering:PracticePathFiltering|null) => {
      return filtering?.onlyFullPracticePaths === false
    }
  },
  byExamPathNumber: {
    name: "byExamPathNumber",
    displayedName: "Vizsgatétel szám alapján",
    sortingFunction: PracticePathSorters.sortByExamPathNumber,
    sortingAvailableOnlyIfPredicate: (filtering:PracticePathFiltering|null) => {
      return filtering?.onlyFullPracticePaths === true
    }
  },
  byName: {
    name: "byName",
    displayedName: "ABC szerint",
    sortingFunction: PracticePathSorters.sortByName
  },
  byCreatedDesc: {
    name: "byCreatedDesc",
    displayedName: "Hozzáadási dátum szerint",
    sortingFunction: PracticePathSorters.sortByCreatedDesc
  }
}
