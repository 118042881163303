export class GlobalGift {
    uuid:string;
    message:string;
    receivableUntil:number;
    created: {
        timestamp:number,
        adminEmail?:string
    };
    packageDefinitionUuid:string;
    packageDefinitionName?:string;
    isActive:boolean;
}

export function globalGiftSorter(globalGift1:GlobalGift, globalGift2:GlobalGift):number {
    return globalGift2.created.timestamp - globalGift1.created.timestamp;
}