<mat-card class = "category-card">
   <!-- Category header -->
   <div class = "category-card-header">
      <div class = "category-icon-container">
         <img class = "category-icon-image" [src] = "category.iconImageUrl" draggable = "false" />
      </div>
      <div class = "category-name">
         {{ category.name }}
      </div>
      <div class = "category-position-edit-container">
         <button mat-mini-fab color = "primary" (click) = "openModifyPositionsDialog()">
            <mat-icon> reorder </mat-icon>
         </button>
      </div>
      <div class = "category-edit-container">
         <button mat-mini-fab color = "primary" (click) = "openModifyCategoryDialog()">
            <mat-icon> edit </mat-icon>
         </button>
      </div>
   </div>

   <!-- Category body -->
   <div class = "category-card-body">
      <div class = "category-infos-container">
         <div class = "category-header-image-container">
            <img class = "category-header-image" [src] = "category.headerImageUrl" draggable = "false" />
         </div>
         <mat-card-content>
            <p class = "category-description-container" >
               Leírás: <br />
               {{ category.description }}
            </p>
            <div class = "category-testtype-description">
               <div class = "category-testtype-name">
                  Teljes teszt
               </div>
               <div class = "category-attributes">
                  <div class = "category-number-of-question-full"> <b> Kérdések száma: </b> {{ category.numberOfQuestionsOfFullTest }} db  </div>
                  <div class = "category-question-time"> <b> Idő kérdésenként: </b> {{ category.maxTimeForOneQuestionInMs / 1000 }} s  </div>
                  <div class = "category-minpoint-full"> <b> Min.: </b> {{ category.minPointFullTest }} pont  </div>
                  <div class = "category-maxpoint-full"> <b> Max.: </b> {{ category.maxPointFullTest }} pont </div>
               </div>
            </div>
            <div class = "category-testtype-description">
               <div class = "category-testtype-name">
                  Tematikus teszt
               </div>
               <div class = "category-attributes">
                  <div class = "category-number-of-question-thematic"> <b> Kérdések száma: </b> {{ category.numberOfQuestionsOfThematicTest }} db  </div>
                  <div class = "category-minpoint-thematic"> <b> Min.: </b> {{ category.minPointThematicTest }} pont  </div>
               </div>
            </div>
         </mat-card-content>
      </div>
      <div class = "category-questiongroups-container">
         <div class = "category-questiongroups-title">
            Hozzárendelt kérdéscsoportok
         </div>
         <div class = "category-questiongroups-content">
            <div *ngFor = "let questionGroup of category.questionGroups" class = "category-questiongroup-div">
               {{ getQuestionGroupName(questionGroup) }}
            </div>
            <div *ngIf = "category.questionGroups.length === 0" class = "category-questiongroups-empty">
               Jelenleg még nincs kérdéscsoport rendelve a kategóriához.
            </div>
         </div>
      </div>
   </div>

   
</mat-card>
