import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import {
  WritableObservableArray,
  ReadableObservableArray,
  isWritableObservableArray,
} from "./../../models/ObservableArray";
import { KeyEventHelper } from "../../KeyEventHelper";
import { GeoHelper } from "../../GeoHelper";
import { ObservableArrayListenerCallback } from "../../models/ObservableArray";
import { LatLng } from "../../models/LatLng";
import { ObservableArray } from "../../models/ObservableArray";
import { MarkerStyle, RenderedMarker, UnderlyingMarker } from "./marker-types";
import { BehaviorSubject, Subject } from "rxjs";
import { MarkerUtil } from "./marker-util";

// MarkeredPolyLineController-hez a markerekhez stílus leírása
export type MarkeredPolylineIconStyles = {
  globalStyle: MarkerStyle;
  firstMarkerStyle?: MarkerStyle;
  lastMarkerStyle?: MarkerStyle;
  specificMarkerStyles?: {
    // Ez az indexű markernek a megadott stílusa legyen, ami felülírja a global-t
    markerIndex: number;
    style: MarkerStyle;
  }[];
};

export type MarkeredPolylineOptions = {
  polylineColor?: string;
  isDraggableMarkers: boolean;
  isMultiSelectable: boolean;
  markerIconStyles?: MarkeredPolylineIconStyles;
  maximumVisibleMarkerOnMap: number;
  displayedName: string; // A megjelenített név, például a beállításokban
  id: string;
  zIndex: number;
  minZoom?: number;
  showFirstAndLast?: boolean;
  opacity?: number;
  clickable?: boolean;
  optimized?: boolean;
};

export type MarkerClickEvent = {
  position: { latitude: number; longitude: number }; // hova kattintott
  underlyingMarkerIndex: number; // melyik markerre
  underlyingRenderedMarker: google.maps.Marker;
};

// Ahhoz, hogy létrehozzuk szükség van egy
// térképhez
export class MarkeredPolylineController {
  private polyline: google.maps.Polyline = new google.maps.Polyline();
  private map?: google.maps.Map;
  private markers: RenderedMarker[] = [];
  private _isMarkerVisible: boolean = true;
  private _isPolylineVisible: boolean = true;

  // Figyelni kell arra, hogy az onLeftClickMarker nem az ngzone-ban van
  // hanem a rootZone-ban. Így ha a callback hiába hív pl setTimeout-ot, vagy async hívást
  // nem fog hívódni a change detector, mert nem ngZone-ban van
  public onLeftClickMarker: Subject<MarkerClickEvent | null> =
    new Subject<MarkerClickEvent | null>();
  public onRightClickMarker: Subject<MarkerClickEvent | null> =
    new Subject<MarkerClickEvent | null>();
  public onMarkerVisibilityChange: Subject<boolean> = new Subject<boolean>();

  public readonly optionsDefaultValues: Partial<MarkeredPolylineOptions> = {
    polylineColor: "green",
    showFirstAndLast: false,
    opacity: 1.0,
    clickable: true,
    optimized: true,
    markerIconStyles: {
      globalStyle: MarkerUtil.getCircleMarker("green", "green", "D"),
      specificMarkerStyles: [],
    },
  };

  constructor(
    // Ez alapján renderelünk
    // Ha WritableArray-t kapunk, akkor kétirányú a data binding, tehát ha a térképen módosítunk valamit
    // akkor az frissülni fog a tömbben is.
    private underlyingMarkers:
      | ReadableObservableArray<UnderlyingMarker>
      | ObservableArray<UnderlyingMarker>,
    private options: MarkeredPolylineOptions
  ) {
    this.options = Object.assign({}, this.optionsDefaultValues, this.options);
    this.options.markerIconStyles.specificMarkerStyles ??= [];

    if (isWritableObservableArray(this.underlyingMarkers) == false) {
      this.options.isDraggableMarkers = false;
    }

    underlyingMarkers.addListener(this.underlyingMarkerChangeListener);
    this.polyline.setOptions({
      strokeColor: this.options.polylineColor,
      strokeWeight: 4,
      zIndex: this.options.zIndex,
    });
  }

  // Teljesen reseteli a térképet és beállítja a [newUnderlyingMarkers] által megadott markereket
  public setNewUnderlyingMarker(
    newUnderlyingMarkers: ObservableArray<UnderlyingMarker>
  ) {
    this.underlyingMarkers = newUnderlyingMarkers;
    this.markers.forEach((m) => m.renderedMarker.setMap(null));
    this.markers = [];
    this.initMapFromUnderlyingMarkers();
  }

  private refreshMarkerIcons() {
    for (let i = 0; i < this.markers.length; i++) {
      const marker = this.markers[i];
      // Szinezzük kijelölés alapján:
      const markerStyle = this.getMarkerStyle(i);

      if (marker.isSelected) {
        marker.renderedMarker.setIcon(markerStyle.selectedIcon);
      } else {
        marker.renderedMarker.setIcon(markerStyle.defaultIcon);
      }
    }
  }

  // Ez a függvény rendereli ki optimalizáltan a markereket
  // A középponthoz legközelebbi maximumVisibleMarkerOnMap darab marker-t jeleníti meg
  public renderMarkersBasedOnCenter(newBoundingBoxCenter: LatLng) {
    const orderedMarkerByDistance = [...this.markers];

    orderedMarkerByDistance.sort(
      (markerA: RenderedMarker, markerB: RenderedMarker) => {
        const distA: number = GeoHelper.distance(
          newBoundingBoxCenter.latitude,
          newBoundingBoxCenter.longitude,
          markerA.underlyingMarker.position.latitude,
          markerA.underlyingMarker.position.longitude
        );
        const distB: number = GeoHelper.distance(
          newBoundingBoxCenter.latitude,
          newBoundingBoxCenter.longitude,
          markerB.underlyingMarker.position.latitude,
          markerB.underlyingMarker.position.longitude
        );

        return distA - distB;
      }
    );

    const firstMarkerGlobally = this.markers[0];
    const lastMarkerGlobally = this.markers[this.markers.length - 1];

    // Maximum egy MarkeredPolylineController ennyi marker-t jelenítsen meg egyszerre
    for (let i = 0; i < orderedMarkerByDistance.length; i++) {
      const marker = orderedMarkerByDistance[i];

      // A centerhez legközelebbi maximumVisibleMarkerOnMap db-ot vesszük be
      // Illetve mindenképp bevesszük, ha az első vagy az utolsó markerről van szó!
      if (
        (this.options.showFirstAndLast &&
          (marker == firstMarkerGlobally || marker == lastMarkerGlobally)) ||
        ((this.map?.getZoom() ?? 0) > (this.options.minZoom ?? 16.5) &&
          this._isMarkerVisible &&
          i < this.options.maximumVisibleMarkerOnMap)
      ) {
        if (marker.renderedMarker.getMap() == null) {
          marker.renderedMarker.setMap(this.map!);
        }
      } else {
        // Levesszük, ha:
        // nem elég nagy a zoom
        // vagy a markerel láthatósága ki van kapcsolva
        // vagy beválogattunk maximumMarkerOnMap darabot
        marker.renderedMarker.setMap(null);
      }
    }

    if (this.options.showFirstAndLast) {
      firstMarkerGlobally?.renderedMarker?.setMap(this.map);
      lastMarkerGlobally?.renderedMarker?.setMap(this.map);

      // Csak akkor lehet draggelni, ha látszódik a többi marker is
      // ha a showFirstAndLast igaz, akkor az első és utolsó mindig látszódik
      // viszont draggelni csak akkor lehessen, ha a többi is látszik
      firstMarkerGlobally?.renderedMarker.setDraggable(this.isMarkerVisible);
      lastMarkerGlobally?.renderedMarker.setDraggable(this.isMarkerVisible);
    }
  }

  // Amikor utoljára történt marker deselecting
  public lastMarkerDeselectingTimestamp: number = 0;
  public deselectAllMarker() {
    let deselectMarkerCounter = 0;
    for (const marker of this.markers) {
      if (marker.isSelected) {
        deselectMarkerCounter += 1;
      }
      marker.isSelected = false;
    }

    // Ha legalább 1 marker deselecteltünk, tehát bármi változott
    if (deselectMarkerCounter > 0) {
      this.lastMarkerDeselectingTimestamp = Date.now().valueOf();
      this.refreshMarkerIcons();
    }
  }

  // Látszódjon-e a polyline
  public setPolylineVisibility(isVisible: boolean): MarkeredPolylineController {
    this.polyline.setMap(isVisible ? this.map! : null);
    this._isPolylineVisible = isVisible;
    return this;
  }

  public isPolylineVisible = () => this._isPolylineVisible;
  public get isMarkerVisible() {
    return this._isMarkerVisible;
  }
  public set isMarkerVisible(markerVisible: boolean) {
    this._isMarkerVisible = markerVisible;
  }

  // Látszódjanak-e a markerek
  public setMarkersVisibility(isVisible: boolean) {
    this._isMarkerVisible = isVisible;

    this.renderMarkersBasedOnCenter({
      latitude: this.map!.getCenter()?.lat()!,
      longitude: this.map!.getCenter()?.lng()!,
    });

    this.onMarkerVisibilityChange.next(isVisible);
  }

  initMapFromUnderlyingMarkers() {
    this.setPolylineToCurrentUnderlying();
    for (let i = 0; i < this.underlyingMarkers.length; i++) {
      this.addRenderedMarkerFrom(this.underlyingMarkers.getAt(i), i);
    }
  }

  setMap(map: google.maps.Map) {
    this.map = map;

    // Beállítjuk a polyline-nak, hogy melyik térképen jelenjen meg
    this.polyline.setMap(map);
    this.polyline.setOptions({
      visible: this._isPolylineVisible,
      zIndex: this.options.zIndex,
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            fillOpacity: 1,
          },
          offset: "100%",
          repeat: "200px",
        },
      ],
    });

    // A polyline-t beállítjuk a controller által tárolt pontokra
    // és hozzáadjuk a térképhez a jelenleg tárolt underlying markereket
    this.initMapFromUnderlyingMarkers();
    this.refreshMarkerIcons();

    // Ha a térképre kattintunk üres területe
    this.map.addListener("click", () => {
      this.deselectAllMarker();
    });

    // Azért kell, mert amikor elengedjük a dragelést semmi nem updatelődik ameddig a camera move animáció nem végez
    // (tehát ameddig nem áll meg a mozgásból)
    // Ez se oldja meg teljesen a problémát, de viszonylag sokszor meghívódik a tilesloaded event
    this.map.addListener("tilesloaded", () => {
      this.renderMarkersBasedOnCenter({
        latitude: this.map?.getCenter()!.lat()!,
        longitude: this.map?.getCenter()!.lng()!,
      });
    });

    // drag esetén frissítjük a kirenderelt markereket, csak n darabot jelenítünk meg egyszerre,
    // a centerhez legközelebb n darabot.
    this.map.addListener("drag", () => {
      if (Math.random() < 0.1) {
        this.renderMarkersBasedOnCenter({
          latitude: this.map?.getCenter()!.lat()!,
          longitude: this.map?.getCenter()!.lng()!,
        });
      }
    });
  }

  underlyingMarkersToLatLng(
    underlyingMarkers: UnderlyingMarker[]
  ): google.maps.LatLng[] {
    return underlyingMarkers.map((u) => {
      return new google.maps.LatLng(u.position.latitude, u.position.longitude);
    });
  }

  // A polyline teljes egészét beállítja a jelenlegi underlying adatra
  private setPolylineToCurrentUnderlying() {
    this.polyline.setPath(
      this.underlyingMarkersToLatLng(this.underlyingMarkers.getFullCopy())
    );
  }

  public setMarkerOpacity(opacity: number): MarkeredPolylineController {
    this.markers.forEach((m) => m.renderedMarker.setOpacity(opacity));
    this.options.opacity = opacity;
    return this;
  }

  public getSelectedMarkerIndexes(): number[] {
    const result: number[] = [];
    for (let i = 0; i < this.markers.length; i++) {
      const marker = this.markers[i];
      if (marker.isSelected) {
        result.push(i);
      }
    }
    return result;
  }

  getDisplayedName = () => this.options.displayedName;
  getId = () => this.options.id;

  private addRenderedMarkerFrom(
    underlyingMarker: UnderlyingMarker,
    index: number
  ): void {
    const newMarker = new google.maps.Marker({
      clickable: this.options.clickable,
      map: this._isMarkerVisible ? this.map : null,
      draggable: this.options.isDraggableMarkers,
      position: {
        lat: underlyingMarker.position.latitude,
        lng: underlyingMarker.position.longitude,
      },
      optimized: this.options.optimized,
      opacity: this.options.opacity,
      icon: this.getMarkerStyle(index).defaultIcon,
      zIndex: this.options.zIndex,
    });

    const newRenderedMarker: RenderedMarker = {
      renderedMarker: newMarker,
      underlyingMarker: underlyingMarker,
      isSelected: false,
    };

    if (this.options.isDraggableMarkers) {
      newMarker.addListener("click", (event: any) => {
        this._onClickMarker(newRenderedMarker);
      });

      newMarker.addListener("drag", (event: any) => {
        const selectedMarkers = this.getSelectedMarkerIndexes();
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        this._onDragMarker(newRenderedMarker, lat, lng, selectedMarkers);
      });
    }

    if (this.options.clickable) {
      newMarker.addListener("click", (event: any) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        this.onLeftClickMarker.next({
          position: { latitude: lat, longitude: lng },
          underlyingMarkerIndex: this.underlyingMarkers.getIndexOf(
            newRenderedMarker.underlyingMarker
          ),
          underlyingRenderedMarker: newMarker,
        });
      });
      newMarker.addListener("rightclick", (event: any) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        this.onRightClickMarker.next({
          position: { latitude: lat, longitude: lng },
          underlyingMarkerIndex: this.underlyingMarkers.getIndexOf(
            newRenderedMarker.underlyingMarker
          ),
          underlyingRenderedMarker: newMarker,
        });
      });
    }

    this.markers.push(newRenderedMarker);
  }

  private _onClickMarker(clickedMarker: RenderedMarker) {
    if (this.options.isMultiSelectable == false) return;

    const selectedIndex = this.markers.indexOf(clickedMarker);

    // [CTRL] lenyomásával történt kattintáskor bevesszük az adott markert a kiválasztásba
    if (KeyEventHelper.isCtrlDown) {
      clickedMarker.isSelected = !clickedMarker.isSelected;
      this.refreshMarkerIcons();
      return;
    }

    // [SHIFT] lenyomása esetén
    // ha volt már kiválasztva egynél több elem
    // akkor abba  az irányba terjesztjük az intervallumot amerre a kijelölés történt
    // ha pedig még nem volt, akkor csak kijelöljük az első elemet
    if (KeyEventHelper.isShiftDown) {
      const selectedMarkerIndexes = this.getSelectedMarkerIndexes();
      if (selectedMarkerIndexes.length == 0) {
        clickedMarker.isSelected = !clickedMarker.isSelected;
        this.refreshMarkerIcons();
        return;
      }

      const firstSelected = selectedMarkerIndexes[0];
      const lastSelected =
        selectedMarkerIndexes[selectedMarkerIndexes.length - 1];

      // Ha az intervallum közepébe választott, akkor nem csinálunk semmit!
      if (selectedIndex >= firstSelected && selectedIndex <= lastSelected) {
        return;
      }

      // Merre lépjen? Jobbra, vagy balra terjesztjük az intervallumot.
      // Ha az index amit kiválasztott előtte van, akkor visszafele megyünk, ha utána, akkor előre lépünk
      const step = firstSelected < selectedIndex ? 1 : -1;
      for (let i = firstSelected; i != selectedIndex + step; i += step) {
        this.markers[i].isSelected = true;
      }

      this.refreshMarkerIcons();
    }
  }

  public hasAnySelectedMarker() {
    return this.markers.some((m) => m.isSelected);
  }

  private _onDragMarker(
    draggedMarker: RenderedMarker,
    lat: number,
    lng: number,
    selectedMarkerIndexes: number[]
  ) {
    const draggedMarkerIndex = this.markers.indexOf(draggedMarker);

    // Amit mozgatunk annak frissítjük az underlying pozícióját a lat/lng-re
    const index = this.underlyingMarkers.getIndexOf(
      draggedMarker.underlyingMarker
    );

    const draggedUnderlyingMarker = this.underlyingMarkers.getCopyAt(index);
    const latDelta = lat - draggedUnderlyingMarker.position.latitude; // Az előző ismert pozból kivonjuk az újat
    const lngDelta = lng - draggedUnderlyingMarker.position.longitude;

    draggedUnderlyingMarker.position = { latitude: lat, longitude: lng };
    this.updateUnderlyingMarkersIfWritable(index, draggedUnderlyingMarker);

    // Ha van kijelölés, de olyan elemet mozgattunk ami nem volt benne a kijelölésben
    // akkor a kijelölt elemeket nem mozgatjuk!
    if (
      selectedMarkerIndexes.length != 0 &&
      selectedMarkerIndexes.includes(draggedMarkerIndex) == false
    ) {
      return;
    }

    // Ha pedig van kijelölés akkor a többi elemet mozgatjuk deltával
    if (selectedMarkerIndexes.length != 0) {
      for (const selectedIndex of selectedMarkerIndexes) {
        const marker = this.markers[selectedIndex];
        // Ha ez az a marker amit épp mozgatunk ignoráljuk
        if (marker == draggedMarker) continue;
        const ind = this.underlyingMarkers.getIndexOf(marker.underlyingMarker);
        const otherSelectedMarkerCopy = this.underlyingMarkers.getCopyAt(ind);
        otherSelectedMarkerCopy.position = {
          latitude: otherSelectedMarkerCopy.position.latitude + latDelta,
          longitude: otherSelectedMarkerCopy.position.longitude + lngDelta,
        };
        this.updateUnderlyingMarkersIfWritable(ind, otherSelectedMarkerCopy);
      }
    }
  }

  updateUnderlyingMarkersIfWritable(index: number, newValue: UnderlyingMarker) {
    const isWritable =
      (this.underlyingMarkers as WritableObservableArray<UnderlyingMarker>)
        .updateAt != undefined;
    if (isWritable) {
      (
        this.underlyingMarkers as WritableObservableArray<UnderlyingMarker>
      ).updateAt(index, newValue);
    }
  }

  private updatePolylineAt(index: number, newPosition: LatLng) {
    this.polyline
      .getPath()
      .setAt(
        index,
        new google.maps.LatLng(newPosition.latitude, newPosition.longitude)
      );
  }

  // Ha van specifikus az adott indexre, akkor azt adja vissza
  // egyébként pedig a globális stílust
  private getMarkerStyle(index: number): MarkerStyle {
    const styleIndex =
      this.options.markerIconStyles.specificMarkerStyles.findIndex(
        (specific) => specific.markerIndex == index
      );

    // Nézzük meg,hogy az első vagy utolsó-e?
    if (
      index == 0 &&
      this.options.markerIconStyles.firstMarkerStyle !== undefined
    ) {
      return this.options.markerIconStyles.firstMarkerStyle;
    } else if (
      index == this.markers.length - 1 &&
      this.options.markerIconStyles.lastMarkerStyle !== undefined
    ) {
      return this.options.markerIconStyles.lastMarkerStyle;
    }

    // Nincs ennek a markernek specifikus index megadva
    if (styleIndex == -1) {
      return this.options.markerIconStyles.globalStyle;
    }

    return this.options.markerIconStyles.specificMarkerStyles[styleIndex].style;
  }

  // Az underlying marker törlésre került
  // és az ehhez tartozó markert töröljük
  private removeRenderedMarker(removedIndex: number) {
    this.markers[removedIndex].renderedMarker.setMap(null);
    this.markers.splice(removedIndex, 1);
  }

  private updateRenderedMarker(
    newUnderlyingMarker: UnderlyingMarker,
    atPosition: number
  ) {
    const currentRenderedMarker = this.markers[atPosition];
    currentRenderedMarker.underlyingMarker = newUnderlyingMarker;
    currentRenderedMarker.renderedMarker.setPosition({
      lat: newUnderlyingMarker.position.latitude,
      lng: newUnderlyingMarker.position.longitude,
    });
  }

  private underlyingMarkerChangeListener: ObservableArrayListenerCallback<UnderlyingMarker> =
    (
      action: "update" | "add" | "remove" | "removedAllElement",
      oldValue?: UnderlyingMarker | undefined,
      newValue?: UnderlyingMarker | undefined,
      index?: number | undefined,
      actionOrigin?: string | undefined
    ) => {
      switch (action) {
        case "add": {
          this.addRenderedMarkerFrom(newValue!, index!);
          this.setPolylineToCurrentUnderlying();

          // Hozzáadásnál csak akkor jelenítsünk meg markert ha az a jelenlegi viewport-ban látszódik
          // mozgatáskor ez frissülni fog az onChangeMapBounding box által
          // a default, hogy a térkép közepéhez legközelebbi x markert jelenítjük meg
          // viszont ezt nagyon költséges lenne minden add-nál vagy update-nél ellenőrizni
          // pl: hozzáadunk egyszerre 1000 markert, akkor az 1000^2 művelet lenne az onChangeMapBoundingBox miatt
          // ha minden add-nál lefuttatnánk

          const isContains = this.map.getBounds().contains({
            lat: newValue.position.latitude,
            lng: newValue.position.longitude,
          });

          this.markers[index].renderedMarker.setMap(
            isContains ? this.map : null
          );

          break;
        }
        case "removedAllElement": {
          // Beállítjuk a polyline-t (üres path-et kap)
          this.setPolylineToCurrentUnderlying();

          // majd az összes markert levesszük a térképről
          this.markers.forEach((m) => m.renderedMarker.setMap(null));
          this.markers.forEach((m) => (m.underlyingMarker = null));
          this.markers.length = 0;
          break;
        }
        case "remove": {
          this.removeRenderedMarker(index!);
          this.setPolylineToCurrentUnderlying();
          this.refreshMarkerIcons();
          break;
        }
        case "update": {
          this.updateRenderedMarker(newValue!, index!);
          this.updatePolylineAt(index, newValue.position);

          // Lehet, hogy egy kritikus pont közel került a térkép közepéhez, ezért meg kell jelenítenünk
          // Átmenetileg ellenőrzés nélkül megjelenítjük az updatelt markert
          // Default a térkép közepéhez képest a legközelebbi x markert jelenítjük meg
          // A legjobb lenne lefuttatni, hogy benne van-e a legközelebbi x markerben
          // viszont ez például egy dragnál nagyon költséges, mert marker mozgatása alatt
          // a drag event nagyon sokszor meghívódik!
          // TODO*
          if (this.markers[index].renderedMarker.getMap() == null) {
            this.markers[index].renderedMarker.setMap(this.map);
          } else break; // Ha rajta van nem csinálunk semmit
        }
      }
    };

  // Leiratkozik a markers observable-ről
  dispose() {
    this.underlyingMarkers.removeListener(this.underlyingMarkerChangeListener);
    this.markers.forEach((m) => {
      m.renderedMarker.setMap(null);
      google.maps.event.clearInstanceListeners(m.renderedMarker);
    });

    this.markers = [];

    this.onLeftClickMarker?.unsubscribe();
    this.onRightClickMarker?.unsubscribe();
    this.onMarkerVisibilityChange?.unsubscribe();
    this.polyline.setMap(null);
  }
}
