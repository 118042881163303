import { AnswerDetails } from './../../../../../../../classes/model/fillout-details';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilloutDetails } from 'src/app/classes/model/fillout-details';
import { Question } from 'src/app/classes/model/question';
import { matchingPropertyPredicate } from 'src/app/functions/misc';
import { QuestionService } from 'src/app/services/question.service';
import { StudentService } from 'src/app/services/student.service';

class ResolvedQuestion {
  text:string;
  optionTexts:Array<string>;
  optionCorrectnesses:Array<boolean>;
  optionChoosen:Array<boolean>;
  mediaUrl:string;
  spentTimeOnAnswerInMs:number|null;
  answerIndex:number;
  answerIndexes:number[];
  type: "simple"|"arrangeable";
  answerDetailForQuestion?:AnswerDetails;


  constructor() {
    this.optionTexts = new Array<string>();
    this.optionCorrectnesses = new Array<boolean>();
    this.optionChoosen = new Array<boolean>();
    this.mediaUrl = "";
    this.answerIndexes = [];
  }
}

@Component({
  selector: 'app-student-fillout-details',
  templateUrl: './student-fillout-details.component.html',
  styleUrls: ['./student-fillout-details.component.scss']
})
export class StudentFilloutDetailsComponent implements OnInit {

  filloutDetails:FilloutDetails|null;
  resolvedQuestions:Array<ResolvedQuestion>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{ filloutUuid:string, index:number },
    private studentService:StudentService,
    private questionService:QuestionService
  ) {}

  public ngOnInit():void {
    this.filloutDetails = null;
    this.resolvedQuestions = new Array<ResolvedQuestion>();
    this.loadFillout();
  }

  private async loadFillout() {
    try {
      const result:{ details:FilloutDetails } = await this.studentService.getFillout(this.data.filloutUuid);
      this.filloutDetails = result.details;
      for(let filloutDetail of this.filloutDetails.answers) {
        const question:Question|null = this.questionService.getQuestionArrayRef().find(matchingPropertyPredicate("uuid", filloutDetail.questionUuid)) ?? null;

        if(question == null) {
          this.resolvedQuestions.push(null);
          continue;
        }

        let resolvedQuestion:ResolvedQuestion = new ResolvedQuestion();
        resolvedQuestion.spentTimeOnAnswerInMs = filloutDetail.spentTimeOnAnswerInMs ?? null;
        resolvedQuestion.text = question.text;
        resolvedQuestion.answerIndexes = filloutDetail.answerIndexes;
        resolvedQuestion.answerIndex = filloutDetail.answerIndex;
        resolvedQuestion.type = filloutDetail.type;
        resolvedQuestion.answerDetailForQuestion = filloutDetail;

        for(let [index, option] of question.options.entries()) {
          resolvedQuestion.optionTexts.push(option.text);
          resolvedQuestion.optionCorrectnesses.push(option.isSolution);
          if(index === filloutDetail.answerIndex) {
            resolvedQuestion.optionChoosen.push(true);
          } else {
            resolvedQuestion.optionChoosen.push(false);
          }
        }

        resolvedQuestion.mediaUrl = question.mediaUrl;

        this.resolvedQuestions.push(resolvedQuestion);
      }
    } catch(error:any) {}
  }

  getOptionClass(isCorrect:boolean, isChoosen:boolean):Object {
    if(isCorrect){
      return { "student-fillout-option-green": true }
    } else {
      if(isChoosen) {
        return { "student-fillout-option-red": true }
      } else {
        return { "student-fillout-option-white": true }
      }
    }
  }

}
