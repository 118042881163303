
<!-- Teljes container, 100% méretű -->
<div class="main-container">

  <!-- 
    Alignment class határozza meg, hogy hol lesznek az ikonok
    ez a div egy flexbox
  -->
  <div [ngClass]="[positionMapToClass[iconContainerPosition],alignmentMapToClass[iconsAlignment]]">
    <div *ngFor="let imageUrl of iconUrls">
      <img class="icon" [src]="imageUrl">
    </div>
  </div>
</div>
