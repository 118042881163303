import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VideoJsPlayerOptions } from '../videojs-player/videojs-player.component';

@Component({
  selector: 'app-vr-video-file-preview-dialog',
  templateUrl: './vr-video-file-preview-dialog.component.html',
  styleUrls: ['./vr-video-file-preview-dialog.component.scss']
})
export class VrVideoFilePreviewDialogComponent implements OnInit, OnDestroy {

  baseVideoJsPlayerOptions:Partial<VideoJsPlayerOptions> = {
    autoplay: false,
    controls: true,
    loop: false,
    aspectRatio: "16:9",
  }

  videoJsPlayerOptions:VideoJsPlayerOptions;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{ file:File }
  ) { }

  ngOnInit(): void {
    this.videoJsPlayerOptions = {
      ...this.baseVideoJsPlayerOptions,
      sources: [
        { src: URL.createObjectURL(this.data.file), type: "video/mp4" }
      ]
    };
  }

  public ngOnDestroy():void {
    if(this.videoJsPlayerOptions.sources[0]) {
      URL.revokeObjectURL(this.videoJsPlayerOptions.sources[0].src);
    }
  }

}
