import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Permission, permissionAssignableToValues, PermissionGroup } from 'src/app/classes/model/permissions';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-permission-editor-dialog',
  templateUrl: './permission-editor-dialog.component.html',
  styleUrls: ['./permission-editor-dialog.component.scss']
})
export class PermissionEditorDialogComponent implements OnInit {

  nameFC:FormControl;
  displayedNameFC:FormControl;
  descriptionFC:FormControl;
  permissionGroupFC:FormControl;
  assignableToFC:FormControl;
  formGroup:FormGroup;

  permissionAssignableToValues = permissionAssignableToValues;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{
      dialogMode:"add"|"edit",
      permission?:Permission,
      permissionGroups:ReadonlyArray<PermissionGroup>
    },
    private permissionService:PermissionsService,
    private snackBarService:MatSnackBar,
    private dialogRef:MatDialogRef<PermissionEditorDialogComponent>
  ) { }

  ngOnInit(): void {
    this.nameFC = new FormControl(this.data.permission?.name ?? "", [ Validators.required ]);
    this.displayedNameFC = new FormControl(this.data.permission?.displayedName ?? "", [ Validators.required ]);
    this.permissionGroupFC = new FormControl(this.data.permission?.permissionGroup ?? "");
    this.assignableToFC = new FormControl(this.data.permission?.assignableTo ?? [], [ Validators.required ]);
    this.descriptionFC = new FormControl(this.data.permission?.description ?? "");

    if(this.data.dialogMode === "edit") {
      this.nameFC.disable();
    }

    this.formGroup = new FormGroup({
      nameFC: this.nameFC,
      displayedNameFC: this.displayedNameFC,
      descriptionFC: this.descriptionFC,
      assignableToFC: this.assignableToFC
    });
  }

  public async handleSaveButtonClick():Promise<void> {
    const permission:Permission = {
      name: this.nameFC.value,
      displayedName: this.displayedNameFC.value,
      assignableTo: this.assignableToFC.value
    };

    if(this.permissionGroupFC.value) {
      permission.permissionGroup = this.permissionGroupFC.value;
    }

    if(this.descriptionFC.value) {
      permission.description = this.descriptionFC.value;
    }
    
    if(this.data.dialogMode === "add") {
      try {
        await this.permissionService.addPermission(permission);
        this.dialogRef.close(permission);
        this.snackBarService.open("Sikeres jogosultság létrehozás", "Bezár", { duration: 3000, panelClass: "mat-snackbar-succcess" });
      } catch(error:any) {}
    } else {
      try {
        await this.permissionService.modifyPermission(permission);
        this.dialogRef.close(permission);
        this.snackBarService.open("Sikeres jogosultság módosítás", "Bezár", { duration: 3000, panelClass: "mat-snackbar-succcess" });
      } catch(error:any) {}
    }
  }

}

