<div class = "content-header">
    <button
        mat-button
        color="primary"
        (click) = "openContentInfoDialog()"
    >
        <mat-icon> info </mat-icon>
        Hogyan működik?
    </button>
    <mat-paginator
        [length]="(numberOfDisplayedLogs$ | async)"
        [pageSize]="pageSizeOptions[0]"
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true"
        (page)="onPageEvent()"
    ></mat-paginator>
</div>

<div
    class="content"
    [ngSwitch]="(practicalModuleLogsDataService.practicalModuleLogsContentState$ | async).stateType"
>
    <!-- Loading state -->
    <div *ngSwitchCase="PracticalModuleLogsListStateType.Loading">
        <div class="loading-container">
            <mat-spinner></mat-spinner>
            <div style="color: lightgrey; font-style: italic;">
                Logok betöltése...
            </div>
        </div>
    </div>

    <!-- Error state -->
    <div *ngSwitchCase="PracticalModuleLogsListStateType.Error">
        <div
            [ngSwitch]="getErrorFromState(practicalModuleLogsDataService.practicalModuleLogsContentState$ | async)"
            class = "error-container"
        >
            <div *ngSwitchCase="PracticalModuleLogsListErrorType.InvalidConfigurationError">
                <div style="margin-bottom: 10px;">
                    Hibás entitás típus és/vagy entitás kulcs konfiguráció.
                </div>
                <div>
                    Entitás típus: {{ practicalModuleLogsDataService.getEntityTypeAsString() }}
                </div>
                <div>
                    Entitás kulcs: {{ practicalModuleLogsDataService.getEntityKeyAsString() }}
                </div>
            </div>
            <span *ngSwitchCase="PracticalModuleLogsListErrorType.PermissionMissing">
                Nincs megfelelő jogosultságod a logok megtekintéséhez.
            </span>
            <span *ngSwitchCase="PracticalModuleLogsListErrorType.InvalidEntityType">
                A beállított entitástípus ({{ practicalModuleLogsDataService.getEntityTypeAsString() }}) nem megfelelő.
            </span>
            <span *ngSwitchCase="PracticalModuleLogsListErrorType.InvalidEntityKey">
                A beállított entitáskulcs ({{ practicalModuleLogsDataService.getEntityKeyAsString() }} ) nem megfelelő.
            </span>
            <span *ngSwitchCase="PracticalModuleLogsListErrorType.IvalidInterval">
                A megadott időintervallum nem megfelelő.
            </span>
            <span *ngSwitchDefault>
                Hiba történt a logok lekérdezése közben.
            </span>
        </div>
    </div>

    <!-- Data state (listing the logs) -->
    <div *ngSwitchCase="PracticalModuleLogsListStateType.Data">
        <app-practical-module-logs-list
            [practicalModuleLogs]="getDataFromState(practicalModuleLogsDataService.practicalModuleLogsContentState$ | async)"
        ></app-practical-module-logs-list>
    </div>
</div>