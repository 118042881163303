import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminRole } from 'src/app/classes/model/admin-user';
import { Permission } from 'src/app/classes/model/permissions';
import { AdminRoleService, RemovedPermissionAffect } from 'src/app/services/admin-roles.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { AdminRoleEditorDialogComponent } from './admin-role-editor-dialog/admin-role-editor-dialog.component';

@Component({
  selector: 'app-admin-roles',
  templateUrl: './admin-roles.component.html',
  styleUrls: ['./admin-roles.component.scss']
})
export class AdminRolesComponent implements OnInit {

  adminRoles:ReadonlyArray<AdminRole> = [];

  isContentLoading:boolean = false;

  constructor(
    private adminRoleService:AdminRoleService,
    private permissionsService:PermissionsService,
    private dialogService:MatDialog,
    private snackBarService:MatSnackBar
  ) { }

  public ngOnInit():void {
    this.adminRoles = this.adminRoleService.getAdminRolesArrayRef();
    this.loadData();
  }

  private async loadData():Promise<void> {
    this.isContentLoading = true;

    try {
      await this.adminRoleService.loadDataIntoCache(); 
      await this.permissionsService.loadDataIntoCache();
    } catch(error:any) {}
    
    this.isContentLoading = false;
  }


  public handleAddButtonClick():void {
    this.dialogService.open(
      AdminRoleEditorDialogComponent,
      {
        data: {
          dialogMode: "add"
        },
        disableClose: true
      }
    );
  }

  public handleEditButtonClick(adminRole:AdminRole):void {
    this.dialogService.open(
      AdminRoleEditorDialogComponent,
      {
        data: {
          dialogMode: "edit",
          adminRole: adminRole
        },
        disableClose: true
      }
    );
  }

  public async handleDeleteButtonClick(adminRole:AdminRole):Promise<void> {
    try {
      let removedPermissionAffects:Array<RemovedPermissionAffect>|null = [];
      if(adminRole.basePermissions.length > 0) {
        removedPermissionAffects = await this.adminRoleService.openConfirmAdminRoleUpdatingDialog(
          "Szerepkör törlése",
          "A bepipált adminok ELVESZTIK a jogosultságukat a szerepkör törlésével!",
          adminRole,
          adminRole.basePermissions
        );
        
        if(removedPermissionAffects === null) {
          return;
        }
      }

      await this.adminRoleService.deleteAdminRole(adminRole.name, removedPermissionAffects);
      this.snackBarService.open("Sikeres admin szerepkör tölrés", "Bezár", { duration: 3000, panelClass: "mat-snackbar-success" });
    } catch(error:any) {}
  }

  
}
