<section class = "logger-section" *ngIf = "logsRef.length > 0">
    <div *ngFor = "let log of logsRef" class = "logger-logs-container" [ngClass] = "getLogClass(log.type)">
        <div class = "logger-log-attributes">
            <div>
                <mat-icon *ngIf = "log.type !== LogType.Simple">
                    {{ getIconNameForType(log.type) }}
                </mat-icon>
            </div>
            <div class = "logger-log-date"> {{ '[' + ( log.date | date: "YYYY.MM.dd. HH:mm:ss" ) + ']' }} </div>
        </div>
        <div class = "logger-log-content"> {{ log.content }} </div>
    </div>
</section>
