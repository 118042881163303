import { Component } from '@angular/core';

@Component({
  selector: 'app-practical-module-logs-page',
  templateUrl: './practical-module-logs-page.component.html',
  styleUrls: ['./practical-module-logs-page.component.scss']
})
export class PracticalModuleLogsPageComponent {

}
