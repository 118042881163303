import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Student } from 'src/app/classes/model/student';
import { StudentService } from 'src/app/services/student.service';

@Component({
  selector: 'app-create-gift-for-user-dialog',
  templateUrl: './create-gift-for-user-dialog.component.html',
  styleUrls: ['./create-gift-for-user-dialog.component.scss']
})
export class CreateGiftForUserDialogComponent implements OnInit {
  giftFG:FormGroup;
  reasonFC:FormControl;
  detailsFC:FormControl;
  packageDefinitionFC:FormControl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{ student:Student },
    private dialogRef:MatDialogRef<CreateGiftForUserDialogComponent>,
    private studentService:StudentService,
    public snackBar:MatSnackBar
  ) { }

  ngOnInit():void {
    this.reasonFC = new FormControl("");
    this.detailsFC = new FormControl("",  [ Validators.required ]);
    this.packageDefinitionFC = new FormControl("", [ Validators.required ]);

    this.giftFG = new FormGroup({
      reasonFC: this.reasonFC,
      detailsFC: this.detailsFC,
      creditsFC: this.packageDefinitionFC
    });
  }

  public async handleCreateGiftButtonClick():Promise<void>  {
    try {
      const gift:any = await this.studentService.createGiftForStudent(this.data.student, this.packageDefinitionFC.value, this.reasonFC.value, this.detailsFC.value);
      this.dialogRef.close(gift);
      this.snackBar.open("Ajándék sikeresen létrehozva a felhasználó részére", "Bezár", { duration: 3000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}
  }

}
