import { Emitter } from "strict-event-emitter";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { MarkerStyle, UnderlyingMarker } from "./marker-types";

// nem felülírható z index sorrend: https://stackoverflow.com/questions/15627325/google-maps-polygon-and-marker-z-index

export type SingleMarkerControllerEvents = {
  changeDirectionAngle: [newAngle: number]
}

export type SingleMarkerOptions = {
  underlyingMarker: BehaviorSubject<UnderlyingMarker>;
  iconStyle: MarkerStyle;
  isDraggable: boolean;
  shouldMapCenterFollowMarker: boolean;
  zIndex: number;
  initialDirectionAngle?: number;
  clickable?: boolean;
};

export class SingleMarkerController extends Emitter<SingleMarkerControllerEvents> {
  private googleMap!: google.maps.Map;

  private eventListeners: google.maps.MapsEventListener[] = [];

  // Single Markerhez tartozó változók
  private renderedMarker!: google.maps.Marker;
  private underlyingMarker!: BehaviorSubject<UnderlyingMarker>;
  private currentMarkerStyle: MarkerStyle;
  public isVisible: boolean = true;

  // Irányszöghöz tartozó változók
  public directionAngle: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );
  public isDirectionAngleVisible: boolean = false;
  public polylineForDirectionAngle: google.maps.Polyline =
    new google.maps.Polyline({
      strokeColor: "black",
    });
  private readonly arrowLineSize: number = 0.00025;
  private readonly arrowHeadSize: number = 0.0001;

  private subscriptions: Subscription[] = [];

  // Szükség van egy láthatatlan markerre amit a nyíl hegyére helyezünk el
  // ennek a segítségével fogjuk mozgatni a nyilat
  private directionAngleRotateHelperMarker!: google.maps.Marker;

  constructor(public singleMarkerOptions: SingleMarkerOptions) {
    super();
    this.singleMarkerOptions.clickable ??= true;
    this.underlyingMarker = this.singleMarkerOptions.underlyingMarker;
    this.currentMarkerStyle = this.singleMarkerOptions.iconStyle;
    this.isDirectionAngleVisible =
      this.singleMarkerOptions.initialDirectionAngle != null;
    this.directionAngle.next(
      this.singleMarkerOptions.initialDirectionAngle ?? 0
    );

    // Change listener
    this.subscriptions.push(
      this.singleMarkerOptions.underlyingMarker.subscribe((value) => {
        this._onChangedUnderlyingMarker();
        this.followIfNeed();
      })
    );

  }

  public setZIndex(newZIndex: number) {
    this.singleMarkerOptions.zIndex = newZIndex;
  }

  public setNewUnderlyingMarker(
    newUnderlyingMarker: BehaviorSubject<UnderlyingMarker>
  ) {
    this.underlyingMarker = newUnderlyingMarker;
    this.update();
  }

  private onRenderedMarkerDrag(event: any) {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const currentUnderlyingMarker = this.underlyingMarker.getValue();
    currentUnderlyingMarker.position = { latitude: lat, longitude: lng };
    this.underlyingMarker.next(this.underlyingMarker.getValue());
  }

  private initMarker() {
    this.renderedMarker = new google.maps.Marker({
      draggable: this.singleMarkerOptions.isDraggable,
      icon: this.currentMarkerStyle.defaultIcon,
      optimized: false,
      clickable: this.singleMarkerOptions.clickable,
    });

    this.directionAngleRotateHelperMarker = new google.maps.Marker({
      draggable: this.singleMarkerOptions.isDraggable,
      optimized: false,

      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 11,
        fillColor: "white",
        strokeWeight: 5,
        strokeColor: "red",
      },
      opacity: 0,
    });

    // EVENT LISTENERS
    this.eventListeners.push(
      this.renderedMarker.addListener("drag", (event: any) => {
        this.onRenderedMarkerDrag(event);
      })
    );

    this.eventListeners.push(
      this.renderedMarker.addListener("dragend", (event: any) => {
        this.onRenderedMarkerDrag(event);
      })
    );

    this.eventListeners.push(
      this.directionAngleRotateHelperMarker.addListener(
        "drag",
        (event: any) => {
          this.directionAngle.next(
            bearingBetweenPoints(
              {
                latitude: this.renderedMarker.getPosition()?.lat()!,
                longitude: this.renderedMarker.getPosition()?.lng()!,
                /*  latitude: this.underlyingMarker.getValue().position.lat,
        longitude: this.underlyingMarker.getValue().position.lng, */
              },
              {
                latitude: this.directionAngleRotateHelperMarker
                  .getPosition()
                  ?.lat()!,
                longitude: this.directionAngleRotateHelperMarker
                  .getPosition()
                  ?.lng()!,
              }
            )
          );
          this.updateDirectionAnglePolyline();
        }
      )
    );

    this.eventListeners.push(
      this.directionAngleRotateHelperMarker.addListener(
        "dragend",
        (event: any) => {
          this.setDirectionAngleHelperMarkerPositionToCurrentArrowHead();
        }
      )
    );
  }

  private setDirectionAngleHelperMarkerPositionToCurrentArrowHead() {
    const headpos = this.calculateArrowHeadPosition(
      this.underlyingMarker.getValue().position,
      this.directionAngle.getValue()
    );
    this.directionAngleRotateHelperMarker.setPosition(headpos);
  }

  public setNewIconStyle(style: MarkerStyle) {
    this.currentMarkerStyle = style;
    this._onChangedUnderlyingMarker();
  }

  public setVisibility(visible: boolean) {
    this.renderedMarker.setMap(!visible ? null : this.googleMap);
    this.polylineForDirectionAngle.setMap(!visible ? null : this.googleMap);
    this.directionAngleRotateHelperMarker.setMap(
      !visible ? null : this.googleMap
    );

    this.isVisible = visible;
  }

  // Lehessen-e megadni markerhez irányszöget?
  public setDirectionAngleEditEnabled(enabled: boolean) {
    if (enabled) {
      this.directionAngleRotateHelperMarker.setMap(this.googleMap);
      this.polylineForDirectionAngle.setMap(this.googleMap);
      this.isDirectionAngleVisible = true;
    } else {
      this.directionAngleRotateHelperMarker.setMap(null);
      this.polylineForDirectionAngle.setMap(null);
      this.isDirectionAngleVisible = false;
    }
    this.update();
  }

  public setOpacity(opacity: number) {
    if (this.renderedMarker == null) return; // Csak akkor lehet hívni ha map-hez lett rendelve a marker
    this.renderedMarker.setOpacity(opacity);
  }

  public getOpacity() {
    return this.renderedMarker.getOpacity();
  }

  public setMarkerFollowEnabled(isEnabled: boolean) {
    this.singleMarkerOptions.shouldMapCenterFollowMarker = isEnabled;
    this.followIfNeed();
  }

  private _onChangedUnderlyingMarker() {
    if (this.googleMap == null || this.renderedMarker == null) return;
    this.update();
  }

  private followIfNeed() {
    if (this.singleMarkerOptions.shouldMapCenterFollowMarker) {
      this.googleMap.panTo({
        lat: this.underlyingMarker.getValue().position.latitude,
        lng: this.underlyingMarker.getValue().position.longitude,
      });
    }
  }

  private update() {
    // Akkor nincs projekció, ha még nem töltődött be a térkép amihez hozzá van rendelve
    if (this.renderedMarker == null || this.googleMap?.getProjection() == null)
      return;

    this.renderedMarker.setZIndex(this.singleMarkerOptions.zIndex);
    const currentPositionOfMarker = {
      lat: this.underlyingMarker.getValue().position.latitude,
      lng: this.underlyingMarker.getValue().position.longitude,
    };

    this.renderedMarker.setPosition(currentPositionOfMarker);
    this.renderedMarker.setIcon(this.currentMarkerStyle.defaultIcon);
    this.setDirectionAngleHelperMarkerPositionToCurrentArrowHead();
    this.updateDirectionAnglePolyline();
  }

  dispose() {
    this.eventListeners.forEach((e) => e.remove());

    this.renderedMarker?.setMap(null);
    this.renderedMarker = null;

    this.polylineForDirectionAngle.setMap(null);
    this.directionAngleRotateHelperMarker.setMap(null);

    this.directionAngle.unsubscribe();
    this.subscriptions.forEach(s => s.unsubscribe());

    this.removeAllListeners();
  }

  setMap(googleMap: google.maps.Map) {
    this.googleMap = googleMap;
    if (this.renderedMarker == null) {
      this.initMarker();
    }

    this.renderedMarker.setMap(this.isVisible ? this.googleMap : null);
    this.directionAngleRotateHelperMarker.setMap(
      this.isDirectionAngleVisible ? googleMap : null
    );
    this.polylineForDirectionAngle.setMap(
      this.isDirectionAngleVisible ? googleMap : null
    );

    const headPosition = this.calculateArrowHeadPosition(
      this.underlyingMarker.getValue().position,
      this.directionAngle.getValue()
    );
    this.directionAngleRotateHelperMarker.setPosition(headPosition);

    // A rendereléshez szükség van a térkép által használt projekcióra
    // a projekció viszont csak a teljes betöltés után érhető el
    // az idle event akkor hívódik meg, amikor a térképen nincs egyáltalán mozgás
    // illetve a betöltés pillanatakor! az addListenerOnce hozzáad egy event listenert a maphez
    // ami az első esemény érkezése után törlődik
    google.maps.event.addListenerOnce(this.googleMap, "idle", () => {
      this.update();
    });
    this.update();
  }

  private rotate(
    sourcePoint: { latitude: number; longitude: number },
    angle: number
  ) {
    const prj = this.googleMap.getProjection()!; // Projekció csak azután lesz, hogy a térkép teljesen betöltődött!
    if (prj == undefined) {
      return { lat: 0, lng: 0 };
    }

    const origin = prj.fromLatLngToPoint({
      lat: sourcePoint.latitude,
      lng: sourcePoint.longitude,
    }); // rotate around first point

    const point = prj.fromLatLngToPoint({
      lat: sourcePoint.latitude + this.arrowLineSize,
      lng: sourcePoint.longitude,
    });

    const rotatedLatLng = prj.fromPointToLatLng(
      this.rotatePoint(point, origin, angle) as any
    );
    return { lat: rotatedLatLng!.lat(), lng: rotatedLatLng!.lng() };
  }

  // https://stackoverflow.com/questions/10518293/google-maps-api-v3-rotate-a-polygon-by-certain-degree
  private rotatePoint(point: any, origin: any, angle: number) {
    const angleRad = (angle * Math.PI) / 180.0;
    return {
      x:
        Math.cos(angleRad) * (point.x - origin.x) -
        Math.sin(angleRad) * (point.y - origin.y) +
        origin.x,
      y:
        Math.sin(angleRad) * (point.x - origin.x) +
        Math.cos(angleRad) * (point.y - origin.y) +
        origin.y,
    };
  }

  private calculateArrowHeadPosition(
    criticalPoint: { latitude: number; longitude: number },
    angle: number
  ) {
    return this.rotate(criticalPoint, angle);
  }

  public getArrowHeadPosition(): { lat: number; lng: number } {
    return this.calculateArrowHeadPosition(
      {
        latitude: this.renderedMarker.getPosition()?.lat(),
        longitude: this.renderedMarker.getPosition()?.lng(),
      },
      this.directionAngle.getValue()
    );
  }

  // Frissíti az irányszöghöz tartozó polyline-t, ez maga a nyíl
  private updateDirectionAnglePolyline() {
    const sourcePointLat = this.renderedMarker.getPosition()?.lat();
    const sourcePointLng = this.renderedMarker.getPosition()?.lng();
    if (!sourcePointLat || !sourcePointLng || !this.isDirectionAngleVisible)
      return;

    // Angle alapján számoljuk ki, hogy a nyíl teteje hova kerülne
    const arrowEndPoint: google.maps.LatLng = new google.maps.LatLng(
      this.getArrowHeadPosition()
    );

    const arrowHeadLeftPoint: google.maps.LatLng = new google.maps.LatLng({
      lat:
        arrowEndPoint.lat() +
        this.arrowHeadSize *
          Math.cos(
            degreesToRadians((this.directionAngle.getValue() + 210) % 360)
          ),
      lng:
        arrowEndPoint.lng() +
        this.arrowHeadSize *
          Math.sin(
            degreesToRadians((this.directionAngle.getValue() + 210) % 360)
          ),
    });

    const arrowHeadRightPoint: google.maps.LatLng = new google.maps.LatLng({
      lat:
        arrowEndPoint.lat() +
        this.arrowHeadSize *
          Math.cos(
            degreesToRadians((this.directionAngle.getValue() + 150) % 360)
          ),
      lng:
        arrowEndPoint.lng() +
        this.arrowHeadSize *
          Math.sin(
            degreesToRadians((this.directionAngle.getValue() + 150) % 360)
          ),
    });

    this.polylineForDirectionAngle.setPath([
      { lat: sourcePointLat, lng: sourcePointLng },
      arrowEndPoint,
      arrowHeadLeftPoint,
      arrowEndPoint,
      arrowHeadRightPoint,
    ]);

    // Végül beállítjuk a zIndex-t a path számára
    this.polylineForDirectionAngle.setOptions({
      zIndex: this.singleMarkerOptions.zIndex,
    });
  }
}

// HELPER FUNCTIONS
function degreesToRadians(degrees: number): number {
  return (degrees * Math.PI) / 180;
}

function radiansToDegrees(radians: number): number {
  return (radians * 180) / Math.PI;
}

function bearingBetweenPoints(
  fromPosition: { latitude: number; longitude: number },
  toPosition: { latitude: number; longitude: number }
): number {
  const startLat = degreesToRadians(fromPosition.latitude);
  const startLng = degreesToRadians(fromPosition.longitude);
  const destLat = degreesToRadians(toPosition.latitude);
  const destLng = degreesToRadians(toPosition.longitude);

  const y = Math.sin(destLng - startLng) * Math.cos(destLat);
  const x =
    Math.cos(startLat) * Math.sin(destLat) -
    Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
  const brng = radiansToDegrees(Math.atan2(y, x));
  return (brng + 360) % 360;
}
