import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommentsOnEntityModuleService } from '../../services/comments-on-entity.service';
import { EntityType } from '../../models/entity-types';

@Component({
  selector: 'app-comment-writer',
  templateUrl: './comment-writer.component.html',
  styleUrls: ['./comment-writer.component.scss']
})
export class CommentWriterComponent implements OnInit, OnDestroy {
  @Input() entityType:EntityType;
  @Input() entityKeyValue:string;

  @ViewChild("messageEditor", { static: true })
  messageEditorElementRef:ElementRef<HTMLDivElement>;

  constructor(
    private commentsOnEntityService:CommentsOnEntityModuleService
  ) { }

  ngOnInit(): void {
    // Add an event listener to send the message on "Enter" keydown
    this.messageEditorElementRef.nativeElement.addEventListener("keydown", this.onMessageEditorKeyDown);
  }

  ngOnDestroy(): void {
    // Remove the keydown event listener
    this.messageEditorElementRef.nativeElement.removeEventListener("keydown", this.onMessageEditorKeyDown);
  }

  public async addCommentToEntity():Promise<void> {
    // Get the message from the message editor
    const message:string = this.getMessageFromMessageEditor();
    // If there is no message, there is nothing to do
    if(message === "") {
      return;
    }

    try {
      await this.commentsOnEntityService.addCommentToEntity(message);
      this.messageEditorElementRef.nativeElement.textContent = "";
    } catch(error:any) {
      console.error(error);
      // Show error
    }
  }

  private getMessageFromMessageEditor():string {
    return this.messageEditorElementRef.nativeElement.textContent;
  }

  private onMessageEditorKeyDown = async (keyboardEvent:KeyboardEvent) => {
    // If the enter is pressed, but the shfit is not
    if(keyboardEvent.shiftKey === false && keyboardEvent.key === "Enter") {
      // Prevent the default behaviour (making a new line)
      keyboardEvent.preventDefault();
      // Add the message in the message editor to the entity
      await this.addCommentToEntity();
    }
  }

}
