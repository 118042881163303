import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { BackendService } from './common/backend.service';
import { Injectable } from '@angular/core';
import { stringify } from 'querystring';
@Injectable({
  providedIn: 'root'
})
export class PracticePathCutReleasedVideoService {
  constructor(private backendService: BackendService, private errorHandlerService: HttpErrorHandlerService) { }

  private getApiPath() {
    return "/api/admin/practice_path/cut_released_video";
  }

  public async cutAndMakeNewPracticePath(sourcePracticePathUuid: string, newPracticePathName: string, startMs: number, endMs: number,startCloudInstance: boolean): Promise<string | undefined> {
    try {
      const requestBody = {
        sourcePracticePathUuid: sourcePracticePathUuid,
        startMs: startMs,
        endMs: endMs,
        newPracticePathName: newPracticePathName,
        startCloudInstance: startCloudInstance
      };

      const result: { taskId: string } = await this.backendService.callApi(this.getApiPath(), "POST", requestBody);

      return result.taskId ?? undefined;
    } catch (error: any) {
      console.error(error);
      this.errorHandlerService.handleError(error, "Hiba a vágás indítása közben");
    }
  }
}
