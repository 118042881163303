import { Injectable } from '@angular/core';
import { Question, questionSorter } from '../classes/model/question';
import { MediaType, mediaTypeToString } from '../classes/media';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { HttpResponseData } from '../classes/http-communication';
import { DataCacherService } from './data-cacher-service';
import { matchingPropertyPredicate } from '../functions/misc';
import { Permission, PermissionString } from '../classes/model/permissions';

/**
 * @description THis class provices services for the question magangement. It's fuctions creates HTTP requests and manipulates the data in the remote
 * database.
 */
@Injectable({
  providedIn: 'root'
})
export class QuestionService implements DataCacherService {
  /** The URL for the admin questions API */
  private readonly questionsApiUrlFragment:string = "/api/admin/question";
  /** Question array to store the questions */
  private questions:Array<Question>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.TheoryRead ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService,
  ) {
    this.questions = new Array<Question>();
  }

  public getQuestionArrayRef():ReadonlyArray<Readonly<Question>> {
    return this.questions;
  }

  /**
   * Gets the list of the questions from the remote server. The list contains all questions which can be found in the database at the query's time.
   *
   * @returns {Promise<Array<Question>>} a Promise which contains the questions in a Question array
   */
  public async fetchQuestions():Promise<Array<Question>> {
    let questions:Array<Question> = [];
    try {
      const response:HttpResponseData<Array<Question>> = await this.backendService.callApi(this.questionsApiUrlFragment, "GET");
      questions = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a kérdések lekérdezése közben.");
    }

    return questions;
  }

  /**
   * Saves the locally built question in the remote database. If the insertion was successful, the database sends back the question's uuid, and the
   * media's S3 bucket url, if a media was provided.
   *
   * @param {Partial<Question>} newQuestion - the new question object without the uuid and the mediaUrl
   *
   * @returns {Promise<boolean>} a Promise which contains a boolean value to indicate the succession of the operation
   */
  public async addQuestion(newQuestion:Partial<Question>, mediaType:MediaType, questionMedia?:any):Promise<void> {
    const httpBody:Object = {
      item: newQuestion,
      mediaType: mediaTypeToString(mediaType)
    }

    const fileMap:Map<string, File> = new Map<string, File>();
    questionMedia && fileMap.set("question-media", questionMedia);

    try {
      const response:HttpResponseData<Partial<Question>> = await this.backendService.callApi(this.questionsApiUrlFragment, "PUT", httpBody, fileMap);
      Object.assign<Partial<Question>, Partial<Question>>(newQuestion, response.data);
      this.questions.push(newQuestion as Question);
      this.questions.sort(questionSorter);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a kérdés létrehozása közben.");
    }
  }

  /**
   * Removes a question from the remote database with the given question uuid.
   *
   * @param {string} uuid - the question's uuid
   *
   * @returns {Promise<boolean>} a Promise which contains a boolean value to indicate the succession of the operation
  */
  public async deleteQuestion(questionUuid:string):Promise<void> {
    const apiUrl:string = this.questionsApiUrlFragment + "/" + questionUuid;

    try {
      await this.backendService.callApi(apiUrl, "DELETE");
      this.questions.removeItems(matchingPropertyPredicate("uuid", questionUuid));
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a kérdés törlése közben.");
    }
  }

  /**
   * Modifies a question's attributes in the remote database with the given question uuid. If a media is provided, the database swaps the old media
   * with the new one.
   *
   * @param {Question} modifiedQuestion - the question's new attributes
   * @param {Media} media - new media for the question
   *
   * @returns {Promise<boolean>} a Promise which contains a boolean value to indicate the succession of the operation
  */
  public async modifyQuestion(modifiedQuestion:Question, mediaType:MediaType, questionMedia?:any):Promise<void> {
    const httpBody:Object = {
      item: modifiedQuestion,
      mediaType: mediaTypeToString(mediaType)
    }

    const fileMap:Map<string, File> = new Map<string, File>();
    questionMedia && fileMap.set("question-media", questionMedia);

    try {
      const response:HttpResponseData<Partial<Question>> = await this.backendService.callApi(this.questionsApiUrlFragment, "POST", httpBody, fileMap);
      Object.assign<Partial<Question>, Partial<Question>>(modifiedQuestion, response.data);
      this.questions.replaceItems(matchingPropertyPredicate("uuid", modifiedQuestion.uuid), modifiedQuestion);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a kérdés módosítása közben.");
    }
  }

  public async loadDataIntoCache(): Promise<void> {
    const questions:Array<Question> = await this.fetchQuestions();
    this.questions.replaceArrayElements(questions).sort(questionSorter);
  }

  public clearCachedData():void {
    this.questions.length = 0;
  }

  public getRequiredPermissionsForDataLoading(): ReadonlyArray<PermissionString> {
    return this.requiredPermissionsForDataLoading;
  }

  public getNameOfCachedData(): string {
    return "Kérdések";
  }

}
