import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Settings, SettingsService } from 'src/app/services/common/settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  loggingApiUrlFC:FormControl;

  toogleSubscription:Subscription;

  constructor(
    private settingsService:SettingsService,
    private snackBarService:MatSnackBar
  ) { }

  ngOnInit(): void {
    this.loggingApiUrlFC = new FormControl(this.settingsService.getSettingsValue(Settings.apiLogging, false));
  }

  public handleSaveButtonClick():void {
    this.settingsService.setSettingsValue<boolean>(Settings.apiLogging, this.loggingApiUrlFC.value);
    this.snackBarService.open("Beállítások elmentve", "Bezár", { duration: 3000 });
  }

}
