import { Injectable, OnDestroy } from "@angular/core";
import { AnonymizerTaskService } from 'src/app/services/anonymizer-task.service';
import { BehaviorSubject, combineLatestWith, map, Observable } from "rxjs";
import { AnonymizerTaskReference } from "src/app/classes/model/anonymizer-task";
import { CachedPracticePath, PracticePathCacherService } from '../../../../../../services/cacher-services/practice-path-cacher.service';
import { PracticeCityCacherService } from "../../../../../../services/cacher-services/practice-city-cacher.service";
import { CachedPracticePathFields } from "../model/resolved-practice-path";

@Injectable()
export class AnonymizerTasksPageService implements OnDestroy {
  private anonymizerTasksSubject:BehaviorSubject<Array<AnonymizerTaskReference>|null>;

  private readonly anonymizerTaskUpdateIntervalTimeInMs:number = 10000; // 10 sec
  private anonymizerTaskUpdateIntervalId:NodeJS.Timeout|null;

  public readonly pageSize:number = 15;
  private pageIndexSubject:BehaviorSubject<number>;

  private displayedAnonymizerTasks:Observable<Array<AnonymizerTaskReference>|null>;

  public listFinishedTasks:boolean;

  constructor(
    private anonymizerTaskService:AnonymizerTaskService,
    private practicePathCacherService:PracticePathCacherService,
    private practiceCityResolvingCacherService:PracticeCityCacherService
  ) {
    this.anonymizerTasksSubject = new BehaviorSubject<Array<AnonymizerTaskReference>|null>(null);
    this.anonymizerTaskUpdateIntervalId = null;
    this.pageIndexSubject = new BehaviorSubject<number>(0);

    this.displayedAnonymizerTasks = this.anonymizerTasksSubject.pipe(
      combineLatestWith(this.pageIndexSubject),
      map<[AnonymizerTaskReference[], number], AnonymizerTaskReference[]>(([ anonymizerTasks, pageIndex ]) => {
        // If the anonymization tasks null, return null
        if(anonymizerTasks === null) {
          return null;
        }

        // Get a slice of the anonyimizer tasks with the given pagesize and index
        const displayedAnonymizerTasks:Array<AnonymizerTaskReference> = anonymizerTasks.slice(
          pageIndex * this.pageSize, (pageIndex + 1) * this.pageSize);

        // Start a resolving attempt for the practice path references in the given slice
        const practicePathUuids:Array<string> = displayedAnonymizerTasks.map(
          (anonymizerTaskReference:AnonymizerTaskReference) => { return anonymizerTaskReference.metadata.practicePathUuid; }
        );

        // Start the resolving of the practice paths and their cities
        // Note: We don't wait the resolving here, we want to display the tasks immediately
        this.fetchPracticePathsAndCities(practicePathUuids);

        // Return the anonymizer tasks slice
        return displayedAnonymizerTasks;
      })
    );
  }

  public ngOnDestroy():void {
    // If there was an ongoing interval, clear it
    if(this.anonymizerTaskUpdateIntervalId) {
      clearInterval(this.anonymizerTaskUpdateIntervalId);
    }
  }

  public async initalize(listFinishedTasksOnInit:boolean):Promise<void> {
    // Set if we want to se the active or the finished tasks based on the parameter
    this.listFinishedTasks = listFinishedTasksOnInit;

    try {
      // Fetch and udate the an
      await this.updateAnonymizerTasks();
    } catch(error:any) {
      // If an error happened throw a AnonymizerTasksPageInitializationError with it
      throw new AnonymizerTasksPageInitializationError(AnonymizerTasksPageInitialization.Error, error);
    }

    // Launch the interval which fetches updates the anonymizer tasks
    this.launchFetchInterval(this.anonymizerTaskUpdateIntervalTimeInMs);
  }

  /**
   * Gets an observable for the (entire) fetched anonimizer tasks.
   */
  public get anonymizerTasks$():Observable<Array<AnonymizerTaskReference>|null> {
    return this.anonymizerTasksSubject.asObservable();
  }

  /**
   * Gets an observable for the displayed anonymizer tasks (based on the pagesize and the pageindex).
   */
  public get displayedAnonymizerTasks$():Observable<Array<AnonymizerTaskReference>|null> {
    return this.displayedAnonymizerTasks;
  }

  /**
   * Launch the interval which fetches updates the anonymizer tasks in every `intervalTimeInMs` milliseconds.
   */
  private launchFetchInterval(intervalTimeInMs:number):void {
    // If there is already an ongoing interval, remove it first
    if(this.anonymizerTaskUpdateIntervalId) {
      clearInterval(this.anonymizerTaskUpdateIntervalId);
    }

    // Set the new interval, and save the id
    this.anonymizerTaskUpdateIntervalId = setInterval(
      async () => {
        // In every cycle update the anonymizer tasks
        await this.updateAnonymizerTasks();
      },
      intervalTimeInMs
    );
  }

  private async fetchPracticePathsAndCities(practicePathUuids:Array<string>):Promise<void> {
    // Resolve the practice paths
    const cachedPracticePaths:Array<CachedPracticePath> = await this.practicePathCacherService.fetchAndCachePracticePaths(
      practicePathUuids
    );
    // Resove the cities of the resolved practice paths
    const practiceCityUuids:Array<string> = cachedPracticePaths.filter(
      cachedPracticePaths => cachedPracticePaths.isExists == true
    ).map(
      cachedPracticePaths => cachedPracticePaths.practicePath.cityUuid
    );
    await this.practiceCityResolvingCacherService.fetchAndCachePracticeCitys(practiceCityUuids);
  }

  /**
   * Updates the anonymizer tasks. It fetches the actual (active or finished) anonymizer tasks based on the service's
   * `listFinishedTasks` value.
   */
  public async updateAnonymizerTasks():Promise<void> {
    const anonymizerTasks:Array<AnonymizerTaskReference> = await this.anonymizerTaskService.fetchAnonymizerTaskReferences(
      this.listFinishedTasks
    );
    anonymizerTasks.sort(AnonymizedTaskSorter.sortByCreatedDesc)
    this.anonymizerTasksSubject.next(anonymizerTasks);
  }

  /**
   * Set the page index to the given value. Note: the page index cannot be set bigger, than the biggest
   * possible index based on the actual stored datas.
   *
   * @param index the new page index
   */
  public setPageIndex(index:number):void {
    if(index > Math.floor((this.anonymizerTasksSubject.value?.length ?? 0) / this.pageSize)) {
      return;
    }

    this.pageIndexSubject.next(index);
  }

  /**
   * Update the listed (active or finished) anonymizer tasks' type.
   *
   * @param listFinishedTasks true if the finsihed tasks should be listed, false otherwise
   */
  public async updateListedTaskType(listFinishedTasks:boolean):Promise<void> {
    this.listFinishedTasks = listFinishedTasks;
    try {
      await this.updateAnonymizerTasks();
    } catch(error:any) {
      console.error(error);
    }
  }

}

export enum AnonymizerTasksPageInitialization {
  Loading,
  Error,
  Ready
}

export class AnonymizerTasksPageInitializationError {
  initStatus:AnonymizerTasksPageInitialization;
  error:any;

  constructor(initStatus:AnonymizerTasksPageInitialization, error?:any) {
    this.initStatus = initStatus;
    this.error = error;
  }
}

type AnonymizedTaskSorterFunction = (
  anonymizerTaskReference1:AnonymizerTaskReference,
  anonymizerTaskReference2:AnonymizerTaskReference
) => number;

class AnonymizedTaskSorter {
  public static sortByCreatedDesc:AnonymizedTaskSorterFunction = (
    anonymizerTaskReference1:AnonymizerTaskReference,
    anonymizerTaskReference2:AnonymizerTaskReference
  ) => {
    return anonymizerTaskReference2.taskCreationTimestamp - anonymizerTaskReference1.taskCreationTimestamp;
  }
}
