import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomValidators } from 'src/app/classes/custom-validators';
import { Vendor } from 'src/app/classes/model/vendor';
import { VendorService } from 'src/app/services/vendor.service';

export type CreateVendorGiftDialogInput = {
  vendor:Vendor
};

@Component({
  selector: 'app-create-vendor-gift-dialog',
  templateUrl: './create-vendor-gift-dialog.component.html',
  styleUrls: ['./create-vendor-gift-dialog.component.scss']
})
export class CreateVendorGiftDialogComponent implements OnInit {
  giftFG:FormGroup;
  reasonFC:FormControl;
  messageFC:FormControl;
  packageDefinitionFC:FormControl;
  quantityFC:FormControl;

  giftCreatingInProgress:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{ vendor:Vendor },
    private vendorService:VendorService,
    public snackBar:MatSnackBar,
    private dialogRef:MatDialogRef<CreateVendorGiftDialogComponent>
  ) { }

  public static open(dialogService:MatDialog, data:CreateVendorGiftDialogInput):MatDialogRef<CreateVendorGiftDialogComponent, void> {
    return dialogService.open<
      CreateVendorGiftDialogComponent,
      CreateVendorGiftDialogInput,
      void
    >(
      CreateVendorGiftDialogComponent,
      {
        data: data
      }
    );
  }

  ngOnInit():void {
    this.reasonFC = new FormControl("");
    this.messageFC = new FormControl("",  [ Validators.required ]);
    this.packageDefinitionFC = new FormControl("", [ Validators.required ]);
    this.quantityFC = new FormControl(1, [ Validators.required, Validators.min(1), CustomValidators.wholeNumber ])

    this.giftFG = new FormGroup({
      reasonFC: this.reasonFC,
      messageFC: this.messageFC,
      packageDefinitionFC: this.packageDefinitionFC,
      quantityFC: this.quantityFC
    });
  }

  public async handleCreateGiftButtonClick():Promise<void>  {
    this.giftCreatingInProgress = true;

    try {
      await this.vendorService.createGiftForVendor(this.data.vendor, this.packageDefinitionFC.value, this.reasonFC.value, this.messageFC.value, this.quantityFC.value);
      this.dialogRef.close();
      this.snackBar.open("Ajándék sikeresen létrehozva a viszonteladó részére", "Bezár", { duration: 3000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}

    this.giftCreatingInProgress = false;
  }

}
