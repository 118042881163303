import { Injectable } from '@angular/core';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class AnonymizerService {
  private readonly addSlaveToSystemApiPath:string = "/api/admin/anonymizer/add_slave_to_system";

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) { }

  /**
   * Requests to add a slave to the anonymizer system.
   */
  public async requestAddSlaveToSystem():Promise<void> {
    try {
      await this.backendService.callApi(this.addSlaveToSystemApiPath, "POST");
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a slave hozzáadásaközben.");
    }
  }

}
