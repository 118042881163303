<mat-card class = "questiongroup-card">
    <div class = "questiongroup-container">
        <div class = "questiongroup-info">
            <div class = "questiongroup-name">
                {{ questionGroup.name }}
                <mat-icon *ngIf = "questionGroup.isHighlighted"> star_rate </mat-icon>
            </div>
            <div>
                <b>Kérdések száma a csoportban:</b> {{ questionGroup.questions.length }} db
            </div>
        </div>
        <div class = "questiongroup-list-questions">
            <button mat-mini-fab color = "primary" [routerLink] = "'/dashboard/actual-questionbank/questions'" [queryParams] = "getQueryParam()">
                <mat-icon> list </mat-icon>
            </button>
        </div>
        <div class = "questiongroup-edit">
            <button mat-mini-fab color = "primary"  (click) = "openQuestionGroupModifyDialog()">
                <mat-icon> edit </mat-icon>
            </button>

        </div>
    </div>
    <div class = "questiongroup-category-list">
        <mat-chip-listbox>
            <mat-chip-option *ngFor = "let category of assignedCategories" color = "primary" [disableRipple] = "true" [selectable] = "false">
                {{ category.name }}
            </mat-chip-option>
        </mat-chip-listbox>
    </div>
</mat-card>
