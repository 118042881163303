<table class = "tasks-table">
    <tr class = "table-header">
        <th style = "width: 30px;"></th>
        <th style = "width: 130px; text-align: start;"> Elindítva </th> 
        <th style = "width: 60px; text-align: center;"> Státusz </th> 
        <th style = "width: 120px; text-align: center;"> Logok </th> 
        <th style = "width: 160px; text-align: start;"> Vizsgahelyszín </th> 
        <th style = "text-align: start;"> Útvonal név </th> 
        <th style = "width: 80px; text-align: start;"> Útv. típus </th> 
        <th style = "width: 120px; text-align: start;"> Útv. azonosító </th> 
    </tr>
    <tr
        *ngFor = "let anonymizerTask of (anonymizerTasksPageService.displayedAnonymizerTasks$ | async)"
    >
        <td>
            <mat-icon
                [matTooltip] = "'taskId: ' + anonymizerTask.taskId"
            >
                info
            </mat-icon>
        </td>
        <td>
            {{ anonymizerTask.taskCreationTimestamp | date: "YYYY-MM-dd HH:mm" }}
        </td> 
        <td style = "text-align: center;">
            <span *ngIf = "anonymizerTask.isFinished"> Kész </span>
            <span *ngIf = "!anonymizerTask.isFinished"> {{ anonymizerTask.progress }}% </span>
        </td> 
        <td style = "text-align: center;">
            <button
                (click) = "onOpenAnonymizerTaskLogsButtonClick(anonymizerTask.taskId)"
                class = "open-logs-button"
            >
                Megnyitás
            </button>
        </td>
        <td>
            
            <ng-container
                *ngIf = "isPracticeCityFetchingInProgressOfTask(anonymizerTask)
                    then practiceCityFetched;
                    else practiceCityFetchingInProgress"
            >
            </ng-container>
            
            <ng-template #practiceCityFetched>
                <div
                    *ngIf = "
                        practiceCityCacherService.getPracticeCityFromCache(
                            getCityUuidOfAnonymizerTask(anonymizerTask)
                        )?.practiceCity as practiceCity
                        else practiceCityCannotBeResolved"  
                >
                    <a
                        class = "link-to-practice-city"
                        [routerLink] = "getRelativeRouterLinkToPracticeCity(practiceCity.uuid)"
                    >
                        {{ practiceCity.city }}
                    </a>
                </div>
                <ng-template #practiceCityCannotBeResolved>
                    <span class = "non-resolvable-city-text">
                        Nem feloldható 😔
                    </span>
                </ng-template>
            </ng-template>

            <ng-template #practiceCityFetchingInProgress>
                <mat-spinner
                    diameter = "16"
                    strokeWidth = "3"
                ></mat-spinner>
            </ng-template>

        </td>
        <td>
            <ng-container
                *ngIf = "practicePathCacherService.isPracticePathCached(anonymizerTask.metadata.practicePathUuid);
                    then practicePathResolved;
                    else practicePathResolvingInProgress"
            >
            </ng-container>
            
            <ng-template #practicePathResolved>
                <div
                    *ngIf = "
                        practicePathCacherService.getPracticePathFromCache(
                            anonymizerTask.metadata.practicePathUuid
                        )?.practicePath as practicePath;
                        else practicePathCannotBeResolved"  
                >
                    <a
                        class = "link-to-practice-path"
                        [routerLink] = "getRelativeRouterLinkToPracticePath(practicePath.cityUuid, practicePath.uuid)"
                    >
                        {{ practicePath.name }}
                    </a>
                </div>
                <ng-template #practicePathCannotBeResolved>
                    <span class = "non-resolvable-path-text">
                        Nem feloldható 😔
                    </span>
                </ng-template>
            </ng-template>

            <ng-template #practicePathResolvingInProgress>
                <mat-spinner
                    diameter = "16"
                    strokeWidth = "3"
                ></mat-spinner>
            </ng-template>
           
        </td>
        <td>
            <span
                *ngIf = "
                    practicePathCacherService.getPracticePathFromCache(
                        anonymizerTask.metadata.practicePathUuid
                    )?.practicePath as practicePath"
                class = "chip-item"
                [style.background-color] = "practicePath.isFullPracticePath ? 'lightblue' : 'lightgreen'"
            >
                {{ practicePath.isFullPracticePath ? "hosszú" : "rövid" }}
            </span>
        </td>
        <td>
            <div
                *ngIf = "
                practicePathCacherService.getPracticePathFromCache(
                    anonymizerTask.metadata.practicePathUuid
                )?.practicePath as practicePath"
            >
                <span
                    *ngIf = "!practicePath.isFullPracticePath"
                    class = "chip-item"
                    style = "background-color: aquamarine;"
                >
                    ID: {{ practicePath.autoIncrementId }}
                </span>
                <span
                    *ngIf = "practicePath.isFullPracticePath"
                    class = "chip-item"
                    style = "background-color: rgb(183, 142, 88);"
                >
                    Vizsgatétel szám: {{ practicePath.examPathNumber + 1 }}
                </span>
            </div>
            
        </td>
    </tr>
</table>