<div style="height: 100%; display: flex; flex-direction: column">
  <app-icon-flow
    (onTapIcon)="onTapIconInIconFlow($event)"
    [selectedIconIndexes]="selectedIconIndexes"
    [iconUrls]="this.practiceIconUrls"
  >
  </app-icon-flow>

  <app-path-icon-recorder
    style="display: block; margin-top: 30px; margin-bottom: 20px"
    [iconRecorderService]="iconRecorderService"
  >
  </app-path-icon-recorder>

  <mat-checkbox
    #isShowingCheckbox
    style="margin-bottom:10px; display:inline-block;"
    [checked]="
      this.globalEditorService?.pathIconMapController?.isVisible ?? false
    "
    (change)="
      this.globalEditorService.pathIconMapController.setVisibility(
        isShowingCheckbox.checked
      )
    "
    color="primary"
    >Útvonal ikonok mutatása a térképen</mat-checkbox
  >

  Aktív útvonal ikonok ({{this.getCountOfActivePathIcon()}})
  <div class="path-icons-container">
    <div
      *ngFor="
        let pathIcon of this.globalEditorService.pathIconAssignments$;
        let i = index
      "
    >
      <app-path-icon-card
        (onTapIcon)="changeMapPositionToBeginningOfIconPath(pathIcon)"
        (onMouseEnter)="onHoverIconPathCard(pathIcon)"
        (onMouseLeave)="onUnHoverIconPathCard(pathIcon)"
        [isActive]="isSpecificPathIconActive(pathIcon)"
        (onTapDelete)="onTapDelete(pathIcon)"
        [pathIcon]="pathIcon"
        [id]="i"
      ></app-path-icon-card>
    </div>
    <div
      *ngIf = "globalEditorService.pathIconAssignments$.length === 0"
      class = "no-path-icons"
    >
      Jelenleg nincs felvéve egyetlen útvonal ikon sem.
      <span *ngIf = "selectedIconIndexes.length === 0">
        Kattints a fent található ikon listára, hogy elindítsd az útvonak ikon felvéltelt.
      </span>
    </div>
  </div>
</div>
