<div class = "title">
    {{ data.city.city }} tartalomfelelősei
</div>
<div class = "responsilbes-list-container">
    <div *ngFor = "let assignedResponsible of assignedResponsibles" class = "responsible-card">
        <div>
            {{ assignedResponsible.username }}
        </div>
        <div>
            <app-icon-button
                [icon] = "'delete'"
                [disabled] = "assignedResponsible.uuid === ownUuid"
                (click) = "unassignResponsible(assignedResponsible.uuid)"
            ></app-icon-button>
        </div>
    </div>
    <div *ngIf = "assignedResponsibles.length === 0" class = "no-responsible-for-city">
        Jelenleg nincs felvéve egyetlen felelős sem a vizsgahelyszínhez.
    </div>
</div>

<div class = "add-responsible-container">
    <div>
         <mat-form-field appearance='outline' style = "width: 100%;">
            <mat-label>Felelős</mat-label>
            <mat-select [formControl] = "responsibleSelectFC" >
                <mat-option *ngFor = "let responsible of unassignedResponsibles" [value] = "responsible.uuid">
                    {{ responsible.username }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div style = "justify-self: end;">
        <button
            mat-raised-button
            [disabled] = "responsibleSelectFC.value === ''"
            (click) = "handlaAssignResponsibleButtonClick()"
        >
            Hozzáad
        </button>
    </div>
</div>
<mat-dialog-actions align = "end">
    <button mat-raised-button mat-dialog-close>
        Bezár
    </button>
</mat-dialog-actions>
