<div class = "title">
    Érintési pont módosítása
</div>

<div style = "margin-bottom: 20px;">
    <!-- Name -->
    <div class = "subtitle">
        Név
    </div>
    <form>
        <mat-form-field appearance='outline' style = "width: 100%;">
            <mat-label>Érintési pont neve</mat-label>
            <textarea matInput [formControl] = "nameFC" class = "node-name-textarea"></textarea>
            <mat-error *ngIf = "nameFC.hasError('required')">Kötelező mező!</mat-error>
            <mat-error *ngIf = "!nameFC.hasError('required') && nameFC.hasError('maximalLineCount')">A név nem lehet 2 sornál több!</mat-error>
        </mat-form-field>
    </form>

    <div>
        <app-media-form
            [formControlRef] = "imageFC"
            [previewEnabled] = "true"
            [acceptableFileTypes] = "[ 'image/png', 'image/jpeg' ]"
            [defaultPreviewMedia] = "data.node.imageUrl ?? ''"
            [previewWidth] = "300"
            [previewHeight] = "300 * (9/16)"
        ></app-media-form>
    </div>
</div>

<!-- Actions -->
<div style = "text-align: end; margin-top: 20px;">
    <button
        mat-raised-button
        color = "primary"
        [disabled] = "!nameFC.value || nameFC.hasError('maximalLineCount')"
        (click) = "handleSaveButtonClick()"
        mat-dialog-close
    >
        Módosítás
    </button>
</div>
