<div class="main-flex-container">
  <!-- Kritikus pontok hozzáadása -->

  <div
    style="
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    "
  >
    <button
      (click)="onTapAddCriticalPoint()"
      color="primary"
      mat-raised-button
      class="add-critical-point-button"
    >
      Kritikus pont hozzáadása
      <mat-icon style="margin-left: 6px">add_circle</mat-icon>
    </button>

    <app-comments-on-entity-dialog-opener-button
      [entityType]="'practice_path'"
      [entityKeyValue]="this.globalPracticePathEditor.practicePath.uuid"
      [dialogTitle]="'Megjegyzések az útvonalhoz'"
    ></app-comments-on-entity-dialog-opener-button>

    <div style="display: flex; flex-direction: column">
      <mat-checkbox
        [(ngModel)]="this.globalPracticePathEditor.shouldStopAtCriticalPoint"
        (change)="onChangeCriticalPointStopSettings()"
        color="primary"
        style="margin-right: 5px"
      >
        Kritikus pontnál megállás
      </mat-checkbox>

      <mat-checkbox
        [(ngModel)]="this.globalPracticePathEditor.isNarrationEnabled"
        color="primary"
        style="margin-right: 5px"
      >
        Kritikus pont felolvasás
      </mat-checkbox>
    </div>
  </div>

  <!-- Kritikus pontok listázása -->
  <span class="critical-point-list-title">
    Útvonalhoz rendelt kritikus pontok</span
  >
  <div #criticalPointList class="critical-point-list">
    <div
      [ngClass]="{
        'active-critical-point':
          this.globalPracticePathEditor.currentlyActiveCriticalPoint.getValue()
            ?.uuid == assignment.criticalPointUuid
      }"
      class="critical-point-card"
      *ngFor="
        let assignment of this.globalPracticePathEditor
          .criticalPointAssignments$;
        trackBy: trackAssignmentByUuid;
        let i = index
      "
    >
      <div class="critical-point-card-header">
        <div style="display: flex; column-gap: 10px">
          <div
            (click)="onTapCriticalPointTitle(assignment.criticalPointUuid)"
            class="critical-point-card-title"
          >
            {{ i + 1 }}.
            {{
              this.getCriticalPointFromUuid(assignment.criticalPointUuid).title
            }}
          </div>
          <div
            *ngIf="
              getCriticalPointFromUuid(assignment.criticalPointUuid).isVerified
            "
          >
            <img
              src="assets/pictures/png/verified.png"
              class="verified-badge"
            />
          </div>
        </div>

        <div class="critical-point-card-actions-container">
          <span
            *ngIf="
              getCriticalPointFromUuid(assignment.criticalPointUuid).isLocal
            "
            matTooltip="Lokális kritikus pont"
          >
            🏡
          </span>
          <mat-icon
            (click)="openCriticalPointAssignmentLogs(assignment)"
            style="cursor: pointer"
            >assignment</mat-icon
          >
          <mat-icon
            (click)="
              onTapEditAssignment(
                getCriticalPointFromUuid(assignment.criticalPointUuid),
                assignment
              )
            "
            style="cursor: pointer"
            >edit</mat-icon
          >
          <mat-icon
            class="delete-icon"
            (click)="
              onTapRemoveAssignment(
                getCriticalPointFromUuid(assignment.criticalPointUuid),
                assignment
              )
            "
            style="cursor: pointer"
            >delete</mat-icon
          >
          <mat-icon
            class="info-icon"
            [matTooltip]="
              'assignment:' +
              assignment.uuid +
              ' 💥crit: ' +
              assignment.criticalPointUuid
            "
            >info</mat-icon
          >
        </div>
      </div>

      <span>
        {{
          this.getCriticalPointFromUuid(assignment.criticalPointUuid)
            .description
        }}
      </span>
      <div
        style="margin-top: 10px; display: flex; justify-content: space-between"
      >
        <!-- Megállási pontok a kritikus pont kártyán -->
        <div>
          <div
            (click)="
              onTapEditAssignment(
                getCriticalPointFromUuid(assignment.criticalPointUuid),
                assignment
              )
            "
            *ngIf="getActiveStopPointsOfAssignment(assignment).length == 0"
            class="critical-point-with-zero-active-stop-point-text-container"
          >
            Megállási pont beállítása
          </div>

          <div
            style="margin-bottom: 4px"
            *ngFor="let stopPoint of assignment.stopPoints"
          >
            <span
              *ngIf="stopPoint.isActive"
              (click)="onTapStopPointText(stopPoint.stopTimeInMs)"
              class="stop-time-text"
            >
              <span>
                {{ stopPoint.stopTimeInMs | msToTimeString }}
              </span>
              <span *ngIf="stopPoint.focusSettings"> 🎯 </span>
            </span>
          </div>
        </div>
        <div style="align-self: flex-end">
          <button
            class="toggle-verification-button"
            [class.is-verified]="
              getCriticalPointFromUuid(assignment.criticalPointUuid).isVerified
            "
            [disabled]="
              isVerificationStatusChangeinProgress(assignment.uuid) ||
              hasUserCriticalPointVerificationChangePermission() == false
            "
            [class.button-loading]="
              isVerificationStatusChangeinProgress(assignment.uuid)
            "
            (click)="onTapCriticalPointVerificationStatusButton(assignment)"
          >
            {{
              getCriticalPointFromUuid(assignment.criticalPointUuid).isVerified
                ? "Hitelesítés levétel"
                : "Hitelesít"
            }}
          </button>
        </div>
      </div>
    </div>
    <div
      *ngIf="globalPracticePathEditor.criticalPointAssignments$.length === 0"
      class="no-critical-points"
    >
      Az útvonalkoz még nincsenek hozzárendelt kritikus pontok. Kattints a
      térképre a kritikus pont jelzőjére, hogy felvégy egy létező, vagy a
      "Kritikus pont hozzáadása" gombra, hogy létrehozz egy új kritikus pontot.
    </div>
  </div>
</div>
