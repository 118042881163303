import { MatTabChangeEvent } from '@angular/material/tabs';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export class TabScrollPositionRecoveryService{
  private savedScrollPositions: Map<string, number> = new Map<string, number>();
  public onMatTabChange = new Subject<MatTabChangeEvent>();
  public mainScrollContexts  = new Map<string, HTMLElement>();


  constructor(){
    this.onMatTabChange.subscribe(matTabChange => {
      const id = matTabChange.index.toString();
      if(this.mainScrollContexts.get(id) == null) return;

      this.mainScrollContexts.get(matTabChange.index.toString()).scrollTop = this.getLastSavedScrollPosition(matTabChange.index.toString());
    });
  }

  saveScrollPositonFor(tabName: string, scrollPosition: number){
    this.savedScrollPositions.set(tabName, scrollPosition);
  }

  getLastSavedScrollPosition(tabName: string){
    return this.savedScrollPositions.get(tabName) ?? 0;
  }

  createEventListenerForMainScrollContextInTab(tabName: string, mainScrollContext: HTMLElement){
    mainScrollContext.addEventListener('scroll', event => {
      this.saveScrollPositonFor(tabName, mainScrollContext.scrollTop);
    });
    this.mainScrollContexts.set(tabName, mainScrollContext);
  }

  dispose(){
    this.mainScrollContexts.forEach(value => value.removeAllListeners());
  }


}
