import { PracticeCityZone } from './../../../../classes/model/practice-city-zone';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { WrappedZoneOnMap } from '../../zone-map-controller';
import { PracticePath } from 'src/app/classes/model/practice-path';

@Component({
  selector: 'app-city-zone-card',
  templateUrl: './city-zone-card.component.html',
  styleUrls: ['./city-zone-card.component.scss']
})
export class CityZoneCardComponent implements OnInit {
  @Input()
  zone: PracticeCityZone;

  @Input()
  allPracticePathsInCity: PracticePath[]

  @Output()
  mouseLeave: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  mouseEnter: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  clickDelete: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  clickEdit: EventEmitter<void> = new EventEmitter<void>();


  constructor() { }

  ngOnInit(): void {
  }

  getVideoCount() : number {
    return this.allPracticePathsInCity.filter(path => path.zoneUuids.includes(this.zone.uuid)).length;
  }

  @HostListener('window:mouseleave', ['$event.target'])
  onMouseLeave() {
    this.mouseLeave.emit();
  }

  onMouseEnter() {
    this.mouseEnter.emit();
  }

  random(){
    return Math.random();
  }

}
