<div
    class = "draggable-dialog-header"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" 
    cdkDragHandle
    cdkDragBoundary=".cdk-overlay-container" 
    [style.min-height] = "config.headerHeight"
    [style.background-color] = "config.headerColor"
    [style.color] = "config.titleColor"
>
    {{ config.title }}
    <button
        mat-dialog-close
        (mousedown) = "$event.stopPropagation()"
        class = "dialog-close-button"
    >
        <mat-icon> close </mat-icon>
    </button>
</div>
<div
    class = "draggable-dialog-content"
    [style.padding] = "config.contentPadding"
>
    <ng-template [cdkPortalOutlet] = "componentPortal"></ng-template>
</div>