import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { map, Observable } from 'rxjs';
import { PracticalModuleLogsContentDataState, PracticalModuleLogsContentErrorState, PracticalModuleLogsContentErrorType, PracticalModuleLogsContentState, PracticalModuleLogsContentStateType } from '../../models/practical-module-log-list-state.model';
import { PracticalModuleLog } from '../../models/practical-module-log.model';
import { PracticalModuleLogsDataService } from '../../services/practical-module-logs-data.service';
import { PracticalModuleLogsContentInfoDialogComponent } from '../practical-module-logs-content-info-dialog/practical-module-logs-content-info-dialog.component';

@Component({
  selector: 'app-practical-module-logs-content',
  templateUrl: './practical-module-logs-content.component.html',
  styleUrls: ['./practical-module-logs-content.component.scss']
})
export class PracticalModuleLogsContentComponent {
  @Input() pageSizeOptions: Array<number> = [ 5, 20, 50 ];

  @ViewChild(MatPaginator, { static: true }) paginator:MatPaginator;

  PracticalModuleLogsListStateType = PracticalModuleLogsContentStateType;
  PracticalModuleLogsListErrorType = PracticalModuleLogsContentErrorType;

  protected numberOfDisplayedLogs$:Observable<number>;

  constructor(
    protected practicalModuleLogsDataService:PracticalModuleLogsDataService,
    private matPaginatorIntl:MatPaginatorIntl,
    private dialogService:MatDialog
  ) { }

  public ngOnInit(): void {
    // Create a stream for the number of items in the filtered logs
    this.numberOfDisplayedLogs$ = this.practicalModuleLogsDataService.filteredLogs$.pipe(
      map<Array<PracticalModuleLog>, number>((filteredPracticalModuleLogs:Array<PracticalModuleLog>) => {
        return filteredPracticalModuleLogs?.length ?? 0;
      })
    );

    // Remove the items per page label from the paginator
    this.matPaginatorIntl.itemsPerPageLabel = "";
  }

  public ngAfterViewInit(): void {
    // After the view initialized update the pagination state with the default (initialized) value
    this.practicalModuleLogsDataService.updatePaginationState(this.paginator.pageSize, this.paginator.pageIndex);
  }

  /**
   * Handles the paginator's state change event (page size or page index changes). It updates the pagination state
   * in the `PracticalModuleLogsDataService`.
   */
  protected onPageEvent():void {
    this.practicalModuleLogsDataService.updatePaginationState(this.paginator.pageSize, this.paginator.pageIndex);
  }

  /**
   * Gets the data from the content state. If the provided state is not `PracticalModuleLogsContentDataState`,
   * `null` returned.
   * 
   * @param practicalModuleLogsContentState the content state object
   * 
   * @returns the data from the state or `null`
   */
  protected getDataFromState(practicalModuleLogsContentState:PracticalModuleLogsContentState):Array<PracticalModuleLog>|null {
    return practicalModuleLogsContentState instanceof PracticalModuleLogsContentDataState ? practicalModuleLogsContentState.data : null;
  }

  /**
   * Gets the error from the content state. If the provided state is not `PracticalModuleLogsContentErrorState`,
   * `null` returned.
   * 
   * @param practicalModuleLogsContentState the content state object
   * 
   * @returns the error from the state or `null`
   */
  protected getErrorFromState(practicalModuleLogsContentState:PracticalModuleLogsContentState):PracticalModuleLogsContentErrorType|null {
    return practicalModuleLogsContentState instanceof PracticalModuleLogsContentErrorState ? practicalModuleLogsContentState.errorType : null;
  }

  /**
   * Opens a practical module log content info dialog.
   */
  protected openContentInfoDialog():void {
    PracticalModuleLogsContentInfoDialogComponent.open(this.dialogService);
  }
}

export type PaginationState = {
  pageSize:number;
  pageIndex:number;
}
