import { HttpErrorHandlerService } from './../../../services/common/http-error-handler.service';
import { BackendService } from './../../../services/common/backend.service';
import { PracticeCityZone } from './../../../classes/model/practice-city-zone';
import { browser } from 'protractor';
import { Injectable, InjectionToken } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class PracticeCityZoneService {
  constructor(private backendService: BackendService, private httpErrorHandler: HttpErrorHandlerService) { }

  private getPolygonsUpdateApiUrl(cityUuid: string) {
    return `/api/admin/practice_path_city/${cityUuid}/zones/update_zones_polygons`;
  }

  private getBaseApiUrl(cityUuid: string, zoneUuid: string | undefined = undefined) {
    if (zoneUuid == undefined) {
      zoneUuid = "";
    }

    return `/api/admin/practice_path_city/${cityUuid}/zones/${zoneUuid}`;
  }

  private getZoneImageUpdateApiUrl(cityUuid: string, zoneUuid: string) {
    return `${this.getBaseApiUrl(cityUuid, zoneUuid)}/update_zone_image`;
  }

  async addZone(cityUuid: string, zone: Pick<PracticeCityZone, "name" | "polygon" | "polygonColor">): Promise<PracticeCityZone> {
    const apiUrl = this.getBaseApiUrl(cityUuid, '');
    const body = {
      name: zone.name,
      polygon: zone.polygon,
      polygonColor: zone.polygonColor,
    };

    try {
      const response: { insertedZone: PracticeCityZone } = await this.backendService.callApi(apiUrl, "PUT", body);
      return response.insertedZone;
    } catch (e) {
      console.error(e);
      this.httpErrorHandler.handleError(e, "Zóna hozzáadása nem sikerült");
    }
  }

  async updateZoneProperties(cityUuid: string, zoneUuid: string, zone: Pick<PracticeCityZone, 'name'>): Promise<PracticeCityZone> {
    const apiUrl = this.getBaseApiUrl(cityUuid, zoneUuid);

    const body = {
      name: zone.name
    };

    const response : {updatedZone: PracticeCityZone} =  await this.backendService.callApi(apiUrl, "POST", body);
    return response.updatedZone;
  }

  async removeZone(cityUuid: string, zoneUuid: string): Promise<void> {
    const apiUrl = this.getBaseApiUrl(cityUuid, zoneUuid);

    const body = {};

    try{
      await this.backendService.callApi(apiUrl, "DELETE", body);
    }catch(e){
      this.httpErrorHandler.handleError(e,"Zóna törlése nem sikerült");
    }

  }

  async getAllZoneInCity(cityUuid: string): Promise<PracticeCityZone[]> {
    const apiUrl = this.getBaseApiUrl(cityUuid);
    const response: { zones: PracticeCityZone[] } = await this.backendService.callApi(apiUrl, "GET");

    return response.zones;
  }

  async updatePolygonsOfZones(cityUuid: string, zones: Pick<PracticeCityZone, 'uuid' | 'polygon' | 'polygonColor'>[]): Promise<void> {
    const apiUrl = this.getPolygonsUpdateApiUrl(cityUuid);

    const body = {
      newZonePolygons: zones.map(zone => ({
        zoneUuid: zone.uuid,
        polygon: zone.polygon,
        polygonColor: zone.polygonColor
      }))
    };

    try{
      await this.backendService.callApi(apiUrl, "POST", body);
    }catch(e){
      console.error(e);
      this.httpErrorHandler.handleError(e, "Zónák frissítése nem sikerült");
    }
  }

  async updateZoneImage(cityUuid: string, zoneUuid: string, image: File): Promise<PracticeCityZone> {
    const apiUrl = this.getZoneImageUpdateApiUrl(cityUuid, zoneUuid);

    const body = {};

    const files = new Map(Object.entries({
      'zone-image': image
    }));

    const response : {updatedZone: PracticeCityZone} =  await this.backendService.callApi(apiUrl, "POST", body, files);
    return response.updatedZone;
  }
}
