import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuestionGroupService } from 'src/app/services/questiongroup.service';
import { QuestionGroup } from 'src/app/classes/model/question-groups';
import { DialogMode } from 'src/app/classes/misc';
import { Category } from 'src/app/classes/model/category';
import { ConfirmationDialogService } from 'src/app/modules/confirmation-dialog/services/confirmation-dialog.service';
import { matchingPropertyPredicate } from 'src/app/functions/misc';

/**
 * @description This component contains the dialog for the question group creation and modification. The title and the displayed buttons are based on
 * the injected data object's `dialogMode` property.
*/
@Component({
  selector: 'app-questiongroup-edit-dialog',
  templateUrl: './questiongroup-edit-dialog.component.html',
  styleUrls: ['./questiongroup-edit-dialog.component.scss']
})
export class QuestiongroupEditDialogComponent implements OnInit {
  // This assignments is for to enable the usage of the enum in the HTML template.
  DialogMode = DialogMode;

  /** The title of the dialog. */
  title:string;

  // FormGroups and FormControls
  /** FormGroup for the FormControls. */
  questionGroupFormGroup:FormGroup;
  /** FormControl for the question group's name input. */
  questionGroupNameFormControl:FormControl;
  questionGroupCategorySelectFC:FormControl;
  questionGroupIsHighlightedFC:FormControl;

  tempAssignedCategories:Array<Readonly<Category>>;

  categoriesInSelect:{ uuid:string, name:string }[];

  constructor(
    private dialogRef:MatDialogRef<QuestiongroupEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{
      dialogMode:DialogMode,
      questionGroup:QuestionGroup,
      categories:ReadonlyArray<Readonly<Category>>
    },
    private questionGroupService:QuestionGroupService,
    private snackBar:MatSnackBar,
    public dialog:MatDialog,
    private confirmationDialogService:ConfirmationDialogService
  ) {}

  ngOnInit():void {
    this.title = "Kérdéscsoport " + (this.data.dialogMode === DialogMode.Add ? "felvétele" : "módosítása");

    // Create and initalize the FormControls based on the DialogMode
    this.questionGroupNameFormControl  = new FormControl(this.data.questionGroup == null ? "" : this.data.questionGroup.name,  [ Validators.required ]);


    this.categoriesInSelect = new Array<{ uuid:string, name:string }>();
    for(let category of this.data.categories) {
      this.categoriesInSelect.push({ uuid: category.uuid, name: category.name });
    }

    this.tempAssignedCategories = new Array<Readonly<Category>>();
    if(this.data.questionGroup !== null) {
      for(let categoryUuid of this.data.questionGroup.categories) {
        this.categoriesInSelect.removeItems(matchingPropertyPredicate("uuid", categoryUuid));
        this.tempAssignedCategories.push(this.data.categories.find(matchingPropertyPredicate("uuid", categoryUuid)));
      }
    }
    
    this.questionGroupCategorySelectFC = new FormControl();
    if(this.categoriesInSelect.length === 0) {
      this.questionGroupCategorySelectFC.disable();
    }

    this.questionGroupIsHighlightedFC = new FormControl(this.data.questionGroup == null ? false : this.data.questionGroup.isHighlighted)

    // Assign the FormControls to the FormGroup
    this.questionGroupFormGroup = new FormGroup({
      questionGroupName: this.questionGroupNameFormControl,
      questionGroupCategorySelectFC: this.questionGroupCategorySelectFC,
      questionGroupIsHighlightedFC: this.questionGroupIsHighlightedFC
    });
  }


  assignQuestionGroupToCategory():void {
    this.tempAssignedCategories.push(this.data.categories.find(matchingPropertyPredicate("uuid", this.questionGroupCategorySelectFC.value as string)));
    this.categoriesInSelect.removeItems(matchingPropertyPredicate("uuid", this.questionGroupCategorySelectFC.value));
    this.questionGroupCategorySelectFC.reset();
    if(this.categoriesInSelect.length === 0) {
      this.questionGroupCategorySelectFC.disable();
    }
  }

  removeQuestionGroupFromCategory(categoryUuid:string):void {
    let category:Category = this.data.categories.find(matchingPropertyPredicate("uuid", categoryUuid));
    if(category) {
      this.categoriesInSelect.push({ uuid: category.uuid, name: category.name });
      this.tempAssignedCategories.splice(this.tempAssignedCategories.findIndex((element:Category) => { return element.uuid === categoryUuid }), 1);
      this.questionGroupCategorySelectFC.enable();
    }
  }

  /**
   * Handles the 'add button' click. The function checks the validity of the forms, if there is no error, the corresponding question group service
   * function will be called, and the dialog will be closed. The function creates a Angular Material Snackbar which will indicate the result of the
   * operation.
   * 
   * @returns {void} nothing
  */
  handleAddButtonClick():void {
    if(this.questionGroupFormGroup.valid) {
      // Build a partial question group based on the input values
      const newQuestionGroup:Partial<QuestionGroup> = {
        name: this.questionGroupNameFormControl.value as string,
        questions: [],
        categories: this.tempAssignedCategories.map(category => category.uuid),
        isHighlighted: this.questionGroupIsHighlightedFC.value as boolean
      };
    
      this.addQuestionGroup(newQuestionGroup);
      this.dialogRef.close();
    }
  }

  /**
   * Handles the 'delete button' click. The function calls the correspondig question group service function and then creates a Angular Material
   * Snackbar which will indicate the result of the operation.
   * 
   * @returns {void} nothing
  */
  handleDeleteButtonClick():void {
    this.confirmationDialogService.open(
      "Kérdéscsoport törlése",
      "Biztosan törli a kérdéscsoportot?",
      async () => {
        await this.deleteQuestionGroup(this.data.questionGroup.uuid);
        this.dialogRef.close();
      }
    );
  }

  /**
   * Handles the 'modify button' click. The function checks the validity of the forms, if there is no error, the corresponding question group service
   * function will be called, and the dialog will be closed. The function creates a Angular Material Snackbar which will indicate the result of the
   * operation.
   * 
   * @returns {void} nothing
  */
  handleModifyButtonClick():void {
    if(this.questionGroupFormGroup.valid) {
      // Build a QuestionGroup based on the original and the input values
      const modifiedQuestionGroup:QuestionGroup = {
        uuid: this.data.questionGroup.uuid,
        name: this.questionGroupNameFormControl.value as string,
        questions: this.data.questionGroup.questions,
        categories: this.tempAssignedCategories.map(category => category.uuid),
        isHighlighted: this.questionGroupIsHighlightedFC.value as boolean,
        thematicId: this.data.questionGroup.thematicId
      };
     
      this.modifyQuestionGroup(modifiedQuestionGroup);
      this.dialogRef.close();
    }
  }

  private async addQuestionGroup(newQuestionGroup:Partial<QuestionGroup>):Promise<void> {
    try {
      await this.questionGroupService.addQuestionGroup(newQuestionGroup);
      this.snackBar.open("Kérdéscsoport sikeresen hozzáadva", "Bezár", { duration: 2000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}
  }

  private async deleteQuestionGroup(questionGroupUuid:string):Promise<void> {
    try {
      await this.questionGroupService.deleteQuestionGroup(questionGroupUuid);
      this.snackBar.open("Kérdéscsoport sikeresen törölve", "Bezár", { duration: 2000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}
  }

  private async modifyQuestionGroup(modifiedQuestionGroup:QuestionGroup):Promise<void> {
    try {
      await this.questionGroupService.modifyQuestionGroup(modifiedQuestionGroup);
      this.snackBar.open("Kérdéscsoport sikeresen módosítva", "Bezár", { duration: 2000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}
  }

}
