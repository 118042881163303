<div style = "display: flex; column-gap: 20px;">
  <!--  Date interval selector -->
  <mat-form-field style="width: 100%;" appearance="outline">
    <mat-label> Időintervallum </mat-label>
    <mat-date-range-input [rangePicker] = "picker">
        <input
            matStartDate
            [formControl]="fromDateFilterFC"
            [disabled]="true"
        />
        <input
            matEndDate
            [formControl]="toDateFilterFC"
            [disabled]="true"
        >
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
  </mat-form-field>

  <!-- Operation selector -->
  <mat-form-field style = "width: 100%;" appearance="outline">
    <mat-label> Műveletek </mat-label>
    <mat-select
      [formControl]="eventsFilterFC"
      multiple
      [compareWith]="operationsSelectCompareWith"
    >
      <mat-select-trigger>
        {{ getDisplayedValueForEventSelectTrigger() }}
      </mat-select-trigger>
      <mat-optgroup
        *ngFor="let group of displayedPracticeModuleEventLogGroups"
        [label]="group.groupName"
      >
        <mat-option
          *ngFor="let eventDescription of group.eventDescriptions"
          [value]="getEventOptionValue(group, eventDescription.eventName)"
        >
          <div class = "operation-option-item">
            <div
              class = "operation-color-indicator"
              [style.background-color] = "eventDescription.color"
            >
              <!-- Color indicator -->
            </div>
            {{ eventDescription.displayedName }}
          </div>
          
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>

  <!-- Text filter -->
<!--   <mat-form-field style = "width: 100%;" appearance="outline">
    <mat-label> Keresés </mat-label>
    <input matInput [formControl] = "textSearchFC" />
  </mat-form-field> -->
</div>