import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogMode } from 'src/app/classes/misc';
import { PracticeIcon } from 'src/app/classes/model/practice-icons';
import { Permission } from 'src/app/classes/model/permissions';
import { PracticeIconService } from 'src/app/services/practice-icon.service';
import { PracticeIconsEditorComponent } from './practice-icons-editor/practice-icons-editor.component';
import { PermissionService } from 'src/app/services/common/permission.service';

@Component({
  selector: 'app-practice-icons',
  templateUrl: './practice-icons.component.html',
  styleUrls: ['./practice-icons.component.scss']
})
export class PracticeIconsComponent implements OnInit {

  practiceIcons:ReadonlyArray<Readonly<PracticeIcon>>;
  isContentLoading:boolean;

  userCanEdit:boolean = false;

  constructor(
    private practiceIconService:PracticeIconService,
    private dialog:MatDialog,
    private permissionService:PermissionService
  ) { }

  public ngOnInit():void {
    this.userCanEdit = this.permissionService.isLoggedUserHasPermission(Permission.PracticeIconWrite);
    this.practiceIcons = this.practiceIconService.getPracticeIconsArrayRef();
    this.loadPracticeIcons();
  }

  public async handleReloadButtonClick():Promise<void> {
    await this.loadPracticeIcons();
  }

  public handleAddButtonClick():void {
    this.dialog.open(PracticeIconsEditorComponent, {
      data: {
        editorMode: DialogMode.Add,
        practicePath: null
      }
    });
  }

  public handleEditButtonClick(practiceIconIndex:number) {
    this.dialog.open(PracticeIconsEditorComponent, {
      data: {
        editorMode: DialogMode.Edit,
        practiceIcon: this.practiceIcons[practiceIconIndex]
      }
    });
  }

  private async loadPracticeIcons():Promise<void> {
    this.isContentLoading = true;

    try {
      await this.practiceIconService.loadDataIntoCache();
    } catch(error:any) {}

    this.isContentLoading = false;
  }

}
