import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotifierService } from 'src/app/services/common/notifier-service.service';
import { Permission } from 'src/app/classes/model/permissions';
import { Vendor, VendorDocument, VendorDocumentFile } from 'src/app/classes/model/vendor';
import { PermissionService } from 'src/app/services/common/permission.service';
import { ResourceService } from 'src/app/services/common/resource.service';
import { AddVendorDocumentsDialogComponent, AddVendorDocumentsDialogOutput } from '../../components/add-vendor-documents-dialog/add-vendor-documents-dialog.component';

@Component({
  selector: 'app-vendor-documents-tab',
  templateUrl: './vendor-documents-tab.component.html',
  styleUrls: ['./vendor-documents-tab.component.scss']
})
export class VendorDocumentsTabComponent implements OnInit {
  @Input() vendor:Vendor;

  downloadingResources:Array<string> = [];

  constructor(
    private dialogService:MatDialog,
    private resourceService:ResourceService,
    private notifierService:NotifierService,
    private permissionService:PermissionService
  ) { }

  public ngOnInit(): void {
    this.sortDocumentsByCreationDesc();
  }

  protected openAddDocumentsToVendorDialog():void { }

  protected onAddDocumentsButtonClick():void {
    const dialogRef:MatDialogRef<AddVendorDocumentsDialogComponent, AddVendorDocumentsDialogOutput> = 
    AddVendorDocumentsDialogComponent.open(
      this.dialogService,
      { vendorUuid: this.vendor.uuid }
    );

    dialogRef.afterClosed().subscribe(
      (dialogOutput:AddVendorDocumentsDialogOutput) => {
        if(dialogOutput) {
          this.vendor.documents = dialogOutput.documents;
          this.sortDocumentsByCreationDesc();
        }
      }
    );
  }

  /**
   * Sorts the vendors documents by their creation timestamp in descending order.
   */
  private sortDocumentsByCreationDesc():void {
    this.vendor.documents.sort(
      (vendorDocument1:VendorDocument, vendorDocument2:VendorDocument) => {
        return vendorDocument2.created.timestamp - vendorDocument1.created.timestamp;
      }
    );
  }

  /**
   * Handles the dowload icon click. It start the target document file download by the browser.
   * 
   * @param vendorDocumentFile the target document file
   */
  protected async onDownloadIconClick(vendorDocumentFile:VendorDocumentFile):Promise<void> {
    // Check if the resource is already downloading
    if(this.isResourceDownloading(vendorDocumentFile)) {
      // If so, there is nothing to do
      return;
    }

    // Add the resource's path to the downloading resources array
    this.downloadingResources.push(vendorDocumentFile.fileUrl);
    
    // Download the resource
    try {
      await this.resourceService.downloadResource(
        vendorDocumentFile.fileUrl,
        vendorDocumentFile.originalFileName
      );
    } catch(error:any) {
      this.notifierService.notify("error", "Hiba a fájl letöltése közben.");
    }
    
      
    // Remove the resource's path from the downloading resources array
    this.downloadingResources.removeItems(
      downloadingResource => downloadingResource === vendorDocumentFile.fileUrl
    );
  }

  /**
   * Determines if the given document file is in the downloading state.
   * 
   * @param vendorDocumentFile the target document file
   * 
   * @returns true if the file is in downloading state, false otherwise
   */
  protected isResourceDownloading(vendorDocumentFile:VendorDocumentFile):boolean {
    return this.downloadingResources.includes(vendorDocumentFile.fileUrl);
  }

  /**
   * Determines that the current admin user has the vendor document upload permission.
   * 
   * @returns true if the admin has the permission, false otherwise
   */
  protected hasAdminDocumentUploadPermission():boolean {
    return this.permissionService.isLoggedUserHasPermission(Permission.VendorDocumentUpload);
  }

}
