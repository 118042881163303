import { Injectable } from '@angular/core';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { AppNotification, appNotificationSorter } from "../classes/model/app-notification";
import { HttpResponseData } from '../classes/http-communication';
import { DataCacherService } from './data-cacher-service';
import { Permission, PermissionString } from '../classes/model/permissions';

@Injectable({
  providedIn: 'root'
})
export class AppNotificationService implements DataCacherService {
  private readonly appNotificationsApiUrlFragment:string = "/api/admin/user/notification";
  appNotifications:Array<AppNotification>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.ServerManager ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
    ) {
    this.appNotifications = new Array<AppNotification>();
  }

  public getAppNotificationsArrayRef():ReadonlyArray<Readonly<AppNotification>> {
    return this.appNotifications;
  }

  public async fetchAppNotifications():Promise<Array<AppNotification>> {
    let appNotifications:Array<AppNotification> = [];
    try {
      const response:HttpResponseData<Array<AppNotification>> = await this.backendService.callApi(this.appNotificationsApiUrlFragment, "GET");
      appNotifications = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az értesítések betöltése közben.");
    }

    return appNotifications;
  }

  public async createNotification(newAppNotification:Partial<AppNotification>):Promise<void> {
    const httpBody:Object = {
      data: {
        appNotification: newAppNotification
      }
    }
    try {
      const response:HttpResponseData<AppNotification> = await this.backendService.callApi(this.appNotificationsApiUrlFragment, "POST", httpBody);
      this.appNotifications.push(response.data);
      this.appNotifications.sort(appNotificationSorter);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az értesítés létrehozása közben.");
    }
  }

  public async loadDataIntoCache(): Promise<void> {
    const appNotifications:Array<AppNotification> = await this.fetchAppNotifications();
    this.appNotifications.replaceArrayElements(appNotifications).sort(appNotificationSorter);
  }

  public getRequiredPermissionsForDataLoading(): readonly PermissionString[] {
    return this.requiredPermissionsForDataLoading;
  }

  public clearCachedData():void {
    this.appNotifications.length = 0;
  }

  public getNameOfCachedData(): string {
    return "App értesítések";
  }


}
