import { Injectable } from '@angular/core';
import {
  Environment,
  predefinedEnvironments,
  PredefinedEnvironmentType,
} from './environment';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class PredefinedEnvironmentService {

  constructor(
    private environmentService:EnvironmentService
  ) { }

  /**
   * Returns the entire environment for the given predefined environment type.
   * 
   * @param environmentType the type of the predefined environment
   * 
   * @returns the predefined environment
   */
  public getPredefinedEnvironment(environmentType:PredefinedEnvironmentType):Environment {
    return predefinedEnvironments[environmentType];
  }

  /**
   * Return the predefined environments as an array.
   * 
   * @returns array of the predefined environments
   */
  public getPredefinedEnvironments():Array<Readonly<Environment>> {
    return Object.values(predefinedEnvironments);

  }

  /**
   * Loads a predefined environment with the `EnvironmentService`.
   * 
   * @param environmentType the predefined environment's type
   */
  public loadPredefinedEnvironment(environmentType:PredefinedEnvironmentType):void {
    const environment:Environment = this.getPredefinedEnvironment(environmentType);
    this.environmentService.setEnvironment(environment);
  }

  /**
   * Checks if a given type is a valid predefined type.
   * 
   * @param type a type to be checked
   * 
   * @returns true, if the given type is a valid predefined type, false otherwise
   */
  public isValidPredefinedType(type:any):boolean {
    for(const predefinedEnvironmentTypeName in predefinedEnvironments) {
      if(predefinedEnvironmentTypeName === type) {
        return true;
      }
    }

    return false;
  }
}