import { PathIconRecording } from '../../../timed-icons-tab/components/timed-icon-recorder/timed-icon-recorder.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotifierService } from 'src/app/services/common/notifier-service.service';
import { IconRecorderService } from '../../../timed-icons-tab/components/timed-icon-recorder/timed-icon-recorder.service';

@Component({
  selector: 'app-path-icon-recorder',
  templateUrl: './path-icon-recorder.component.html',
  styleUrls: ['./path-icon-recorder.component.scss']
})
export class PathIconRecorderComponent implements OnInit {
  @Input()
  public iconRecorderService: IconRecorderService<PathIconRecording>;

  @Output()
  onTapGeoTaggedIndex = new EventEmitter<number>();

  constructor(private notifier: NotifierService){

  }

  ngOnInit(): void {

  }

  onTapSaveRecording(practiceIconUrl: string){
    const isValid = this.iconRecorderService.isValidRecording(practiceIconUrl);
    if(!isValid){
      this.notifier.notify('error', 'A mentés nem lehetséges, mert a végpont nem lehet a kezdőpont előtt!');
      return;
    }

    this.iconRecorderService.finishRecording(practiceIconUrl);
  }

  onCancelRecording(practiceIconUrl: string){
    this.iconRecorderService.cancelRecording(practiceIconUrl);
  }


}
