<div id = "student-gift-dialog">
    <div id = "student-gift-title">
        Ajándék létrehozása
    </div>
    <form [formGroup] = "giftFG">
        <div id = "student-gift-inputs">
            <div id = "student-gift-name">
                 <mat-form-field appearance='outline' style = "width: 100%">
                    <mat-label>Ajándék oka</mat-label>
                    <textarea matInput [formControl] = "reasonFC"> </textarea>
                    <mat-hint> Magunknak szánt leírás az ajándék okáról. </mat-hint>
                </mat-form-field>
            </div>
            <div id = "student-gift-details">
                 <mat-form-field appearance='outline' style = "width: 100%">
                    <mat-label>Leírás</mat-label>
                    <input matInput [formControl] = "detailsFC" required />
                    <mat-hint> Ezt látja a tanuló. </mat-hint>
                    <mat-error *ngIf = "detailsFC.hasError('required')">Kötelező mező! (Ezt látja a tanuló)</mat-error>
                </mat-form-field>
            </div>
            <div id = "student-gift-credits">
                <app-package-definition-selector
                    [formControl] = "packageDefinitionFC"
                    ngDefaultControl
                ></app-package-definition-selector>
            </div>
        </div>
        <div style = "text-align: right;">
            <button mat-raised-button mat-dialog-close style = "margin-right: 20px;">
                Bezár
            </button>
            <button mat-raised-button color = "primary" [disabled] = "giftFG.invalid" (click) = "handleCreateGiftButtonClick()">
                Létrehozás
            </button>
        </div>
    </form>
</div>
