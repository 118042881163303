<mat-accordion displayMode = "flat" multi>
    <mat-expansion-panel
        *ngFor = "let menuPoint of menu.menuPoints; let i = index"
        (opened) = "expansionPanelOpened(i)"
        hideToggle
        #expansionPanel
    >
        <mat-expansion-panel-header [collapsedHeight] = "'auto'" [expandedHeight] = "'auto'">
            <mat-panel-title>
                <mat-icon> {{ menuPoint.iconName }} </mat-icon>
                <div class = "navigation-menupoint"> {{ menuPoint.name }} </div>
                
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div
            *ngFor = "let subMenuPoint of menuPoint.subMenuPoints"
            class = "navigation-submenupoint"
            [routerLink] = "getRouteCommands(menuPoint, subMenuPoint)"
        >
            {{ subMenuPoint.name }}
        </div>
    </mat-expansion-panel>

</mat-accordion>
