<div *ngIf="!hasAnyRawVideo()" style="text-align:center;margin-top:20px;">
  Nincs nyers videó, és minimal bitrate videó se.<br>
  Ha anonimizálni szeretnél új videót tölts fel az útvonalhoz egyet.
  <div style = "margin-top: 10px;">
    <button
        mat-raised-button
        color = "primary"
        (click) = "navigateToMainDataEdit()"
    >
        Ugrás az útvonal szerkesztéséhez
    </button>
  </div>
</div>

<ng-template [ngIf]="hasAnyRawVideo()">
    <div class = "video-container">
        <video #video class = "video" controls></video>
    </div>
    <div class = "video-data">
        <div class = "video-data-item">
            <div class = "video-data-title">
                Eredeti fájlnév:
            </div>

            <div class = "video-data-value">
                {{ this.practicePath.video.rawVideo?.originalFileName ?? this.practicePath.video.rawVideoConvertedMinimalBitrate?.originalFileName }}
            </div>
        </div>

        <div class = "video-data-item">
            <div class = "video-data-title">
                Feltöltve:
            </div>
            <div class = "video-data-value">
                {{ (this.practicePath.video.rawVideo?.creationTimestamp ?? this.practicePath.video.rawVideoConvertedMinimalBitrate?.videoCreationTimestamp) | date: "YYYY-MM-dd HH:mm" }}
            </div>
        </div>

        <div class = "video-data-item">
          <div class = "video-data-title">
              Verziók:
          </div>
          <div class = "video-data-value">
              {{ hasOriginalRaw() ? 'Eredeti nyers |' : ''}}
              {{ hasMinimalRaw() ? 'Minimal bitrate' : ''}}
          </div>
       </div>

    </div>

    <div style = "display: flex; justify-content: center;">
        <button
            (click) = "onDeleteRawVideoButtonClick()"
            mat-raised-button
            color = "error"
            [disabled] = "rawVideoDeletionInProgress"
            [class.button-loading] = "rawVideoDeletionInProgress"
        >
            Nyers videó törlése
        </button>
    </div>


    <div class = "anonyimize-button-container">
        <button
            mat-raised-button
            (click) = "anonymizeRawVideo()"
            color = "primary"
            [class.button-loading] = "isAnonymizationStarting"
            [disabled] = "isAnonymizationStarting||this.practicePath.video.rawVideo == null"
        >
            <span class = "material-icons">
                auto_fix_high
            </span>
            Anonimizálás
        </button>

        <mat-checkbox [(ngModel)]="this.anonymizationSettings.fastMode" style="margin-left:10px;margin-right:10px;">
          Gyors mód
        </mat-checkbox>

        <mat-checkbox [(ngModel)]="this.anonymizationSettings.startNewInstance" style="margin-left:10px;margin-right:10px;">
          Gép indítása
        </mat-checkbox>

        <mat-checkbox [(ngModel)]="this.anonymizationSettings.debugBoundingBox" style="margin-left:10px;margin-right:10px;">
          Debug dobozok
        </mat-checkbox>
    </div>
</ng-template>
