<section id = "questions-section">
    <app-page-header
        title = "Kérdések"
        subtitle = "Az elméleti modulban elérhető kérdéseket találod itt. Az összes kérdés listázva van, de a szűrő segítségével
            egyszerűen kereshetsz vagy szűrhetsz."
        style = "margin-bottom: 20px;"
    >
       <div class = "header-actions">
            <div id = "questions-refresh-button">
                <button mat-mini-fab color = "primary" (click) = "handleReloadButtonClick()"> <mat-icon> cached </mat-icon> </button>
            </div>
            <div id = "questions-open-button">
                <button
                    mat-raised-button
                    (click) = "openAllQuestions()"
                    style="white-space: nowrap;"
                > Mindet kinyit </button>
            </div>
            <div id = "questions-filter-form-container">
                <form [formGroup] = "questionFilterFormGroup">
                    <div id = "question-filters-container">
                        <div id = "question-filter-search-container">
                             <mat-form-field appearance='outline' id = "question-filter-search-form" >
                                <mat-label>Keresés</mat-label>
                                <input matInput formControlName = "questionSearchFilterFormControl" />
                                <mat-icon matSuffix> search </mat-icon>
                            </mat-form-field>
                        </div>
                        <div id = "question-filter-category-container">
                             <mat-form-field appearance='outline' id = "question-filter-category-form" >
                                <mat-label>Kategória</mat-label>
                                <mat-select formControlName = "questionFilterCategorySelect">
                                    <mat-option [value] = "uuidFilterWildCard"> Bármely </mat-option>
                                    <mat-option [value] = "uuidFilterEmptyField"> Kategória nélküliek </mat-option>
                                    <mat-option *ngFor = "let category of categories" [value] = "category.uuid"> {{ category.name }} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                             <mat-form-field appearance='outline' id = "question-filter-category-position-form" >
                                <mat-label>Pozíció</mat-label>
                                <mat-select formControlName = "questionFilterCategoryPositionSelect">
                                    <mat-option [value] = "positionFilterWildcard"> * </mat-option>
                                    <mat-option *ngFor = "let position of getCategoryPositionsAsArray(questionFilterCategorySelect.value)" [value] = "position - 1">
                                        {{ position}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div id = "question-filter-questiongroups-container">
                             <mat-form-field appearance='outline' id = "question-filter-questiongroups-form" >
                                <mat-label>Kérdéscsoport</mat-label>
                                <mat-select formControlName = "questionFilterQuestiongroupSelect">
                                    <mat-option [value] = "uuidFilterWildCard"> Bármely </mat-option>
                                    <mat-option [value] = "uuidFilterEmptyField"> Kérdéscsoport nélküliek </mat-option>
                                    <mat-option *ngFor = "let questionGroup of getQuestionGroups()" [value] = "questionGroup.uuid"> {{ questionGroup.name }} </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div id = "question-more-filters-container">
                            <button mat-mini-fab color = "primary" [matMenuTriggerFor] = "filterMenu">
                                <mat-icon> more_vert </mat-icon>
                            </button>
                            <mat-menu #filterMenu = "matMenu">
                                <div>
                                    <div class = "question-explanation-filter-title"> Média </div>
                                    <mat-radio-group formControlName = "questionFilterMediaRadio" (click) = "$event.stopPropagation()">
                                        <mat-radio-button class = "question-explanation-filter-option" [value] = "FilterByPresence.All">
                                            Mindegy
                                        </mat-radio-button>
                                        <mat-radio-button class = "question-explanation-filter-option" [value] = "FilterByPresence.Present">
                                            Van
                                        </mat-radio-button>
                                        <mat-radio-button class = "question-explanation-filter-option" [value] = "FilterByPresence.NotPresent">
                                            Nincs
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div>
                                    <div class = "question-explanation-filter-title"> Illusztráció </div>
                                    <mat-radio-group formControlName = "questionFilterIllustrationRadio" (click) = "$event.stopPropagation()">
                                        <mat-radio-button class = "question-explanation-filter-option" [value] = "FilterByPresence.All">
                                            Mindegy
                                        </mat-radio-button>
                                        <mat-radio-button class = "question-explanation-filter-option" [value] = "FilterByPresence.Present">
                                            Igen
                                        </mat-radio-button>
                                        <mat-radio-button class = "question-explanation-filter-option" [value] = "FilterByPresence.NotPresent">
                                            Nem
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div>
                                    <div class = "question-explanation-filter-title"> Magyarázat </div>
                                    <mat-radio-group formControlName = "questionFilterExplanationRadio" (click) = "$event.stopPropagation()">
                                        <mat-radio-button class = "question-explanation-filter-option" [value] = "FilterByPresence.All">
                                            Mindegy
                                        </mat-radio-button>
                                        <mat-radio-button class = "question-explanation-filter-option" [value] = "FilterByPresence.Present">
                                            Van
                                        </mat-radio-button>
                                        <mat-radio-button class = "question-explanation-filter-option" [value] = "FilterByPresence.NotPresent">
                                            Nincs
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div>
                                    <div class = "question-explanation-filter-title"> Frissítve </div>
                                    <mat-radio-group formControlName = "questionFilterIsUpdatedRadio" (click) = "$event.stopPropagation()">
                                        <mat-radio-button class = "question-explanation-filter-option" [value] = "FilterByPresence.All">
                                            Mindegy
                                        </mat-radio-button>
                                        <mat-radio-button class = "question-explanation-filter-option" [value] = "FilterByPresence.Present">
                                            Igen
                                        </mat-radio-button>
                                        <mat-radio-button class = "question-explanation-filter-option" [value] = "FilterByPresence.NotPresent">
                                            Nem
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div style = "text-align: right; margin: 10px 10px 0 0;">
                                    <button mat-flat-button color = "primary" (click) = "filterInactiveQuestions()"> Inaktív kérdések </button>
                                </div>
                            </mat-menu>
                        </div>
                    </div>
                </form>
            </div>
       </div>
    </app-page-header>
    <mat-paginator #questionPaginator class = "question-paginator" [length] = "getNumberOfFilteredQuestions()" [pageSize] = "defaultPageSize" [pageSizeOptions] = "pageSizeOptions" showFirstLastButtons> </mat-paginator>
    <div id = "questions-questions-container">
        <app-question
            *ngFor = "let questionIndex of getDisplayedQuestions()"
            [question] = "getQuestionsArray()[questionIndex]"
            [questionGroups] = "questionGroups"
            [categories] = "categories"
            style = "margin-bottom: 10px;"
        >
        </app-question>
    </div>
    <div id = "new-questions-button-container">
        <button mat-fab color = "primary" (click) = "showAddQuestionDialog()">
            <mat-icon> add </mat-icon>
        </button>
    </div>
</section>
