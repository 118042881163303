<div>
  <p id="header-text" style="text-align: start">
    Töltsd fel a szükséges fájlokat!
  </p>

  <div class="upload-row">
    <!-- Teljes videó feltöltő input -->
    <div id="video-upload-col" class="upload-row-col">
      Teljes videó
      <br/>
      <input #videoInput type= "file" (change)="changeFileInput($event)"/>
    </div>

    <!-- JSON metadata feltöltő input -->
    <div id="metadata-upload-col" class="upload-row-col">
      MrVegas JSON Metadata 📽<br>
      <input #metadataInput  type="file" (change)="changeFileInput($event)"/>
    </div>
  </div>


  <!-- Feltöltő gomb, ami a FullVideoUpload service-t hívja -->
  <button mat-raised-button color="primary" id="upload-button" (click)="onTapUpload()">
    Feltöltés
  </button>
</div>



