<ng-container
    *ngIf="
        componentState === CommentsOnEntityComponentState.SuccessfullyLoaded;
        then successfullyLoaded;
        else componentLoadingOrHasError"
></ng-container>

<!-- Loading and error guards -->
<ng-template #componentLoadingOrHasError>
    <div [ngSwitch] = "componentState" class = "loading-and-error-container">
        <div *ngSwitchCase = "CommentsOnEntityComponentState.Loading">
            <mat-spinner
                diameter="50"
                strokeWidth="8"
                style = "margin: 20px auto 0;"
            ></mat-spinner>
            <div style = "text-align: center; margin-top: 10px;">
                Kommentek betöltése...
            </div>
        </div>
        <div
            *ngSwitchCase = "CommentsOnEntityComponentState.UserHasNoPermissionError"
            class = "no-permissions-error"
        >
            Nincs jogosultságod a megjegyzések olvasására.
        </div>
        <div
            *ngSwitchCase = "CommentsOnEntityComponentState.CommentsFetchingError"
            class = "fetch-error"
        >
            <div>
                Hiba történt kommentek lekérésekor. Kattints az alábbi gombra az újrapróbálkozáshoz.
            </div>
            <div style = "margin-top: 10px;">
                <button
                    mat-raised-button
                    color = "primary"
                    (click) = "initalize()"
                >
                    <mat-icon> refresh </mat-icon>
                    Újratöltés
                </button>
            </div>
        </div>
        <div *ngSwitchDefault class = "other-error">
            Hiba történt a komponens inicializálásakor.
        </div>
    </div>
</ng-template>

<ng-template #successfullyLoaded>
    <app-comment-list></app-comment-list>
</ng-template>

<app-comment-writer
    [entityType] = "entityType"
    [entityKeyValue] = "entityKeyValue"
></app-comment-writer>