import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomValidators } from 'src/app/classes/custom-validators';
import { CityNode, PracticeCity } from 'src/app/classes/model/practice-city';
import { PracticeCityNodeService } from 'src/app/services/practice-city-node.service';

@Component({
  selector: 'app-practice-city-node-editor',
  templateUrl: './practice-city-node-editor.component.html',
  styleUrls: ['./practice-city-node-editor.component.scss']
})
export class PracticeCityNodeEditorComponent implements OnInit {

  nameFC: FormControl;
  isFreeFC: FormControl;
  isVisibleFC: FormControl;
  imageFC: FormControl;
  isMainNodeFC: FormControl;
  assignedPositions: Array<number>;

  constructor(
    private dialogRef: MatDialogRef<PracticeCityNodeEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { city: PracticeCity, node: CityNode, maxPosition: number },
    private practiceCityNodeService: PracticeCityNodeService,
    protected snackBarService: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.nameFC = new FormControl(this.data.node.name, [ Validators.required, CustomValidators.maximalLineCount(2) ]);
    this.imageFC = new FormControl(this.data.node.imageUrl ?? null);

    this.isFreeFC = new FormControl(this.data.node.isFree ?? false);
    this.isVisibleFC = new FormControl(this.data.node.isVisible ?? false);

    this.isMainNodeFC = new FormControl(this.data.node.isMainNode ?? false);
  }


  public async handleSaveButtonClick():Promise<void> {
    const modifiedNode:CityNode = {
      uuid: this.data.node.uuid,
      name: this.nameFC.value,
      isFree: this.isFreeFC.value,
      isVisible: this.isVisibleFC.value,
      isMainNode:this.isMainNodeFC.value
    }

    try {
      await this.practiceCityNodeService.updateNodeInCity(this.data.city, modifiedNode, this.imageFC.value);
      this.snackBarService.open("Érintési pont sikeresen módosítva.", "Bezár", { duration: 3000, panelClass: [ "mat-snackbar-success" ] });
      this.dialogRef.close();
    } catch(error:any) {}
  }

}
