import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Category } from 'src/app/classes/model/category';
import { QuestionGroup } from 'src/app/classes/model/question-groups';
import { matchingPropertyPredicate } from 'src/app/functions/misc';

enum QuestionGroupAssignment {
  NotAssigned, Assigned
}

enum QuestionGroupSortingMethod {
  Aphabetical, Thematic
}

class ResolvedQuestionGroupItem {
  questionGroupUuid:string;
  questionGroupName:string;
  questionGroupThematicId:number;
  categoies:Array<string>;
}

class ResolvedQuestionGroups {
  items:Array<ResolvedQuestionGroupItem>;
  categoriesRef:ReadonlyArray<Readonly<Category>>
  questionGroupSortingMethod:QuestionGroupSortingMethod;

  constructor(categoriesRef:ReadonlyArray<Readonly<Category>>) {
    this.items = new Array<ResolvedQuestionGroupItem>();
    this.categoriesRef = categoriesRef;
    this.questionGroupSortingMethod = QuestionGroupSortingMethod.Aphabetical;
  }

  addItem(questionGroup:QuestionGroup) {
    let categoryNames:Array<string> = new Array<string>();
    for(const categoryUuid of questionGroup.categories) {
      const category:Category = this.categoriesRef.find(matchingPropertyPredicate("uuid", categoryUuid));
      categoryNames.push(category.name);
    }
    this.items.push({
      questionGroupUuid: questionGroup.uuid,
      questionGroupName: questionGroup.name,
      questionGroupThematicId: questionGroup.thematicId,
      categoies: categoryNames
    });
    this.sortElements();
  }

  removeItem(questionGroupUuid:string) {
    this.items.removeItems(matchingPropertyPredicate("questionGroupUuid", questionGroupUuid));
  }

  sortElements() {
    let sortingFunction:(item1:ResolvedQuestionGroupItem, item2:ResolvedQuestionGroupItem) => number;

    if(this.questionGroupSortingMethod == QuestionGroupSortingMethod.Aphabetical) {
      sortingFunction = function(item1:ResolvedQuestionGroupItem, item2:ResolvedQuestionGroupItem):number {
        return item1.questionGroupName.localeCompare(item2.questionGroupName);
      }
    } else {
      sortingFunction = function(item1:ResolvedQuestionGroupItem, item2:ResolvedQuestionGroupItem):number {
        if(item1.questionGroupThematicId < item2.questionGroupThematicId) {
          return -1;
        } else if(item1.questionGroupThematicId > item2.questionGroupThematicId) {
          return 1;
        } else {
          return 0;
        }
      }
    }

    this.items.sort(sortingFunction);
  }
}

@Component({
  selector: 'app-question-questiongroup-assignment-edit-dialog',
  templateUrl: './question-questiongroup-assignment-edit-dialog.component.html',
  styleUrls: ['./question-questiongroup-assignment-edit-dialog.component.scss']
})
export class QuestionQuestiongroupAssignmentEditDialogComponent implements OnInit {
  QuestionGroupAssignment = QuestionGroupAssignment;
  QuestionGroupSortingMethod = QuestionGroupSortingMethod;

  tempAssignedQuestionGroups:Array<string>;
  assignedQuestionGroups:ResolvedQuestionGroups;
  notAssignedQuestionGroups:ResolvedQuestionGroups;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      categories:ReadonlyArray<Readonly<Category>>,
      questionGroups:ReadonlyArray<Readonly<QuestionGroup>>,
      assignedQuestionGroups:Array<string>,
    }
  ) { }

  ngOnInit(): void {
    this.assignedQuestionGroups = new ResolvedQuestionGroups(this.data.categories);
    this.notAssignedQuestionGroups = new ResolvedQuestionGroups(this.data.categories);
    this.tempAssignedQuestionGroups = [ ...this.data.assignedQuestionGroups ];

    for(let questionGroup of this.data.questionGroups) {
      if(this.data.assignedQuestionGroups.includes(questionGroup.uuid)) {
        this.assignedQuestionGroups.addItem(questionGroup);
      } else {
        this.notAssignedQuestionGroups.addItem(questionGroup);
      }
    }

  }

  assingQuestionGroup(questionGroupUuid:string):void {
    let questionGroup:QuestionGroup = this.data.questionGroups.find(matchingPropertyPredicate("uuid", questionGroupUuid));
    this.assignedQuestionGroups.addItem(questionGroup);
    this.notAssignedQuestionGroups.removeItem(questionGroupUuid);
    this.tempAssignedQuestionGroups.push(questionGroupUuid);
  }

  unassingQuestionGroup(questionGroupUuid):void {
    let questionGroup:QuestionGroup = this.data.questionGroups.find(matchingPropertyPredicate("uuid", questionGroupUuid));
    this.assignedQuestionGroups.removeItem(questionGroupUuid);
    this.notAssignedQuestionGroups.addItem(questionGroup);
    const index:number = this.tempAssignedQuestionGroups.findIndex(element => { element === questionGroupUuid });
    this.tempAssignedQuestionGroups.splice(index, 1);
  }

  handleSaveButtonClick():void {
    this.data.assignedQuestionGroups.length = 0;
    for(let questionGroupUuid of this.tempAssignedQuestionGroups) {
      this.data.assignedQuestionGroups.push(questionGroupUuid);
    }
  }

  sortQuestionGroups(questionGroupAssignment:QuestionGroupAssignment, questionGroupSortingMethod:QuestionGroupSortingMethod):void {
    const target:ResolvedQuestionGroups = questionGroupAssignment === QuestionGroupAssignment.NotAssigned ? this.notAssignedQuestionGroups : this.assignedQuestionGroups;
    
    if(target.questionGroupSortingMethod !== questionGroupSortingMethod) {
      target.questionGroupSortingMethod = questionGroupSortingMethod;
      target.sortElements();
    }
  }

}
