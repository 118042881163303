import { Component, OnInit } from '@angular/core';
import { Permission } from 'src/app/classes/model/permissions';
import { PermissionService } from 'src/app/services/common/permission.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  canReadStatistics:boolean;

  constructor(
    private permissionService:PermissionService
  ) {}

  ngOnInit():void {
    this.canReadStatistics = this.permissionService.isLoggedUserHasPermission(Permission.StatisticsRead);
  }

}
