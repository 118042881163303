<div class = "dialog-header">
    <div class = "title">
        Viszonteladó információ
    </div>
    <button
        mat-dialog-close
        class = "close-button"
    >
        <mat-icon> close </mat-icon>
    </button>
    
</div>
<mat-tab-group mat-stretch-tabs class = "tab-group" animationDuration = "0ms">
    <mat-tab label = "ℹ️ Alapinformációk">
        <app-vendor-base-informations-tab
            [vendor] = "data.vendor"
        ></app-vendor-base-informations-tab>
    </mat-tab>
    <mat-tab label = "🛒 Vásárlások">
        <app-vendor-purchases-tab
            [vendor] = "data.vendor"
            [vendorPackages]="data.vendorPackages"
        ></app-vendor-purchases-tab>
    </mat-tab>
    <mat-tab label = "🎁 Ajándékok">
        <app-vendor-gifts-tab
            [vendor] = "data.vendor"
            [vendorPackages]="data.vendorPackages"
        ></app-vendor-gifts-tab> 
    </mat-tab>
    <mat-tab label = "📄 Dokumentumok">
        <app-vendor-documents-tab
            [vendor] = "data.vendor"
        ></app-vendor-documents-tab> 
    </mat-tab>
</mat-tab-group>