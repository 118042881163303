<mat-card *ngIf = "question" class = "question-card">
    <div [id] = "getContainerID()" class = "question-main-container">
        <div class = "question-header" (click) = "toogleCardExpansion()">
            <div class = "question-questiontext">
                <span *ngIf = "question?.metadata?.id"> [ {{ question.metadata.id }} ] </span>
                {{ question.text }}
            </div>
            <div class = "question-edit-button-container">
                <button mat-mini-fab color = "primary" (click) = "openQuestionModifyDialog($event)">
                    <mat-icon> edit </mat-icon>
                </button>
            </div>
            <div class = "question-expand-button-container">
                <button mat-mini-fab color = "primary"  class = "question-expand-button">
                    <mat-icon> {{ getButtonIcon() }} </mat-icon>
                </button>
            </div>
        </div>
        
        <div [@expand] = "isExpanded ? 'expanded' : 'retracted'" class = "question-body" >
            <div class = "question-media-container">
                <div class = "question-media-title"> Média: </div>
                <div class = "question-media">
                    <!-- No media -->
                    <div *ngIf = "getQuestionMediaType() == MediaType.None" class = "question-media-no-media"> A kérdéshez nem tartozik média. </div>
                    <!-- Image media -->
                    <img *ngIf = "getQuestionMediaType() == MediaType.Image" [src] = "question.mediaUrl" draggable = "false" />
                    <!-- Video media -->
                    <video *ngIf = "getQuestionMediaType() == MediaType.Video" [src] = "question.mediaUrl" controls></video>
                    <div *ngIf = "question.hasIllustration" class = "question-media-illustration"> illusztráció (vizsgában nem jelenik meg) </div>
                </div>
            </div>
            <div class = "question-options-container">
                <div class = "question-options-title" *ngIf = "question.type === 'simple'"> Válaszlehetőségek: </div>
                <div class = "question-options-title" *ngIf = "question.type === 'arrangeable'"> Helyes sorrend: </div>
                <div *ngFor = "let option of question.options; let i = index" [class] = "getOptionClass(i)">
                    {{ option.text }}
                </div>
            </div>
            <div class = "question-questiongroups-container">
                <div class = "question-questiongroups-title"> Kérdéscsoportok: </div>
                <div *ngFor = "let assignedQuestionGroup of question.questionGroups" class = "question-questiongroup-div">
                    {{ getQuestionGroupName(assignedQuestionGroup) }}
                </div>
                <div *ngIf = "question.questionGroups.length === 0" class = "question-questiongroups-no-questiongroups">
                    Jelenleg a kérdés nincs egyetlen kérdéscsoporthoz sem rendelve.
                </div>
            </div>
            <div class = "question-explanation-container">
                Magyarázat: <br />
                {{ question.explanation }}
            </div>
            <div class = "question-positions-container">
                <div class = "question-positions-title"> Pozíciók: </div>
                <div *ngFor = "let positions of question.categories" class = "question-positions-div">
                    {{ getCategoryName(positions.categoryUuid) }}:
                    <span *ngFor = "let position of positions.positions"> {{ position + 1 }} </span>
                </div>
                <div *ngIf = "question.questionGroups.length === 0" class = "question-positions-no-positions">
                    Jelenleg a kérdés nincs egyetlen pozícióhoz sem rendelve.
                </div>
            </div>
            <div class = "question-footer">
                <div>
                    <div class = "question-isupdated">
                        {{ question.isUpdated ? "Frissítve" : "Nincs frissítve" }}
                    </div>
                </div>
                <div>
                    <div *ngIf = "hasMetadata()" class = "question-metadata">
                        Utoljára módosítva: {{ getModificationDate() }} ({{ question.metadata.lastModifier }})
                    </div>
                </div>
            </div>
            
        </div>    
    </div>
</mat-card>
