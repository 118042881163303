import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/common/settings.service';
import { RevisionNumberService } from 'src/app/services/revision-number.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  
  revisionNumber:string;
  selectedServerAddress:string;

  constructor(
    private settingsService:SettingsService,
    private revisionNumberService:RevisionNumberService
  ) { }

  ngOnInit(): void {
    this.revisionNumber = "?";
    this.loadRevisionNumber();
    this.selectedServerAddress = this.settingsService.getSettingsValue("savedEnvironment");
  }

  public async loadRevisionNumber():Promise<void> {
    try {
      this.revisionNumber = await this.revisionNumberService.getRevisionNumber();
    } catch(error:any) {}
  }

}
