import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CriticalPointPositionInPath } from 'src/app/classes/model/practice-path';

export type OtherPracticePathsToCriticalPointAssignedToDialogData = {
  criticalPointPositionInPaths:Array<CriticalPointPositionInPath>;
}

@Component({
  selector: 'app-other-practice-paths-to-critical-point-assigned-to-dialog',
  templateUrl: './other-practice-paths-to-critical-point-assigned-to-dialog.component.html',
  styleUrls: ['./other-practice-paths-to-critical-point-assigned-to-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OtherPracticePathsToCriticalPointAssignedToDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data:OtherPracticePathsToCriticalPointAssignedToDialogData,
    protected activatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {}

  getRouterLink(criticalPointPositionInPath:CriticalPointPositionInPath){
    return ['../..', criticalPointPositionInPath.practicePathUuid, 'video-edit' ];
  }

}
