import { NotifierService } from 'src/app/services/common/notifier-service.service';
import { MrVegasTaskService, TaskProgressResponse } from './../../../services/mr-vegas-task-service';
import { Component, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type MrVegasProgressDialogInput = {
  taskId: string
  dialogTitle?:string;
};

export type MrVegasProgressDialogOutput = {
  // null, ha egyszer se tudta lekérdezni
  lastTaskState: TaskProgressResponse | null;
}

@Component({
  selector: 'app-mrvegas-task-progress-dialog',
  templateUrl: './mrvegas-task-progress-dialog.component.html',
  styleUrls: ['./mrvegas-task-progress-dialog.component.scss']
})
export class MrvegasTaskProgressDialogComponent implements OnInit, OnDestroy {
  readonly taskUpdateIntervalInMs: number = 15000;

  currentTaskState: TaskProgressResponse | null = null;
  unexpectedErrorHappen: boolean = false;

  isDestroyed: boolean = false;

  constructor(
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA)
    protected data: MrVegasProgressDialogInput,
    private matDialogRef: MatDialogRef<MrvegasTaskProgressDialogComponent, MrVegasProgressDialogOutput|null>,
    protected mrvegasTaskService: MrVegasTaskService) { }

  static openDialog(matDialog: MatDialog, data: MrVegasProgressDialogInput): MatDialogRef<MrvegasTaskProgressDialogComponent, MrVegasProgressDialogOutput> {
    return matDialog.open<MrvegasTaskProgressDialogComponent, MrVegasProgressDialogInput, MrVegasProgressDialogOutput>(MrvegasTaskProgressDialogComponent, {
      data: data,
      disableClose: true
    });
  }

  ngOnInit(): void {
    this.initTaskUpdater();
  }

  async initTaskUpdater(){
    try{
      const lastState = await this.mrvegasTaskService.waitForVideoConvertTask(this.data.taskId, (currentState: TaskProgressResponse) => {
        if(this.isDestroyed)return;

        this.currentTaskState = currentState;
        console.log(this.currentTaskState);
      },this.taskUpdateIntervalInMs);

      this.currentTaskState = lastState;
    }catch(e){
      this.unexpectedErrorHappen = true;
    }
  }

  ngOnDestroy(): void {
    this.isDestroyed = true;
  }


  onTapClose(){
    this.matDialogRef.close({lastTaskState:this.currentTaskState});
  }


  // A bezárás gomb csak bizonyos esetekben van megjelenítve.
  // Csak akkor ha egy végállapothoz jutottunk el
  shouldCloseButtonVisible() {
    return this.unexpectedErrorHappen || this.currentTaskState?.state == "DONE" || this.currentTaskState?.state == "ERROR";
  }
}
