import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

@Injectable({
    providedIn:'root'
})
export class NavigationQueueService {
    private queue = Promise.resolve(true);

    constructor(private readonly router: Router) {}

    /**
     * Starts a navigation with the Angular's built in Router ( @see Router ).
     * If a navigation is already running, this waits for it's resolving and then
     * starts the next one. For the queueing it uses promise chaining.
     * 
     * @param commands commands for the navigation @see Router
     * @param extras extras for the navigation @see Router
     * @returns 
     */
    public navigate(commands: any[], extras?: NavigationExtras) {
        const enqueue = async () => {
            try {
                await this.queue;
            } catch(error) {
                console.error(error);
            }
            return await this.router.navigate(commands, extras);
        };
        this.queue = enqueue();
        return this.queue;
    }
}

