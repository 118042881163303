import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { QuestionChange } from 'src/app/classes/model/changelog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { QuestionService } from 'src/app/services/question.service';
import { Question } from 'src/app/classes/model/question';
import { MatDialog } from '@angular/material/dialog';
import { ViewChangeDialogComponent } from './view-change-dialog/view-change-dialog.component';
import { ChangelogService } from 'src/app/services/changelog.service';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator:MatPaginator;

  questions:ReadonlyArray<Readonly<Question>>;
  dataSource:MatTableDataSource<Readonly<QuestionChange>>;
  displayedColumns:ReadonlyArray<string> = [ "date", "revision", "question-uuid", "operation", "user" ];
  isContentLoading:boolean;

  constructor(
    private changelogService:ChangelogService,
    private questionService:QuestionService,
    public dialogService:MatDialog
  ) { }

  ngOnInit(): void {
    this.questions = this.questionService.getQuestionArrayRef();
    this.dataSource = new MatTableDataSource<Readonly<QuestionChange>>();
    this.loadChangelog();
  }

  ngAfterViewInit():void {
    this.dataSource.paginator = this.paginator;
  }

  private async loadChangelog():Promise<void> {
    this.dataSource.data = [];
    this.isContentLoading = true;
    try {
      await this.changelogService.loadDataIntoCache();
    } catch(error:any) {}
    this.dataSource.data = this.changelogService.getChangesArrayRef() as Array<Readonly<QuestionChange>>;
    this.isContentLoading = false;
  }

  public getDisplayedOperationName(questionChange:QuestionChange):string {
    switch(questionChange.type) {
      case "add":     return "Hozzáadás";
      case "modify":  return "Módosítás";
      case "delete":  return "Törlés";
      default:        return "Ismeretlen";
    }
  }

  handleUuidClick(change:QuestionChange):void {
    this.dialogService.open(ViewChangeDialogComponent, {
      data: {
        change: change
      },
      disableClose: true,
      height: "80vh"
    });
  }

  public async handleReloadButtonClick():Promise<void> {
    await this.loadChangelog();
  }

}
