<button
  mat-mini-fab
  [matMenuTriggerFor]="orderMenu"
  style="background-color: #f05154"
>
  <mat-icon> more_vert </mat-icon>
</button>

<mat-menu
  #orderMenu="matMenu"
  class="order-menu"
>
  <button
    mat-menu-item
    *ngFor="let sortingFunctionOption of availableSortingOptions()"
    (click)="updatePracticePathSorting(sortingFunctionOption)"
  >
    <mat-icon>
      {{
        sortingFunctionOption === selectedSortingOption
          ? "check"
          : "noise_control_off"
      }}
    </mat-icon>
    <span>
      {{ sortingFunctionOption.displayedName }}
    </span>
  </button>
</mat-menu>
