import { Injectable } from '@angular/core';
import { DataCacherService } from './data-cacher-service';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { HttpResponseData } from '../classes/http-communication';
import { AdminRole } from '../classes/model/admin-user';
import { matchingPropertyPredicate } from '../functions/misc';
import { Permission, PermissionString } from '../classes/model/permissions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdminRoleUpdatingDialogComponent } from '../components/dashboard/content/admins/admin-roles/admin-role-updating-dialog/admin-role-updating-dialog.component';
import { Subscription } from 'rxjs';
import {PracticeCityZone} from '../classes/model/practice-city-zone';


@Injectable({
  providedIn: 'root'
})
export class AdminRoleService implements DataCacherService {
  private readonly adminRolesApiUrlFragment:string = "/api/admin/admins/roles";
  private readonly deleteAdminRoleApiUrlFragment:string = "/api/admin/admins/roles/delete";
  private readonly modifyAdminRoleApiUrlFragment:string = "/api/admin/admins/roles/modify";

  private adminRoles:Array<AdminRole>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.ServerManager ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService,
    private dialogService:MatDialog
  ) {
    this.adminRoles = new Array<AdminRole>();
  }

  public getAdminRolesArrayRef():ReadonlyArray<AdminRole> {
    return this.adminRoles;
  }

  public async fetchAdminRoles():Promise<Array<AdminRole>> {
    let adminRoles:Array<AdminRole> = [];

    try {
      const response:HttpResponseData<Array<AdminRole>> = await this.backendService.callApi(this.adminRolesApiUrlFragment, "GET");
      adminRoles = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a jogosultságok lekérdezése közben.");
    }

    return adminRoles;
  }

  public async addAdminRole(adminRole:AdminRole):Promise<void> {
    const httpBody:Object = {
      adminRole: adminRole,

    };

    try {
      await this.backendService.callApi(this.adminRolesApiUrlFragment, "PUT", httpBody);
      this.adminRoles.push(adminRole);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az admin szerepkör létrehozásakor.");
    }
  }

  public async deleteAdminRole(adminRoleName:string, removedPermissionAffects:Array<RemovedPermissionAffect>):Promise<void> {
    const httpBody:Object = {
      deletedAdminRoleName: adminRoleName,
      removedPermissionAffects: removedPermissionAffects
    }

    try {
      await this.backendService.callApi(this.deleteAdminRoleApiUrlFragment, "POST", httpBody);
      this.adminRoles.removeItems(matchingPropertyPredicate("name", adminRoleName));
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az admin szerepkör törlésekor.");
    }
  }

  public async modifyAdminRole(adminRole:AdminRole, removedPermissionAffects:Array<RemovedPermissionAffect>):Promise<void> {
    const httpBody:Object = {
      adminRole: adminRole,
      removedPermissionAffects: removedPermissionAffects
    };

    try {
      await this.backendService.callApi(this.modifyAdminRoleApiUrlFragment, "POST", httpBody);
      this.adminRoles.replaceItems(matchingPropertyPredicate("name", adminRole.name), adminRole);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az admin szerepkör módosításakor.");
    }
  }

  public openConfirmAdminRoleUpdatingDialog(title:string, infoString:string, adminRole:AdminRole, removedPermissions:Array<PermissionString>):Promise<Array<RemovedPermissionAffect>|null> {
    return new Promise<Array<RemovedPermissionAffect>|null>(
      (resolve, reject) => {
        const dialogRef:MatDialogRef<AdminRoleUpdatingDialogComponent, Array<RemovedPermissionAffect>> = this.dialogService.open(
          AdminRoleUpdatingDialogComponent,
          {
            data: {
              title: title,
              infoString: infoString,
              removedPermissions: removedPermissions,
              adminRole: adminRole
            },
            disableClose: true
          }
        );

        const dialogClosedSubscription:Subscription = dialogRef.afterClosed().subscribe(
          (removedPermissionAffects:Array<RemovedPermissionAffect>) => {
            dialogClosedSubscription.unsubscribe();

            if(removedPermissionAffects) {
              resolve(removedPermissionAffects);
            } else {
              resolve(null);
            }
          }
        )
      }
    );
  }

  public async loadDataIntoCache(): Promise<void> {
    const adminRoles:Array<AdminRole> = await this.fetchAdminRoles();
    this.adminRoles.replaceArrayElements(adminRoles);
  }

  public getRequiredPermissionsForDataLoading(): readonly PermissionString[] {
    return this.requiredPermissionsForDataLoading;
  }

  public clearCachedData():void {
    this.adminRoles.length = 0;
  }

  public getNameOfCachedData(): string {
    return "Adminisztrátor szerepkörök";
  }

}

export type RemovedPermissionAffect = {
  permissionName:PermissionString;
  affectedAdminUuids:Array<string>;
}
