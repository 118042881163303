import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Permission, PermissionAssignableToString, permissionAssignableToValues, PermissionGroup } from 'src/app/classes/model/permissions';
import { matchingPropertyPredicate } from 'src/app/functions/misc';
import { ConfirmationDialogService } from 'src/app/modules/confirmation-dialog/services/confirmation-dialog.service';
import { PermissionGroupsService } from 'src/app/services/permission-groups.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { PermissionEditorDialogComponent } from './permission-editor-dialog/permission-editor-dialog.component';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {

  displayedPermissions:Array<DisplayedPermission> = [];
  permissionGroups:ReadonlyArray<PermissionGroup> = [];

  isContentLoading:boolean = false;

  permissionAssignableToValues = permissionAssignableToValues;

  constructor(
    private permissionsService:PermissionsService,
    private permissionGroupService:PermissionGroupsService,
    private dialogService:MatDialog,
    private confirmationDialogService:ConfirmationDialogService,
    private snackBarService:MatSnackBar
  ) { }

  public ngOnInit():void {
    this.permissionGroups = this.permissionGroupService.getPermissionGroupsArrayRef();
    this.loadData();
  }

  private async loadData():Promise<void> {
    this.isContentLoading = true;

    let permissions:ReadonlyArray<Permission> = [];
    try {
      permissions = await this.permissionsService.fetchPermissions();
      await this.permissionGroupService.loadDataIntoCache();
      this.displayedPermissions.replaceArrayElements(permissions.map(this.mapPermissionToDisplayed));
    } catch(error:any) {}

    this.isContentLoading = false;
  }

  private mapPermissionToDisplayed:(permission:Permission) => DisplayedPermission = (permission:Permission) => {
    const displayedPermission:DisplayedPermission = permission as DisplayedPermission;
    displayedPermission.permissionGroupDisplayedName = this.permissionGroups.find(matchingPropertyPredicate("name", permission.permissionGroup))?.displayedName ?? "-";
    displayedPermission.displayedAssignedToNames = permission.assignableTo.map(
      (assignableToItem:PermissionAssignableToString) => {
        return permissionAssignableToValues.find(
          matchingPropertyPredicate("name", assignableToItem)
        )?.displayedName ?? "Ismeretlen";
      }
    );
    return displayedPermission;
  }

  public handleAddButtonClick():void {
    const dialogRef:MatDialogRef<PermissionEditorDialogComponent, Permission> = this.dialogService.open(
      PermissionEditorDialogComponent,
      {
        data: {
          dialogMode: "add",
          permissionGroups: this.permissionGroups
        },
        disableClose: true
      }
    );

    const dialogCloseSubscription:Subscription = dialogRef.afterClosed().subscribe(
      (permission:Permission) => {
        dialogCloseSubscription.unsubscribe();
        if(permission) {
          this.displayedPermissions.push(this.mapPermissionToDisplayed(permission));
        }
      }
    );
  }

  public handleEditButtonClick(permission:Permission):void {
    const dialogRef:MatDialogRef<PermissionEditorDialogComponent, Permission> = this.dialogService.open(
      PermissionEditorDialogComponent,
      {
        data: {
          dialogMode: "edit",
          permissionGroups: this.permissionGroups,
          permission: permission
        },
        disableClose: true
      }
    );

    const dialogCloseSubscription:Subscription = dialogRef.afterClosed().subscribe(
      (permission:Permission) => {
        dialogCloseSubscription.unsubscribe();
        if(permission) {
          this.displayedPermissions.replaceItems(matchingPropertyPredicate("name", permission.name), this.mapPermissionToDisplayed(permission));
        }
      }
    );
  }

  public async handleDeleteButtonClick(permission:Permission):Promise<void> {
    this.confirmationDialogService.open(
      "Jogosultság csoport törlése",
      "Biztosan törlöd?",
      async () => {
        try {
          await this.permissionsService.deletePermission(permission.name);
          this.displayedPermissions.removeItems(matchingPropertyPredicate("name", permission.name));
          this.snackBarService.open("Sikeres jogosultság tölrés", "Bezár", { duration: 3000, panelClass: "mat-snackbar-success" });
        } catch(error:any) {}
      }
    );
  }

}

class DisplayedPermission extends Permission {
  permissionGroupDisplayedName:string;
  displayedAssignedToNames:Array<string>;
}
