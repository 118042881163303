import { BehaviorSubject } from "rxjs";
import { MenuWindowOverlay } from "./overlays/menu-window-overlay";
import { SingleMarkerController } from "./single-marker-controller";
import { LatLng } from "../../models/LatLng";
import { MarkeredPolylineController } from "./markered-polyline-controller";
import { _countGroupLabelsBeforeOption } from "@angular/material/core";
import { Subject } from "rxjs";
import { disconnect } from "process";

// Amikor változik valami a térképen, akkor frissítsük hozzá az underlying adatot is
// Ha pedig underlie változik valami (nyílván kintről), akkor frissítsük hozzá a map szintén lévő objektumokat is
// Ezen keresztül lehet vezérelni a térképet
export class GoogleMapExtendedController {
  private markeredPolylineControllers: MarkeredPolylineController[] = [];
  private singleMarkerControllers: SingleMarkerController[] = [];
  private _onTap: Subject<LatLng> = new Subject<LatLng>();
  

  // root zone hívja, figyelni kell, hogy váltsunk ngZone-ra
  public isStreetViewMode = new BehaviorSubject<boolean>(false); // Street view módban van-e a térkép
  public lastLatLngPositionOfCursor = new BehaviorSubject<LatLng>({latitude: 0, longitude: 0}); // Utolsó ismert pozíció, ahol a kurzor volt


  // A paraméterben kapott térképet fogja vezérelni
  constructor(public map: google.maps.Map) {
    this.addListeners();
  }

  public get onTap() {
    return this._onTap.asObservable();
  }

  dispose() {
    this.markeredPolylineControllers?.forEach((m) => m.dispose());
    this.singleMarkerControllers?.forEach((sm) => sm.dispose());
    this._onTap?.unsubscribe();
    this.isStreetViewMode?.unsubscribe();
    this.markeredPolylineControllers = [];
    this.singleMarkerControllers = [];

    if(this.map){
      google.maps.event.clearInstanceListeners(this.map?.getStreetView());
      google.maps.event.clearInstanceListeners(this.map);
    }

    this.map = null;
  }

  addListeners() {
    this.map.addListener("idle", () => {
      const newCenter = this.map.getBounds()?.getCenter();
      for (const mk of this.markeredPolylineControllers) {
        mk.renderMarkersBasedOnCenter({
          latitude: newCenter!.lat(),
          longitude: newCenter!.lng(),
        });
      }
    });

    this.map.addListener("mousemove", (event) => {
      this.lastLatLngPositionOfCursor.next({
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng(),
      });
    });



    const thePanorama = this.map.getStreetView();
    google.maps.event.addListener(thePanorama, "visible_changed", () => {
      this.isStreetViewMode.next(thePanorama.getVisible());
    });
  }


  public getZoomLevel(): number {
    return this.map.getZoom();
  }

  public setZoomLevel(zoomLevel: number){
    this.map.setZoom(zoomLevel);
  }

  private latLngBoundToPolygon(latLngBound: google.maps.LatLngBounds) : LatLng[]{
    const ne = latLngBound.getNorthEast();
    const sw = latLngBound.getSouthWest();
    const nw = new google.maps.LatLng(ne.lat(), sw.lng());
    const se = new google.maps.LatLng(sw.lat(), ne.lng());
    const path = [ne, nw, sw, se];

    return path.map((p) => {
      return {
        latitude: p.lat(),
        longitude: p.lng(),
      };
    });
  }

  public createRectanglePolygonFromCenterRelativeToZoomLevel(
    center: LatLng,
    width: number,
    height: number,
    zoomLevel: number
  ) : LatLng[]{
    const bounds = new google.maps.LatLngBounds();
    const centerLatLng = new google.maps.LatLng(center.latitude, center.longitude);

    const centerPixel = this.map.getProjection().fromLatLngToPoint(centerLatLng);

    // Generated by copilot
    const pixelWidth = width / Math.pow(2, zoomLevel);
    const pixelHeight = height /  Math.pow(2, zoomLevel);
    const swPixel = new google.maps.Point(
      centerPixel.x - pixelWidth / 2,
      centerPixel.y + pixelHeight / 2
    );
    const nePixel = new google.maps.Point(
      centerPixel.x + pixelWidth / 2,
      centerPixel.y - pixelHeight / 2
    );

    const swLatLng = this.map.getProjection().fromPointToLatLng(swPixel);
    const neLatLng = this.map.getProjection().fromPointToLatLng(nePixel);
    bounds.extend(swLatLng);
    bounds.extend(neLatLng);

    return this.latLngBoundToPolygon(bounds);
  }


  public getCenterOfMap() : LatLng {
    const center = this.map.getCenter();
    return {
      latitude: center.lat(),
      longitude: center.lng(),
    }
  }

  public addSingleMarkerController(
    singleMarkerController: SingleMarkerController
  ) {
    this.singleMarkerControllers.push(singleMarkerController);
    singleMarkerController.setMap(this.map);
  }

  public addMarkeredPolylineController(
    markeredPolylineController: MarkeredPolylineController
  ) {
    this.markeredPolylineControllers.push(markeredPolylineController);
    markeredPolylineController.setMap(this.map);
  }

  public removeMarkeredPolylineController(controller: MarkeredPolylineController){
    const ind = this.markeredPolylineControllers.indexOf(controller);
    if(ind != -1){
      this.markeredPolylineControllers[ind].dispose();
      this.markeredPolylineControllers.splice(ind, 1);
    }
  }

  public exitFullscreen() {
    if (document.fullscreenElement == null) return;
    const d = document as any;

    if (d.exitFullscreen) {
      d.exitFullscreen();
    } else if (d.msExitFullscreen) {
      d.msExitFullscreen();
    } else if (d.mozCancelFullScreen) {
      d.mozCancelFullScreen();
    } else if (d.webkitExitFullscreen) {
      d.webkitExitFullscreen();
    }
  }

  public getMarkeredPolylineControllers(): Array<MarkeredPolylineController> {
    return this.markeredPolylineControllers;
  }

  removeAllOverlay(){
    this.overlays.forEach((overlay) => {
      overlay.setMap(null);
      overlay.dispose();
    });
    this.overlays = [];
  }

  removeOverlay(overlay: MenuWindowOverlay) {
    const ind = this.overlays.indexOf(overlay);
    if (ind == -1) return;
    this.overlays.splice(ind, 1);
    overlay.setMap(null);
    overlay.dispose();
  }

  // Bármilyen drag event-nél bezáródik
  overlays: MenuWindowOverlay[] = [];
  public showMenu(overlay: MenuWindowOverlay) {
    overlay.setMap(this.map);
    this.overlays.push(overlay);

    const tmpListenerZoom = this.map.addListener("zoom_changed", () => {
      this.removeOverlay(overlay);
      tmpListenerZoom.remove();
    });
    const tmpListenerClick = this.map.addListener("click", () => {
      this.removeOverlay(overlay);
      tmpListenerClick.remove();
    });
  }

  // Kizárólag a térkép hívhatja tap esetén!
  onTapGoogleMap(position: LatLng) {
    this._onTap.next(position);
  }

  setCenter(position: LatLng) {
    this.map.setCenter({
      lat: position.latitude,
      lng: position.longitude,
    });
  }
}
