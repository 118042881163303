import { Component, Input } from '@angular/core';
import { PracticalModuleLog } from '../../models/practical-module-log.model';

@Component({
  selector: 'app-practical-module-logs-list',
  templateUrl: './practical-module-logs-list.component.html',
  styleUrls: ['./practical-module-logs-list.component.scss']
})
export class PracticalModuleLogsListComponent {
  @Input() practicalModuleLogs:Array<PracticalModuleLog>;
}
