import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import videojs from 'video.js';
import 'videojs-vr/dist/videojs-vr.js';

@Component({
  selector: 'app-videojs-player',
  templateUrl: './videojs-player.component.html',
  styleUrls: ['./videojs-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideojsPlayerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('video', {static: true}) videoElement:ElementRef<HTMLVideoElement>;

  @Input() options:VideoJsPlayerOptions;

  player:videojs.Player;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.player = videojs(this.videoElement.nativeElement, this.options);
    this.player.vr({ projection: "360" });
  }

  ngOnDestroy(): void {
    if(this.player) {
      this.player.dispose();
    }
  }

}

export type VideoJsPlayerOptions = {
  aspectRatio?:string,
  autoplay?:boolean,
  controls?:boolean,
  loop?:boolean,
  sources:Array<{
    src:string,
    type:string,
  }>,
};