<div class="mainContainer" [ngClass]="{
  'mainContainerClosed' : !isOpened,
  'mainContainerOpened' : isOpened
  }
  ">

  <div *ngIf="!isOpened; else closeButton;">
    <button (click)="onTapMenu()" mat-raised-button color="primary">
      {{this.menuName}}
    </button>
  </div>

  <ng-template #closeButton>
    <button class="btn-close" (click)="onTapMenu()" #closeButton>
      ✕
    </button>
  </ng-template>

  <div *ngIf="isOpened" class="header">
    Beállítások
  </div>

  <div class="radio-button-flexbox-wrapper" *ngIf="isOpened">
    <div *ngFor="let item of values">
      <div class="radio-button">
        {{item.getDisplayedName()}}
        <mat-slide-toggle
          (change)="onToggleChange(item.getId(),$event)"
          [(ngModel)]="item.isMarkerVisible"
          [disableRipple]="true"
        ></mat-slide-toggle>
      </div>
    </div>
  </div>
</div>
