import { LatLng } from "../../models/LatLng";

export class UnderlyingMarker {
  constructor(public position: LatLng) {}
}

// Ezt a markert jelenítjük meg
export type RenderedMarker = {
  renderedMarker: google.maps.Marker;
  underlyingMarker: UnderlyingMarker;
  isSelected: boolean;
};

export type MarkerStyle = {
  defaultIcon: string | google.maps.Icon | google.maps.Symbol;
  selectedIcon: string | google.maps.Icon | google.maps.Symbol;
};
