import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { ComponentType } from '@angular/cdk/overlay'
import { DraggableDialogContentWrapperComponent } from "../components/draggable-dialog-content-wrapper/draggable-dialog-content-wrapper.component";
import { DraggableDialogConfig } from "../model/draggable-dialog-config";
import { DraggableDialogContentWrapperData } from "../model/draggable-dialog-content-wrapper-data";

@Injectable()
export class DraggableDialogService {
    constructor(private dialogService:MatDialog) {}

    public open<
      DialogContentComponent,
      DialogInput = any,
      DialogOutput = any
    >(
      componentType:ComponentType<DialogContentComponent>,
      matDialogConfig:MatDialogConfig<DialogInput>,
      draggableDialogConfig:DraggableDialogConfig
    ):MatDialogRef<DraggableDialogContentWrapperComponent<DialogContentComponent, DialogInput>, DialogOutput> {
      // Get the panel class's value as a string array (empty if none provided)
      const originalPanelClasses:Array<string> = matDialogConfig.panelClass ?
      (typeof matDialogConfig.panelClass === "string" ? [ matDialogConfig.panelClass ] : matDialogConfig.panelClass ) :
      [];
      
      // Setup the dialog config for the wrapper component
      const dialogConfig:MatDialogConfig<DialogInput&DraggableDialogContentWrapperData<DialogContentComponent>> = {
        ...matDialogConfig,
        data: {
          ...matDialogConfig.data ?? ({} as DialogInput),
          contentComponentType: componentType,
          daggableDialogConfig: draggableDialogConfig
        },
        panelClass: [ ...originalPanelClasses, "draggable-dialog-panel" ],
        hasBackdrop: false,
        disableClose: true
      };

      return this.dialogService.open<
        DraggableDialogContentWrapperComponent<DialogContentComponent, DialogInput>,
        DialogInput,
        DialogOutput
      >(
        DraggableDialogContentWrapperComponent,
        dialogConfig
      );
    }

}