export class AppNotification {
    title:string;
    message:string;
    route:string;
    type:"single"|"all";
    userUuid?:string;
    createdOn:number;
    createdBy:string;
}

export function appNotificationSorter(appNotification1:AppNotification, appNotification2:AppNotification):number {
    return appNotification2.createdOn - appNotification1.createdOn;
}