import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from 'src/app/services/category.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Category } from 'src/app/classes/model/category';
import { CustomValidators } from 'src/app/classes/custom-validators';

class CategoryQuestionPosition {
  position:number;
  numberOfQuestions:number;
  point:number;
}

@Component({
  selector: 'app-category-question-position-edit-dialog',
  templateUrl: './category-question-position-edit-dialog.component.html',
  styleUrls: ['./category-question-position-edit-dialog.component.scss']
})
export class CategoryQuestionPositionEditDialogComponent implements OnInit {
  categoryQuestionPositionFG:FormGroup;

  tableData:CategoryQuestionPosition[];

  constructor(
    private dialogRef:MatDialogRef<CategoryQuestionPosition>,
    @Inject(MAT_DIALOG_DATA) public data:{ category:Category },
    private categoryService:CategoryService,
    private snackBar:MatSnackBar
  ) { }

  ngOnInit(): void {
    this.categoryQuestionPositionFG = new FormGroup({});

    this.tableData = new Array<CategoryQuestionPosition>();
    let position:number = 1;
    for(let distributionRule of this.data.category.distributionRules) {
      this.tableData.push({ position: position, numberOfQuestions: distributionRule.questions.length, point: distributionRule.point });
      this.categoryQuestionPositionFG.addControl(
        "categoryQuestionPositionPoint" + position + "FC",
        new FormControl(distributionRule.point, [ Validators.required, CustomValidators.positiveWholeNumber ]));
      ++position;
    }
  }

  pointFormControlHasError(position:number, errorName:string):boolean {
    return this.categoryQuestionPositionFG.controls["categoryQuestionPositionPoint" + position + "FC"].hasError(errorName);
  }

  getQueryParams(position:number):Object {
    return {
      category: this.data.category.uuid,
      position: position - 1
    }
  }

  handleSaveButtonClick():void {
    if(this.categoryQuestionPositionFG.valid) {
      for(let position = 1; position <= this.data.category.distributionRules.length; ++position) {
        this.data.category.distributionRules[position - 1].point = this.categoryQuestionPositionFG.controls["categoryQuestionPositionPoint" + position + "FC"].value;
      }

      this.modifyQuestionPointsInCategory(this.data.category);
      this.dialogRef.close();
    }
  }

  private async modifyQuestionPointsInCategory(category:Category):Promise<void> {
    try {
      await this.categoryService.modifyCategory(category);
      this.snackBar.open("Kategória pozíció pontszámok sikeresen módosítva", "Bezár", { duration: 2000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}
  }

}
