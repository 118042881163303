<app-page-header
    title = "Kiosztott ajándékok"
    subtitle = "Az adminok által manuálisan kiosztott ajándékokat találhatod itt."
    style = "margin-bottom: 20px;"
></app-page-header>

<ng-template [ngIf] = "!isContentLoading">
    <ng-template [ngIf] = "handedOutGifts.length > 0">
        <table>
            <thead>
                <td class = "sender-column">
                    Küldő
                </td>
                <td class = "sent-at-column">
                    Kiküldve
                </td>
                <td class = "redeemed-column">
                    Beváltva
                </td>
                <td class = "receiver-column">
                    Ajándékozott
                </td>
                <td class = "package-column">
                    Csomag (mennyiség)
                </td>
            </thead>
            <tbody>
                <tr *ngFor = "let gift of handedOutGifts">
                    <td>
                        {{ gift.senderAdminEmail }}
                    </td>
                    <td>
                        {{ gift.sendingTimestamp | date: "YYYY-MM-dd HH:mm"}}
                    </td>
                    <td>
                        {{ gift.receivingMethod === 'throughEmail' ? (gift.isRedeemed ? 'Igen' : 'Nem' ) : '-' }}
                    </td>
                    <td>
                        <img [src] = "'assets/emojis/' + emojiSrc[gift.entityType]"  class = "entity-type-image" />
                        <span
                            [class.clickable] = "gift.entityType === 'vendor' && canViewVendorInfo || gift.entityType === 'student' && canViewStudentInfo"
                            (click) = "openInfoDialog(gift)"
                        >
                            {{ gift.displayedName }}
                        </span>
                    </td>
                    <td>
                        {{ gift.displayedPackageName }}
                        <span *ngIf = "gift.count > 1">
                            × {{ gift.count }}
                        </span>
                        <span *ngIf = "gift.receivingMethod === 'throughEmail' && gift.packageCode">
                            [{{ gift.packageCode | packageCode }}]
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-template>
    <div *ngIf = "handedOutGifts.length === 0" class = "no-data">
        Nincs jelenleg egyetlen személyre kiosztott ajándék sem!
    </div>
</ng-template>
<div *ngIf = "isContentLoading" class = "loading-container">
    <mat-spinner></mat-spinner>
</div>

<div class = "create-personal-gift-button" (click) = "openCreatePersonalGiftViaEmailDialog()">
    +
</div>