import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SettingsService, Settings } from 'src/app/services/common/settings.service';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { EnvironmentService } from 'src/app/services/common/environments/environment.service';
import { Environment, EnvironmentType, customEnvironmentTypeValue } from 'src/app/services/common/environments/environment';
import { PredefinedEnvironmentService } from 'src/app/services/common/environments/predefined-environment.service';
import { CustomEnvironmentService } from 'src/app/services/common/environments/custom-environment.service';

@Component({
  selector: 'app-server-selector-dialog',
  templateUrl: './server-selector-dialog.component.html',
  styleUrls: ['./server-selector-dialog.component.scss']
})
export class ServerSelectorDialogComponent implements OnInit, OnDestroy {
  @ViewChild("customEnvironmentHostAddressInput", { static: true })
  private customEnvironmentHostAddressInput:ElementRef<HTMLInputElement>;

  protected selectedEnvironmentType:EnvironmentType;
  protected customEnvironmentHostAddressFC:FormControl<string>;

  constructor(
    protected environmentService:EnvironmentService,
    protected predefinedEnvironmentService:PredefinedEnvironmentService,
    protected customEnvironmentService:CustomEnvironmentService,
    private dialogRef:MatDialogRef<ServerSelectorDialogComponent>
  ) { }

  public ngOnInit():void {
    this.initialize();

    this.loadSelectedEnvironment();
  }

  public ngOnDestroy():void {
    this.customEnvironmentHostAddressInput.nativeElement.removeAllListeners();
  }
  
  private initialize():void {
    // Create the custom environment's form control
    this.customEnvironmentHostAddressFC = new FormControl<string>("");

    // Add an event listener on the custom environment address's input field to select the custom
    // environment type on focus
    this.customEnvironmentHostAddressInput.nativeElement.addEventListener(
      "focus",
      () => {
        this.selectedEnvironmentType = this.customEnvironmentService.getCustomEnvironmentTypeValue();
      }
    );
  }


  private loadSelectedEnvironment():void {
    const currentEnvironment:Readonly<Environment> = this.environmentService.getCurrentEnvironment();
    this.selectedEnvironmentType = currentEnvironment.type;
    
    const defaultCustomEnvironmentHostAddressFCValue:string = 
      currentEnvironment.type === this.customEnvironmentService.getCustomEnvironmentTypeValue() ?
        currentEnvironment.serverHost : "";
    this.customEnvironmentHostAddressFC.setValue(defaultCustomEnvironmentHostAddressFCValue, { emitEvent: false });
  }

  public handleSaveButtonClick():void {
    if(this.selectedEnvironmentType === this.customEnvironmentService.getCustomEnvironmentTypeValue()) {
      this.customEnvironmentService.setCustomEnvironment(this.customEnvironmentHostAddressFC.value);
    } else {
      this.predefinedEnvironmentService.loadPredefinedEnvironment(this.selectedEnvironmentType);
    }

    this.dialogRef.close();
  }

  protected isSaveButtonEnabled():boolean {
    return this.selectedEnvironmentType !== this.customEnvironmentService.getCustomEnvironmentTypeValue() ||
      this.customEnvironmentHostAddressFC.value !== "";
  }

}
