<ng-container
    *ngIf="practicalModuleLogs.length > 0
    then displayLogs;
    else noLogs"
></ng-container>

<ng-template #displayLogs>
    <app-practical-module-log-item
        *ngFor="let practicalModuleLog of practicalModuleLogs"
        [practicalModuleLog]="practicalModuleLog"
        style="margin-bottom: 10px;"
    ></app-practical-module-log-item>
</ng-template>

<ng-template #noLogs>
    <div class = "no-logs">
        Nincsenek megjeleníthető logok.
    </div>
</ng-template>