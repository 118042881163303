export function getImageUrlAsCssBackground(imageUrl: string): Object {
  return {
    "background-image": "url('" + imageUrl + "')"
  };
}

export function matchingPropertyPredicate<ElementType, ElementTypeKeyUnion extends keyof ElementType, PropertyType = ElementType[ElementTypeKeyUnion]>(
  propertyName: ElementTypeKeyUnion, propertyValue: PropertyType): (element: ElementType) => boolean {

  return (element: ElementType) => {
    return element[propertyName] === propertyValue;
  }
}


export function createArrayWithConsecutiveNumbers(length: number, start: number = 1): Array<number> {
  const result: Array<number> = [];
  for (let number: number = start; number < start + length; ++number) {
    result.push(number);
  }
  return result;
}

export function getFullName(...nameFragments: Array<string>): string {
  return nameFragments.filter(
    (nameFragment: string) => { return nameFragment; }
  ).join(" ");
}

export type Type<T> = new (...args: any[]) => T;
