import { AfterViewInit, Component, ElementRef, Inject, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationDialogComponentData } from '../../models/confirmation-dialog-data';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit, AfterViewInit {

  @ViewChild("message") messageElementRef:ElementRef<HTMLDivElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:ConfirmationDialogComponentData,
    private domSanitizer:DomSanitizer
  ) { }

  ngOnInit():void { }

  ngAfterViewInit():void {
    // WARNING: this approach is could be vulnerable, only used to allow the style attribute
    this.messageElementRef.nativeElement.innerHTML = this.domSanitizer.sanitize(
      SecurityContext.HTML,
      this.domSanitizer.bypassSecurityTrustHtml(this.data.message)
    );
  }
  
}
