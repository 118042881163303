<div class = "video-container">
    <video #video class = "video" controls></video>
</div>
<ng-template [ngIf] = "releasedVideo != null">
    <div class = "video-data">
        <div *ngIf = "releasedVideo.releasedFinalVideoUuid" class = "video-data-item">
            <div class = "video-data-title">
                Végleges videó UUID:
            </div>
            <div class = "video-data-value">
                {{ releasedVideo.releasedFinalVideoUuid }}
            </div>
        </div>
    
        <div class = "video-data-item">
            <div class = "video-data-title">
                Kiadva:
            </div>
            <div class = "video-data-value">
                {{ releasedVideo.creationTimestamp | date: "YYYY-MM-dd HH:mm:ss" }}
            </div>
        </div>
    </div>
</ng-template>
