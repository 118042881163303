import { AudioPlayerService } from './../../services/audio-player-service';
import { ObservableArraySubscription } from "./../../models/ObservableArray";
import { TabScrollPositionRecoveryService } from "./../../../../services/tab-position-recovery.service";
import { CriticalPointAssignment } from "./../../../../classes/model/practice-path";
import { PracticePathGlobalVideoEditorPageService } from "../../services/practice-path-global-video-editor-page.service";
import { NotifierService } from 'src/app/services/common/notifier-service.service';
import { PracticePathUtil } from "../../../../services/practice-path-util";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
  Injector,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { Component, Input, OnInit } from "@angular/core";
import { CriticalPoint } from "src/app/classes/model/practice-path";
import * as uuid from "uuid";
import { ConfirmationDialogService } from "src/app/modules/confirmation-dialog/services/confirmation-dialog.service";
import { Subscription } from "rxjs";
import { Permission } from 'src/app/classes/model/permissions';
import { PermissionService } from 'src/app/services/common/permission.service';
import { PracticalModuleLogsDialogComponent } from 'src/app/modules/practical-module-logs/components/practical-module-logs-dialog/practical-module-logs-dialog.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: "app-critical-points-tab",
  templateUrl: "./critical-points-tab.component.html",
  styleUrls: ["./critical-points-tab.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CriticalPointsTabComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input()
  originalTabIndex: number = -1; // Tab group-on belüli indexe

  @ViewChild("criticalPointList", { read: ElementRef })
  criticalPointList: ElementRef;

  observableArraySubscription: ObservableArraySubscription[] = [];
  subscribers: Subscription[] = [];

  verificationStateChangesInProcess:Array<string> = [];

  constructor(
    private editorService: PracticePathUtil,
    public globalPracticePathEditor: PracticePathGlobalVideoEditorPageService,
    private confirmationDialog: ConfirmationDialogService,
    private notifierService: NotifierService,
    private scrollPositionRecovery: TabScrollPositionRecoveryService,
    private changeDetectorRef: ChangeDetectorRef,
    private audioPlayer: AudioPlayerService,
    private viewContainerRef: ViewContainerRef,
    private injector: Injector,
    private permissionService:PermissionService,
    private dialogService:MatDialog
  ) {}

  ngOnDestroy(): void {
    this.observableArraySubscription.forEach((s) => s.unsubscribe());
    this.subscribers.forEach((s) => s.unsubscribe());
  }

  ngOnInit(): void {
    // OnPush miatt nekünk kell manuálisan hívni
    // A change detectort
    // A kritikus pont tab a következő esetekben változhat:
    // Kritikus pont változás, kritikus pont hozzárendelés, vagy a videó ideje változik
    // hiszen akkor változhat a kártyák aktivitása
    this.observableArraySubscription.pushArray([
      this.globalPracticePathEditor.criticalPointAssignments$.addListener(
        () => {
          this.changeDetectorRef.markForCheck();
        }
      ),
      this.globalPracticePathEditor.criticalPointsInCorrespondingCity$.addListener(
        () => {
          this.changeDetectorRef.markForCheck();
        }
      ),
    ]);

    this.subscribers.push(
      this.globalPracticePathEditor.mainVideoPlayerController
        .getCurrentPositionInMs$()
        .subscribe(() => {
          this.changeDetectorRef.markForCheck();
        })
    );
  }

  private initScrollRecovery() {
    this.scrollPositionRecovery.createEventListenerForMainScrollContextInTab(
      this.originalTabIndex.toString(),
      this.criticalPointList.nativeElement
    );
  }

  // Az ismert idővel frissítjük
  // a megfelelő megállási pontot
  // Visszatér, hogy melyiket frissítette
  // (azt frissíti ami ahhoz az érintéshez tartozik ami a kritikus pont felvételekor történik)
  updateStopPointsWithKnownTime(
    stopPoints: {
      stopTimeInMs: number;
    }[],
    knownTime: number
  ): number {
    if (stopPoints.length === 1) {
      stopPoints[0].stopTimeInMs = knownTime;
    }

    // Ha a jelenlegi pont kisebb az első megállási pontnál is
    // akkor az ahhoz a ponthoz tartozik
    if (stopPoints[0].stopTimeInMs > knownTime) {
      stopPoints[0].stopTimeInMs = knownTime;
      return 0;
    }

    for (let i = 0; i < stopPoints.length; i++) {
      // Kizárásos alapon ezt frissítjük
      if (i == stopPoints.length - 1) {
        stopPoints[i].stopTimeInMs = knownTime;
        return i;
      }
      if (
        stopPoints[i].stopTimeInMs <= knownTime &&
        stopPoints[i + 1].stopTimeInMs > knownTime
      ) {
        stopPoints[i].stopTimeInMs = knownTime;
        return i;
      }
    }
  }

  // Megváltoztatta a kritikus pont megállását
  // Kikapcsolásnál figyelmeztetjük, hogy nem fog megállni a kritikus pontoknál
  onChangeCriticalPointStopSettings() {
    if (!this.globalPracticePathEditor.shouldStopAtCriticalPoint) {
      this.notifierService.notify(
        "warning",
        "Kikapcsoltad a megállítást, nem fogsz megállni a kritikus pontoknál!"
      );
    }
  }

  onTapEditAssignment(
    criticalPoint: CriticalPoint,
    assignment: CriticalPointAssignment
  ) {

    this.globalPracticePathEditor.generalEventEmitter.emit("criticalPointEditorOpened");
    
    this.globalPracticePathEditor.openCriticalPointEditorAndHandleSave(
      criticalPoint,
      assignment,
      this.viewContainerRef,
      this.injector
    ).finally(() => {
      this.globalPracticePathEditor.generalEventEmitter.emit("criticalPointEditorClosed");
    });

  }

  onTapRemoveAssignment(
    criticalPoint: CriticalPoint,
    assignment: CriticalPointAssignment
  ) {
    const textAboutDeletion =  criticalPoint.isLocal ? 'Mivel a kritikus pont lokális ezért végleg törlődik, nem lesz elérhető a térképen.' : 'Ez a kritikus pont globális, ezért elérhető lesz más videó számára, csak erről az útvonalról veszed le.';
    this.confirmationDialog.open(
      `Kritikus pont levétel\n${criticalPoint.title}`,
      `Biztosan törlöd a hozzárendelést?\n\n${textAboutDeletion}`,
      async () => {
        const result =
          await this.globalPracticePathEditor.removeCriticalPointAssignment(
            assignment.uuid,
            criticalPoint
          );
        if (result) {
          this.notifierService.notify(
            "success",
            "Sikeres kritikus pont levétel!"
          );
        } else {
          this.notifierService.notify("error", "Hiba lépett fel törléskor!");
        }
      }
    );
  }

  trackAssignmentByUuid(index: number, value:CriticalPointAssignment){
    return value.uuid;
  }


  onTapNarration(criticalPoint: CriticalPoint){
    if(criticalPoint.audio?.narrationForDescriptionUrl != null){
      this.audioPlayer.play(criticalPoint.audio?.narrationForDescriptionUrl);
    }else{
      this.notifierService.notify('error', 'Ehhez a kritikus ponthoz nem tartozik hang');
    }

  }

  onTapAddCriticalPoint() {
    this.globalPracticePathEditor.generalEventEmitter.emit("criticalPointEditorOpened");
    this.globalPracticePathEditor.mainVideoPlayerController.pause();

    // Itt vagyunk jelenleg
    const currentCoordinate =
      this.globalPracticePathEditor.currentPosition.getValue().position;

    // Becsült pontok
    const estimatedStopPoints = this.editorService.estimateStopPoints(
      this.globalPracticePathEditor.path$.getFullCopy(),
      {
        latitude: currentCoordinate.latitude,
        longitude: currentCoordinate.longitude,
      }
    );

    // A becsült pontokban pontosíthatjuk az egyik becslést a jelenlegi időre
    // meg kell határozni, hogy az újonnal felvett kritikus pont esetén
    // a jelenlegi időpillanat hanyadik érintést jelenti.
    // Annak az érintésnek az idejét kell frissíteni
    const updatedIndex = this.updateStopPointsWithKnownTime(
      estimatedStopPoints,
      this.globalPracticePathEditor.mainVideoPlayerController.getLastKnownPosition()
    );

    const stopPoints = estimatedStopPoints.map((ms) => {
      return {
        // Maga a Kritikus pont hozzárendelés azonosítóját a szerver hozza létre, de az abban lévő stop pont hozzárendeléseket a kliens
        stopPointUuid: uuid.v4(),
        stopTimeInMs: ms.stopTimeInMs,
        isActive: false,
      };
    });
    stopPoints[updatedIndex].isActive = true; // default csak az aktív ahol a felvétel pillanatkor vagyunk

    
    this.globalPracticePathEditor.openCriticalPointEditorAndHandleSave(
      {
        anchorPoint: currentCoordinate,
        coordinate: currentCoordinate,
        title: "",
        description: "",
        isLocal: false,
      } as CriticalPoint,
      {
        criticalPointUuid: "",
        stopPoints: stopPoints,
        uuid: "",
      },
      this.viewContainerRef,
      this.injector,
      updatedIndex,
    ).finally(() => {
      this.globalPracticePathEditor.generalEventEmitter.emit("criticalPointEditorClosed");
    });
  }

  // criticalPointUuid-val rendelkező kritikus pontot ad vissza
  getCriticalPointFromUuid(criticalPointUuid: string): CriticalPoint {
    return this.globalPracticePathEditor.criticalPointsInCorrespondingCity$.find(
      (cp) => cp.uuid == criticalPointUuid
    );
  }

  // Rákattintott egy megállási pont textre egy kritikus pont kártyán belül
  async onTapStopPointText(stopTimeInMs: number) {
    this.globalPracticePathEditor.mainVideoPlayerController.play();
    this.globalPracticePathEditor.mainVideoPlayerController.seekTo(
      Math.max(0, stopTimeInMs - 750)
    );
  }

  onTapCriticalPointTitle(criticalPointUuid: string) {
    this.globalPracticePathEditor.googleMapController.setCenter(
      this.getCriticalPointFromUuid(criticalPointUuid).coordinate
    );
  }

  ngAfterViewInit() {
    this.initScrollRecovery();
  }

  getActiveStopPointsOfAssignment(assignment: CriticalPointAssignment){
    return assignment.stopPoints.filter(sp => sp.isActive);
  }

  /**
   * Handles the verification status button click. Based on the critical point's actual verification status
   * it changes that (from verified to non-verified and vice-versa).
   *
   * @param criticalPointAssignment the assignent corresponding to the critical point in the edited path
   */
  protected async onTapCriticalPointVerificationStatusButton(criticalPointAssignment:CriticalPointAssignment):Promise<void> {
    // Push the actually verification status changed critical point's assignment's uuid into the status changes in process array
    // This is used to determine that which buttons should be disabled and show a loading indicator
    this.verificationStateChangesInProcess.push(criticalPointAssignment.uuid);

    // With using the page's service update the critical point's verification's status
    try {
      await this.globalPracticePathEditor.setCriticalPointVerificationState(
        criticalPointAssignment.criticalPointUuid,
        !this.getCriticalPointFromUuid(criticalPointAssignment.criticalPointUuid).isVerified
      );
    } catch(error:any) {}

    // Remove the critical assignment uuid from the status changes in process array
    this.verificationStateChangesInProcess.removeItems(cpaUuid => cpaUuid === criticalPointAssignment.uuid);
  }

  /**
   * Gets that the critical point corresponding to the critical point assignment is actually under a verification status chage.
   *
   * @param criticalPointAssignmentUuid the uuid of the critical point assignment
   *
   * @returns true, if the critical point is under a verification status chage, false otherwise.
   */
  protected isVerificationStatusChangeinProgress(criticalPointAssignmentUuid:string):boolean {
    return this.verificationStateChangesInProcess.includes(criticalPointAssignmentUuid)
  }

  protected hasUserCriticalPointVerificationChangePermission():boolean {
    return this.permissionService.isLoggedUserHasPermission(Permission.CriticalPointVerificationChange);
  }

  /**
   * Opens the practical module logs dialog for the given critical point assignment.
   * 
   * @param criticalPointAssignment the critical point assignment to open the logs for
   */
  protected openCriticalPointAssignmentLogs(criticalPointAssignment: CriticalPointAssignment): void {
    PracticalModuleLogsDialogComponent.open(
      this.dialogService,
      {
        entityType: "critical_point_assignment",
        entityKey: criticalPointAssignment.uuid,
        dialogTitle: "Kritikus pont hozzárendelés logok"
      }
    );
  }

}
