import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PermissionGroup } from 'src/app/classes/model/permissions';
import { ConfirmationDialogService } from 'src/app/modules/confirmation-dialog/services/confirmation-dialog.service';
import { PermissionGroupsService } from 'src/app/services/permission-groups.service';
import { PermissionGroupEditorDialogComponent } from './permission-group-editor-dialog/permission-group-editor-dialog.component';

@Component({
  selector: 'app-permission-groups',
  templateUrl: './permission-groups.component.html',
  styleUrls: ['./permission-groups.component.scss']
})
export class PermissionGroupsComponent implements OnInit {

  permissionGroups:ReadonlyArray<PermissionGroup> = [];

  isContentLoading:boolean = false;

  constructor(
    private permissionGroupsService:PermissionGroupsService,
    private dialogService:MatDialog,
    private confirmationDialogService:ConfirmationDialogService,
    private snackBarService:MatSnackBar
  ) { }

  public ngOnInit():void {
    this.permissionGroups = this.permissionGroupsService.getPermissionGroupsArrayRef();
    this.loadData();
  }

  private async loadData():Promise<void> {
    this.isContentLoading = true;

    try {
      await this.permissionGroupsService.loadDataIntoCache(); 
    } catch(error:any) {}
    
    this.isContentLoading = false;
  }

  public handleAddButtonClick():void {
    this.dialogService.open(
      PermissionGroupEditorDialogComponent,
      {
        data: {
          dialogMode: "add"
        },
        disableClose: true
      }
    );
  }

  public handleEditButtonClick(permissionGroup:PermissionGroup):void {
    this.dialogService.open(
      PermissionGroupEditorDialogComponent,
      {
        data: {
          dialogMode: "edit",
          permissionGroup: permissionGroup
        },
        disableClose: true
      }
    );
  }

  public handleDeleteButtonClick(permissionGroup:PermissionGroup):void {
    this.confirmationDialogService.open(
      "Jogosultság csoport törlése",
      "Biztosan törlöd?",
      async () => {
        try {
          await this.permissionGroupsService.deletePermissionGroup(permissionGroup.name);
          this.snackBarService.open("Sikeres jogosultság csoport tölrés", "Bezár", { duration: 3000, panelClass: "mat-snackbar-success" });
        } catch(error:any) {}
      }
    );
  }

}
