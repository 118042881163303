import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EntityComment } from '../../models/entity-comment';
import { CommentsOnEntityModuleService } from '../../services/comments-on-entity.service';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss']
})
export class CommentListComponent implements OnInit, OnDestroy, AfterViewInit {
  isScrollbarAtTheBottom:boolean;
  commentsOnEntityChangeSubscription:Subscription;
  commentedOnEntitySubscription:Subscription;
  previousCommentsOnEntitiyState:Array<EntityComment> = [];

  constructor(
    protected commentsOnEntityService:CommentsOnEntityModuleService,
    private elementRef:ElementRef<HTMLElement>,
    private changeDetectorRef:ChangeDetectorRef
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.initializeEventListeners();
  }

  ngOnDestroy(): void {
    // Unsubscribe from the events
    this.elementRef.nativeElement.removeEventListener("scroll", this.onListScroll);
    this.commentsOnEntityChangeSubscription?.unsubscribe();
    this.commentedOnEntitySubscription?.unsubscribe();
  }

  private initializeEventListeners():void {
    // Initialize the scroll event listener
    this.elementRef.nativeElement.addEventListener("scroll", this.onListScroll);

    // Cases when we have to scroll to the bottom:
    // a) when the list loads for the first time (handled in (1))
    // b) when the scrollbar was at the bottom and any new comment arrived (handled in (1))
    // c) when we made a new comment from here (handled in (2))

    // Initialize the comment list change event listener (1)
    this.commentsOnEntityChangeSubscription = this.commentsOnEntityService.getEntityComments().subscribe(
      this.onCommentListChange
    );

    // Initialize the event listener on comment creating (2)
    this.commentedOnEntitySubscription = this.commentsOnEntityService.newCommentCreated().subscribe(
      this.scrollListToBottom
    );
  }

  /**
   * Handles the comment list scrolling event. It checks that the scrollbar is at the bottom, or not and sets the
   * `isScrollbarAtTheBottom` member property accordingly.
   * 
   * @param event the scrolling event
   */
  private onListScroll = (event:Event) => {
    this.setIsScrollbarAtTheBottom();
  }

  private setIsScrollbarAtTheBottom() {
    // Determine if the scrollbar is on the bottom
    const bottomScrollPosition:number = this.elementRef.nativeElement.scrollTop + this.elementRef.nativeElement.clientHeight;
    const scrollHeight:number = this.elementRef.nativeElement.scrollHeight;
    this.isScrollbarAtTheBottom = bottomScrollPosition === scrollHeight;
  }

  /**
   * Handles the comment list change event. It determines that a scrolling to bottom action automatically should happen or not.
   * If should, it scrolls the comment list to the bottom.
   * 
   * @param newEntityComments 
   */
  private onCommentListChange = (newEntityComments:Array<EntityComment>) => {
    // Calculate the is scrollbar at bottom value before the UI update
    this.setIsScrollbarAtTheBottom();

    // Check if the array is filled with data (lenght goes from 0 to some positive value)
    // This could happend in other cases than the initialization, but this behaviour is not a problem in that case
    const isArrayFilled:boolean = this.previousCommentsOnEntitiyState.length === 0 && newEntityComments.length > 0;
    // Render the elements
    this.changeDetectorRef.detectChanges();

    // If the scrollbar was at the bottom, or the array is filled with data
    if(this.isScrollbarAtTheBottom || isArrayFilled) {
      // Scroll the comment list to bottom
      this.scrollListToBottom(); 
    }

    this.previousCommentsOnEntitiyState = newEntityComments;
  }

  /**
   * Scrolls the comment list to the bottom
   */
  private scrollListToBottom = () => {
    // Scrolls to the bottom - the scrollHeight is always bigger than the actual target scrollTop position
    this.elementRef.nativeElement.scrollTo({ top: this.elementRef.nativeElement.scrollHeight });
  }

  /**
   * Get the bottom margin value of an entity comment item. If the comments belongs to the same author a smaller gap
   * is applied.
   * 
   * @param entityComments the entity comments array for searching by index
   * @param index the index of the comment
   * @returns the margin value
   */
  public getBottomMarginOfEntityCommentItem(entityComments:Array<EntityComment>, index:number):number {
    if(entityComments.length - 1 <= index) {
      return 5;
    }

    return entityComments[index].created.adminEmail === entityComments[index + 1].created.adminEmail ? 5 : 15;
  }

  /**
   * Gets the target entity comment's uuid for ngFor's trackBy.
   * 
   * @param index the index of the element
   * @param enitityComment the target entity comment element
   * 
   * @returns the entity comment's uuid
   */
  protected ngForTrackByEntityUuid(index: number, enitityComment:EntityComment):string {
    return enitityComment.uuid;
  }

}
