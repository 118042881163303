<mat-card class = "feedback-card">
    <div>
        <div class = "feedback-card-header">
            <div>
                {{ email }}
                <span class = "feedback-student-uuid" (click) = "openStudentDetailsDialog(feedback.created.userUuid)">
                    (uuid: {{ feedback.created.userUuid }})
                </span>
            </div>
            <div> {{ feedback.created.timestamp | date: "YYYY. MM. dd. HH:mm:ss" }} </div>
        </div>
        
        <div class = "feedback-card-message">
            <div *ngFor = "let messageFragment of message">
                {{ messageFragment }}
            </div>
        </div>
    </div>
    <div style = "justify-self: end;">
        <div *ngIf = "feedback.closed !== null" class = "feedback-handled-data">
            <div> {{ feedback.closed.adminEmail }} </div>
            <div> {{ feedback.closed.timestamp | date: "YYYY. MM. dd. HH:mm:ss" }} </div>
        </div>
        <div *ngIf = "feedback.closed === null">
            <button mat-flat-button color = "primary" (click) = "handleHandleFeedbackButtonClick()">
                Kezel
            </button>
        </div>
    </div>
</mat-card>
