export class Permission {
  public static readonly None:PermissionString = "NONE";
  public static readonly TheoryRead:PermissionString = "THEORY_READ";
  public static readonly TheoryWrite:PermissionString = "THEORY_WRITE";
  public static readonly StudentsRead:PermissionString = "STUDENTS_READ";
  public static readonly StudentsWrite:PermissionString = "STUDENTS_WRITE";
  public static readonly StudentGiftWrite:PermissionString = "STUDENT_GIFT_WRITE";
  public static readonly PracticePathRead:PermissionString = "PRACTICE_PATH_READ";
  public static readonly PracticePathWrite:PermissionString = "PRACTICE_PATH_WRITE";
  public static readonly PurchasesRead:PermissionString = "PURCHASES_READ";
  public static readonly FeedbackRead:PermissionString = "FEEDBACK_READ";
  public static readonly FeedbackClose:PermissionString = "FEEDBACK_CLOSE";
  public static readonly StatisticsRead:PermissionString = "STATISTICS_READ";
  public static readonly StatisticsRecalculate:PermissionString = "STATISTICS_RECALCULATE";
  public static readonly GlobalGiftRead:PermissionString = "GLOBAL_GIFT_READ";
  public static readonly GlobalGiftWrite:PermissionString = "GLOBAL_GIFT_WRITE";
  public static readonly ServerManager:PermissionString = "SERVER_MANAGER";
  public static readonly PushNotificationRead:PermissionString = "PUSH_NOTIFICATION_READ";
  public static readonly PushNotificationWrite:PermissionString = "PUSH_NOTIFICATION_WRITE";
  public static readonly DatabaseCacheReload:PermissionString = "DATABASE_CACHE_RELOAD";
  public static readonly GiftsOfAdminRead:PermissionString = "GIFTS_OF_ADMIN_READ";
  public static readonly VendorRead:PermissionString = "VENDOR_READ";
  public static readonly VendorWrite:PermissionString = "VENDOR_WRITE";
  public static readonly InstructorRead:PermissionString = "INSTRUCTOR_READ";
  public static readonly InstructorWrite:PermissionString = "INSTRUCTOR_WRITE";
  public static readonly VendorGifting:PermissionString = "VENDOR_GIFTING";
  public static readonly PracticeVideoConvert:PermissionString = "PRACTICE_VIDEO_CONVERT";
  public static readonly PracticeIconWrite:PermissionString = "PRACTICE_ICON_WRITE";
  public static readonly PaymentRequest:PermissionString = "PAYMENT_REQUEST";
  public static readonly PackageDefinitionRead:PermissionString = "PACKAGE_DEFINITION_READ";
  public static readonly CommentsOnEntity:PermissionString = "COMMENTS_ON_ENTITY";
  public static readonly CriticalPointVerificationChange:PermissionString = "CRITICAL_POINT_VERIFICATION_CHANGE";
  public static readonly VendorDocumentUpload:PermissionString = "VENDOR_DOCUMENT_UPLOAD";
  public static readonly PracticalModuleLogsRead: PermissionString = "PRACTICAL_MODULE_LOGS_READ";
  public static readonly PracticeVideoUpload:PermissionString = "PRACTICE_VIDEO_UPLOAD";
  public static readonly GeneralFileUpload:PermissionString = "GENERAL_FILE_UPLOAD";

  name:PermissionString;
	displayedName:string;
	permissionGroup?:string;
	description?:string;
	isSpecial?:boolean;
  assignableTo:Array<PermissionAssignableToString>;
}

export type PermissionString =
  "NONE"|
  "THEORY_READ"|
  "THEORY_WRITE"|
  "STUDENTS_READ"|
  "STUDENTS_WRITE"|
  "STUDENT_GIFT_WRITE"|
  "PRACTICE_PATH_READ"|
  "PRACTICE_PATH_WRITE"|
  "PRACTICE_VIDEO_CONVERT"|
  "PRACTICE_ICON_WRITE"|
  "PRACTICE_PATH_CITY_CONTENT_RESPONSIBLE_WRITE"|
  "PURCHASES_READ"|
  "FEEDBACK_READ"|
  "FEEDBACK_CLOSE"|
  "STATISTICS_READ"|
  "STATISTICS_RECALCULATE"|
  "GLOBAL_GIFT_READ"|
  "GLOBAL_GIFT_WRITE"|
  "SERVER_MANAGER"|
  "PUSH_NOTIFICATION_READ"|
  "PUSH_NOTIFICATION_WRITE"|
  "DATABASE_CACHE_RELOAD"|
  "PACKAGE_DEFINITION_READ"|
  "VENDOR_READ"|
  "VENDOR_WRITE"|
  "VENDOR_GIFTING"|
  "INSTRUCTOR_READ"|
  "INSTRUCTOR_WRITE"|
  "GIFTS_OF_ADMIN_READ"|
  "PAYMENT_REQUEST"|
  "COMMENTS_ON_ENTITY"|
  "CRITICAL_POINT_VERIFICATION_CHANGE"|
  "VENDOR_DOCUMENT_UPLOAD"|
  "PRACTICE_VIDEO_UPLOAD"|
  "GENERAL_FILE_UPLOAD"|
  "PRACTICAL_MODULE_LOGS_READ"
; 

export class PermissionGroup {
	name:string; 
	displayedName:string;
	description?:string;
}

export type PermissionAssignableToString = "ADMINS"|"VENDORS"|"STUDENTS";

export type DisplayedAssignableToValue = {
  name:PermissionAssignableToString;
  displayedName:string;
}

export const permissionAssignableToValues:ReadonlyArray<DisplayedAssignableToValue> = [
  { name: "ADMINS", displayedName: "Adminisztrátorok" },
  { name: "VENDORS", displayedName: "Viszonteladók" },
  { name: "STUDENTS", displayedName: "Tanulók" }
];

export function isPermissionRequirementsMet(userPermissions:ReadonlyArray<PermissionString>, requiredPermissions:ReadonlyArray<PermissionString>):boolean {
  if(requiredPermissions.includes(Permission.None)) {
    return true;
  }

  for(const requiredPermission of requiredPermissions) {
    if(!userPermissions.includes(requiredPermission)) {
      return false;
    }
  }

  return true;
}