export enum MediaType {
  None, Image, Video, Unsupported
}

export class Media {
  type: MediaType;
  content: string;

  constructor(type: MediaType, content: string) {
    this.type = type;
    this.content = content;
  }

  typeToString(): "none" | "image" | "video" | "unsupported" {
    return mediaTypeToString(this.type);
  }
}

export function mediaTypeToString(mediaType: MediaType): "none" | "image" | "video" | "unsupported" {
  switch (mediaType) {
    case MediaType.None: return "none";
    case MediaType.Image: return "image";
    case MediaType.Video: return "video";
    case MediaType.Unsupported: return "unsupported";
  }
}

export function getMediaTypeFromUrl(url: string): MediaType {
  if (!url) {
    return MediaType.None;
  }

  switch (url.slice(url.length - 4).toLowerCase()) {
    case ".png":
    case "jpg":
    case "jpeg":
      return MediaType.Image;
    case ".mp4":
      return MediaType.Video
    default:
      return MediaType.Unsupported;
  }
}

export function trimPrefixFromBase64(media: Media): string {
  if (media.type === MediaType.Image) {
    media.content = media.content.replace("data:image/jpeg;base64,", "");
  } else {
    media.content = media.content.replace("data:video/mp4;base64,", "");
  }

  return media.content;
}
