import { Component, Input, OnInit } from '@angular/core';
import { PracticalModuleLogsDataService } from './services/practical-module-logs-data.service';
import { PracticePathCacherService } from 'src/app/services/cacher-services/practice-path-cacher.service';
import { CriticalPointCacherService } from 'src/app/services/cacher-services/critical-point-cacher.service';
import { cachedPracticePathFields } from './models/cached-entities.model';
import { PracticalModuleLogsEntityCacherHelperService } from './services/practical-module-logs-entity-cacher-helper.servce';
import { RelatedEntityTypeToPracticalModuleLog } from './models/practical-module-log.model';



@Component({
  selector: 'app-practical-module-logs',
  templateUrl: './practical-module-logs.component.html',
  styleUrls: ['./practical-module-logs.component.scss'],
  providers: [
    PracticalModuleLogsDataService,
    PracticePathCacherService,
    CriticalPointCacherService,
    PracticalModuleLogsEntityCacherHelperService
  ]
})
export class PracticalModuleLogsComponent implements OnInit {
  @Input() entityType:RelatedEntityTypeToPracticalModuleLog|"all";
  @Input() entityKey:string;

  constructor(
    private practicalModuleLogsDataService:PracticalModuleLogsDataService,
    private practicePathCacherService:PracticePathCacherService
  ) {
   
  }

  public ngOnInit(): void {
    // Initalize the used services
    this.initializeServices();
  }

  /**
   * Initializes the used services under the main (this) component. We need to initialize:
   *  - the data service to which entity we would like to get the logs for
   *  - the cacher services which fields of the entity we would like to cache
   */
  private initializeServices():void {
    // Initialize the logs data service
    this.practicalModuleLogsDataService.initialize(this.entityType, this.entityKey);

    // Initialize the cacher services
    // For the practice paths we would like to store only the name (besides the uuid)
    this.practicePathCacherService.queriedFields = cachedPracticePathFields; 
  }

}
