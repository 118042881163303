import { Platform } from '@angular/cdk/platform';
import { Component, Input, OnInit, HostListener, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomValidators } from 'src/app/classes/custom-validators';
import Hls from 'hls.js';

type DisplayedPreviewType = "audio"|"image"|"video"|null;

@Component({
  selector: 'app-media-form',
  templateUrl: './media-form.component.html',
  styleUrls: ['./media-form.component.scss']
})
export class MediaFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() formControlRef:FormControl;
  @Input() acceptableFileTypes:ReadonlyArray<string>;
  @Input() previewEnabled:boolean = false;
  @Input() defaultPreviewMedia:string|null = null;
  @Input() previewWidth:number = 400;
  @Input() previewHeight:number = 400 * (16 / 9);
  @Input() buttonText:string = "Média kiválasztása";
  @Input() buttonVisible:boolean = true;

  @ViewChild("button", { static: false }) button:ElementRef<HTMLDivElement>;

  bodyEventHandlers:Map<string, EventListenerOrEventListenerObject> = new Map<string, EventListenerOrEventListenerObject>();

  public previewType:"audio"|"image/video"|"mixed"|"none" = "none";
  public displayedPreviewType:DisplayedPreviewType = null;
  private previewAudioElementRef:ElementRef<HTMLAudioElement>|null = null;
  @ViewChild("PreviewAudio") set newPreviewAudioElementRef(newPreviewAudioElementRef:ElementRef) {
    if(newPreviewAudioElementRef) {
      this.previewAudioElementRef = newPreviewAudioElementRef;
      this.updatePreview();
    }
  }

  private previewImageElementRef:ElementRef<HTMLImageElement>|null = null;
  @ViewChild("PreviewImage") set newPreviewImageElementRef(newPreviewImageElementRef:ElementRef) {
    if(newPreviewImageElementRef) {
      this.previewImageElementRef = newPreviewImageElementRef;
      this.updatePreview();
    }
  }

  private previewVideoElementRef:ElementRef<HTMLVideoElement>|null = null;
  @ViewChild("PreviewVideo") set newPreviewVideoElementRef(newPreviewVideoElementRef:ElementRef) {
    if(newPreviewVideoElementRef) {
      this.previewVideoElementRef = newPreviewVideoElementRef;
      this.updatePreview();
    }
  }

  public uploadedFile:File|null = null;
  @HostListener('change', ['$event.target.files']) emitFiles(event:FileList) {
    const file:File|null = event && event.item(0);
    this.handleFileLoading(file);
  }

  @ViewChild("fileInput", { static: false }) fileInput:ElementRef<HTMLInputElement>;

  constructor(
    private platform:Platform,
    private snackBar:MatSnackBar
  ) {}

  ngOnInit():void {
    if(this.formControlRef.validator) {
      this.formControlRef.setValidators( [ this.formControlRef.validator, CustomValidators.acceptableFileTypes(this.acceptableFileTypes) ]);
    } else {
      this.formControlRef.setValidators([ CustomValidators.acceptableFileTypes(this.acceptableFileTypes) ]);
    }

    this.formControlRef.valueChanges.subscribe(
      (newValue:File|string) => {
        console.log("form control new value");
        console.log(newValue);

        if(typeof newValue === "string") {
          this.displayedPreviewType = this.getMediaTypeFromExtension(newValue);
          this.defaultPreviewMedia = newValue;
          this.updatePreview();
        }
      }
    )

    this.setPreviewType();

    if(this.defaultPreviewMedia) {
      this.displayedPreviewType = this.getMediaTypeFromExtension(this.defaultPreviewMedia);
    }
  }

  public ngAfterViewInit():void {
    let dragCounter:number = 0;

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      let handler:EventListenerOrEventListenerObject = (event) => {
        event.stopPropagation();
        event.preventDefault();

        switch(eventName) {
          case "dragenter": ++dragCounter; break;
          case "dragleave": --dragCounter; break;
          case "drop": dragCounter = 0; break;
        }

        if(dragCounter > 0) {
          this.button.nativeElement.classList.add("drag");
          this.button.nativeElement.classList.remove("click");
        } else {
          this.button.nativeElement.classList.add("click");
          this.button.nativeElement.classList.remove("drag");
        }
      };

      document.body.addEventListener(eventName, handler, false)
      this.bodyEventHandlers.set(eventName, handler);
    });

    this.button.nativeElement.addEventListener("drop", this.handleDropEvent, false);

  }

  public ngOnDestroy():void {
    this.bodyEventHandlers.forEach(
      (value:EventListenerOrEventListenerObject, key:string) => {
        document.body.removeEventListener(key, value, false);
      }
    );
    this.button.nativeElement.removeEventListener("drop", this.handleDropEvent);

  }

  private setPreviewType():void {
    if(this.previewEnabled) {
      for(const fileType of this.acceptableFileTypes) {
        const mainType:string = fileType.split("/")[0];
        if(mainType === "audio") {
          if(this.previewType === "none") {
            this.previewType = "audio";
          } else if(this.previewType !== "audio") {
            this.previewType = "mixed";
            break;
          }
        } else if(mainType === "image" || mainType == "video") {
          if(this.previewType === "none") {
            this.previewType = "image/video";
          } else if(this.previewType !== "image/video") {
            this.previewType = "mixed";
            break;
          }
        }
      }
    }
  }

  private handleFileLoading(file:File) {
    if(this.acceptableFileTypes.includes(file?.type ?? "")) {
      this.setFormValue(file);
      this.displayedPreviewType = this.getUploadedMediaType();
      this.updatePreview();
    } else {
      this.snackBar.open("A fájl formátum nem támogatott!", "Bezár", { duration: 3000 });
    }
  }

  private setFormValue(newValue:File|null):void {
    this.uploadedFile = newValue;
    this.formControlRef.setValue(newValue);
    this.updatePreview();
  }

  public getUploadedMediaType():DisplayedPreviewType {
    if(this.uploadedFile === null || this.uploadedFile === undefined) {
      return null;
    }

    // [TODO] update
    return this.uploadedFile.type.split("/")[0] as DisplayedPreviewType;
  }

  private updatePreview():void {
    if(!this.previewEnabled) {
      return;
    }

    let previewElementRef:ElementRef<HTMLAudioElement|HTMLImageElement|HTMLVideoElement>|null = null;

    switch(this.displayedPreviewType) {
      case "audio":
        this.previewImageElementRef = null;
        this.previewVideoElementRef = null;
        previewElementRef = this.previewAudioElementRef;
        break;

      case "image":
        this.previewAudioElementRef = null;
        this.previewVideoElementRef = null;
        previewElementRef = this.previewImageElementRef;
        break;

      case "video":
        this.previewAudioElementRef = null;
        this.previewImageElementRef = null;
        previewElementRef = this.previewVideoElementRef;
        break;
    }

    if(previewElementRef) {
      const parent:HTMLDivElement = previewElementRef.nativeElement.parentElement! as HTMLDivElement;
      parent.style.border = "none";
      if(this.uploadedFile) {
        previewElementRef.nativeElement.src = URL.createObjectURL(this.uploadedFile);
      } else if(this.defaultPreviewMedia) {
        if(this.defaultPreviewMedia.endsWith(".m3u8")) {
          const hls:Hls = new Hls();
          hls.loadSource(this.defaultPreviewMedia);
          hls.attachMedia(this.previewVideoElementRef.nativeElement);
        } else {
          previewElementRef.nativeElement.src = this.defaultPreviewMedia;
        }
      }
    }
  }

  public handleDeleteMediaButtonClick():void {
    this.setFormValue(null);
  }

  public getPreviewContainerClass():ReadonlyArray<string> {
    if(this.previewEnabled) {
      switch(this.previewType) {
        case "audio":
          return [ "media-form-preview-container-audio" ];

        case "image/video":
          return [ "media-form-preview-container-image-video" ];
      }
    }

    return [];
  }

  public handleInputButtonClick():void {
    this.fileInput.nativeElement.click();
  }

  getMediaTypeFromExtension(url:string):DisplayedPreviewType {
    const tokens:ReadonlyArray<string> = url.split(".");
    if(tokens.length < 2) {
      return null;
    }
    const extension:string = tokens[tokens.length - 1];

    switch(extension.toLocaleLowerCase()) {
      case "mp3":
      case "wav":
          return "audio";
      case "jpg":
      case "jpeg":
      case "png":
        return "image";
      case "mov":
      case "mp4":
      case "m3u8":
        return "video";
      default:
        return null;
    }
  }

  public getPreviewContainerStyle():Object {
    const styles:Object = {
      width: this.previewWidth + 'px'
    };

    if(this.displayedPreviewType !== "audio") {
      return Object.assign(styles, { height: this.previewHeight + 'px' });
    }

    let previewHeightForAudio:number = 60;
    if(this.platform.FIREFOX) {
      previewHeightForAudio = 40;
    } else if(this.platform.BLINK) {
      previewHeightForAudio = 54;
    } else if(this.platform.SAFARI) {
      previewHeightForAudio = 31;
    }

    return Object.assign(styles, { height: previewHeightForAudio + 'px' });
  }

  private handleDropEvent = (event:DragEvent) => {
    if(event.dataTransfer.files.length > 0) {
      this.handleFileLoading(event.dataTransfer.files.item(0));
    }
  };

}
