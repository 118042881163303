import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl,  } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService, LoginResult } from 'src/app/services/common/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { ServerSelectorDialogComponent } from './server-selector-dialog/server-selector-dialog.component';
import { EnvironmentService } from 'src/app/services/common/environments/environment.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild("emailInput", { static: true }) emailInputElement:ElementRef<HTMLInputElement>;
  @ViewChild("passwordInput", { static: true }) passwordInputElement:ElementRef<HTMLInputElement>;

  loginForm:FormGroup;
  loginEmailFormControl:FormControl<string>;
  loginPasswordFormControl:FormControl<string>;
  showPassword:boolean;

  @HostListener('document:keydown.shift.control.v')
  openServerSelector() {
    this.dialogService.open(ServerSelectorDialogComponent)
  }

  @HostListener('document:keydown.enter')
  onEnterKeydown() {
    if(document.activeElement === this.emailInputElement.nativeElement) {
      this.passwordInputElement.nativeElement.focus();
    } else {
      this.checkCredentials();
    }
  }

  constructor(
    private snackBar:MatSnackBar,
    private authenticationService:AuthenticationService,
    private dialogService:MatDialog,
    protected environmentService:EnvironmentService
  ) { }

  ngOnInit(): void {
    // Init FormContorls
    this.loginEmailFormControl = new FormControl<string>("");
    this.loginPasswordFormControl = new FormControl<string>("");
    this.loginForm = new FormGroup({
      loginEmailFormControl: this.loginEmailFormControl,
      loginPasswordFormControl:  this.loginPasswordFormControl
    });

    this.showPassword = false;
  }

  /**
   * Handles the click on the login button. Extracts the informations from the login fields and attemt to login the user.
   * If the login is successful the session will be set, otherwise an error message will be shown.
  */
  public async checkCredentials():Promise<void> {
    if(this.loginEmailFormControl.value === "" || this.loginPasswordFormControl.value === "") {
      this.snackBar.open("A mezők kitöltése kötelező!", "Bezár", { duration: 3000 });
      return;
    }

    const loginEmail:string = this.loginEmailFormControl.value.trim();

    const loginResult:LoginResult = await this.authenticationService.login(loginEmail, this.loginPasswordFormControl.value);
    switch(loginResult) {
      case LoginResult.WrongCredentials:
        this.snackBar.open("Hibás felhasználónév vagy jelszó.", "Bezár", { duration: 3000 });
        break;
      case LoginResult.ServerError:
        this.snackBar.open("Hiba történt bejelentkezés közben.", "Bezár", { duration: 3000 });
        break;
      case LoginResult.ServerConnectionError:
        this.snackBar.open("Hiba a szerverkapcsolatban.", "Bezár", { duration: 3000 });
        break;
    }
  }

}
