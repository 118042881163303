<!-- Header -->
<div class = "title">
    Viszonteladó regisztrációja
</div>

<!-- Content -->
<div>
    <app-vendor-data-form
    [mode] = "'add'"
    ></app-vendor-data-form>
    <div>
        <mat-checkbox [(ngModel)] = "registrateAsClassroomInstructor"> Regisztráció Classroom oktatóként is </mat-checkbox>
    </div>
</div>
   
<!-- Actions -->
<mat-dialog-actions align = "end">
    <button mat-raised-button mat-dialog-close>
        Bezár
    </button>
    <button
        mat-raised-button
        color = "primary"
        (click) = "onRegistrateVendorButtonClick()"
        [disabled] = "!isFormValid() || registrationInProgress"
        [class.button-loading] = "registrationInProgress"
    >
        Viszonteladó regisztrálása
    </button>
</mat-dialog-actions>

