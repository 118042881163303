import { moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  CityNode,
  practiceCityNodeSorter,
  PracticeCity,
} from "src/app/classes/model/practice-city";
import { matchingPropertyPredicate } from "src/app/functions/misc";
import { PracticeCityNodeService } from "src/app/services/practice-city-node.service";

@Component({
  selector: "app-pracitce-city-exam-number-node-assignment-editor-dialog",
  templateUrl:
    "./pracitce-city-exam-number-node-assignment-editor-dialog.component.html",
  styleUrls: [
    "./pracitce-city-exam-number-node-assignment-editor-dialog.component.scss",
  ],
})
export class PracitceCityExamNumberNodeAssignmentEditorDialogComponent
  implements OnInit
{
  selectedExamNumber: number = 0;
  thesisNumberDatas: Array<ThesisNumberData>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { city: PracticeCity },
    private practiceCityNodeService: PracticeCityNodeService,
    private snackbBarService: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.thesisNumberDatas = [];

    for (let index = 0; index < this.data.city.countOfExamPath; ++index) {
      const thesisNumberData: ThesisNumberData = {
        assignedNodes: [],
        unassignedNodes: [],
        hasUnsavedWork: false,
      };

      thesisNumberData.assignedNodes =
        this.data.city.cityNodeUuidsAssigmentToExamThesis[index].map(
          (nodeUuid: string) => {
            const node: CityNode = this.data.city.nodes.find(
              matchingPropertyPredicate("uuid", nodeUuid)
            );
            return {
              uuid: node.uuid,
              name: node.name,
            };
          }
        );

      thesisNumberData.unassignedNodes = this.data.city.nodes.map(
        (cityNode: CityNode) => {
          return {
            uuid: cityNode.uuid,
            name: cityNode.name,
          };
        }
      );
      thesisNumberData.unassignedNodes.sort(this.partialCityNodeSorter);

      this.thesisNumberDatas.push(thesisNumberData);
    }
  }

  public selectExamNumber(index: number): void {
    this.selectedExamNumber = index;
  }

  public assingNodeToPosition(node: Partial<CityNode>): void {
    this.thesisNumberDatas[this.selectedExamNumber].assignedNodes.push(node);
    this.thesisNumberDatas[this.selectedExamNumber].hasUnsavedWork =
      this.hasDifference(this.selectedExamNumber);
  }

  public unassingNodeToPosition(index: number): void {
    this.thesisNumberDatas[this.selectedExamNumber].assignedNodes.splice(
      index,
      1
    );

    this.thesisNumberDatas[this.selectedExamNumber].hasUnsavedWork =
      this.hasDifference(this.selectedExamNumber);
  }

  private partialCityNodeSorter = (
    cityNode1: Partial<CityNode>,
    cityNode2: Partial<CityNode>
  ) => {
    return practiceCityNodeSorter(cityNode1 as CityNode, cityNode2 as CityNode);
  };

  public async handleSaveButtonClick(): Promise<void> {
    const nodeUuids: Array<string> = this.thesisNumberDatas[
      this.selectedExamNumber
    ].assignedNodes.map((assignedNode) => assignedNode.uuid);

    try {
      await this.practiceCityNodeService.updateCityExamNumberNodeAssignment(
        this.data.city,
        this.selectedExamNumber,
        nodeUuids
      );
      this.thesisNumberDatas[this.selectedExamNumber].hasUnsavedWork =
        this.hasDifference(this.selectedExamNumber);
      this.snackbBarService.open("Sikeres módosítás", "Bezár", {
        duration: 3000,
        panelClass: ["mat-snackbar-success"],
      });
    } catch (error: any) {}
  }

  private hasDifference(positionIndex: number): boolean {
    // Ha megváltozott a vizsga érintési pontok száma
    if (
      this.data.city.cityNodeUuidsAssigmentToExamThesis[positionIndex]
        .length !== this.thesisNumberDatas[positionIndex].assignedNodes.length
    ) {
      return true;
    }

    for (
      let index = 0;
      index <
      this.data.city.cityNodeUuidsAssigmentToExamThesis[positionIndex].length;
      ++index
    ) {
      if (
        this.data.city.cityNodeUuidsAssigmentToExamThesis[positionIndex][
          index
        ] !== this.thesisNumberDatas[positionIndex].assignedNodes[index].uuid
      ) {
        return true;
      }
    }

    return false;
  }

  public onAssignedNodeDragEnd(event: any) {
    moveItemInArray(
      this.thesisNumberDatas[this.selectedExamNumber].assignedNodes,
      event.previousIndex,
      event.currentIndex
    );

    this.thesisNumberDatas[this.selectedExamNumber].hasUnsavedWork =
      this.hasDifference(this.selectedExamNumber);
  }
}

type ThesisNumberData = {
  assignedNodes: Array<Partial<CityNode>>;
  unassignedNodes: Array<Partial<CityNode>>;
  hasUnsavedWork: boolean;
};
