<!-- TODO CHANGE SORTABLE JS [sortablejs] = "subFormControls" [sortablejsOptions] = "sortablejsOptions"-->
<div cdkDropList (cdkDropListDropped)="onDragEnd($event)">
    <div cdkDrag *ngFor = "let subFormControl of subFormControls; let i = index" class = "list-item">
         <mat-form-field appearance='outline' class = "form-item">
            <mat-label>{{ i + 1 }}. {{ nameSuffix }}</mat-label>
            <input matInput [formControl] = "subFormControl">
            <mat-error>Kötelező mező!</mat-error>
        </mat-form-field>
        <div>
            <span class = "material-icons delete-button" (click) = "removeElement(i)">
                delete
            </span>
        </div>
    </div>
</div>

