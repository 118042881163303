<app-page-header
    title = "Admin menü beállítások"
    subtitle = "Globális (minden ezt a böngészőt használó felhasználóhoz tartozó) beállítások az admin menühöz."
    style = "margin-bottom: 20px;"
></app-page-header>

<div class = "general-settings-item">
    <div class = "general-settings-title"> API URL logolás </div>
    <div class = "general-settings-content">
        <mat-slide-toggle [formControl] = "loggingApiUrlFC"> {{ loggingApiUrlFC.value ? "Bekapcsolva" : "Kikapcsolva" }} </mat-slide-toggle>
    </div>
</div>
<div style = "margin-top: 20px;">
    <button
        mat-raised-button
        color = "primary"
        (click) = "handleSaveButtonClick()"
    >
        Beállítások mentése
    </button>
</div>
