<div class="none-video-container" *ngIf="previewVideoPlayerController == null">
  Nincs még videó feltöltve ehhez az útvonalhoz
</div>

<div
  *ngIf="previewVideoPlayerController != null"
  class="video-player-container"
>
  <div style="margin-bottom: 10px">
    <span
      style="margin-bottom:15px;"
      class="no-minimal-bitrate"
      *ngIf="!currentPracticePath.video.rawVideoConvertedMinimalBitrate"
    >
      Nincs minimal bitrate az útvonalnak
    </span>
    <br>

    {{ this.currentPreviewVideoType }} - ez látható a szerkesztőben is

    <br />
  </div>

  <video-player [videoPlayerController]="previewVideoPlayerController">
  </video-player>
</div>

<button
  (click)="onTapUploadNewVideo()"
  style="margin-top: 20px; display: block; width: 190px; margin-bottom: 15px"
  mat-raised-button
  color="primary"
  [disabled]="adminHasPracticeVideoUploadPermission() == false"
>
  <mat-icon style="margin-right: 10px">cloud_upload</mat-icon>
  Új videó feltöltése
</button>
