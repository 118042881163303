export class ChartDataPoint {
    name:string;
    value:number;
}

export class LineChartData {
    name:string;
    series:Array<ChartDataPoint>;

    constructor(name:string, pointNames:ReadonlyArray<string> = []) {
        this.name = name;
        this.series = new Array<ChartDataPoint>();
        for(let pointName of pointNames) {
            this.series.push({ name: pointName, value: 0 });
        }
    }
}