import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PracticeCity, practiceCitySorter } from 'src/app/classes/model/practice-city';
import { Permission } from 'src/app/classes/model/permissions';
import { PracticeCityService } from 'src/app/services/practice-city.service';
import { PracticeCitiesEditorComponent } from './practice-cities-editor/practice-cities-editor.component';
import { PermissionService } from 'src/app/services/common/permission.service';

@Component({
  selector: 'app-practice-cities',
  templateUrl: './practice-cities.component.html',
  styleUrls: ['./practice-cities.component.scss']
})
export class PracticeCitiesComponent implements OnInit {

  practiceCities:Array<PracticeCity>;
  isContentLoading:boolean;

  userCanEdit:boolean = false;

  constructor(
    private practiceCityService:PracticeCityService,
    private dialog:MatDialog,
    private permissionService:PermissionService
  ) { }

  public ngOnInit():void {
    this.userCanEdit = this.permissionService.isLoggedUserHasPermission(Permission.PracticePathWrite);
    this.loadPracticeCities();
  }

  public async handleReloadButtonClick():Promise<void> {
    await this.loadPracticeCities();
  }

  private async loadPracticeCities():Promise<void> {
    this.isContentLoading = true;
    try {
      this.practiceCities = await this.practiceCityService.fetchPracticeCities();
      this.practiceCities.sort(practiceCitySorter);
    } catch(error:any) {}
    this.isContentLoading = false;
  }

  public handleAddButtonClick():void {
    const dialogRef:MatDialogRef<PracticeCitiesEditorComponent, boolean> = this.dialog.open(
      PracticeCitiesEditorComponent,
      {
        data: {
          practiceCity: null
        },
        disableClose: true
      }
    );

    dialogRef.afterClosed().subscribe(
      async (shouldReloadData:boolean) => {
        if(shouldReloadData) {
          await this.loadPracticeCities();
        }
      }
    );
  }

  protected async onCityChanged():Promise<void> {
    await this.loadPracticeCities();
  }

}
