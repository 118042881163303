import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AdminRole, AdminUser } from 'src/app/classes/model/admin-user';
import { Permission } from 'src/app/classes/model/permissions';
import { matchingPropertyPredicate } from 'src/app/functions/misc';
import { PracticalModuleLogsDialogComponent } from 'src/app/modules/practical-module-logs/components/practical-module-logs-dialog/practical-module-logs-dialog.component';
import { AdminRoleService } from 'src/app/services/admin-roles.service';
import { AdminUserService } from 'src/app/services/admin-user.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { AdminUserEditorDialogComponent } from './admin-user-editor-dialog/admin-user-editor-dialog.component';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {

  displayedAdminUsers:Array<DisplayedAdminUser> = [];
  adminRoles:ReadonlyArray<AdminRole> = [];
  permissions:ReadonlyArray<Permission> = [];

  isContentLoading:boolean = false;

  constructor(
    private adminUserService:AdminUserService,
    private adminRoleService:AdminRoleService,
    private permissionsService:PermissionsService,
    private dialogService:MatDialog
  ) { }

  public ngOnInit():void {
    this.adminRoles = this.adminRoleService.getAdminRolesArrayRef();
    this.permissions = this.permissionsService.getPermissionsArrayRef();
    this.loadData();
  }

  private async loadData():Promise<void> {
    this.isContentLoading = true;

    let adminUsers:ReadonlyArray<AdminUser> = [];

    try {
      this.adminRoleService.loadDataIntoCache();
      await this.permissionsService.loadDataIntoCache();
      adminUsers = await this.adminUserService.fetchAdminUsers();
    } catch(error:any) {}

    this.displayedAdminUsers = adminUsers.map(this.mapAdminUserToDisplayed);
    
    this.isContentLoading = false;
  }

  private mapAdminUserToDisplayed:(adminUser:AdminUser) => DisplayedAdminUser =
  (adminUser:AdminUser) => {
    const displayedAdminUser:DisplayedAdminUser = adminUser as DisplayedAdminUser;
      displayedAdminUser.isServerManager = adminUser.permissions.includes(Permission.ServerManager);
      displayedAdminUser.displayedRoleNames = adminUser.roleNames.map(
        (roleName:string) => {
          return this.adminRoles.find(matchingPropertyPredicate("name", roleName))?.displayedName ?? "Ismeretlen szerepkör";
        }
      );
      return displayedAdminUser;
  }

  public handleAddButtonClick():void {
    const dialogRef:MatDialogRef<AdminUserEditorDialogComponent, AdminUser> = this.dialogService.open(
      AdminUserEditorDialogComponent,
      {
        data: {
          dialogMode: "add",
          adminRoles: this.adminRoles,
          permissions: this.permissions
        },
        disableClose: true
      }
    );

    const dialogCloseSubscription:Subscription = dialogRef.afterClosed().subscribe(
      (adminUser:AdminUser) => {
        dialogCloseSubscription.unsubscribe();
        if(adminUser) {
          this.displayedAdminUsers.push(this.mapAdminUserToDisplayed(adminUser));
        }
      }
    );
  }

  public handleEditButtonClick(adminUser:AdminUser):void {
    const dialogRef:MatDialogRef<AdminUserEditorDialogComponent, AdminUser> = this.dialogService.open(
      AdminUserEditorDialogComponent,
      {
        data: {
          dialogMode: "edit",
          adminRoles: this.adminRoles,
          adminUser: adminUser,
          permissions: this.permissions
        },
        disableClose: true
      }
    );

    const dialogCloseSubscription:Subscription = dialogRef.afterClosed().subscribe(
      (adminUser:AdminUser) => {
        dialogCloseSubscription.unsubscribe();
        if(adminUser) {
          this.displayedAdminUsers.replaceItems(matchingPropertyPredicate("uuid", adminUser.uuid), this.mapAdminUserToDisplayed(adminUser));
        }
      }
    );
  }

  /**
   * Opens the practical module logs dialog with the related logs for the given admin. 
   * 
   * @param adminUser the admin user to open the the practical module logs dialog for
   */
  protected opePracticalModuleLogsForAdminUser(adminUser: AdminUser): void {
    PracticalModuleLogsDialogComponent.open(
      this.dialogService,
      {
        entityType: "admin",
        entityKey: adminUser.uuid,
        dialogTitle: "Adminisztrátorhoz tartozó logok",
        entityName: adminUser.username
      }
    );
  }

}

class DisplayedAdminUser extends AdminUser {
  displayedRoleNames:Array<string>;
  isServerManager:boolean;
}