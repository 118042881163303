import { Injectable } from '@angular/core';
import { clearStudentPersonalDetails, setUnsettedValuesInStudent, Student, studentSorter } from '../classes/model/student';
import { BackendService } from './common/backend.service';
import { FilloutDetails } from '../classes/model/fillout-details';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { HttpResponseData } from '../classes/http-communication';
import { DataCacherService } from './data-cacher-service';
import { Permission, PermissionString } from '../classes/model/permissions';
import { NotifierService } from 'src/app/services/common/notifier-service.service';

@Injectable({
  providedIn: 'root'
})
export class StudentService implements DataCacherService {
  readonly studentsApiUrlFragment = "/api/admin/user";
  readonly filloutDetailsApiUrlFragment = "/api/admin/history";
  readonly createGiftApiUrlFragment = "/api/admin/user/add_explicit_gift_to_student"
  readonly deleteStudentPersinalDetailsApiUrlFragment = "/api/admin/user/delete_user"

  private students:Array<Student>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.StudentsRead ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService,
    private notifierService:NotifierService
  ) {
    this.students = new Array<Student>();
  }

  public getStudentsArrayRef():ReadonlyArray<Readonly<Student>> {
    return this.students;
  }

  public async fetchStudents():Promise<Array<Readonly<Student>>> {
    let students:Array<Readonly<Student>> = [];

    try {
      const response:HttpResponseData<Array<Readonly<Student>>> = await this.backendService.callApi(this.studentsApiUrlFragment, "GET", undefined,undefined,undefined,200000);
      students = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a tanulók lekérdezése közben.");
    }

    return students;
  }

  public async getStudent(studentUuid:string):Promise<Student|null> {
    const apiUrlFragment:string = this.studentsApiUrlFragment + "/" + studentUuid;
    let result:Student|null = null;

    try {
      const response:HttpResponseData<Student> = await this.backendService.callApi(apiUrlFragment, "GET");
      result = setUnsettedValuesInStudent(response.data);
    } catch(error:any) {
      if(error?.error?.error === "USER_NOT_FOUND") {
        this.notifierService.notify("error", "A keresett tanuló nem található.");
        throw error;
      } else {
        this.httpErrorHandlerService.handleError(error, "Hiba a tanuló lekérdezése közben.");
      }
    }

    return result;
  }

  async getFillout(filloutUuid:string):Promise<{ details:FilloutDetails }> {
    const apiUrlFragment:string = this.filloutDetailsApiUrlFragment + "/" + filloutUuid;
    let result:{ details:FilloutDetails };

    try {
      const response:HttpResponseData<{ details:FilloutDetails }> = await this.backendService.callApi(apiUrlFragment, "GET");
      result = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a kitöltés részletek lekérdezése közben.");
    }

    return result;
  }

  public async createGiftForStudent(student:Student, packageDefinitionUuid:string, reason:string, message:string):Promise<any> {
    const httpBody:Object = {
      data: {
        studentUuid: student.uuid,
        packageDefinitionUuid: packageDefinitionUuid,
        reason: reason,
        message: message
      }
    };

    try {
      const response:{ createdNewPackage:any } = await this.backendService.callApi(this.createGiftApiUrlFragment, "POST", httpBody);
      student.pendingExplicitGiftedPackage.push(response.createdNewPackage);
      return response.createdNewPackage;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az ajándék létrehozása közben.");
    }
  }

  public async deleteStudentPersonalDetails(student:Student):Promise<void> {
    const httpBody:Object = {
      data: {
        userUuid: student.uuid
      }
    };

    try {
      await this.backendService.callApi(this.deleteStudentPersinalDetailsApiUrlFragment, "POST", httpBody);
      clearStudentPersonalDetails(student);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a tanuló személyes adatainak törlése közben.");
    }
  }

  public async loadDataIntoCache():Promise<void> {
    const students:Array<Readonly<Student>> = await this.fetchStudents();
    this.students.replaceArrayElements(students);
    this.students.forEach(setUnsettedValuesInStudent);
    this.students.sort(studentSorter);
  }

  public clearCachedData():void {
    this.students.length = 0;
  }

  public getRequiredPermissionsForDataLoading(): ReadonlyArray<PermissionString> {
    return this.requiredPermissionsForDataLoading;
  }

  public getNameOfCachedData(): string {
    return "Monitorozott felhasználók";
  }

}
