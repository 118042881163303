<section id = "questiongroups-section">
    <app-page-header
        title = "Kérdéscsoportok"
        subtitle = "Az elméleti modulban elérhető kérdéscsoportokat találod itt A kérdéseket csoportokba soroltuk, hogy tematikusan is
            tudjanak gyakorolni a tanulók."
        style = "margin-bottom: 20px;"
    >
        <button mat-mini-fab color = "primary" (click) = "handleReloadButtonClick()">
            <mat-icon> refresh </mat-icon>
        </button>
    </app-page-header>
    <div id = "new-questiongroup-button-container">
        <button mat-fab color = "primary" (click) = "openAddQuestionGroupDialog()">
            <mat-icon> add </mat-icon>
        </button>
    </div>
    <div *ngIf = "isContentLoading" id = "loading-container">
        <mat-spinner></mat-spinner>
    </div>
    <app-questiongroup
        *ngFor = "let questionGroup of questionGroups"
        [questionGroup] = "questionGroup"
        [categories] = "categories"
        style = "margin-bottom: 10px;"
    ></app-questiongroup>
</section>