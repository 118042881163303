import { Component, OnInit, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Question } from 'src/app/classes/model/question';
import { QuestionService } from 'src/app/services/question.service';
import { DialogMode } from 'src/app/classes/misc';
import { MediaType, getMediaTypeFromUrl } from 'src/app/classes/media';
import { QuestionGroup } from 'src/app/classes/model/question-groups';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuestionCategoryAssignmentEditDialogComponent } from '../question-category-assignment-edit-dialog/question-category-assignment-edit-dialog.component';
import { Category } from 'src/app/classes/model/category';
import { QuestionFilterService } from '../services/question-filter.service';
import { QuestionQuestiongroupAssignmentEditDialogComponent } from '../question-questiongroup-assignment-edit-dialog/question-questiongroup-assignment-edit-dialog.component';
import { ConfirmationDialogService } from 'src/app/modules/confirmation-dialog/services/confirmation-dialog.service';
import { Subscription } from 'rxjs';
import { matchingPropertyPredicate } from 'src/app/functions/misc';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

enum OptionType {
  Simple, Arrangeable
}

class OptionsControl {
  private static defaultOptionTextValues:Array<string> = ["", "", ""];
  private static defaultOptionSolutionValues:Array<boolean> = [ false, false, false ];
  static readonly optionTextFCName:string = "question-option-text";
  static readonly optionSolutionFCName:string = "question-option-solution";
  private formGroupRef:FormGroup;
  private nextOptionFormControlId:number;
  questionOptionTextFCs:Array<FormControl>;
  questionOptionSolutionFCs:Array<FormControl>;
  subscriptions:Array<Subscription>;
  optionFCIds:number[];
  numberOfSolutions:number;
  isNumberOfSolutionsChanged:boolean;

  constructor(formGroupRef:FormGroup, defaultOptionTextValues:Array<string> = OptionsControl.defaultOptionTextValues, defaultOptionSolutionValues:Array<boolean> = OptionsControl.defaultOptionSolutionValues) {
    this.formGroupRef = formGroupRef;
    this.nextOptionFormControlId = 1;
    this.isNumberOfSolutionsChanged = true;
    this.numberOfSolutions = 0;
    this.questionOptionTextFCs = new Array<FormControl>();
    this.questionOptionSolutionFCs = new Array<FormControl>();
    this.subscriptions = new Array<Subscription>();
    this.optionFCIds = new Array<number>();
    for(let i = 0; i < defaultOptionTextValues.length; ++i) {
      this.addOptionForm(defaultOptionTextValues[i], defaultOptionSolutionValues[i]);
      if(defaultOptionSolutionValues[i]) {
        ++this.numberOfSolutions;
      }
    }
  }

  addOptionForm(optionText:string = "", isSolution:boolean = false):void {
    const newOptionTextFormControl = new FormControl(optionText, [ Validators.required ]);
    const newOptionSolutionFormControl = new FormControl(isSolution, [ Validators.required ]);

    const subscription:Subscription = newOptionSolutionFormControl.valueChanges.subscribe(
      () => {
        this.isNumberOfSolutionsChanged = true;
        if(newOptionSolutionFormControl.value) {
          ++this.numberOfSolutions;
        } else {
          --this.numberOfSolutions;
        }
      }
    );

    this.questionOptionTextFCs.push(newOptionTextFormControl);
    this.questionOptionSolutionFCs.push(newOptionSolutionFormControl);
    this.subscriptions.push(subscription);
    this.formGroupRef.addControl(OptionsControl.optionTextFCName + this.nextOptionFormControlId, newOptionTextFormControl);
    this.formGroupRef.addControl(OptionsControl.optionSolutionFCName + this.nextOptionFormControlId, newOptionSolutionFormControl);
    this.optionFCIds.push(this.nextOptionFormControlId);
    ++this.nextOptionFormControlId;
  }

  deleteOptionForm(index:number):void {
    if(this.questionOptionTextFCs.length > 2) {
      if(this.questionOptionSolutionFCs[index].value) {
        --this.numberOfSolutions;
      }
      this.questionOptionTextFCs.splice(index, 1);
      this.questionOptionSolutionFCs.splice(index, 1);

      this.subscriptions[index].unsubscribe();
      this.subscriptions.splice(index, 1);

      this.formGroupRef.removeControl(OptionsControl.optionTextFCName + this.optionFCIds[index]);
      this.formGroupRef.removeControl(OptionsControl.optionSolutionFCName + this.optionFCIds[index]);
      this.optionFCIds.splice(index, 1);
    }
  }

  isControlsValid():boolean {
    if(this.numberOfSolutions !== 1) {
      return false;
    }

    for(let questionOptionTextFC of this.questionOptionTextFCs) {
      if(!questionOptionTextFC.valid) {
        return false;
      }
    }

    return true;
  }

}

function getQuestionOptionsPropertyAsArray<PropertyType>(question:Partial<Question>, propertyName:string):Array<PropertyType> {
  let result:Array<PropertyType> = new Array<PropertyType>();
  for(let option of question.options) {
    result.push(option[propertyName]);
  }
  return result;
}

/**
 * @description This component contains the dialog for the question creation and modification. The title and the displayed buttons are based on the
 * injected data object's `dialogMode` property.
*/
@Component({
  selector: 'app-question-edit-dialog',
  templateUrl: './question-edit-dialog.component.html',
  styleUrls: ['./question-edit-dialog.component.scss']
})
export class QuestionEditDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Relative path for the default image */
  readonly noMediaUploadedImageUrl:string = "/assets/pictures/png/no-camera.png";

  // This assignments is for to enable the usage of the enums/classes in the HTML template.
  DialogMode = DialogMode;
  MediaType = MediaType;
  OptionType = OptionType;
  OptionsControl = OptionsControl;

   /** The title of the dialog. */
  title:string;
  /** Temporary question object */
  question:Partial<Question>;

  // FormGroups and FormControls
  /** FormGroup for the FormControls. */
  questionFG:FormGroup;
  /** FormControl for the questions's text input. */
  questionTextFC:FormControl;
  /** FormControl for the questions's explanation input. */
  questionExplanationFC:FormControl;
  questionIsUpdatedFC:FormControl;
  questionHasIllustrationFC:FormControl;

  optionsControl:OptionsControl;

  optionTypeSelectFC:FormControl;
  newArrangeableOptionFC:FormControl;
  optionType:OptionType;
  displayedArrangeableOptions:string[];
  displayArrangeableError:boolean;
  isCategoryAssignmentEnabled:boolean;

  // Media member variables
  /** The uploaded icon's media type. If there is no locally uploaded media, it holds the media type of the stored media (on the server). */
  uploadedMediaType:MediaType;
  /** The data of the locally uploaded media. */
  uploadedMediaData:string;

  uploadedMedia:any;

  constructor(
    private dialogRef:MatDialogRef<QuestionEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      dialogMode:DialogMode,
      question:Question,
      categories:ReadonlyArray<Readonly<Category>>
      questionGroups:ReadonlyArray<Readonly<QuestionGroup>>
    },
    private questionMangerService:QuestionService,
    private questionFilterService:QuestionFilterService,
    private snackBar: MatSnackBar,
    public dialog:MatDialog,
    private confirmationDialogService:ConfirmationDialogService
  ) { }

  ngOnInit():void {
    const self = this;

    this.questionFG = new FormGroup({});

    // Init member varialbles
    this.displayedArrangeableOptions = new Array<string>();
    this.displayArrangeableError = false;
    this.isCategoryAssignmentEnabled = true;

    this.question = new Question();

    if(this.data.dialogMode === DialogMode.Add) {
      this.title = "Kérdés felvétele";

      this.optionType = OptionType.Simple;
      this.optionTypeSelectFC = new FormControl(this.optionType);
      this.optionTypeSelectFC.valueChanges.subscribe(
        function(optionType:OptionType) {
          self.optionType = optionType;
          if(optionType === OptionType.Arrangeable) {
            self.question.categories.length = 0;
            self.isCategoryAssignmentEnabled = false;
          } else {
            self.isCategoryAssignmentEnabled = true;
          }
        }
      );

      this.optionsControl = new OptionsControl(this.questionFG);
      this.uploadedMediaType = MediaType.None;
    } else {
      this.question.initAsCopy(this.data.question);
      this.title = "Kérdés módosítása";

      this.optionType = this.question.type === "simple" ? OptionType.Simple : OptionType.Arrangeable;
      this.optionTypeSelectFC = new FormControl(this.optionType);
      this.optionTypeSelectFC.disable();

      this.optionsControl = new OptionsControl(this.questionFG, getQuestionOptionsPropertyAsArray(this.question, "text"), getQuestionOptionsPropertyAsArray(this.question, "isSolution"));
      this.displayedArrangeableOptions = getQuestionOptionsPropertyAsArray(this.question, "text");
      this.uploadedMediaType = getMediaTypeFromUrl(this.question.mediaUrl);
    }

    this.questionTextFC = new FormControl(this.question.text, [ Validators.required ]);
    this.questionFG.addControl("questionTextFC", this.questionTextFC);

    this.questionExplanationFC = new FormControl(this.question.explanation);
    this.questionFG.addControl("questionExplanationFC", this.questionExplanationFC);

    this.questionIsUpdatedFC = new FormControl(this.question.isUpdated !== undefined ? this.question.isUpdated : false);
    this.questionFG.addControl("questionIsUpdatedFC", this.questionIsUpdatedFC);

    this.questionFG.addControl("optionTypeSelectFC", this.optionTypeSelectFC);

    this.newArrangeableOptionFC = new FormControl("");
    this.questionFG.addControl("newArrangeableOptionFC", this.newArrangeableOptionFC);

    this.questionFG.addControl("optionTypeSelectFC", this.optionTypeSelectFC);

    this.questionHasIllustrationFC = new FormControl(this.question.hasIllustration !== undefined ? this.question.hasIllustration : false);
    if(this.question.mediaUrl === "") {
      this.questionHasIllustrationFC.disable();
    }
    this.questionFG.addControl("questionHasIllustrationFC", this.questionHasIllustrationFC);

    this.uploadedMediaData = null;
  }

  ngAfterViewInit():void {
    const self = this;
    const mediaInput:HTMLInputElement = document.getElementById("question-media-input") as HTMLInputElement;

    mediaInput.onchange = function() {
      self.uploadedMedia = mediaInput.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(self.uploadedMedia);
      mediaInput.textContent = self.uploadedMedia.name;

      switch(self.uploadedMedia.type) {
        case "image/jpeg":
          self.uploadedMediaType = MediaType.Image;
          break;

        case "video/mp4":
          self.uploadedMediaType = MediaType.Video;
          break;
        default:
          self.uploadedMediaType = MediaType.Unsupported;
      }

      reader.onload = function(e) {
        const mediaPreview:HTMLImageElement = document.getElementById("question-media-preview-media") as HTMLImageElement;
        self.uploadedMediaData = e.target.result.toString();
        mediaPreview.setAttribute("src", self.uploadedMediaData);
        self.questionHasIllustrationFC.enable();
      }
    }

  }

  public ngOnDestroy():void {
    this.optionsControl.subscriptions.forEach((subscription:Subscription) => { subscription.unsubscribe(); });
  }

  /**
   * Gets the media URL based on the uploaded media's state.
   *
   * @returns {string} the media url to render
  */
  getMediaUrl():string {
    if(this.question === null || this.uploadedMediaType === MediaType.None) {
      return this.noMediaUploadedImageUrl;
    } else {
      return this.question.mediaUrl;
    }
  }

  /**
   * Handles the remove media button click. If there is one, removes the actually (locally or remote) uploaded media.
   *
   * @returns {void} nothing
  */
  removeMedia():void {
    if(this.uploadedMediaType === MediaType.Image || this.uploadedMediaType === MediaType.Video) {
      this.uploadedMediaType = MediaType.None;
      this.uploadedMediaData = "";
      this.questionHasIllustrationFC.setValue(false);
      this.questionHasIllustrationFC.disable();
    }
  }

  addArrangeableOption():void {
    this.displayedArrangeableOptions.push(this.newArrangeableOptionFC.value);
    this.newArrangeableOptionFC.setValue("");
    if(this.displayedArrangeableOptions.length > 1) {
      this.displayArrangeableError = false;
    }
  }

  deleteArrangeableOption(index:number):void {
    this.displayedArrangeableOptions.splice(index, 1);
  }

  /**
   * Checks the validity of the form. The form is valid when all FormControls are valid and the submitted number of solutions is exactly one.
   *
   * @returns {boolean} the validity of the form
  */
  isFormValid():boolean {
    //this.optionsControl.isNumberOfSolutionChanged

    // Validity of question text and explanation
    if(this.questionTextFC.invalid || this.questionExplanationFC.invalid) {
      return false;
    }

    // Validity of the option fields based on the question type
    this.optionsControl.isNumberOfSolutionsChanged = false;
    switch(this.optionType) {
      case OptionType.Simple:
        if(!this.optionsControl.isControlsValid()) {
          return false;
        }
        break;
      case OptionType.Arrangeable:
        if(this.displayedArrangeableOptions.length < 2) {
          this.displayArrangeableError = true;
          return false;
        }
        break;
    }

    // Validity of the uploaded image
    if(this.uploadedMediaType === MediaType.Unsupported) {
      return false;
    }

    return true;
  }

  categoryName(categorUuid:string):string {
    return this.data.categories.find(matchingPropertyPredicate("uuid", categorUuid))?.name;
  }

  questionGroupName(questiongroupUuid:string):string {
    return this.data.questionGroups.find(matchingPropertyPredicate("uuid", questiongroupUuid))?.name;
  }

  openQuestionCategoryAssignmentEditDialog():void {
    this.dialog.open(QuestionCategoryAssignmentEditDialogComponent, {
        data: {
          tempQuestionCategories: this.question.categories,
          categories: this.data.categories
        },
        disableClose: true
      }
    );
  }

  openQuestionQuestiongroupAssignmentEditDialog():void {
    this.dialog.open(QuestionQuestiongroupAssignmentEditDialogComponent, {
        data: {
          categories: this.data.categories,
          questionGroups: this.data.questionGroups,
          assignedQuestionGroups: this.question.questionGroups
        },
        disableClose: true
      }
    );
  }

  clearAssignments():void {
    this.confirmationDialogService.open(
      "Hozzárendelések törlése",
      "Biztosan törli a hozzárendelésket? A kérdés törlődik a kategóriák és a kérdéscsoportok alól is, így nem fog kiosztásra kerülni a tesztekben.",
      () => {
        this.question.categories.length = 0;
        this.question.questionGroups.length = 0;
      }
    );
  }

  /**
   * Handles the 'add button' click. The function calls the `isFormValid()` function, which checks the validity of the forms. If there is no error,
   * the corresponding question service function will be called, and the dialog will be closed. The function creates a Angular Material Snackbar
   * which will indicate the result of the operation.
   *
   * @returns {void} nothing
  */
  handleAddButtonClick():void {
    if(this.isFormValid()) {
      this.question.text = this.questionTextFC.value as string;
      this.question.explanation = this.questionExplanationFC.value as string;
      this.question.isUpdated = this.questionIsUpdatedFC.value as boolean;
      this.question.hasIllustration = this.questionHasIllustrationFC.value as boolean;

      switch(this.optionType) {
        case OptionType.Simple:
          this.question.type = "simple";
          for(let i = 0; i < this.optionsControl.questionOptionTextFCs.length; ++i) {
            this.question.options.push({
              text: this.optionsControl.questionOptionTextFCs[i].value as string,
              isSolution: this.optionsControl.questionOptionSolutionFCs[i].value as boolean
            });
          }
          break;

        case OptionType.Arrangeable:
          this.question.type = "arrangeable";
          let position:number = 0;
          for(let optionText of this.displayedArrangeableOptions) {
            this.question.options.push({
              text: optionText,
              position: position++
            });
          }
          break;
      }

      this.addQuestion(this.question, this.uploadedMediaType, this.uploadedMedia);
      this.dialogRef.close();
    }
  }

  /**
   * Handles the 'add button' click. The function calls the `isFormValid()` function, which checks the validity of the forms. If there is no error,
   * the corresponding question service function will be called, and the dialog will be closed. The function creates a Angular Material Snackbar
   * which will indicate the result of the operation.
   *
   * @returns {void} nothing
  */
 handleDeleteButtonClick():void {
    this.confirmationDialogService.open(
      "Kérdés törlsése",
      "Biztosan törli a kérdést?",
      async () => {
        await this.deleteQuestion(this.question.uuid);
        this.dialogRef.close();
      }
    );
  }

  /**
   * Handles the 'modify button' click. The function calls the `isFormValid()` function, which checks the validity of the forms. If there is no error,
   * the corresponding question service function will be called, and the dialog will be closed. The function creates a Angular Material Snackbar
   * which will indicate the result of the operation.
   *
   * @returns {void} nothing
  */
 handleModifyButtonClick():void {
    if(this.isFormValid()) {
      // Build a Question based on the original and the input values
      let modifiedQuestion:Question = new Question();
      modifiedQuestion.uuid = this.data.question.uuid;
      modifiedQuestion.text = this.questionTextFC.value as string;
      modifiedQuestion.explanation = this.questionExplanationFC.value as string;
      modifiedQuestion.questionGroups = this.question.questionGroups;
      modifiedQuestion.mediaUrl = this.data.question.mediaUrl;
      modifiedQuestion.categories = this.question.categories;
      modifiedQuestion.metadata = this.data.question.metadata;
      modifiedQuestion.isUpdated = this.questionIsUpdatedFC.value as boolean;
      modifiedQuestion.hasIllustration = this.questionHasIllustrationFC.value as boolean;

      switch(this.optionType) {
        case OptionType.Simple:
          modifiedQuestion.type = "simple";
          for(let i = 0; i < this.optionsControl.questionOptionTextFCs.length; ++i) {
            modifiedQuestion.options.push({
              text: this.optionsControl.questionOptionTextFCs[i].value as string,
              isSolution: this.optionsControl.questionOptionSolutionFCs[i].value as boolean
            });
          }
          break;

        case OptionType.Arrangeable:
          modifiedQuestion.type = "arrangeable";
          let position:number = 0;
          for(let optionText of this.displayedArrangeableOptions) {
            modifiedQuestion.options.push({
              text: optionText,
              position: position++
            });
          }
          break;
      }

      this.modifyQuestion(modifiedQuestion, this.uploadedMediaType, this.uploadedMedia);
      this.dialogRef.close();
    }
  }

  private async addQuestion(newQuestion:Partial<Question>, mediaType:MediaType, questionMedia:any):Promise<void> {
    try {
      await this.questionMangerService.addQuestion(newQuestion, mediaType, questionMedia);
      this.questionFilterService.updateDisplayedQuestions();
      this.snackBar.open("Kérdés sikeresen hozzáadva", "Bezár", { duration: 2000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}
  }

  private async deleteQuestion(questionUuid:string):Promise<void> {
    try {
      await this.questionMangerService.deleteQuestion(questionUuid);
      this.questionFilterService.updateDisplayedQuestions();
      this.snackBar.open("Kérdés sikeresen törölve", "Bezár", { duration: 2000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}
  }

  private async modifyQuestion(modifiedQuestion:Question, mediaType:MediaType, questionMedia:any):Promise<void> {
    try {
      await this.questionMangerService.modifyQuestion(modifiedQuestion, mediaType, questionMedia);
      this.snackBar.open("Kérdés sikeresen módosítva", "Bezár", { duration: 2000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}
  }

  public onArrangeableAnswerDragEnd(event: any){
    moveItemInArray(this.displayedArrangeableOptions, event.previousIndex, event.currentIndex);
  }

}
