import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from 'src/app/services/common/notifier-service.service';
import { Vendor } from 'src/app/classes/model/vendor';
import { VendorService } from 'src/app/services/vendor.service';
import { VendorDataFormComponent, VendorDataFormResult } from '../vendor-data-form/vendor-data-form.component';

export type ModifyVendorDialogInput = {
  vendor: Vendor;
}

export type ModifyVendorDialogOutput = Pick<Vendor, "phoneNumber" | "permissions" | "hasContract">;
@Component({
  selector: 'app-modify-vendor-dialog',
  templateUrl: './modify-vendor-dialog.component.html',
  styleUrls: ['./modify-vendor-dialog.component.scss']
})
export class ModifyVendorDialogComponent implements OnInit {
  @ViewChild(VendorDataFormComponent, { static: true }) vendorDataFormComponent: VendorDataFormComponent;

  protected isModificationInProgress: boolean = false;
  protected vendorHasContract: boolean;
  protected defaultFormValues: Pick<Vendor, "email" | "name" | "type" | "phoneNumber" | "hasContract" |"type"> & {hasActiveSzamlazzHuConnection: boolean};

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: ModifyVendorDialogInput,
    private vendorService: VendorService,
    private dialogRef: MatDialogRef<ModifyVendorDialogComponent, ModifyVendorDialogOutput>,
    private notifierService: NotifierService
  ) { }

  public static open(
    dialogService: MatDialog,
    data: ModifyVendorDialogInput
  ): MatDialogRef<ModifyVendorDialogComponent, ModifyVendorDialogOutput> {
    return dialogService.open<
      ModifyVendorDialogComponent,
      ModifyVendorDialogInput,
      ModifyVendorDialogOutput
    >(
      ModifyVendorDialogComponent,
      {
        data: data,
        disableClose: true
      }
    );
  }

  public ngOnInit(): void {
    this.vendorHasContract = this.data.vendor.hasContract;
    this.defaultFormValues = {
      phoneNumber: this.data.vendor.phoneNumber,
      email: this.data.vendor.email,
      type: this.data.vendor.type,
      name: this.data.vendor.name,
      hasContract: this.data.vendor.hasContract,
      hasActiveSzamlazzHuConnection: this.data.vendor.currentSzamlazzHuConnection?.hasActiveConnection ?? false
    };
  }

  /**
   * Handles the registration button click. It makes an API call through the vendor service to registrate the
   * vendor with the given infromations in the vendor data form.
   */
  public async onSaveModificationsButtonClick(): Promise<void> {
    // Set the registration in progress to true
    this.isModificationInProgress = true;

    // Get the form data from the from subcomponent
    const vendorDataFormResult: VendorDataFormResult = this.vendorDataFormComponent.getFormData();

    // Set the vendor fields
    const vendorFields: Partial<Vendor> = {
      phoneNumber: vendorDataFormResult.phoneNumber
    };

    try {
      // Registrate the vendor
      const modifiedFields = await this.vendorService.modifyVendor(
        this.data.vendor.uuid,
        vendorDataFormResult.phoneNumber,
        vendorDataFormResult.hasActiveSzamlazzHuConnection,
        this.vendorHasContract
      );
      // On succes, close the dialog and show a notification about it
      this.dialogRef.close(modifiedFields);
      this.notifierService.notify("success", "Sikeres viszonteladó létrehozás");
    } catch (error: any) {
      // If the error is vendor already exists, show an notification
      if (error.error?.error === "VENDOR_ALREADY_EXISTS") {
        this.notifierService.notify("error", "A viszonteladó már regisztrálva van ezzel az e-mail címmel.");
      }
    }

    // Set the registration in progress to false
    this.isModificationInProgress = false;
  }

  /**
   * Returns that the vendor data for is valid.
   * 
   * @returns true, if the form valid, false otherwise
   */
  protected isFormValid(): boolean {
    return this.vendorDataFormComponent.isFormValid();
  }

}
