import {
  Directive,
  HostListener,
  Component,
  Output,
  EventEmitter,
  Input,
  SimpleChange,
} from "@angular/core";

@Directive({
  selector: "[track-scroll]",
})
export class TrackScrollDirective {
  @Output() pageYPositionChange: EventEmitter<any> = new EventEmitter();
  @HostListener("window:scroll", ["$event"])
  track(event) {
    console.log("Scroll Event", event);
    this.pageYPositionChange.emit(document.documentElement.scrollTop);
  }
  constructor() {}
}
