<div *ngIf="isContentLoading == false">
  <div style="width: 900px; display: block">
    <div class = "pactice-path-name">
      {{ this.practicePath.name }}
    </div>
    <ng-template [ngIf]="!isContentLoading">
      <mat-tab-group
        [selectedIndex]="tabIndex"
        (selectedIndexChange)="tabIndexChange($event)"
        mat-align-tabs="center"
        animationDuration="0ms"
      >
        <mat-tab label="Nyers videó">
          <app-raw-video-tab
            [practicePath]="this.practicePath"
            (anonymizationStartedEvent)="anonymizationStarted()"
          ></app-raw-video-tab>
        </mat-tab>

        <mat-tab label="Anonimizált videók">
          <app-anonymized-videos-tab
            [anonymizedVideos]="this.practicePath.video.anonymizedVideos"
            [selectedIndex]="selectedAnonymizationIndex"
            [practicePath]="this.practicePath"
            [anonymizerTask]="anonymizerTask"
            (selectedAnonymizedIndexChanged)="selectedAnonymizedIndexChanged($event)"
          ></app-anonymized-videos-tab>
        </mat-tab>

        <mat-tab label="Végleges videók">
          <app-final-videos-tab
            [finalVideos]="this.practicePath.video.finalVideos"
            [practicePath]="this.practicePath"
            (clickedOnAnonymizedVideoEvent)="handleClickedOnAnonomizedVideoEvent($event)"
            (finalVideoUploaded) = "onFinalVideoUploaded($event)"
          ></app-final-videos-tab>
        </mat-tab>

        <mat-tab label="Kiadott videó" [disabled] = "practicePath.video.releasedVideo == null">
          <app-released-videos-tab
            [releasedVideo]="practicePath.video.releasedVideo"
          ></app-released-videos-tab>
        </mat-tab>

      </mat-tab-group>
    </ng-template>
  </div>
</div>


<div *ngIf="isContentLoading">
  <mat-spinner></mat-spinner>
</div>
