import { GeneralFileUploadService } from './general-file-upload-service';
import { AwsS3Service } from "src/app/services/common/aws-s3.service";
import { GeoTaggedFrame } from "./gopro-metadata-extractor";
import { Injectable } from "@angular/core";
import {
  CriticalPoint,
  CriticalPointAssignment,
  PathEditState,
  PracticePath,
  PracticePathBaseInformations,
  PracticePathNarrationDuringRecording
} from "../classes/model/practice-path";
import { BackendService } from "./common/backend.service";
import { HttpErrorHandlerService } from "./common/http-error-handler.service";
import { PracticeCity } from "../classes/model/practice-city";
import { NamedFile } from "./common/aws-s3.service";
import { S3 } from "aws-sdk";
import { VideoComposerService } from "./video-composer.service";
import { FinalVideo, PracticeVideo } from "../classes/model/practice-video";


@Injectable({
  providedIn: "root",
})
export class PracticePathService {
  private readonly practicePathApiUrlFragment: string =
    "/api/admin/practice_path";
  private readonly practicePathUploadRawVideoApiUrlFragment: string =
    "/api/admin/practice_path/upload_raw_video";
  private readonly practicePathUploadFinalVideoApiUrlFragment: string =
    "/api/admin/practice_path/upload_final_video";
  private readonly changeEditStatesApiUrlFragment: string =
    "/api/admin/practice_path/change_edit_state";
  private readonly selectPracticePathsApiPath: string =
  "/api/admin/practice_path/select";

  private readonly narrationsOfRecordingFileArrayName: string = "narrationsOfRecording";

  constructor(
    private backendService: BackendService,
    private httpErrorHandlerService: HttpErrorHandlerService,
    private s3Service: AwsS3Service,
    private videoComposerService:VideoComposerService,
    private generalFileUploadService:GeneralFileUploadService
  ) { }

  private getApiUrlPath(cityUuid:string, practicePathUuid?:string):string {
    let apiUrlFragment:string = `/api/admin/practice_path_city/${cityUuid}/practice_paths`;

    if(practicePathUuid) {
      apiUrlFragment += `/${practicePathUuid}`;
    }

    return apiUrlFragment;
  }

  // Csak és kizárólag a GeoTaggedFrames-t updateli
  // nincs side-effect, a backend csak kicseréli a path tömböt az újra
  // Igazzal tér vissza, ha sikerült a frissítés
  public async updateGeoTaggedFramesPath(
    practicePathUuid: string,
    newPath: Readonly<GeoTaggedFrame[]>
  ) {
    try {
      const response: {
        data: PracticePath;
        criticalPoints: Array<CriticalPoint>;
      } = await this.backendService.callApi(
        this.practicePathApiUrlFragment + `/${practicePathUuid}/update_path`,
        "POST",
        { newPath: newPath }
      );

      return true;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba az útvonal pontok módosításakor."
      );
      return false;
    }
  }

  /**
   * Return the practice paths of the city. If the mode is set to 'summary', only the base informations are returned.
   *
   * @param cityUuid uuid of the city
   * @param mode the mode of the query ('summary' or 'all')
   *
   * @returns array of practice paths or their base informations based in the set query mode
   */
  fetchPracticePathsOfCity(cityUuid:string, mode?:"all"):Promise<Array<PracticePath>>
  fetchPracticePathsOfCity(cityUuid:string, mode?:"summary"):Promise<Array<PracticePathBaseInformations>>
  fetchPracticePathsOfCity(cityUuid:string, mode?:"all"|"summary"):Promise<Array<PracticePath>>|Promise<Array<PracticePathBaseInformations>>
  public async fetchPracticePathsOfCity(
    cityUuid:string,
    mode:"all"|"summary" = "all"
  ):Promise<Array<PracticePath>|Array<PracticePathBaseInformations>> {
    const apiUrlFragment:string = `${this.getApiUrlPath(cityUuid)}?mode=${mode}`;
    let practicePaths:Array<PracticePath>|Array<PracticePathBaseInformations> = [];

    try {
      const response:{ practicePaths:Array<PracticePath>|Array<PracticePathBaseInformations> } = await this.backendService.callApi(
        apiUrlFragment,
        "GET"
      );
      practicePaths = response.practicePaths;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a vizsgahelyszín útvonalainak lekérdezése közben.");
    }

    return practicePaths;
  }

  fetchPracticePath(pathUuid:string, mode?:"all"):Promise<PracticePath>
  fetchPracticePath(pathUuid:string, mode?:"summary"):Promise<PracticePathBaseInformations>
  fetchPracticePath(pathUuid:string, mode?:"all"|"summary"):Promise<PracticePath>|Promise<PracticePathBaseInformations>
  public async fetchPracticePath(
    pathUuid:string,
    mode:"all"|"summary" = "all"
  ) {
    const apiUrlFragment:string = `${this.practicePathApiUrlFragment}/${pathUuid}?mode=${mode}`;

    try {
      const response:{ practicePath: PracticePath } = await this.backendService.callApi(apiUrlFragment, "GET");
      return response.practicePath;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az útvonal lekérése közben");
    }
  }

  public async fetchPracticePathSetFields(practicePathUuids:Array<string>, fields:ReadonlyArray<keyof PracticePath>):Promise<PracticePathQueryResult> {
    const apiPath:string = `${this.selectPracticePathsApiPath}?fields=${fields.join(",")}`;
    const httpBody:Object = {
      practicePathUuids: practicePathUuids
    }

    let result:PracticePathQueryResult;

    try {
      const response:PracticePathQueryResult = await this.backendService.callApi(apiPath, "POST", httpBody);
      result = response;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az útvonalak feloldása közben.");
    }

    return result;
  }

  public async addPracticePathToCity(
    cityUuid:string,
    newPracticePath: {
      name: string;
      zoneUuids: string[];
      isFullPracticePath: boolean;
      nodeTouches?: { nodeUuid: string; touchMs: number }[];
      examPathNumber?: number;
      isFree: boolean
    }
  ): Promise<PracticePath> {
    const httpBody = {
      data: {
        item: newPracticePath,
      },
    };

    try {
      const response: { practicePath: PracticePath } = await this.backendService.callApi(
        this.getApiUrlPath(cityUuid),
        "PUT",
        httpBody
      );

      Object.assign<Partial<PracticePath>, Partial<PracticePath>>(
        newPracticePath,
        response.practicePath
      );
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba az útvonal létrehozása közben."
      );
    }

    return newPracticePath as PracticePath;
  }

  // (1) Kérünk ideiglenes accesst egy tmp s3 mappához
  // (2) Feltöltjük a teljes videót
  // (3) Mivel ez egy teljes, full videó, ezért nem kell összeillesztenünk, szimplán csak
  // meghívjuk az uploadRawVideo endpointot, ami egy külső url-t vár. Átadjuk neki az ideiglenes mappába feltöltött videót
  public async uploadFullRawPracticePathVideo(
    practicePathUuid:string,
    file: NamedFile,
    gpsMetadata: GeoTaggedFrame[],
    onProgress: (state: "upload" | "save", progress: number) => void
  ):Promise<PracticePath> {
    // Upload file the server, then the server stream it to AWS
    let generalUploadedFileRelativeUrlInBucket:string;
    try {
      generalUploadedFileRelativeUrlInBucket = await this.generalFileUploadService.uploadFile(file.file, (loaded: number, total:number) => {
        onProgress("upload", Math.floor(loaded / total * 100));
      });
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba történt a nyers videó feltöltése közben");
    }

    // A szerver letölti az ideiglenes path-ről, majd feltölti a saját mappájába
    onProgress("save", 100);
    return await this.updateRawPracticePathVideo(
      practicePathUuid,
      generalUploadedFileRelativeUrlInBucket,
      file.name,
      gpsMetadata
    );
  }

  // Meghívja azt az endpointot ami egy külső url alapján frissíti a practice path-et
  public async updateRawPracticePathVideo(
    practicePathUuid:string,
    generalUploadedFilePathInBucket: string,
    originalVideoName: string,
    gpsMetadata: GeoTaggedFrame[]
  ): Promise<PracticePath> {
    const httpDataBody: Object = {
      practicePathUuid: practicePathUuid,
      generalUploadedFilePath: generalUploadedFilePathInBucket,
      originalVideoName: originalVideoName,
      gpsMetadataOfVideo: gpsMetadata,
    };

    try {
      const response: { data: PracticePath } = await this.backendService.callApi(
          this.practicePathUploadRawVideoApiUrlFragment,
        "POST",
        httpDataBody,
        undefined,
        undefined,
        1000 * 60 * 60 * 20
      );

      return response.data;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba a videó feltöltése közben."
      );
    }
  }

  /**
   * Uploads a final video for a practice path.
   *
   * @param practicePathUuid the uuid of the target practice path
   * @param file the uploaded videofile
   * @param onProgress callback on the upload progress
   *
   * @returns the updated final videos of the practice path
   */
  public async uploadFinalPracticePathVideo(
    practicePathUuid:string,
    file: NamedFile,
    onProgress: (state: "upload" | "save", progress: number) => void
  ):Promise<PracticeVideo> {
    // Upload file the server, then the server stream it to AWS
    let generalUploadedFileRelativeUrlInBucket:string;
    try {
      generalUploadedFileRelativeUrlInBucket = await this.generalFileUploadService.uploadFile(file.file, (loaded: number, total:number) => {
        onProgress("upload", Math.floor(loaded / total * 100));
      });
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba történt a végleges videó feltöltése közben");
    }

    // A szerver letölti az ideiglenes path-ről, majd feltölti a saját mappájába
    onProgress("save", 100);
    return await this.updateFinalPracticePathVideo(
      practicePathUuid,
      generalUploadedFileRelativeUrlInBucket,
      file.name
    );
  }

  /**
   * Uploads a final video for a practice path from the provided URL. The server
   * dowloads the target file and uploads it to the appropriate bucket path.
   *
   * @param practicePathUuid the uuid of the target practice path
   * @param externalUrl the url of the external file
   * @param originalVideoName the original name of the file
   *
   * @returns the updated final videos of the practice path
   */
  public async updateFinalPracticePathVideo(
    practicePathUuid:string,
    generalUploadedFilePath: string,
    originalVideoName:string
  ):Promise<PracticeVideo> {
    // Create the http body
    const httpBody:Object = {
      practicePathUuid: practicePathUuid,
      generalUploadedFilePath: generalUploadedFilePath,
      originalVideoName: originalVideoName
    };

    try {
      // Send the request
      const response: { practicePathVideo:PracticeVideo } = await this.backendService.callApi(
          this.practicePathUploadFinalVideoApiUrlFragment,
        "POST",
        httpBody,
        undefined,
        undefined,
        1000 * 60 * 60 * 20
      );

      return response.practicePathVideo;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba a végleges videó feltöltése közben."
      );
    }
  }

  public async deletePracticePath(practicePathUuid:string): Promise<void> {
    const apiUrlFragment:string = `${this.practicePathApiUrlFragment}/${practicePathUuid}`;

    try {
      await this.backendService.callApi(apiUrlFragment, "DELETE");
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba az útvonal törlése közben."
      );
    }
  }

  // Visszatér a backend a legfrisebb practice path-el a modify után
  public async modifyPracticePath(
    practicePathUuid:string,
    updatedPracticePathValues: {
      name: string;
      editState: string;
      isFullPracticePath: boolean;
      nodeTouches?: { nodeUuid: string; touchMs: number }[];
      examPathNumber?: number;
      zoneUuids: string[],
      isFree: boolean
    }
  ): Promise<PracticePath> {
    const apiUrlFragment:string = `${this.practicePathApiUrlFragment}/${practicePathUuid}`;

    const httpBody = {
      item: updatedPracticePathValues,
    };

    try {
      const response: { updatedPracticePath: PracticePath } = await this.backendService.callApi(
        apiUrlFragment,
        "POST",
        httpBody
      );

      return response.updatedPracticePath;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba az útvonal módosítása közben."
      );
    }
  }

  // Több kritikus pontnak egyszerre megváltoztatja az edit state-et
  // ha például változott egy kritikus pont, ami benne van x videóban
  // akkor ezeket a videókat átállítjuk 'Ellenőrzésre vár'-ra
  public async changeEditStates(
    practicePaths: ReadonlyArray<PracticePath>,
    newEditState: PathEditState
  ): Promise<void> {
    const httpBody: Object = {
      practicePathUuids: practicePaths.map((practicePath) => practicePath.uuid),
      newEditState: newEditState,
    };

    try {
      await this.backendService.callApi(
        this.changeEditStatesApiUrlFragment,
        "POST",
        httpBody
      );
      practicePaths.forEach((practicePath: PracticePath) => {
        practicePath.editState = newEditState;
      });
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Az érintett útvonalak állapotának frissítése nem sikerült."
      );
    }
  }

  /**
   * Returns the number of the non-verified critical points of the practice path.
   *
   * @param practicePath the practice path
   *
   * @returns the number of the non-verified critical points
   */
  public getNumberOfNonVerifiedCriticalPointsOfPath(
    practiceCity:PracticeCity,
    practicePath:PracticePath|PracticePathBaseInformations
  ):number {
    // Filter all critical point assignents of which the corresponding critical point is not verified
    // and return the resulting array's length
    return practicePath.criticalPointAssignments.filter(
      (criticalPointAssignment:CriticalPointAssignment) => {
        // Get the corresponding critical point from the city
        const criticalPoint:CriticalPoint = practiceCity.criticalPoints.find(
          criticalPoint => criticalPoint.uuid === criticalPointAssignment.criticalPointUuid
        );

        // If the critical point not exist, the integrity is damaged
        if(criticalPoint == undefined) {
          return false;
        }

        return !criticalPoint.isVerified;
      }
    ).length;
  }

  /**
   * Returns the number of critical points without any stop points in the given practice path.
   *
   * @param practicePath the target practice path
   *
   * @returns the number of critical points without stop point
   */
  public getNumberOfCriticalPointsWithoutStopPointOfPath(practicePath:PracticePath|PracticePathBaseInformations):number {
    return practicePath.criticalPointAssignments.filter(
      (criticalPointAssignment:CriticalPointAssignment) => {
        return criticalPointAssignment.stopPoints.length === 0;
      }
    ).length;
  }


  public async uploadNarrationsDuringRecordingToPracticePath(
    practicePathUuid:string,
    titlesOfFiles:Array<{ fileName:string, title?:string }>,
    files:Array<File>,
    onUploadProgress:(loaded: number, total?: number) => void
  ):Promise<Array<PracticePathNarrationDuringRecording>> {
    const apiUrlPath:string = `${this.practicePathApiUrlFragment}/${practicePathUuid}/upload-narrations-during-recording`;

    const httpBody = {
      titlesOfFiles: titlesOfFiles
    }

    const fileMap:Map<string, Array<File>> = new Map<string, Array<File>>();
    fileMap.set(this.narrationsOfRecordingFileArrayName, files);

    try {
      const response:{ narrationsDuringRecording:Array<PracticePathNarrationDuringRecording> } = await this.backendService.callApi(
        apiUrlPath,
        "POST",
        httpBody,
        fileMap,
        {
          onUploadProgress: onUploadProgress
        },
        1000 * 60 * 60 * 2
      );

      return response.narrationsDuringRecording;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a narrációs fájlok feltöltése közben");
    }
  }

}

export type PracticePathQueryResult = {
  practicePaths:Array<Partial<PracticePath>&Pick<PracticePath, "uuid">>;
  notFoundPracticePathUuids:Array<string>;
}
