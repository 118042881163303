import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StudentService } from 'src/app/services/student.service';
import { Student } from 'src/app/classes/model/student';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { StudentDetailsDialogComponent, StudentDetailsDialogData } from './student-details-dialog/student-details-dialog.component';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { debounceTime, Subscription } from 'rxjs';
import { getStringWithRemovedDiacritics } from "src/app/functions/compare-strings-without-accents";

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator:MatPaginator;
  @ViewChild(MatSort,  { static: true }) sort:MatSort;

  stundentSearchFC:FormControl<string>;
  
  studentLoadingInProgress:boolean;
  studentFilteringInProgress:boolean;

  dataSource:MatTableDataSource<Readonly<Student>>;
  displayedColumns:ReadonlyArray<string> = [ "uuid", "fullname", "email", "customerId", "registrationDate" ];
  filteredArray:Array<Student>;

  studentSearchSubscription:Subscription;

  constructor(
    private studentService:StudentService,
    public dialog:MatDialog,

  ) { }

  public ngOnInit():void {
    // Create the filtered array
    this.filteredArray = new Array<Student>();

    // Create the datasource for the material table
    this.dataSource = new MatTableDataSource<Readonly<Student>>();
    this.dataSource.data = new Array<Student>();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // Create the form control
    this.stundentSearchFC = new FormControl<string>("");
    this.studentSearchSubscription = this.stundentSearchFC.valueChanges.pipe(
      debounceTime(600)
    ).subscribe(
      () => {
        this.updateFilteredArray();
      }
    );
    
    const shouldRefreshStudentsData:boolean = this.studentService.getStudentsArrayRef().length === 0;
    this.loadStudents(shouldRefreshStudentsData);
  }

  public ngOnDestroy():void {
    this.studentSearchSubscription?.unsubscribe()
  }

  updateFilteredArray():void {
    this.studentFilteringInProgress = true;

    this.filteredArray.length = 0;
    const searchValue:string = this.stundentSearchFC.value.toLocaleLowerCase().trim();
    const searchValueWithoutHyphen = searchValue.split("-").join("");
    // Transform the seach value, where the diactitics are removed (á -> a, etc.)
    const seachValueWithoutDiacritics:string = getStringWithRemovedDiacritics(searchValue);
    
    this.filteredArray = this.studentService.getStudentsArrayRef().filter(
      (student:Student) => {
        // Get the full name without diacritics
        const fullnameWithoutDiacritics:string = getStringWithRemovedDiacritics(student.fullname.toLocaleLowerCase());

        // Check if the e-mail contains the search value
        if(student.email.toLocaleLowerCase().includes(searchValue)) {
          return true;
        }

        // Check if the uuid contains the search value
        if(student.uuid.toLocaleLowerCase().includes(searchValue)) {
          return true;
        }

        // Check if the name contains the search value (diacritics excluded)
        if(fullnameWithoutDiacritics.includes(seachValueWithoutDiacritics)) {
          return true;
        }

        // Check if the customer id contains the search value
        if(student.customerId?.id.toLocaleLowerCase().includes(searchValueWithoutHyphen)) {
          return true;
        }

        return false;
      }
    );

    this.dataSource.data = this.filteredArray;

    this.studentFilteringInProgress = false;
  }

  public async openStudentDetailsDialog(student:Readonly<Student>):Promise<void> {
    this.dialog.open<StudentDetailsDialogComponent, StudentDetailsDialogData>(
      StudentDetailsDialogComponent,
      {
        data: {
          studentUuid: student.uuid
        }
      }
    );
  }

  public async handleReloadButtonClick():Promise<void> {
    await this.loadStudents();
  }

  public async loadStudents(refreshStudents:boolean = true):Promise<void> {
    this.studentLoadingInProgress = true;
    
    try {
      if(refreshStudents) {
        await this.studentService.loadDataIntoCache();
      }
      this.dataSource.data = [];
      this.updateFilteredArray();
    } catch(error:any) {}

    this.studentLoadingInProgress = false;
  }

  protected getCustomerIdOfStudent(student:Student):string {
    if(!student.customerId?.id) {
      return "-"
    }

    const separatorPosition:number = student.customerId.id.length / 2;
    return `${student.customerId.id.substring(0, separatorPosition)}-${student.customerId.id.substring(separatorPosition,)}`;
  }
}