<div (click)="onTapButton()" class="main-container">
  <mat-icon
    [ngClass]="{
      'active-gps-mat-icon': shouldFollow,
      enabled: shouldFollow
    }"
    [style]="
      'cursor:pointer;color:' + (shouldFollow ? '#FF7168' : '#666666') + ';'
    "
  >
    {{ shouldFollow ? "gps_fixed" : "gps_off" }}
  </mat-icon>
</div>
