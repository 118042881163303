<div class = "title">
    Vizsga érintési pont sorrend megadás vizsgaútvonalakhoz
</div>
<div class = "exam-numbers-container">
    <div
        *ngFor = "let thesisNumberData of thesisNumberDatas; let i = index"
        class = "exam-number-card"
        [class.selected] = "i === selectedExamNumber"
        (click) = "selectExamNumber(i)"
    >
        {{ i + 1 }}
        <span *ngIf = "thesisNumberData.hasUnsavedWork" class = "has-unsaved-work"> * </span>
    </div>
</div>

<div class = "columns">
    <div>
        <div class = "subtitle">
            Nem hozzárendelt vizsga érintési pontok
        </div>
        <div style = "overflow: hidden;">
            <ng-container
                *ngIf="thesisNumberDatas[selectedExamNumber].unassignedNodes.length; then unassignedNodeList; else noUnassignedNodes"
            ></ng-container>

            <ng-template #unassignedNodeList>
                <div class = "nodes">
                    <div class = "node" *ngFor = "let node of thesisNumberDatas[selectedExamNumber].unassignedNodes">
                        <div>
                            {{ node.name }}
                        </div>
                        <div>
                            <span class = "material-icons" (click) = "assingNodeToPosition(node)"> add </span>
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template #noUnassignedNodes>
                <div class = "no-nodes-in-list">
                    Nincsenek hozzárendelhető érintési pontok.
                </div>
            </ng-template>
            </div>

    </div>
    <div>
        <div class = "subtitle">
            Hozzárendelt érintési pontok
        </div>
        <div cdkDropList class = "nodes" (cdkDropListDropped)="onAssignedNodeDragEnd($event)">
            <ng-container
                *ngIf="thesisNumberDatas[selectedExamNumber].assignedNodes.length; then assignedNodeList; else noAssignedNodes"
            ></ng-container>

            <ng-template #assignedNodeList>
                <div cdkDrag class = "node" *ngFor = "let node of thesisNumberDatas[selectedExamNumber].assignedNodes; let i = index">
                    <div>
                        {{ node.name }}
                    </div>
                    <div>
                        <span class = "material-icons" (click) = "unassingNodeToPosition(i)"> delete </span>
                    </div>
                </div>
            </ng-template>

            <ng-template #noAssignedNodes>
                <div class = "no-nodes-in-list">
                    Nincsenek hozzárendelt érintési pontok.
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class = "actions">
    <button
        mat-raised-button
        color = "primary"
        (click) = "handleSaveButtonClick()"
    >
        {{ selectedExamNumber + 1 }}. vizsgatétel mentése
    </button>
    <button mat-raised-button mat-dialog-close> Bezár </button>
</div>
