<div [ngClass]="{
    'active-icon': this.isActive
  }" class="timed-icon-card">
  <!-- Icon és intervallum -->
  <div style="display:flex; align-items:center;">
    <!-- Icon kép -->
    <img class="icon-img" [src]="this.resolvedPracticeIcon.iconUrl"/>

    <!-- Idő intervallum -->
    <div style="margin-left:10px; display:inline;">
      <span (click)="this.onTapTime.next(this.timedIcon.startFrame);" class="clickable-time">{{ this.timedIcon.startFrame | msToTimeString }}</span>
      -
      <span (click)="this.onTapTime.next(this.timedIcon.endFrame);" class="clickable-time">{{ this.timedIcon.endFrame | msToTimeString }}</span>
    </div>
  </div>

  <!-- Szerkesztés/Törlés -->
  <div style="display:flex;gap:7px;">
    <mat-icon (click)="this.onTapEdit.next();" style="cursor: pointer"  > edit</mat-icon>
    <mat-icon (click)="this.onTapDelete.next();" class="delete-icon" style="cursor: pointer"
      >delete</mat-icon
    >
  </div>
</div>
