import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'msToTimeString'})
export class MsToTimeStringPipe implements PipeTransform {
  transform(milliseconds:number):string {
    if(milliseconds === undefined || Number.isNaN(milliseconds)) {
        return "-";
      }

      milliseconds = Math.floor(milliseconds);
      const ms = milliseconds % 1000;
      const s = Math.floor(milliseconds / 1000) % 60;
      const m = Math.floor(milliseconds / 1000 / 60) % 60;
      const h = Math.floor(milliseconds / 1000 / 60 / 60);

      return (h > 0 ? `${h}:` : "") + (m + "").padStart(2, "0") + ":" + (s + "").padStart(2, "0") + "." + (ms + "").slice(0, 3).padStart(3, "0"); 
  }
}