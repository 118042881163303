<!-- Header -->
<div class = "title">
    Viszonteladó módosítása
</div>

<!-- Content -->
<div>
    <app-vendor-data-form
        [mode] = "'edit'"
        [defaultValues] = "defaultFormValues"
        [isSzamlazzHuConnectionCheckboxEnabled]="data.vendor.currentSzamlazzHuConnection != undefined"
    ></app-vendor-data-form>
    <div>
        
        <mat-checkbox [(ngModel)] = "vendorHasContract"> Szerződtetett viszonteladó </mat-checkbox>
    </div>
</div>

<!-- Actions -->
<mat-dialog-actions align = "end">
    <button mat-raised-button mat-dialog-close>
        Bezár
    </button>
    <button
        mat-raised-button
        color = "primary"
        (click) = "onSaveModificationsButtonClick()"
        [disabled] = "!isFormValid() || isModificationInProgress"
        [class.button-loading] = "isModificationInProgress"
    >
        Módosítások mentése
    </button>
</mat-dialog-actions>
