export class PracticeVideo {
    rawVideo:RawVideo;
    rawVideoConvertedMinimalBitrate:RawVideoMinimalBitrate|undefined;
    anonymizedVideos:Array<AnonymizedVideo>;
    finalVideos:Array<FinalVideo>;
    releasedVideo?:ReleasedVideo;
}

export class RawVideo {
    videoUrl:string;
    creationTimestamp:number;
    originalFileName:string;
}

export class RawVideoMinimalBitrate {
    originalFileName:string;
    videoCreationTimestamp:number;
    minimalBitrateVideoUrl:string;
    bitrateMbit:number;
}

export class AnonymizedVideo {
  constructor(
    public uuid: string,
    public anonymizerTaskId: string, // Ez a task hozza létre a videót
    public isAnonymizedTaskFinished: boolean, // Az anonimizált task végzett-e, ha igen, akkor van videoUrl
    public creationTimestamp: number,
    public isPermanentDeleted:boolean,

    // isPermanentDeleted true esetén lesz értéke!
    public permanentDeletionInformation?:{
      deletedBy: "admin" | "system",
      adminUuid?:string, // Ha a törlő admin volt, akkor a uuid
      reason?: string, // Nem kötelező, admin megadhatja a jövőben
      timestamp: number
    },
    public videoUrl?: string | undefined, // relative, az s3 bucketban, akkor van értéke ha az isAnonymizedTaskFinished true

    ) {}
}

// Végleges videó (anonimizált videóból létrehozva vagy csak egy feltöltés)
export class FinalVideo {
  constructor(
    public uuid: string,
    public videoUrl: string | undefined,
    public creationTimestamp: number,
    public isAnonymizedFinalized: boolean,
    public isPermanentDeleted: boolean,

    // isPermanentDeleted true esetén lesz értéke!
    public permanentDeletionInformation?:{
      deletedBy: "admin" | "system",
      adminUuid?:string, // Ha a törlő admin volt, akkor a uuid
      reason?: string, // Nem kötelező, admin megadhatja a jövőben
      timestamp: number
    },
    // Csak akkor létezik, ha anonimizált lett véglegesítve.
    // illetve, lehet, hogy az értéke invalid, mivel az anonimizált videók törölhetőek
    // mielőtt hivatkozunk rá meg kell nézni, hogy az anonimizáltnak az isPermanentDeleted értéke hamis-e
    public anonymizedVideoUuid?: string,
    public originalFileName?: string, // Csak akkor létezik ha újonnal lett feltöltve a videó
  ) {}
}

// Kiadott final video, hls formátumra konvertálva
export class ReleasedVideo {
  constructor(
    public releasedFinalVideoUuid: string, // Nem biztos, hogy létezik, hiszen a véglegesített videók is törölhetőek!
    public videoHlsUrl: string,
    public creationTimestamp: number,
    public isPermanentDeleted: boolean
  ) {}
}


export function videoSorter(video1:AnonymizedVideo|FinalVideo, video2:AnonymizedVideo|FinalVideo):number {
    return video2.creationTimestamp - video1.creationTimestamp;
}
