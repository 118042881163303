<div *ngFor = "let permissionGroup of extendedPermissionGroups">
    <div class = "group-title">
        {{ permissionGroup.displayedName }}
    </div>
    <div *ngFor = "let permission of permissionGroup.permissions">
        <mat-checkbox
            (change) = "handleCheckboxChange($event, permission)"
            [checked] = "isPermissionAssigned(permission)"
            [disabled] = "permission.isSpecial || disabledPermissions.includes(permission.name)"
        >
            {{ permission.displayedName }}
        </mat-checkbox>
    </div>
</div>
