<div class = "title">
    További útvonalak, amihez a kritikus pont hozzá van rendelve
</div>

<ng-container
    *ngIf = "data.criticalPointPositionInPaths.length > 0;
        then assignedToOtherPracticePaths;
        else notAssignedToOtherPracticePaths"
></ng-container>

<ng-template #assignedToOtherPracticePaths>
    <div class = "practice-path-list">
        <div *ngFor = "let criticalPointPositionInPath of data.criticalPointPositionInPaths" class = "practice-path-card">
            <div>
                <div class = "practice-path-name">
                    {{ criticalPointPositionInPath.practicePathName }}
                </div>
                <div class = "practice-path-uuid">
                    {{ criticalPointPositionInPath.practicePathUuid }}
                </div>
                <div class = "practice-path-position">
                    {{ criticalPointPositionInPath.criticalPointPosition }}. pozíción
                </div>
            </div>
            <div>
                <a [routerLink] = "getRouterLink(criticalPointPositionInPath)"  target = "_blank" >
                    <button
                        mat-flat-button
                        color = "primary"
                    >
                        <mat-icon> edit </mat-icon>
                        Megnyitás új lapon
                    </button>
                </a>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #notAssignedToOtherPracticePaths>
    <div class = "not-assigned-to-other-practice-paths">
        Az kritikus pont jelenleg nincs egyetlen másik útvonalhoz sem hozzárendelve.
    </div>
</ng-template>
