import { IconPath } from "./../../../../../../classes/model/practice-path";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PracticeIcon } from "src/app/classes/model/practice-icons";
import { PracticePathGlobalVideoEditorPageService } from "src/app/modules/practice-path-video-editor-page/services/practice-path-global-video-editor-page.service";
@Component({
  selector: "app-path-icon-card",
  templateUrl: "./path-icon-card.component.html",
  styleUrls: ["./path-icon-card.component.scss"],
})
export class PathIconCardComponent implements OnInit {
  @Input()
  pathIcon: IconPath;


  // Azonosítója, sorszám
  @Input()
  id: number;

  // Ha aktív akkor kijelöljük a kártyát
  // (módosítjuk a hátterét)
  @Input()
  isActive: boolean = false;

  @Output()
  onTapIcon = new EventEmitter<void>();


  @Output()
  onMouseEnter = new EventEmitter<void>();

  @Output()
  onMouseLeave = new EventEmitter<void>();

  @Output()
  onTapDelete = new EventEmitter<void>();

  @Output()
  onTapEdit = new EventEmitter<void>();

  // timedIcon.practiceIconUuid alapján
  resolvedPracticeIcon: PracticeIcon | undefined = undefined;

  constructor(
    private globalPracticePathEditorService: PracticePathGlobalVideoEditorPageService
  ) {}

  ngOnInit(): void {
    this.resolveIcon();
  }


  onTapJump(){
    this.onTapIcon.next();
  }

  private resolveIcon() {
    this.resolvedPracticeIcon =
      this.globalPracticePathEditorService.practiceIcons.find(
        (pi) => pi.uuid == this.pathIcon.practiceIconUuid
      );
  }
}
