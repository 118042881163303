import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminRole } from 'src/app/classes/model/admin-user';
import { PermissionString } from 'src/app/classes/model/permissions';
import { AdminRoleService, RemovedPermissionAffect } from 'src/app/services/admin-roles.service';

@Component({
  selector: 'app-admin-role-editor-dialog',
  templateUrl: './admin-role-editor-dialog.component.html',
  styleUrls: ['./admin-role-editor-dialog.component.scss']
})
export class AdminRoleEditorDialogComponent implements OnInit {

  nameFC:FormControl;
  displayedNameFC:FormControl;
  descriptionFC:FormControl;
  formGroup:FormGroup;
  assignedPermissions:Array<PermissionString> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{ dialogMode:"add"|"edit", adminRole?:AdminRole },
    private adminRoleService:AdminRoleService,
    private snackBarService:MatSnackBar,
    private dialogRef:MatDialogRef<AdminRoleEditorDialogComponent>
  ) { }

  ngOnInit(): void {
    this.nameFC = new FormControl(this.data.adminRole?.name ?? "", [ Validators.required ]);
    this.displayedNameFC = new FormControl(this.data.adminRole?.displayedName ?? "", [ Validators.required ]);
    this.descriptionFC = new FormControl(this.data.adminRole?.description ?? "");

    if(this.data.dialogMode === "edit") {
      this.nameFC.disable();
    }

    if(this.data.adminRole?.basePermissions) {
      this.assignedPermissions = this.data.adminRole.basePermissions.slice();
    }

    this.formGroup = new FormGroup({
      nameFC: this.nameFC,
      displayedNameFC: this.displayedNameFC,
      descriptionFC: this.descriptionFC
    });
  }

  public async handleSaveButtonClick():Promise<void> {
    const adminRole:AdminRole = {
      name: this.nameFC.value,
      displayedName: this.displayedNameFC.value,
      basePermissions: this.assignedPermissions
    };

    if(this.descriptionFC.value) {
      adminRole.description = this.descriptionFC.value;
    }
    
    if(this.data.dialogMode === "add") {
      try {
        await this.adminRoleService.addAdminRole(adminRole);
        this.dialogRef.close(adminRole);
        this.snackBarService.open("Sikeres admin szerepkör létrehozás", "Bezár", { duration: 3000, panelClass: "mat-snackbar-succcess" });
      } catch(error:any) {}
    } else {
      try {
        const removedPermissions:Array<PermissionString> = this.data.adminRole.basePermissions.filter(
          permission => !this.assignedPermissions.includes(permission)
        );

        let removedPermissionAffects:Array<RemovedPermissionAffect>|null = [];
        if(removedPermissions.length > 0) {
          removedPermissionAffects = await this.adminRoleService.openConfirmAdminRoleUpdatingDialog(
            "Jogosultságok törlése a szerepkörből",
            "A bepipált adminok ELVESZTIK a jogosultságukat a szerepkör megváltoztatásával.",
            this.data.adminRole,
            removedPermissions
          );
          if(removedPermissionAffects === null) {
            return;
          }
        }

        await this.adminRoleService.modifyAdminRole(adminRole, removedPermissionAffects);
        this.dialogRef.close(adminRole);
        this.snackBarService.open("Sikeres jogosultság csoport módosítás", "Bezár", { duration: 3000, panelClass: "mat-snackbar-succcess" });
      } catch(error:any) {}
    }
  }

}
