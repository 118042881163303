import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QuestionGroup } from 'src/app/classes/model/question-groups';
import { QuestionGroupService } from 'src/app/services/questiongroup.service';
import { QuestiongroupEditDialogComponent } from './questiongroup-edit-dialog/questiongroup-edit-dialog.component';
import { DialogMode } from 'src/app/classes/misc';
import { CategoryService } from 'src/app/services/category.service';
import { Category } from 'src/app/classes/model/category';

/**
 * @description The main component for the question groups view. In the init state, the component fetch all question groups from the server.
*/
@Component({
  selector: 'app-questiongroups',
  templateUrl: './questiongroups.component.html',
  styleUrls: ['./questiongroups.component.scss']
})
export class QuestiongroupsComponent implements OnInit {
  questionGroups:ReadonlyArray<Readonly<QuestionGroup>>;
  categories:ReadonlyArray<Category>;
  isContentLoading:boolean;

  constructor(public dialog:MatDialog, private questionGroupService:QuestionGroupService, private categoryService:CategoryService) { }

  ngOnInit(): void {
    this.questionGroups = this.questionGroupService.getQuestionGroupsArrayRef();
    this.categories = this.categoryService.getCategoriesArrayRef();
  }

  /**
   * Opens a dialog to add a new question group to the questopn groups.
   * 
   * @return {void} nothing
  */
  openAddQuestionGroupDialog():void {
    this.dialog.open(QuestiongroupEditDialogComponent, {
      data: {
        dialogMode: DialogMode.Add,
        questionGroup: null,
        categories: this.categories
      },
      disableClose: true
    });
  }

  public async handleReloadButtonClick():Promise<void> {
    try {
      await this.categoryService.loadDataIntoCache();
      await this.questionGroupService.loadDataIntoCache();
    } catch(error:any) {}
  }

}
