import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotifierService } from 'src/app/services/common/notifier-service.service';
import { BehaviorSubject } from 'rxjs';
import { PracticePath, PracticePathBaseInformations, PracticePathNarrationDuringRecording } from 'src/app/classes/model/practice-path';
import { ProgressDialogComponent } from 'src/app/modules/shared-module/components/progress-dialog/progress-dialog.component';
import { PracticePathService } from 'src/app/services/practice-path.service';
import { NewNarrationDuringRecordingItemComponent } from './new-narration-during-recording-item/new-narration-during-recording-item.component';

@Component({
  selector: 'app-narrations-during-recording-upload-tab',
  templateUrl: './narrations-during-recording-upload-tab.component.html',
  styleUrls: ['./narrations-during-recording-upload-tab.component.scss']
})
export class NarrationsDuringRecordingUploadTabComponent implements OnInit {
  @Input() practicePath: PracticePath | PracticePathBaseInformations;

  @ViewChild("fileInput", { static: true }) fileInputElement: ElementRef<HTMLInputElement>;

  @ViewChildren(NewNarrationDuringRecordingItemComponent)
  newNarrationDuringRecordingItemComponents: QueryList<NewNarrationDuringRecordingItemComponent>;

  filesToUpload: Array<File> = [];

  private readonly maxNumberOfFilesInOneUpload: number = 10;

  @Output() narrationsUploaded: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private practicePathService: PracticePathService,
    private notifierService: NotifierService,
    private dialogService: MatDialog
  ) { }

  ngOnInit(): void {
    // Add the change event handler to the file input element
    this.fileInputElement.nativeElement.addEventListener("change", this.onFilesInputChange)
  }


  private onFilesInputChange = () => {
    // Get the files from the input element
    const files: FileList = this.fileInputElement.nativeElement.files;

    // Show a warning message if the user wants to upload more files than the limit
    if (files.length > this.maxNumberOfFilesInOneUpload - this.filesToUpload.length) {
      this.notifierService.notify("warning", `Egyszerre maximum ${this.maxNumberOfFilesInOneUpload} fájl tölthető fel.`);
    }

    // Calculate the maximum remaining uploadable files
    const maxFileIndex: number = Math.min(files.length, this.maxNumberOfFilesInOneUpload - this.filesToUpload.length) - 1;

    // Add the files to the pending list
    for (let index: number = 0; index <= maxFileIndex; ++index) {
      this.filesToUpload.push(files.item(index));
    }

    // Clear fileInputElement value, reset to empty
    this.fileInputElement.nativeElement.value = "";
  }

  /**
   * Handles the add files button click. It clicks the hidden file input element in the DOM.
   */
  protected onAddFilesButtonClick(): void {
    // Dispatch a click on the file input element
    this.fileInputElement.nativeElement.click();
  }

  /**
   * Handles the upload button click. It uploads the pending files with their given titles with an API service,
   * and updates the (local) practice path on success.
   */
  protected async onUploadButtonClick(): Promise<void> {
    // Get the titles for the file names
    const titlesOfFiles: Array<{ fileName: string, title?: string }> = this.getTitlesForFilesFromChildComponents();

    // Create the result array
    let newNarrationsDuringRecording: Array<PracticePathNarrationDuringRecording> = [];

    // Create the progress and the text subject for the upload
    const progressSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    const textSubject: BehaviorSubject<string> = new BehaviorSubject<string>("Fájlok feltöltése a szerverre...");

    // Create the callback method for the upload progress
    const onUploadProgress: (loaded: number, total?: number) => void = (loaded: number, total?: number) => {
      const progress: number = total ? (loaded / total) : 0;
      progressSubject.next(progress);

      // If the upload is at 100%, we can assume that the saving to the S3 is happening
      if (progress === 1) {
        textSubject.next("Fájlok mentése S3-ba...")
      }
    };

    // Open the progress dialog
    const uploadProgressDialogRef = ProgressDialogComponent.open(this.dialogService, {
      progressSubject: progressSubject,
      textSubject: textSubject
    });

    try {
      // Upload the files to the practice path
      newNarrationsDuringRecording = await this.practicePathService.uploadNarrationsDuringRecordingToPracticePath(
        this.practicePath.uuid,
        titlesOfFiles,
        this.filesToUpload,
        onUploadProgress
      );
    } catch (error: any) {
      // If an error happened, log the error (the service will open a notification for now)
      console.error(error);
      return;
    } finally {
      // Close the upload progress dialog
      uploadProgressDialogRef.close();
    }

    // On success we push the uploaded narrations to the end of the array
    this.practicePath.audio.narrationsDuringRecording.pushArray(newNarrationsDuringRecording);
    // Set the peding files to upload to empty array
    this.filesToUpload = [];
    // Show a notification about the successful upload
    this.notifierService.notify("success", "Narráció(k) sikeresen hozzáadva az útvonalhoz.");
    // Emit an event for the parent that the upload is happened
    this.narrationsUploaded.emit();
  }

  /**
   * Gets the given titles for the narrations from the child components.
   *
   * @returns the titles for the files
   */
  private getTitlesForFilesFromChildComponents(): Array<{ fileName: string, title?: string }> {
    const result: Array<{ fileName: string, title?: string }> = [];

    // Iterate over the child components
    for (const newNarrationDuringRecordingItemComponent of this.newNarrationDuringRecordingItemComponents) {
      // Get the values from the child components
      const componentStateValues: { file: File, title?: string } = newNarrationDuringRecordingItemComponent.getComponentStateValues();
      // Push them into the result array
      result.push({
        fileName: componentStateValues.file.name,
        title: componentStateValues.title
      });
    }

    // Return the result
    return result;
  }

  /**
   * Handles the delete button click event from the child components.
   *
   * @param index the removed pendig file's index
   */
  protected onChildDeleteItemButtonClick(index: number): void {
    // Remove the file from the pendig files array with the given index
    this.filesToUpload.splice(index, 1);
  }
}



