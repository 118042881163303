import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PermissionGroup } from 'src/app/classes/model/permissions';
import { PermissionGroupsService } from 'src/app/services/permission-groups.service';

@Component({
  selector: 'app-permission-group-editor-dialog',
  templateUrl: './permission-group-editor-dialog.component.html',
  styleUrls: ['./permission-group-editor-dialog.component.scss']
})
export class PermissionGroupEditorDialogComponent implements OnInit {

  nameFC:FormControl;
  displayedNameFC:FormControl;
  descriptionFC:FormControl;
  formGroup:FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{ dialogMode:"add"|"edit", permissionGroup?:PermissionGroup },
    private permissionGroupsService:PermissionGroupsService,
    private snackBarService:MatSnackBar,
    private dialogRef:MatDialogRef<PermissionGroupEditorDialogComponent>
  ) { }

  ngOnInit(): void {
    this.nameFC = new FormControl(this.data.permissionGroup?.name ?? "", [ Validators.required ]);
    this.displayedNameFC = new FormControl(this.data.permissionGroup?.displayedName ?? "", [ Validators.required ]);
    this.descriptionFC = new FormControl(this.data.permissionGroup?.description ?? "");

    if(this.data.dialogMode === "edit") {
      this.nameFC.disable();
    }

    this.formGroup = new FormGroup({
      nameFC: this.nameFC,
      displayedNameFC: this.displayedNameFC,
      descriptionFC: this.descriptionFC
    });
  }

  public async handleSaveButtonClick():Promise<void> {
    const permissionGroup:PermissionGroup = {
      name: this.nameFC.value,
      displayedName: this.displayedNameFC.value
    };

    if(this.descriptionFC.value) {
      permissionGroup.description = this.descriptionFC.value;
    }
    
    if(this.data.dialogMode === "add") {
      try {
        await this.permissionGroupsService.addPermissionGroup(permissionGroup);
        this.dialogRef.close(permissionGroup);
        this.snackBarService.open("Sikeres jogosultság csoport létrehozás", "Bezár", { duration: 3000, panelClass: "mat-snackbar-succcess" });
      } catch(error:any) {}
    } else {
      try {
        await this.permissionGroupsService.modifyPermissionGroup(permissionGroup);
        this.dialogRef.close(permissionGroup);
        this.snackBarService.open("Sikeres jogosultság csoport módosítás", "Bezár", { duration: 3000, panelClass: "mat-snackbar-succcess" });
      } catch(error:any) {}
    }
  }

}
