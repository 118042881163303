import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren, QueryList, OnDestroy, ViewContainerRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { Question } from 'src/app/classes/model/question';
import { QuestionService } from 'src/app/services/question.service';
import { QuestionFilterService, FilterByPresence } from './services/question-filter.service';
import { DialogMode } from 'src/app/classes/misc';
import { QuestionEditDialogComponent } from './question-edit-dialog/question-edit-dialog.component'
import { QuestionGroupService } from 'src/app/services/questiongroup.service';
import { QuestionGroup } from 'src/app/classes/model/question-groups';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CategoryService } from 'src/app/services/category.service';
import { Category } from 'src/app/classes/model/category';
import { QuestionComponent } from './question/question.component';
import { Subscription } from 'rxjs';
import { matchingPropertyPredicate } from 'src/app/functions/misc';

/**
 * @description This component represents a question card in the questions view.
*/
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  providers: [
    QuestionFilterService
  ]
})
export class QuestionsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("questionPaginator") paginator:MatPaginator;
  @ViewChildren(QuestionComponent) childrenQuestionsRefs:QueryList<QuestionComponent>;

  FilterByPresence = FilterByPresence;
  uuidFilterWildCard:string;
  uuidFilterEmptyField:string;
  positionFilterWildcard:number;
  filterQuestionByUuid:string;

  /** Array reference for the question groups. */
  questions:ReadonlyArray<Readonly<Question>>;
  questionGroups:ReadonlyArray<Readonly<QuestionGroup>>;
  categories:ReadonlyArray<Readonly<Category>>;
  
  // FormGroups and FormControls
  questionFilterFormGroup:FormGroup;
  /** Formcontrol for the seach filter input.  */
  questionSearchFilterFormControl:FormControl;
  /** Formcontrol for the question groups (filter) select . */
  questionFilterQuestiongroupSelect:FormControl;
  /** Formcontrol for the categories (filter) select . */
  questionFilterCategorySelect:FormControl;

  questionFilterCategoryPositionSelect:FormControl;


  /** Formcontrol for the explanation filter radio buttons. */
  questionFilterExplanationRadio:FormControl;
  questionFilterMediaRadio:FormControl;
  questionFilterIllustrationRadio:FormControl;
  questionFilterIsUpdatedRadio:FormControl;

  /** Paginator config */
  defaultPageSize:number = 10;
  pageSizeOptions:number[] = [10, 25, 50, 100];

  /** Subscriptions */
  subscriptions:Array<Subscription>;
  
  constructor(
    public dialog:MatDialog,
    private questionService:QuestionService,
    private questionGroupService:QuestionGroupService,
    private location:Location,
    private activatedRoute:ActivatedRoute,
    private categoryService:CategoryService,
    private questionFilterService:QuestionFilterService,
    private viewContainerRef:ViewContainerRef
  ) {
      
    this.categories = new Array<Category>();
    this.questionGroups = new Array<QuestionGroup>();
    this.subscriptions = new Array<Subscription>();
  }

  ngOnInit(): void {
    const self = this;

    this.questionFilterService.resetDisplayedQuestionIndexesArray();
    
    // Init the members
    this.uuidFilterWildCard = QuestionFilterService.UuidFilterWildcard;
    this.uuidFilterEmptyField = QuestionFilterService.UuidFilterEmptyField;
    this.positionFilterWildcard = QuestionFilterService.PositionFilterWildCard;
    // Init the filter service's members
    this.questionFilterService.initFilters();
    
    this.questions = this.questionService.getQuestionArrayRef();
    this.questionFilterService.setQuestionsArrayRef(this.questions);
    this.questionFilterService.setFilteredStartAndEndIndex(0, this.defaultPageSize);
    
    
    this.questionGroups = this.questionGroupService.getQuestionGroupsArrayRef();
    this.categories = this.categoryService.getCategoriesArrayRef();
    
    // Process the data in the url
    const routeSubscription:Subscription = this.activatedRoute.queryParams.subscribe(
      parameters => {
        if(parameters["question"]) {
          this.filterQuestionByUuid = parameters["question"];
        } else {
          this.filterQuestionByUuid = "";
        }

        if(parameters["category"]) {
          this.questionFilterService.filteredCategoryUuid = parameters["category"];
          if(parameters["position"]) {
            this.questionFilterService.filteredCategoryPosition = Number.parseInt(parameters["position"]);
          }
        }

        if(parameters["questiongroup"]) {
          this.questionFilterService.filteredQuestionGroupUuid = parameters["questiongroup"];
        }

        this.questionFilterService.filteredQuestionText = this.filterQuestionByUuid;
        this.questionFilterService.updateDisplayedQuestions();
      }
    );
    this.subscriptions.push(routeSubscription);

    this.questionFilterCategorySelect = new FormControl(this.questionFilterService.filteredCategoryUuid);
    const categorySelectSubscription:Subscription = this.questionFilterCategorySelect.valueChanges.subscribe(
      function(selectValue:string):void {
        self.questionFilterService.filteredCategoryUuid = selectValue;
        if(self.questionFilterService.isValidCategoryFiltered()) {
          self.questionFilterCategoryPositionSelect.enable();
        } else {
          self.questionFilterCategoryPositionSelect.setValue(QuestionFilterService.PositionFilterWildCard);
          self.questionFilterCategoryPositionSelect.disable();
        }
        self.location.go(self.generateLocation());
        self.resetPaginator();
        self.questionFilterService.updateDisplayedQuestions();
      }
    );
    this.subscriptions.push(categorySelectSubscription);
    
    this.questionFilterCategoryPositionSelect = new FormControl(this.questionFilterService.filteredCategoryPosition);
    if(!this.questionFilterService.isValidCategoryFiltered()) {
      this.questionFilterCategoryPositionSelect.disable();
    }
    const categoryPositionSelectSubscription:Subscription = this.questionFilterCategoryPositionSelect.valueChanges.subscribe(
      function(selectValue:number):void {
        self.questionFilterService.filteredCategoryPosition = selectValue;
        self.location.go(self.generateLocation());
        self.resetPaginator();
        self.questionFilterService.updateDisplayedQuestions();
      }
    );
    this.subscriptions.push(categoryPositionSelectSubscription);
    
    // Changing the select's value
    this.questionFilterQuestiongroupSelect = new FormControl(this.questionFilterService.filteredQuestionGroupUuid);
    const questionGroupSelectSubscription:Subscription = this.questionFilterQuestiongroupSelect.valueChanges.subscribe(
      function(selectValue:string):void {
        self.questionFilterService.filteredQuestionGroupUuid = selectValue;
        self.location.go(self.generateLocation());
        self.resetPaginator();
        self.questionFilterService.updateDisplayedQuestions();
      }
    );
    this.subscriptions.push(questionGroupSelectSubscription);
      
    // Changing the search input value
    this.questionSearchFilterFormControl = new FormControl();
    const questionTextSearchSubscription:Subscription = this.questionSearchFilterFormControl.valueChanges.subscribe(
      function(searchValue:string):void {
        self.questionFilterService.filteredQuestionText = searchValue;
        self.location.go(self.generateLocation());
        self.resetPaginator();
        self.questionFilterService.updateDisplayedQuestions();
      }
    );
    this.subscriptions.push(questionTextSearchSubscription);
        
    this.questionFilterMediaRadio = new FormControl(FilterByPresence.All);
    const questionMediaRadioSubscription:Subscription = this.questionFilterMediaRadio.valueChanges.subscribe(
    function(radioValue:FilterByPresence):void {
        self.questionFilterService.filterByMediaPresence = radioValue;
        self.location.go(self.generateLocation());
        self.resetPaginator();
        self.questionFilterService.updateDisplayedQuestions();
      }
    );
    this.subscriptions.push(questionMediaRadioSubscription);

    this.questionFilterIllustrationRadio = new FormControl(FilterByPresence.All);
    const questionIllustrationRadioSubscription:Subscription = this.questionFilterIllustrationRadio.valueChanges.subscribe(
      function(radioValue:FilterByPresence):void {
        self.questionFilterService.filterByIllustrationPresence = radioValue;
        self.location.go(self.generateLocation());
        self.resetPaginator();
        self.questionFilterService.updateDisplayedQuestions();
      }
    );
    this.subscriptions.push(questionIllustrationRadioSubscription);

    this.questionFilterExplanationRadio = new FormControl(FilterByPresence.All);
    const questionExplanationRadioSubscription:Subscription = this.questionFilterExplanationRadio.valueChanges.subscribe(
      function(radioValue:FilterByPresence):void {
        self.questionFilterService.filterByExplanationPresence = radioValue;
        self.location.go(self.generateLocation());
        self.resetPaginator();
        self.questionFilterService.updateDisplayedQuestions();
      }
    );
    this.subscriptions.push(questionExplanationRadioSubscription);

    this.questionFilterIsUpdatedRadio = new FormControl(FilterByPresence.All);
    const questionisUpdatedRadioSubscription:Subscription = this.questionFilterIsUpdatedRadio.valueChanges.subscribe(
      function(radioValue:FilterByPresence):void {
        self.questionFilterService.filterByIsUpdated = radioValue;
        self.location.go(self.generateLocation());
        self.resetPaginator();
        self.questionFilterService.updateDisplayedQuestions();
      }
    );
    this.subscriptions.push(questionisUpdatedRadioSubscription);

    this.questionFilterFormGroup = new FormGroup({
      questionFilterCategorySelect: this.questionFilterCategorySelect,
      questionFilterCategoryPositionSelect: this.questionFilterCategoryPositionSelect,
      questionFilterQuestiongroupSelect: this.questionFilterQuestiongroupSelect,
      questionSearchFilterFormControl: this.questionSearchFilterFormControl,
      questionFilterMediaRadio: this.questionFilterMediaRadio,
      questionFilterIllustrationRadio: this.questionFilterIllustrationRadio,
      questionFilterExplanationRadio: this.questionFilterExplanationRadio,
      questionFilterIsUpdatedRadio: this.questionFilterIsUpdatedRadio
    });
    
  }

  ngAfterViewInit():void {
    const self = this;
    const paginatorSubscription:Subscription = this.paginator.page.subscribe(
      function(pageEvent:PageEvent) {
        self.questionFilterService.setFilteredStartAndEndIndex(pageEvent.pageIndex * pageEvent.pageSize, (pageEvent.pageIndex + 1) * pageEvent.pageSize);
        self.questionFilterService.updateDisplayedQuestions();
      }
    );
    this.subscriptions.push(paginatorSubscription);
  }

  public ngOnDestroy():void {
    for(const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }

    this.questionFilterService.clearCachedData();
  }
  
  /**
   * Opens a dialog to add a new question to the questions.
   * 
   * @return {void} nothing
  */
  showAddQuestionDialog():void {
    this.dialog.open(QuestionEditDialogComponent, {
      data: {
        dialogMode: DialogMode.Add,
        question: null,
        categories: this.categories,
        questionGroups: this.questionGroups
      },
      disableClose: true,
      viewContainerRef: this.viewContainerRef // For inject a provided service
    });
  }

  getDisplayedQuestions():ReadonlyArray<number> {
    return this.questionFilterService.getDisplayedQuestionIndexes();
  }

  getNumberOfFilteredQuestions():number {
    return this.questionFilterService.numberOfFilteredQuestions;
  }

  getQuestionsArray(): ReadonlyArray<Readonly<Question>> {
    return this.questions;
  }

  resetPaginator():void {
    this.questionFilterService.setFilteredStartAndEndIndex(0, this.paginator.pageSize);
    this.paginator.firstPage();
  }

  generateLocation():string {
    let location:string = "/dashboard/actual-questionbank/questions";
    let isQuestionMarkAppendend:boolean = false;
    if(this.questionFilterService.filteredCategoryUuid !== QuestionFilterService.UuidFilterWildcard) {
      location += ("?category=" + this.questionFilterService.filteredCategoryUuid);
      isQuestionMarkAppendend = true;
      if(this.questionFilterService.filteredCategoryPosition !== QuestionFilterService.PositionFilterWildCard) {
        location += "&position=" + this.questionFilterService.filteredCategoryPosition;
      } 
    }
    if(this.questionFilterService.filteredQuestionGroupUuid !== QuestionFilterService.UuidFilterWildcard) {
      if(!isQuestionMarkAppendend) {
        location += "?";
        isQuestionMarkAppendend = true;
      } else {
        location += "&";
      }
      location += ("questiongroup=" + this.questionFilterService.filteredQuestionGroupUuid);
    }

    return location;
  }

  getCategoryPositionsAsArray(categoryUuid:string):number[] {
    let result = new Array<number>();
    
    if(categoryUuid === QuestionFilterService.UuidFilterWildcard || categoryUuid === QuestionFilterService.UuidFilterEmptyField) {
      return result;
    }

    let category:Category = this.categories.find(matchingPropertyPredicate( "uuid",categoryUuid));
    if(category !== null) {
      for(let i = 1; i <= category.numberOfQuestionsOfFullTest; ++i) {
        result.push(i);
      }
    }
    

    return result;
  }

  filterInactiveQuestions():void {
    this.questionFilterCategorySelect.setValue(QuestionFilterService.UuidFilterEmptyField);
    this.questionFilterCategoryPositionSelect.setValue(QuestionFilterService.PositionFilterWildCard);
    this.questionFilterQuestiongroupSelect.setValue(QuestionFilterService.UuidFilterEmptyField);
  }

  openAllQuestions():void {
    for(let questionComponentRef of this.childrenQuestionsRefs) {
      if(!questionComponentRef.isExpanded) {
        questionComponentRef.toogleCardExpansion();
      }
    }
  }

  getQuestionGroups():ReadonlyArray<Readonly<QuestionGroup>> {
    let result:Array<Readonly<QuestionGroup>> = new Array<Readonly<QuestionGroup>>();
    
    for(let questionGroup of this.questionGroups) {
      if(this.questionFilterCategorySelect.value === this.uuidFilterWildCard) {
        result.push(questionGroup);
      } else if(this.questionFilterCategorySelect.value === this.uuidFilterEmptyField && questionGroup.categories.length === 0) {
        result.push(questionGroup);
      } else if(questionGroup.categories.includes(this.questionFilterCategorySelect.value)) {
        result.push(questionGroup);
      }
    }

    return result;
  }

  public async handleReloadButtonClick():Promise<void> {
    try {
      await this.categoryService.loadDataIntoCache();
      await this.questionGroupService.loadDataIntoCache();
      await this.questionService.loadDataIntoCache();
      this.questionFilterService.updateDisplayedQuestions();
    } catch(error:any) {}
  }
}
