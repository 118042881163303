import { GeoTaggedFrame } from './gopro-metadata-extractor';
export class GoproVideoMetadata{
  constructor(
    public geoTaggedFrames: GeoTaggedFrame[]
  ){}

}

export class MetadataJsonReader{
  // Kivételt dob, ha nem olvasható be a fájl, vagy ha nem megfelelő a struktúra
  async read(file: File) : Promise<GoproVideoMetadata>{
    const buffer = await file.arrayBuffer();
    const uint8Array = new Uint8Array(buffer);

    const jsonString  = uint8Array.reduce((acc, i) => acc += String.fromCharCode.apply(null, [i]), '');


    const json = JSON.parse(jsonString);

    if(json["geoTaggedFrames"] == null){
      throw Error("Invalid JSON structure");
    }

    return {geoTaggedFrames:json["geoTaggedFrames"]};
  }
}
