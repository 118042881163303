import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { EnvironmentLoaderService } from './common/environments/environment-loader.service';
import { SettingsService } from './common/settings.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitializationService {

  constructor(
    private settingsService:SettingsService,
    private environmentLoaderService:EnvironmentLoaderService,
    private matPaginatorIntl:MatPaginatorIntl,
  ) { }

  /**
   * Initializes the application's components service. It loads any configurations which is used
   * through the entire application such as the environment.
   */
  public initialize():void {
    // Load the saved settings to the memory from the local storage
    this.settingsService.loadSettingsFromLocalStorage();

    // Load the initial environment
    this.loadEnvironment();

    // Set the paginators global config
    this.loadMatPaginatorConfig();
  }

  /**
   * Loads the (initial) environment through the `environmentLoaderService`.
   */
  private loadEnvironment():void {
    this.environmentLoaderService.load();
  }

  /**
   * Loads the paginators config. It sets its status informations string to hungarian.
   */
  private loadMatPaginatorConfig():void {
    this.matPaginatorIntl.itemsPerPageLabel = "Elemek száma oldalaknént:"
    this.matPaginatorIntl.getRangeLabel = function(page: number, pageSize: number, length: number):string {
      let tens:string[] = [ "ó", "ő", "ó", "ó", "ő", "ő", "ó", "ő", "ó", "ő" ];
      let ones:string[] = [ "ő", "ő", "ó", "ő", "ő", "ó", "ő", "ó", "ő" ];
      let suffix:string = "";

      if(length > 0 && length % 1000 === 0) {
        suffix = "-ből";
      } else if(length > 0 && length % 100 === 0) {
        suffix = "-ból";
      } else if(length % 10 === 0){
        suffix = "-b" + tens[(length / 10) % 10] + "l";
      } else {
        suffix = "-b" + ones[(length % 10) - 1] + "l"; 
      }

      if(length == 0 || pageSize == 0) {
        return "0 a " + length + suffix;
      } else {
        return (page * pageSize + 1) + " – " + (Math.min(length, (page + 1) * pageSize)) + " a " + length + suffix;
      }
    }
  }
}
