export class QuestionGroup {
    uuid:string;
    name:string;
    questions:string[];
    categories:string[];
    isHighlighted:boolean;
    thematicId:number;
}

export function questionGroupSorter(questionGroup1:QuestionGroup, questionGroup2:QuestionGroup) {
    return questionGroup1.name.localeCompare(questionGroup2.name);
}