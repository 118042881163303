import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CustomValidators } from 'src/app/classes/custom-validators';
import { AdminRole, AdminUser } from 'src/app/classes/model/admin-user';
import { PermissionString } from 'src/app/classes/model/permissions';
import { AdminUserService } from 'src/app/services/admin-user.service';
import { Permission } from 'src/app/classes/model/permissions';
import { NotifierService } from 'src/app/services/common/notifier-service.service';

@Component({
  selector: 'app-admin-user-editor-dialog',
  templateUrl: './admin-user-editor-dialog.component.html',
  styleUrls: ['./admin-user-editor-dialog.component.scss']
})
export class AdminUserEditorDialogComponent implements OnInit, OnDestroy {

  nameFC:FormControl;
  emailFC:FormControl;
  roleFC:FormControl;
  formGroup:FormGroup;
  assignedPermissions:Array<PermissionString> = [];
  disabledPermissions:Array<PermissionString> = [];

  roleFcValueChagesSubscription:Subscription;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{
      dialogMode:"add"|"edit",
      adminUser?:AdminUser,
      adminRoles:ReadonlyArray<AdminRole>,
      permissions:ReadonlyArray<Permission>
    },
    private adminUserService:AdminUserService,
    private dialogRef:MatDialogRef<AdminUserEditorDialogComponent>,
    private notifierService:NotifierService
  ) { }

  public ngOnInit(): void {
    const assignedAdminRoles:Array<AdminRole> = this.data.adminRoles.filter(
      (adminRole:AdminRole) => {
        return this.data.adminUser?.roleNames.some(roleName => roleName === adminRole.name)
      }
    );

    this.disabledPermissions = this.getAllPermissionsOfRoles(assignedAdminRoles);

    this.nameFC = new FormControl(this.data.adminUser?.username ?? "", [ Validators.required ]);
    this.emailFC = new FormControl(this.data.adminUser?.email ?? "", [ Validators.required, CustomValidators.email ]);
    this.roleFC = new FormControl(assignedAdminRoles ?? []);

    if(this.data.dialogMode === "edit") {
      this.emailFC.disable();
    }

    this.roleFcValueChagesSubscription = this.roleFC.valueChanges.subscribe(
      (newAdminRoles:Array<AdminRole>) => {
        const permissions:Array<PermissionString> = this.getAllPermissionsOfRoles(newAdminRoles);
        permissions.pushArrayIfNotExists(this.getSpecialPermissionsOfAdmin());

        this.disabledPermissions.replaceArrayElements(permissions);

        permissions.pushArrayIfNotExists(this.data.adminUser?.permissions ?? []);
        this.assignedPermissions.replaceArrayElements(permissions);

      }
    );

    if(this.data.adminUser?.permissions) {
      this.assignedPermissions = this.data.adminUser.permissions.slice();
    }

    this.formGroup = new FormGroup({
      nameFC: this.nameFC,
      emailFC: this.emailFC,
      roleFC: this.roleFC
    });
  }

  public ngOnDestroy():void {
    this.roleFcValueChagesSubscription?.unsubscribe();
  }

  public async handleSaveButtonClick():Promise<void> {
    const adminUser:Partial<AdminUser> = {
      username: this.nameFC.value,
      roleNames: ((this.roleFC.value ?? []) as Array<AdminRole>).map(adminRole => adminRole.name),
      permissions: this.assignedPermissions
    };

    if(this.data.dialogMode === "add") {
      adminUser.email = this.emailFC.value;

      try {
        const registratedAdminUser:AdminUser = await this.adminUserService.registrateAdminUser(adminUser);
        this.dialogRef.close(registratedAdminUser);
        this.notifierService.notify("success", "Sikeres adminisztrátor létrehozás");
      } catch(error:any) {
        console.error(error);
        if(error.error?.error === "ADMIN_EMAIL_TAKEN") {
          this.notifierService.notify("error", "A megadott admin e-mail cím foglalt.");
        }
      }
    } else {
      adminUser.uuid = this.data.adminUser.uuid;

      try {
        const modifiedAdminUser:AdminUser = await this.adminUserService.modifyAdminUser(adminUser);
        this.dialogRef.close(modifiedAdminUser);
        this.notifierService.notify("success", "Sikeres adminisztrátor módosítás");
      } catch(error:any) {
        console.error(error);
      }
    }
  }

  public getAllPermissionsOfRoles(adminRoles:Array<AdminRole>):Array<PermissionString> {
    const permissions:Array<PermissionString> = [];
    for(const adminRole of adminRoles ?? []) {
      for(const permissionName of adminRole.basePermissions) {
        permissions.pushIfNotExists(permissionName);
      }
    }
    return permissions;
  }

  public getSpecialPermissionsOfAdmin():Array<PermissionString> {
    const specialPermissions:Array<PermissionString> = [];

    for(const permission of this.data.permissions) {
      if(permission.isSpecial && this.data.adminUser?.permissions.includes(permission.name)) {
        specialPermissions.push(permission.name);
      }
    }

    return specialPermissions;
  }

}
