import { SharedComponentsModule } from './../shared-module/shared-module.module';
import { AudioPlayerService } from './services/audio-player-service';
import { PathIconCardComponent } from './tabs/path-icons-tab/components/path-icon-card/path-icon-card.component';
import { TrackScrollDirective } from "./components/track-scroll-position-directive/track-scroll-position-directive";
import { CommonModule } from "@angular/common";
import { PracticePathVideoEditorRoutingModule } from "./practice-path-video-editor-page-routing.module";
import { NgModule } from "@angular/core";
import { AngularSplitModule } from "angular-split";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTabsModule } from "@angular/material/tabs";
import { GoogleMapsModule } from "@angular/google-maps";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule } from "@angular/forms";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDialogModule } from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { PracticePathVideoEditorPageComponent } from "./practice-path-video-editor-page.component";
import {
  GoogleMapExtendedComponent,
  MarkeredPolylineControllerToRadioPipe,
} from "./components/google-map-extended/google-max-extended.component";
import { DirectionalAngleEditComponent } from "./components/critical-point-editor-dialog/directional-angle-edit/directional-angle-edit.component";
import { FocusVideoOverlayComponent } from "./components/focus-video-overlay/focus-video-overlay.component";
import { MsToTimeStringPipe } from "./pipes/ms-to-time-pipe";
import { IconOverlayComponent } from "./components/icon-overlay/icon-overlay.component";
import { TextVideoOverlayComponent } from "./components/text-video-overlay/text-video-overlay.component";
import { RadioButtonsFloatMenuComponent } from "./components/google-map-extended/radio-buttons-float-menu/radio-buttons-float-menu.component";
import { CriticalPointsTabComponent } from "./tabs/critical-points-tab/critical-points-tab.component";
import { CriticalPointEditorDialogComponent } from "./components/critical-point-editor-dialog/critical-point-editor-dialog.component";
import { OtherInfoTabComponent } from "./tabs/other-info-tab/other-info-tab.component";
import { MatTableModule } from "@angular/material/table";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { CopyableTextComponent } from "./components/copyable-text/copyable-text.component";
import { FollowSingleMarkerControllerButtonComponent } from "./components/google-map-extended/follow-single-marker-controller-button/follow-single-marker-controller-button.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { TimedIconsTabComponent } from "./tabs/timed-icons-tab/timed-icons-tab.component";
import { IconFlowComponent } from "./tabs/timed-icons-tab/components/icon-flow/icon-flow.component";
import { TimedIconCardComponent } from "./tabs/timed-icons-tab/components/timed-icon-card/timed-icon-card.component";
import { TimedIconRecorderComponent } from "./tabs/timed-icons-tab/components/timed-icon-recorder/timed-icon-recorder.component";
import { PathIconsTabComponent } from "./tabs/path-icons-tab/path-icons-tab.component";
import { PathIconRecorderComponent } from "./tabs/path-icons-tab/components/path-icon-recorder/path-icon-recorder.component";
import { PlatformModule } from '@angular/cdk/platform';
import { OtherPracticePathsToCriticalPointAssignedToDialogComponent } from './components/critical-point-editor-dialog/other-practice-paths-to-critical-point-assigned-to-dialog/other-practice-paths-to-critical-point-assigned-to-dialog.component';
import { CommentsOnEntityModule } from '../comments-on-entity/comments-on-entity.module';

@NgModule({
  declarations: [
    MsToTimeStringPipe,
    PracticePathVideoEditorPageComponent,
    GoogleMapExtendedComponent,
    DirectionalAngleEditComponent,
    FocusVideoOverlayComponent,
    IconOverlayComponent,
    TextVideoOverlayComponent,
    RadioButtonsFloatMenuComponent,
    MarkeredPolylineControllerToRadioPipe,
    CriticalPointsTabComponent,
    CriticalPointEditorDialogComponent,
    OtherInfoTabComponent,
    CopyableTextComponent,
    FollowSingleMarkerControllerButtonComponent,
    TrackScrollDirective,
    TimedIconsTabComponent,
    IconFlowComponent,
    TimedIconCardComponent,
    TimedIconRecorderComponent,
    PathIconsTabComponent,
    TimedIconRecorderComponent,
    PathIconRecorderComponent,
    PathIconCardComponent,
    OtherPracticePathsToCriticalPointAssignedToDialogComponent
  ],
  imports: [
    SharedComponentsModule,
    PracticePathVideoEditorRoutingModule,
    MatFormFieldModule,
    ScrollingModule,
    MatCheckboxModule,
    AngularSplitModule,
    MatTooltipModule,
    ClipboardModule,
    MatTabsModule,
    CommonModule,
    MatDialogModule,
    MatTableModule,
    MatInputModule,
    PlatformModule,
    GoogleMapsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatButtonModule,
    FormsModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    CommentsOnEntityModule
  ],
  exports: [ PracticePathVideoEditorPageComponent, GoogleMapExtendedComponent ],
  providers: [ AudioPlayerService ],
})
export class PracticePathVideoEditPageModule {}
