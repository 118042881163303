import { PracticalModuleLog } from './practical-module-log.model';

export enum PracticalModuleLogsContentStateType {
  Loading,
  Error,
  Data
};

export enum PracticalModuleLogsContentErrorType {
  InvalidConfigurationError,
  PermissionMissing,
  InvalidEntityType,
  InvalidEntityKey,
  IvalidInterval,
  OtherError
}

export abstract class PracticalModuleLogsContentState {
  stateType:PracticalModuleLogsContentStateType;

  constructor(stateType:PracticalModuleLogsContentStateType) {
    this.stateType = stateType;
  }
}
  
export class PracticalModuleLogContentLoadingState extends PracticalModuleLogsContentState {
  constructor() {
    super(PracticalModuleLogsContentStateType.Loading);
  }
}
  
export class PracticalModuleLogsContentErrorState extends PracticalModuleLogsContentState {
  errorType:PracticalModuleLogsContentErrorType;
  error:any;
  
  constructor(errorType:PracticalModuleLogsContentErrorType, error?:any) {
    super(PracticalModuleLogsContentStateType.Error);
    this.errorType = errorType;
    this.error = error;
  }
}

export class PracticalModuleLogsContentDataState extends PracticalModuleLogsContentState {
  data:Array<PracticalModuleLog>;
  
  constructor(data:Array<PracticalModuleLog>) {
    super(PracticalModuleLogsContentStateType.Data);
    this.data = data;
  }
}
