import { Type } from './../../../functions/misc';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatestWith, map, Observable, Subscription } from 'rxjs';
import { PracticalModuleLogEntityEvent, PracticeModuleLogsFiltering } from '../components/practice-module-logs-filter/practice-module-logs-filter.component';
import { CriticalPointAssignmentLog, CriticalPointLog, GeneralPracticalModuleLog, getEventNameOfPracticalModuleLog, getRealTypeOfPracticalModuleLog, LoggedPracticalModuleEntityType, PracticalModuleLog, PracticePathLog, RelatedEntityTypeToPracticalModuleLog } from '../models/practical-module-log.model';
import { PracticalModuleLogsApiService } from './practical-module-logs-api.service';
import { PracticalModuleLogsContentDataState, PracticalModuleLogsContentErrorState, PracticalModuleLogsContentErrorType, PracticalModuleLogContentLoadingState, PracticalModuleLogsContentState } from '../models/practical-module-log-list-state.model';
import { PracticePathCacherService } from 'src/app/services/cacher-services/practice-path-cacher.service';
import { CriticalPointCacherService } from 'src/app/services/cacher-services/critical-point-cacher.service';
import { CachedCriticalPointFields, CachedPracticePath } from '../models/cached-entities.model';
import { generalEventName } from '../models/practice-module-event-log-groups.model';
import { PaginationState } from '../components/practical-module-logs-content/practical-module-logs-content.component';
import { number } from 'echarts';

@Injectable()
export class PracticalModuleLogsDataService implements OnDestroy {
  private readonly fetchedPracticalModuleLogsSubject:BehaviorSubject<Array<PracticalModuleLog>|null>;
  private readonly filteredPracticalModuleLogs:Observable<Array<PracticalModuleLog>|null>;
  private readonly practicalModuleLogsFilteringSubject:BehaviorSubject<PracticeModuleLogsFiltering|null>;
  private readonly practicalModulePaginationStateSubject:BehaviorSubject<PaginationState|null>;
  
  private readonly practicalModueLogsContentStateSubject:BehaviorSubject<PracticalModuleLogsContentState>;

  private displayedLogsStreamSubscription:Subscription|null = null;

  private entityType:RelatedEntityTypeToPracticalModuleLog | "all";
  private entityKey:string;

  constructor(
    private practicalModuleLogsApiService:PracticalModuleLogsApiService,
    private practicePathCacherService:PracticePathCacherService,
    private criticalPointCacherService:CriticalPointCacherService
  ) {
    this.fetchedPracticalModuleLogsSubject = new BehaviorSubject<Array<PracticalModuleLog>|null>(null);
    this.practicalModuleLogsFilteringSubject = new BehaviorSubject<PracticeModuleLogsFiltering|null>(null);
    this.practicalModulePaginationStateSubject = new BehaviorSubject<PaginationState|null>(null);

    this.practicalModueLogsContentStateSubject = new BehaviorSubject<PracticalModuleLogsContentState>(
      new PracticalModuleLogContentLoadingState()
    );

    this.filteredPracticalModuleLogs = this.createFilteredPracticalModuleLogsStream();

    // Create a stream which updates the content stream with data via combining the filtered logs stream
    // and the pagination state stream
    this.createDisplayedLogsUpdaterStream();
  }

  public ngOnDestroy(): void {
    this.displayedLogsStreamSubscription?.unsubscribe();
  }

  /**
   * Initialize the logged entity type and key to use in the service.
   * 
   * @param entityType the type of the entity (including "all")
   * @param entityKey the key of the entity - required if the queried type is not "all"
   */
  public initialize(entityType:RelatedEntityTypeToPracticalModuleLog|"all", entityKey?:string):void {
    this.entityType = entityType;
    this.entityKey = entityKey;
  }

  /**
   * Observable for the content's state.
   */
  public get practicalModuleLogsContentState$():Observable<PracticalModuleLogsContentState> {
    return this.practicalModueLogsContentStateSubject.asObservable();
  }

 /**
  * Observable for the fetched logs.
  */
  public get fetchedLogs$():Observable<Array<PracticalModuleLog>|null> {
    return this.fetchedPracticalModuleLogsSubject.asObservable();
  }

  /**
   * Observable for the filtered logs (fetched logs with the filtering applied).
   */
  public get filteredLogs$():Observable<Array<PracticalModuleLog>|null> {
    return this.filteredPracticalModuleLogs;
  }

  /**
   * Gets the setted entity type as a string. If the entity type is undefined "undefined" returned.
   * 
   * @returns the entity type as string
   */
  public getEntityTypeAsString():RelatedEntityTypeToPracticalModuleLog| "all" | "undefined" {
    return this.entityType !== undefined ? this.entityType : "undefined";
  }

  /**
   * Gets the setted entity key as a string. If the entity key is undefined "undefined" returned.
   * 
   * @returns the entity key as string
   */
  public getEntityKeyAsString():string {
    return this.entityKey !== undefined ? this.entityKey : "undefined";
  }

  /**
   * Creates the filtered practical module logs stream via combining the fetched practical module logs
   * and the filtering streams.
   * 
   * @returns the newly created stream
   */
  private createFilteredPracticalModuleLogsStream():Observable<Array<PracticalModuleLog>> {
    // Combine the fetched logs stream with the filtering state stream => filtered logs stream
    return this.fetchedPracticalModuleLogsSubject.pipe(
      combineLatestWith(this.practicalModuleLogsFilteringSubject),
      map<[Array<PracticalModuleLog>, PracticeModuleLogsFiltering], Array<PracticalModuleLog>>(
        ([fetchedPracticalModuleLogs, practicalModuleLogsFiltering]) => {
          // If the fetched logs or the filtering is null
          if(fetchedPracticalModuleLogs == null || practicalModuleLogsFiltering == null) {
            // Push null into the stream
            return null;
          }

          // Return the filtered logs
          return this.filterLogs(fetchedPracticalModuleLogs, practicalModuleLogsFiltering);
        }
      )
    );
  }

  /**
   * Creates a stream to update the displayed logs. It combines the filtered logs stream with
   * the pagination state stream, and creates a new data state for the content stream. If any
   * of the streams is null, no data pushed into the content stream.
   */
  private createDisplayedLogsUpdaterStream():void {
    // If the stream is already exists, there is nothing to do
    if(this.displayedLogsStreamSubscription) {
      return;
    }

    // Combine the filtered logs stream with the pagination state stream => displayed logs stream
    const displayedLogsStream:Observable<Array<PracticalModuleLog>|null> = this.filteredPracticalModuleLogs.pipe(
      combineLatestWith(this.practicalModulePaginationStateSubject),
      map<[Array<PracticalModuleLog>, PaginationState], Array<PracticalModuleLog>>(
        ([filteredPracticalModuleLogs, practicalModulePaginationState]) => {
          // If the filtered logs or the pagination state is null
          if(filteredPracticalModuleLogs == null || practicalModulePaginationState == null) {
            // Push null into the stream
            return null;
          }
          
          // Retrun the correct section of the filtered logs based on the pagination state
          return filteredPracticalModuleLogs.slice(
            practicalModulePaginationState.pageSize * practicalModulePaginationState.pageIndex,
            practicalModulePaginationState.pageSize * (practicalModulePaginationState.pageIndex + 1),
          );
        }
      )
    );
    
    // Subscribe to the displayed logs stream to update the content state stream with data
    this.displayedLogsStreamSubscription = displayedLogsStream.subscribe(
      (displayedLogs:Array<PracticalModuleLog>|null) => {
        // If the displayed logs null, there is nothing to do
        if(displayedLogs == null) {
          return;
        }

        // Push a new data state to the content state stream
        this.practicalModueLogsContentStateSubject.next(new PracticalModuleLogsContentDataState(displayedLogs));
      }
    );
  }

  /**
   * Applies the given filtering on the given logs array and returns the resulting remaining logs.
   * 
   * @param logs the logs to filter
   * @param filtering the practical module filtering to apply
   * 
   * @returns the filtered logs
   */
  private filterLogs(logs:Array<PracticalModuleLog>, filtering:PracticeModuleLogsFiltering):Array<PracticalModuleLog> {
    const filteredLogs:Array<PracticalModuleLog> = logs.filter(
      (practicalModuleLog:PracticalModuleLog) => {
        // Check if the logged entity operation is in the filter's operation set
        if(this.isLoggedEventIsInFilteredEvents(practicalModuleLog, filtering.events) == false) {
          return false;
        }

        // Check if any of the log's datas contains the given string
        if(filtering.containedText && this.isLoggedEntityContainsString(practicalModuleLog, filtering.containedText) == false) {
          return false;
        }

        // If all checks passed, return true
        return true;
      }
    );

    // Return the filtered logs
    return filteredLogs;
  }

  /**
   * Determines if the logged entity event is in a given set of logged entity events.
   * 
   * @param practicalModuleLog the log to check
   * @param filteredEvents the set of the (acceptable) events
   * 
   * @returns true if the set contains the log's event, false otherwise
   */
  private isLoggedEventIsInFilteredEvents(
    practicalModuleLog:PracticalModuleLog,
    filteredEvents:Array<PracticalModuleLogEntityEvent>
  ):boolean {
    const logType = getRealTypeOfPracticalModuleLog(practicalModuleLog);

    const eventName:string = logType == GeneralPracticalModuleLog ? generalEventName : getEventNameOfPracticalModuleLog(practicalModuleLog);

    return filteredEvents.some(
      (event:PracticalModuleLogEntityEvent) => {
        return event.entityType === practicalModuleLog.loggedEntityType &&
        event.eventName === eventName;
      }
    );
  }

  /**
   * Checks if the logged entity contains the given string. It works as following:
   *  - if the log is a critical point log, it checks the critical point's name, description and the related practice path's name
   *  - if the log is a critical point assignment log, it checks the critical point's name, description and the related
   *    practice path's name
   *  - if the log is a practice path log, it checks the practice path's name
   * 
   * @param practicalModuleLog the log to be checked 
   * @param containedText the text to contain
   * 
   * @returns true if the log satisfies the conditions about the contained text, false otherwise
   */
  private isLoggedEntityContainsString(practicalModuleLog:PracticalModuleLog, containedText:string):boolean {
    switch(practicalModuleLog.loggedEntityType) {
      case "critical_point":
        // *** DEEP SHIT JENCI CODE, átmenetileg kivéve
        const criticalPointLog:CriticalPointLog = practicalModuleLog as CriticalPointLog;
        return this.isCachedCriticalPointContainsString(criticalPointLog.loggedEntityKey, containedText) ||
        this.isCachedPracticePathContainsString(criticalPointLog.practicePathUuid, containedText);
      case "critical_point_assignment":
        const criticalPointAssignmentLog:CriticalPointAssignmentLog = practicalModuleLog as CriticalPointAssignmentLog;
        return this.isCachedCriticalPointContainsString(criticalPointAssignmentLog.criticalPointUuid, containedText) ||
        this.isCachedPracticePathContainsString(criticalPointAssignmentLog.practicePathUuid, containedText);
      case "practice_path":
        return this.isCachedPracticePathContainsString(practicalModuleLog.loggedEntityKey, containedText);
      default:
        return false;
    }
  }

  /**
   * Determines if a cached critical point with the given uuid is contains the given string in it's title or
   * description. If the critical point is not exists, false returned.
   * 
   * @param criticalPointUuid the uuid of the critical point
   * @param containedText the text to contain
   * 
   * @returns true if the critical point contains the given text, false otherwise
   */
  private isCachedCriticalPointContainsString(criticalPointUuid:string, containedText:string):boolean {
    // Get the critical point from the cache
    const criticalPoint:CachedCriticalPointFields|null = this.criticalPointCacherService.getCriticalPointFromCache(
      criticalPointUuid
    ).criticalPoint;
    
    // Return the check result
    return criticalPoint?.title.includes(containedText) || criticalPoint?.description.includes(containedText);
  }

  /**
   * Determines if a cached practice path with the given uuid is contains the given string in it's name.
   * If the practice path not exists, false returned.
   * 
   * @param criticalPointUuid the uuid of the practice path
   * @param containedText the text to contain
   * 
   * @returns true if the practice path contains the given text, false otherwise
   */
  private isCachedPracticePathContainsString(practicePathUuid:string, containedText:string):boolean {
    // Get the practice path from the cache
    const practicePath:CachedPracticePath|null = this.practicePathCacherService.getPracticePathFromCache(
      practicePathUuid
    )?.practicePath as CachedPracticePath;

    // Return the check result
    return practicePath?.name.includes(containedText);
  }

  /**
   * Updates the filtering with the given new filtering value via pushing the new filtering state into the filtering
   * stream. If the start or the end point of the selected date interval is changed, it updates the fetched practical
   * logs accordingly.
   * 
   * @param filtering the new practical log filtering
   */
  public async updateFiltering(filtering:PracticeModuleLogsFiltering):Promise<void> {
    // We check that the start or the end point of the selected date interval is changed
    const isDateIntervalChanged:boolean =
    filtering.startTimestamp !== this.practicalModuleLogsFilteringSubject.value?.startTimestamp ||
    filtering.endTimestamp !== this.practicalModuleLogsFilteringSubject.value?.endTimestamp;
    if(isDateIntervalChanged) {
      // If so, we have to update the fetched logs
      await this.updateFetchedPracticalModuleLogsAndCachedEntities(filtering.startTimestamp, filtering.endTimestamp);      
    }

    // Push the actual filtering into the stream
    this.practicalModuleLogsFilteringSubject.next(filtering);
  }

  /**
   * Update the fetched logs. It pushes the fetched result into the `fetchedPracticalModuleLogsSubject` stream.
   * 
   * @param startTimestamp the start timestamp for the logs
   * @param endTimestamp the end 
   */
  private async updateFetchedPracticalModuleLogsAndCachedEntities(startTimestamp:number, endTimestamp:number):Promise<void> {
    // Check the entity type and name configuration validity
    if(this.isEntityTypeAndKeyConfigurationValid() == false) {
      // If it was invalid, set the state to error - invalid configuration
      this.practicalModueLogsContentStateSubject.next(
        new PracticalModuleLogsContentErrorState(PracticalModuleLogsContentErrorType.InvalidConfigurationError)
      );
      return;
    }
    
    // Set the list state to loading
    this.practicalModueLogsContentStateSubject.next(new PracticalModuleLogContentLoadingState());

    let fetchedLogs:Array<PracticalModuleLog>;

    try {
      // Check the configured entity type
      if(this.entityType == "all") {
        // If it was 'all', we fetch all logs in the date interval
        fetchedLogs = await this.practicalModuleLogsApiService.fetchAllLogs(startTimestamp, endTimestamp);
      } else {
        // If it was not 'all', we fetch the logs for the configured entity 
        fetchedLogs = await this.practicalModuleLogsApiService.fetchLogsForEntity(
          this.entityType as LoggedPracticalModuleEntityType, this.entityKey, startTimestamp, endTimestamp
        );
      }
    } catch(error:any) {
      const apiErrorId:string|null = error.error?.error ?? null;
      let errorType:PracticalModuleLogsContentErrorType;

      switch(apiErrorId) {
        case "REQUIRED_PERMISSION_MISSING":
          errorType = PracticalModuleLogsContentErrorType.PermissionMissing;
          break;
        case "INVALID_ENTITY_TYPE":
          errorType = PracticalModuleLogsContentErrorType.InvalidEntityType;
          break;
        case "INVALID_ENTITY_KEY":
          errorType = PracticalModuleLogsContentErrorType.InvalidEntityKey;
          break;
        case "INVALID_INTERVAL":
          errorType = PracticalModuleLogsContentErrorType.IvalidInterval;
          break;
        default:
          errorType = PracticalModuleLogsContentErrorType.OtherError;
          break;

      }

      // Set the content state to error via pushing the error state to the content state stream
      this.practicalModueLogsContentStateSubject.next(new PracticalModuleLogsContentErrorState(errorType, error));
      // Set the fetched logs to null, to "remove" the preivous data from the stream
      this.fetchedPracticalModuleLogsSubject.next(null);
      return;
    }
    
    try {
      // Update the cached entities based on the freshly fetched logs
      await this.updateCachedEntities(fetchedLogs);
    } catch(error:any) {
      // TODO: error handling after refact
    }

    // Push the retrieved data into the fetched logs stream
    this.fetchedPracticalModuleLogsSubject.next(fetchedLogs);
  }

  /**
   * Determines if the configured entity type and key combination is valid. The entity type must always be set
   * and the entity key can only be undefined if the entity type is "all".
   * 
   * @returns true if the configuration is valid, false otherwise
   */
  public isEntityTypeAndKeyConfigurationValid():boolean {;
    return !(this.entityType == undefined || (this.entityType !== "all" && this.entityKey == undefined));
  }

  /**
   * Updates the pagination state via pushing it the pagination state stream.
   * 
   * @param pageSize the pagesize
   * @param pageIndex the actual page index
   */
  public updatePaginationState(pageSize:number, pageIndex:number):void {
    this.practicalModulePaginationStateSubject.next({ pageSize: pageSize, pageIndex: pageIndex });
  }

  /**
   * Update the cached entities based on the provided logs.
   * 
   * @param practicalModuleLogs the practical module logs
   */
  private async updateCachedEntities(practicalModuleLogs:Array<PracticalModuleLog>):Promise<void> {
    // Create empty sets for the practice paths and the critical points
    const practicePathUuids:Set<string> = new Set<string>();
    const criticalPointUuids:Set<string> = new Set<string>();
    
    // Iterate over the logs
    for(const practicalModuleLog of practicalModuleLogs) {
      // Add each type of uuid to the correspondig set based on the type of the log
      switch(practicalModuleLog.loggedEntityType) {
        case "critical_point":
          const criticalPointLog:CriticalPointLog = practicalModuleLog as CriticalPointLog;
          if(criticalPointLog.practicePathUuid != null){
            practicePathUuids.add(criticalPointLog.practicePathUuid);
          }
          
          criticalPointUuids.add(criticalPointLog.loggedEntityKey);
          break;
        case "critical_point_assignment":
          const criticalPointAssignmentLog:CriticalPointAssignmentLog = practicalModuleLog as CriticalPointAssignmentLog;
          practicePathUuids.add(criticalPointAssignmentLog.practicePathUuid);
          criticalPointUuids.add(criticalPointAssignmentLog.criticalPointUuid);
          break;
        case "practice_path":
          const practicePathLog:PracticePathLog = practicalModuleLog as PracticePathLog;
          practicePathUuids.add(practicePathLog.loggedEntityKey);
          break;
      }
    }

    // Fetch and cache the entities
    await this.practicePathCacherService.fetchAndCachePracticePaths(Array.from(practicePathUuids));
    await this.criticalPointCacherService.fetchAndCacheCriticalPoints(Array.from(criticalPointUuids));
  }

}