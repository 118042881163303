import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-practical-module-logs-content-info-dialog',
  templateUrl: './practical-module-logs-content-info-dialog.component.html',
  styleUrls: ['./practical-module-logs-content-info-dialog.component.scss']
})
export class PracticalModuleLogsContentInfoDialogComponent {

  /**
   * Opens a practical module log info dialog.
   * 
   * @param dialogService the dialog service
   * 
   * @returns reference to the opened dialog
   */
  public static open(dialogService:MatDialog):MatDialogRef<PracticalModuleLogsContentInfoDialogComponent, void> {
    return dialogService.open<
      PracticalModuleLogsContentInfoDialogComponent, void, void
    >(
      PracticalModuleLogsContentInfoDialogComponent
    );
  }

}
