import { Injectable } from '@angular/core';
import { CityNode, PracticeCity, practiceCityNodeSorter, practiceCitySorter } from '../classes/model/practice-city';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { HttpResponseData } from '../classes/http-communication';
import { matchingPropertyPredicate } from '../functions/misc';

@Injectable({
  providedIn: 'root'
})
export class PracticeCityNodeService {
  private readonly practiceCitiesApiUrlFragment:string = "/api/admin/practice_path_city";
  private readonly updateCityExamNumberNodeAssignmentInCityApiUrlFragment:string =
    `${this.practiceCitiesApiUrlFragment}/modify_node_uuid_assignment_to_exam_thesis`;

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) { }

  public async addNodeToCity(city:PracticeCity, newCityNode:Partial<CityNode>):Promise<void> {
    const apiUrlFragment:string = `${this.practiceCitiesApiUrlFragment}/${city.uuid}/nodes`;

    const httpBody:Object = {
      data: {
        item: newCityNode
      }
    };

    try {
      const response:HttpResponseData<CityNode> = await this.backendService.callApi(apiUrlFragment, "PUT", httpBody);
      city.nodes.push(response.data);
      city.nodes.sort(practiceCityNodeSorter);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba történt a vizsga érintési pont felvétele közben.");
    }
  }

  public async deleteNodeFromCity(city:PracticeCity, nodeUuid:string):Promise<void> {
    const apiUrlFragment:string = `${this.practiceCitiesApiUrlFragment}/${city.uuid}/nodes/${nodeUuid}`;

    try {
      await this.backendService.callApi(apiUrlFragment, "DELETE");
      city.nodes.removeItems(matchingPropertyPredicate("uuid", nodeUuid));
      for(let position of city.cityNodeUuidsAssigmentToExamThesis) {
        position.removeItems((element:string) => { return element === nodeUuid; });
      }
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba történt a vizsga érintési pont törlése közben.");
    }
  }


  public async updateNodeInCity(city:PracticeCity, modifiedNode:Partial<CityNode>, nodeImage:File):Promise<void> {
    const apiUrlFragment:string = `${this.practiceCitiesApiUrlFragment}/${city.uuid}/nodes`;
    const httpBody:Object = {
      item: modifiedNode
    };

    const fileMap:Map<string, File> = new Map<string, File>();
    nodeImage && fileMap.set("nodeImage", nodeImage);

    try {
      const response:HttpResponseData<CityNode> = await this.backendService.callApi(apiUrlFragment, "POST", httpBody, fileMap);
      city.nodes.replaceItems(matchingPropertyPredicate("uuid", response.data.uuid), response.data);
      city.nodes.sort(practiceCityNodeSorter);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba történt a vizsga érintési pont módosítása közben.");
    }
  }

  public async updateCityExamNumberNodeAssignment(city:PracticeCity, examNumber:number, nodeUuids:Array<string>):Promise<void> {
    const httpBody:Object = {
      cityUuid: city.uuid,
      examThesisIndexZeroBased: examNumber,
      nodeUuids: nodeUuids
    };

    try {
      await this.backendService.callApi(this.updateCityExamNumberNodeAssignmentInCityApiUrlFragment, "POST", httpBody);
      city.cityNodeUuidsAssigmentToExamThesis[examNumber].replaceArrayElements(nodeUuids);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Sikertelen a hozzárendelés módosítás.");
    }
  }

}
