import { Injectable } from '@angular/core';
import { DataCacherService } from './data-cacher-service';
import { Permission, PermissionString } from '../classes/model/permissions';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { HttpResponseData } from '../classes/http-communication';
import { matchingPropertyPredicate } from '../functions/misc';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService implements DataCacherService {
  private readonly permissionsApiUrlFragment:string = "/api/admin/permissions";

  private permissions:Array<Permission>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.ServerManager ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) {
    this.permissions = new Array<Permission>();
  }

  public getPermissionsArrayRef():ReadonlyArray<Permission> {
    return this.permissions;
  }

  public async fetchPermissions():Promise<Array<Permission>> {
    let permissions:Array<Permission> = [];
    try {
      const response:HttpResponseData<Array<Permission>> = await this.backendService.callApi(this.permissionsApiUrlFragment, "GET");
      permissions = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a jogosultságok lekérdezése közben.");
    }

    return permissions;
  }

  public async addPermission(permission:Permission):Promise<void> {
    const httpBody:Object = {
      permission: permission
    };

    try {
      await this.backendService.callApi(this.permissionsApiUrlFragment, "PUT", httpBody);
      this.permissions.push(permission);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a jogosultság létrehozásakor.");
    }
  }

  public async deletePermission(permissionName:string):Promise<void> {
    const apiUrlFragment:string = `${this.permissionsApiUrlFragment}/${permissionName}`;

    try {
      await this.backendService.callApi(apiUrlFragment, "DELETE");
      this.permissions.removeItems(matchingPropertyPredicate("name", permissionName));
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a jogosultság törlésekor.");
    }
  }

  public async modifyPermission(permission:Permission):Promise<void> {
    const httpBody:Object = {
      permission: permission
    };

    try {
      await this.backendService.callApi(this.permissionsApiUrlFragment, "POST", httpBody);
      this.permissions.replaceItems(matchingPropertyPredicate("name", permission.name), permission);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a jogosultság csoport módosításakor.");
    }
  }

  public async loadDataIntoCache(): Promise<void> {
    const permissions:Array<Permission> = await this.fetchPermissions();
    this.permissions.replaceArrayElements(permissions);
  }

  public getRequiredPermissionsForDataLoading(): readonly PermissionString[] {
    return this.requiredPermissionsForDataLoading;
  }

  public clearCachedData():void {
    this.permissions.length = 0;
  }

  public getNameOfCachedData(): string {
    return "Jogosultságok";
  }
}
