<section class = "header-section">
    <div class = "header-left">
        <div
            #hamburgerContainer
            class = "hamburger-container"
            [ngClass] = "{ active: isNavigationSectionVisible }"
            (click) = "toogleNavigationSectionVisibility()"
        >
        <span class = "material-icons">
                menu
        </span>
        </div>
        <div class = "header-logo-container" routerLink = "/dashboard">
            Mr. Kresz
        </div>
    </div>
    <div
        class = "header-center"
        [matTooltip] = "environmentService.getCurrentEnvironment().serverHost"
        matTooltipPosition = "below"
    >
        <div
            [style.background-color]= "environmentService.getCurrentEnvironment().color"
            class = "color-indicator"
        >
            <!-- Color indicator -->
        </div>
        <div>
            {{ environmentService.getCurrentEnvironment().displayedName }}
        </div>
    </div>
    <div class = "header-right">
        <div>
            {{ sessionService.getUsername() }}
        </div>
        <div class = "header-userinfo" [matMenuTriggerFor] = "userMenu">
            <mat-icon class = "header-userinfo-icon"> person </mat-icon>
        </div>
    </div>
</section>

<mat-menu #userMenu = "matMenu">
    <div class = "user-dropdown-infos">
        <div class = "user-dropdown-user-name">
            {{ username }}
        </div>
        <div class = "user-dropdown-user-role">
            <div *ngFor = "let roleName of displayedRoles">
                {{ roleName }}
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <button mat-menu-item (click) = "handleLogoutButtonClick()">
        <mat-icon> logout </mat-icon>
        <span> Kijelentkezés </span>
    </button>
</mat-menu>
