import { Injectable, Injector, ProviderToken } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from './backend.service'
import { SessionService, UserSession } from './session.service';
import { sha256 } from 'js-sha256';
import { AdminUserService } from '../admin-user.service';
import { AppNotificationService } from '../app-notification.service';
import { CategoryService } from '../category.service';
import { DataCacherService } from '../data-cacher-service';
import { FeedbackService } from '../feedback.service';
import { GlobalGiftService } from '../global-gift.service';
import { PracticeIconService } from '../practice-icon.service';
import { QuestionService } from '../question.service';
import { QuestionGroupService } from '../questiongroup.service';
import { StatisticsService } from '../statistics.service';
import { StudentLoggingService } from '../student-logging.service';
import { StudentService } from '../student.service';
import { ChangelogService } from '../changelog.service';
import { AdminPersonalGiftService } from '../admin-personal-gift.service';
import { AdminRoleService } from '../admin-roles.service';
import { InstructorService } from '../instructor.service';
import { PackageDefinitionService } from '../package-definition.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    private sessionService:SessionService,
    private backendService:BackendService,
    private router:Router,
    private injector:Injector
  ) { }

  public async login(email:string, password:string):Promise<LoginResult> {
    let loginResult:LoginResult;
    email += !email.includes('@') ? "@gmail.com" : "";
    const httpBody:Object = { credentials: { email: email, password: sha256(password) } };

    try {
      const response:LoginResponse = await this.backendService.callApi("/api/admin/auth/get_token", "POST", httpBody);

      switch(response.result) {
        case "ACCESS_GRANTED":
          loginResult = LoginResult.Success;
          this.sessionService.createLocalSession(response.data as any);
          this.router.navigate(["dashboard"]);
          break;
        case "ACCESS_DENIED":
          loginResult = LoginResult.WrongCredentials;
          break;
        default:
          loginResult = LoginResult.ServerError;
      }
    } catch(error:any) {
      if(error?.error?.error) {
        loginResult = LoginResult.ServerError;
      } else {
        loginResult = LoginResult.ServerConnectionError;
      }
    }

    return loginResult;
  }

  public async logout():Promise<void> {
    this.sessionService.deleteLocalSession();
    this.clearCachedDatas();
    await this.router.navigate(["login"]);
  }

  public clearCachedDatas():void {
    const dataCacherServices:Array<ProviderToken<DataCacherService>> = [
      AdminPersonalGiftService,
      AdminRoleService,
      AdminUserService,
      AppNotificationService,
      CategoryService,
      ChangelogService,
      FeedbackService,
      GlobalGiftService,
      InstructorService,
      PackageDefinitionService,
      PracticeIconService,
      QuestionService,
      QuestionGroupService,
      StatisticsService,
      StudentLoggingService,
      StudentService
    ];
    
    dataCacherServices.forEach(
      (serviceClass:ProviderToken<DataCacherService>) => {
        const service:DataCacherService|null = this.injector.get(serviceClass, null);
        service?.clearCachedData?.();
      }
    );
  }

}

type LoginResulString = "ACCESS_GRANTED"|"ACCESS_DENIED";

export enum LoginResult {
  Success, WrongCredentials, ServerError, ServerConnectionError
}

class LoginResponse {
  result:LoginResulString;
  data?:UserSession;
}