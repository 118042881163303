<mat-card class = "notification-card">
    <div class = "notification-card-content-info">
        <div class = "notification-addressed">
            Címzett: {{ addressed }}
        </div>
        <div class = "notification-title">
            {{ appNotification.title }}
        </div>
        <div class = "notification-message">
            {{ appNotification.message }}
        </div>
        <div class = "notification-route">
            Útvonal: <span class = "notification-route-string"> {{ appNotification.route }} </span> 
        </div>
        
    </div>
    <div class = "notification-card-admin-info">
        <div>
            {{ appNotification.createdBy }}
        </div>
        <div>
            {{ appNotification.createdOn | date: "YYYY.MM.dd. HH:mm:ss" }}
        </div>
    </div>
</mat-card>
