import { PracticePath } from 'src/app/classes/model/practice-path';

// Statikus kirajzolásra használható (nem változtatható dinamikusan a kirajzolás
// azt fogja kirajzolni egy practicePath esetén amilyen példányt először kap az adott útvonalból)
// Szemben például a MarkeredPolylineController dinamikusan változtatható. (Folyamatosan reagál az input változására)
// egy path csak egyszer adható hozzá
// Egy kirajzolt path-nek csak a színe módosítható utólag a highlightPathInZone-al
export class PracticePathMapController{
  private renderedPracticePaths: {polyline: google.maps.Polyline, practicePath: PracticePath}[] = [];
  private currentHighlightedZoneUuid: string | null = null;

  constructor(public map: google.maps.Map){

  }

  getDefaultPolylineOptions() : {strokeOpacity: number, strokeColor: string, zIndex: number}{
    return {
      strokeOpacity:0.2,
      strokeColor: this.getDefaultColorOfPracticePath(),
      zIndex:0
    }
  }

  showPracticePaths(){
    for(const renderedPracticepath of this.renderedPracticePaths){
      renderedPracticepath.polyline.setMap(this.map);
    }
  }

  hidePracticePaths(){
    for(const renderedPracticepath of this.renderedPracticePaths){
      renderedPracticepath.polyline.setMap(null);
    }
  }

  addPracticePathToMap(practicePath:PracticePath){
    // Check practice path not exists in renderedPracticePaths
    if(this.renderedPracticePaths.find(x => x.practicePath.uuid === practicePath.uuid)){
      throw new Error("Practice path already exists in map");
    }

    const polyline = new google.maps.Polyline({
      path: practicePath.path.map(p => ({lat: p.position.latitude, lng: p.position.longitude})),
      geodesic: true,
      clickable:false,
      draggable:false,
      editable:false,
      ...this.getDefaultPolylineOptions(),
      strokeWeight: 5,
    });
    polyline.setMap(this.map);
    this.renderedPracticePaths.push({polyline, practicePath});
  }

  getDefaultColorOfPracticePath(){
    return '#000000';
  }

  highlighPathInZone(zoneUuid: string,highlightColor: string){
    if(this.currentHighlightedZoneUuid === zoneUuid) return;

    let zIndexHighligh = 1000;

    for(const renderedPracticepath of this.renderedPracticePaths){
      const shouldHighlightThisPath = (renderedPracticepath.practicePath.zoneUuids ?? []).includes(zoneUuid);

      if(shouldHighlightThisPath){
        renderedPracticepath.polyline.setOptions({
          strokeColor: highlightColor,
          strokeOpacity:1.0,
          zIndex:zIndexHighligh
        });

      }else{
        renderedPracticepath.polyline.setOptions(this.getDefaultPolylineOptions());
      }
    }
    this.currentHighlightedZoneUuid = zoneUuid;
  }

  removeHighlightFromPathInZone(){
    for(const renderedPracticepath of this.renderedPracticePaths){
      renderedPracticepath.polyline.setOptions(this.getDefaultPolylineOptions());
    }
    this.currentHighlightedZoneUuid = null;

  }

}
