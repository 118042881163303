import { Injectable } from '@angular/core';
import { PracticeIcon, practiceIconSorter } from '../classes/model/practice-icons';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { HttpResponseData } from '../classes/http-communication';
import { DataCacherService } from './data-cacher-service';
import { matchingPropertyPredicate } from '../functions/misc';
import { Permission, PermissionString } from '../classes/model/permissions';

@Injectable({
  providedIn: 'root'
})
export class PracticeIconService implements DataCacherService {
  private readonly practiceIconsApiUrlFragment:string = "/api/admin/practice_path_icon";
  private readonly practiceIconMediaName:string = "icon-media";
  private practiceIcons:Array<PracticeIcon>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.PracticePathRead ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) {
    this.practiceIcons = new Array<PracticeIcon>();
  }

  public getPracticeIconsArrayRef(): ReadonlyArray<Readonly<PracticeIcon>> {
    return this.practiceIcons;
  }

  public async fetchPracticeIcons(): Promise<Array<PracticeIcon>> {
    let practiceIcons:Array<PracticeIcon> = [];

    try {
      const response:HttpResponseData<Array<PracticeIcon>> = await this.backendService.callApi(this.practiceIconsApiUrlFragment, "GET");
      practiceIcons = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az útvonal ikonok lekérdezése közben!");
    }

    return practiceIcons;
  }

  public async addPracticeIcon(practiceIcon:Partial<PracticeIcon>, iconMedia:File):Promise<void> {
    const httpBody:Object = {
      item: practiceIcon
    };

    const fileMap:ReadonlyMap<string, File> = new Map([
      [ this.practiceIconMediaName, iconMedia ]
    ]);

    try {
      const response:HttpResponseData<PracticeIcon> = await this.backendService.callApi(this.practiceIconsApiUrlFragment, "PUT", httpBody, fileMap);
      this.practiceIcons.push(response.data);
      this.practiceIcons.sort(practiceIconSorter);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az útvonal ikon létrehozása közben.");
    }
  }

  public async deletePracticeIcon(practiceIconUuid:string):Promise<void> {
    const apiUrlFragment:string = this.practiceIconsApiUrlFragment + "/" + practiceIconUuid;
    try {
      await this.backendService.callApi(apiUrlFragment, "DELETE");
      this.practiceIcons.removeItems(matchingPropertyPredicate("uuid", practiceIconUuid));
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az útvonal ikon törlése közben.");
    }
  }

  public async modifyPracticeIcon(practiceIcon:PracticeIcon, iconMedia?:File):Promise<void> {
    const httpBody:Object = {
      item: practiceIcon
    };

    const fileMap:Map<string, File> = new Map<string, File>();
    iconMedia && fileMap.set(this.practiceIconMediaName, iconMedia);

    try {
      const response:HttpResponseData<PracticeIcon> = await this.backendService.callApi(this.practiceIconsApiUrlFragment, "POST", httpBody, fileMap);
      this.practiceIcons.replaceItems(matchingPropertyPredicate("uuid", practiceIcon.uuid), response.data);
      this.practiceIcons.sort(practiceIconSorter);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az útvonal ikon módosítása közben.");
    }
  }


  public async loadDataIntoCache(): Promise<void> {
    const practiceIcons:Array<PracticeIcon> = await this.fetchPracticeIcons();
    this.practiceIcons.replaceArrayElements(practiceIcons).sort(practiceIconSorter);
  }

  public getRequiredPermissionsForDataLoading(): readonly PermissionString[] {
    return this.requiredPermissionsForDataLoading;
  }

  public clearCachedData():void {
    this.practiceIcons.length = 0;
  }

  public getNameOfCachedData(): string {
    return "Útvonal ikonok";
  }

}
