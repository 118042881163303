import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, QueryParamsHandling } from '@angular/router';
import { Subscription } from 'rxjs';
import { CityNode } from 'src/app/classes/model/practice-city';
import { DisplayedPathEditState, PathEditState, pathEditStates } from 'src/app/classes/model/practice-path';
import { matchingPropertyPredicate } from 'src/app/functions/misc';
import { NavigationQueueService } from 'src/app/services/common/navigation-queue.service';
import { EventEmitter } from "@angular/core";
import { PracticeCityNameAndResponsibles, PracticePathsPageDataService } from '../services/practice-paths-page-data.service';
import { SessionService } from 'src/app/services/common/session.service';
import { PracticeCityService } from 'src/app/services/practice-city.service';
import { PracticeCityZone } from 'src/app/classes/model/practice-city-zone';

@Component({
  selector: 'app-practice-path-filter',
  templateUrl: './practice-path-filter.component.html',
  styleUrls: ['./practice-path-filter.component.scss']
})
export class PracticePathFilterComponent implements OnInit, OnDestroy {
  pathEditStates:ReadonlyArray<DisplayedPathEditState> = pathEditStates;

  @Output() initialized:EventEmitter<void> = new EventEmitter<void>();

  // FormControls
  protected nameOrUuidFilterFC:FormControl<string>;
  protected editStateFilterFC:FormControl<"*"|PathEditState>;
  protected cityFilterFC:FormControl<string>;
  protected nodeFilterFC:FormControl<string>;
  protected zoneFilterFC:FormControl<string>;
  protected onlyFullPracticePathsFC:FormControl<boolean>;
  protected onlyWithNoReleasedVideoFC:FormControl<boolean>;
  protected onlyFreePracticePathsFC:FormControl<boolean>;

  // Default values
  private readonly nameOrUuidFilterDefaultValue = "";
  private readonly editStateFilterDefaultValue = "*";
  private readonly cityFilterDefaultValue = "*";
  private readonly nodeFilterDefaultValue = "*";
  private readonly zoneFilterDefaultValue = "*";
  private readonly onlyFullPracticePathsDefaultValue = false;
  private readonly onlyWithNoReleasedVideoFCDefaultValue = false;
  private readonly onlyFreePracticePathsFCDefaultValue = false;

  // Subscriptions
  private nameOrUuidFilterFCValueChangesSubscription:Subscription;
  private editStateFilterFCValueChangesSubscription:Subscription;
  private cityFilterFCValueChangesSubscription:Subscription;
  private cityFilterFCValueChangesForNodeSubscription:Subscription;
  private nodeFilterFCValueChangesSubscription:Subscription;
  private zoneFilterFCValueChangesSubscription:Subscription;
  private onlyFullPracticePathsFCValueChangesSubscription:Subscription;
  private onlyWithNoReleasedVideoFCValueChangesSubscription:Subscription;
  private onlyFreePracticePathsFCValueChangesSubscription:Subscription;

  constructor(
    private navigationQueueService:NavigationQueueService,
    private activatedRoute:ActivatedRoute,
    protected practicePathsPageDataService:PracticePathsPageDataService,
    private practiceCityService:PracticeCityService,
    private sessionService:SessionService
  ) { }

  ngOnInit(): void {
    this.initializeFilters();
  }

  ngOnDestroy(): void {
    // Unsubscribe from the value changes events
    this.nameOrUuidFilterFCValueChangesSubscription?.unsubscribe();
    this.editStateFilterFCValueChangesSubscription?.unsubscribe();
    this.cityFilterFCValueChangesSubscription?.unsubscribe();
    this.cityFilterFCValueChangesForNodeSubscription?.unsubscribe();
    this.nodeFilterFCValueChangesSubscription?.unsubscribe();
    this.zoneFilterFCValueChangesSubscription?.unsubscribe();
    this.onlyFullPracticePathsFCValueChangesSubscription?.unsubscribe();
    this.onlyWithNoReleasedVideoFCValueChangesSubscription?.unsubscribe();
    this.onlyFreePracticePathsFCValueChangesSubscription?.unsubscribe();
  }

  /**
   * Creates and initalizes the form controls of the filters. It checks the session storage first to load
   * a previously used filtering state, if none found it creates one from the query parameters of the URL.
   * When everything is loaded correctly, the actual filtering is emitted.
   */
  private async initializeFilters():Promise<void> {
    // Create the form controls
    this.createFormControls();

    // Subscribe to the selected city loading stream (to disable the node selector during that)
    this.subscribeToSelectedCityLoading();

    // Load the filters from the URL
    this.loadFilterStatesFromUrl();

    // Update the URL (to match with the filters' states)
    const filtering:PracticePathFiltering = this.composeFiltering();
    await this.updateRoutePathAndQueryParams(filtering);

    // Update the actually used practice path filtering
    this.practicePathsPageDataService.updatePracticePathFiltering(filtering);

    // Emit that the component finished it's initialization
    this.initialized.emit();
  }

  /**
   * Creates the form controls for the filters and creates the initial value changes subscriptions for them.
   */
  private createFormControls():void {
    // Name filter form control
    this.nameOrUuidFilterFC = new FormControl<string>(this.nameOrUuidFilterDefaultValue);
    this.nameOrUuidFilterFCValueChangesSubscription = this.nameOrUuidFilterFC.valueChanges.subscribe(
      this.formControlValueChanges
    );

    // Edit state filter form control
    this.editStateFilterFC = new FormControl<PathEditState|"*">(this.editStateFilterDefaultValue);
    this.editStateFilterFCValueChangesSubscription = this.editStateFilterFC.valueChanges.subscribe(
      this.formControlValueChanges
    );

    // City filter form control
    this.cityFilterFC = new FormControl<string>(this.cityFilterDefaultValue);
    this.cityFilterFCValueChangesSubscription = this.cityFilterFC.valueChanges.subscribe(
      this.formControlValueChanges
    );

    // Node filter form control
    this.nodeFilterFC = new FormControl<string>(this.nodeFilterDefaultValue);
    this.nodeFilterFCValueChangesSubscription = this.nodeFilterFC.valueChanges.subscribe(
      this.formControlValueChanges
    );

    this.zoneFilterFC = new FormControl<string>(this.nodeFilterDefaultValue);
    this.zoneFilterFCValueChangesSubscription = this.zoneFilterFC.valueChanges.subscribe(
      this.formControlValueChanges
    );

    // Only full practice paths form control
    this.onlyFullPracticePathsFC = new FormControl<boolean>(this.onlyFullPracticePathsDefaultValue);
    this.onlyFullPracticePathsFCValueChangesSubscription = this.onlyFullPracticePathsFC.valueChanges.subscribe(
      this.formControlValueChanges
    );

    // Only with no released video form control
    this.onlyWithNoReleasedVideoFC = new FormControl<boolean>(this.onlyWithNoReleasedVideoFCDefaultValue);
    this.onlyWithNoReleasedVideoFCValueChangesSubscription = this.onlyWithNoReleasedVideoFC.valueChanges.subscribe(
      this.formControlValueChanges
    );

    // Only with no released video form control
    this.onlyFreePracticePathsFC = new FormControl<boolean>(this.onlyFreePracticePathsFCDefaultValue);
    this.onlyFreePracticePathsFCValueChangesSubscription = this.onlyFreePracticePathsFC.valueChanges.subscribe(
      this.formControlValueChanges
    );
  }

  /**
   * Subcribes to the selected city loading. If the city is loading, set the node and zone selectors to disabled and it's value
   * to all nodes, otherwise enables the said selectors.
   */
  private subscribeToSelectedCityLoading():void {
    this.practicePathsPageDataService.selectedCityLoading$.subscribe(
      (isLoading:boolean) => {
        if(isLoading) {
          this.nodeFilterFC.setValue("*", { emitEvent: false });
          this.nodeFilterFC.disable({ emitEvent: false });

          this.zoneFilterFC.setValue("*", { emitEvent: false });
          this.zoneFilterFC.disable({ emitEvent: false });
        } else {
          this.nodeFilterFC.enable({ emitEvent: false });
          this.zoneFilterFC.enable({ emitEvent: false });
        }
      }
    );
  }

  /**
   * Callback function to handle a for control's value change.
   */
  private formControlValueChanges = async () => {
    // Get the actual filtering and
    const filtering:PracticePathFiltering = this.composeFiltering();
    // Update the URL's path and query params
    await this.updateRoutePathAndQueryParams(filtering);
    // Update the actual filtering with the practicePathsPageDataService
    await this.practicePathsPageDataService.updatePracticePathFiltering(filtering);
  }

  /**
   * Composes the filtering object based on the form controls' values.
   *
   * @returns the filtering object
   */
  private composeFiltering():PracticePathFiltering {
    return {
      onlyFullPracticePaths: this.onlyFullPracticePathsFC.value,
      nameOrUuidContains: this.nameOrUuidFilterFC.value ? this.nameOrUuidFilterFC.value : null,
      editState: this.editStateFilterFC.value !== "*" ? this.editStateFilterFC.value : null,
      cityUuid: this.cityFilterFC.value !== "*" ? this.cityFilterFC.value : null,
      nodeUuid: this.nodeFilterFC.value !== "*" ? this.nodeFilterFC.value : null,
      onlyWithNoReleasedVideo: this.onlyWithNoReleasedVideoFC.value ? true : null,
      onlyFreePracticePaths: this.onlyFreePracticePathsFC.value ? true : null,
      zoneUuid: this.zoneFilterFC.value !== "*" ? this.zoneFilterFC.value : null,
    }
  }

  /**
   * Updates the actual URL's query parameters based on the provided filtering object.
   *
   * @param filtering the filtering object
   */
  private async updateRoutePathAndQueryParams(filtering:PracticePathFiltering) {
    const queryParams:PracticePathFilteringQueryParams = this.createQueryParamsFromFilterStates(filtering);
    const queryParamsHandling:QueryParamsHandling = "merge";
    const cityUuid:string = filtering.cityUuid;

    // For the navigation it uses the navigation queue service
    // It is needed, because there are other compontents that would like to update
    // the query parameters aswell at the initialization process, and with the default
    // navigation they would cancel eachother out
    await this.navigationQueueService.navigate(
      [ "../..", cityUuid, "paths" ],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: queryParamsHandling,
        replaceUrl: true
      }
    );
  }

  /**
   * Creates a query parameters object from the provided filtering object.
   *
   * @param filtering the filtering object
   * @returns the query parameters object
   */
  private createQueryParamsFromFilterStates(filtering:PracticePathFiltering):PracticePathFilteringQueryParams {
    const queryParams:PracticePathFilteringQueryParams = {};

    queryParams.pathType = filtering.onlyFullPracticePaths ? "FULL" : "SHORT";
    queryParams.editState =  filtering.editState ?? null;
    if(filtering.nameOrUuidContains) {
      queryParams.nameOrUuidContains = encodeURIComponent(filtering.nameOrUuidContains);
    } else {
      queryParams.nameOrUuidContains = null;
    }

    queryParams.nodeUuid = filtering.nodeUuid ? encodeURIComponent(filtering.nodeUuid) : null;
    queryParams.zoneUuid = filtering.zoneUuid ? encodeURIComponent(filtering.zoneUuid) : null;

    queryParams.onlyWithNoReleasedVideo = filtering.onlyWithNoReleasedVideo ? "true" : null;
    queryParams.onlyFreePracticePaths = filtering.onlyFreePracticePaths ? "true" : null;

    return queryParams;
  }

  /**
   * Loads the filter states from the URL.
   */
  private loadFilterStatesFromUrl():void {
    // Get the actual path params
    const pathParams:PracticePathFilteringPathParams = this.activatedRoute.snapshot.params as PracticePathFilteringPathParams;
    // Get the actual query params
    const queryParams:PracticePathFilteringQueryParams = this.activatedRoute.snapshot.queryParams;
    // Set the filter states from the query params
    this.loadFormControllerValuesFromPathAndQueryParams(pathParams, queryParams);
  }

  /**
   * Sets the filters' states from a provided query params object.
   *
   * @param queryParams the query params object
   */
  private loadFormControllerValuesFromPathAndQueryParams(
    pathParams:PracticePathFilteringPathParams,
    queryParams:PracticePathFilteringQueryParams
  ):void {
    // Name
    this.nameOrUuidFilterFC.setValue(
      decodeURIComponent(queryParams.nameOrUuidContains ?? this.nameOrUuidFilterDefaultValue),
      { emitEvent: false }
    );

    // Edit state (with existence check)
    const isEditStateExists:boolean = pathEditStates.find(matchingPropertyPredicate("key", queryParams.editState)) !== undefined;
    const editState:PathEditState|"*" = isEditStateExists ? queryParams.editState : this.editStateFilterDefaultValue;
    this.editStateFilterFC.setValue(editState, { emitEvent: false });

    // City
    this.cityFilterFC.setValue(pathParams.cityUuid, { emitEvent: false });

    // Node (with existence check)
    const nodeUuid:string = decodeURIComponent(queryParams.nodeUuid);
    const node:CityNode|undefined = this.practicePathsPageDataService.getSelectedCity()?.nodes.find(
      matchingPropertyPredicate("uuid", nodeUuid)
    );
    this.nodeFilterFC.setValue(node ? nodeUuid : this.nodeFilterDefaultValue, { emitEvent: false });


    // Zone (with existence check)
    const zoneUuid:string = decodeURIComponent(queryParams.zoneUuid);
    const zone:PracticeCityZone|undefined = this.practicePathsPageDataService.getSelectedCity()?.zones.find(
      matchingPropertyPredicate("uuid", zoneUuid)
    );
    this.zoneFilterFC.setValue(zone ? zoneUuid : this.zoneFilterDefaultValue, { emitEvent: false });

    // Only full practice paths
    let pathType:"SHORT"|"FULL" = queryParams.pathType;
    if(pathType !== "SHORT" && pathType !== "FULL") {
      pathType = this.onlyFullPracticePathsDefaultValue ? "FULL" : "SHORT";
    }
    this.onlyFullPracticePathsFC.setValue(queryParams.pathType === "FULL", { emitEvent: false });

    // Only with ro released video
    let onlyWithNoReleasedVideo:"true"|"false" = queryParams.onlyWithNoReleasedVideo;
    if(onlyWithNoReleasedVideo !== "true" && onlyWithNoReleasedVideo !== "false") {
      onlyWithNoReleasedVideo = new Boolean(this.onlyWithNoReleasedVideoFCDefaultValue).toString() as "true"|"false";
    }
    this.onlyWithNoReleasedVideoFC.setValue(
      onlyWithNoReleasedVideo === "true",
      { emitEvent: false }
    );

    // Only free practice paths
    let onlyFreePracticePaths:"true"|"false" = queryParams.onlyFreePracticePaths;
    if(onlyFreePracticePaths !== "true" && onlyFreePracticePaths !== "false") {
      onlyFreePracticePaths = new Boolean(this.onlyFreePracticePathsFCDefaultValue).toString() as "true"|"false";
    }
    this.onlyFreePracticePathsFC.setValue(
      onlyFreePracticePaths === "true",
      { emitEvent: false }
    );
  }

  /**
   * Gets the actually selected node's name for the selector.
   *
   * @returns the actually selected node's name
   */
  protected getSelectedNodeName():string {
    if(this.nodeFilterFC.value === "*" || this.practicePathsPageDataService.getSelectedCity() == null) {
      return "Összes";
    }

    return this.practicePathsPageDataService.getSelectedCity().nodes.find(
      (node:CityNode) => {
        return node.uuid === this.nodeFilterFC.value
      }
    ).name;
  }

  /**
   * Gets the actually selected zone's name for the selector.
   *
   * @returns the actually selected zone's name
   */
  protected getSelectedZoneName() : string{
    if(this.zoneFilterFC.value === "*" || this.practicePathsPageDataService.getSelectedCity() == null) {
      return "Összes";
    }

    return this.practicePathsPageDataService.getSelectedCity().zones.find(
      (zone:PracticeCityZone) => {
        return zone.uuid === this.zoneFilterFC.value
      }
    ).name;
  }


  protected isAdminResposibleOfCity(practiceCityNameAndResponsibles:PracticeCityNameAndResponsibles):boolean {
    return this.practiceCityService.isAdminContentResposibleOfCity(
      practiceCityNameAndResponsibles.contentResponsibles,
      this.sessionService.getUuid()
    );
  }
}

/**
 * Type for the practice path filtering object.
 */
export type PracticePathFiltering = {
  nameOrUuidContains:string|null;
  editState:PathEditState|null;
  cityUuid:string|null;
  nodeUuid:string|null;
  zoneUuid:string|null;
  onlyFullPracticePaths:boolean|null;
  onlyWithNoReleasedVideo:boolean|null;
  onlyFreePracticePaths:boolean|null;
};

/**
 * Type for the path filtering's query parameters. It stores the strings percent encoded.
 */
export type PracticePathFilteringQueryParams = {
  pathType?:"FULL"|"SHORT";
  editState?:PathEditState|"*";
  nameOrUuidContains?:string;
  nodeUuid?:string;
  zoneUuid?:string;
  onlyWithNoReleasedVideo?:"true"|"false";
  onlyFreePracticePaths?:"true"|"false";
}

export type PracticePathFilteringPathParams = {
  cityUuid:string;
}
