<div id = "statistics-container">
    <div id = "statistics-header">
        <div></div>
        <div>
            <div id = "statistics-title">
                Statisztikák
            </div>
            <div *ngIf = "displayedStatistics && displayedStatistics.calculatedOn" style = "font-size: 12px; text-align: center;">
                Frissítve: {{ displayedStatistics.calculatedOn | date: "YYYY. MM. dd. HH:mm:ss" }}
            </div>
        </div>
        <div>
            <button mat-mini-fab color = "primary" (click) = "handleReloadButtonClick()">
                <mat-icon> cached </mat-icon>
            </button>
        </div>
        <div></div>
    </div>
    <ng-template [ngIf] = "displayedStatistics">
         <mat-form-field appearance='outline'>
            <mat-label>Intervallum</mat-label>
            <mat-select [formControl] = "timeIntervalSelectFC">
                <mat-option *ngFor = "let timeIntervalSelectItem of timeIntervalSelectItems" [value] = "timeIntervalSelectItem.lastNDays">
                    {{ timeIntervalSelectItem.displayedName }}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <!-- Registrations -->
        <div style = "margin-bottom: 50px;" class = "out">
            <div class = "statistics-section-title">
                Regisztrációk
            </div>
            <div style = "margin-bottom: 10px;">
                <b> Összesen: </b> {{ displayedStatistics.registrations.overall }}
            </div>
            <div style = "display: grid; grid-template-columns: 250px 1fr;" class = "chart-container">
                <div style = "height: 200px;">
                    <div style = "text-align: center; font-style: italic;"> Platformok </div>
                    <div echarts [options] = "platformRegistrationsChartOptions" style = "height: 200px;">
                        <!-- Platform registrations chart goes here -->
                    </div>
                </div>
                <div echarts style = "height: 250px; overflow: hidden;"  [options] = "registrationsChartOptions">
                    <!-- Registrations chart goes here -->
                </div>
            </div>
        </div>
        <!-- Purchases -->
        <div style = "margin-bottom: 50px;" class = "out">
            <div class = "statistics-section-title">
                Vásárlások
            </div>
            <div style = "display: grid; grid-template-columns: 340px 20px 340px 20px 340px; margin-bottom: 20px;">
                <mat-card class = "statistics-purchase-card">
                    <div style = "font-size: 16px; font-weight: 500; margin-bottom: 10px;">
                        Összes
                    </div>
                    <div *ngFor = "let packageDefinition of displayedStatistics.packageDefinitions">
                        <span style = "font-weight: 500;"> {{ packageDefinition.displayedName }}: </span> {{ displayedStatistics.purchases.overall.packages[packageDefinition.uuid] }}
                    </div>
                </mat-card>
                <div></div>
                <mat-card class = "statistics-purchase-card">
                    <div style = "font-size: 16px; font-weight: 500;  margin-bottom: 10px;">
                        Aktuális hónap
                    </div>
                    <div *ngFor = "let packageDefinition of displayedStatistics.packageDefinitions">
                        <span style = "font-weight: 500;"> {{ packageDefinition.displayedName }}: </span> {{ displayedStatistics.purchases.actualMonth.packages[packageDefinition.uuid] }}
                    </div>
                </mat-card>
                <div></div>
                <mat-card class = "statistics-purchase-card">
                    <div style = "font-size: 16px; font-weight: 500;  margin-bottom: 10px;">
                        Ma
                    </div>
                    <div *ngFor = "let packageDefinition of displayedStatistics.packageDefinitions">
                        <span style = "font-weight: 500;"> {{ packageDefinition.displayedName }}: </span> {{ displayedStatistics.purchases.actualDay.packages[packageDefinition.uuid] }}
                    </div>
                </mat-card>
            </div>
            <div style = "display: grid; grid-template-columns: 1fr;">
                <div
                    echarts
                    style = "height: 750px; overflow: hidden;"
                    class = "chart-container"
                    [options] = "pruchasesChartOptions"
                ></div>
            </div>

        </div>
        <!-- Fillouts -->
        <div style = "margin-bottom: 50px;" class = "out">
            <div class = "statistics-section-title">
                Megkezdett kitöltések
            </div>
            <div style = "display: grid; grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); font-size: 14px; margin-bottom: 10px;">
                <mat-card *ngFor = "let categoryUuid of categoryUuids" class = "statistics-fillout-card">
                    <div style = "text-align: center; margin-bottom: 5px;"> <b> {{ getCategoryName(categoryUuid) }} </b> </div>
                    <div> <b> Vizsga: </b> {{ displayedStatistics.histories.categories[categoryUuid].exam }} </div>
                    <div> <b> Gyakorlás: </b> {{ displayedStatistics.histories.categories[categoryUuid].practice }} </div>
                    <div> <b> Tematikus: </b> {{ displayedStatistics.histories.categories[categoryUuid].thematic }} </div>
                </mat-card>
            </div>
        </div>

        <!-- Usage -->
        <div style = "margin-bottom: 50px;" class = "out">
            <div class = "statistics-section-title">
                Napi használat
            </div>
            <div style = "margin-bottom: 10px; display: grid; grid-template-columns: 120px 120px;">
                <div>
                    <b> Tegnap: </b> {{ displayedStatistics.usage.yesterday }}
                </div>
                <div>
                    <b> Ma eddig: </b> {{ displayedStatistics.usage.today }}
                </div>
            </div>
            <div style = "display: grid; grid-template-columns: 1fr;">
                <div
                    echarts
                    style = "height: 250px; overflow: hidden;"
                    class = "chart-container"
                    [options] = "usageChartOptions"
                ></div>
            </div>
        </div>
        <!-- Devices -->
        <div>
            <div class = "statistics-section-title">
                Készülék eloszlás
            </div>
            <div echarts [options] = "devicesChartOptions" style = "height: 250px; width: 250px;"></div>
        </div>
    </ng-template>
    <div *ngIf = "!displayedStatistics" style = "width: 100px; margin: 20px auto 0;">
        <mat-spinner></mat-spinner>
    </div>
</div>
