import { PracticeCityZone } from './practice-city-zone';
import { CriticalPoint } from './practice-path';

export class PracticeCity {
  uuid:string;
  city:string;
  countOfExamPath:number;
  nodes:Array<CityNode>;
  criticalPoints:Array<CriticalPoint>;
  contentResponsibles:Array<string>;
  isVisible?:boolean;
  cityNodeUuidsAssigmentToExamThesis:Array<Array<string>>;
  practicePathUuids:Array<string>;
  zones: PracticeCityZone[]
}

export class CityNode {
  uuid:string;
  name:string;
  isFree?:boolean;
  imageUrl?:string;
  isVisible?:boolean;
  isMainNode?: boolean;
  blurhashOfImage?: string;
}

export function practiceCitySorter(practiceCity1:PracticeCity, practiceCity2:PracticeCity):number {
  return practiceCity1.city.localeCompare(practiceCity2.city);
}

export function practiceCityNodeSorter(cityNode1:CityNode, cityNode2:CityNode):number {
  return cityNode1.name.localeCompare(cityNode2.name);
}


/** This is the fields of the PracticeCityBaseInformations type. */
export const practiceCityBaseInformationsFields:Array<keyof PracticeCity> = [
  "uuid", "city", "nodes", "cityNodeUuidsAssigmentToExamThesis", "countOfExamPath", "practicePathUuids", "zones"
];

/**
 * Reduced type or the PracticeCity.
 * NOTE: If this type is changed, modify the `practiceCityBaseInformationsFields` accordingly.
 */
export type PracticeCityBaseInformations = {
  uuid:string;
  city:string;
  nodes:Array<CityNode>;
  cityNodeUuidsAssigmentToExamThesis:Array<Array<string>>;
  countOfExamPath:number;
  practicePathUuids:Array<string>;
  zones: Array<PracticeCityZone>
}
