export enum LogType {
    Simple, Info, Success, Warning, Error
}

export class Log {
    type:LogType;
    date:Date;
    content:string;
}

export class LogsMap {
    container:Map<string, Array<Readonly<Log>>>;

    constructor() {
        this.container = new Map<string, Array<Readonly<Log>>>();
    }

    addLog(key:string, content:string, type:LogType = LogType.Simple):void {
        if(!this.container.has(key)) {
            this.container.set(key, new Array<Readonly<Log>>());
        }
        this.container.get(key).push({ type: type, date: new Date(), content: content });
    }

    getLogs(key:string):ReadonlyArray<Readonly<Log>> {
        if(this.container.has(key)) {
            return this.container.get(key);
        } else {
            this.container.set(key, new Array<Readonly<Log>>());
            return this.container.get(key);
        }
    }

    clearLogs(key:string) {
        this.container.delete(key);
    }
}