import { Component, ElementRef, Injector, OnInit, ProviderToken } from "@angular/core";
import { CategoryService } from "src/app/services/category.service";
import { QuestionGroupService } from "src/app/services/questiongroup.service";
import { QuestionService } from "src/app/services/question.service";
import { FeedbackService } from "src/app/services/feedback.service";
import { StatisticsService } from "src/app/services/statistics.service";
import { PracticeIconService } from "src/app/services/practice-icon.service";
import { isPermissionRequirementsMet, PermissionString } from "src/app/classes/model/permissions";
import { PermissionService } from "src/app/services/common/permission.service";
import { AuthenticationService } from "src/app/services/common/authentication.service";
import { SelfPermissionsService } from "src/app/services/self-permissions.service";
import { RoleGuardService } from "src/app/services/guards/role-guard.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DataCacherService } from "src/app/services/data-cacher-service";
import { AdminUsersBasicInfoService } from "src/app/services/admin-users-basic-info.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {

  actualLoadingAssetName:string;
  actualLoadingAssetIndex:number;

  readonly dataCacherServices:Array<ProviderToken<DataCacherService>> = [
    AdminUsersBasicInfoService,
    CategoryService,
    FeedbackService,
    QuestionService,
    QuestionGroupService,
    StatisticsService,
    PracticeIconService
  ];
  dataCacherServiceInstances:Array<DataCacherService>;

  initializationTryCount:number = 0;
  readonly maxInitializationTryCount:number = 3;

  protected initStatus:DashboardInitStatus;
  protected readonly DashboardInitStatus:typeof DashboardInitStatus = DashboardInitStatus;

  constructor(
    private permissionService:PermissionService,
    private authenticationService: AuthenticationService,
    private selfPermissionsService: SelfPermissionsService,
    private roleGuardService: RoleGuardService,
    private activatedRoute: ActivatedRoute,
    private routerService: Router,
    public elementRef: ElementRef<HTMLElement>,
    private injector:Injector
  ) {}

  public ngOnInit():void {
    // Start the dasboard's initialization
    this.initialize();
  }

  private async initialize():Promise<void> {
    // Increase the initialization try
    ++this.initializationTryCount;

    try {
      // Set the initialization status to "own permission loading"
      this.initStatus = DashboardInitStatus.OwnPermissionLoading;
      // Initialize the admin permissions
      await this.initializeOwnPermissions();

      // Check if the actual route available for the admin
      // If not, he/she will be redirected to the /dashboard route
      await this.checkActualRouteAvailabilityForAdmin();

      // Set the initialization status to "cached data loading"
      this.initStatus = DashboardInitStatus.CachedDataLoading;
      // Load the relevant datas into the cache
      await this.loadDatasIntoCache();
    } catch(error:any) {
      // If we didn't reach the max retry attemps yet
      if(this.initializationTryCount < this.maxInitializationTryCount) {
        // Put a warining on the console with the happened error
        console.warn("An error occured during the dashboard's initialization. The error was:");
        console.error(error);
        console.warn("Retrying...");
        // Try the initialization again
        // Note: this can't throw an exception
        await this.initialize();
        return;
      }

      // If error occured during the initialization (after the maximum retry attempts)
      // Print the error to the console (for debug)
      console.error("An error occured during the dashboard's initialization.");
      console.error(error);

      // Set the init status to the appropriate error state
      if((error as DashboardInitError).initStatus) {
        this.initStatus = error.initStatus;
      } else {
        this.initStatus = DashboardInitStatus.OtherError;
      }

      return;
    }

    // At this point everything was fine so the dasboard is ready to show the content
    this.initStatus = DashboardInitStatus.Ready;
  }

  /**
   * Initializes (or actualizes on reload) the admin's own permissions
   */
  private async initializeOwnPermissions():Promise<void> {
    try {
      await this.selfPermissionsService.updateSelfPermissions();
    } catch(error:any) {
      throw new DashboardInitError(DashboardInitStatus.OwnPermissionError, error);
    }
  }

  /**
   * Checks if the actual target route is available for the admin user. If not, he/she will be redirected to a 'safe' route.
   */
  private async checkActualRouteAvailabilityForAdmin() {
    const isActualRouteCanActivate:boolean = this.roleGuardService.canActivate(
      this.activatedRoute.snapshot,
      this.routerService.routerState.snapshot
    );
    if(!isActualRouteCanActivate) {
      await this.routerService.navigate(["/dashboard"]);
    }
  }

  /**
   * Loads the defined datas by the `dataCacherServices` member property into the services' cache.
   */
  private async loadDatasIntoCache():Promise<void> {
    // Get the admin's actual permissions
    const userPermissions:Array<PermissionString> = this.permissionService.getLoggedUserPermissions();

    // Get the services' instances and filter out the ones thoose the admin has no permission for
    this.dataCacherServiceInstances = this.dataCacherServices.map(
      (dataCacherService:ProviderToken<DataCacherService>) => {
        return this.injector.get(dataCacherService);
      }
    ).filter(
      (dataCacherService:DataCacherService) => {
        return isPermissionRequirementsMet(userPermissions, dataCacherService.getRequiredPermissionsForDataLoading());
      }
    );

    // Iterate over the service instances, and load the datas into the cache with them
    for(let index:number = 0; index < this.dataCacherServiceInstances.length; ++index) {
      this.actualLoadingAssetName = this.dataCacherServiceInstances[index].getNameOfCachedData();
      this.actualLoadingAssetIndex = index;
      try {
        await this.dataCacherServiceInstances[index].loadDataIntoCache();
      } catch(error:any) {
        throw new DashboardInitError(DashboardInitStatus.CachedDataLoadingError, error);
      }
    }
  }

  /**
   * Handles the reload button click on the dashboard's error state. It relaunches the initialization with
   * the retries refreshed.
   */
  protected async onReloadButtonClick():Promise<void> {
    // Set the retry attempt back to the starting value
    this.initializationTryCount = 0;
    // Relaunch the initialization
    await this.initialize();
  }

  /**
   * Handles the back stepping to the login page from the dashboards error state. It logs out the user.
   */
  protected async onBackToLoginPageButtonClick():Promise<void> {
    await this.authenticationService.logout();
  }

}

enum DashboardInitStatus {
  OwnPermissionLoading,
  CachedDataLoading,
  Ready,
  OwnPermissionError,
  CachedDataLoadingError,
  OtherError
}

class DashboardInitError {
  error:any;
  initStatus:DashboardInitStatus;

  constructor(initStatus:DashboardInitStatus, error:any) {
    this.initStatus = initStatus;
    this.error = error;
  }
}