import { Injectable } from '@angular/core';
import { BackendService } from "./common/backend.service";
import { HttpErrorHandlerService } from "./common/http-error-handler.service";

@Injectable({
  providedIn: 'root'
})
export class EntityAccessCheckService {
  private readonly entityAccessCheckApiUrlFragment:string = "/api/admin/entity_access_check";

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) { }

  public async checkEntityAccess(entityType:string, entityKeys:any):Promise<boolean> {
    let hasAccess:boolean = false;

    const httpBody:Object = {
      entityType: entityType,
      entityKeys: entityKeys
    };

    try {
      const result:{ hasAccess:boolean } = await this.backendService.callApi(
        this.entityAccessCheckApiUrlFragment,
        "POST",
        httpBody
      );

      hasAccess = result.hasAccess;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az entitáshoz való jogosultság lekérdezése közben.");
    }

    return hasAccess;
  }
}
