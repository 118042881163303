<table>
  <tr>
    <td>geoTaggedFrame count</td>
    <td>
      {{ currentLoadedMetadata.geoTaggedFramesCount }}
    </td>
  </tr>

  <tr>
    <td>Total frame count</td>
    <td>
      {{ currentLoadedMetadata.frameCount }}
    </td>
  </tr>

  <tr>
    <td>Total video duration</td>
    <td>{{ currentLoadedMetadata.durationInMs }}ms</td>
  </tr>

  <tr>
    <td>frameRate</td>
    <td>
      {{ currentLoadedMetadata.frameRateNumerator }} /  {{ currentLoadedMetadata.frameRateDenumerator }}
    </td>
  </tr>

  <tr>
    <td>width x height</td>
    <td>
      {{ currentLoadedMetadata.widthPixel }} x
      {{ currentLoadedMetadata.heightPixel }}
    </td>
  </tr>
</table>
