import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MenuPoint, SubMenuPoint } from 'src/app/classes/menu';
import { isPermissionRequirementsMet, PermissionString } from 'src/app/classes/model/permissions';
import { matchingPropertyPredicate } from 'src/app/functions/misc';
import { PermissionService } from '../common/permission.service';
import { MenuService } from '../menu.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService  {

  constructor(
    private menuService:MenuService,
    private permissionService:PermissionService
  ) { }

  public canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):boolean {
    const userPermissions:Array<PermissionString> = this.permissionService.getLoggedUserPermissions();

    const queriesPosition:number = state.url.indexOf("?");
    const urlWithoutQuery:string = state.url.slice(0, queriesPosition === -1 ? state.url.length : queriesPosition);

    const routeFragments:Array<string> = urlWithoutQuery.split("/");
    const dashboardAt:number = routeFragments.findIndex(fragment => fragment === "dashboard");

    if(routeFragments.length < 2 || dashboardAt < 0) {
      return false;
    }

    const menuPoint:MenuPoint = this.menuService.menu.menuPoints.find(matchingPropertyPredicate("route", routeFragments[dashboardAt + 1])) ?? null;
    if(menuPoint === null) {
      return false;
    }

    const subMenuPoint:SubMenuPoint = menuPoint.subMenuPoints.find(matchingPropertyPredicate("subroute", routeFragments[dashboardAt + 2])) ?? null;
    if(subMenuPoint === null || !isPermissionRequirementsMet(userPermissions, subMenuPoint.requiredPermissions)) {
      return false;
    }

    return true;
  }

  
}
