<!-- mat form field for zone name input -->

<div *ngIf="this.currentState == 'SHOW_ADD_ZONE_BUTTON_STATE'">
  <button
    style="background: linear-gradient(to bottom right, #ef4765, #ff9a5a)"
    color="primary"
    mat-raised-button
    (click)="clickAddNewZone.emit()"
  >
    <mat-icon>add_box</mat-icon>
    Új zóna hozzáadása
  </button>
</div>

<div *ngIf="this.currentState == 'ADDING_NEW_ZONE_STATE'">
  <div style="display: flex; align-items: center">
     <mat-form-field appearance='outline'>
      <input
        (keyup.enter)="onTapSaveZone()"
        matInput
        placeholder="Új zóna neve"
        [(ngModel)]="zoneName"
      />
    </mat-form-field>
    <div
      [style.background-color]="this.newZoneColor"
      style="margin-left: 5px; width: 22px; height: 22px; border-radius: 50%"
    ></div>
  </div>

  <div>
    <button
      mat-raised-button
      color="primary"
      style="margin-right: 10px"
      (click)="onTapSaveZone()"
    >
      Hozzáadás
    </button>
    <button mat-raised-button (click)="clickCancelAddNewZone.emit()">
      Elvetés
    </button>
  </div>
</div>
