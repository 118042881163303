import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import Hls from 'hls.js';
import { ReleasedVideo } from 'src/app/classes/model/practice-video';

@Component({
  selector: 'app-released-videos-tab',
  templateUrl: './released-videos-tab.component.html',
  styleUrls: ['./released-videos-tab.component.scss']
})
export class ReleasedVideosTabComponent implements OnInit, OnChanges, OnDestroy {
  @Input() releasedVideo:ReleasedVideo;

  @ViewChild("video", { static: true }) videoElement:ElementRef<HTMLVideoElement>;

  readonly hls:Hls = new Hls();
  displayedVideoHlsUrl:string|null = null;

  constructor() { }

  public ngOnInit(): void {}

  public ngOnDestroy():void {
    this.hls.destroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If there is no released video or the displayed video is the same as the released video
    if(this.releasedVideo == null || this.displayedVideoHlsUrl === this.releasedVideo.videoHlsUrl) {
      // There is nothing to do
      return;
    }
    
    // If there is a displayed video
    if(this.displayedVideoHlsUrl) {
      // Deatch the actual one
      this.hls.detachMedia();
    }

    // Init the (hls) video
    this.initializeHlsVideo();
  } 
  

  /**
   * Inititializes the HLS video and attaches it to the video element.
   */
  initializeHlsVideo():void {
    this.hls.loadSource(this.releasedVideo.videoHlsUrl);
    this.hls.attachMedia(this.videoElement.nativeElement);
    this.displayedVideoHlsUrl = this.releasedVideo.videoHlsUrl;
  }

}
