import { Injectable } from "@angular/core";
import { toast } from "ngx-sonner";

export type NotifierMessageType = "error" | "success" | "info" | "warning";

@Injectable()
export class NotifierService {
  constructor() { }

  public notify(type: NotifierMessageType, message: string) {

    switch(type){
      case "info":
        toast.info(message);
        break;
      case "success":
        toast.success(message);
        break;
      case "warning":
        toast.warning(message);
        break;
      case "error":
        toast.error(message);
        break;
      default:
        toast.info(message);
        break;
    }
    console.log(`Type: ${type}, Message: ${message}`);
  }
}