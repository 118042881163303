import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Vendor } from '../../../../../../classes/model/vendor';
import { PackageGrouperService } from './services/package-grouper.service';
import { Package } from 'src/app/classes/model/packages/Package';

export type VendorInfoDialogData = {
  vendor:Vendor,
  vendorPackages: Array<Package>
};

@Component({
  selector: 'app-vendor-details-dialog',
  templateUrl: './vendor-details-dialog.component.html',
  styleUrls: ['./vendor-details-dialog.component.scss'],
  providers: [
    PackageGrouperService
  ]
})
export class VendorDetailsDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:VendorInfoDialogData
  ) { }

  ngOnInit(): void { }

   /**
   * Opens the add vendor details dialog.
   * 
   * @param dialogService the dialog servce
   * @param dialogData the dialog input (vendor)
   * 
   * @returns refernece to the opened dialog
   */
  public static open(dialogService:MatDialog, dialogData:VendorInfoDialogData):MatDialogRef<VendorDetailsDialogComponent, void> {
    return dialogService.open<
      VendorDetailsDialogComponent,
      VendorInfoDialogData,
      void
    >(
      VendorDetailsDialogComponent,
      {
        data: dialogData,
        minHeight: "400px",
        maxHeight: "750px",
        height: "70vh",
        disableClose: true
      }
    );
  }
}
