<app-page-header
    title = "Vizsgahelyszínek"
    subtitle = "A forgalmi felkészítő modulhoz tartozó vizsgahelyszíneket szerkesztheted itt."
    style = "margin-bottom: 20px;"
></app-page-header>
<ng-template [ngIf] = "!isContentLoading">
    <app-practice-city-card
        *ngFor = "let practiceCity of practiceCities"
        [practiceCity] = "practiceCity"
        (cityChanged)="onCityChanged()"
    ></app-practice-city-card>

    <div *ngIf = "practiceCities.length === 0" class = "no-available-cities">
        Nincsenek számodra elérhető vizsgahelyszínek!
    </div>
</ng-template>
<div *ngIf = "isContentLoading">
    <mat-spinner></mat-spinner>
</div>
<div *ngIf = "userCanEdit" class = "add-button-container">
    <button mat-fab color = "primary" (click) = "handleAddButtonClick()">
        <mat-icon> add </mat-icon>
    </button>
</div>