<div class = "title-row">
    <div class = "title">
        {{ title }}
    </div>
    <div class = "page-actions">
        <ng-content></ng-content>
    </div>
</div>
<div *ngIf = "subtitle" class = "subtitle">
    {{ subtitle }}
</div>