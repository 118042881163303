<app-page-header
    title = "Anonimizációs folyamatok"
    subtitle = "A forgalmi felkészítő modulhoz tartozó videók aktuálisan futó anonimizációs folyamatait találhatod itt."
    style = "margin-bottom: 20px;"
></app-page-header>

<ng-container
    *ngIf = "initState === AnonymizerTasksPageInitialization.Ready;
        then contentReady;
        else contentLoadingOrError"
></ng-container>

<!--  Loading and error guards -->
<ng-template #contentLoadingOrError>
    <div [ngSwitch] = "initState">
        <div *ngSwitchCase = "AnonymizerTasksPageInitialization.Loading">
            <mat-spinner
                style = "margin: 50px auto 0;"
            ></mat-spinner>
            <div style = "margin-top: 20px; text-align: center;">
                Anonimizációs folyamatok betöltése...
            </div>
        </div>
        <div *ngSwitchCase = "AnonymizerTasksPageInitialization.Error">
            <div>
                Hiba történt az anonimizációs folyamatok betöltésekor. Kattints az alábbi gombra az újrapróbálkozáshoz.
            </div>
            <div style = "margin-top: 10px;">
                <button
                    mat-raised-button
                    color = "primary"
                    (click) = "initializeAnonymizerTasks()"
                >
                    <mat-icon> refresh </mat-icon>
                    Újratöltés
                </button>
            </div>
        </div>

    </div>

</ng-template>

<!-- Content -->
<ng-template #contentReady>
    <div class = "select-and-sync-button-container">
        <div>
             <mat-form-field appearance='outline'>
                <mat-label>Státusz</mat-label>
                <mat-select [formControl]="taskStatusSelectFC">
                    <mat-option [value] = "false"> Aktív folyamatok </mat-option>
                    <mat-option [value] = "true"> Befejezett folyamatok </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div style = "display: flex; column-gap: 10px;">
            <button
                mat-raised-button
                color = "primary"
                (click) = "onAddSlaveButtonClick()"
                [disabled] = "slaveAdditionInProgress"
                [class.button-loading] = "slaveAdditionInProgress"
            >
                <mat-icon> add_to_queue </mat-icon>
                Slave hozzáadása
            </button>
            <button
                mat-raised-button
                color = "primary"
                (click) = "onSynchronizeButtonClick()"
                [disabled] = "manualSyncInProgress"
                [class.button-loading] = "manualSyncInProgress"
            >
                <mat-icon> sync </mat-icon>
                Szinkronizálás
            </button>
        </div>
    </div>

    <div class = "table-above">
        <div class = "last-updated-on-container">
            Utoljára frissítve: {{ lastUpdatedOn | date: "YYYY-MM-dd HH:mm:ss" }}
        </div>
        <div>
            <mat-paginator
                [pageSize] = "anonymizerTasksPageService.pageSize"
                [length] = "(anonymizerTasksPageService.anonymizerTasks$ | async).length"
            ></mat-paginator>
        </div>
    </div>

    <app-anonymizer-tasks-table
        *ngIf = "(anonymizerTasksPageService.anonymizerTasks$ | async).length > 0"
    ></app-anonymizer-tasks-table>

    <div
        *ngIf = "(anonymizerTasksPageService.anonymizerTasks$ | async).length === 0"
    >
        Jelenleg nincs egyetlen megjeleníthető anonimizációs folyamat sem.
    </div>
</ng-template>

