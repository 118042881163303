import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-overlay',
  templateUrl: './icon-overlay.component.html',
  styleUrls: ['./icon-overlay.component.scss']
})
export class IconOverlayComponent implements OnInit {
  @Input()
  iconUrls!: string[];

  @Input()
  iconContainerPosition: "left"|"right"|"top" = "left";

  @Input()
  iconsAlignment: "center" | "start" = "start";


  // css osztályok a különböző alignment-hez
  positionMapToClass = {
    left : "leftIconAlignmentClass",
    right : "rightIconAlignmentClass",
    top : "topIconAlignmentClass"
  };

  alignmentMapToClass = {
    center : 'flexAlignmentCenter',
    start : 'flexAlignmentStart'
  };

  constructor() { }

  ngOnInit(): void {

  }
}
