import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PackageDefinition } from 'src/app/modules/package-definitions-page/models/package-definitions.model';
import { PackageDefinitionService } from 'src/app/services/package-definition.service';

@Component({
  selector: 'app-package-definition-selector',
  templateUrl: './package-definition-selector.component.html',
  styleUrls: ['./package-definition-selector.component.scss']
})
export class PackageDefinitionSelectorComponent implements OnInit {
  @Input() formControl:FormControl = new FormControl();
  @Input() includeInactive:boolean = false;

  packageDefinitions:ReadonlyArray<PackageDefinition>;

  constructor(
    private packageDefinitionService:PackageDefinitionService
  ) { }

  public ngOnInit():void {
    this.packageDefinitions = this.packageDefinitionService.getPackageDefinitionsArrayRef();
    this.loadPackageDefinitions();
  }

  private async loadPackageDefinitions():Promise<void> {
    await this.packageDefinitionService.loadDataIntoCache();

    if(!this.includeInactive) {
      this.packageDefinitions = this.packageDefinitions.filter(
        (packageDefinition:PackageDefinition) => {
          return packageDefinition.isActive;
        }
      )
    }
  }

}
