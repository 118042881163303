import { PracticePathVideoEditPageModule } from './../practice-path-video-editor-page/practice-path-video-editor-page.module';
import { MrvegasMetadataViewerPageComponent } from "./mrvegas-metadata-viewer-page.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MrvegasMetadataViewerRoutingModule } from "./mrvegas-metadata-viewer-routing.module";
import { PracticePathPointsMapViewerComponent } from './components/practice-path-points-map-viewer/practice-path-points-map-viewer.component';
import { MetadataTableComponent } from './components/metadata-table/metadata-table.component';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { SharedComponentsModule } from '../shared-module/shared-module.module';

@NgModule({
  declarations: [MrvegasMetadataViewerPageComponent, PracticePathPointsMapViewerComponent, MetadataTableComponent],
  imports: [
    CommonModule,
    MrvegasMetadataViewerRoutingModule,
    NgxDropzoneModule,
    PracticePathVideoEditPageModule,
    SharedComponentsModule
  ],
  exports: [MrvegasMetadataViewerPageComponent],
})
export class MrvegasMetadataViewerModule {}
