import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Vendor } from 'src/app/classes/model/vendor';
import { Permission } from 'src/app/classes/model/permissions';
import { AdminPersonalGiftService, PersonalGift } from 'src/app/services/admin-personal-gift.service';
import { VendorService } from 'src/app/services/vendor.service';
import { StudentDetailsDialogComponent, StudentDetailsDialogData } from '../../users/students/student-details-dialog/student-details-dialog.component';
import { CreatePersonalGiftViaEmailDialogComponent } from './create-personal-gift-via-email-dialog/create-personal-gift-via-email-dialog.component';
import { VendorDetailsDialogComponent } from '../../users/vendors/vendor-details-dialog/vendor-details-dialog.component';
import { PermissionService } from 'src/app/services/common/permission.service';

@Component({
  selector: 'app-personal-gifts',
  templateUrl: './personal-gifts.component.html',
  styleUrls: ['./personal-gifts.component.scss']
})
export class PersonalGiftsComponent implements OnInit {

  handedOutGifts:ReadonlyArray<PersonalGift> = [];

  isContentLoading:boolean = false;

  emojiSrc = {
    "student": "student.png",
    "vendor": "money-mouth-face.png",
    "email": "email.png"
  };

  canViewVendorInfo:boolean = true;
  canViewStudentInfo:boolean = true;

  constructor(
    private dialogService:MatDialog,
    private adminPersonalGiftService:AdminPersonalGiftService,
    private permissionService:PermissionService,
    private vendorService:VendorService,
    private snackBarService:MatSnackBar
  ) { }

  public ngOnInit():void {
    this.canViewStudentInfo = this.permissionService.isLoggedUserHasPermission(Permission.StudentsRead);
    this.canViewVendorInfo = this.permissionService.isLoggedUserHasPermission(Permission.VendorRead);

    this.handedOutGifts = this.adminPersonalGiftService.getHandedOutGiftsArrayRef();
    this.loadHandedOutGifts();
  }

  private async loadHandedOutGifts():Promise<void> {
    this.isContentLoading = true;

    try {
      await this.adminPersonalGiftService.loadDataIntoCache();
    } catch(error:any) {}

    this.isContentLoading = false;
  }

  public openCreatePersonalGiftViaEmailDialog():void {
    const dialogRef:MatDialogRef<CreatePersonalGiftViaEmailDialogComponent> = this.dialogService.open(
      CreatePersonalGiftViaEmailDialogComponent,
      {
        disableClose: true
      }
    );

    const dialogClosedSubscription:Subscription = dialogRef.afterClosed().subscribe(
      async () => { 
        dialogClosedSubscription.unsubscribe();
        await this.loadHandedOutGifts();
      }
    );
  }

  public async openInfoDialog(gift:PersonalGift):Promise<void> {
    switch(gift.entityType) {
      case "student":
        if(!this.canViewStudentInfo) {
          return;
        }
        
        const dialogRef:MatDialogRef<StudentDetailsDialogComponent> = this.dialogService.open<
          StudentDetailsDialogComponent,
          StudentDetailsDialogData
        >(
          StudentDetailsDialogComponent,
          {
            data: {
              studentUuid: gift.receiverId
            }
          }
        );
        const dialogClosedSubscription:Subscription = dialogRef.afterClosed().subscribe(
          async () => { 
            dialogClosedSubscription.unsubscribe();
            await this.loadHandedOutGifts();
          }
        );

        break;

      case "vendor":
        if(!this.canViewVendorInfo) {
          return;
        }

        try {
          const vendors:ReadonlyArray<Vendor> = await this.vendorService.fetchVendors();
          const vendor:Vendor|undefined = vendors.find(vendor => vendor.uuid === gift.receiverId);

          const vendorPackages = await this.vendorService.fetchVendorPackages(gift.receiverId);
          
          if(vendor) {
            const dialogRef:MatDialogRef<VendorDetailsDialogComponent> = VendorDetailsDialogComponent.open(
              this.dialogService,
              { vendor: vendor, vendorPackages: vendorPackages  }
            );
            const dialogClosedSubscription:Subscription = dialogRef.afterClosed().subscribe(
              async () => { 
                dialogClosedSubscription.unsubscribe();
                await this.loadHandedOutGifts();
              }
            );
          } else {
            this.snackBarService.open("Nincs ilyen viszonteladó!", "Bezár", { duration: 3000 });
          }

        } catch(error:any) {}

        break;
    }
  }

}
