<div class="icon-recorder-main-container">
  <div
    class="icon-recording-row"
    *ngFor="let recording of this.iconRecorderService.getAllRecording()"
  >
    <!-- Icon -->
    <img
      class="icon"
      style="width: 40px; height: 40px; margin-right: 15px"
      [src]="recording.practiceIconUrl"
    />


    <!-- Kezdet és vég  -->
    <div style="display:inline-block;min-width:85px;">
      <span (click)="onTapGeoTaggedIndex.next(recording.startGeoTaggedFrameIndex)" class="valid-time">
        {{ recording.startGeoTaggedFrameIndex }}
      </span>
      <span style="white-space: pre-wrap"> - </span>
      <span
        (click)="onTapGeoTaggedIndex.next(recording.endGeoTaggedFrameIndex)"
        style="margin-right: 20px"
        [class]="
          recording.endGeoTaggedFrameIndex >= recording.startGeoTaggedFrameIndex
            ? 'valid-time'
            : 'invalid-time'
        "
      >
        {{ recording.endGeoTaggedFrameIndex }}
      </span>
    </div>


    <!-- Mentés gomb -->
    <button
    mat-raised-button
    class="save-button"
    color="primary"
      (click)="onTapSaveRecording(recording.practiceIconUrl)"
      style="margin-right:25px;"
    >
      Mentés
    </button>

    <!-- Visszavonás gomb -->
    <span
    class="cancel-button"
      (click)="onCancelRecording(recording.practiceIconUrl)"
      style="font-weight: bold; color: black; cursor: pointer"
    >
      Visszavonás
    </span>
  </div>
</div>
