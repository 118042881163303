<app-page-header
    title = "Classroom oktatók"
    subtitle = "A Mr. Kresz Classroom rendszerébe regisztrált oktatók listáját találhatod és szerkesztheted itt."
    style = "margin-bottom: 20px;"
></app-page-header>

<ng-template [ngIf] = "!isContentLoading">
    <app-instructor-card
        *ngFor = "let instructor of instructors"
        [instructor] = "instructor"
    ></app-instructor-card>
</ng-template>
<div *ngIf = "isContentLoading" class = "loading-container">
    <mat-spinner></mat-spinner>
</div>
<div class = "add-button" (click) = "handleAddIstructorButtonClick()">
    +
</div>