import { Injectable } from '@angular/core';
import { AnonymizerTask, AnonymizerTaskReference } from '../classes/model/anonymizer-task';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class AnonymizerTaskService {
  private readonly listAnonymizationTasksApiPath:string = "/api/admin/practice_path/list_active_practice_path_anonymization_task";
  private readonly syncAnonymizedVideosApiPath:string = "/api/admin/practice_path/sync_anonymized_videos";
  private readonly anonymizingTasksApiPath:string = "/api/admin/anonymizer/tasks";

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) { }

  /**
   * Fetches all anonyimzer tasks references of a given type (actice or finished).
   *
   * @param isFinished the type of the anonymizer task reference
   *
   * @returns array of the resulting anonyimizer task references
   */
  public async fetchAnonymizerTaskReferences(isFinished:boolean):Promise<Array<AnonymizerTaskReference>> {
    const apiPath:string = `${this.listAnonymizationTasksApiPath}?isFinished=${isFinished ? "true" : "false"}`;

    let result:Array<AnonymizerTaskReference> = new Array<AnonymizerTaskReference>();

    try {
      const response:{ anonymizerTaskReferences:Array<AnonymizerTaskReference> } = await this.backendService.callApi(apiPath, "GET");
      result = response.anonymizerTaskReferences;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az anonimizáló folyamatok lekérdezése közben.");
    }

    return result;
  }

  /**
   * Fetches a specific anonymizer task by it's id.
   *
   * @param anonymizerTaskId the id of the anonymizer task
   *
   * @returns the requested anonyimizer task
   */
  public async fetchAnonymizerTask(anonymizerTaskId:string):Promise<AnonymizerTask> {
    const apiUrlFragment:string = `${this.anonymizingTasksApiPath}/${anonymizerTaskId}`;

    try {
      const response:{ task:AnonymizerTask } = await this.backendService.callApi(apiUrlFragment, "GET");
      return response.task;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az anonimizáló folyamat állapotának lekérdezése közben");
    }
  }

  /**
   * Syncs the anonymized videos on the backend.
   */
  public async syncAnonymizedVideos():Promise<void> {
    try {
      await this.backendService.callApi(this.syncAnonymizedVideosApiPath, "POST",undefined,undefined,undefined,1000000000);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az anonimizált videók szinkronizálása közben.");
    }
  }

}
