import { Injectable } from '@angular/core';
import { HttpResponseData } from '../classes/http-communication';
import { MaintenanceStatus } from '../classes/model/maintenance';
import { Permission, PermissionString } from '../classes/model/permissions';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  private readonly maintenanceApiUrlFragment:string = "/api/admin/server/maintenance"

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.ServerManager ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) {}

  public async fetchMaintenanceStatus():Promise<MaintenanceStatus> {
    let maintenanceStatus:MaintenanceStatus;

    try {
      const response:HttpResponseData<MaintenanceStatus> = await this.backendService.callApi(this.maintenanceApiUrlFragment, "GET");
      maintenanceStatus = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a karbantartási állapot lekérdezése közben.");
    }

    return maintenanceStatus;
  }

  public async setMaintenanceStatus(newMaintenanceStatus:MaintenanceStatus):Promise<void> {
    try {
      await this.backendService.callApi(this.maintenanceApiUrlFragment, "POST", { data: newMaintenanceStatus });
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a karbantartási állapot beállítása közben.");
    }
  }

}
