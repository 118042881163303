import { PracticeIcon } from 'src/app/classes/model/practice-icons';
import { PracticePathGlobalVideoEditorPageService } from '../../../../services/practice-path-global-video-editor-page.service';
import { TimedIcon } from './../../../../../../classes/model/practice-path';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-timed-icon-card',
  templateUrl: './timed-icon-card.component.html',
  styleUrls: ['./timed-icon-card.component.scss']
})
export class TimedIconCardComponent implements OnInit {
  @Input()
  timedIcon: TimedIcon;


  // Ha aktív akkor kijelöljü a kártyát
  // (módosítjuk a hátterét)
  @Input()
  isActive: boolean = false;

  @Output()
  onTapTime = new EventEmitter<number>();

  @Output()
  onTapDelete = new EventEmitter<void>();

  @Output()
  onTapEdit = new EventEmitter<void>();

  // timedIcon.practiceIconUuid alapján
  resolvedPracticeIcon: PracticeIcon|undefined = undefined;

  constructor(private globalPracticePathEditorService: PracticePathGlobalVideoEditorPageService) { }

  ngOnInit(): void {
    this.resolveIcon();
  }

  private resolveIcon(){
    this.resolvedPracticeIcon = this.globalPracticePathEditorService.practiceIcons.find(pi => pi.uuid == this.timedIcon.practiceIconUuid);
  }


}
