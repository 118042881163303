export class KeyEventHelper {
  // Le van-e nyomva a ctrl billentyű
  static isCtrlDown = false;
  static isShiftDown = false;
}

window.onkeydown = function (e: any) {
  KeyEventHelper.isCtrlDown =
    e.keyIdentifier == "Control" || e.ctrlKey == true || e.key == "Alt";
  KeyEventHelper.isShiftDown = e.keyIdentifier == "Shift" || e.shiftKey == true;
};

window.onkeyup = function (e: any) {
  if (e.keyIdentifier == "Control" || e.key == "Alt") {
    KeyEventHelper.isCtrlDown = false;
  }

  if (e.keyIdentifier == "Shift" || e.key == "Shift") {
    KeyEventHelper.isShiftDown = false;
  }
};
