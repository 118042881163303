import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AppNotification } from 'src/app/classes/model/app-notification';
import { AppNotificationService } from 'src/app/services/app-notification.service';

@Component({
  selector: 'app-create-notification-dialog',
  templateUrl: './create-notification-dialog.component.html',
  styleUrls: ['./create-notification-dialog.component.scss']
})
export class CreateNotificationDialogComponent implements OnInit, OnDestroy {

  appNotificationFG:FormGroup;
  titleFC:FormControl;
  messageFC:FormControl;
  routeFC:FormControl;
  typeFC:FormControl; // True if the type is "all", false otherwise
  userUuidFC:FormControl;

  typeSubscription:Subscription;

  constructor(
    private appNotificationService:AppNotificationService,
    private snackBar:MatSnackBar
  ) { }

  ngOnInit(): void {
    this.titleFC = new FormControl("", [ Validators.required ]);
    this.messageFC = new FormControl("", [ Validators.required ]);
    this.routeFC = new FormControl("", [ Validators.required ]);
    this.typeFC = new FormControl(false);
    this.userUuidFC = new FormControl("", [ Validators.required ]);

    this.appNotificationFG = new FormGroup({
      titleFC: this.titleFC,
      messageFC: this.messageFC,
      routeFC: this.routeFC,
      typeFC: this.typeFC,
      userUuidFC: this.userUuidFC,
    });

    this.typeSubscription = this.typeFC.valueChanges.subscribe(
      newValue => {
        if(!newValue) {
          this.userUuidFC.enable();
        } else {
          this.userUuidFC.setValue("");
          this.userUuidFC.disable();
        }
      }
    );
  }

  public ngOnDestroy():void {
    this.typeSubscription.unsubscribe();
  }

  public async handleCreateNotificationButtonClick():Promise<void> {
    const newAppNotification:Partial<AppNotification> = {
      title: this.titleFC.value as string,
      message: this.messageFC.value as string,
      route: this.routeFC.value as string,
      type: (this.typeFC.value as boolean) ? "all" : "single",
      userUuid: this.userUuidFC.value as string
    }

    try {
      await this.appNotificationService.createNotification(newAppNotification);
      this.snackBar.open("Értesítés sikeresen létrehozva.", "Bezár", { duration: 3000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}
  }
  

}
