import { Injectable } from '@angular/core';
import { BackendService, HttpRequestMethod } from './common/backend.service';
import { Statistics } from '../classes/model/statistics';
import { DisplayedStatistics } from '../classes/model/displayed-statistics';
import { LineChartData } from '../classes/charts';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { HttpResponseData } from '../classes/http-communication';
import { DataCacherService } from './data-cacher-service';
import { matchingPropertyPredicate } from '../functions/misc';
import { Permission, PermissionString } from '../classes/model/permissions';
import { EnvironmentService } from './common/environments/environment.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService implements DataCacherService {
  private statistics:Statistics|null;
  private displayedStatistics:DisplayedStatistics|null;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.StatisticsRead ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService,
    private environmentService:EnvironmentService
  ) {
    this.statistics = null;
    this.displayedStatistics = null;
  }

  public getStatisticsRef():Readonly<Statistics> {
    return this.statistics;
  }

  public getDisplayedStatisticsRef():Readonly<DisplayedStatistics> {
    return this.displayedStatistics;
  }

  public async fetchStatistics(recalculate:boolean = false):Promise<Statistics> {
    const apiUrl:string = "/api/admin/statistics" + (recalculate ? "/recalculate" : "");
    const httpRequestMethod:HttpRequestMethod = recalculate ? "POST" : "GET";
    let statistics:Statistics;
    
    try {
      const response:HttpResponseData<Statistics> = await this.backendService.callApi(apiUrl, httpRequestMethod);
      statistics = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a statisztikák lekérdezése közben.");
    }

    return statistics;
  }

  public async calculateStatistics(recalculate:boolean = false):Promise<DisplayedStatistics> {
    if(this.statistics === null || recalculate) {
      this.statistics = await this.fetchStatistics(recalculate);
    }

    const isCalculatedStatisticsFromTheSameServer:boolean
      = this.environmentService.getCurrentEnvironment().serverHost !== this.displayedStatistics?.fromServer
    if(this.displayedStatistics === null || !isCalculatedStatisticsFromTheSameServer) {
      this.displayedStatistics = new DisplayedStatistics(this.statistics.packageDefinitions);
    } else if(!recalculate) {
      return this.displayedStatistics;
    }

    this.displayedStatistics.resetToDefault(this.statistics.packageDefinitions.map(packageDefinition => packageDefinition.uuid));
    this.displayedStatistics.calculatedOn = this.statistics.calculatedOn;
    this.displayedStatistics.fromServer = this.environmentService.getCurrentEnvironment().serverHost;

    const dateNames:Array<string> = new Array<string>();
    let date:Date = new Date(this.statistics.startDate);
    for(let i = 0; i < this.statistics.dailyStatistics.length; ++i) {
      dateNames.push(date.toDateString());
      date.setDate(date.getDate() + 1);
    }

    this.displayedStatistics.initDailyArrays(dateNames, this.statistics.packageDefinitions.map(packageDefinition => packageDefinition.uuid));
    
    this.displayedStatistics.registrations.platforms.push(
      { name: "apple", value: 0 },
      { name: "facebook", value: 0 },
      { name: "google", value: 0 }
    );

    for(const categoryUuid of this.statistics.categoryUuids) {
      this.displayedStatistics.histories.categories[categoryUuid] = {
        exam: 0,
        practice: 0,
        thematic: 0
      };
    }

    date = new Date(this.statistics.startDate);
    const today:Date = new Date();

    for(const [i, dailyStatisticsItem] of this.statistics.dailyStatistics.entries()) {
      // Overall registrations
      this.displayedStatistics.registrations.overall += dailyStatisticsItem.registrations.apple + dailyStatisticsItem.registrations.facebook + dailyStatisticsItem.registrations.google;
    
      // Daily registrations
      this.displayedStatistics.registrations.daily[0].series[i].value += dailyStatisticsItem.registrations.apple + dailyStatisticsItem.registrations.facebook + dailyStatisticsItem.registrations.google;

      // Registations by platform
      for(const chartDataPoint of this.displayedStatistics.registrations.platforms) {
        chartDataPoint.value += dailyStatisticsItem.registrations[chartDataPoint.name];
      }

      // Purchases
      for(const packageName in dailyStatisticsItem.purchases.packages) {
        // Overall
        this.displayedStatistics.purchases.overall.packages[packageName] += dailyStatisticsItem.purchases.packages[packageName].student + dailyStatisticsItem.purchases.packages[packageName].vendor;

        // This month
        if(date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth()) {
          this.displayedStatistics.purchases.actualMonth.packages[packageName] += dailyStatisticsItem.purchases.packages[packageName].student + dailyStatisticsItem.purchases.packages[packageName].vendor;
        }

        // This day
        if(date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate()) {
          this.displayedStatistics.purchases.actualDay.packages[packageName] += dailyStatisticsItem.purchases.packages[packageName].student + dailyStatisticsItem.purchases.packages[packageName].vendor;
        }

        // General
        const lineChartData:LineChartData = this.displayedStatistics.purchases.daily.find(matchingPropertyPredicate("name", packageName));
        if(lineChartData) {
          lineChartData.series[i].value = dailyStatisticsItem.purchases.packages[packageName].student + dailyStatisticsItem.purchases.packages[packageName].vendor;
        }
      }

      // Fillouts
      for(const categoryUuid in dailyStatisticsItem.histories.categories) {
        this.displayedStatistics.histories.categories[categoryUuid].exam += dailyStatisticsItem.histories.categories[categoryUuid].exam;
        this.displayedStatistics.histories.categories[categoryUuid].practice += dailyStatisticsItem.histories.categories[categoryUuid].practice;
        this.displayedStatistics.histories.categories[categoryUuid].thematic += dailyStatisticsItem.histories.categories[categoryUuid].thematic;
      }
      this.displayedStatistics.histories.games.roadSign += dailyStatisticsItem.histories.games.roadSign;
      this.displayedStatistics.histories.games.trueFalse += dailyStatisticsItem.histories.games.trueFalse;

      // Usage
      this.displayedStatistics.usage.daily[0].series[i].value = dailyStatisticsItem.usage;
      
      date.setDate(date.getDate() + 1);
    }
    
    // Usage
    this.displayedStatistics.usage.yesterday = this.statistics.dailyStatistics[this.statistics.dailyStatistics.length - 2]?.usage ?? 0;
    this.displayedStatistics.usage.today = this.statistics.dailyStatistics[this.statistics.dailyStatistics.length - 1]?.usage ?? 0;

    // Devices
    this.displayedStatistics.devices.push(
      { name: "android", value: 0 },
      { name: "ios", value: 0 },
      { name: "mixed", value: 0 },
      { name: "unknown", value: 0 }
    );

    for(const chartDataPoint of this.displayedStatistics.devices) {
      chartDataPoint.value += this.statistics.deviceStatistics[chartDataPoint.name];
    }
    
    return this.displayedStatistics;
  }

  public async loadDataIntoCache(): Promise<void> {
    this.statistics = await this.fetchStatistics();
  }

  public getRequiredPermissionsForDataLoading(): readonly PermissionString[] {
    return this.requiredPermissionsForDataLoading;
  }

  public clearCachedData():void {
    this.statistics = null;
    this.displayedStatistics = null;
  }

  public getNameOfCachedData(): string {
    return "Statisztikák";
  }
  
}
