import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type NotificationDialogType = "success"|"warning"|"error"|"info";

export type NotificationDialogInput = {
  type:NotificationDialogType,
  text:string
}

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data:NotificationDialogInput,
    private elementRef:ElementRef<HTMLElement>
  ) { }

  ngOnInit(): void {
    this.elementRef.nativeElement.classList.add(this.data.type);
  }

  public static open(dialogService:MatDialog, data:NotificationDialogInput):MatDialogRef<NotificationDialogComponent, void> {
    return dialogService.open<
      NotificationDialogComponent,
      NotificationDialogInput,
      void
    >(
      NotificationDialogComponent,
      {
        data: data,
        disableClose: true,
        panelClass: [ "no-padded-dialog" ],
        minWidth: "400px",
        maxWidth: "800px",
        width: "30vw"
      }
    );
  }

}
