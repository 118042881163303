import { Injectable } from '@angular/core';
import { DataCacherService } from './data-cacher-service';
import { Permission, PermissionGroup, PermissionString } from '../classes/model/permissions';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { HttpResponseData } from '../classes/http-communication';
import { matchingPropertyPredicate } from '../functions/misc';

@Injectable({
  providedIn: 'root'
})
export class PermissionGroupsService implements DataCacherService {
  private readonly permissionGroupsApiUrlFragment:string = "/api/admin/permissions/groups";

  private permissionGroups:Array<PermissionGroup>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.ServerManager ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) {
    this.permissionGroups = new Array<PermissionGroup>();
  }

  public getPermissionGroupsArrayRef():ReadonlyArray<PermissionGroup> {
    return this.permissionGroups;
  }

  public async fetchPermissionGroups():Promise<Array<PermissionGroup>> {
    let permissionGroups:Array<PermissionGroup> = [];

    try {
      const response:HttpResponseData<Array<PermissionGroup>> = await this.backendService.callApi(this.permissionGroupsApiUrlFragment, "GET");
      permissionGroups = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a jogosultságok lekérdezése közben.");
    }

    return permissionGroups;
  }

  public async addPermissionGroup(permissionGroup:PermissionGroup):Promise<void> {
    const httpBody:Object = {
      permissionGroup: permissionGroup
    };

    try {
      await this.backendService.callApi(this.permissionGroupsApiUrlFragment, "PUT", httpBody);
      this.permissionGroups.push(permissionGroup);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a jogosultság csoport létrehozásakor.");
    }
  }

  public async deletePermissionGroup(permissionGroupName:string):Promise<void> {
    const apiUrlFragment:string = `${this.permissionGroupsApiUrlFragment}/${permissionGroupName}`;

    try {
      await this.backendService.callApi(apiUrlFragment, "DELETE");
      this.permissionGroups.removeItems(matchingPropertyPredicate("name", permissionGroupName));
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a jogosultság csoport törlésekor.");
    }
  }

  public async modifyPermissionGroup(permissionGroup:PermissionGroup):Promise<void> {
    const httpBody:Object = {
      permissionGroup: permissionGroup
    };

    try {
      await this.backendService.callApi(this.permissionGroupsApiUrlFragment, "POST", httpBody);
      this.permissionGroups.replaceItems(matchingPropertyPredicate("name", permissionGroup.name), permissionGroup);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a jogosultság csoport módosításakor.");
    }
  }

  public async loadDataIntoCache(): Promise<void> {
    const permissionGroups:Array<PermissionGroup> = await this.fetchPermissionGroups();
    this.permissionGroups.replaceArrayElements(permissionGroups);
  }

  public getRequiredPermissionsForDataLoading(): readonly PermissionString[] {
    return this.requiredPermissionsForDataLoading;
  }

  public clearCachedData():void {
    this.permissionGroups.length = 0;
  }

  public getNameOfCachedData(): string {
    return "Jogosultság csoportok";
  }
}
