import { MsToTimeStringPipe } from "./../../../../pipes/ms-to-time-string.pipe";
import { MarkerUtil } from "./../../../practice-path-video-editor-page/components/google-map-extended/marker-util";
import { GoogleMapExtendedController } from "./../../../practice-path-video-editor-page/components/google-map-extended/google-map-controller";
import { WritableObservableArray } from "./../../../practice-path-video-editor-page/models/ObservableArray";
import { UnderlyingMarker } from "./../../../practice-path-video-editor-page/components/google-map-extended/marker-types";
import {
  MarkerClickEvent,
  MarkeredPolylineController,
} from "./../../../practice-path-video-editor-page/components/google-map-extended/markered-polyline-controller";
import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: "app-practice-path-points-map-viewer",
  templateUrl: "./practice-path-points-map-viewer.component.html",
  styleUrls: ["./practice-path-points-map-viewer.component.scss"],
})
export class PracticePathPointsMapViewerComponent implements OnInit, OnChanges, OnDestroy{
  @Input()
  geoTaggedFrames: {
    millisecOfFrame: number;
    position: { latitude: number; longitude: number };
  }[];

  pathPolylineController: MarkeredPolylineController;
  map: GoogleMapExtendedController;

  ngOnChanges() {
    if (this.map == null) return;
    if (this.pathPolylineController != null) {
      this.map.removeMarkeredPolylineController(this.pathPolylineController);
    }

    this.init();
  }

  constructor() {}
  ngOnInit(): void {}


  ngOnDestroy(): void {
    this.map.dispose();
  }

  initPathPolylineController() {
    const array = WritableObservableArray.fromPodArray(
      this.geoTaggedFrames.map((gf) => ({ position: gf.position }))
    );

    this.pathPolylineController = new MarkeredPolylineController(array, {
      displayedName: "Sárga útvonal",
      id: "yellow_path",
      clickable: true,
      isDraggableMarkers: true,
      isMultiSelectable: false,
      maximumVisibleMarkerOnMap: 240,
      showFirstAndLast: true,
      zIndex: 0,
      markerIconStyles: {
        firstMarkerStyle: MarkerUtil.getCircleMarker("black", "black"),
        lastMarkerStyle: MarkerUtil.getCircleMarker("green", "green"),
        globalStyle: MarkerUtil.getCircleMarker(
          "orange",
          "blue",
          undefined,
          new google.maps.Size(26, 26),
          new google.maps.Point(13, 13)
        ),
      },
      polylineColor: "orange",
    });

    this.pathPolylineController.onRightClickMarker.subscribe(
      (markerClickEvent: MarkerClickEvent) => {
        let msOfGeoTaggedFrame = this.geoTaggedFrames[markerClickEvent.underlyingMarkerIndex]
        ?.millisecOfFrame;

        if(msOfGeoTaggedFrame == null){
          console.error("NULL GEO TAGGED FRAME MS");
          msOfGeoTaggedFrame = 0;
        }

        const timeAsStr = new MsToTimeStringPipe().transform(
          msOfGeoTaggedFrame
        );

        const infowindow = new google.maps.InfoWindow({
          content: markerClickEvent.underlyingMarkerIndex.toString() + ": " + timeAsStr,
        });

        infowindow.open({
          map: this.map.map,
          anchor: markerClickEvent.underlyingRenderedMarker,
        });
      }
    );

    this.map.addMarkeredPolylineController(this.pathPolylineController);
  }

  init() {
    this.initPathPolylineController();
    this.map.setCenter(this.geoTaggedFrames[0].position);
  }

  onGoogleMapInited(map: GoogleMapExtendedController) {
    this.map = map;
    this.init();
  }
}
