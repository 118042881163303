import { Injectable } from "@angular/core";
import { PracticeCity } from "src/app/classes/model/practice-city";
import { PracticeCityQueryResult, PracticeCityService } from "src/app/services/practice-city.service";

type StoredPracticeCityFields2 = Partial<PracticeCity>&Pick<PracticeCity, "uuid">;

@Injectable()
export class PracticeCityCacherService {
  // key: practice city uuid - value: CachedPracticeCity
  private cachedPracticeCitysMap:Map<string, CachedPracticeCity>;
  private pendingRequestedPracticeCityUuids:Array<string>;
  private queriedPracticeCityFields:ReadonlyArray<keyof PracticeCity> = [ "uuid", "city" ];

  constructor(
    private practiceCityService:PracticeCityService
  ) {
    this.cachedPracticeCitysMap = new Map<string, CachedPracticeCity>();
    this.pendingRequestedPracticeCityUuids = new Array<string>();
  }

  /**
   * Sets the queried fields value.
   */
  public set queriedFields(fields:ReadonlyArray<keyof PracticeCity>) {
    this.queriedPracticeCityFields = fields;

    // Make sure that the "uuid" is always present
    if(fields.includes("uuid") == false) {
      fields = [ ...fields, "uuid" ];
    }
  }

  /**
   * Determines if the practice city with the given uuid is already cached.
   * 
   * @param practiceCityUuid the target practice city's uuid
   * 
   * @returns true if the practice city is already cached, false otherwise
   */
  public isPracticeCityCached(practiceCityUuid:string):boolean {
    return this.getPracticeCityFromCache(practiceCityUuid) != null;
  }

  /**
   * Gets the practice city with the given uuid from the cache. If the practice city is not cached `null`
   * is returned. 
   * 
   * @param practiceCityUuid the target practice city's uuid
   * 
   * @returns a `CachedPracticeCity` object if the practice city is cached, false otherwise
   */
  public getPracticeCityFromCache(practiceCityUuid:string):CachedPracticeCity|null {
    return this.cachedPracticeCitysMap.get(practiceCityUuid) ?? null;
  }


  /**
   * Gets the practice citys with the given uuids from the cache. If a practice city with the given
   * uuid is not exists in the cache, it will be omitted from the resulting array.
   * 
   * @param practiceCityUuids the target practice citys' uuids
   * 
   * @returns an array of `CachedPracticeCity` objects from the cache
   */
  public getPracticeCitysFromCache(practiceCityUuids:Array<string>):Array<CachedPracticeCity> {
    const result:Array<CachedPracticeCity> = [];

    for(const practiceCityUuid of practiceCityUuids) {
      const CachedPracticeCity:CachedPracticeCity|null = this.getPracticeCityFromCache(practiceCityUuid);
      if(CachedPracticeCity) {
        result.push(CachedPracticeCity);
      }
    }

    return result;
  }

  /**
   * Fetches and caches the practice citys with the given practice city uuids.
   * Note: If the practice city is already in the cache, it will be not fetched from the server.
   * 
   * @param practiceCityUuids array of the practice city uuids to query and cache
   * 
   * @returns an array of `CachedPracticeCity` objects based on the provided `practiceCityUuids` array
   */
   public async fetchAndCachePracticeCitys(practiceCityUuids:Array<string>):Promise<Array<CachedPracticeCity>> {
    // Determine the target practice city uuids
    const targetPracticeCityUuids:Array<string> = practiceCityUuids.filter(
      (practiceCityUuid:string) => {
        // The practice city uuid will be filtered out if
        // - it's already resolved (or determined to be unresolvable)
        // - it's resolution is already in progress
        return this.isPracticeCityCached(practiceCityUuid) == false &&
        this.pendingRequestedPracticeCityUuids.includes(practiceCityUuid) == false;
      }
    );

    // If all practice citys uuids is filtered out, nothing to do
    if(targetPracticeCityUuids.length === 0) {
      return [];
    }

    // Remove the possible duplicates from the target practice city uuids array
    targetPracticeCityUuids.removeDuplicates();

    // Put them in a pending state
    this.pendingRequestedPracticeCityUuids.pushArray(targetPracticeCityUuids);

    try {
      // Fetch the target practice citys
      const practiceCityQueryResult:PracticeCityQueryResult = await this.practiceCityService.fetchPracticeCitySetFields(
        targetPracticeCityUuids, this.queriedPracticeCityFields
      );
      

      practiceCityQueryResult.practiceCities.forEach(
        (practiceCity:PracticeCity) => {
          this.cachedPracticeCitysMap.set(
            practiceCity.uuid,
            {
              practiceCityUuid: practiceCity.uuid,
              isExists: true,
              practiceCity: practiceCity
            }
          );
        }
      );

      practiceCityQueryResult.notFoundPracticeCityUuids.forEach(
        (practiceCityUuid:string) => {
          this.cachedPracticeCitysMap.set(
            practiceCityUuid,
            {
              practiceCityUuid: practiceCityUuid,
              isExists: false
            }
          );
        }
      );


      return this.getPracticeCitysFromCache(practiceCityUuids);
    } catch(error:any) {
      console.error(error);
    } finally {
      // Remove the target practice citys from the pending state
      this.pendingRequestedPracticeCityUuids.removeItems((practiceCityUuid) => targetPracticeCityUuids.includes(practiceCityUuid));
    }
  }
}

export type CachedPracticeCity = {
  practiceCityUuid:string,
  isExists:boolean,
  practiceCity?:StoredPracticeCityFields
}

type StoredPracticeCityFields = Partial<PracticeCity>&Pick<PracticeCity, "uuid">;