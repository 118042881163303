<div class = "header">
    <div class = "instructor-name">
        {{ fullname }}
    </div>
</div>
<div class = "data">
    <div class = "data-item">
        <div class = "data-item-title">
            E-mail:
        </div>
        <div class = "data-item-value">
            {{ instructor.email }}
        </div>
    </div>
    <div class = "data-item" *ngIf = "instructor.phoneNumber">
        <div class = "data-item-title">
            Telefon:
        </div>
        <div class = "data-item-value">
            {{ instructor.phoneNumber }}
        </div>
    </div>
</div>