import { ChartDataPoint, LineChartData } from "../charts"

export class PackagesPurchaseStatistics {
    packages:{
        [key:string]:number;
    }

    constructor(
        private packageDefinitionUuids:Array<string>
    ) {
        this.packages = {};
        for(const packageDefinitionUuid of this.packageDefinitionUuids) {
            this.packages[packageDefinitionUuid] = 0;
        }
    }
}

export class DisplayedStatistics {
    calculatedOn:number|null;
    fromServer:string|null;
    public packageDefinitions:Array<{ uuid:string, displayedName:string }>

    registrations:{
        overall:number,
        daily:Array<LineChartData>
        platforms:Array<ChartDataPoint>
    }

    purchases: {
        overall:PackagesPurchaseStatistics,
        actualMonth:PackagesPurchaseStatistics,
        actualDay:PackagesPurchaseStatistics,
        daily:Array<LineChartData>;
    }

    usage: {
        daily:Array<LineChartData>;
        yesterday:number;
        today:number;
    }

    devices:Array<ChartDataPoint>;

    histories: {
        categories: {
            [key:string]:{
                exam:number,
                practice:number,
                thematic:number
            };
        }
        games: {
            roadSign:number,
            trueFalse:number
        }
    }

    constructor(packageDefinitions:Array<{ uuid:string, displayedName:string }>) {
        this.packageDefinitions = packageDefinitions;
        this.resetToDefault(packageDefinitions.map(packageDefinition => packageDefinition.uuid));
    }

    public resetToDefault(packageDefinitionUuids:Array<string>):void {
        this.calculatedOn = null
        this.fromServer = null;
        
        this.registrations = {
            overall: 0,
            daily: new Array<LineChartData>(),
            platforms: new Array<ChartDataPoint>()
        };

        this.purchases = {
            overall: new PackagesPurchaseStatistics(packageDefinitionUuids),
            actualMonth: new PackagesPurchaseStatistics(packageDefinitionUuids),
            actualDay: new PackagesPurchaseStatistics(packageDefinitionUuids),
            daily: new Array<LineChartData>()
        };

        this.usage = {
            daily: new Array<LineChartData>(),
            yesterday: 0,
            today: 0
        };

        this.devices = new Array<ChartDataPoint>();

        this.histories = {
            categories: {},
            games: {
                roadSign: 0,
                trueFalse: 0
            }
        };
    }

    public initDailyArrays(datesNames:ReadonlyArray<string>, packageDefinitionUuids:Array<string>):void {
        for(let packageDefinitionUuid of packageDefinitionUuids) {
            this.purchases.daily.push(new LineChartData(packageDefinitionUuid, datesNames));
        }

        this.registrations.daily.push(new LineChartData("Registrations", datesNames));

        this.usage.daily.push(new LineChartData("Usage", datesNames));
    }
}