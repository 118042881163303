
<h3>
    Hogyan működik?
</h3>
<p>
    Jelenleg a rendszer különböző gyakorlati modulhoz tartozó entitáshoz többféle eseményeket logol. Ez
    azt jelenti, hogy ha valaki egy eseményt létrehoz (vagy műveletet végrehajt), akkor arról egy
    naplóbejegyzés készül, amiből lehet látni, hogy kitől és mikor származik az, és hogy mi volt a változás.
</p>

<h3>
    Milyen logokat látunk?
</h3>
<p>
    Egy entitás  (vizsgaútonal, kritikus pont, kritikus pont hozzárendelés) esetén minden olyan bejegyzést
    látunk a felületen, ami arra az entitásra hatással volt. Például egy útvonal esetén látjuk annak
    létrehozását vagy az alapadatainak a módosítását, de azt is, ha valaki egy hozzárendelt kritikus pontot
    módosított. Fontos megjegyezni, hogy csak azokat műveleteket látjuk más hozzá kapcsolódó entitásokkal
    kapcsolatban, amelyek a kapcsolat létrejötte után történtek. Tehát az előző példát véve csak azokat a
    kritikus pont eseményeket fogjuk látni, ami a hozzárendelés létrejötte után közvetkeztek be.
</p>