import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminRole, AdminUser } from 'src/app/classes/model/admin-user';
import { Permission, PermissionString } from 'src/app/classes/model/permissions';
import { matchingPropertyPredicate } from 'src/app/functions/misc';
import { AdminRoleService as AdminRoleService, RemovedPermissionAffect } from 'src/app/services/admin-roles.service';
import { AdminUserService } from 'src/app/services/admin-user.service';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-admin-role-updating-dialog',
  templateUrl: './admin-role-updating-dialog.component.html',
  styleUrls: ['./admin-role-updating-dialog.component.scss']
})
export class AdminRoleUpdatingDialogComponent implements OnInit {
  displayedRemovedPermissionAffects:Array<DisplayedRemovedPermisionAffect> = [];

  isContentLoading:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:{
      adminRole:AdminRole,
      removedPermissions:Array<PermissionString>,
      title:string,
      infoString:string
    },
    private adminUserService:AdminUserService,
    private adminRoleService:AdminRoleService,
    private permissionsService:PermissionsService,
    private dialogRef:MatDialogRef<AdminRoleUpdatingDialogComponent>
  ) { }

  public ngOnInit():void {
    this.initializeDisplayedRemovedPermissionAffects();
  }

  private async initializeDisplayedRemovedPermissionAffects():Promise<void> {
    this.isContentLoading = true;

    let adminUsers:ReadonlyArray<AdminUser> = [];
    let adminRoles:ReadonlyArray<AdminRole> = [];
    let permissions:ReadonlyArray<Permission> = [];

    try {
      adminUsers = await this.adminUserService.fetchAdminUsers();
      adminRoles = await this.adminRoleService.fetchAdminRoles();
      permissions = await this.permissionsService.fetchPermissions();
    } catch(error:any) {
      this.dialogRef.close();
      return;
    }

    // Get the removed permissions (the unresolvable permissions is filtered out)
    const removedPermissions:Array<Permission> = this.data.removedPermissions.filter(
      (permissionName:PermissionString) => {
        return permissions.find(matchingPropertyPredicate("name", permissionName)) !== undefined
      }
    ).map(
      (permissionName:PermissionString) => {
        return permissions.find(matchingPropertyPredicate("name", permissionName))
      }
    );

    this.displayedRemovedPermissionAffects = [];

    for(const removedPermission of removedPermissions) {
      // Determine all other admin roles' names, which also contains the removed permission
      const otherRoleNamesWithTheRemovedPermission:Array<string> = this.getOtherRoleNamesWithTheRemovedPermission(
        adminRoles,
        removedPermission.name
      );

      // For each admin user we determine, that if they are affected by the change
      // An admin is count as affected if she/he is NOT in any other role, that is contains the removed permission
      const affectedAdminUsers:Array<AdminUser> = this.getAffectedAdminUsers(
        adminUsers,
        removedPermission.name,
        otherRoleNamesWithTheRemovedPermission
      );

      const displayedRemovedPermissionAffect:DisplayedRemovedPermisionAffect = {
        permission: removedPermission,
        affectedAdminUsers: affectedAdminUsers.map(
          (adminUser:AdminUser) => {
            return {
              adminUser: adminUser,
              isAffected: true
            }
          }
        )
      };

      this.displayedRemovedPermissionAffects.push(displayedRemovedPermissionAffect);
    }

    this.isContentLoading = false;
  }

  /**
   * Gets the all the role names which contains the given permission except the one found in `data.adminRole`.
   * 
   * @param adminRoles the list of the admin roles
   * @param permissionName the target permission
   * 
   * @returns array of the names of the result roles
   */
  private getOtherRoleNamesWithTheRemovedPermission(
    adminRoles:ReadonlyArray<AdminRole>,
    permissionName:PermissionString
  ):Array<string> {
    const otherRolesWithTheRemovedPermission:Array<AdminRole> = adminRoles.filter(
      (adminRole:AdminRole) => {
        const adminRoleHasTheRemovedPemission:boolean = adminRole.basePermissions.includes(permissionName);
        return adminRoleHasTheRemovedPemission && adminRole.name !== this.data.adminRole.name;
      }
    );

    const otherRoleNamesWithTheRemovedPermission:Array<string> = otherRolesWithTheRemovedPermission.map(
      (adminRole:AdminRole) => {
        return adminRole.name
      }
    );

    return otherRoleNamesWithTheRemovedPermission;
  }

  /**
   * Gets the list of the admin users whoose are affected by the deletion of the permission from the admin role.
   * 
   * @param adminUsers the list of the admin users
   * @param removedPermissionName the name of the removed permission
   * @param otherRoleNamesWithTheRemovedPermission the name of the roles which contains the permission other than the affected admin role
   * 
   * @returns the list of the admin users affected by the change
   */
  private getAffectedAdminUsers(
    adminUsers:ReadonlyArray<AdminUser>,
    removedPermissionName:PermissionString,
    otherRoleNamesWithTheRemovedPermission:Array<string>
  ):Array<AdminUser> {
    return adminUsers.filter(
      (adminUser:AdminUser) => {
        const adminHasTheRemovedPermission:boolean = adminUser.permissions.includes(removedPermissionName);
        const adminHasOtherRoleWithTheRemovedPermission:boolean = adminUser.roleNames.some(
          (roleName:string) => {
            return otherRoleNamesWithTheRemovedPermission.includes(roleName);
          }
        );

        return adminHasTheRemovedPermission && !adminHasOtherRoleWithTheRemovedPermission;
      }
    );
  }

  public onCheckboxChange(change:MatCheckboxChange, affectedAdminUser:AffectedAdminUser) {
    affectedAdminUser.isAffected = change.checked;
  }


  public handleSaveButtonClick():void {
    const removedPermissionAffect:Array<RemovedPermissionAffect> = this.displayedRemovedPermissionAffects.map(
      (displayedRemovedPermisionAffect:DisplayedRemovedPermisionAffect) => {
        return {
          permissionName: displayedRemovedPermisionAffect.permission.name,
          affectedAdminUuids: displayedRemovedPermisionAffect.affectedAdminUsers.filter(
            affectedAdminUser => affectedAdminUser.isAffected
          ).map(
            affectedAdminUser => affectedAdminUser.adminUser.uuid
          )
        };
      }
    );

    this.dialogRef.close(removedPermissionAffect);
  }

}

type DisplayedRemovedPermisionAffect = {
  permission:Permission;
  affectedAdminUsers:Array<AffectedAdminUser>;
}

type AffectedAdminUser = {
  isAffected:boolean;
  adminUser:AdminUser;
}

