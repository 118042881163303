import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Permission, PermissionAssignableToString, PermissionGroup, PermissionString } from 'src/app/classes/model/permissions';
import { matchingPropertyPredicate } from 'src/app/functions/misc';
import { PermissionGroupsService } from 'src/app/services/permission-groups.service';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-permission-selector',
  templateUrl: './permission-selector.component.html',
  styleUrls: ['./permission-selector.component.scss']
})
export class PermissionSelectorComponent implements OnInit {
  @Input() assignedPermissions:Array<PermissionString>;
  @Input() showSpecialPermissions:boolean = false;
  @Input() disabledPermissions:Array<PermissionString> = [];
  @Input() withAssignableTo:Array<PermissionAssignableToString>|null = null;
  
  permissions:ReadonlyArray<Permission>;
  permissionGroups:ReadonlyArray<PermissionGroup>;

  extendedPermissionGroups:Array<ExtendedPermissionGroup> = [];

  constructor(
    private permissionsService:PermissionsService,
    private permissionGroupsService:PermissionGroupsService
  ) { }

  public ngOnInit():void {
    this.permissions = this.permissionsService.getPermissionsArrayRef();
    this.permissionGroups = this.permissionGroupsService.getPermissionGroupsArrayRef();
    this.initialize();
  }

  private async initialize():Promise<void> {
    await this.loadPermissionsAndPermissionGroups();
    this.initializeExtendedPermissionGroups();
  }

  private async loadPermissionsAndPermissionGroups():Promise<void> {
    try {
      await this.permissionsService.loadDataIntoCache();
      await this.permissionGroupsService.loadDataIntoCache();
    } catch(error:any) {
      console.error(error);
    }
  }

  private initializeExtendedPermissionGroups():void {
    const otherExtendedPermissionGroup:ExtendedPermissionGroup = {
      name: "",
      displayedName: "Egyéb jogosultságok",
      description: "Ebben a csoportban azok a jogosultságok találhatóak, amelyek nincsenek csoporthoz rendelve.",
      permissions: []
    }

    for(const permission of this.permissions) {
      if(this.withAssignableTo !== null && !permission.assignableTo.some(assignableToItem => this.withAssignableTo.includes(assignableToItem))) {
        continue;
      }

      let extendedPermissionGroup:ExtendedPermissionGroup|undefined;

      if(!permission.permissionGroup) {
        extendedPermissionGroup = otherExtendedPermissionGroup;
      } else {
        extendedPermissionGroup = this.extendedPermissionGroups.find(matchingPropertyPredicate("name", permission.permissionGroup));
      }

      if(extendedPermissionGroup === undefined) {
        const permissionGroup:PermissionGroup = this.permissionGroups.find(matchingPropertyPredicate("name", permission.permissionGroup));

        if(permissionGroup === undefined) {
          continue;
        }

        extendedPermissionGroup = {
          name: permissionGroup.name,
          displayedName: permissionGroup.displayedName,
          description: permissionGroup.description,
          permissions: []
        }

        this.extendedPermissionGroups.push(extendedPermissionGroup);
      }

      if(!permission.isSpecial || this.showSpecialPermissions) {
        extendedPermissionGroup.permissions.push(permission);
      }
    }

    this.extendedPermissionGroups.push(otherExtendedPermissionGroup);

    this.extendedPermissionGroups = this.extendedPermissionGroups.filter(extendedPermissionGroup => extendedPermissionGroup.permissions.length > 0);
  }

  public handleCheckboxChange(event:MatCheckboxChange, permission:Permission):void {
    if(event.checked && !this.assignedPermissions.includes(permission.name)) {
      this.assignedPermissions.push(permission.name);
      return;
    }

    if(!event.checked && this.assignedPermissions.includes(permission.name)) {
      this.assignedPermissions.removeItems(
        (permissionName:PermissionString) => {
          return permissionName === permission.name;
        }
      );
    }
  }

  public isPermissionAssigned(permission:Permission):boolean {
    return this.assignedPermissions.includes(permission.name);
  }

}

class ExtendedPermissionGroup extends PermissionGroup  {
  permissions:Array<Permission>;
}
