<ng-container
    *ngIf = "initStatus === DashboardInitStatus.Ready;
        then dashboardReady;
        else dashboardLoadingOrError"
></ng-container>

<!-- Guards for loading and errors -->
<ng-template #dashboardLoadingOrError>

    <ng-container
        *ngIf = "initStatus === DashboardInitStatus.OwnPermissionLoading || initStatus === DashboardInitStatus.CachedDataLoading;
        then dashboardLoading;
        else dashboardError"
    ></ng-container>

    <!--  Loadings -->
    <ng-template #dashboardLoading>
        <div class = "loading-section">
            <div class = "loading-content">
                <mat-spinner style = "margin: 30px auto"></mat-spinner>
                <div [ngSwitch]="initStatus">
                    <span *ngSwitchCase = "DashboardInitStatus.OwnPermissionLoading">
                        Saját jogosultságok betöltése
                    </span>
                    <span *ngSwitchCase = "DashboardInitStatus.CachedDataLoading">
                        {{ actualLoadingAssetName }} betöltése...
                        ({{ actualLoadingAssetIndex + 1 }}/{{ dataCacherServiceInstances.length }})
                    </span>
                </div>
                <div [style.opacity] = "initializationTryCount > 1 ? 1 : 0" style = "margin-top: 10px;">
                    Újrapróbálkozás... ({{ initializationTryCount }})
                </div>
            </div>
        </div>
    </ng-template>

    <!-- Errors -->
    <ng-template #dashboardError>
        <div class = "error-section">
            <div class = "error-content">
                <div class = "error-message" >
                    <div [ngSwitch]="initStatus" style = "font-size: 1.25em; font-weight: 500;">
                        <span *ngSwitchCase = "DashboardInitStatus.OwnPermissionError">
                            Hiba történt a jogosultságaid betöltése közben.
                        </span>
                        <span *ngSwitchCase = "DashboardInitStatus.CachedDataLoadingError">
                            Hiba történt az cachelt adatok betöltése közben.
                        </span>
                        <span *ngSwitchDefault>
                            Hiba történt az oldal betöltése közben.
                        </span>
                    </div>
                    <div style = "margin: 10px 0 30px; font-size: 1em;" >
                        Ha újra szeretnéd próbálni a betöltést, kattints az "Újratöltés", ha pedig vissza
                        akarsz lépni a bejelentkező oldalra, kattints a "Vissza a bejelentkezéshez" gombra.
                    </div>
                </div>
                <div class = "error-action-buttons">
                    <button
                        mat-raised-button
                        color = "primary"
                        (click) = "onReloadButtonClick()"
                    >
                        <mat-icon> refresh </mat-icon>
                        Újratöltés
                    </button>
                    <button
                        mat-raised-button
                        color = "primary"
                        (click) = "onBackToLoginPageButtonClick()"
                    >
                        <mat-icon> logout </mat-icon>
                        Vissza a bejelentkezéshez
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
    
</ng-template>

<!-- Content -->
<ng-template #dashboardReady>
    <div style="display: flex; flex-direction: column; height: 100%">
        <div id="header-container">
            <app-header></app-header>
        </div>

        <!-- menu + content -->
        <!-- min height explan: https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container -->
        <div style="flex-grow: 1; display: block;min-height:0">
            <div style="display: flex; flex-direction: row; height: 100%">
            <div id="navigation-container">
                <app-navigation></app-navigation>
            </div>
            <div style="display: block; flex: 1; height: 100%; min-width: 0;" id="content-container">
                <app-content></app-content>
            </div>
            </div>
        </div>

        <!-- <div id="footer-container">
            <app-footer></app-footer>
        </div> -->
    </div>
</ng-template>






