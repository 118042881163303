<div class = "dialog-content">
    <form [formGroup] = "categoryQuestionPositionFG">
        <mat-table [dataSource] = "tableData" class = "mat-elevation-z8">
            <ng-container matColumnDef = "position">
                <mat-header-cell *matHeaderCellDef> Pozíció </mat-header-cell>
                <mat-cell *matCellDef = "let position"> {{position.position}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef = "numberOfQuestions">
                <mat-header-cell *matHeaderCellDef> Kérdések száma </mat-header-cell>
                <mat-cell *matCellDef = "let position">
                    {{position.numberOfQuestions}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef = "point">
                <mat-header-cell *matHeaderCellDef> Pont </mat-header-cell>
                <mat-cell *matCellDef = "let position">
                        <mat-form-field appearance='outline' class = "category-question-position-point-form">
                        <input matInput [formControlName] = "'categoryQuestionPositionPoint' + position.position + 'FC'" type = "number" min = "0" required />
                        <mat-error *ngIf = "pointFormControlHasError(position.position, 'required')">Kötelező!</mat-error>
                        <mat-error *ngIf = "pointFormControlHasError(position.position, 'positiveWholeNumber')">A számnak pozitív egésznek kell lennie!</mat-error>
                    </mat-form-field>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef = "list">
                <mat-header-cell *matHeaderCellDef> Listázás </mat-header-cell>
                <mat-cell *matCellDef = "let position">
                    <button mat-mini-fab color = "primary" [routerLink] = "'/dashboard/actual-questionbank/questions'" [queryParams] = "getQueryParams(position.position)" mat-dialog-close>
                        <mat-icon> list </mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef = "['position', 'numberOfQuestions', 'point', 'list']"></mat-header-row>
            <mat-row *matRowDef = "let row; columns: ['position', 'numberOfQuestions', 'point', 'list'];"></mat-row>
        </mat-table>
    </form>
</div>
<div id = "category-question-position-edit-dialog-actions">
    <button mat-button mat-dialog-close> Vissza </button>
    <button id = "category-question-position-edit-save-button" mat-raised-button color = "primary" [disabled] = "!categoryQuestionPositionFG.valid" (click) = "handleSaveButtonClick()"> Mentés </button>
</div>

