
/**
 * @description Represents an option of a question.
*/
export class QuestionOption {
    text:string;
    isSolution?:boolean;
    position?:number;
    
    constructor(text:string = "", isSolution?:boolean, position?:number) {
        this.text = text;
        if(isSolution !== undefined) {
            this.isSolution = isSolution;
        }
        if(position !== undefined) {
            this.position = position;
        }
    }
}

export class QuestionPosition {
    categoryUuid:string;
    positions:number[];
}

export class QuestionMetadata {
    id?:string;
    lastModifier?:string;
    lastModifyDate?:string;
}

/**
 * @description Represents a question.
*/
export class Question {
    uuid:string;
    type:"simple"|"arrangeable";
    text:string;
    options:QuestionOption[];
    explanation:string;
    questionGroups:string[];
    categories:QuestionPosition[];
    mediaUrl:string;
    metadata:QuestionMetadata;
    difficulty?:number;
    isUpdated:boolean;
    isInQueue:boolean;
    hasIllustration?:boolean;

    constructor() {
        //this.uuid = "teszt";
        this.text = "";
        this.explanation = "";
        this.options = new Array<QuestionOption>();
        this.questionGroups = new Array<string>();
        this.categories = new Array<QuestionPosition>();
        this.metadata = new QuestionMetadata();
        this.isUpdated = true;
        this.isInQueue = false;
        this.hasIllustration = false;
    }

    initAsCopy(copiedQuestion:Question) {
        this.uuid = copiedQuestion.uuid;
        this.type = copiedQuestion.type;
        this.text = copiedQuestion.text;
        this.explanation = copiedQuestion.explanation;
        this.mediaUrl = copiedQuestion.mediaUrl;
        this.isUpdated = copiedQuestion.isUpdated;
        this.isInQueue = copiedQuestion.isInQueue;
        this.hasIllustration = copiedQuestion.hasIllustration;

        

        this.options = new Array<QuestionOption>();
        for(let copiedOption of copiedQuestion.options) {
            this.options.push(new QuestionOption(copiedOption.text, copiedOption.isSolution, copiedOption.position));
        }

        this.questionGroups = new Array<string>();
        for(let copiedQuestionGroup of copiedQuestion.questionGroups) {
            this.questionGroups.push(copiedQuestionGroup);
        }

        this.categories = new Array<QuestionPosition>();
        for(let copiedCategory of copiedQuestion.categories) {
            let questionPosiotion:QuestionPosition = new QuestionPosition();
            questionPosiotion.categoryUuid = copiedCategory.categoryUuid;
            questionPosiotion.positions = new Array<number>();
            for(let position of copiedCategory.positions) {
                questionPosiotion.positions.push(position);
            }
            this.categories.push(questionPosiotion);
        }

        if(copiedQuestion.difficulty !== undefined) { this.difficulty = copiedQuestion.difficulty; }
        if(copiedQuestion.isUpdated !== undefined) { this.isUpdated = copiedQuestion.isUpdated; }

        this.metadata = new QuestionMetadata();
        if(copiedQuestion.metadata.id !== undefined) { this.metadata.id = copiedQuestion.metadata.id }
        if(copiedQuestion.metadata.lastModifier !== undefined) { this.metadata.lastModifier = copiedQuestion.metadata.lastModifier }
        if(copiedQuestion.metadata.lastModifyDate !== undefined) { this.metadata.lastModifyDate = copiedQuestion.metadata.lastModifyDate }

    
    
    }
}

export function questionSorter(element1:Question, element2:Question) {
    let q1:number = element1.metadata.id === undefined ? Infinity : parseInt(element1.metadata.id);
    let q2:number = element2.metadata.id === undefined ? Infinity : parseInt(element2.metadata.id);
    return q1 - q2;
}