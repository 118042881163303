import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Category } from 'src/app/classes/model/category';
import { QuestionPosition } from 'src/app/classes/model/question';
import { FormGroup, FormControl } from '@angular/forms';
import { matchingPropertyPredicate } from 'src/app/functions/misc';


class DisplayedQuestionPositions {
  categoryUuid:string;
  categoryName:string;
  positions:number[];

  constructor(categoryUuid:string, categoryName:string, numberOfPositions:number) {
    this.categoryUuid = categoryUuid;
    this.categoryName = categoryName;
    this.positions = new Array<number>();
    for(let position = 1; position <= numberOfPositions; ++position) {
      this.positions.push(position);
    }
  }
}

@Component({
  selector: 'app-question-category-assignment-edit-dialog',
  templateUrl: './question-category-assignment-edit-dialog.component.html',
  styleUrls: ['./question-category-assignment-edit-dialog.component.scss']
})
export class QuestionCategoryAssignmentEditDialogComponent implements OnInit {

  tempQuestionCategories:QuestionPosition[];
  displayedQuestionPositionsArray:DisplayedQuestionPositions[];
  categoriesInSelect:Array<Readonly<Category>>;
  
  categorySelectFG:FormGroup;
  questionCategorySelectFC:FormControl;

  constructor(@Inject(MAT_DIALOG_DATA) public data:{ tempQuestionCategories:QuestionPosition[],  categories:ReadonlyArray<Readonly<Category>> }) { }
  
  ngOnInit(): void {
    this.tempQuestionCategories = new Array<QuestionPosition>();
    this.displayedQuestionPositionsArray = new Array<DisplayedQuestionPositions>();
    for(let questionPositions of this.data.tempQuestionCategories) {
      let category:Category = this.data.categories.find(matchingPropertyPredicate("uuid", questionPositions.categoryUuid));
      // Deep copy the original property
      let positions:number[] = new Array<number>();
      for(let position of questionPositions.positions) {
        positions.push(position);
      }
      this.tempQuestionCategories.push({ categoryUuid: category.uuid, positions: positions });
      // Displayed positions
      this.displayedQuestionPositionsArray.push(new DisplayedQuestionPositions(category.uuid, category.name, category.numberOfQuestionsOfFullTest));
    }

    // Initialize the (remaining) category selecor 
    this.categoriesInSelect = new Array<Category>();
    for(let category of this.data.categories) {
      if(!this.tempQuestionCategories.some(element => { return element.categoryUuid === category.uuid })) {
        this.categoriesInSelect.push(category);
      }
    }

    // Inintilazite the FormControl and the FormGroup
    this.questionCategorySelectFC = new FormControl();
    if(this.categoriesInSelect.length === 0) {
      this.questionCategorySelectFC.disable();
    }
    this.categorySelectFG = new FormGroup({
      questionCategorySelectFC: this.questionCategorySelectFC
    });

  }

  getPositionStyleClass(categoryUuid:string, position:number):string {
    const index:number = this.tempQuestionCategories.findIndex(element => { return element.categoryUuid === categoryUuid });

    if(this.tempQuestionCategories[index].positions.includes(position)) {
      return "question-category-assignment-position-toogle in-position";
    } else {
      return "question-category-assignment-position-toogle not-in-position";
    }
  }

  addQuestionToCategory():void {
    const index:number = this.categoriesInSelect.findIndex(element => { return element.uuid === this.questionCategorySelectFC.value });

    if(index !== -1) {
      this.tempQuestionCategories.push({ categoryUuid: this.categoriesInSelect[index].uuid, positions: [] })
      this.displayedQuestionPositionsArray.push(new DisplayedQuestionPositions(this.categoriesInSelect[index].uuid, this.categoriesInSelect[index].name, this.categoriesInSelect[index].numberOfQuestionsOfFullTest))
      this.categoriesInSelect.splice(index, 1);
      this.questionCategorySelectFC.reset();
      if(this.categoriesInSelect.length === 0) {
        this.questionCategorySelectFC.disable();
      }
    }
  }

  removeQuestionFromCategory(categoryUuid:string):void {
    // Delete category from the displayed array
    const index:number = this.displayedQuestionPositionsArray.findIndex(element => { return element.categoryUuid === categoryUuid });
    this.displayedQuestionPositionsArray.splice(index, 1);
    // Delete category from the temporary positions array
    const index2:number = this.tempQuestionCategories.findIndex(element => { return element.categoryUuid === categoryUuid });
    this.tempQuestionCategories.splice(index, 1);
    // Insert the deleted category to the category select
    this.categoriesInSelect.push(this.data.categories.find(matchingPropertyPredicate("uuid", categoryUuid)));
    this.questionCategorySelectFC.enable();
  }

  tooglePositionAssignment(categoryUuid:string, position:number):void {
    const categoryIndex:number = this.tempQuestionCategories.findIndex(element => { return element.categoryUuid === categoryUuid });
    const positionIndex:number = this.tempQuestionCategories[categoryIndex].positions.findIndex(element => { return element === position });
    if(positionIndex === -1) {
      this.tempQuestionCategories[categoryIndex].positions.push(position);
      this.tempQuestionCategories[categoryIndex].positions = this.tempQuestionCategories[categoryIndex].positions.sort(
        function(position1, position2):number {
          if(position1 < position2) {
            return -1;
          } else if(position1 > position2){
            return 1;
          } else {
            return 0;
          }
        }
      );
    } else {
      this.tempQuestionCategories[categoryIndex].positions.splice(positionIndex, 1);
    }
  }

  handleSaveButtonClick():void {
    this.data.tempQuestionCategories.length = 0;
    this.tempQuestionCategories.filter(element => { return element.positions.length > 0 }).forEach(
      element => {
        this.data.tempQuestionCategories.push(element);
      }
    );
  }

}
