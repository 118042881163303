<div
  class="city-zone-card"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    "
  >
    <div style="display: flex; align-items: center">
      <div
        class="circle-color-box"
        [style.background-color]="this.zone.polygonColor"
      ></div>
      <span class="zone-name-span">{{ this.zone.name }}</span>
    </div>

    <div>{{this.getVideoCount()}} videó</div>
  </div>

  <div style="display: flex; align-items: flex-start">
    <mat-icon (click)="clickEdit.emit()" class="edit-icon" style="margin-right:12px;">edit</mat-icon>

    <mat-icon (click)="clickDelete.emit()" class="delete-icon" style="margin-right:20px;">
      delete
    </mat-icon>


    <div *ngIf="this.zone.imageUrl == null">
      Kép hiányzik 📸
    </div>
    <img *ngIf="this.zone.imageUrl != undefined"
      class="zone-image"
      [src]="this.zone.imageUrl"
    />
  </div>
</div>
