<div *ngIf="selectedIndex != null" class="video-container">
  <video
    *ngIf="finalVideos[selectedIndex].videoUrl"
    class="video"
    [src]="finalVideos[selectedIndex].videoUrl"
    controls
  ></video>
</div>
<div *ngIf="finalVideos.length > 0" class="final-videos-list">
  <table class="final-videos-table">
    <thead>
      <tr class="final-videos-table-header">
        <td class="added-on">Létrehozva</td>
        <td class="anonymized">Anonimizált videó</td>
        <td class="release">Kiadás</td>
        <td class="deleted-column">Törlés info</td>
      </tr>
    </thead>
  </table>
  <table class="final-videos-table" #finalVideosDataTable>
    <tbody>
      <tr
        *ngFor="let finalVideo of finalVideos; let i = index"
        class="final-videos-table-data-row"
        [ngClass]="{ selected: selectedIndex === i }"
        (click)="selectIndex(i)"
      >
        <td class="added-on">
          {{ finalVideo.creationTimestamp | date : "YYYY-MM-dd HH:mm" }}
        </td>
        <td class="anonymized">
          <span
            *ngIf="finalVideo.anonymizedVideoUuid"
            (click)="navigateToAnonymizedVideo(finalVideo.anonymizedVideoUuid)"
            class="anonimized-video-link"
          >
            {{ finalVideo.anonymizedVideoUuid }}
          </span>
          <span *ngIf="!finalVideo.anonymizedVideoUuid"> - </span>
        </td>
        <td class="release">
          <span
            *ngIf="
              practicePath.video.releasedVideo &&
              practicePath.video.releasedVideo.releasedFinalVideoUuid ===
                finalVideo.uuid
            "
            class="released"
          >
            <div>
              Kiadva (<a [href]="practicePath.video.releasedVideo.videoHlsUrl"
                >link</a
              >)
            </div>
            <div>
              ({{
                practicePath.video.releasedVideo.creationTimestamp
                  | date : "YYYY-MM-dd HH:mm"
              }})
            </div>
          </span>

          <div
            *ngIf="
              practicePath.video.releasedVideo?.releasedFinalVideoUuid !==
              finalVideo.uuid
            "
            class="final-video-table-button release-button"
            (click)="releaseFinalVideo(i)"
            [class.disabled]="releasingIndex !== null"
            [class.button-loading]="releasingIndex === i"
          >
            <span class="material-icons"> rocket_launch </span>
            Kiadás
          </div>
        </td>

        <td class="deleted-column">
          <div *ngIf="finalVideo.isPermanentDeleted">
            <span>
              <span style="font-weight: 500">Törölve</span>
              <br />
              <span style="font-size: 0.9rem">
                {{ finalVideo.permanentDeletionInformation.timestamp | date }}
                {{ finalVideo.permanentDeletionInformation.reason }}
              </span>
            </span>
          </div>
          <div *ngIf="!finalVideo.isPermanentDeleted">
            <div
              class="final-video-table-button delete"
              (click)="deleteFinalVideo(i)"
              [class.disabled]="isFinalVideoDeletionInProgress(i)"
              [class.button-loading]="isFinalVideoDeletionInProgress(i)"
            >
              <span class="material-icons"> delete </span>
              Törlés
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<mat-checkbox
  [(ngModel)]="this.startCloudInstance"
  style="margin-left: 10px; margin-right: 10px"
>
  Gép indítása
</mat-checkbox>
<ng-template [ngIf]="selectedIndex === null">
  <div class="no-final-video">Jelenleg nincs véglegesített videó</div>
</ng-template>

<div class="upload-final-video-button-container">
  <input #videoInput type="file" style="display: none" />
  <button
    mat-raised-button
    (click)="selectFinalVideoToUpload()"
    color="primary"
    [disabled]="hasAdminFinalVideoUploadPermisison() == false"
  >
    <span class="material-icons"> cloud_upload </span>
    Videó feltöltése
  </button>
</div>
