import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DraggableDialogContentWrapperData } from '../../model/draggable-dialog-content-wrapper-data';
import { defaultDraggableDialogConfig, DraggableDialogConfig } from '../../model/draggable-dialog-config';

@Component({
  selector: 'app-draggable-dialog-content-wrapper',
  templateUrl: './draggable-dialog-content-wrapper.component.html',
  styleUrls: ['./draggable-dialog-content-wrapper.component.scss']
})
export class DraggableDialogContentWrapperComponent<ContentComponentType, DialogInput> implements OnInit {

  protected componentPortal:ComponentPortal<ContentComponentType>;

  protected config:DraggableDialogConfig;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected dialogData:DialogInput&DraggableDialogContentWrapperData<ContentComponentType>
  ) { }

  ngOnInit(): void {
    this.componentPortal = new ComponentPortal(this.dialogData.contentComponentType);

    this.config = {
      ...defaultDraggableDialogConfig,
      ...this.dialogData.daggableDialogConfig
    };
  }

}
