<div class = "tab-header">
    <div class = "tilte">
        Új narráció feltöltése
        <span *ngIf = "filesToUpload.length > 0">
            ({{ filesToUpload.length }})
        </span>
    </div>
    <div style = "height: 100%; aspect-ratio: 1;">
        <button
            class = "add-files-button"
            (click) = "onAddFilesButtonClick()"
        >
            <mat-icon> add </mat-icon>
        </button>
        <input
            #fileInput
            type = "file"
            style = "display: none;"
            multiple
            accept = "audio/*, video/*"
        />
    </div>
</div>
<div class = "new-items-container">
    <app-new-narration-during-recording-item
        *ngFor = "let fileToUpload of filesToUpload; let i = index"
        [audioFile] = "fileToUpload"
        style = "margin: 10px 5px;"
        (deleteItemButtonClicked) = "onChildDeleteItemButtonClick(i)"
    ></app-new-narration-during-recording-item>
    <div
        *ngIf = "filesToUpload.length === 0"
        class = "no-files-to-upload"
    >
        Jelenleg nincs feltöltésre váró fájl kiválasztva.
    </div>
</div>
<div style = "text-align: center; margin-top: 5px;">
    <button
        class = "upload-button"
        (click) = "onUploadButtonClick()"
        [disabled] = "filesToUpload.length === 0"
    >
        Feltöltés
    </button>
</div>