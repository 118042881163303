<app-page-header
    title = "Monitorozott felhasználók"
    subtitle = "A debug célból megfigyelés alatt álló tanulókat találhatod itt."
    style = "margin-bottom: 20px;"
>
    <button mat-mini-fab color = "primary" (click) = "handleReloadButtonClick()">
        <mat-icon> refresh </mat-icon>
    </button>
</app-page-header>

<mat-card class = "student-logging-card" *ngFor = "let loggedStudent of loggedStudents; let i = index">
    <div>
        <div>
            <span class = "student-logging-student-uuid" (click) = "openStudentDetailsDialog(loggedStudent.uuid)">
                {{ loggedStudent.uuid }}
            </span>
        </div>
        <div>
            {{ loggedStudent.email }}
        </div>
    </div>
    <div>
        <button *ngIf = "loggedStudent.isActive" mat-raised-button color = "primary" (click) = "switchLogging(i, false)">
            Kikapcsolás
        </button>
        <button *ngIf = "!loggedStudent.isActive" mat-raised-button (click) = "switchLogging(i, true)">
            Visszakapcsolás
        </button>
    </div>
</mat-card>
<div *ngIf = "!loggedStudents || loggedStudents.length === 0" style = "font-style: italic; color: lightgray; text-align: center; margin-top: 20px;">
    Nincsenek monitorozott felhasználók.
</div>
