import { Component, Input, OnInit } from '@angular/core';
import { Package } from 'src/app/classes/model/packages/Package';
import { Vendor, VendorInvoiceSummary } from 'src/app/classes/model/vendor';
import { GroupedPackages } from '../../models/grouped-packages';
import { PackageGrouperService } from '../../services/package-grouper.service';

@Component({
  selector: 'app-vendor-purchases-tab',
  templateUrl: './vendor-purchases-tab.component.html',
  styleUrls: ['./vendor-purchases-tab.component.scss']
})
export class VendorPurchasesTabComponent implements OnInit {
  @Input() vendor:Vendor;
  @Input() vendorPackages:  Array<Package>;

  groupedPurchasedPackagesGroups:Array<GroupedPackages> = [];

  constructor(
    private packageGrouperService:PackageGrouperService
  ) { }

  ngOnInit(): void {
    // Calculate the gift groups by their bulk creation
    this.groupedPurchasedPackagesGroups = this.calculateGroupedPurchasedPackagesGroups(this.vendorPackages);
  }

  /**
   * Groups the purchased packages by their bulk creation. If the gift was not in a bulk creation originally
   * it will be represented in a standalone group.
   */
   private calculateGroupedPurchasedPackagesGroups(packages:Array<Package>):Array<GroupedPackages> {
    // Filter out the gifted packages packages
    const purchasedPackages:Array<Package> = packages.filter(
      packageElement => packageElement.packageType === "PurchasedPackage"
    );

    // Group the packages
    const groupedPurchasedPackages:Array<GroupedPackages> = this.packageGrouperService.groupPackages(purchasedPackages);

    // Sort the groups by their creation timestamp
    groupedPurchasedPackages.sort((group1, group2) => { return group2.creationTimestamp - group1.creationTimestamp });

    // Return the result
    return groupedPurchasedPackages;
  }

}
