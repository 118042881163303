import { number } from "echarts";
import { Writable } from "stream";
export type ObservableArrayListenerCallback<T> = (
  action: "update" | "add" | "remove" | "removedAllElement",
  oldValue?: T,
  newValue?: T,
  index?: number,
  actionOrigin?: string
) => void;

// Csak olvasható
export class ReadableObservableArray<T> {
  array: T[] = [];
  protected listeners: ObservableArrayListenerCallback<T>[] = [];

  constructor() {}

  public [Symbol.iterator](): Iterator<T, any, undefined> {
    let step = 0;
    return {
      next: (...args: any[] | undefined[]) => {
        if (step == this.array.length) {
          return {
            value: undefined,
            done: true,
          };
        }

        return {
          value: this.array[step++],
          done: false,
        };
      },
    };
  }

  find(predicate: (value: T) => boolean): T | undefined {
    for (let i = 0; i < this.array.length; i++) {
      if (predicate(this.array[i])) {
        return this.array[i];
      }
    }
    return undefined;
  }

  // visszatér az elem indexével
  // ha pedig nem találta -1
  findIndex(predicate: (value: T) => boolean): number {
    for (let i = 0; i < this.array.length; i++) {
      if (predicate(this.array[i])) {
        return i;
      }
    }
    return -1;
  }

  get length() {
    return this.array.length;
  }

  addListener(
    listener: ObservableArrayListenerCallback<T>
  ): ObservableArraySubscription {
    this.listeners.push(listener);
    return {
      unsubscribe: () => {
        this.removeListener(listener);
      },
    };
  }

  // Törli az összes feliratkozót
  // és törli a pod tömbre mutató referenciát
  // destroy után már nem használható a tömb az observable-ben
  destroy(){
    this.array = [];
    this.listeners = [];
  }

  // Kizárólag readonly tömböt adunk vissza
  // hiszen, ha a pod tömbön keresztül módosítana bárki
  // nem tudnánk értesíteni a feliratkozókat a változásról!
  public getOriginalArrayRef(): Readonly<T[]> {
    return this.array;
  }

  removeListener(listener: ObservableArrayListenerCallback<T>) {
    const index = this.listeners.indexOf(listener);
    if (index != -1) this.listeners.splice(index, 1);
  }

  getCopyAt(index: number): T {
    const copy = JSON.parse(JSON.stringify(this.array[index]));
    return copy;
  }
  getAt(index: number): T {
    return this.array[index];
  }

  getFullCopy() {
    const result: T[] = [];
    const len = this.array.length;

    for (let i = 0; i < len; i++) {
      result.push(this.getCopyAt(i));
    }

    return result;
  }

  getIndexOf(element: T): number {
    return this.array.indexOf(element);
  }

  protected _notifyAll(
    action: "update" | "add" | "remove" | "removedAllElement",
    oldValue?: T,
    newValue?: T,
    index?: number,
    actionOrigin?: string
  ) {
    this.listeners.forEach((listener) => {
      listener(action, oldValue, newValue, index, actionOrigin);
    });
  }
}

export function isWritableObservableArray<T>(object: any) {
  return (object as WritableObservableArray<T>).updateAt !== undefined;
}

export class PipedObservableArray<
  SourceType,
  TransformedType
> extends ReadableObservableArray<TransformedType> {
  constructor(
    private source: ObservableArray<SourceType>,
    private mapping: (elem: SourceType) => TransformedType
  ) {
    super();
    this.copySourceArray();
    this.addSourceListener();
  }

  // A forrásból az adott indexű elemet újra mappeli
  // erre akkor lehet szükség, amikor habár a forrás adat nem változott
  // de a leképezés (mapping) függősége változott.
  // pl: egy másik tömb alapján mappel amiben változás történt így ha újra mappelünk más adatot kapunk
  resyncFromSource(index: number) {
    const targetElem: SourceType = this.source.getCopyAt(index);
    const mappedOldValue = this.getCopyAt(index);
    const mapped: TransformedType = this.mapping(targetElem);
    this.array[index] = mapped;
    this._notifyAll("update", mappedOldValue, mapped, index);
  }

  copySourceArray() {
    for (let i = 0; i < this.source.length; i++) {
      const curr = this.source.getCopyAt(i);
      this.array.push(this.mapping(curr));
    }
  }

  protected _notifyAll(
    action: "update" | "add" | "remove" | "removedAllElement",
    oldValue?: TransformedType,
    newValue?: TransformedType,
    index?: number,
    actionOrigin?: string
  ) {
    this.listeners.forEach((listener) => {
      listener(action, oldValue, newValue, index, actionOrigin);
    });
  }


  destroy(){
    this.mapping = null;
    super.destroy();
  }

  addSourceListener() {
    this.source.addListener(
      (
        action: "update" | "add" | "remove" | "removedAllElement",
        oldValue?: SourceType,
        newValue?: SourceType,
        index?: number,
        actionOrigin?: string
      ) => {
        if (action == "update") {
          const oldMapped = this.getCopyAt(index);
          const newMappedValue = this.mapping(newValue);
          this.array[index] = newMappedValue;
          this._notifyAll(
            "update",
            oldMapped,
            newMappedValue,
            index,
            actionOrigin
          );
        }
        if (action == "add") {
          const mappedValue = this.mapping(newValue);
          this.array.push(mappedValue);
          this._notifyAll("add", undefined, mappedValue, index, actionOrigin);
        }

        if (action == "remove") {
          const oldMappedValue = this.getCopyAt(index);
          this.array.splice(index, 1);

          this._notifyAll(
            "remove",
            oldMappedValue,
            undefined,
            index,
            actionOrigin
          );
        }

        if (action == "removedAllElement") {
          this.array.length = 0; // A transzformált tömb elemeit is töröljük!
          this._notifyAll(
            "removedAllElement",
            undefined,
            undefined,
            undefined,
            actionOrigin
          );
        }
      }
    );
  }
}

// Egy olyan tömb ami az összes változásról értesíti a feliratkozókat
// A változtatásokhoz forrást is lehet rendelni, így azonosítani tudja
// a változtató, hogy egy adott változást ő hozott-e létre (ha ő maga is fel van iratkozva az értesítésekre)
export class WritableObservableArray<ItemType>
  extends ReadableObservableArray<ItemType>
  implements Iterable<ItemType>
{
  pipe<TransformedType>(mapping: (elem: ItemType) => TransformedType) {
    return new PipedObservableArray(this, mapping);
  }

  // Egy pod array alapján létrehoz egy observable arrayt
  // és összeköti a 2 tömböt. Ha módosítjuk az observable array-t, akkor az eredeti tömb is módosul
  // az observable array underlying tömbjének be lesz állítva ez a podArray
  // arra viszont figyeljünk, hogy a podArray-en keresztüli módosítás esetén nem kapunk értesítést!
  static fromPodArrayWithBinding<ItemType>(
    podArray: ItemType[]
  ): WritableObservableArray<ItemType> {
    const inst = new WritableObservableArray<ItemType>();
    inst.array = podArray;
    return inst;
  }

  // Binding nélkül létrehozza, csak lemásolja a tömböt
  static fromPodArray<ItemType>(
    podArray: ItemType[]
  ): WritableObservableArray<ItemType> {
    const inst = new WritableObservableArray<ItemType>();
    inst.array = [...podArray];
    return inst;
  }

  updateAt(index: number, newItem: ItemType, actionOrigin?: string) {
    const oldValue = this.getCopyAt(index);
    this.array[index] = newItem;
    this._notifyAll("update", oldValue, newItem, index, actionOrigin);
  }


  removeAt(index: number, actionOrigin?: string) {
    const oldValue = this.getCopyAt(index);
    this.array.splice(index, 1);
    this._notifyAll("remove", oldValue, undefined, index, actionOrigin);
  }

  // Inplace filterezést végez a tömbön
  filterInPlace(predicate: (elem: ItemType) => boolean) {
    for (let i = 0; i < this.array.length; i++) {
      if (predicate(this.array[i]) == false) {
        this.removeAt(i);
        i--;
      }
    }
  }

  removeAll(actionOrigin?: string) {
    this.array.length = 0;
    this._notifyAll(
      "removedAllElement",
      undefined,
      undefined,
      undefined,
      actionOrigin
    );
  }

  insert(index: number, newItem: ItemType, actionOrigin?: string) {
    // Ha nincs még a tömbben egy elem se, és a 0. indexre szeretne beszúrni, akkor szimplán csak addoljunk
    if (index == 0 && this.array.length == 0) {
      this.add(newItem, actionOrigin);
      return;
    }

    // (1) Indextől lementjük az összes elemet
    const save: ItemType[] = [];
    for (let i = index; i < this.array.length; i++) {
      save.push(this.getCopyAt(i));
    }

    // (2) Indextől kitörlünk mindent
    const originalArraySize = this.array.length;
    for (let i = index; i < originalArraySize; i++) {
      this.removeAt(index, actionOrigin);
    }

    // (3) Index-re beszúrjuk az új itemet
    this.add(newItem, actionOrigin);

    // (4) Majd beszúrjuk utána a lementett itemeket
    for (const saved of save) {
      this.add(saved, actionOrigin);
    }
  }

  add(newItem: ItemType, actionOrigin?: string) {
    this.array.push(newItem);
    this._notifyAll(
      "add",
      undefined,
      newItem,
      this.array.length - 1,
      actionOrigin
    );
  }
}

export class ObservableArraySubscription {
  constructor(public unsubscribe: () => void) {}
}

export type ObservableArray<T> =
  | WritableObservableArray<T>
  | ReadableObservableArray<T>;
