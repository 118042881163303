import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/services/common/backend.service';
import { LoggedPracticalModuleEntityType, PracticalModuleLog } from '../models/practical-module-log.model';

@Injectable()
export class PracticalModuleLogsApiService {
  private readonly practiceModuleLogsApiPath:string = "/api/admin/practical_module_logs";

  constructor(
    private backendService:BackendService
  ) {}


  /**
   * Fetches all logs in the given time interval.
   * 
   * @param startTimestamp the start of the time interval
   * @param endTimestamp the end of the time interval
   * 
   * @returns the array of logs in the time interval
   */
  public async fetchAllLogs(startTimestamp:number, endTimestamp:number):Promise<Array<PracticalModuleLog>> { 
    return this.fetchLogsWithQueryParams(
      `startTimestampMs=${startTimestamp}&endTimestampMs=${endTimestamp}`
    );
  }

  /**
   * Fetch all logs for a given entity in the given time interval.
   * 
   * @param entityType the type of the entity
   * @param entityKey the key of the entity
   * @param startTimestamp the start of the time interval
   * @param endTimestamp the end of the time interval
   * 
   * @returns the array of logs for the entity in the time interval
   */
  public async fetchLogsForEntity(
    entityType:LoggedPracticalModuleEntityType|undefined,
    entityKey:string|undefined,
    startTimestamp:number,
    endTimestamp:number
  ):Promise<Array<PracticalModuleLog>> {
    if(entityType != undefined && entityKey == undefined){
      throw Error("entityKey must be defined if entityType is defined");
    }
    // Log all parameters
    console.log(`entityType: ${entityType}, entityKey: ${entityKey}, startTimestamp: ${startTimestamp}, endTimestamp: ${endTimestamp}`);

    return this.fetchLogsWithQueryParams(
      `relatedEntityType=${entityType}&entityKey=${entityKey}&startTimestampMs=${startTimestamp}&endTimestampMs=${endTimestamp}`
    );
  }

  /**
   * Fetches the logs from the server's API with the provided query params.
   * 
   * @param queryParamsString the query params as string
   * 
   * @returns the array of logs queried from the server
   */
  private async fetchLogsWithQueryParams(queryParamsString:string):Promise<Array<PracticalModuleLog>> {
    const apiPath:string = `${this.practiceModuleLogsApiPath}?${queryParamsString}`;

    let logs:Array<PracticalModuleLog> = [];

    try {
      const response:{ logs:Array<PracticalModuleLog> } = await this.backendService.callApi(apiPath, "GET");
      logs = response.logs;
    } catch(error:any) {
      // On error we rethrow the error
      throw error;
    }

    return logs;
  }

}


