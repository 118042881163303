<div class = "title">
    Ajándék létrehozása e-mail címre
</div>

<div class = "email-form-field">
    <mat-form-field appearance='outline' style = "width: 100%">
        <mat-label>E-mail</mat-label>
        <input matInput [formControl] = "emailFC" />
        <mat-error>{{getEmailError()}}</mat-error>
    </mat-form-field>
</div>
<div class = "reason-form-field">
        <mat-form-field appearance='outline' style = "width: 100%">
        <mat-label>Ok</mat-label>
        <input matInput [formControl] = "reasonFC" />
        <mat-hint>Magunknak leírás az ajándék kiosztásának okáról</mat-hint>
        <mat-error>Kötelező mező! (Magunknak)</mat-error>
    </mat-form-field>
</div>
<div class = "message-form-field">
        <mat-form-field appearance='outline' style = "width: 100%">
        <mat-label>Leírás</mat-label>
        <input matInput [formControl] = "messageFC" />
        <mat-hint>Ezt látja a tanuló</mat-hint>
        <mat-error>Kötelező mező! (Ezt látja a tanuló)</mat-error>
    </mat-form-field>
</div>
<div class = "package-form-field">
    <app-package-definition-selector
        [formControl] = "packageDefinitionFC"
        ngDefaultControl
    ></app-package-definition-selector>
</div>


<div style = "text-align: right;">
    <button mat-raised-button mat-dialog-close style = "margin-right: 20px;">
        Bezár
    </button>
    <button mat-raised-button color = "primary" [disabled] = "giftFG.invalid" (click) = "handleCreateGiftButtonClick()">
        Létrehozás
    </button>
</div>
