import { NotifierService } from 'src/app/services/common/notifier-service.service';
import { PracticeCityBaseInformations, practiceCityBaseInformationsFields } from "src/app/classes/model/practice-city";
import { PracticePathMainDataEditorComponent } from "./../practice-path-main-data-editor-page/components/practice-path-main-data-editor/practice-path-main-data-editor.component";
import { Component, OnInit, ViewChild } from "@angular/core";
import { PracticeCityService } from "src/app/services/practice-city.service";
import { PracticePathService } from "src/app/services/practice-path.service";
import { ActivatedRoute, Router } from "@angular/router";

// Új PracticePath létrehozására használható

@Component({
  selector: "app-practice-path-create-new",
  templateUrl: "./practice-path-create-new.component.html",
  styleUrls: ["./practice-path-create-new.component.scss"],
})
export class PracticePathCreateNewComponent implements OnInit {
  @ViewChild("practicePathEditor")
  practicePathEditor: PracticePathMainDataEditorComponent;

  practiceCitiesBaseInformations:Array<PracticeCityBaseInformations> = [];

  InitState:typeof InitState = InitState;
  initState:InitState;

  cityUuid:string;

  constructor(
    private notifier: NotifierService,
    protected practiceCityService: PracticeCityService,
    private practicePathService: PracticePathService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loadPracticeCityBaseInformations();

    this.cityUuid = this.activatedRoute.snapshot.params.cityUuid;
  }

  /**
   * Loads the cities base informations into the component.
   */
  protected async loadPracticeCityBaseInformations():Promise<void> {
    this.initState = InitState.Loading;
    try {
      this.practiceCitiesBaseInformations = await this.practiceCityService.fetchPracticeCities(
        practiceCityBaseInformationsFields
      ) as Array<PracticeCityBaseInformations>;
    } catch(error:any) {
      this.initState = InitState.Error;
      return;
    }

    this.initState = InitState.Success;
  }

  /**
   * Handles the components add new practice path button click.
   *
   * @returns
   */
  async onTapAddNewPracticePath() {
    const newPracticePathValues = this.practicePathEditor.getDataFromForm();
    const isValid = this.practicePathEditor.isValidForm();
    if(!isValid){
      this.notifier.notify('error','Adj meg minden kötelező mezőt!');
      return;
    }

    try {
      const insertedPracticePath =
        await this.practicePathService.addPracticePathToCity(
          newPracticePathValues.cityUuid,
          {
            isFullPracticePath: newPracticePathValues.isFullPracticePath,
            name: newPracticePathValues.practicePathName,
            examPathNumber: newPracticePathValues.examPathNumber,
            zoneUuids: newPracticePathValues.zoneUuids,
            isFree: newPracticePathValues.isFree
         }
        );
      this.notifier.notify(
        "success",
        "Sikeres útvonal felvétel. Most pedig add hozzá a videót!"
      );

      this.router.navigate(
        ["../../..", newPracticePathValues.cityUuid, "paths", insertedPracticePath.uuid, "main-data-edit"],
        { relativeTo: this.activatedRoute }
      );


    } catch (e) {
      this.notifier.notify(
        "error",
        "Útvonal felvétel nem sikerült. info@mrkresz.hu címre írj!"
      );
    }
  }
}

enum InitState {
  Loading,
  Error,
  Success
}
