export function getStringWithRemovedDiacritics(string:string):string {
  // Normalize the strings in such way that the base character and it's diacritics will be
  // seperate codepoints
  const normalizedString:string = string.normalize("NFD");
  // Return the string with the diacritics removed
  return normalizedString.replace(/\p{Diacritic}/gu, "");
}

export function compareStringsWithoutDiacritics(string1:string, string2:string):boolean {
  // Get the string values without the diacritics
  const string1WithoutDiacritics:string = getStringWithRemovedDiacritics(string1);
  const string2WithoutDiacritics:string = getStringWithRemovedDiacritics(string2);
  // Return the result of the comparison
  return string1WithoutDiacritics === string2WithoutDiacritics;
}