import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

export type ProgressDialogInput = {
  progressSubject:Omit<BehaviorSubject<number>, "next">;
  textSubject:Omit<BehaviorSubject<string>, "next">;
}


@Component({
  selector: 'app-progress-dialog',
  templateUrl: './progress-dialog.component.html',
  styleUrls: ['./progress-dialog.component.scss']
})
export class ProgressDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data:ProgressDialogInput
  ) { }

  ngOnInit(): void { }

  public static open(
    dialogService:MatDialog,
    data:ProgressDialogInput
  ):MatDialogRef<
    ProgressDialogComponent,
    void
  > {
    return dialogService.open<
      ProgressDialogComponent,
      ProgressDialogInput,
      void
    >(
      ProgressDialogComponent,
      {
        data: data,
        width: "400px",
        height: "300px",
        disableClose: true
      }
    );
  }

}
