import { posix } from "path";
import { GoogleMapPosition } from 'src/app/classes/model/google-map-position';
import {
  CriticalPoint,
  IconPath,
  TimedIcon,
} from "./../classes/model/practice-path";
import { LatLng } from "../modules/practice-path-video-editor-page/models/LatLng";
import {
  CriticalPointAssignment,
  PathItem,
  StopPoint,
} from "../classes/model/practice-path";
import { GeoHelper } from "../modules/practice-path-video-editor-page/GeoHelper";
import { GeoTaggedFrame } from "./gopro-metadata-extractor";
import { Injectable } from "@angular/core";
import { PracticeIcon } from "../classes/model/practice-icons";

@Injectable({
  providedIn: "root",
})
export class PracticePathUtil {
// Egy kritikus pontnál akkor állunk meg, ha ezen a sugaron belül vagyunk
  static readonly criticalPointStopRadiusMeter = 30;

  // Az előző érintés óta legalább ennyit kell elhaladunk az előző érintési ponttól
  // hogy egy másik érintési pontot felvételét engedélyezzük
  static readonly minimumDistanceToConsiderAnotherStopPoint =
    PracticePathUtil.criticalPointStopRadiusMeter * 2 + 0.1;

  // Akkor állunk meg egy stop pontnál, ha legfeljebb ennyire vagyunk tőle
  static readonly maximumEpsilonTimeDiffOnStopPointMs = 65;

  // Ha a jelenlegi pozíciónkhoz képest [pathIconRadiusMeter] méteres sugárban
  // található egy útvonal ikon akkor azt az ikont megjelenítjük, ha az irányszöge is egyezik
  static readonly pathIconRadiusMeter = 6;

  // Abszolút maximum eltérés a jelenlegi pozíció és az útvonal ikon iránya között
  // Ez tehát egy 60 fokos tartomány határoz meg
  static readonly maximumAngleDiffOnPathIconCheck = 30;

  // A legközelebbi geo tagged frame-et adja vissza ami a videoPositionInMs előtt van még
  getClosestGeoTaggedFrameToVideoPosition(
    geoTaggedFrames: Readonly<PathItem[]>,
    videoPositionInMs: number
  ): PathItem {
    if(geoTaggedFrames.length == 0){
      console.error("empty geotagged frames");
    }

    let closest = geoTaggedFrames[0];

    // Nem tesszük fel, hogy a geoTaggedFrames rendezett, inkább lerendezzük!
    const geoTaggedFramesSortedCopy = [...geoTaggedFrames];
    geoTaggedFramesSortedCopy.sort((a, b) => {
      return a.millisecOfFrame - b.millisecOfFrame;
    });

    for (const geoTaggedFrame of geoTaggedFramesSortedCopy) {
      if (geoTaggedFrame.millisecOfFrame > videoPositionInMs) break;
      closest = geoTaggedFrame;
    }
    return closest;
  }

  getClosestGeoTaggedFrameToGeoLocation(
    geoTaggedFrames: Readonly<PathItem[]>,
    targetGeoLocation: LatLng
  ): PathItem {
    if(geoTaggedFrames.length == 0){
      console.error("geoTaggedFrames empty, closest geotagged frame loc");
      return {millisecOfFrame:0 ,position:{latitude:0,longitude:0}};
    }

    let closest = geoTaggedFrames[0];
    let closestDist = 100000000;
    for (const geoTaggedFrame of geoTaggedFrames) {
      const dist = Math.abs(
        GeoHelper.distance(
          geoTaggedFrame.position.latitude,
          geoTaggedFrame.position.longitude,
          targetGeoLocation.latitude,
          targetGeoLocation.longitude
        )
      );
      if (dist < closestDist) {
        closestDist = dist;
        closest = geoTaggedFrame;
      }
    }
    return closest;
  }

  // null-al tér vissza, ha nincs a közelben megállási pont
  // ha pedig van a közelben (70ms-en belül) akkor megadja
  // hogy melyik kritikus pontnál állunk meg és melyik megállási pontnál vagyunk
  getCloseStopPointToVideoPosition(
    criticalPointAssignments: Readonly<CriticalPointAssignment[]>,
    videoPositionInMs: number
  ): null | {
    stopPoint: StopPoint;
    criticalPointAssignment: CriticalPointAssignment;
  } {
    let currentClosestDist = 10000000;
    let result: null | {
      stopPoint: StopPoint;
      criticalPointAssignment: CriticalPointAssignment;
    };

    // Megkeresi a legközelebbi megállási pontot ami még nem nagyobb a videoPositionInMs-nél
    for (const assignment of criticalPointAssignments) {
      for (const stopPoint of assignment.stopPoints) {
        // Inaktív stop pontot nem jelenítünk meg
        if (stopPoint.isActive == false) continue;

        // Ha 50-el több az még nem baj.
        if (stopPoint.stopTimeInMs > videoPositionInMs + 50) continue;

        const positionDiff = videoPositionInMs - stopPoint.stopTimeInMs;
        const maximumEpsilonDiffMs =
          PracticePathUtil.maximumEpsilonTimeDiffOnStopPointMs; // Ha ezen belül van az eltérés akkor megállunk
        if (
          positionDiff < currentClosestDist &&
          positionDiff < maximumEpsilonDiffMs
        ) {
          currentClosestDist = positionDiff;
          result = {
            stopPoint: stopPoint,
            criticalPointAssignment: assignment,
          };
        }
      }
    }

    return result;
  }

  getBearingAngleBetweenPosition(
    from: GoogleMapPosition,
    to: GoogleMapPosition
  ) {
    return GeoHelper.bearingAngle(
      {
        latitude: from.latitude,
        longitude: from.longitude,
      },
      {
        latitude: to.latitude,
        longitude: to.longitude,
      }
    );
  }

  // Egy adott videó pozíción merre nézett az autó
  getBearingAngleAtVideoPosition(
    geoTaggedFrames: Readonly<GeoTaggedFrame[]>,
    videoPositionInMs: number
  ) {
    const closestGeoTaggedFrame = this.getClosestGeoTaggedFrameToVideoPosition(
      geoTaggedFrames,
      videoPositionInMs
    );
    const ind = geoTaggedFrames.indexOf(closestGeoTaggedFrame);

    return this.getBearingAngle(geoTaggedFrames, ind);
  }

  // Adott indexen lévő geoTaggedFrame irányszöge
  // Ha a nulladik akkor
  getBearingAngle(geoTaggedFrames: Readonly<GeoTaggedFrame[]>, index: number) {
    if(geoTaggedFrames.length < 2){
      console.error("Geo tagged frames less than 2");
      return 0;
    }

    if(geoTaggedFrames.length <= index){
      console.error("invalid geotagged frames index");
      return 0;
    }

    if (index == 0) {
      return this.getBearingAngleBetweenPosition(
        geoTaggedFrames[index].position,
        geoTaggedFrames[index + 1].position
      );
    } else {
      return this.getBearingAngleBetweenPosition(
        geoTaggedFrames[index - 1].position,
        geoTaggedFrames[index].position
      );
    }
  }

  /**
   * Megadja, hogy egy adott GPS koordinátán állva meg kell-e állnunk a criticalPointCandidate-en
   * @param geoTaggedFrame Jelenlegi pozíció
   * @param bearingAngle Jelenlegi irányszög (erre néz az autó a jelenlegi pozíción állva)
   * @param criticalPointCandidate Erre a kritikus pontra teszteljük a megállást
   */
  shouldStopAtCriticalPointOnSpecificGeoTaggedFrame(
    geoTaggedFrame: PathItem,
    bearingAngle: number,
    criticalPointCandidate: {
      latitude: number;
      longitude: number;
      directionalAngle?: number;
    }
  ): boolean {
    // Távolság ellenőrzés
    const dist = GeoHelper.distance(
      geoTaggedFrame.position.latitude,
      geoTaggedFrame.position.longitude,
      criticalPointCandidate.latitude,
      criticalPointCandidate.longitude
    );

    return dist < PracticePathUtil.criticalPointStopRadiusMeter;
  }

  // Szögek közti eltérés [0,180] közti eredmény
  angleDiff(a: number, b: number) {
    let angleDiff = Math.abs(a - b);
    if (angleDiff > 180) angleDiff = 360 - angleDiff;
    return angleDiff;
  }

  // Megállási pont becslée
  // A path és a kritikus pont alapján
  // meghatározza a lehetséges megállási pontokat
  estimateStopPoints(
    geoTaggedFrames: ReadonlyArray<PathItem>,
    criticalPoint: {
      latitude: number;
      longitude: number;
      directionalAngle?: number;
    }
  ): { stopTimeInMs: number }[] {
    /** ALGORITMUS
     * Szimuláljunk a path
     */
    const resultStopPointInMs: { stopTimeInMs: number }[] = [];
    let isStopPointAddingEnabled: boolean = true;
    let lastCoordinateOnStopPointInsert: {
      latitude: number;
      longitude: number;
    }; // Az utolsó megállási pont felvételkor a koordinátánk

    for (let i = 0; i < geoTaggedFrames.length; i++) {
      const currentGeoTaggedFrame = geoTaggedFrames[i];
      const currentBearingAngle = this.getBearingAngle(geoTaggedFrames, i);

      if (isStopPointAddingEnabled == false) {
        const distSinceLastInsert = GeoHelper.distance(
          lastCoordinateOnStopPointInsert.latitude,
          lastCoordinateOnStopPointInsert.longitude,
          currentGeoTaggedFrame.position.latitude,
          currentGeoTaggedFrame.position.longitude
        );

        // Ha legalább 100 métert haladtunk az utolsó megállási pont óta újra engedélyezzük a stop point hozzáadást
        if (
          distSinceLastInsert >
          PracticePathUtil.minimumDistanceToConsiderAnotherStopPoint
        ) {
          isStopPointAddingEnabled = true;
        }
      }

      // Ha az adott pozíció közel van a kritikus ponthoz, akkor hozzáadjuk stopPoint-nak
      // A következő stopPoint-ot csak akkor kezdjük el hozzáadni, ha legalább 50 métert haladtunk
      if (
        this.shouldStopAtCriticalPointOnSpecificGeoTaggedFrame(
          currentGeoTaggedFrame,
          currentBearingAngle,
          criticalPoint
        )
      ) {
        if (isStopPointAddingEnabled) {
          resultStopPointInMs.push({
            stopTimeInMs: currentGeoTaggedFrame.millisecOfFrame,
          });
          lastCoordinateOnStopPointInsert = currentGeoTaggedFrame.position;
          isStopPointAddingEnabled = false;
        }
      }
    }

    return resultStopPointInMs;
  }

  // Igazzal tér vissza, ha legalább egy path ponthoz közel van (~50 méter)
  public isCriticalPointCloseToAtLeastOnePathPoint(
    criticalPoint: CriticalPoint,
    geoTaggedFrames: Readonly<GeoTaggedFrame[]>
  ) {
    for (const frame of geoTaggedFrames) {
      if (
        GeoHelper.distance(
          criticalPoint.coordinate.latitude,
          criticalPoint.coordinate.longitude,
          frame.position.latitude,
          frame.position.longitude
        ) < 50
      ) {
        return true;
      }
    }
    return false;
  }

  // Egy pontnak az első érintése egy sugaron belül
  // más szóval mikor kerültünk először egy adott pont környezetébe először
  getFirstTouchOfPointInPracticePath(
    point: { latitude: number; longitude: number },
    geoTaggedFrames: Readonly<GeoTaggedFrame[]>,
    radius: number
  ): GeoTaggedFrame | null {
    for (const frame of geoTaggedFrames) {
      if (
        GeoHelper.distance(
          frame.position.latitude,
          frame.position.longitude,
          point.latitude,
          point.longitude
        ) <= radius
      ) {
        return frame;
      }
    }
    return null;
  }

  // Meghatározza a jelenleg látható útvonal ikonokat
  // Egy útvonal ikon akkor aktív/látható, ha:
  // A jelenlegi videó pozícióhoz tartozó koordináta 8 méteres sugarába
  // beleesik legalább 1 pontja az útvonal ikonnak
  public determineCurrentlyVisiblePathIcons(
    currentPosition: PathItem,
    geoTaggedFrames: ReadonlyArray<PathItem>,
    pathIcons: ReadonlyArray<IconPath>,
    icons: ReadonlyArray<PracticeIcon>
  ): IconPath[] {
    const result: IconPath[] = [];

    for (const icon of pathIcons) {
      if (this.isIconPathVisible(currentPosition, geoTaggedFrames, icon)) {
        result.push(icon);
      }
    }

    return result;
  }

  public isIconPathVisible(
    currentPosition: PathItem,
    geoTaggedFrames: ReadonlyArray<PathItem>,
    iconPath: IconPath
  ): boolean {
    if (iconPath.path.length <= 1 || geoTaggedFrames.length <= 1) return false;


    const ind = geoTaggedFrames.indexOf(currentPosition);

    if(ind == -1){
      console.error("not found provided path item");
      return false;
    }

    for (let i = 0; i < iconPath.path.length; i++) {
      const point = iconPath.path[i];
      const dist = GeoHelper.distance(
        point.latitude,
        point.longitude,
        currentPosition.position.latitude,
        currentPosition.position.longitude
      );

      if (dist < PracticePathUtil.pathIconRadiusMeter) {
        // Ha a körön belül van akkor nézzük meg az útvonal ikon irányszögét és a path irányszögét
        // Ha nagyobb az eltérés, mint 120 fok, akkor nem jelenítjük meg az ikont
        const pathBearingAngle = this.getBearingAngleBetweenPosition(
          geoTaggedFrames[ind == 0 ? 1 : ind].position,
          geoTaggedFrames[ind == 0 ? 0 : ind - 1].position
        );
        const iconBearingAngle = this.getBearingAngleBetweenPosition(
          iconPath.path[i == 0 ? 1 : i],
          iconPath.path[i == 0 ? 0 : i - 1]
        );

        const angleDiff = this.angleDiff(pathBearingAngle, iconBearingAngle);
        if (angleDiff < PracticePathUtil.maximumAngleDiffOnPathIconCheck) {
          return true;
        }
      }
    }

    return false;
  }

  // Meghatározza a megjelenítendő ikonokat
  public determineCurrentlyVisibleIcons(
    currentVideoMs: number,
    geoTaggedFrames: Readonly<PathItem[]>,
    timedIcons: ReadonlyArray<TimedIcon>,
    pathIcons: ReadonlyArray<IconPath>,
    practiceIcons: ReadonlyArray<PracticeIcon>
  ): PracticeIcon[] {
    const result: Array<PracticeIcon> = [];

    // Időzített ikonok
    for (const t of timedIcons) {
      if (currentVideoMs >= t.startFrame && currentVideoMs <= t.endFrame) {
        const pIcon = practiceIcons.find((p) => p.uuid == t.practiceIconUuid);
        if (pIcon != undefined) {
          result.push(pIcon);
        } else {
          console.error(
            "Olyan időzített ikonra történik hivatkozás amit nem találunk."
          );
          console.log(currentVideoMs, t, practiceIcons);
        }
      }
    }

    result.pushArray(
      this.determineCurrentlyVisiblePathIcons(
        this.getClosestGeoTaggedFrameToVideoPosition(
          geoTaggedFrames,
          currentVideoMs
        ),
        geoTaggedFrames,
        pathIcons,
        practiceIcons
      ).map((p) =>
        practiceIcons.find((pIcon) => pIcon.uuid == p.practiceIconUuid)
      )
    );

    return result;
  }
}

@Injectable({
  providedIn: "root",
})
export class PathIconSorter {
  constructor(private util: PracticePathUtil) {}

  // Útvonal ikonokat helyben rendezi
  sortPathIconInPlace(
    pathIcons: IconPath[],
    geoTaggedFrames: Readonly<PathItem[]>
  ) {
    pathIcons.sort((a, b) => {
      let A = this.util.getFirstTouchOfPointInPracticePath(
        b.path[0] ?? { latitude: 0, longitude: 0 },
        geoTaggedFrames,
        8
      )?.millisecOfFrame;
      let B = this.util.getFirstTouchOfPointInPracticePath(
        a.path[0] ?? { latitude: 0, longitude: 0 },
        geoTaggedFrames,
        8
      )?.millisecOfFrame;

      A ??= 1000000000; // Ha nem érintettük, akkor a végére kerüljön
      B ??= 1000000000;

      return B - A;
    });
  }
}

// A térképen a kritikus pontok sorszámáért felel
@Injectable({
  providedIn: "root",
})
export class CriticalPointSorter {
  constructor(private util: PracticePathUtil) {}

  // Kritikus pont hozzárendeléseket rendezi
  sortCriticalPointAssignmentsInPlace(
    criticalPointAssignment: CriticalPointAssignment[],
    criticalPoint: ReadonlyArray<CriticalPoint>,
    geoTaggedFrames: ReadonlyArray<PathItem>
  ) {
    criticalPointAssignment.sort((a, b) => {
      // Ha nincs neki stop pont akkor nem érinti az útvonalat, kerüljön a tömb legvégére!
      let aTime = a.stopPoints.filter((sp) => sp.isActive)[0]?.stopTimeInMs;
      let bTime = b.stopPoints.filter((sp) => sp.isActive)[0]?.stopTimeInMs;

      // Ha valamelyik null akkor megbecsüljük, hogy mi lenne a megállási ideje
      if (aTime == null) {
        const current = criticalPoint.find(
          (cp) => cp.uuid == a.criticalPointUuid
        );
        aTime =
          this.util.estimateStopPoints(geoTaggedFrames, current.coordinate)[0]
            ?.stopTimeInMs ?? 10000000;
      }

      if (bTime == null) {
        const current = criticalPoint.find(
          (cp) => cp.uuid == b.criticalPointUuid
        );
        bTime =
          this.util.estimateStopPoints(geoTaggedFrames, current.coordinate)[0]
            ?.stopTimeInMs ?? 10000000;
      }
      return aTime - bTime;
    });
  }
  // Meghatározza egy RENDEZEETT kritikus pont tömbben egy új kritikus pont pozícióját
  // a tömb növekvő sorrendben van
  // Ha utolsónak kell beszúrnunk akkor a tömb hosszát adja vissza (mint index)
  determineCriticalPointIndexInOrder(
    sortedCriticalPoints: CriticalPointAssignment[],
    newCriticalPoint: CriticalPoint,
    geoTaggedFrames: PathItem[],
    criticalPoints: CriticalPoint[]
  ): number {
    // Először határozzuk meg az új kritikus pontnak az első megállási pontját
    const points = new PracticePathUtil().estimateStopPoints(geoTaggedFrames, {
      latitude: newCriticalPoint.coordinate.latitude,
      longitude: newCriticalPoint.coordinate.longitude,
    });

    if (points.length == 0) return sortedCriticalPoints.length;

    // A legelső megállási pont alapján vannak rendezve a kritikus pontok
    const p = points[0];
    for (let i = 0; i < sortedCriticalPoints.length; i++) {
      const current = sortedCriticalPoints[i];
      const cp = criticalPoints.find(
        (c) => c.uuid == current.criticalPointUuid
      );

      if (current.stopPoints.length == 0) {
        const sp = this.util.estimateStopPoints(geoTaggedFrames, {
          latitude: cp.coordinate.latitude,
          longitude: cp.coordinate.longitude,
        });

        if (sp.length == 0) {
          return i;
        }
        if (sp[0].stopTimeInMs >= p.stopTimeInMs) return i;
      } else {
        if (current.stopPoints[0].stopTimeInMs >= p.stopTimeInMs) return i;
      }
    }

    return sortedCriticalPoints.length;
  }
}
