export class Feedback{
    constructor(
        public uuid:string,
        public created:{
            userUuid:string,
            userEmail:string,
            timestamp:number
        },
        public message: string,
        public closed:{
            adminEmail:string,
            timestamp:number
        }|null
    ) {}
}

export function feedbackSorter(feedback1:Feedback, feedback2:Feedback) {
    return feedback2.created.timestamp - feedback1.created.timestamp
}