<div>
    <mat-spinner diameter = 50 style = "margin: 0 auto;"></mat-spinner>
</div>
<div style = "margin-top: 20px;">
    <div style = "text-align: center; margin-bottom: 20px;">
        {{ data.uploadProgress.text }}
    </div>
    <mat-progress-bar [value] = "data.uploadProgress.value"></mat-progress-bar>
</div>

