<div class = "tab-header">
    Itt találhatóak a viszonteladó vásárlásai.
</div>
<div class = "package-group-info-container">
    <app-vendor-package-group-info
        *ngFor = "let groupedPurchasedPackagesGroup of groupedPurchasedPackagesGroups"
        [groupedPackages] = "groupedPurchasedPackagesGroup"
        class = "vendor-package-group-info"
    ></app-vendor-package-group-info>
    <div *ngIf = "groupedPurchasedPackagesGroups.length === 0" class = "no-gifts">
        Jelenleg nincs kiosztott ajándék a viszonteladó számára.
    </div>
</div>