import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppNotification } from "src/app/classes/model/app-notification";
import { AppNotificationService } from "src/app/services/app-notification.service";
import { CreateNotificationDialogComponent } from './create-notification-dialog/create-notification-dialog.component';

@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss']
})
export class UserNotificationsComponent implements OnInit {

  appNotifications:ReadonlyArray<Readonly<AppNotification>>;

  constructor(private appNotificationService:AppNotificationService, public dialog:MatDialog) { }

  ngOnInit():void {
    this.appNotifications = this.appNotificationService.getAppNotificationsArrayRef();
    this.loadAppNotifications();
  }

  public async handleReloadButtonClick():Promise<void> {
    await this.loadAppNotifications();
  }

  public async loadAppNotifications():Promise<void> {
    try {
      await this.appNotificationService.loadDataIntoCache();
    } catch(error:any) {}
  }

  openCreateAppNotificationDialog():void {
    this.dialog.open(CreateNotificationDialogComponent, {
      disableClose: true
    });
  }

}
