import { MetadataJsonReader } from "./../../../../../../../../services/MetadataJsonReader";
import { PracticePathService } from "src/app/services/practice-path.service";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PracticePath } from "src/app/classes/model/practice-path";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NamedFile } from "src/app/services/common/aws-s3.service";
import { FileUploadIndicatorDialogComponent, UploadProgress, } from "src/app/components/misc/file-upload-indicator-dialog/file-upload-indicator-dialog.component";
import { PracticePathUploadDialogComponent } from "../../practice-path-upload-dialog.component";

const s3ObjectSanitize = require('sanitize-s3-objectkey');

@Component({
  selector: "app-upload-full-video-tab",
  templateUrl: "./upload-full-video-tab.component.html",
  styleUrls: ["./upload-full-video-tab.component.scss"],
})
export class UploadFullVideoTabComponent implements OnInit {
  @ViewChild("videoInput") videoInput: ElementRef;
  @ViewChild("metadataInput") metadataInput: ElementRef;

  @Input() practicePath: PracticePath;

  readonly allowedVideoExtensions = ["mp4"];
  readonly allowedMetadataExtension = ["json"];

  videoFile: File;
  metadataFile: File;

  constructor(
    private dialogRef: MatDialogRef<PracticePathUploadDialogComponent>,
    private dialogService: MatDialog,
    private snackBar: MatSnackBar,
    private practicePathService: PracticePathService
  ) {}

  ngOnInit(): void {}

  hasValidExtension(file: File, allowedExtension: string[]) {
    if (file == null) return false;
    allowedExtension = allowedExtension.map((a) => a.toLowerCase());
    return allowedExtension.includes(
      file.name.toLowerCase().split(".").pop() ?? ""
    );
  }

  isValidMetadata():boolean {
    const hasValidExtension = this.hasValidExtension(
      this.metadataFile,
      this.allowedMetadataExtension
    );
    if (!hasValidExtension)
      this.snackBar.open(
        "A metadata-nak .JSON kiterjesztésűnek kell lennie.",
        "Bezár",
        { duration: 3500 }
      );

    return hasValidExtension;
  }

  isValidVideo():boolean {
    const hasValidExtension = this.hasValidExtension(
      this.videoFile,
      this.allowedVideoExtensions
    );
    if (!hasValidExtension)
      this.snackBar.open(
        "A videónak .mp4 kiterjesztésűnek kell lennie.",
        "Bezár",
        { duration: 3500 }
      );

    return hasValidExtension;
  }

  changeFileInput(event: any) {
    this.videoFile = this.videoInput.nativeElement.files[0];
    this.metadataFile = this.metadataInput.nativeElement.files[0];
  }

  async onTapUpload() {
    if (!this.isValidMetadata() || !this.isValidVideo()) return;
    const uploadProgress: UploadProgress = {
      text: "Feltöltés előkészítése...",
      value: 0,
    };

    const onProgress = (state: string, progress: number) => {
      uploadProgress.value = progress;
      uploadProgress.text =
        state == "upload"
          ? `Feltöltés ${progress}%`
          : `Mentés S3-ba ${progress}%`;
    };

    const fileUploadDialog: MatDialogRef<FileUploadIndicatorDialogComponent> =
    this.dialogService.open(FileUploadIndicatorDialogComponent, {
      data: {
        uploadProgress: uploadProgress,
      },
      disableClose: true,
    });

    let updatedPracticePath:PracticePath|null = null;

    try {
      const namedFile: NamedFile = {
        file: this.videoFile,
        name: this.videoFile.name,
      };
      const metadataReader = new MetadataJsonReader();
      const metadataJson = await metadataReader.read(this.metadataFile);

      // A nevét szanetizáljuk, hogy ne legyen benne csak A-Z character
      namedFile.name = s3ObjectSanitize(namedFile.name);

      updatedPracticePath = await this.practicePathService.uploadFullRawPracticePathVideo(
        this.practicePath.uuid,
        namedFile,
        metadataJson.geoTaggedFrames,
        onProgress
      );

      this.snackBar.open("Sikeres feltöltés", "Bezár");
    } catch (error:any) {
      console.error(error);
    }

    fileUploadDialog.close();

    this.dialogRef.close(updatedPracticePath);
  }
}
