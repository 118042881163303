
<!-- Material input field-->
 <mat-form-field appearance='outline' style="width:400px;">
  <mat-label>Törlés oka</mat-label>
  <textarea style="height:150px;" [(ngModel)]="reason" matInput placeholder="például: Azért szeretném törölni mert hibás a videó közepe"></textarea>
</mat-form-field>
<br>
Biztosan szeretnéd törölni?
<br>

<div style="margin-top:30px; display:flex;justify-content: space-between;">
  <button color="primary" mat-raised-button mat-raised-button style="flex:1;" (click) = "onTapYes()">
    Igen
  </button>

  <button color="warn" mat-raised-button style="flex:1; margin-left:30px;" (click) = "onTapNo()">
    Nem
  </button>
</div>
