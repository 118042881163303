<section id = "user-notifications-section">
    <div id = "user-notifications-title">
        App értesítések
        <button mat-mini-fab color = "primary" (click) = "handleReloadButtonClick()">
            <mat-icon> cached </mat-icon>
        </button> 
    </div>
    
    <div>
        <app-user-notification-card *ngFor = "let appNotification of appNotifications" [appNotification] = "appNotification"></app-user-notification-card>
    </div>
    
    <div id = "add-notification-button-container">
        <button mat-fab color = "primary" (click) = "openCreateAppNotificationDialog()">
            <mat-icon>
                add
            </mat-icon>
        </button>
    </div>
</section>
