<div
  *ngIf="
    data.practicePath.video?.releasedVideo != null;
    then hasReleasedVideo;
    else hasNotReleasedVideo
  "
></div>

<!-- Nincs az útvonalnak released videója -->
<ng-template #hasNotReleasedVideo>
  Ehhez az útvonalhoz nem tartozik kiadott videó, ezért nem vágható.
</ng-template>

<div *ngIf="(data.practicePath?.path?.length ?? 0) == 0" style="color:red;">
  <h1>Nincs egyetlen gps koordináta sem! Így nem vághatsz</h1>
</div>


<!-- Van az útvonalnak released videója -->
<ng-template #hasReleasedVideo>
  <div class="main-container">
    <div class="header">
      <div style="flex: 1">
        <b>{{ data.city.city }}</b> | {{ data.practicePath.name }} |
      </div>

      <div>
        <button mat-dialog-close mat-raised-button>
          Bezárás
        </button>
      </div>
    </div>

    <div class="content">
      <div
        class="content-inner"
      >
        <!-- Videó + térkép -->
        <div
          style="
            column-gap: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            overflow: visible;
          "
        >
          <video-player [videoPlayerController]="videoPlayerController">
          </video-player>
          <google-map-extended
            style="overflow: hidden"
            (onReady)="onInitedGoogleMapController($event)"
          >
          </google-map-extended>
        </div>
      </div>
    </div>

     <mat-form-field appearance='outline' style="margin-top:20px;width:400px;" appearance="fill">
      <mat-label>A kivágott útvonal neve</mat-label>
      <input matInput placeholder="Rókusi körforgalom 1. kijárat rövid videó" [(ngModel)]="this.newPracticePathName">
    </mat-form-field>

    <!-- Kezdő és végpont beállító -->
    <div style="margin-top: 15px;display:flex;align-items: center;column-gap: 15px;">
      <h3 style="width:150px;">Új videó kezdete: </h3>
      {{startMs|msToTimeString}}

      <button (click)="startMs=videoPlayerController._currentPositionInMs$.getValue()" mat-raised-button style="height:33px;">Beállítás a jelenlegi videó pillantra</button>
    </div>

    <div style="margin-top: 10px;display:flex;align-items: center;column-gap: 15px;">
      <h3 style="width:150px;">Új videó vége: </h3>
      {{endMs|msToTimeString}}

      <button (click)="endMs=videoPlayerController._currentPositionInMs$.getValue()" style="margin-left:25px; display:block;" mat-raised-button style="height:33px;">Beállítás a jelenlegi videó pillantra</button>
    </div>

    <!-- Vágás gomb -->

    <mat-checkbox [(ngModel)]="this.startCloudInstance" style="margin-left:10px;margin-right:10px;">
      Gép indítása
    </mat-checkbox>

    <button (click)="onTapCut()" style="margin-top:15px;" color="primary" mat-raised-button style="height:33px;">Vágás indítása</button>
  </div>
</ng-template>
