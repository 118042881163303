import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { MaintenanceStatus } from "src/app/classes/model/maintenance";
import { MaintenanceService } from "src/app/services/maintenance.service";

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit, OnDestroy {

  actualMaintenanceStatus:MaintenanceStatus|null;

  maintenanceFG:FormGroup;
  isActiveFC:FormControl;
  messageFC:FormControl;

  isActiveSubscription:Subscription;

  constructor(private maintenanceService:MaintenanceService, public snackBar:MatSnackBar) { }

  public ngOnInit():void {
    this.isActiveFC = new FormControl();
    this.isActiveSubscription = this.isActiveFC.valueChanges.subscribe(
      newValue => {
        if(newValue) {
          this.messageFC.enable();
        } else {
          this.messageFC.setValue(this.actualMaintenanceStatus.message);
          this.messageFC.disable();
        }
      }
    );

    this.messageFC = new FormControl("", [ Validators.required ]);

    this.maintenanceFG = new FormGroup({
      isActiveFC: this.isActiveFC,
      messageFC: this.messageFC
    });

    this.loadMaintenanceStatus();
  }

  public ngOnDestroy():void {
    this.isActiveSubscription.unsubscribe();
  }

  private async loadMaintenanceStatus():Promise<void> {
    try {
      this.actualMaintenanceStatus = await this.maintenanceService.fetchMaintenanceStatus();
      this.isActiveFC.setValue(this.actualMaintenanceStatus.isActive);
      this.messageFC.setValue(this.actualMaintenanceStatus.message);
    } catch(error:any) {}
  }

  public async handleReloadButtonClick() {
    await this.loadMaintenanceStatus();
  }

  public async setMaintenanceStatus():Promise<void> {
    try {
      await this.maintenanceService.setMaintenanceStatus({
        isActive: this.isActiveFC.value as boolean,
        message: this.messageFC.value as string
      });
      this.actualMaintenanceStatus = await this.maintenanceService.fetchMaintenanceStatus();
      this.snackBar.open("Karbantartási állapot sikeresen módosítva.", "Bezár", { duration: 2000, panelClass: ["mat-snackbar-success"] });
    } catch(error:any) {}
  }

}
