<div style = "margin-bottom: 20px;">
    <!-- Name -->
    <div class = "subtitle" style="margin-bottom: 10px;">
        Név
    </div>
    <form>
        <mat-form-field appearance='outline' style = "width: 100%;">
            <mat-label>Zóna neve</mat-label>
            <textarea matInput [formControl] = "nameFC" class = "node-name-textarea"></textarea>
            <mat-error *ngIf = "nameFC.hasError('required')">Kötelező mező!</mat-error>
            <mat-error *ngIf = "!nameFC.hasError('required') && nameFC.hasError('maximalLineCount')">A név nem lehet 2 sornál több!</mat-error>
        </mat-form-field>
    </form>
    <!-- isFree & isVisible &  isMainNode-->
    <div style = "margin-bottom: 20px; display: flex; flex-direction:column;flex-wrap:wrap;gap: 15px;">
    </div>
    <div>
        <app-media-form
            [formControlRef] = "imageFC"
            [previewEnabled] = "true"
            [acceptableFileTypes] = "[ 'image/png', 'image/jpeg' ]"
            [defaultPreviewMedia] = "data.zone.imageUrl ?? ''"
            [previewWidth] = "300"
            [previewHeight] = "300 * (9/16)"
        ></app-media-form>
    </div>
</div>

<!-- Actions -->
<div style = "display: flex; justify-content: end; column-gap: 10px;">
  <button
      mat-raised-button
      color = "red"
      (click) = "handleCancelButtonClick()"
  >
      Bezárás
  </button>
  <button
      mat-raised-button
      color = "primary"
      [disabled] = "!nameFC.value || nameFC.hasError('maximalLineCount')"
      (click) = "handleSaveButtonClick()"
  >
      Módosítás
  </button>
</div>