import { Injectable } from "@angular/core";
import { Menu, MenuPoint, SubMenuPoint } from "../classes/menu";
import {
  isPermissionRequirementsMet,
  Permission,
  PermissionString,
} from "../classes/model/permissions";
import { PermissionService } from "./common/permission.service";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  public readonly menu: Menu = {
    menuPoints: [
      {
        name: "Aktuális kérdésbank",
        iconName: "traffic",
        route: "actual-questionbank",
        subMenuPoints: [
          {
            name: "Kategóriák",
            subroute: "categories",
            requiredPermissions: [Permission.TheoryRead],
          },
          {
            name: "Kérdéscsoportok",
            subroute: "questiongroups",
            requiredPermissions: [Permission.TheoryRead],
          },
          {
            name: "Kérdések",
            subroute: "questions",
            requiredPermissions: [Permission.TheoryRead],
          },
          {
            name: "Módosítások",
            subroute: "changelog",
            requiredPermissions: [Permission.TheoryRead],
          },
        ],
      },
      {
        name: "Gyakorlat",
        iconName: "edit_road",
        route: "practice",
        subMenuPoints: [
          {
            name: "Vizsgahelyszínek",
            subroute: "cities",
            requiredPermissions: [Permission.PracticePathRead],
          },
          {
            name: "Jelzőtábla ikonok",
            subroute: "icons",
            requiredPermissions: [Permission.PracticePathRead],
          },
          {
            name: "Anonimizációs folyamatok",
            subroute: "anonymizer-tasks",
            requiredPermissions: [Permission.PracticeVideoConvert],
          },
          {
            name: "Útvonal ellenőrző",
            subroute: "mrvegas",
            requiredPermissions: [
              Permission.PracticePathRead
            ],
          },
          {
            name: "Logok",
            subroute: "logs",
            requiredPermissions: [
              Permission.PracticalModuleLogsRead
            ],
          },
        ],
      },
      {
        name: "Felhasználók",
        iconName: "person",
        route: "users",
        subMenuPoints: [
          {
            name: "Tanulók",
            subroute: "students",
            requiredPermissions: [
              Permission.StudentsRead,
              Permission.StudentsWrite,
              Permission.StudentGiftWrite,
            ],
          },
          {
            name: "Visszajelzések",
            subroute: "feedbacks",
            requiredPermissions: [Permission.FeedbackRead],
          },
          {
            name: "Vásárlások",
            subroute: "purchases",
            requiredPermissions: [Permission.PurchasesRead],
          },
          {
            name: "Viszonteladók",
            subroute: "vendors",
            requiredPermissions: [Permission.VendorRead],
          },
          {
            name: "Classroom oktatók",
            subroute: "instructors",
            requiredPermissions: [Permission.InstructorRead],
          },
          {
            name: "Classroom jelentkezések",
            subroute: "instructors/applications",
            requiredPermissions: [Permission.InstructorRead],
          },
        ],
      },
      {
        name: "Csomagok",
        iconName: "card_giftcard",
        route: "packages",
        subMenuPoints: [
          {
            name: "Csomag entitások",
            subroute: "package-entities",
            requiredPermissions: [
              Permission.PackageDefinitionRead
            ],
          },
          {
            name: "Csomagdefiníciók",
            subroute: "package-definitions",
            requiredPermissions: [
              Permission.PackageDefinitionRead
            ],
          }
        ]
      },
      {
        name: "Szerver",
        iconName: "build_circle",
        route: "server",
        subMenuPoints: [
          {
            name: "Cache adatbázis frissítése",
            subroute: "refresh-cache",
            requiredPermissions: [Permission.DatabaseCacheReload],
          },
          {
            name: "Karbantartás",
            subroute: "maintenance",
            requiredPermissions: [Permission.ServerManager],
          },
        ],
      },
      {
        name: "Speciális",
        iconName: "grade",
        route: "special",
        subMenuPoints: [
          {
            name: "App értesítések",
            subroute: "user-notifications",
            requiredPermissions: [
              Permission.PushNotificationRead,
              Permission.PushNotificationWrite,
            ],
          },
          {
            name: "Globális ajándékok",
            subroute: "global-gifts",
            requiredPermissions: [
              Permission.GlobalGiftRead,
              Permission.GlobalGiftWrite,
            ],
          },
          {
            name: "Kiosztott ajándékok",
            subroute: "personal-gifts",
            requiredPermissions: [Permission.GiftsOfAdminRead],
          },
          {
            name: "Tanuló monitorozás",
            subroute: "student-logging",
            requiredPermissions: [Permission.ServerManager],
          },
          {
            name: "Playground",
            subroute: "playground",
            requiredPermissions: [Permission.ServerManager],
          },
        ],
      },
      {
        name: "Adminisztrátorok",
        iconName: "admin_panel_settings",
        route: "admins",
        subMenuPoints: [
          {
            name: "Adminisztrátorok listája",
            subroute: "admin-users",
            requiredPermissions: [Permission.ServerManager],
          },
          {
            name: "Jogosultságok",
            subroute: "permissions",
            requiredPermissions: [Permission.ServerManager],
          },
          {
            name: "Jogosultság csoportok",
            subroute: "permission-groups",
            requiredPermissions: [Permission.ServerManager],
          },
          {
            name: "Szerepkörök",
            subroute: "roles",
            requiredPermissions: [Permission.ServerManager],
          },
        ],
      },
      {
        name: "Admin menü",
        iconName: "settings",
        route: "admin-menu",
        subMenuPoints: [
          {
            name: "Beállítások",
            subroute: "settings",
            requiredPermissions: [Permission.None],
          },
        ],
      },
    ],
  };

  constructor(private permissionService: PermissionService) {}

  public getAvailableMenu(): Readonly<Menu> {
    const userPermissions: Array<PermissionString> = this.permissionService.getLoggedUserPermissions();

    const menu: Menu = { menuPoints: [] };
    this.menu.menuPoints.forEach((menuPoint: MenuPoint) => {
      const filteredMenuPoint: MenuPoint = {
        name: menuPoint.name,
        iconName: menuPoint.iconName,
        route: menuPoint.route,
        subMenuPoints: menuPoint.subMenuPoints.filter(
          (subMenuPoint: SubMenuPoint) => {
            return isPermissionRequirementsMet(
              userPermissions,
              subMenuPoint.requiredPermissions
            );
          }
        ),
      };

      if (filteredMenuPoint.subMenuPoints.length > 0) {
        menu.menuPoints.push(filteredMenuPoint);
      }
    });

    return menu;
  }
}
