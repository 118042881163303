<div class = "title-container">
    <span
        *ngIf = "narrationDuringRecording.title"
        class = "title"
    >
        {{ narrationDuringRecording.title }}
    </span>
    <span
        *ngIf = "!narrationDuringRecording.title"
        class = "no-title"
    >
        Nincs cím megadva
    </span>
</div>
<div style = "margin-bottom: 10px; font-size: 0.9rem;">
    <div style = "font-weight: 500;">
        Eredeti fájlnév:
    </div>
    <div>
        {{ narrationDuringRecording.originalFileName }}
    </div>
</div>
<div class = "audio-container">
    <audio
        style = "width: 100%;"
        [src] = "narrationDuringRecording.audioUrl"
        preload = "metadata"
        controls
    ></audio>
        <button
            class = "action-button open-button"
            (click) = "onOpenButtonClick()"
        >
            <mat-icon> open_in_new </mat-icon>
            Megnyitás
        </button>
        <button
            class = "action-button download-button"
            (click)="downloadAudio()"
            [disabled] = "isAudioDownloading"
            [class.button-loading] = "isAudioDownloading"
        >
            <mat-icon> download </mat-icon>
            Letöltés
        </button>
</div>
<div class = "creation-info">
    <div>
        {{ narrationDuringRecording.created.adminEmail }}
    </div>
    <div>
        {{ narrationDuringRecording.created.timestamp | date: "YYYY-MM-dd HH:mm:ss" }}
    </div>
</div>
