<div class="form-item">
  <!-- E-mail address -->
  <mat-form-field appearance='outline' style="margin-bottom: 10px;">
    <mat-label>E-mail cím</mat-label>
    <input matInput [formControl]="emailFC" />
    <mat-hint> Ezt használja a viszonteladó majd a bejelentkezéshez </mat-hint>
    <mat-error *ngIf="emailFC.hasError('required')">Kötelező mező!</mat-error>
    <mat-error *ngIf="!emailFC.hasError('required') && emailFC.hasError('email')">Hibás e-mail cím formátum!</mat-error>
  </mat-form-field>
</div>
<div class="form-item">
  <!-- Name -->
  <mat-form-field appearance='outline' style="margin-bottom: 10px;">
    <mat-label>Név</mat-label>
    <input matInput [formControl]="nameFC" />
    <mat-error>Kötelező mező!</mat-error>
  </mat-form-field>
</div>
<div class="form-item">
  <!-- Vendor type -->
  <mat-form-field appearance='outline' style="margin-bottom: 10px;">
    <mat-label>Típus</mat-label>
    <mat-select [formControl]="typeFC">
      <mat-option *ngFor="let vendorType of vendorTypes" [value]="vendorType.name">
        {{ vendorType.displayedName }}
      </mat-option>
    </mat-select>
    <mat-error>Kötelező mező!</mat-error>
  </mat-form-field>
</div>
<div class="form-item">
  <!-- Phone number -->
  <mat-form-field appearance='outline'>
    <mat-label>Telefonszám</mat-label>
    <input matInput [formControl]="phoneNumberFC" placeholder="+36301122333" />
    <mat-error>Hibás formátum! Elvárt: +36301122333</mat-error>
  </mat-form-field>
</div>
<div class="form-item">
  <mat-checkbox [disabled]="!isSzamlazzHuConnectionCheckboxEnabled" [formControl]="hasActiveSzamlazzHuConnectionFC">
    <span [style.pointer-events]="'all'" [matTooltipDisabled]="isSzamlazzHuConnectionCheckboxEnabled"
      [matTooltip]="'A viszonteladó nem küldött még kérelmet'">
      Van aktív számlázz.hu kapcsolata
    </span>
  </mat-checkbox>
</div>