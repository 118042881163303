import { Injectable } from "@angular/core";
import { CachedCriticalPoint, CriticalPointCacherService } from "src/app/services/cacher-services/critical-point-cacher.service";
import { CachedPracticePath, PracticePathCacherService } from "src/app/services/cacher-services/practice-path-cacher.service";

@Injectable()
export class PracticalModuleLogsEntityCacherHelperService {
  constructor(
    private practicePathCacherService:PracticePathCacherService,
    private criticalPointCacherService:CriticalPointCacherService
  ) {}

  /**
   * Gets the caching state in the `PracticePathCacherService` of a practice path with the given uuid.
   * 
   * @param practicePathUuid the uuid of the practice path
   * 
   * @returns the caching state of the practice path as `EntityCachingState`
   */
  public getPracticePathCachingState(practicePathUuid:string):EntityCachingState {
    if(this.practicePathCacherService.isPracticePathCached(practicePathUuid) == false) {
      return EntityCachingState.ErrorDuringQuery;
    }

    const cachedPracticePath:CachedPracticePath = this.practicePathCacherService.getPracticePathFromCache(
      practicePathUuid
    );

    return cachedPracticePath.isExists ? EntityCachingState.CachedAndExist : EntityCachingState.CachedAndNotExist;
  }

  /**
   * Gets the caching state in the `CriticalPointCacherService` of a critical point with the given uuid.
   * 
   * @param criticalPointUuid the uuid of the critical point
   * 
   * @returns the caching state of the critical point as `EntityCachingState`
   */
  public getCriticalPointCachingState(criticalPointUuid:string):EntityCachingState {
    if(this.criticalPointCacherService.isCriticalPointCached(criticalPointUuid) == false) {
      return EntityCachingState.ErrorDuringQuery;
    }

    const cachedCriticalPoint:CachedCriticalPoint = this.criticalPointCacherService.getCriticalPointFromCache(
      criticalPointUuid
    );

    return cachedCriticalPoint.isExists ? EntityCachingState.CachedAndExist : EntityCachingState.CachedAndNotExist;
  }
}

export enum EntityCachingState {
  CachedAndExist,
  CachedAndNotExist,
  ErrorDuringQuery
}