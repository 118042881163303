import { StopPoint } from "./../../../../../classes/model/practice-path";
import { PracticePathUtil } from "../../../../../services/practice-path-util";
import { GoogleMapPosition } from 'src/app/classes/model/google-map-position';
import { FormControl } from "@angular/forms";
import { MarkeredPolylineController } from "./../../google-map-extended/markered-polyline-controller";
import { GeoHelper } from "./../../../GeoHelper";
import { MarkerUtil } from "./../../google-map-extended/marker-util";
import { LatLng } from "./../../../models/LatLng";
import { BehaviorSubject } from "rxjs";
import { SingleMarkerController } from "./../../google-map-extended/single-marker-controller";
import { GoogleMap } from "@angular/google-maps";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  DoCheck,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  MarkerStyle,
  UnderlyingMarker,
} from "../../google-map-extended/marker-types";
import { CriticalPoint, PathItem } from "src/app/classes/model/practice-path";
import {
  ObservableArray,
  WritableObservableArray,
} from "../../../models/ObservableArray";
import { posix } from "path";
import { Writable } from "stream";

/// A kritikus pont térképen lévő pozícióját és hozzátartozó irányszöget ezen keresztül módosíthatjuk (Draggel, maga a térképen)
@Component({
  selector: "app-directional-angle-edit",
  templateUrl: "./directional-angle-edit.component.html",
  styleUrls: ["./directional-angle-edit.component.scss"],
})
export class DirectionalAngleEditComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @ViewChild(GoogleMap)
  googleMap!: GoogleMap;

  readonly zIndexForMarkers = {
    path: 0,
    otherCriticalPoint: 1,
    stopPoint: 2,
    criticalPoint: 3,
  };

  pathMarkeredPolylineControllers: MarkeredPolylineController[] = [];

  criticalPointController!: SingleMarkerController;
  private innerCriticalPointPosition$: BehaviorSubject<UnderlyingMarker>;

  activeStopPointsMarkers: SingleMarkerController[] = [];

  // Megállási pontok csak ezen a körön belül lehetnek
  criticalPointStopCircle: google.maps.Circle;

  // googleMap-ben lévő térkép
  map!: google.maps.Map;

  // A kritikus ponthoz tartozó alap pont aminek az x méteres sugarában lehetnek csak a megállási pontok
  // Erre azért van szükség, hogy maga a kritikus pont mozgatható legyen, a következő okokból:
  // 1, Nem akarjuk, hogy a kritikus pontok kitakarják egymást (nem tudnánk kijelölni a megfelelőt, elfednék egymást)
  // 2, Ha pontosabban tudjuk, hogy hol is van a kritikus pont a valóságban, akkor módosíthatunk a videóhoz tartozó gps koordináta becslésén
  // Az anchor point-nak a lényege, hogy habár a kritikus pontot mozgattuk, de azt csak pontosítás miatt tettük meg
  // a megállási pontoknak az eredeti pont (anchorPoint) környezetében kell lenniük.
  // Ha a kritikus ponthoz néznénk, akkor meglehetne azt csinálni, hogy egy videóhoz veszek egy globális K kritikus pontot
  // és veszek fel hozzá megállási pontokat. Majd ha egy másik videónál módosítanám a K pont pozícióját,
  // akkor az előző videóban a megállási pontok kívül eshetnének a K kritikus pont sugarán
  // így a megállási pontok érvénytelenek lennének, mivel azt mondtuk,
  // hogy a megállási pontoknak mindenképp egy adott sugaron belül kell létezniük
  // Amikor például tekerni szeretnénk, akkor ezeket a megállási pontokat
  // egy irányba se tudnánk eltekerni, hiszen alapból érvénytelen területen vannak
  @Input()
  criticalPointAnchor: {
    position: { latitude: number; longitude: number };
    radiusInMeter: number;
  } = { position: { latitude: 0, longitude: 0 }, radiusInMeter: 0 };

  anchorCircleOnMap!: google.maps.Circle;

  // A jelenlegi kritikus ponton kívüli pontok
  otherGlobalCriticalPointMarkerController : MarkeredPolylineController;
  otherLocalCriticalPointMarkerController: MarkeredPolylineController;

  @Input()
  showPathMarkers: boolean = false;

  // Az összes kritikus pont azon kívül amit éppen szerkesztünk
  // A hívó fél feladat olyan tömböt átadni amiben nincs benne!
  @Input()
  otherCriticalPoints: Array<CriticalPoint>;

  // Ebből a kritikus pontból indul ki a nyíl
  @Input()
  initialCriticalPointPosition: { latitude: number; longitude: number } = {
    latitude: 0,
    longitude: 0,
  };

  // Az útvonal amihez a kritikus pontot rendeltük hozzá
  @Input()
  path: PathItem[] = [];

  // Az irányszög szerkesztése bekapcsolva
  @Input()
  isDirectionAngleEditEnabled: boolean = false;

  // Jelenlegi bearingAngle
  @Input()
  initialBearingAngle?: number;

  @Input()
  selectedStopPointUuid: string;

  @Input()
  allStopPoints: BehaviorSubject<StopPoint[]> = new BehaviorSubject([]);

  // Jelenlegi bearingAngle event emitter a külső komponens számára
  // Ezen keresztül értesítjük a szülőt a változásról
  @Output()
  onChangeCurrentBearing = new EventEmitter<{
    angle: number;
    point: { latitude: number; longitude: number };
  }>();

  @Output()
  onChangeCriticalPointPosition = new EventEmitter<{
    latitude: number;
    longitude: number;
  }>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private editorService: PracticePathUtil
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.criticalPointController.dispose();
    this.pathMarkeredPolylineControllers.forEach((p) => p.dispose());
    this.otherLocalCriticalPointMarkerController.dispose();
    this.otherGlobalCriticalPointMarkerController.dispose();
    this.activeStopPointsMarkers.forEach((as) => as.dispose());

    google.maps.event.clearInstanceListeners(this.map);
    this.map = null;

    clearTimeout(this.renderStopPointMarkerSetTimeout);
  }

  ngOnChanges(changes: SimpleChanges) {
    // Ha változott az irányszög szerkesztés állapota, akkor frissítjük a controllert.
    // Ha false->true-ra vált akkor megjelenik egy nyíl amit mozgatva lehet változtatni az irányszöget
    if (changes.isDirectionAngleEditEnabled) {
      if (this.criticalPointController == null) return;

      const isDirectionAngleEditEnabled =
        changes.isDirectionAngleEditEnabled.currentValue;
      this.criticalPointController.setDirectionAngleEditEnabled(
        isDirectionAngleEditEnabled
      );
    }
    if (changes.selectedStopPointUuid) {
      this.renderStopPointsMarker();
    }
  }

  public ngAfterViewInit():void {
    // Az init adatból létrehozunk egy observable-t
    this.innerCriticalPointPosition$ = new BehaviorSubject<UnderlyingMarker>({
      position: new LatLng(
        this.initialCriticalPointPosition.latitude,
        this.initialCriticalPointPosition.longitude
      ),
    });

    // Google map init
    this.initGoogleMap();
    this._initMainCriticalPointMarker();
    this._initAnchorCircle();
    this._initCriticalPointStopCircle();
    this._initOtherCriticalPoints();
    this._initPathPolyline();
    this.renderStopPointsMarker();
    this.addCriticalPointPositionChangeListener();

    this.allStopPoints.subscribe(() => this.renderStopPointsMarker());
  }

  addCriticalPointPositionChangeListener() {
    // Változó amit a innerCriticalPointPosition subscribe-on belül használunk
    // Ha tiltott területre került a kritikus pont akkor ez alapján helyezzük vissza az előző pozira
    let lastKnownCriticalPoint: typeof this.criticalPointAnchor.position = {
      latitude: this.criticalPointAnchor.position.latitude,
      longitude: this.criticalPointAnchor.position.latitude,
    };
    this.innerCriticalPointPosition$.subscribe(
      (currentPosition: UnderlyingMarker) => {
        // Ha a jelenlegi pozíció kiment az anchor point sugarából, akkor az előző pontot beállítjuk
        // A zöld körön kívülre nem helyezhetjük a kritikus pontot
        const dist = GeoHelper.distance(
          currentPosition.position.latitude,
          currentPosition.position.longitude,
          this.criticalPointAnchor.position.latitude,
          this.criticalPointAnchor.position.longitude
        );

        const isForbiddenPoint = dist > this.criticalPointAnchor.radiusInMeter;

        if (isForbiddenPoint) {
          this.innerCriticalPointPosition$.next({
            position: {
              latitude: lastKnownCriticalPoint.latitude,
              longitude: lastKnownCriticalPoint.longitude,
            },
          });
        } else {
          lastKnownCriticalPoint = {
            latitude: currentPosition.position.latitude,
            longitude: currentPosition.position.longitude,
          };
          this.onChangeCriticalPointPosition.emit(currentPosition.position);
        }

        this.criticalPointStopCircle.setCenter({
          lat: this.innerCriticalPointPosition$.getValue().position.latitude,
          lng: this.innerCriticalPointPosition$.getValue().position.longitude,
        });
      }
    );
  }

  private _initCriticalPointStopCircle() {
    this.criticalPointStopCircle = new google.maps.Circle({
      strokeColor: "red",
      strokeOpacity: 0.45,
      strokeWeight: 2,
      fillColor: "red",
      clickable: false,
      fillOpacity: 0.06,
      map: this.map,
      center: {
        lat: this.innerCriticalPointPosition$.getValue().position.latitude,
        lng: this.innerCriticalPointPosition$.getValue().position.longitude,
      },
      radius: PracticePathUtil.criticalPointStopRadiusMeter,
    });
  }

  private _initAnchorCircle() {
    this.anchorCircleOnMap = new google.maps.Circle({
      strokeColor: "green",
      strokeOpacity: 0.45,
      strokeWeight: 2,
      fillColor: "green",
      clickable: false,
      fillOpacity: 0.04,
      map: this.map,
      center: {
        lat: this.criticalPointAnchor.position.latitude,
        lng: this.criticalPointAnchor.position.longitude,
      },
      radius: this.criticalPointAnchor.radiusInMeter,
    });
  }

  lastStopPointRenderTimestamp: number = 0;
  renderStopPointMarkerSetTimeout: any;
  private renderStopPointsMarker() {
    if (this.lastStopPointRenderTimestamp >= Date.now().valueOf() - 400) {
      // Ha 1000 ms-en belül volt renderelés, akkor várunk!
      this.renderStopPointMarkerSetTimeout = setTimeout(() => {
        // Megnézzük, hogy mikor volt az utolsó renderelés.
        if (this.lastStopPointRenderTimestamp >= Date.now().valueOf() - 400)
          return;
        this.renderStopPointsMarker();
      }, 400);
      return;
    }

    this.activeStopPointsMarkers.forEach((m) => m.dispose());
    this.activeStopPointsMarkers = [];

    // Ha 400ms alatt lezárták a streamet
    if (this.allStopPoints.closed) return;

    for (const stopPoint of this.allStopPoints.getValue()) {
      const posi = this.editorService.getClosestGeoTaggedFrameToVideoPosition(
        this.path,
        stopPoint.stopTimeInMs
      ).position;

      const newOne = new SingleMarkerController({
        isDraggable: false,
        clickable: false,
        iconStyle: MarkerUtil.getLabeledMarker(
          stopPoint.isActive ? "green" : "grey",
          "purple",
          stopPoint.stopPointUuid == this.selectedStopPointUuid ? "M" : "m",
          stopPoint.stopPointUuid == this.selectedStopPointUuid
            ? "black"
            : "transparent"
        ),
        shouldMapCenterFollowMarker: false,
        underlyingMarker: new BehaviorSubject({
          position: {
            latitude: posi.latitude,
            longitude: posi.longitude,
          },
        }),
        zIndex: this.zIndexForMarkers.stopPoint,
      });
      newOne.setMap(this.map);
      newOne.setOpacity(
        stopPoint.stopPointUuid == this.selectedStopPointUuid ? 0.9 : 0.8
      );
      this.activeStopPointsMarkers.push(newOne);
    }
    this.lastStopPointRenderTimestamp = Date.now().valueOf();
  }

  private _initPathPolyline() {
    const pathMarkerStyle: MarkerStyle = MarkerUtil.getCircleMarker(
      "orange",
      "orange",
      undefined,
      new google.maps.Size(12, 12),
      new google.maps.Point(6, 6)
    );

    // * Pathből csak a zöld körön belüli pontokat tartjuk meg!
    // * Több Polyline-ra lesz szükség, mert egy körbe jutás, majd kör elhagyás számít 1 polyline-nak
    // Tegyük fel, hogy bejutunk a körbe b1-nél, majd legközelebb k1-nél hagyjuk el
    // ekkor, ha újra belépünk b2-nél és csak 1 polyline lenne, k1 és b2 lenne összekötve
    // ami lehet, hogy egy vonalat eredményezne a teljes körön át (ami nem elvárt)
    // Ehelyett minden kör elhagyás után kezdjünk egy új polyline-t, különben
    // bugosan össze-vissza lennének húzva  hosszú vonalak és bizonyos szomszédos polyline pontok
    // nagyon messze lennének egymástól, mint például k1 és b2

    let shouldStartNew = false;
    let currentPolylinePoints: GoogleMapPosition[] = [];
    for (let i = 0; i < this.path.length; i++) {
      const pathItem = this.path[i];
      const d = GeoHelper.distance(
        pathItem.position.latitude,
        pathItem.position.longitude,
        this.criticalPointAnchor.position.latitude,
        this.criticalPointAnchor.position.longitude
      );
      const ok = d < this.criticalPointAnchor.radiusInMeter + 100;

      // Akkor mentjük el a polyline-t, ha elhagyjuk a kört, vagy ha az utolsó path pont
      if (
        (!ok || i == this.path.length - 1) &&
        currentPolylinePoints.length > 0
      ) {
        const newController = new MarkeredPolylineController(
          WritableObservableArray.fromPodArray(
            currentPolylinePoints.map((p) => ({ position: p }))
          ),
          {
            displayedName: "",
            id: "path",
            clickable: false,
            optimized: true,
            isDraggableMarkers: false,
            isMultiSelectable: false,
            polylineColor: "orange",
            maximumVisibleMarkerOnMap: 40,
            zIndex: this.zIndexForMarkers.path,
            opacity: 1.0,
            markerIconStyles: {
              globalStyle: pathMarkerStyle,
            },
          }
        );

        newController.setMap(this.map);

        newController.setMarkersVisibility(this.showPathMarkers);
        this.pathMarkeredPolylineControllers.push(newController);
        currentPolylinePoints = [];
      }

      // Kör elhagyásnál beállítjuk, hogy újat kezdhetünk amikor belépünk a körbe
      if (!ok) {
        shouldStartNew = true;
        continue;
      } else {
        // Benne vagyunk a körbe
        currentPolylinePoints.push(pathItem.position);
      }
    }
  }

  private _initMainCriticalPointMarker() {
    // Single marker hozzáadása az inicializált maphez
    // A criticalPointPosition observert figyelve mozgatja a térképen a markert
    this.criticalPointController = new SingleMarkerController({
      underlyingMarker: this.innerCriticalPointPosition$,
      iconStyle: MarkerUtil.getCircleMarker(
        "red",
        "red",
        "K",
        new google.maps.Size(30, 30),
        new google.maps.Point(15, 15)
      ),
      isDraggable: true,
      shouldMapCenterFollowMarker: false,
      initialDirectionAngle: this.initialBearingAngle,
      zIndex: this.zIndexForMarkers.criticalPoint,
    });
    this.criticalPointController.setMap(this.map);
    this.criticalPointController.setOpacity(0.75);
    this.criticalPointController.setDirectionAngleEditEnabled(
      this.isDirectionAngleEditEnabled
    );

    this.criticalPointController.directionAngle.subscribe(() => {
      const newArrowHeadPosition =
        this.criticalPointController.getArrowHeadPosition();
      this.onChangeCurrentBearing.emit({
        angle: this.criticalPointController.directionAngle.getValue(),
        point: {
          latitude: newArrowHeadPosition.lat,
          longitude: newArrowHeadPosition.lng,
        },
      });
    });

    this.changeDetectorRef.detectChanges();
  }

  private _initOtherCriticalPoints() {
    // Csak azokat a kritikus pontokat jelenítjük meg amik a körön belül vannak!
    const criticalPointInsideAnchorCircle = this.otherCriticalPoints.filter(
      (cp) => {
        const d = GeoHelper.distance(
          cp.coordinate.latitude,
          cp.coordinate.longitude,
          this.criticalPointAnchor.position.latitude,
          this.criticalPointAnchor.position.longitude
        );
        return d < this.criticalPointAnchor.radiusInMeter + 100;
      }
    );

   const otherGlobalCriticalPoints = criticalPointInsideAnchorCircle.filter(
      (cp) => !cp.isLocal
    );

    const otherLocalCriticalPoints = criticalPointInsideAnchorCircle.filter(
      (cp) => cp.isLocal
    );



    this.otherLocalCriticalPointMarkerController = new MarkeredPolylineController(
      WritableObservableArray.fromPodArray(
        otherLocalCriticalPoints.map((ocp) => ({
          position: ocp.coordinate,
        }))
      ),
      {
        displayedName: "",
        id: "otherPoints",
        isDraggableMarkers: false,
        isMultiSelectable: false,
        zIndex: this.zIndexForMarkers.otherCriticalPoint,
        minZoom: 1,
        clickable: false,
        optimized: true,
        opacity: 0.7,
        markerIconStyles: {
          // Szürke a lokális pont (ez a külső térképen nem látszódik, itt viszont kell, hogy ne tegye rá egy lokális pontra a térképen a kritikus pontot)
          globalStyle: MarkerUtil.getCircleMarker("grey", "grey"),
          specificMarkerStyles: [],
        },
        maximumVisibleMarkerOnMap: 40,
        showFirstAndLast: false,
      }
    );

    this.otherGlobalCriticalPointMarkerController = new MarkeredPolylineController(
      WritableObservableArray.fromPodArray(
        otherGlobalCriticalPoints.map((ocp) => ({
          position: ocp.coordinate,
        }))
      ),
      {
        displayedName: "",
        id: "otherPoints",
        isDraggableMarkers: false,
        isMultiSelectable: false,
        zIndex: this.zIndexForMarkers.otherCriticalPoint,
        minZoom: 1,
        clickable: false,
        optimized: true,
        opacity: 0.7,
        markerIconStyles: {
          globalStyle: MarkerUtil.getNotAssignedCriticalPointMarker(),
          specificMarkerStyles: [],
        },
        maximumVisibleMarkerOnMap: 40,
        showFirstAndLast: false,
      }
    );

    this.otherLocalCriticalPointMarkerController.setPolylineVisibility(false);
    this.otherLocalCriticalPointMarkerController.setMap(this.map);


    this.otherGlobalCriticalPointMarkerController.setPolylineVisibility(false);
    this.otherGlobalCriticalPointMarkerController.setMap(this.map);
  }

  // Térkép alap beállítások megadása
  initGoogleMap() {
    this.map = this.googleMap.googleMap!;

    this.map.setOptions({
      scrollwheel: true,
      zoomControl: true,
      clickableIcons: false,
      mapTypeControl: false,
      minZoom: 16,
      zoom: 18,
      fullscreenControl: false,
    });

    this.map.setCenter({
      lat: this.criticalPointAnchor.position.latitude,
      lng: this.criticalPointAnchor.position.longitude,
    });
  }
}
