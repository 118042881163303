import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Question } from 'src/app/classes/model/question';


@Component({
  selector: 'app-view-change',
  templateUrl: './view-change.component.html',
  styleUrls: ['./view-change.component.scss']
})
export class ViewChangeComponent implements OnInit, AfterViewInit {
  @Input() data:Question;
  @Input() compareTo:Question;
  @Input() differentColor:string;

  @ViewChild("rootDiv") rootDiv:ElementRef;

  sameColor:string;

  constructor() { }

  ngOnInit(): void {
    this.sameColor = "#000000";
  }

  ngAfterViewInit():void {
    let rootDivElement:HTMLDivElement = this.rootDiv.nativeElement;
    this.createCompareView(this.data, this.compareTo, rootDivElement);
  }

  createCompareView(dataObject:Object, compareToObject:Object, container:HTMLDivElement, level:number = 0):void {
    let propertyNames:Array<string> = new Array<string>();
    for(let properyName in dataObject) {
      if(dataObject.hasOwnProperty(properyName) == false)continue;
      propertyNames.push(properyName);
    }
    propertyNames.sort();

    for(let properyName of propertyNames) {
      let newPropertyChild:HTMLDivElement = document.createElement("div");
      for(let i = 0; i < level; ++i) {
        let newTabChild:HTMLSpanElement = document.createElement("span");
        newTabChild.setAttribute("style", "display: inline-block; width: 20px");
        newPropertyChild.appendChild(newTabChild);
      }

      let newPropertyNameChild:HTMLSpanElement = document.createElement("span");
      newPropertyNameChild.textContent = properyName + ": ";
      newPropertyNameChild.setAttribute("style", "font-weight: 500");
      newPropertyChild.appendChild(newPropertyNameChild);
      container.appendChild(newPropertyChild);

      if(typeof dataObject[properyName] === 'object') {
        let nestedCompareToObject = compareToObject && compareToObject[properyName] ? compareToObject[properyName] : null;
        this.createCompareView(dataObject[properyName], nestedCompareToObject, container, level + 1);
      } else {
        let newValueChild:HTMLSpanElement = document.createElement("span");
        newValueChild.textContent = dataObject[properyName];
        newPropertyChild.appendChild(newValueChild);
        if(compareToObject && compareToObject[properyName] !== null && dataObject[properyName] === compareToObject[properyName]) {
          newValueChild.setAttribute("style", "color: " + this.sameColor);
        } else {
          newValueChild.setAttribute("style", "color: " + this.differentColor);
        }
      }
    }
  }

}
