import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { PermissionString } from '../../classes/model/permissions';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private sessionService:SessionService
  ) { }

  /**
   * Determines if the logged user has the specified permission. If there is no logged in user
   * an exeption is thrown.
   *
   * @param permissionName the identifier name of the permission
   *
   * @returns true if the logged user has the given permission, false otherwise
   */
  public isLoggedUserHasPermission(permissionName:PermissionString):boolean {
    if(this.sessionService.isThereLocalSession() === false) {
      throw Error("No user session");
    }

    return this.sessionService.getPermissions()?.includes(permissionName) ?? false;
  }

  /**
   * Gets the logged user's permissions. If there is no logged in user an exeption is thrown.
   *
   * @returns an array of identifier names of the user's permission
   */
  public getLoggedUserPermissions():Array<PermissionString> {
    if(this.sessionService.isThereLocalSession() === false) {
      throw Error("No user session");
    }

    return  this.sessionService.getPermissions() ?? [];
  }
}
