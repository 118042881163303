<div class="flow-container">
  <div style="position:relative;" *ngFor="let iconUrl of this.iconUrls; let i = index;">
    <img (click)="onTapIcon.next(i)"
    [ngClass]="{
      'active-icon': this.selectedIconIndexes.includes(i)
    }"
    class="icon"
    [src]="iconUrl"/>

    <div *ngIf="this.selectedIconIndexes.includes(i)" class="record-circle">
    </div>
  </div>
</div>
