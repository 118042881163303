<mat-card class="data-card">
  <div class="practice-path-header">
    <div>
      <span *ngIf="practicePath.isFullPracticePath" class="full-practice-path-number">
        {{
        practicePath.examPathNumber != null
        ? practicePath.examPathNumber + 1
        : "-"
        }}
      </span>
      <span *ngIf="!practicePath.isFullPracticePath" class="practice-path-auto-increment-id">
        {{ practicePath.autoIncrementId ?? "?" }}
      </span>
      <span (click)="onTapPathName(imageDialogContent)" class="practice-path-name">
        {{ practicePath.name }}
      </span>
      <span style="vertical-align: middle" [matTooltip]="practicePath.uuid">
        <mat-icon style="font-size: 22px; height: 21px; width: 21px">
          info
        </mat-icon>
      </span>
    </div>
    <div *ngIf="practicePath.isFree" style="margin-right: 5px; display: flex; align-items: center;">
      <span class="material-icons" style="color: green; cursor: default;" matTooltip="Ingyenes útvonal">
        money_off
      </span>
    </div>
    <div class="header-critical-points">
      <span class="material-icons"> local_fire_department </span>
      <span class="number">
        {{ practicePath.criticalPointAssignments.length }}
      </span>
    </div>
    <div>
      <span class="item-edit-state-color-indicator" [ngStyle]="{
          'background-color': getEditStateColor(practicePath.editState)
        }" [matTooltip]="getEditStateName(practicePath.editState)" matTooltipPosition="above">
        <!-- Edit state color -->
      </span>
    </div>
    <div class="practice-path-button" style="margin-right: 10px"
      (click)="openNarrationsDuringRecordingDialog(practicePath)" [class.disabled]="!practicePath.audio"
      matTooltip="Belső hiba" [matTooltipDisabled]="practicePath.audio != undefined">
      <span class="material-icons"> mic </span>
    </div>

    <div class="practice-path-button" style="margin-right: 10px" (click)="openVideoManagerDialog(practicePath)"
      [class.disabled]="!userCanConvertVideos">
      <span class="material-icons"> android </span>
    </div>

    <div style="margin-right: 10px">
      <a [routerLink]="[practicePath.uuid, 'video-edit']">
        <div class="practice-path-button">
          <span class="material-icons"> videocam </span>
        </div>
      </a>
    </div>

    <div style="margin-right: 10px">
      <a [routerLink]="[practicePath.uuid, 'main-data-edit']">
        <div class="practice-path-button">
          <span class="material-icons"> edit </span>
        </div>
      </a>
    </div>

    <div style="margin-right:10px;" class="practice-path-button" (click)="openPracticePathLogs()">
      <span class="material-icons"> sticky_note_2 </span>
    </div>

    <div class="practice-path-button" (click)="handleDeleteButtonClick()" [class.disabled]="!userCanEdit">
      <span class="material-icons"> delete </span>
    </div>
  </div>
  <div class="item-data-row">
    <span class="item-data-title"> Zónák: </span>
    {{
    (practicePath.zoneUuids?.length ?? 0) > 0 ? this.getZonesString() : "-"
    }}
  </div>
  <div class="item-data-row">
    <div *ngIf="
        practicePath.video?.rawVideo == null &&
        practicePath.video?.rawVideoConvertedMinimalBitrate == null
      " style="
        background-color: 'grey';
        display: inline-block;
        color: white;
        padding: 3px;
      ">
      Nincs nyers videó és minimal bitrate se
    </div>

    <span *ngIf="
        practicePath.video?.rawVideo != null ||
        practicePath.video?.rawVideoConvertedMinimalBitrate != null
      " class="item-data-title">
      Nyers videó fájlnév:
      {{
      practicePath.video?.rawVideo?.originalFileName ??
      "Nincs eredeti nyers videó név."
      }}
    </span>

    <span *ngIf="practicePath.video?.rawVideo?.videoUrl != null" class="video-minimal-bitrate-badge">Eredeti
      bitrate</span>

    <span *ngIf="hasMinimalBitrateVideo()" class="video-minimal-bitrate-badge">Minimal bitrate</span>
  </div>
  <div class="item-data-row" *ngIf="hasReleasedVideo()">
    🎬 Rendelkezik kiadott videóval 🎬
  </div>
  <div>
    <span *ngIf="practicePath.video?.releasedVideo == null" class="item-data-row" style="
        display: block;
        background-color: 'red';
        display: inline-block;
        color: white;
        padding: 3px;
      ">
      Kiadott videó hiányzik
    </span>
  </div>

  <div class="item-data-row">
    🤖 Anonimizált: {{ practicePath.video?.anonymizedVideos?.length ?? 0 }} 🆗
    Véglegesített: {{ practicePath.video?.finalVideos?.length ?? 0 }}
  </div>
  <div class="item-data-row">
    <ng-container *ngIf="
        practicePath.criticalPointAssignments.length > 0;
        then hasCriticalPoint;
        else hasNoCriticalPoint
      ">
    </ng-container>

    <ng-template #hasCriticalPoint>
      <span>
        <ng-container *ngIf="
            numberOfNonVerifiedCriticalPoints === 0;
            then allCriticalPointVerified;
            else hasNonVerifiedCriticalPoint
          ">
        </ng-container>

        <ng-template #allCriticalPointVerified>
          <span> ✅ Minden kritikus pont hitelesítve </span>
        </ng-template>

        <ng-template #hasNonVerifiedCriticalPoint>
          <span style="color: rgb(180, 120, 12)">
            ⚠️ {{ numberOfNonVerifiedCriticalPoints }} nem hitelesített kritikus
            pont az útvonalban
          </span>
        </ng-template>
      </span>
      <span style="display: inline-block; margin: 0 5px"> | </span>
      <span>
        <ng-container *ngIf="
            numberOfCriticalPointsWithoutStopPoint === 0;
            then allCriticalPointHasStopPoint;
            else criticalPointsWithoutStopPoint
          ">
        </ng-container>

        <ng-template #allCriticalPointHasStopPoint>
          <span> ✅ Minden kritikus rendelkezik megállási ponttal </span>
        </ng-template>

        <ng-template #criticalPointsWithoutStopPoint>
          <span style="color: rgb(180, 120, 12)">
            ⚠️ {{ numberOfCriticalPointsWithoutStopPoint }} megállási pont
            nélküli kritikus pont az útvonalban
          </span>
        </ng-template>
      </span>
    </ng-template>

    <ng-template #hasNoCriticalPoint>
      ℹ️ Az útvonal nem rendelkezik kritikus ponttal
    </ng-template>
  </div>

  <div class="practice-path-footer">
    <span> Felvette: {{ practicePath.created.adminEmail }} </span>
    <span style="justify-self: end">
      {{ practicePath.created.timestamp | date : "YYYY. MM. dd. HH:mm:ss" }}
    </span>
  </div>
</mat-card>


<ng-template #imageDialogContent>
  <div *ngIf="this.practicePath.pathStaticMapLightImageUrl == null">
    Nincs még útvonal hozzárendelve
  </div>

  <img style="display:block;width:640px;" *ngIf="this.practicePath.pathStaticMapLightImageUrl != null"
    [src]="this.practicePath.pathStaticMapLightImageUrl">
</ng-template>