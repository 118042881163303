<div id = "create-gift-dialog">
    <div id = "create-gift-dialog-title">
        Új ajándék létrehozása
    </div>
    <form [formGroup] = "giftFG">

        <div>
            <mat-form-field appearance='outline' style = "width: 100%; margin-bottom: 20px;">
                <mat-label>Leírás</mat-label>
                <input matInput [formControl] = "messageFC" required />
                <mat-hint> Ezt látják a felhasználók. </mat-hint>
                <mat-error>Kötelező mező!</mat-error>
            </mat-form-field>
        </div>
        <app-package-definition-selector
            [formControl] = "packageDefinitionFC"
            ngDefaultControl
            style = "margin-bottom: 10px;"
        ></app-package-definition-selector>
        <div>
                <mat-form-field appearance='outline'>
                <mat-label>Megkapható eddig</mat-label>
                <input
                    matInput
                    [matDatepicker] = "picker"
                    [formControl] = "receivableUntilFC"
                    [min] = "minDate"
                />
                <mat-datepicker-toggle matSuffix [for] = "picker"></mat-datepicker-toggle>
                <mat-datepicker #picker disabled = "false"></mat-datepicker>
            </mat-form-field>
        </div>

    </form>
    <mat-dialog-actions align="end">
        <button mat-flat-button mat-dialog-close style = "margin-right: 10px;"> Bezár </button>
        <button mat-flat-button color = "primary" [disabled] = "giftFG.invalid" (click) = "handleCreateGiftButtonClick()" mat-dialog-close> Ajándék létrehozása </button>
    </mat-dialog-actions>
</div>
