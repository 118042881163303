<div class = "title">
    {{ data.title }}
</div>
<div class = "info">
    {{ data.infoString }}
</div>

<div class = "dialog-content">
    <ng-template [ngIf] = "!isContentLoading">
        <div *ngFor = "let permissionAffect of displayedRemovedPermissionAffects">
            <div class = "permission-name">
                {{ permissionAffect.permission.displayedName }}
            </div>
            <div class = "admins-list">
                <div *ngFor = "let affectedAdminUser of permissionAffect.affectedAdminUsers">
                    <mat-checkbox
                        [checked] = "affectedAdminUser.isAffected"
                        (change) = "onCheckboxChange($event, affectedAdminUser)"
                    >
                    </mat-checkbox>
                    {{ affectedAdminUser.adminUser.username }}
                </div>
                <div *ngIf = "permissionAffect.affectedAdminUsers.length === 0" class = "no-admin-affected">
                    Nincs hatással egyetlen adminisztrátorra sem.
                </div>
            </div>
        </div>
    </ng-template>
    <div *ngIf = "isContentLoading">
        <mat-spinner></mat-spinner>
    </div>
</div>

<mat-dialog-actions align = "end" style = "padding-top: 10px;">
    <button
        mat-raised-button
        mat-dialog-close
    >
        Bezár
    </button>
    <button
        mat-raised-button
        color = "primary"
        (click) = "handleSaveButtonClick()"
    >
        Mentés
    </button>
</mat-dialog-actions>