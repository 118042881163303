<app-page-header
    title = "Útvonalak"
    subtitle = "A forgalmi felkészítő modulhoz tartozó rövid és hosszú videókat találhatod itt."
    style = "margin-bottom: 20px;"
></app-page-header>

<ng-container
    *ngIf = "practicePageInitializationState === PracticePageInitializationState.SucessfullyInitialized;
    then componentSuccessfullyInitialized;
    else componentNotInizialized"
></ng-container>

<!-- Guard -->
<ng-template #componentNotInizialized>
    <div [ngSwitch]="practicePageInitializationState">
        <!-- Loading -->
        <div *ngSwitchCase = "PracticePageInitializationState.Loading" style = "margin-top: 50px;">
            <mat-spinner style = "margin: 0 auto;"></mat-spinner>
            <div style = "text-align: center; margin-top: 20px;">
                Útvonalak betöltése a vizsgahelyszínben
            </div>
        </div>
        <!-- Errors -->
        <span *ngSwitchCase = "PracticePageInitializationState.ErrorDuringFetchingCityNames">
            Hiba a  nevek betöltésekor.
        </span>
        <span *ngSwitchCase = "PracticePageInitializationState.AdminIsNotAssignedToAnyCityError">
            Jelenleg nem vagy hozzárendelve egyetlen útvonalhoz sem.
        </span>
        <span *ngSwitchCase = "PracticePageInitializationState.ErrorDuringFetchingCityDetails">
            Hiba a vizsgahelyszín adatok betöltése közben.
        </span>
        <span *ngSwitchCase = "PracticePageInitializationState.AdminIsNotAssignedToCityError">
            Nem vagy hozzárendelve a vizsgahelyszínhez tartalom felelősként.
        </span>
        <span *ngSwitchDefault>
            Hiba történt az adatok betöltése közben.
        </span>
    </div>
    <div
        *ngIf = "practicePageInitializationState !== PracticePageInitializationState.Loading"
        style = "margin-top: 10px;"
    >
        Kattints a lent található újratöltés gombra. Ha nem segít, írj egy levelet a tapasztalt problémával az info&#64;mrkresz.hu címre!
        <div  style = "margin-top: 10px;">
            <button
                mat-raised-button
                color = "primary"
                (click) = "initialize()"
            >
                <mat-icon> refresh </mat-icon>
                Újratöltés
            </button>
        </div>
    </div>
</ng-template>

<ng-template #componentSuccessfullyInitialized>
    <div class = "filter-container">
        <div class = "filter-container-inner">
            <app-practice-path-filter
                (initialized) = "onFilteringInitialized()"
            ></app-practice-path-filter>
            <app-practice-path-sorting-selector
                (initialized) = "onSortingInitialized()"
            ></app-practice-path-sorting-selector>
        </div>

        <div style = "font-size: 0.8em; color:gray;">
            <span *ngIf = "(isContentLoading$ | async) === false">
                {{ (practicePathsPageDataService.filteredPracticePathBaseInformations$ | async).length }} találat a szűrésre
            </span>
            <span *ngIf = "(isContentLoading$ | async) === true">
                Betöltés...
            </span>
        </div>
    </div>

    <div
        *ngIf = "isContentLoading$ | async">
        <mat-spinner style = "margin: 30px auto 0;"></mat-spinner>
    </div>

    <div
        *ngIf = "(isContentLoading$ | async) === false &&
            (practicePathsPageDataService.filteredPracticePathBaseInformations$ | async).length === 0"
        class = "section-no-data-available"
    >
        Nincs megjeleníthető adat.
    </div>

    <div
        *ngIf = "(isContentLoading$ | async) === false"
        class = "section-data"
    >
        <app-practice-path-card
            *ngFor = "let practicePath of (practicePathsPageDataService.filteredPracticePathBaseInformations$ | async)"
            [practicePath] = "practicePath"
            [practiceCity] = "practicePathsPageDataService.getSelectedCity()"
        ></app-practice-path-card>
    </div>
    <div *ngIf = "userCanEdit" class = "section-add-item">
        <a [routerLink] = "'./create-new-practice-path'">
            <button mat-fab color = "primary">
                <mat-icon> add </mat-icon>
            </button>
        </a>
    </div>
</ng-template>
