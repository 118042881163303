import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AnonymizerTaskReference } from 'src/app/classes/model/anonymizer-task';
import { AnonymizerTaskLogsDialogComponent, AnonymizationProcessLogsDialogInput } from '../../../practice-paths/practice-video-manager-dialog/anonymized-videos-tab/anonymizer-task-logs-dialog/anonymizer-task-logs-dialog.component';
import { AnonymizerTasksPageService } from '../../services/anonymizer-tasks-page.service';
import { PracticeCityCacherService } from '../../../../../../../services/cacher-services/practice-city-cacher.service';
import { PracticePathCacherService } from '../../../../../../../services/cacher-services/practice-path-cacher.service';

@Component({
  selector: 'app-anonymizer-tasks-table',
  templateUrl: './anonymizer-tasks-table.component.html',
  styleUrls: ['./anonymizer-tasks-table.component.scss']
})
export class AnonymizerTasksTableComponent implements OnInit {

  constructor(
    protected anonymizerTasksPageService:AnonymizerTasksPageService,
    protected practicePathCacherService:PracticePathCacherService,
    protected practiceCityCacherService:PracticeCityCacherService,
    private dialogService:MatDialog
  ) { }

  ngOnInit(): void { }

  /**
   * Handles the "Open logs" button click. It opens the dialog to show the given task's actual logs.
   * 
   * @param taskId the id of the task
   */
  protected onOpenAnonymizerTaskLogsButtonClick(taskId:string):void {
    this.dialogService.open<
      AnonymizerTaskLogsDialogComponent,
      AnonymizationProcessLogsDialogInput
    >(
      AnonymizerTaskLogsDialogComponent,
      {
        data: {
          taskId: taskId
        }
      }
    );
  }

  /**
   * Constructs the navigation command for a practice path. The command navigates from the anonymizing processes
   * page to the path's main data editor.
   * 
   * @param resolvedPracticePath the resolved practice path to navigate to
   * 
   * @returns the navigation commands
   */
  protected getRelativeRouterLinkToPracticePath(cityUuid:string, practicePathUuid:string):Array<string> {
    return [ "..", "cities", cityUuid, "paths", practicePathUuid, "main-data-edit" ]
  }

  /**
   * Constructs the navigation command for a practice city. The command navigates from the anonymizing processes
   * page to the city's paths view.
   * 
   * @param resolvedPracticePath the resolved practice path to navigate to
   * 
   * @returns the navigation commands
   */
   protected getRelativeRouterLinkToPracticeCity(cityUuid:string):Array<string> {
    return [ "..", "cities", cityUuid, "paths" ]
  }


  protected getCityUuidOfAnonymizerTask(anonymizerTaskReference:AnonymizerTaskReference):string|null {
    return this.practicePathCacherService.getPracticePathFromCache(
      anonymizerTaskReference.metadata?.practicePathUuid
    )?.practicePath?.cityUuid ?? null;
  }

  protected isPracticeCityFetchingInProgressOfTask(anonymizerTaskReference:AnonymizerTaskReference):boolean {
    // If the task's path is not resolved, the city couldn't be resolved
    if(this.practicePathCacherService.isPracticePathCached(anonymizerTaskReference.metadata?.practicePathUuid) == false) {
      return false;
    }

    // Get the cityUuid of the task's path (null returned, when the path is unresolvable)
    const cityUuid:string|null = this.getCityUuidOfAnonymizerTask(anonymizerTaskReference);
    // If the path was unresolvable, the city resolving progress halted (like it's unresolvable)
    if(cityUuid == null) {
      return true;
    }

    // Return the 
    return this.practiceCityCacherService.isPracticeCityCached(cityUuid)
  }

}
