import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
  HostListener,
  ComponentFactoryResolver,
  OnDestroy,
} from "@angular/core";

@Component({
  selector: "focus-video-overlay",
  templateUrl: "./focus-video-overlay.component.html",
  styleUrls: ["./focus-video-overlay.component.scss"],
})
export class FocusVideoOverlayComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  // A canvas szélességéhez képest relatív adja meg a kör közepének X koordinátáját
  @Input()
  relativeCenterXToWidth?: number = 0.5;

  // A canvas magasságához képest relatív adja meg a kör közepének Y koordinátáját
  @Input()
  relativeCenterYToHeight?: number = 0.5;

  // A canvas magasságához képest relatív adja meg a kör sugarát
  @Input()
  relativeRadiusToHeight?: number = 0.2;

  // Láthótó legyen-e a canvas
  // Ha false, akkor nem renderel semmi a canvas-ra
  @Input()
  isVisible: boolean = true;

  // canvas html element
  @ViewChild("canvas")
  canvas!: ElementRef<HTMLCanvasElement>;

  // Ennek az observernek a segítségével méretezzük át a canvas-t, ha változik a szülő mérete
  // Erre azért van szükség, mert az overlay-eket dinamikusan adjuk hozzá a DOM-hoz:
  // A komponensekhez (overlay component) tartozó nativeElement-et adjuk hozzá egy 3rdparty lejátszóhoz
  // ezért a komponens létrehozása pillanatában (ngAfterViewInit) még nem lesz beszúrva a DOM-ba
  // mivel nem lesz beszúrva, így nem is lesz mérete a canvas-nak, ezért ameddig nem kerül beszúrásra
  // a 3rdparty div-nek a gyerekeként, addig nem lesz mérete a canvas-nak. (Ha nincs mérete, akkor InvalidState errort kapunk draw esetén)
  // ezért volt szükség a resizeObserver-re. Amikor a DOM-ba beszúródik az overlay-hez tartozó nativeElement, akkor a
  // host div (elementRef.nativeElement) és canvas mérete megváltozik
  // hiszen null szülő helyett, most már a 3rdparty div lesz a szülője, aminek lesz mérete
  // ezt a változást pedig a ResizeObserver továbbítja nekünk, ami után elvégezzük a canvas-ban a renderelést
  resizeOb!: ResizeObserver;

  constructor(private elementRef: ElementRef) {}

  ngOnDestroy(): void {
    this.resizeOb.unobserve(this.canvas.nativeElement);
    this.resizeOb.unobserve(this.elementRef.nativeElement);
    this.resizeOb.disconnect();
    this.resizeOb = null;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.canvas == undefined) return;
    this._draw();
  }

  // shorthand drawFocus-nak
  _draw() {
    this.drawFocus(
      this.relativeCenterXToWidth ?? 0,
      this.relativeCenterYToHeight ?? 0,
      this.relativeRadiusToHeight ?? 0.5
    );
  }

  ngAfterViewInit() {
    const thisObj = this;
    // A canvas és maga az overlay-re ResizeObserver
    this.resizeOb = new ResizeObserver(() => {
      // ResizeObserver callback hívása alatt nem generálhatunk újabb
      // resize eventet, ezért setTimeout-ot kell használnunk (draw-on belül canvas méreteződhet)
      // hogy az event loopba tegyük a draw hívását
      // és resize eventtől függetlenül hívjuk meg
      setTimeout(() => {
        thisObj._draw();
      }, 0);
    });

    this.resizeOb.observe(this.elementRef.nativeElement);
    this.resizeOb.observe(this.canvas.nativeElement);

    thisObj._draw();
  }

  drawFocus(
    circleCenterRelativeXToWidth: number,
    circleCenterRelativeYToHeight: number,
    circleRelativeRadiusToHeight: number
  ) {
    const s = this.elementRef.nativeElement.getBoundingClientRect();
    // Akkor lehet 0 az értéke, ha még nincs szülője az overlay-nek. Ebben az esetben draw esetén InvalidState-et kapnánk
    // ez akkor lehet, ha renderer-el szúrjuk be a komponenshez tartozó nativeElement-et egy div-be
    // és azután szúrjuk be az elemet miután az ngAfterViewInit lefutott
    // (tehát angular szinten inicializálódott a komponens, de csak utána került beszúrásra a DOM-ba)
    // Tehát ha még nincs beszúrva, akkor nincs is szülője a nativeElement-nek
    // ezért mivel a mérete a hostnak relatív 100%, nem lesz mérete.
    if (s.height == 0) return;

    if (this.canvas.nativeElement.height != s.height)
      this.canvas.nativeElement.height = s.height;

    if (this.canvas.nativeElement.width != s.width)
      this.canvas.nativeElement.width = s.width;

    const canvas = this.canvas.nativeElement;


    const circleRadius = canvas.height * circleRelativeRadiusToHeight;
    const circleX = circleCenterRelativeXToWidth * canvas.width;
    const circleY = circleCenterRelativeYToHeight * canvas.height;

    const ctx = canvas.getContext("2d")!;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (!this.isVisible) return;

    ctx.fillStyle = "rgba(120,120,120,0)";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const mask = document.createElement("canvas");
    mask.width = canvas.width;
    mask.height = canvas.height;

    const maskCtx = mask.getContext("2d")!;
    maskCtx.fillStyle = "rgba(0,0,0,0.63)";
    maskCtx.fillRect(0, 0, mask.width, mask.height);
    maskCtx.globalCompositeOperation = "destination-out";
    maskCtx.arc(circleX, circleY, circleRadius, 0, 2 * Math.PI);
    maskCtx.fill();

    ctx.drawImage(mask, 0, 0);
  }
}
