<div id="uploadDialogHeader">
  <button (click)="onTapDialogClose()" mat-raised-button color="primary">
    Bezárás
  </button>

</div>

<mat-tab-group #tabGroup mat-align-tabs="center">
  <mat-tab label="Teljes videó feltöltés (kb 50 perc)">
    <app-upload-full-video-tab [practicePath]="data?.practicePath"></app-upload-full-video-tab>
  </mat-tab>

  <mat-tab label="Részvideók feltöltése (Kicsi, 4gb videók)">
    <app-upload-sub-videos-tab></app-upload-sub-videos-tab>
  </mat-tab>
</mat-tab-group>
