export class DistributionRule {
    questions:string[];
    point:number;
}

/**
 * @description Represents a category.
*/
export class Category {
    uuid:string;
    name:string;
    description:string;
    minPointFullTest:number;
    maxPointFullTest:number;
    minPointThematicTest:number;
    numberOfQuestionsOfFullTest:number;
    numberOfQuestionsOfThematicTest:number;
    maxTimeForOneQuestionInMs:number;
    distributionRules:DistributionRule[];
    questionGroups:string[];
    isVisible:boolean;
    iconImageUrl:string;
    headerImageUrl:string;
    isFree:boolean;
    underDevelopment:boolean;
}

export function categorySorter(category1:Category, category2:Category) {
    return category1.name.localeCompare(category2.name);
}