<!-- Container depend on InitState -->
<ng-container *ngIf = "initState === InitState.SUCCESS; then initSuccess; else loadingOrInitError"></ng-container>

<!-- Guard for error and loading -->
<ng-template #loadingOrInitError>
  <div class="section-title">
    Útvonal adat szerkesztő
  </div>

  <div [ngSwitch]="initState">
    <div *ngSwitchCase = "InitState.LOADING" style = "padding-top: 20px;">
      <mat-spinner style = "margin: 0 auto;"></mat-spinner>
      <div style = "text-align: center; margin-top: 20px;">
        Útvonal betöltése
      </div>
    </div>
    <span *ngSwitchCase = "InitState.ERROR"> {{ defaultErrorMessage }} </span>
    <span *ngSwitchCase = "InitState.CITIES_LOADING_ERROR"> Hiba az információk lekérdezése közben. </span>
    <span *ngSwitchCase = "InitState.NOT_EXIST_CITY"> A betölteni kívánt vizsgahelyszín nem létezik. </span>
    <span *ngSwitchCase = "InitState.NOT_EXIST_PATH"> A betölteni kívánt útvonal nem létezik. </span>
    <span *ngSwitchCase = "InitState.NO_ACCESS"> Nincs hozzáférésed az útvonalhoz. </span>
  </div>
  <div *ngIf = "initState !== InitState.LOADING" style = "margin-top: 10px;">
    Kattints az alábbi gombra az újrapróbáláshoz:
    <div style = "margin-top: 10px;">
      <button
        mat-raised-button
        color = "primary"
        (click) = "initialize()"
      >
        <mat-icon> refresh </mat-icon>
        Újratöltés
      </button>
    </div>
  </div>
</ng-template>

<!-- Content -->
<ng-template #initSuccess>

  <!-- Header -->
  <div class = "header">
    <div class="section-title">
      Útvonal adat szerkesztő
    </div>
    <div class = "header-buttons-container">
      <button
        *ngIf=" this.practicePathGlobalVideoEditorPageService.determineVideoUrl(practicePath).type != 'NONE' && practicePath.isFullPracticePath"
        (click)="onTapEditNodeTouchesTime()"
        [color]="isNodeTouchesMissing() ? 'warn' : 'primary'"
        mat-raised-button
      >
        Érintési pontok szerkesztése
        <span *ngIf="isNodeTouchesMissing()">⚠️</span>
      </button>

      <button
        (click)="onTapVideoCut()"
        color="primary"
        mat-raised-button
      >
       <mat-icon> view_array </mat-icon>
        Videó kivágó
      </button>

      <button
        *ngIf="practicePath.video != null && userCanConvertVideos"
        (click)="openVideoManagerDialog()"
        color="primary"
        mat-raised-button
      >
        <mat-icon> android </mat-icon>
        Videó manager
      </button>

      <button
        *ngIf="practicePathGlobalVideoEditorPageService.determineVideoUrl(practicePath).type != 'NONE'"
        (click)="onTapGoToVideoEditor()"
        color="primary"
        mat-raised-button
      >
        <mat-icon> camera </mat-icon>
        Videó szerkesztő
        <mat-icon> arrow_right </mat-icon>
      </button>
    </div>
  </div>

  <!-- City and uuid -->
  <div>
    <div style="font-weight: 600; font-size: 18px">
      {{ currentCity.city }}
    </div>
    <div style="font-size: 16px">
      Útvonal azonosító: {{ practicePath.uuid }}
    </div>
    <div>
      <ng-container *ngIf = "practicePath.criticalPointAssignments.length > 0; then hasCriticalPoint; else hasNoCriticalPoint">
      </ng-container>

      <ng-template #hasCriticalPoint>
        <div>
          <ng-container *ngIf = "numberOfNonVerifiedCriticalPoints === 0; then allCriticalPointVerified; else hasNonVerifiedCriticalPoint">
          </ng-container>

          <ng-template #allCriticalPointVerified>
            <span>
              ✅ Minden kritikus pont hitelesítve
            </span>
          </ng-template>

          <ng-template #hasNonVerifiedCriticalPoint>
            <span style = "color: rgb(180, 120, 12);">
              ⚠️ {{ numberOfNonVerifiedCriticalPoints }} nem hitelesített kritikus pont az útvonalban
            </span>
          </ng-template>
        </div>
        <div>
          <ng-container
            *ngIf = "numberOfCriticalPointsWithoutStopPoint === 0;
              then allCriticalPointHasStopPoint;
              else criticalPointsWithoutStopPoint"
          >
          </ng-container>

          <ng-template #allCriticalPointHasStopPoint>
            <span>
              ✅ Minden kritikus rendelkezik megállási ponttal
            </span>
          </ng-template>

          <ng-template #criticalPointsWithoutStopPoint>
            <span style = "color: rgb(180, 120, 12);">
              ⚠️ {{ numberOfCriticalPointsWithoutStopPoint }} megállási pont nélküli kritikus pont az útvonalban
            </span>
          </ng-template>
        </div>
      </ng-template>

      <ng-template #hasNoCriticalPoint>
        ℹ️ Az útvonal nem rendelkezik kritikus ponttal
      </ng-template>


    </div>
  </div>


  <!-- Main data editor -->
  <app-practice-path-main-data-editor
    #mainDataEditor
    style="display: block; margin-top: 20px"
    [practiceCitiesBaseInformations]="practiceCitiesBaseInformations"
    [practicePath]="practicePath"
  ></app-practice-path-main-data-editor>

  <!-- Main data actions -->
  <div style="display: flex; justify-content: space-between">
    <button
      (click)="onTapSave()"
      style="margin-top: 15px; width: 190px"
      mat-raised-button
      color="primary"
    >
      <mat-icon style="margin-right: 10px"> cloud_upload </mat-icon>
      Adatok mentése
    </button>

    <button
      (click)="onTapDelete()"
      style="margin-top: 15px; width: 170px"
      mat-raised-button
      color = "warn"
    >
      <mat-icon >delete</mat-icon>
      Útvonal törlése
    </button>
  </div>


  <br>
  <mat-divider></mat-divider>

  <!-- Video preview with upload -->
  <app-practice-path-video-preview-with-upload
    style="margin-top: 25px"
    [currentPracticePath]="practicePath"
  ></app-practice-path-video-preview-with-upload>

  <br>
  <mat-divider></mat-divider>
  <br>
  <!-- Comments on entity -->
  <div class = "comments-container">
    <app-comments-on-entity
      [entityType] = "'practice_path'"
      [entityKeyValue] = "practicePath.uuid"
    ></app-comments-on-entity>
  </div>
</ng-template>
