import { MrvegasMetadataViewerModule } from './../mrvegas-metadata-viewer.module';
import { MrVegasVideoMetadata } from './../models/mrvegas-video-metadata';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class MrVegasMetadataFileLoader{
  async checkValidityAndLoadMetadataFromFile(file: File): Promise<MrVegasVideoMetadata>{
    const fileContent = await file.arrayBuffer();
    const asJson = JSON.parse(new TextDecoder().decode(fileContent));

    const metadataFileProperties = [
      "geoTaggedFramesCount",
      "durationInMs",
      "frameCount",
      "frameRateNumerator",
      "frameRateDenumerator",
      "heightPixel",
      "widthPixel",
      "geoTaggedFrames"
    ];

    for(const key of metadataFileProperties ){
      if(asJson[key] == null){
        throw new Error(`MISSING_PROPERTY FROM FILE: ${key}`);
      }
    }

    return asJson as MrVegasVideoMetadata;
  }
}
