import { Injectable } from '@angular/core';
import { CustomEnvironmentService } from './custom-environment.service';
import { PredefinedEnvironmentType } from './environment';
import { PredefinedEnvironmentService } from './predefined-environment.service';
import { Settings, SettingsService } from '../settings.service';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EnvironmentLoaderService {

  constructor(
    private predefinedEnvironmentService: PredefinedEnvironmentService,
    private customEnvironmentService: CustomEnvironmentService,
    private settingsService: SettingsService
  ) { }

  /**
   * Loads the initial environment. If a (valid) environment is saved in the local storage, it loads that, otherwise it loads
   * the default environment for the actual Angular hosting environment.
   */
  public load(): void {
    // Get the saved environment from the local storage
    let environment:any = this.settingsService.getSettingsValue(Settings.savedEnvironment);

    // Check if the environment exists and it has a string type field
    if (environment == null || typeof environment.type !== "string") {
      // If any of that is not fullfilled, load the default environment
      this.loadDefaultEnvironment();
      return;
    }

    const environmentType = environment.type as string; // This is guaranteed because of the previous check
    // Check if the environment's type is custom or not
    if (environmentType === this.customEnvironmentService.getCustomEnvironmentTypeValue()) {
      // If the environment's type is custom, load it as a custom environment
      this.loadCustomEnvironment(environment);
    } else {
      // Otherwise load it as a predefined environment
      this.loadPredefinedEnvironment(environmentType);
    }
  }

  /**
   * Returns the default environment type for the actual Angular hosting environment.
   * 
   * @returns the predefined default environment type
   */
  private getDefaultPredefinedEnvironmentType(): PredefinedEnvironmentType {
    return environment.defaultServerEnvironmentType;
  }

  /**
   * Loads the default environment.
   */
  private loadDefaultEnvironment(): void {
    // Get the default predefined environment type's name
    const defaultEnvironmentType:PredefinedEnvironmentType = this.getDefaultPredefinedEnvironmentType();
    // Load the environment as a predefined environment (it always is a predefined one)
    this.predefinedEnvironmentService.loadPredefinedEnvironment(defaultEnvironmentType);
  }

  /**
   * Tries to load the provided environment as a custom environment. If it can't load it (because of the `environment`
   * is being malformed), it loads the default one.
   * 
   * @param environment the custom environment
   */
  private loadCustomEnvironment(environment: any): void {
    // Check if the environment has a non-empty serverHost string fields
    if (typeof environment?.serverHost !== "string" || environment?.serverHost === "") {
      // If no, load the default environment
      this.loadDefaultEnvironment();
      return;
    }

    // Load the environment as a custom environment with the serverHost's value
    this.customEnvironmentService.setCustomEnvironment(environment.serverHost);
  }


  // Megpróbálja betölteni az environmentType predefined env-et
  // ha nincs ilyen environmentType akkor betölti a default környezetet!

  /**
   * Tries to load the provided environment type as a predefined environment. If it can't load it (because of the environment
   * type is not a predefined environment, it loads the default one.
   * 
   * @param environmentType the predefined environment type
   */
  private loadPredefinedEnvironment(environmentType: string) {
    // Check if the environment type is a valid predefined one
    if (this.predefinedEnvironmentService.isValidPredefinedType(environmentType) == false) {
      // If no, load the default environment
      this.loadDefaultEnvironment();
    } else {
      // Otherwise load the envirionment with the given predefined environment type
      const predefinedEnvironmentType = environmentType as PredefinedEnvironmentType; // This is guaranteed because of the previous check
      this.predefinedEnvironmentService.loadPredefinedEnvironment(predefinedEnvironmentType);
    }
  }
}
