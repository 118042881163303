import { Component, Input, OnInit } from '@angular/core';
import { EntityType } from './models/entity-types';
import { CommentsOnEntityModuleService, CommentsOnEntityServiceError, CommentsOnEntityServiceErrorType } from './services/comments-on-entity.service';

@Component({
  selector: 'app-comments-on-entity',
  templateUrl: './comments-on-entity.component.html',
  styleUrls: ['./comments-on-entity.component.scss'],
  providers: [
    CommentsOnEntityModuleService
  ]
})
export class CommentsOnEntityComponent implements OnInit {
  @Input() entityType:EntityType;
  @Input() entityKeyValue:string;

  componentState:CommentsOnEntityComponentState;
  CommentsOnEntityComponentState:typeof CommentsOnEntityComponentState = CommentsOnEntityComponentState;

  constructor(
    protected commentsOnEntityService:CommentsOnEntityModuleService
  ) { }

  ngOnInit(): void {
    this.initalize();
  }

  /**
   * Initalizes the component and it's service.
   */
  protected async initalize():Promise<void> {
    this.componentState = CommentsOnEntityComponentState.Loading;

    try {
      await this.commentsOnEntityService.initialize(this.entityType, this.entityKeyValue);
    } catch(error:any) {
      switch((error as CommentsOnEntityServiceError).errorType) {
        case CommentsOnEntityServiceErrorType.UserHasNoPermission:
          this.componentState = CommentsOnEntityComponentState.UserHasNoPermissionError
          break;
        case CommentsOnEntityServiceErrorType.CommentsFetchingError:
          this.componentState = CommentsOnEntityComponentState.CommentsFetchingError;
          break;
        default:
          this.componentState = CommentsOnEntityComponentState.OtherError;
      }
      return;
    }

    this.componentState = CommentsOnEntityComponentState.SuccessfullyLoaded;
  }

}

export enum CommentsOnEntityComponentState {
  Loading,
  SuccessfullyLoaded,
  UserHasNoPermissionError,
  CommentsFetchingError,
  OtherError
}