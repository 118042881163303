import { Component, OnInit, Input } from '@angular/core';
import { QuestionGroup } from 'src/app/classes/model/question-groups';
import { MatDialog } from '@angular/material/dialog';
import { QuestiongroupEditDialogComponent } from '../questiongroup-edit-dialog/questiongroup-edit-dialog.component';
import { DialogMode } from 'src/app/classes/misc';
import { Category } from 'src/app/classes/model/category';

/**
 * @description This component represents a question group card in the question groups view.
*/
@Component({
  selector: 'app-questiongroup',
  templateUrl: './questiongroup.component.html',
  styleUrls: ['./questiongroup.component.scss']
})
export class QuestiongroupComponent implements OnInit {
  /** The question group which will be described by the card. */
  @Input() questionGroup:QuestionGroup;
  @Input() categories:ReadonlyArray<Readonly<Category>>;

  assignedCategories:Array<Readonly<Category>>;

  constructor(public dialog:MatDialog) { }

  ngOnInit(): void {
    this.assignedCategories = this.categories.filter((element:Category) => { return this.questionGroup.categories.includes(element.uuid); });
  }

  /**
   * Opens a dialog to modify the corresponding question group.
   * 
   * @returns {void} nothing
  */
  openQuestionGroupModifyDialog():void {
    this.dialog.open(QuestiongroupEditDialogComponent, {
      data: {
        dialogMode: DialogMode.Edit,
        questionGroup: this.questionGroup,
        categories: this.categories
      },
      disableClose: true
    });
  }

  /**
   * Return an object 
  */
  getQueryParam():Object {
    return { questiongroup: this.questionGroup.uuid };
  }

}
