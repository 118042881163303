import { Injectable } from '@angular/core';
import { HttpResponseData } from '../classes/http-communication';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { DataCacherService } from './data-cacher-service';
import { AdminUser } from '../classes/model/admin-user';
import { matchingPropertyPredicate } from '../functions/misc';
import { Permission, PermissionString } from '../classes/model/permissions';

@Injectable({
  providedIn: 'root'
})
export class AdminUserService implements DataCacherService {
  
  private readonly adminUsersApiUrlFragment:string = "/api/admin/admins";
  private readonly registrateAdminUserApiUrlFragment:string = "/api/admin/admins/registrate";

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.ServerManager ];
  
  private adminUsers:Array<AdminUser>;

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) {
    
    this.adminUsers = new Array<AdminUser>();
  }


  public getAdminUsersArrayRef():ReadonlyArray<AdminUser> {
    return this.adminUsers;
  }

  public async fetchAdminUsers():Promise<Array<AdminUser>> {
    let adminUsers:Array<AdminUser> = [];
    try {
      const response:HttpResponseData<Array<AdminUser>> = await this.backendService.callApi(this.adminUsersApiUrlFragment, "GET");
      adminUsers = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az adminisztrátorok nevének lekérdezése közben.");
    }

    return adminUsers;
  }

  public async registrateAdminUser(adminUser:Partial<AdminUser>):Promise<AdminUser> {
    const httpBody:Object = {
      adminUser: adminUser
    }

    let registratedAdminUser:AdminUser;

    try {
      const response:{ adminUser:AdminUser } = await this.backendService.callApi(this.registrateAdminUserApiUrlFragment, "POST", httpBody);
      this.adminUsers.push(response.adminUser);
      registratedAdminUser = response.adminUser;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az adminisztrátor létrehozása közben.");
    }

    return registratedAdminUser;
  }

  public async modifyAdminUser(adminUser:Partial<AdminUser>):Promise<AdminUser> {
    const httpBody:Object = {
      adminUser: adminUser
    }

    let modifiedAdminUser:AdminUser;

    try {
      const response:{ adminUser:AdminUser } = await this.backendService.callApi(this.adminUsersApiUrlFragment, "POST", httpBody);
      this.adminUsers.replaceItems(matchingPropertyPredicate("uuid", adminUser.uuid), response.adminUser);
      modifiedAdminUser = response.adminUser;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az adminisztrátor módosítása közben.");
    }

    return modifiedAdminUser;
  }

  public async loadDataIntoCache(): Promise<void> {
    const adminUsers:Array<AdminUser> = await this.fetchAdminUsers();
    this.adminUsers.replaceArrayElements(adminUsers);
  }

  public getRequiredPermissionsForDataLoading(): readonly PermissionString[] {
    return this.requiredPermissionsForDataLoading;
  }

  public clearCachedData():void {
    this.adminUsers.length = 0;
  }

  public getNameOfCachedData(): string {
    return "Adminisztrátorok";
  }

}


