<div class="main-container">
  <div class="left-side">
    <google-map-extended (onReady)="onGoogleMapReady($event)">
    </google-map-extended>

    <!-- Csúcs és útvonal be/kikapcsoló gomb -->
    <div style="position: absolute; right: 60px; top: 10px">
      <button
        style="margin-right:10px;"
        (click)="onTapChangeVertexesEditable()"
        mat-raised-button
        color="primary"
      >
      <mat-icon style="font-size:20px;">radio_button_checked</mat-icon>
        {{
          isVertexesEditable ? "Csúcsok kikapcsolása" : "Csúszok bekapcsolása"
        }}

      </button>

      <button
      (click)="onTapChangePracticePathsDrawing()"
      mat-raised-button
      color="primary"
    >
    <mat-icon style="font-size:20px;">route</mat-icon>
      {{
        !isPracticePathsDrawOnMapEnabled ? "Útvonalak berajzolása" : "Útvonalak kikapcsolása"
      }}
    </button>
    </div>
  </div>

  <div class="right-side">
    <button (click)="onTapSaveAllZonePolygons()" style="margin-bottom: 15px" mat-raised-button color="primary">
      Összes zóna mentése
    </button>

    <div class="add-new-zone-container">
      <app-city-zone-adder
        class="city-zone-adder"
        [newZoneColor]="currentAddingNewZoneColor"
        [currentState]="this.getZoneAdderComponentState()"
        (clickSaveNewZone)="onTapSaveNewZone($event)"
        (clickAddNewZone)="onTapAddNewZone()"
        (clickCancelAddNewZone)="onTapCancelNewZoneAdding()"
      >
      </app-city-zone-adder>
    </div>

    <div class="zone-list-container">
      <!-- generate 100 random text lorem ipsum -->
      <div *ngFor="let item of this.zones$; trackBy: ngForTrackByZoneUuid">
        <app-city-zone-card
          *ngIf="item.zone.uuid != this.currentEditedNewZoneUuid"
          [allPracticePathsInCity]="this.practicePaths"
          style="height: 110px;display: block; margin-bottom: 8px"
          (clickDelete)="onTapDeleteZone(item.zone.uuid)"
          (clickEdit)="onTapEditZone(item.zone.uuid)"
          (mouseLeave)="onMouseLeaveFromZoneCard()"
          (mouseEnter)="onMouseEnterZoneCard(item.zone.uuid)"
          (click)="this.onTapCityZoneCard(item.zone.uuid)"
          [zone]="item.zone"
        >
        </app-city-zone-card>
      </div>
    </div>
  </div>
</div>
