import { Injectable } from "@angular/core";
import { GroupedPackages } from "../models/grouped-packages";
import { Package } from 'src/app/classes/model/packages/Package';

@Injectable()
export class PackageGrouperService {

  /**
   * Groups the packages by their bulk creation id.
   * 
   * @param packages the packages to group
   * 
   * @returns the grouped packages
   */
  public groupPackages(packages:Array<Package>):Array<GroupedPackages> {
    const result:Array<GroupedPackages> = [];

    // Iterate over the packages
    for(const packageElement of packages) {
      // Get the grouped package with the same bulk creation id
      // Note: If the bulkCreatingGroupId doesn't exist, there will be no group found (which is expected)
      let groupedPackages:GroupedPackages = result.find(
        groupedPackages => groupedPackages.groupId === packageElement.bulkCreatingGroupId
      );

      // If the group was found ...
      if(groupedPackages !== undefined) {
        // ..put the package into that group
        groupedPackages.packages.push(packageElement);
        // Move to the next package
        continue;
      }

      // Create a new group
      groupedPackages = {
        groupId: packageElement.bulkCreatingGroupId ?? packageElement.uuid,
        packages: [ packageElement ],
        displayedName: packageElement.displayedName,
        packagesType: packageElement.packageType,
        creationTimestamp: packageElement.packageCreationTimestamp
      };
      
      // Push the new groups to the grouped packages array
      result.push(groupedPackages);
    }

    // Return the result
    return result;
  }
}