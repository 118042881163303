import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CriticalPointAssignmentLog, CriticalPointLog, GeneralPracticalModuleLog, getEventNameOfPracticalModuleLog, getRealTypeOfPracticalModuleLog, PracticalModuleLog, PracticePathLog } from '../../models/practical-module-log.model';
import { generalLogColor, PracticeModuleEventDescription, PracticeModuleEventLogGroup, practiceModuleEventLogGroups, unidentifiedEventColor } from '../../models/practice-module-event-log-groups.model';
import { EventOrigin } from '../../models/event-origin';

@Component({
  selector: 'app-practical-module-log-item',
  templateUrl: './practical-module-log-item.component.html',
  styleUrls: ['./practical-module-log-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PracticalModuleLogItemComponent implements OnInit {
  @Input() practicalModuleLog:PracticalModuleLog;

  eventColor:string;

  isDetailsShown:boolean = false;

  constructor() { }

  ngOnInit(): void {
    // Set the event color
    this.eventColor = this.getEventColor();
  }


  isPracticalModuleLogGeneral():boolean {
    return getRealTypeOfPracticalModuleLog(this.practicalModuleLog) === GeneralPracticalModuleLog;
  }

  /**
   * Gets the corresponding color for the log's event.
   * 
   * @returns the color as string
   */
  private getEventColor():string {
    // If the log is a general log ...
    if(this.isPracticalModuleLogGeneral()) {
      // ... return the general log color
      return generalLogColor;
    }

    // Otherwise it is some kind of specific log
    // Get the event log group based on the logged entity's type
    const practiceModuleEventLogGroup:PracticeModuleEventLogGroup<string>|undefined = practiceModuleEventLogGroups.find(
      (practiceModuleOperationLogGroup:PracticeModuleEventLogGroup<string>) => {
        return practiceModuleOperationLogGroup.entityType === this.practicalModuleLog.loggedEntityType
      }
    );
    
    // Get the description of the logged event
    const operationDescription:PracticeModuleEventDescription<string>|undefined = practiceModuleEventLogGroup?.eventDescriptions.find(
      (operationDescription:PracticeModuleEventDescription<string>) => {
        return operationDescription.eventName === getEventNameOfPracticalModuleLog(this.practicalModuleLog)
      }
    );

    // Return the event's color (or the unidentified event color, if the event is not a known event)
    return operationDescription?.color ?? unidentifiedEventColor;
  }

  /**
   * Toggles the details shown status.
   */
  protected toggleDetailsShown():void {
    this.isDetailsShown = !this.isDetailsShown;
  }

  /**
   * Converts the practical module log to `CriticalPointLog`.
   * 
   * @param practicalModuleLog the practical module log to convert
   * 
   * @returns the practical module log as `CriticalPointLog`
   */
  protected getLogAsCriticalPointLog(practicalModuleLog:PracticalModuleLog):CriticalPointLog {
    return practicalModuleLog as CriticalPointLog;
  }

  /**
   * Converts the practical module log to `CriticalPointAssignmentLog`.
   * 
   * @param practicalModuleLog the practical module log to convert
   * 
   * @returns the practical module log as `CriticalPointAssignmentLog`
   */
  protected getLogAsCriticalPointAssignmentLog(practicalModuleLog:PracticalModuleLog):CriticalPointAssignmentLog {
    return practicalModuleLog as CriticalPointAssignmentLog;
  }

  /**
   * Converts the practical module log to `PracticePathLog`.
   * 
   * @param practicalModuleLog the practical module log to convert
   * 
   * @returns the practical module log as `PracticePathLog`
   */
  protected getLogAsPracticePathLog(practicalModuleLog:PracticalModuleLog):PracticePathLog {
    return practicalModuleLog as PracticePathLog;
  }

  /**
   * Converts the practical module log to `GeneralPracticalModuleLog`.
   * 
   * @param practicalModuleLog the practical module log to convert
   * 
   * @returns the practical module log as `GeneralPracticalModuleLog`
   */
  protected getLogAsGeneralLog(practicalModuleLog:PracticalModuleLog):GeneralPracticalModuleLog {
    return practicalModuleLog as GeneralPracticalModuleLog;
  }

  /**
   * Gets the system version number from the log's creator information if the creator was the system.
   * Otherwise an empty string is returned.
   * 
   * @param eventOriginEntity the creator info of the practical module log
   * 
   * @returns system version number
   */
  protected getEventOriginSystemEntityVersionNumber(eventOriginEntity:EventOrigin):string {
    return eventOriginEntity.eventInitiator.initiatorType === "system" ? eventOriginEntity.eventInitiator.systemVersion : "";
  }

  /**
   * Gets the admin name from the log's creator information if the creator was an administrator.
   * Otherwise an empty string is returned.
   * 
   * @param eventOriginEntity the creator info of the practical module log
   * 
   * @returns the name of the admin
   */
  protected getEventOriginAdminEntityName(eventOriginEntity:EventOrigin):string {
    return eventOriginEntity.eventInitiator.initiatorType === "admin" ? eventOriginEntity.eventInitiator.adminName : "";
  }
  /**
   * Gets the admin IP address from the log's creator information if the creator was an administrator.
   * Otherwise an empty string is returned.
   * 
   * @param eventOriginEntity the creator info of the practical module log
   * 
   * @returns the IP address of the admin
   */
  protected getEventOriginAdminEntityIpAddress(eventOriginEntity:EventOrigin):string {
    return eventOriginEntity.eventInitiator.initiatorType === "admin" ? eventOriginEntity.eventInitiator.ipAddress : "";
  }

}
