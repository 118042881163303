<div *ngIf="criticalPointAssingmentLog.oldData" style="width: 100%;">
    <div class = "data-title">
        Régi adat:
    </div>
    <div>
        <ngx-json-viewer
            [json]="criticalPointAssingmentLog.oldData"
        ></ngx-json-viewer>
    </div>
</div>
<div *ngIf="criticalPointAssingmentLog.newData" style="width: 100%;">
    <div class = "data-title">
        Új adat:
    </div>
    <div>
        <ngx-json-viewer
            [json]="criticalPointAssingmentLog.newData"
        ></ngx-json-viewer>
    </div>
</div>
<div *ngIf="hasNoLogData()" class = "no-data">
    A log nem rendelkezik megjeleníthető adattal.
</div>