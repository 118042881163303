export class Environment {
  serverHost:string;
  color:string;
  displayedName:string;
  type:EnvironmentType;
}

// Predefined environments


export type PredefinedEnvironmentType =  "local"|"development"|"content"|"production";

export const predefinedEnvironments : Record<PredefinedEnvironmentType,Environment> = {
  local: {
    type: "local",
    serverHost: "http://localhost:3000",
    displayedName: "Lokális környezet",
    color: "#151515"
  },
  development: {
    type: "development",
    serverHost: "https://development.mrkresz.hu",
    displayedName: "Fejlesztői környezet",
    color: "#858585"
  },
  content: {
    type: "content",
    serverHost: "https://content.mrkresz.hu",
    displayedName: "Tartalomkészítői környezet",
    color: "#3f51b5"
  },
  production: {
    type: "production",
    serverHost: "https://production.mrkresz.hu",
    displayedName: "Éles környezet",
    color: "#10a13e"
  },
};


export const customEnvironmentTypeValue = "custom";
export type CustomEnvironmentType = typeof customEnvironmentTypeValue;

export type EnvironmentType = PredefinedEnvironmentType | CustomEnvironmentType;

export class EnvironmentError extends Error {};




