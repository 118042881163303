import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggedStudent } from "src/app/classes/model/logged-student";
import { Student } from 'src/app/classes/model/student';
import { StudentLoggingService } from "src/app/services/student-logging.service";
import { StudentService } from 'src/app/services/student.service';
import { StudentDetailsDialogComponent, StudentDetailsDialogData } from '../../users/students/student-details-dialog/student-details-dialog.component';

@Component({
  selector: 'app-student-logging',
  templateUrl: './student-logging.component.html',
  styleUrls: ['./student-logging.component.scss']
})
export class StudentLoggingComponent implements OnInit {

  loggedStudents:ReadonlyArray<Readonly<LoggedStudent>>;
  isContentLoading:boolean;

  constructor(private studentLoggingService:StudentLoggingService, public snackBar:MatSnackBar, private studentService:StudentService, public dialog:MatDialog) { }

  ngOnInit():void {
    this.loggedStudents = this.studentLoggingService.getLoggedStudentsArrayRef();
    this.loadLoggedStudents();
  }

  public async handleReloadButtonClick():Promise<void> {
    this.loadLoggedStudents();
  }

  private async loadLoggedStudents():Promise<void> {
    this.isContentLoading = true;

    try {
      await this.studentLoggingService.loadDataIntoCache();
    } catch(error:any) {}

    this.isContentLoading = false;
  }

  public async switchLogging(index:number, isActive:boolean) {
    try {
      await this.studentLoggingService.switchLoggingModeForStundent(this.loggedStudents[index], isActive);
      if(isActive) {
        this.snackBar.open("Tanuló monitorozása visszakapcsolva.", "Bezár", { duration: 3000 });
      } else {
        this.snackBar.open("Tanuló monitorozása kikapcsolva.", "Bezár", { duration: 3000 });
      }
    } catch(error:any) {}
  }

  public openStudentDetailsDialog(studentUuid:string):void {
    this.dialog.open<StudentDetailsDialogComponent, StudentDetailsDialogData>(
      StudentDetailsDialogComponent,
      {
        data: {
          studentUuid: studentUuid
        }
      }
    );
  }

}
