<div class = "dialog-content">
    <div class = "text-container">
        {{ data.text }}
    </div>
    <div>
        <button
            class = "close-button"
            mat-dialog-close
        >
            Oké        
        </button>
    </div>
</div>
