import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PracticeCity } from 'src/app/classes/model/practice-city';
import { adminUserSorterByName } from 'src/app/classes/user';
import { AdminBasicInfo, AdminUsersBasicInfoService } from 'src/app/services/admin-users-basic-info.service';
import { SessionService } from 'src/app/services/common/session.service';
import { PracticeCityService } from 'src/app/services/practice-city.service';

export type CityResponsiblesEditorData = {
  city:PracticeCity;
  onResposiblesChange:(contentResponsibles:Array<string>) => void;
}

@Component({
  selector: 'app-city-responsibles-editor',
  templateUrl: './city-responsibles-editor.component.html',
  styleUrls: ['./city-responsibles-editor.component.scss']
})
export class CityResponsiblesEditorComponent implements OnInit {

  responsibleSelectFC:FormControl;

  assignedResponsibles:Array<AdminBasicInfo> = [];
  unassignedResponsibles:Array<AdminBasicInfo> = [];

  ownUuid:string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:CityResponsiblesEditorData,
    private practiceCityService:PracticeCityService,
    private adminUsersBasicInfoService:AdminUsersBasicInfoService,
    private snackBarService:MatSnackBar,
    private sessionService:SessionService
  ) {
    this.responsibleSelectFC = new FormControl("");
  }

  ngOnInit(): void {
    this.ownUuid = this.sessionService.getUuid();
    
    this.unassignedResponsibles = this.adminUsersBasicInfoService.getAdminsBasicInfosArrayRef().slice();
    this.unassignedResponsibles.sort(adminUserSorterByName);

    this.data.city.contentResponsibles.forEach(
      (contentResponsibleUuid:string) => {
        this.assignResponsibleLocally(contentResponsibleUuid);
      }
    );
    this.assignedResponsibles.sort(adminUserSorterByName);
  }

  public handlaAssignResponsibleButtonClick():void {
    this.assignResponsible(this.responsibleSelectFC.value);
    this.responsibleSelectFC.setValue("");
  }

  public async assignResponsible(responsibleUuid:string):Promise<void> {
    const index:number = this.unassignedResponsibles.findIndex(responsible => responsible.uuid === responsibleUuid);
    if(index < 0) {
      return;
    }

    try {
      await this.practiceCityService.updateCityResponsibleAssignment(this.data.city, responsibleUuid, "ADD");
    } catch(error:any) {
      return;
    }

    this.assignResponsibleLocally(responsibleUuid);
    this.data.city.contentResponsibles.push(responsibleUuid);
    this.data.onResposiblesChange(this.data.city.contentResponsibles);
    this.snackBarService.open("Felelős hozzásadása sikerült.", "Bezár", { duration: 3000, panelClass: [ "mat-snackbar-success" ] });
  }

  public async unassignResponsible(responsibleUuid:string):Promise<void> {
    if(responsibleUuid === this.ownUuid) {
      return;
    }

    const index:number = this.assignedResponsibles.findIndex(responsible => responsible.uuid === responsibleUuid);
    if(index < 0) {
      return;
    }

    try {
      await this.practiceCityService.updateCityResponsibleAssignment(this.data.city, responsibleUuid, "DELETE");
    } catch(error:any) {
      return;
    }

    this.unassignResponsibleLocally(responsibleUuid);
    this.data.city.contentResponsibles.removeItems(contentResponsible => contentResponsible === responsibleUuid);
    this.data.onResposiblesChange(this.data.city.contentResponsibles);
    this.snackBarService.open("Felelős levétele sikerült.", "Bezár", { duration: 3000, panelClass: [ "mat-snackbar-success" ] });
  }

   /**
   * Assign the responsible to the local (displayed) array.
   * 
   * @param responsibleUuid the uuid of the responsible
   */
    private assignResponsibleLocally(responsibleUuid:string):void {
      const index:number = this.unassignedResponsibles.findIndex(responsible => responsible.uuid === responsibleUuid);
      if(index < 0) {
        return;
      }
  
      this.assignedResponsibles.push(this.unassignedResponsibles[index]);
      this.assignedResponsibles.sort(adminUserSorterByName);
      this.unassignedResponsibles.splice(index, 1);
    }

  /**
   * Unassign the responsible to the local (displayed) array.
   * 
   * @param responsibleUuid the uuid of the responsible
   */
  private unassignResponsibleLocally(responsibleUuid:string):void {
    const index:number = this.assignedResponsibles.findIndex(responsible => responsible.uuid === responsibleUuid);
    if(index < 0) {
      return;
    }

    this.unassignedResponsibles.push(this.assignedResponsibles[index]);
    this.unassignedResponsibles.sort(adminUserSorterByName);
    this.assignedResponsibles.splice(index, 1);
  }
}
