import { Injectable } from '@angular/core';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { AdminUsersBasicInfoService } from './admin-users-basic-info.service';
import { GiftedPackage, PackageGiftCreatedByAdminInformation } from '../classes/model/packages/GiftedPackage';
import { DataCacherService } from './data-cacher-service';
import { Permission, PermissionString } from '../classes/model/permissions';

@Injectable({
  providedIn: 'root'
})
export class AdminPersonalGiftService implements DataCacherService {
  private readonly adminApiUrlFragment:string = "/api/admin/admins";
  private readonly sendGiftToEmailApiUrlFragment:string = "/api/admin/gift/send_gift_to_email";

  private handedOutGifts:Array<PersonalGift>;

  private readonly requiredPermissionsForDataLoading:ReadonlyArray<PermissionString> = [ Permission.GiftsOfAdminRead ];

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService,
    private adminUsersBasicInfoService:AdminUsersBasicInfoService
  ) {
    this.handedOutGifts = new Array<PersonalGift>();
  }

  public getHandedOutGiftsArrayRef():ReadonlyArray<PersonalGift> {
    return this.handedOutGifts;
  }

  public async fetchHandedOutGifts():Promise<Array<PersonalGift>> {
    let handedOutGifts:Array<PersonalGift> = [];
    try {
      for(const adminUserName of this.adminUsersBasicInfoService.getAdminsBasicInfosArrayRef()) {
        const apiUrlFragment:string = `${this.adminApiUrlFragment}/${adminUserName.uuid}/sent_gifts`;
        const response:{ handedOutGifts:Array<GiftedPackage> } = await this.backendService.callApi(apiUrlFragment, "GET");

        for(const giftedPackage of response.handedOutGifts) {
          let targetPersonalGift:PersonalGift|null = null;
          if(giftedPackage.bulkCreatingGroupId) {
            targetPersonalGift = this.handedOutGifts.find(handedOutGift => handedOutGift.groupId === giftedPackage.bulkCreatingGroupId);
          }

          if(targetPersonalGift) {
            ++targetPersonalGift.count;
          } else {
            handedOutGifts.push(this.giftedPackageToPersonalGift(giftedPackage));
          }
        }
      }
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az admin ajándékok lekérdezése közben.");
    }

    return handedOutGifts;
  }

  public async handOutGiftViaEmail(giftedEmail:string, packageDefinitionUuid:string, reason:string, message:string):Promise<void> {
    const httpBody:Object = {
      emailAddress: giftedEmail,
      packageDefinitionUuid: packageDefinitionUuid,
      reason: reason,
      message: message
    };

    try {
      const response:{ createdNewPackage:GiftedPackage } = await this.backendService.callApi(this.sendGiftToEmailApiUrlFragment, "POST", httpBody);
      this.handedOutGifts.unshift(this.giftedPackageToPersonalGift(response.createdNewPackage));
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az ajándék létrehozása közben.");
    }
  }

  private giftedPackageToPersonalGift(giftedPackage:GiftedPackage):PersonalGift {
    const isRedeemed:boolean = giftedPackage.giftInfo.receiver.receivingType === "throughEmail" && giftedPackage.redemptionInformation?.redemptionTimestamp != undefined;

    const personalGift:PersonalGift = {
      displayedPackageName: giftedPackage.displayedName,
      senderAdminEmail: (giftedPackage.giftInfo as PackageGiftCreatedByAdminInformation).gifterAdminEmail,
      sendingTimestamp: giftedPackage.packageCreationTimestamp,
      isRedeemed: isRedeemed,
      receivingMethod: giftedPackage.giftInfo.receiver.receivingType,
      reveiverIdType: giftedPackage.giftInfo.receiver.idType,
      receiverId: giftedPackage.giftInfo.receiver.id,
      groupId: giftedPackage.bulkCreatingGroupId ?? null,
      count: 1,
      entityType: giftedPackage.giftInfo.receiver.receivingType === "throughEmail" ? "email" : giftedPackage.giftInfo.receiver.entityType,
      packageCode: giftedPackage.giftInfo.receiver.receivingType === "throughEmail" ? (giftedPackage.redemptionInformation?.packageActivationCode ?? "") : ""
    }

    if(personalGift.receivingMethod !== "throughEmail" && giftedPackage.giftInfo.receiver.receiverName) {
      personalGift.displayedName = giftedPackage.giftInfo.receiver.receiverName;
    } else {
      personalGift.displayedName = giftedPackage.giftInfo.receiver.id;
    }

    return personalGift;
  }

  public async loadDataIntoCache(): Promise<void> {
    const handedOutGifts:Array<PersonalGift> = await this.fetchHandedOutGifts();
    this.handedOutGifts.replaceArrayElements(handedOutGifts).sort(personalGiftSorter);
  }

  public getRequiredPermissionsForDataLoading(): readonly PermissionString[] {
    return this.requiredPermissionsForDataLoading;
  }

  public clearCachedData():void {
    this.handedOutGifts.length = 0;
  }

  public getNameOfCachedData(): string {
    return "Kiosztott ajándékok";
  }

}

export type PersonalGift = {
  displayedPackageName:string;
  senderAdminEmail:string;
  sendingTimestamp:number;
  isRedeemed:boolean;
  receivingMethod:"throughEmail"|"explicit";
  reveiverIdType:"uuid"|"email";
  receiverId:string;
  count:number;
  groupId:string;
  entityType:"vendor"|"student"|"email";
  displayedName?:string;
  packageCode?:string;
}

function personalGiftSorter(personalGift1:PersonalGift, personalGift2:PersonalGift):number {
  return personalGift2.sendingTimestamp - personalGift1.sendingTimestamp;
}